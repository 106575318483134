import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import Card from '@mui/material/Card';
import Tabs from '@mui/material/Tabs';
import Tab, { TabProps } from '@mui/material/Tab';

import { ReactErrorComponent } from '../../_lib/react-error';
import { useDashboardContext } from '../../_lib/context/dashboard-context';

import { PlanningLayoutTabPanel } from '../layout/tab-panel';

import { SettingsDetails } from './details';
import { SettingsAdvanced } from './advanced';
import { SettingsMembers } from './members';
import { SettingsImportExport } from './import-export';
import { SettingsAdvancedContextProvider } from './advanced/dashboard-form-advanced-context';

export function SettingsDashboardForm() {
  const { t } = useTranslation();

  const location = useLocation();
  const history = useHistory();

  const theme = useTheme();
  const matchesDownMd = useMediaQuery(theme.breakpoints.down('md'));

  const { dashboard } = useDashboardContext();

  const tabVal = React.useMemo(() => {
    const locationPathname = location.pathname.endsWith('/')
      ? location.pathname.slice(0, -1)
      : location.pathname;
    switch (locationPathname) {
      case `${t('/settings')}`:
        return 0;
      case `${t('/settings')}${t('/members')}`:
        return 1;
      case `${t('/settings')}${t('/advanced')}`:
        return 2;
      case `${t('/settings')}${t('/import-export')}`:
        if (dashboard.enableImportExport) return 3;
        return 0;
      default:
        return 0;
    }
  }, [dashboard.enableImportExport, location.pathname, t]);

  // ----------------------------------------------------------------------------------------------------------
  // ----------------------------------------------------------------------------------------------------------
  // handlers
  // ----------------------------------------------------------------------------------------------------------

  const handleChange = React.useCallback(
    (event: React.SyntheticEvent, newTabVal: number) => {
      if (newTabVal === 0) {
        history.push(`${t('/settings')}`);
      }
      if (newTabVal === 1) {
        history.push(`${t('/settings')}${t('/members')}`);
      }
      if (newTabVal === 2) {
        history.push(`${t('/settings')}${t('/advanced')}`);
      }
      if (newTabVal === 3) {
        history.push(`${t('/settings')}${t('/import-export')}`);
      }
    },
    [history, t]
  );

  const a11yProps = React.useCallback((index: number) => {
    return {
      id: `vertical-tab-${index}`,
      'aria-controls': `vertical-tabpanel-${index}`,
      sx: {
        overflowWrap: 'anywhere',
        textAlign: 'right',
      },
    } as TabProps;
  }, []);

  // ----------------------------------------------------------------------------------------------------------

  return (
    <PlanningLayoutTabPanel
      index={0}
      title={`${t('Dashboard Settings')}`}
      subheader={`${t(
        'Set up your dashboard details, advanced settings, and manage members.'
      )}`}
    >
      <Card
        variant="outlined"
        sx={{
          mt: 3,
          display: 'flex',
          flexGrow: 1,
          [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
          },
        }}
      >
        <Tabs
          orientation={matchesDownMd ? 'horizontal' : 'vertical'}
          variant="scrollable"
          value={tabVal}
          onChange={handleChange}
          aria-label="dashboard detail tabs"
          sx={{
            borderRight: matchesDownMd ? 0 : 1,
            borderBottom: matchesDownMd ? 1 : 0,
            py: matchesDownMd ? 0 : 4,
            my: matchesDownMd ? 1 : 0,

            borderColor: 'divider',
            minWidth: '150px',
            '& .MuiTab-root': {
              alignItems: 'flex-end',
            },
          }}
        >
          <Tab label={t('Overview')} {...a11yProps(0)} />
          <Tab label={t('Members')} {...a11yProps(1)} />
          <Tab label={t('Advanced')} {...a11yProps(2)} />
          {dashboard.enableImportExport && (
            <Tab label={t('Import/Export')} {...a11yProps(3)} />
          )}
        </Tabs>

        {/* overview */}
        <ErrorBoundary FallbackComponent={ReactErrorComponent}>
          <SettingsDetails tabVal={tabVal} tabInd={0} />
        </ErrorBoundary>

        {/* members */}
        <ErrorBoundary FallbackComponent={ReactErrorComponent}>
          <SettingsMembers tabVal={tabVal} tabInd={1} />
        </ErrorBoundary>

        {/* advanced */}
        <ErrorBoundary FallbackComponent={ReactErrorComponent}>
          <SettingsAdvancedContextProvider>
            <SettingsAdvanced tabVal={tabVal} tabInd={2} />
          </SettingsAdvancedContextProvider>
        </ErrorBoundary>

        {/* import / export */}
        <ErrorBoundary FallbackComponent={ReactErrorComponent}>
          <SettingsImportExport tabVal={tabVal} tabInd={3} />
        </ErrorBoundary>
      </Card>
    </PlanningLayoutTabPanel>
  );
}
