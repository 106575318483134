import React from 'react';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';

import { useSummary } from '../../../_utils/hooks/queries/use-summary';
import { useDashboardContext } from '../../../_lib/context/dashboard-context';
import { LinearProgressWithLabel } from '../../linear-progress/with-label';
import { DoughnutChart } from '../../doughnut-chart';

export function AreaSummaryCardActivities({ area, filterUnstarted }: any) {
  const { t } = useTranslation();

  const { dashboard } = useDashboardContext();

  const areaType =
    area?.__typename === 'PriorityAreaType'
      ? 'priorityArea'
      : area?.__typename === 'InterventionType'
      ? 'intervention'
      : undefined;

  const { data, loading, error } = useSummary(
    'contentSummary',
    {
      priorityAreaId: areaType === 'priorityArea' ? area.id : undefined,
      interventionId: areaType === 'intervention' ? area.id : undefined,
    },
    areaType
  );

  const contentSummary = React.useMemo(
    () =>
      areaType
        ? filterUnstarted(data?.[areaType]?.summary?.contentSummary)
        : undefined,
    [areaType, data, filterUnstarted]
  );

  if (error) return null;

  if (loading)
    return <Skeleton variant="rectangular" height={120} sx={{ mb: 2 }} />;

  if (!areaType) return null;

  return (
    <Box>
      <Typography variant="body2" sx={{ mb: 1 }}>
        {t('Activities')}: {contentSummary?.total}
      </Typography>

      {/* activity perc done */}
      {dashboard.enableActivityPercentage && !!contentSummary?.total && (
        <Box sx={{ mb: 1 }}>
          <Typography variant="caption" component="div">
            {t('Avg Perc Done')}
          </Typography>
          <LinearProgressWithLabel
            value={contentSummary?.percDone}
            sx={{ height: '20px', borderRadius: '3px' }}
          />
        </Box>
      )}

      {/* activities doughnut chart */}
      <Box sx={{ my: 2 }}>
        <DoughnutChart
          id={`${area.__typename}-${area.id}`}
          contentSummary={contentSummary}
        />
      </Box>
    </Box>
  );
}
