import Box from '@mui/material/Box';
import LinearProgress, {
  LinearProgressProps,
} from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';

export function LinearProgressWithLabel(
  props: LinearProgressProps & {
    value: number;
    count?: number;
    labelTypographyProps?: any;
  }
) {
  const { value, count, labelTypographyProps, ...other } = props;
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1.5 }}>
        <LinearProgress
          variant="determinate"
          color={value >= 100 ? 'success' : 'primary'}
          {...other}
          value={value > 100 ? 100 : value < 0 ? 0 : value || 0}
        />
      </Box>
      <Box>
        <Typography
          variant="body2"
          color="text.secondary"
          {...labelTypographyProps}
        >{`${Math.round(value < 0 ? 0 : value)}%`}</Typography>
      </Box>
    </Box>
  );
}

LinearProgressWithLabel.defaultProps = {
  count: undefined,
  labelTypographyProps: {},
};
