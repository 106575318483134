import React from 'react';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';

import { DialogModal } from '../../../components/dialog-modal';

import { DashboardContext } from '../../../_lib/context/dashboard-context';
import { ContentDialogContext } from '../../../_lib/context/content-dialog-context';

import { CONTENT_UPDATE } from '../../../_lib/graphql/mutations';
import { logError } from '../../../_lib/error';
import { CONTENT_QUERY } from '../../../_lib/graphql/queries';

export function ConvertIssueToActivity() {
  const { t } = useTranslation();

  const { users, setSnackbarOpen, setSnackbarIsError } =
    React.useContext(DashboardContext);
  const { content, contentId } = React.useContext(ContentDialogContext);

  const [confirmDialogOpen, setConfirmDialogOpen] = React.useState(false);
  const [convertIssueToActivityMutation, { loading }] = useMutation(
    CONTENT_UPDATE,
    {
      update: (cache, { data }) => {
        cache.writeQuery({
          query: CONTENT_QUERY,
          data: { content: data.contentUpdate.content },
          variables: { id: contentId },
        });
      },
    }
  );

  const convertIssueToActivity = React.useCallback(
    ({ assignee, assignees, date }: any) => {
      const variables = {
        id: content.id,
        deadline: date,
        assigneeId: assignee?.id || null,
        assigneesIds: assignees?.map((a: any) => a.id) || null,
        convertToActivity: true,
        isIssue: false,
      };
      convertIssueToActivityMutation({ variables }).catch((e) => {
        logError(e);
        setSnackbarIsError(true);
        setSnackbarOpen(true);
      });
    },
    [
      content,
      convertIssueToActivityMutation,
      setSnackbarIsError,
      setSnackbarOpen,
    ]
  );

  return (
    <>
      <DialogModal
        loading={loading}
        onDismiss={() => setConfirmDialogOpen(false)}
        onSubmit={convertIssueToActivity}
        show={confirmDialogOpen}
        title={t('Select a due date and assignee')}
        type="convert-to-activity"
        users={users}
      />

      <Button
        id="convert-issue-to-activity"
        onClick={() => setConfirmDialogOpen(true)}
        startIcon={<SwapHorizIcon />}
        variant="outlined"
      >
        <Box
          component="span"
          sx={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            minWidth: 0,
          }}
        >
          {t('Convert to Activity')}
        </Box>
      </Button>
    </>
  );
}
