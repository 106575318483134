import React from 'react';
import { useTranslation } from 'react-i18next';
import sanitizeHtml from 'sanitize-html';
import { useLazyQuery, gql } from '@apollo/client';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import LinearProgress from '@mui/material/LinearProgress';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import GetAppIcon from '@mui/icons-material/GetApp';

import { useDashboardContext } from '../../../_lib/context/dashboard-context';
import { useContentDialogContext } from '../../../_lib/context/content-dialog-context';

import { getMediaUrl, getStringDateTime } from '../../../_utils/general-utils';
import { ContentDialogContentUpdateAlert } from './alert';
import { Markdown } from '../../../components/markdown';
import { useOnlineStatus } from '../../../_lib/offline/use-online-status';
import { logError } from '../../../_lib/error';

import { ContentDialogContentUpdateEdit } from './edit';
import { ContentDialogContentUpdateUpdate } from './update';

const CONTENT_UPDATE_ATTACHMENT_QUERY = gql`
  query ContentUpdateAttachment($contentUpdateId: ID!) {
    contentUpdate(contentUpdateId: $contentUpdateId) {
      id
      attachment {
        url
        name
      }
    }
  }
`;

export function ContentDialogContentUpdate({ updateId }: any) {
  const { t } = useTranslation();

  const isOnline = useOnlineStatus();

  const {
    dashboard: { language },
    user,
  } = useDashboardContext();

  const { content } = useContentDialogContext();
  const update = content?.updates?.find((u: any) => u.id === updateId);

  const [isAbleToEdit, setIsAbleToEdit] = React.useState<boolean>(
    user.id === update.author.id &&
      !update.rejectionMessage &&
      !update.automatic
  );
  const [edit, setEdit] = React.useState(false);
  const [downloadAttachment, setDownloadAttachment] = React.useState(false);

  const [getContentUpdate, { data, loading, error }] = useLazyQuery(
    CONTENT_UPDATE_ATTACHMENT_QUERY,
    {
      fetchPolicy: 'no-cache',
    }
  );

  // -----------------------------------------------------------------------------------------------------------
  // -----------------------------------------------------------------------------------------------------------
  // effects
  // -----------------------------------------------------------------------------------------------------------

  // able to edit?
  React.useEffect(() => {
    setIsAbleToEdit(
      user.id === update?.author?.id &&
        !update?.rejectionMessage &&
        !update?.automatic
    );
  }, [update, user.id]);

  // data
  React.useEffect(() => {
    if (data?.contentUpdate?.attachment?.url && downloadAttachment) {
      const { url } = data.contentUpdate.attachment;
      window.open(getMediaUrl(url), '_blank');
      setDownloadAttachment(false);
    }
  }, [data, downloadAttachment]);

  // error
  React.useEffect(() => {
    if (error) logError(error);
  }, [error]);

  // -----------------------------------------------------------------------------------------------------------

  if (!content || !update) return <LinearProgress />;

  return (
    <Box sx={{ mb: 2 }}>
      <ContentDialogContentUpdateUpdate updateId={update.id}>
        {/* header */}
        <Box sx={{ mb: 0.5 }}>
          <Typography variant="body1" display="inline" sx={{ mr: 2 }}>
            <strong>{`${update.author.name}`}</strong>
          </Typography>
          <Typography variant="caption" color="textSecondary" display="inline">
            {getStringDateTime(
              update.updatedAt || update.postDatetime,
              language
            )}
          </Typography>
          {update.isEdited && (
            <Typography
              variant="caption"
              color="textSecondary"
              display="inline"
              sx={{ ml: 1 }}
            >
              ({t('Edited')})
            </Typography>
          )}
        </Box>

        {/* update */}
        <Paper
          variant="outlined"
          sx={{ backgroundColor: '#f8f9fa', py: 1, px: 2 }}
        >
          {edit ? (
            <ContentDialogContentUpdateEdit
              updateId={update.id}
              edit={edit}
              setEdit={setEdit}
            />
          ) : (
            <>
              {/* admin actions : approve / reject / reject message */}
              {(!!update.extensionRequest ||
                !!update.suggestCompleted ||
                (content.access === 'EDIT' && update.status === 'PENDING') ||
                !!update.rejectionMessage) && (
                <ContentDialogContentUpdateAlert updateId={update.id} />
              )}

              {/* update text */}
              <Box>
                <Markdown key={update.text}>
                  {sanitizeHtml(update.text).replace(/\*\*/g, '') || '--'}
                </Markdown>
              </Box>
            </>
          )}
        </Paper>
      </ContentDialogContentUpdateUpdate>

      {/* update actions */}
      {!edit && (
        <Box sx={{ mt: 1 }}>
          <Grid container spacing={1}>
            {/* Edit */}
            {isAbleToEdit && (
              <Grid item>
                <Button size="small" onClick={() => setEdit(true)}>
                  {t('Edit')}
                </Button>
              </Grid>
            )}

            {/* attachment */}
            {!!update.attachment && (
              <Grid item>
                <LoadingButton
                  id="attachment-link"
                  startIcon={<GetAppIcon />}
                  size="small"
                  variant="contained"
                  disableElevation
                  onClick={() => {
                    setDownloadAttachment(true);
                    getContentUpdate({
                      variables: { contentUpdateId: update.id },
                    });
                  }}
                  loading={loading}
                  disabled={!isOnline}
                >
                  {`${t('Download Attachments')} (1)`}
                </LoadingButton>
              </Grid>
            )}
          </Grid>
        </Box>
      )}
    </Box>
  );
}
