import React from 'react';
import { useTranslation } from 'react-i18next';

import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';

import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import DeleteIcon from '@mui/icons-material/Delete';
import ArchiveIcon from '@mui/icons-material/Archive';
import UnarchiveIcon from '@mui/icons-material/Unarchive';

import { useDashboardContext } from '../../../_lib/context/dashboard-context';
import { logError } from '../../../_lib/error';
import { useArchiveArea } from '../../../_utils/hooks/mutations/use-delete-archive-area';

import { PlanningAreaActionsDeletePriorityAreaDialog } from './priority-area-delete-dialog';
import { PlanningAreaActionsDeleteInterventionDialog } from './intervention-delete-dialog';

export function PlanningAreaActionsDelete({
  area,
  isButton,
  isArchive,
  listVariables,
}: any) {
  const { t } = useTranslation();
  const { setSnackbarOpen, setSnackbarIsError } = useDashboardContext();

  const { archiveMutation: unArchiveMutation, archiving: unarchiving } =
    useArchiveArea(
      area.__typename === 'InterventionType' ? 'Intervention' : 'PriorityArea',
      listVariables
    );

  const [confirmDialogOpen, setConfirmDialogOpen] = React.useState(false);

  const getDeleteActionsState = () => {
    if (isArchive) {
      return {
        title: !area.archivedAt ? t('Archive') : t('Unarchive'),
        ariaLabel: !area.archivedAt ? t('Archive') : t('Unarchive'),
        startIcon: !area.archivedAt ? <ArchiveIcon /> : <UnarchiveIcon />,
        color: 'inherit' as
          | 'inherit'
          | 'error'
          | 'primary'
          | 'secondary'
          | 'success'
          | 'info'
          | 'warning'
          | undefined,
      };
    }

    return {
      title: t('Delete'),
      ariaLabel: t('delete'),
      startIcon: <DeleteIcon />,
      color: 'error' as
        | 'inherit'
        | 'error'
        | 'primary'
        | 'secondary'
        | 'success'
        | 'info'
        | 'warning'
        | undefined,
    };
  };

  // handlers
  const handleClick = () => {
    if (!isArchive || !area.archivedAt) {
      setConfirmDialogOpen(true);
    } else {
      unArchiveMutation({
        variables: {
          ...listVariables,
          id: area.id,
          isUnarchive: true,
        },
      })
        .catch((err: any) => {
          logError(err);
          setSnackbarIsError(true);
        })
        .finally(() => {
          setSnackbarOpen(true);
        });
    }
  };

  return (
    <>
      {/* dialogs */}
      {area.__typename === 'PriorityAreaType' ? (
        <PlanningAreaActionsDeletePriorityAreaDialog
          open={confirmDialogOpen}
          setOpen={setConfirmDialogOpen}
          priorityArea={area}
          isArchive={isArchive}
        />
      ) : (
        <PlanningAreaActionsDeleteInterventionDialog
          open={confirmDialogOpen}
          setOpen={setConfirmDialogOpen}
          intervention={area}
          isArchive={isArchive}
          listVariables={listVariables}
        />
      )}

      {/* button */}
      {!isButton ? (
        <Tooltip title={getDeleteActionsState().title} arrow>
          <IconButton
            onClick={handleClick}
            aria-label={getDeleteActionsState().ariaLabel}
          >
            {isArchive ? (
              !area.archivedAt ? (
                <ArchiveIcon />
              ) : (
                <UnarchiveIcon />
              )
            ) : (
              <DeleteForeverIcon />
            )}
          </IconButton>
        </Tooltip>
      ) : (
        <Button
          variant="outlined"
          color={getDeleteActionsState().color}
          startIcon={getDeleteActionsState().startIcon}
          onClick={handleClick}
          size="small"
          disabled={unarchiving}
        >
          {getDeleteActionsState().title}
        </Button>
      )}
    </>
  );
}
