type RequireAtLeastOne<T, Keys extends keyof T = keyof T> = Pick<
  T,
  Exclude<keyof T, Keys>
> &
  {
    [K in Keys]-?: Required<Pick<T, K>> & Partial<Pick<T, Exclude<Keys, K>>>;
  }[Keys];

export const mergeDualArraysOnKey = <
  K extends PropertyKey,
  T extends RequireAtLeastOne<{ [f in PropertyKey]?: unknown }, K>
>(
  key: K,
  ...lists: [T[], T[]]
): T[] => {
  const lookup: { [key in string]: number } = {};
  return lists[0].concat(lists[1]).reduce((acc: T[], value: T, i: number) => {
    const lookupKey = `${value[key]}`;
    if (Object.prototype.hasOwnProperty.call(lookup, lookupKey)) {
      acc[lookup[lookupKey]] = { ...acc[lookup[lookupKey]], ...value };
    } else {
      acc.push(value);
      lookup[lookupKey] = acc.length - 1;
    }
    return acc;
  }, []);
};

export const mergeArrays = <
  K extends PropertyKey,
  T extends RequireAtLeastOne<{ [f in PropertyKey]?: unknown }, K>
>(
  key: K,
  ...lists: T[][]
): T[] => {
  if (lists.length === 1) {
    return lists[0];
  }
  const l1 = lists.pop() || [];
  const l2 = lists.pop() || [];
  return mergeArrays(key, mergeDualArraysOnKey(key, l1, l2), ...lists);
};
