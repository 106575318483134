import React from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorBoundary } from 'react-error-boundary';

import { Font, BlobProvider } from '@react-pdf/renderer';

import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';

import notoSansFont from '../../../../assets/fonts/Noto_Sans/NotoSans-Regular.ttf';
import notoSansFontItalic from '../../../../assets/fonts/Noto_Sans/NotoSans-Italic.ttf';
import notoSansFontLight from '../../../../assets/fonts/Noto_Sans/NotoSans-Light.ttf';
import notoSansFontLightItalic from '../../../../assets/fonts/Noto_Sans/NotoSans-LightItalic.ttf';
import notoSansFontMedium from '../../../../assets/fonts/Noto_Sans/NotoSans-Medium.ttf';
import notoSansFontMediumItalic from '../../../../assets/fonts/Noto_Sans/NotoSans-MediumItalic.ttf';
import notoSansFontBold from '../../../../assets/fonts/Noto_Sans/NotoSans-Bold.ttf';
import notoSansFontBoldItalic from '../../../../assets/fonts/Noto_Sans/NotoSans-BoldItalic.ttf';
import notoSansFontBlack from '../../../../assets/fonts/Noto_Sans/NotoSans-Black.ttf';
import notoSansFontBlackItalic from '../../../../assets/fonts/Noto_Sans/NotoSans-BlackItalic.ttf';

import { ReportsPdfDocument } from './document';

import { useReportsContext } from '../../../_lib/context/reports-context';
import { useDashboardContext } from '../../../_lib/context/dashboard-context';

import { ReactErrorComponent } from '../../../_lib/react-error';

function BlobUpdate({ blob }: { blob: Blob | null }) {
  const { setPdfBlob } = useReportsContext();
  React.useEffect(() => {
    setPdfBlob((prev: Blob | null) => {
      if (!prev && blob) return blob;
      if (prev && blob && prev.size !== blob.size) return blob;
      return prev;
    });
  }, [blob, setPdfBlob]);
  return null;
}

export function ReportEditPdf() {
  const { t } = useTranslation();

  const { user } = useDashboardContext();
  const { report, reportData, filterAreas, isNew, filterDates, filterMembers } =
    useReportsContext();

  // Fonts
  Font.register({
    family: 'Noto Sans',
    fonts: [
      { src: notoSansFont, fontWeight: 'normal', fontStyle: 'normal' },
      { src: notoSansFontItalic, fontWeight: 'normal', fontStyle: 'italic' },
      { src: notoSansFontLight, fontWeight: 'light', fontStyle: 'normal' },
      {
        src: notoSansFontLightItalic,
        fontWeight: 'light',
        fontStyle: 'italic',
      },
      { src: notoSansFontMedium, fontWeight: 'medium', fontStyle: 'normal' },
      {
        src: notoSansFontMediumItalic,
        fontWeight: 'medium',
        fontStyle: 'italic',
      },
      { src: notoSansFontBold, fontWeight: 'bold', fontStyle: 'normal' },
      { src: notoSansFontBoldItalic, fontWeight: 'bold', fontStyle: 'italic' },
      { src: notoSansFontBlack, fontWeight: 'heavy', fontStyle: 'normal' },
      {
        src: notoSansFontBlackItalic,
        fontWeight: 'heavy',
        fontStyle: 'italic',
      },
    ],
  });

  if (Object.keys(reportData || {}).length === 0)
    return <Skeleton variant="rectangular" height={800} />;

  return (
    <>
      <ErrorBoundary FallbackComponent={ReactErrorComponent}>
        <BlobProvider
          document={
            <ReportsPdfDocument
              t={t}
              user={user}
              isNew={isNew}
              report={{
                ...report,
                filterStartDate: filterDates?.startDate,
                filterEndDate: filterDates?.endDate,
              }}
              reportData={reportData}
              filterAreas={filterAreas}
              filterMembers={filterMembers}
            />
          }
        >
          {({ blob, url, loading, error }) => {
            if (error) return <ReactErrorComponent error={error} />;

            if (loading) return <Skeleton variant="rectangular" height={800} />;

            return (
              <>
                <iframe
                  src={url || undefined}
                  title={`${reportData.dashboard.name}.pdf`}
                  height="800px"
                  width="100%"
                />
                <BlobUpdate blob={blob} />
              </>
            );
          }}
        </BlobProvider>
      </ErrorBoundary>

      <Typography variant="caption" sx={{ color: 'textSecondary' }}>
        *{' '}
        {t(
          "If PDF doesn't show, try another browser or quit and restart your current browser."
        )}
      </Typography>
    </>
  );
}
