import { useTranslation } from 'react-i18next';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import LoadingButton from '@mui/lab/LoadingButton';

import { DialogTitle } from '../../../DialogTitle/dialog-title';

export function AreaPermissionsManageConfirmRemoveDialog({
  open,
  setOpen,
  handleRemove,
  loading,
  setLoading,
}: any) {
  const { t } = useTranslation();

  const handleClose = () => {
    setOpen(false);
    setLoading(false);
  };

  return (
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" onClose={handleClose}>
        {t('Remove access')}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {t('Are you sure you want to remove this member?')}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" disabled={loading}>
          {t('Cancel')}
        </Button>
        <LoadingButton
          onClick={handleRemove}
          color="error"
          autoFocus
          variant="contained"
          disableElevation
          loading={loading}
        >
          {t('Remove')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
