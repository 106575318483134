import React from 'react';
import { View, Text, Image } from '@react-pdf/renderer';

import grey from '@mui/material/colors/grey';

import { getStringDate } from '../../../../../_utils/general-utils';
import { ReportsPdfUiBoxed as Boxed } from '../boxed';

export function ReportsPdfUiHeaderOverview({ t, reportData, report }: any) {
  const { reportsTemplateMeta } = React.useMemo(
    () => reportData.dashboard,
    [reportData.dashboard]
  );
  const countryFocalPoint = React.useMemo(
    () =>
      reportsTemplateMeta.find((meta: any) => meta?.key === 'countryFocalPoint')
        ?.value || '',
    [reportsTemplateMeta]
  );
  const countryLeadId = React.useMemo(
    () =>
      reportsTemplateMeta.find((meta: any) => meta?.key === 'countryLead')
        ?.value || '',
    [reportsTemplateMeta]
  );
  const countryFlag = React.useMemo(
    () =>
      reportsTemplateMeta.find((meta: any) => meta?.key === 'countryFlag')
        ?.file || '',
    [reportsTemplateMeta]
  );

  const countryLead = React.useMemo(
    () =>
      reportData.dashboard.users
        .map((user: any) => user.id)
        .includes(countryLeadId)
        ? reportData.dashboard.users.find(
            (user: any) => user.id === countryLeadId
          ).name
        : countryLeadId,
    [reportData.dashboard.users, countryLeadId]
  );

  return (
    <View>
      {/* title and flag */}
      <View
        style={{
          backgroundColor: reportData.dashboard.colorPalette.primary.bgcolor,
          color: reportData.dashboard.colorPalette.primary.textColor,
          marginBottom: '15px',
          width: '100vw',
          marginLeft: '-1cm',
          marginTop: '-1cm',
        }}
      >
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            minHeight: '50px',
            justifyContent: 'center',
            alignContent: 'center',
            alignItems: 'center',
            marginHorizontal: '1cm',
            paddingVertical: '0.7cm',
          }}
        >
          {!!reportData.dashboard.logo && (
            <Image
              style={{
                position: 'absolute',
                left: '0',
                height: '50px',
                width: '50px',
              }}
              source={{
                uri: reportData.dashboard.logo,
                headers: {
                  'Access-Control-Allow-Origin': window.location.origin,
                  'cache-control': 'no-cache',
                },
                method: 'GET',
                body: '',
              }}
            />
          )}
          <Text
            style={{
              marginRight: '2cm',
              marginLeft: '2cm',
              fontSize: '22px',
              fontWeight: 'bold',
            }}
          >
            {reportData.dashboard.name}
          </Text>
          {!!countryFlag && (
            <Image
              style={{
                position: 'absolute',
                right: '0',
                height: '50px',
                width: '100px',
              }}
              source={{
                uri: countryFlag.url,
                headers: {
                  'Access-Control-Allow-Origin': window.location.origin,
                  'cache-control': 'no-cache',
                },
                method: 'GET',
                body: '',
              }}
            />
          )}
        </View>
      </View>

      <View style={{ marginBottom: '15px' }}>
        <Text>
          {/* report date */}
          <Text
            style={{
              fontWeight: 'bold',
            }}
          >
            {t('Report Date')}:{' '}
          </Text>
          {getStringDate(
            report?.updatedAt || new Date().toString(),
            reportData.dashboard.language
          )}

          {/* period */}
          {(report?.filterStartDate || report?.filterEndDate) && (
            <Text style={{ fontSize: '8px', color: grey[800] }}>
              <Text style={{ paddingHorizontal: '15px' }}> . </Text>
              <Text
                style={{
                  fontWeight: 'bold',
                }}
              >
                {t('Period')}:{' '}
              </Text>
              {report?.filterStartDate
                ? getStringDate(
                    report?.filterStartDate,
                    reportData.dashboard.language,
                    true
                  )
                : ''}
              {' - '}
              {report?.filterEndDate
                ? getStringDate(
                    report?.filterEndDate,
                    reportData.dashboard.language,
                    true
                  )
                : ''}
            </Text>
          )}
        </Text>
      </View>

      {/* country + leads */}

      <View
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        {!!reportData.dashboard.country && (
          <Boxed
            width="32%"
            spacing={1}
            title={t('Country')}
            content={reportData.dashboard.country}
          />
        )}
        {!!countryFocalPoint && (
          <Boxed
            width="32%"
            spacing={1}
            title={t('Focal Point')}
            content={countryFocalPoint}
          />
        )}
        {!!countryLead && (
          <Boxed
            width="32%"
            spacing={1}
            title={t('Country Lead')}
            content={countryLead}
          />
        )}
      </View>

      {/* goal */}
      {!!reportData.dashboard.goal && (
        <View style={{ marginTop: '15px' }}>
          <Boxed
            width="100%"
            widthRatio={[1, 3]}
            spacing={2}
            title={t('Goal')}
            content={reportData.dashboard.goal}
          />
        </View>
      )}
    </View>
  );
}
