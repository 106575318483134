import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';

import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

import { useDashboardContext } from '../../_lib/context/dashboard-context';
import { ReactErrorComponent } from '../../_lib/react-error';

import { getStringDate, withCommas } from '../../_utils/general-utils';
import { TargetDialogRouter } from '../../_lib/routers/target-dialog-router';

import { LinearProgressWithLabel } from '../linear-progress/with-label';
import { getTargetRouteId } from '../../_utils/targets-utils';

export function TargetsSummaryTableRow({
  target,
  area,
  includePriorityAreas,
}: any) {
  const { t } = useTranslation();

  const location = useLocation();
  const history = useHistory();

  const { dashboard } = useDashboardContext();

  // ------------------------------------------------------------------------------------------------------
  // ------------------------------------------------------------------------------------------------------
  // handlers
  // ------------------------------------------------------------------------------------------------------

  const handleTargetClick = () => {
    const params = new URLSearchParams(location.search);
    params.set('target', getTargetRouteId(target, area));
    history.push(`${location.pathname}?${params.toString()}`);
  };

  // ------------------------------------------------------------------------------------------------------

  return (
    <ErrorBoundary FallbackComponent={ReactErrorComponent}>
      <TargetDialogRouter
        target={target}
        container={area}
        includePriorityAreas={includePriorityAreas}
      />

      <TableRow
        sx={{
          '&:last-child td, &:last-child th': { border: 0 },
          cursor: 'pointer',
        }}
        onClick={(event) => {
          event.preventDefault();
          event.stopPropagation();
          handleTargetClick();
        }}
        hover
      >
        <TableCell component="th" scope="row">
          <Typography variant="body2">{target.targetUnit?.name}</Typography>
          {dashboard.enableTargetCategories &&
            !!target.targetUnit?.category && (
              <Typography variant="caption" color="textSecondary">
                <b>{t('Category')}:</b> {target.targetUnit?.category}
              </Typography>
            )}
        </TableCell>
        {dashboard.enableTargetBaselineValue && (
          <TableCell align="left">
            {withCommas(target.startAmount, dashboard.language)}
          </TableCell>
        )}
        <TableCell align="left">
          {withCommas(target.targetAmount, dashboard.language)}
          <br />
          <Typography variant="caption" color="textSecondary">
            {t('Deadline')}:{' '}
            {getStringDate(target.deadline, dashboard.language, true)}
          </Typography>
        </TableCell>
        {dashboard.enableTargetFinancedAmount && (
          <TableCell align="left">
            {withCommas(target.targetFinancedAmount, dashboard.language)}
          </TableCell>
        )}
        <TableCell align="left">
          {withCommas(target.latestAmount, dashboard.language)}
          <br />
          <Typography variant="caption" color="textSecondary">
            {t('Updated')}:{' '}
            {getStringDate(target.latestDate, dashboard.language, true)}
          </Typography>
        </TableCell>
        <TableCell align="left">
          <LinearProgressWithLabel
            sx={{ height: '15px', borderRadius: '2px' }}
            value={target.progressPerc}
            labelTypographyProps={{
              variant: 'caption',
            }}
          />
        </TableCell>
      </TableRow>
    </ErrorBoundary>
  );
}
