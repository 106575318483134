import React from 'react';

import Divider from '@mui/material/Divider';
import Skeleton from '@mui/material/Skeleton';

import { ReactErrorComponent } from '../_lib/react-error';

import { AreaTargets } from '../components/area-targets';

import { useSummary } from '../_utils/hooks/queries/use-summary';

export function InterventionTargets({ intervention }: any) {
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [page, setPage] = React.useState(0);
  const { loading, error, data } = useSummary(
    'targets',
    {
      interventionId: intervention.id,
      paginationTargetsLimit: rowsPerPage,
      paginationTargetsOffset: page * rowsPerPage,
    },
    'intervention'
  );

  if (error) return <ReactErrorComponent error={error} />;

  if (loading || !data) {
    return <Skeleton variant="rectangular" height={300} sx={{ my: 3 }} />;
  }

  if (!data.intervention?.summary?.targets?.targetsByUnit?.meta.count)
    return null;

  return (
    <>
      <Divider sx={{ my: 4 }} />
      <AreaTargets
        area={data.intervention}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
        page={page}
        setPage={setPage}
      />
    </>
  );
}
