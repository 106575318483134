import React from 'react';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';

import AddIcon from '@mui/icons-material/Add';

import { DashboardContext } from '../../../../_lib/context/dashboard-context';

import { DialogTitle } from '../../../../DialogTitle/dialog-title';
import { AdvancedAreaFieldsDetail } from './detail';

export function AdvancedAreaFieldsDialog({ open, setOpen }: any) {
  const { t } = useTranslation();

  const { dashboard } = React.useContext(DashboardContext);

  // -------------------------------------------------------------------------------------------------------

  const areaFieldConfigKeys = React.useMemo(
    () => Array.from({ length: 10 }, (_, i) => `areaField${i + 1}Config`),
    []
  );
  const countNotNullConfig = React.useMemo(
    () =>
      areaFieldConfigKeys.reduce(
        (acc, key) => (dashboard[key] ? acc + 1 : acc),
        0
      ),
    [dashboard, areaFieldConfigKeys]
  );
  const nextNullConfigKey = React.useMemo(
    () => areaFieldConfigKeys.find((key) => !dashboard[key]),
    [dashboard, areaFieldConfigKeys]
  ) as string;

  // -------------------------------------------------------------------------------------------------------

  const [configs, setConfigs] = React.useState<any>(
    areaFieldConfigKeys.reduce(
      (acc, key) => ({
        ...acc,
        [key]: dashboard[key] ? { ...dashboard[key], inEdit: false } : null,
      }),
      {}
    )
  );
  const [newConfigs, setNewConfigs] = React.useState<any>({});

  // -------------------------------------------------------------------------------------------------------
  // -------------------------------------------------------------------------------------------------------
  // handlers
  // -------------------------------------------------------------------------------------------------------

  const addNew = () => {
    setNewConfigs({
      ...newConfigs,
      [nextNullConfigKey]: {
        label: '',
        fieldType: 'TEXT',
        inEdit: true,
        required: false,
        inPriorityArea: false,
        inIntervention: true,
      },
    });
  };

  // -------------------------------------------------------------------------------------------------------
  // -------------------------------------------------------------------------------------------------------
  // effects
  // -------------------------------------------------------------------------------------------------------

  React.useEffect(() => {
    setConfigs(
      areaFieldConfigKeys.reduce(
        (acc, key) => ({
          ...acc,
          [key]: dashboard[key]
            ? { ...dashboard[key], inEdit: configs[key]?.inEdit || false }
            : null,
        }),
        {}
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [areaFieldConfigKeys, dashboard]);

  // -------------------------------------------------------------------------------------------------------

  return (
    <Dialog
      open={open}
      aria-labelledby="form-dialog-title"
      fullWidth
      maxWidth="xs"
    >
      <DialogTitle id="form-dialog-title" onClose={() => setOpen(false)}>
        {t('Manage Area Fields')}
      </DialogTitle>
      <DialogContent>
        <Box>
          <Typography variant="body1" gutterBottom>
            {t(
              'Manage the custom fields available to your main areas and sub-areas.'
            )}
          </Typography>
          <Alert severity="info">
            {t(
              'These fields should not include budget, currently spent, classifications, status, regions, stakeholders, programme, financiers, and targets. These fields are already available.'
            )}
          </Alert>

          {/* list */}
          <Stack spacing={2} sx={{ mt: 2.5 }}>
            {/* current */}
            {Object.keys(configs).map((key) => {
              if (!configs[key]) return null;
              return (
                <AdvancedAreaFieldsDetail
                  key={key}
                  config={{ key, ...configs[key] }}
                  configs={configs}
                  setConfigs={setConfigs}
                />
              );
            })}

            {/* new */}
            {Object.keys(newConfigs).map((key) => {
              if (!newConfigs[key]) return null;
              return (
                <AdvancedAreaFieldsDetail
                  key={key}
                  config={{ key, ...newConfigs[key] }}
                  configs={newConfigs}
                  setConfigs={setNewConfigs}
                  isNew
                />
              );
            })}

            {/* add button */}
            <Button
              startIcon={<AddIcon />}
              fullWidth
              sx={{ justifyContent: 'flex-start' }}
              disabled={countNotNullConfig >= 10}
              onClick={addNew}
            >
              {t('Add Area Field')}
            </Button>
          </Stack>

          {/* more than 10 alert */}
          {countNotNullConfig >= 10 && (
            <Alert severity="warning" sx={{ mt: 2 }}>
              {t(
                'You have reached the maximum number of custom fields. Please delete one to add a new one.'
              )}
            </Alert>
          )}
        </Box>
      </DialogContent>
    </Dialog>
  );
}
