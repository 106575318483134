import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import TableChartIcon from '@mui/icons-material/TableChart';
// eslint-disable-next-line import/no-named-default
import { default as MuiCardContent } from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';
import { useTranslation } from 'react-i18next';
import { useHistory, Link as RouterLink } from 'react-router-dom';
import { useDashboardContext } from '../../../../_lib/context/dashboard-context';

type ContentItemsType = {
  leads: any[];
  coLeads: any[];
  levelNext: any;
  interventions: any[];
  interventionsCount: number;
  activities: any[];
  children: any[];
  childrenCount: number;
  urlRelative: string;
  totalActivitiesCount: number;
};

function CardContent({
  simple,
  contentItems,
  isChildInfo,
}: {
  contentItems: ContentItemsType;
  simple?: any;
  isChildInfo?: boolean;
}) {
  const { t } = useTranslation();
  const {
    dashboard: { interventionName },
  } = useDashboardContext();
  const history = useHistory();
  const {
    leads,
    coLeads,
    levelNext,
    urlRelative,
    children,
    childrenCount,
    interventionsCount,
    totalActivitiesCount,
  } = contentItems;

  if (isChildInfo) {
    return (
      <MuiCardContent sx={{ height: '100%', position: 'relative' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            position: 'relative',
            minHeight: '100%',
          }}
        >
          <Box component="ul" sx={{ height: 'fit-content' }}>
            {children.map((child: any) => {
              return (
                <Box component="li" key={child.id}>
                  <Typography
                    variant="body1"
                    sx={{ textTransform: 'uppercase', fontWeight: '600' }}
                  >
                    <Link
                      sx={{ color: 'inherit', textDecoration: 'underline' }}
                      component={RouterLink}
                      to={`/executive${child.urlRelative}`}
                    >
                      {child.reference}. {child.name}
                    </Link>
                  </Typography>
                </Box>
              );
            })}
          </Box>
          {/* <Box>
            <Divider sx={{ my: 2 }} />
            <Button
              variant="contained"
              onClick={() => null}
              sx={{
                mt: 1,
                width: 'fit-content',
              }}
              size="small"
            >
              {t('Key Performance Indicators / Targets')}
            </Button>
          </Box> */}
        </Box>
      </MuiCardContent>
    );
  }

  return (
    <MuiCardContent sx={{ height: '100%' }}>
      <Typography variant="body1" component="p" fontSize="16px" width="100%">
        <Typography variant="body1" component="span" fontWeight="600">
          {t('Leads')}:
        </Typography>{' '}
        {leads.map((l: any) => l.name).join(', ')}
      </Typography>
      <Typography variant="body1" component="p" fontSize="16px" width="100%">
        <Typography variant="body1" component="span" fontWeight="600">
          {t('Co-Leads')}:
        </Typography>{' '}
        {coLeads.map((l: any) => l.name).join(', ')}
      </Typography>
      <Stack flexDirection="row" mt={2}>
        {levelNext && (
          <Typography
            variant="body1"
            component="p"
            fontSize="16px"
            width="100%"
          >
            <Typography
              variant="body1"
              component="span"
              fontSize="16px"
              fontWeight="600"
            >
              {levelNext?.name}:
            </Typography>{' '}
            {childrenCount}
          </Typography>
        )}
        <Typography variant="body1" component="p" fontSize="16px" width="100%">
          <Typography
            variant="body1"
            component="span"
            fontSize="16px"
            fontWeight="600"
          >
            {interventionName}:
          </Typography>{' '}
          {interventionsCount}
        </Typography>
        <Typography variant="body1" component="p" fontSize="16px" width="100%">
          <Typography
            variant="body1"
            component="span"
            fontSize="16px"
            fontWeight="600"
          >
            {t('Activities')}:
          </Typography>{' '}
          {totalActivitiesCount}
        </Typography>
      </Stack>
      <Divider sx={{ my: 2 }} />
      {/* open activity tracker link */}
      <Button
        variant="contained"
        startIcon={<TableChartIcon />}
        onClick={() => history.push(`${urlRelative}${t('/tracker')}`)}
        disableElevation
        sx={{ displayPrint: 'none', mt: 1 }}
        size="small"
      >
        {t('Open Activity Tracker')}
      </Button>
    </MuiCardContent>
  );
}

CardContent.defaultProps = {
  simple: undefined,
  isChildInfo: false,
};

export default CardContent;
