import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import enTranslations from './en-translations.json';
import frenchTranslations from './fr-translations.json';
import portugueseTranslations from './pt-translations.json';
import serbianTranslations from './sr-translations.json';
import swahiliTranslations from './sw-translations.json';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: enTranslations,
      fr: frenchTranslations,
      pt: portugueseTranslations,
      sr: serbianTranslations,
      sw: swahiliTranslations,
    },

    fallbackLng: 'en',

    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },

    saveMissing: true,
    missingKeyHandler: (lng, ns, key, fallbackValue) => {
      // enable this to see missing translations in the console
      if (
        key.startsWith('/') &&
        !['/edit', '/report', '/planning', '/dashboard'].includes(key)
      )
        return;

      // strange bug??
      if (ns === '/') return;

      if (process.env.NODE_ENV === 'development') {
        console.log(
          `Missing translation: ${lng} - ${ns} - ${key} - ${fallbackValue}`
        );
      }
    },
  });

export default i18n;
