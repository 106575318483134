import React from 'react';
import Typography from '@mui/material/Typography';

import Skeleton from '@mui/material/Skeleton';
import { useDashboardContext } from '../../../_lib/context/dashboard-context';
import { useSummary } from '../../../_utils/hooks/queries/use-summary';

export function AreaSummaryCardCounts({ area }: any) {
  const { dashboard } = useDashboardContext();

  const { data, loading, error } = useSummary(
    'counts',
    {
      priorityAreaId: area.id,
    },
    'priorityArea'
  );

  const descendantLevelNames = React.useMemo(() => {
    if (!dashboard.enableAreaLevels || !area.levelNext) return '';
    const nextLevels: any = [];
    dashboard.areaLevels.forEach((level: any) => {
      if (nextLevels.length !== 0 || level.id === area.levelNext?.id) {
        nextLevels.push(level.name);
      }
    });
    return nextLevels.join(', ');
  }, [area.levelNext, dashboard.areaLevels, dashboard.enableAreaLevels]);

  const descendantsCount = React.useMemo(() => {
    if (!data?.priorityArea?.summary?.priorityAreasCount) return 0;
    return data.priorityArea.summary.priorityAreasCount - 1;
  }, [data]);

  if (error) return null;

  if (loading || !data)
    return (
      <Typography variant="body2">
        <Skeleton />
      </Typography>
    );

  if (!data.priorityArea?.summary) return null;

  return (
    <>
      {dashboard.enableAreaLevels && !!area.levelNext && (
        <Typography variant="body2">
          {descendantLevelNames}: {descendantsCount}
        </Typography>
      )}
      <Typography variant="body2">
        {dashboard.interventionName}:{' '}
        {data.priorityArea.summary.interventionsCount || 0}
      </Typography>
    </>
  );
}
