import React from 'react';
import { useTranslation } from 'react-i18next';

import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

import EditIcon from '@mui/icons-material/Edit';

import { ContentGroupsManageDialog } from './dialog';

import { AdvancedContentGroupsActivateWeights } from './activate-weights';

export function ContentGroupsManage({ enabled }: any) {
  const { t } = useTranslation();

  const [openDialog, setOpenDialog] = React.useState(false);

  if (!enabled) return null;

  return (
    <>
      <ContentGroupsManageDialog open={openDialog} setOpen={setOpenDialog} />

      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ mr: 1.5 }}>
          <Button
            variant="outlined"
            size="small"
            onClick={() => setOpenDialog(true)}
          >
            {t('Manage Activity Phases')}
          </Button>
        </Box>

        <AdvancedContentGroupsActivateWeights />
      </Box>
    </>
  );
}

export function ContentGroupsManageSmall({ disabled }: { disabled?: boolean }) {
  const { t } = useTranslation();

  const [openDialog, setOpenDialog] = React.useState(false);

  return (
    <>
      <ContentGroupsManageDialog
        open={openDialog}
        setOpen={setOpenDialog}
        disableEnforceFocus
      />

      <Button
        variant="outlined"
        size="small"
        onClick={() => setOpenDialog(true)}
        startIcon={<EditIcon />}
        disabled={disabled}
      >
        {t('Edit')}
      </Button>
    </>
  );
}

ContentGroupsManageSmall.defaultProps = {
  disabled: false,
};
