import React from 'react';

import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import { AdvancedOrderSummaryItemsDialog } from './dialog';

export function AdvancedOrderSummaryItems({ enabled }: any) {
  const { t } = useTranslation();

  const [open, setOpen] = React.useState(false);

  if (!enabled) return null;

  return (
    <>
      <AdvancedOrderSummaryItemsDialog open={open} setOpen={setOpen} />

      <Button variant="outlined" size="small" onClick={() => setOpen(true)}>
        {t('Re-order Summary Items')}
      </Button>
    </>
  );
}
