import React from 'react';
import { useTranslation } from 'react-i18next';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import DoneIcon from '@mui/icons-material/Done';
import FlagIcon from '@mui/icons-material/Flag';

import { useDashboardContext } from '../_lib/context/dashboard-context';
import { useContentDialogContext } from '../_lib/context/content-dialog-context';

export function ContentAddMenu() {
  const { t } = useTranslation();

  const { user, dashboard } = useDashboardContext();

  const { anchorElAddMenu, setAnchorElAddMenu, setContentForm } =
    useContentDialogContext();

  const open = React.useMemo(() => Boolean(anchorElAddMenu), [anchorElAddMenu]);

  // ----------------------------------------------------------------------------------------------------------------

  // ----------------------------------------------------------------------------------------------------------------
  // ----------------------------------------------------------------------------------------------------------------
  // handlers
  // ----------------------------------------------------------------------------------------------------------------

  const handleClose = React.useCallback(() => {
    setAnchorElAddMenu(null);
  }, [setAnchorElAddMenu]);

  const handleOpen = React.useCallback(
    (type: string) => {
      setContentForm({
        defaultContentType: type,
        open: true,
      });
      handleClose();
    },
    [handleClose, setContentForm]
  );

  // ----------------------------------------------------------------------------------------------------------------
  // ----------------------------------------------------------------------------------------------------------------
  // effects
  // ----------------------------------------------------------------------------------------------------------------

  // ----------------------------------------------------------------------------------------------------------------

  const buttonsObj = React.useMemo(
    () => ({
      issue: (
        <MenuItem onClick={() => handleOpen('issue')} key="issue">
          <ListItemIcon>
            <ErrorOutlineIcon fontSize="small" color="warning" />
          </ListItemIcon>
          <ListItemText>{t('New Issue')}</ListItemText>
        </MenuItem>
      ),
      activity: (
        <MenuItem onClick={() => handleOpen('activity')} key="activity">
          <ListItemIcon>
            <DoneIcon fontSize="small" color="success" />
          </ListItemIcon>
          <ListItemText>{t('New Activity')}</ListItemText>
        </MenuItem>
      ),
      milestone: (
        <MenuItem onClick={() => handleOpen('milestone')} key="milestone">
          <ListItemIcon>
            <FlagIcon fontSize="small" color="info" />
          </ListItemIcon>
          <ListItemText>{t('New Milestone')}</ListItemText>
        </MenuItem>
      ),
    }),
    [handleOpen, t]
  );

  const buttons = React.useMemo(() => {
    const buttonsNew = [buttonsObj.issue];
    if (dashboard.mode === 'TRACKER' || user.hasAnyInterventionEditPermission) {
      buttonsNew.push(buttonsObj.activity);
      if (dashboard.mode !== 'TRACKER') buttonsNew.push(buttonsObj.milestone);
    }
    return buttonsNew;
  }, [buttonsObj, dashboard.mode, user.hasAnyInterventionEditPermission]);

  // ----------------------------------------------------------------------------------------------------------------

  return (
    <Menu
      id="content-add-menu"
      anchorEl={anchorElAddMenu}
      open={open}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      {buttons.map((button) => button)}
    </Menu>
  );
}
