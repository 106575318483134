import React from 'react';
import { gql, useQuery } from '@apollo/client';

import Skeleton from '@mui/material/Skeleton';

import { logError } from '../../../_lib/error';
import { SubscribeToMore } from '../../subscribe-to-more';

import { CONTENT_STATUS_FRAGMENT } from '../../../_lib/graphql/fragments';

import { AreaSummaryBarChart } from '../bar-chart';

const INTV_FRAGMENT = gql`
  ${CONTENT_STATUS_FRAGMENT}
  fragment IntvFragment on InterventionType {
    id
    summary {
      id
      contentSummary {
        ...ContentStatusFragment
      }
    }
  }
`;

const GET_INTV_QUERY = gql`
  ${INTV_FRAGMENT}
  query PriorityAreaSummaryInterventionsActivitiesBar($id: ID!) {
    intervention(interventionId: $id) {
      ...IntvFragment
    }
  }
`;

const GET_INTV_SUBSCRIPTION = gql`
  ${INTV_FRAGMENT}
  subscription PriorityAreaSummaryInterventionsActivitiesBar($id: ID!) {
    intervention(interventionId: $id) {
      ...IntvFragment
    }
  }
`;

export function AreaSummaryInterventionsActivitiesBar({
  intervention,
  height,
  borderRadius,
}: {
  intervention: any;
  height?: number | string;
  borderRadius?: number | string;
}) {
  const { data, loading, error, subscribeToMore, refetch } = useQuery(
    GET_INTV_QUERY,
    {
      variables: { id: intervention.id },
    }
  );

  // --------------------------------------------------------------------------------------------------------
  // --------------------------------------------------------------------------------------------------------
  // effects
  // --------------------------------------------------------------------------------------------------------

  // loading
  React.useEffect(() => {
    if (error) logError(error);
  }, [error]);

  // --------------------------------------------------------------------------------------------------------

  if (error) return null;

  if (loading || !data) {
    if (!data && !loading) refetch();
    return <Skeleton height={height} variant="text" sx={{ flexGrow: 1 }} />;
  }

  // --------------------------------------------------------------------------------------------------------

  return (
    <>
      <AreaSummaryBarChart
        height={height}
        borderRadius={borderRadius}
        contentSummary={data.intervention.summary.contentSummary}
      />

      {/* subscribe to more */}
      <SubscribeToMore
        subscribeToMore={() =>
          subscribeToMore({
            document: GET_INTV_SUBSCRIPTION,
            variables: { id: intervention.id },
            updateQuery: (prev, { subscriptionData }) => {
              if (!subscriptionData.data) return prev;
              return subscriptionData.data;
            },
          })
        }
      />
    </>
  );
}

AreaSummaryInterventionsActivitiesBar.defaultProps = {
  height: '7px',
  borderRadius: '3px',
};
