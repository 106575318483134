import React from 'react';
import { useTranslation } from 'react-i18next';

import { useAuth } from '../../_utils/hooks/use-auth';
import { ScreenInfoLoading } from '../_info/loading';

export function ScreenAuthLogout() {
  const { t } = useTranslation();
  const { signOut } = useAuth();

  React.useEffect(() => {
    signOut().finally(() => {
      window.location.replace('/');
    });
  });

  return <ScreenInfoLoading message={t('Logging out')} />;
}
