import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Pagination from '@mui/material/Pagination';

import { Target } from './target';

export function MapTargets({
  targetsSummary,
  container,
  rowsPerPage,
  page,
  setPage,
}: any) {
  return (
    <>
      <Grid container spacing={2} justifyContent="center">
        {targetsSummary.targetsByUnit.items.map((tgt: any) => (
          <Target key={tgt.id} target={tgt} container={container} />
        ))}
      </Grid>

      {/* pagination */}
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
        <Pagination
          count={Math.ceil(
            targetsSummary.targetsByUnit.meta.count / rowsPerPage
          )}
          page={page + 1}
          onChange={(e, pg) => setPage(pg - 1)}
        />
      </Box>
    </>
  );
}
