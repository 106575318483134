import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Stack from '@mui/material/Stack';

import { DialogTitle } from '../../../../DialogTitle/dialog-title';
import { DashboardContext } from '../../../../_lib/context/dashboard-context';
import { DASHBOARD_UPDATE } from '../../../../_lib/graphql/mutations';
import { logError } from '../../../../_lib/error';

import { AdvancedDashboardOverviewOption } from './option';

export function AdvancedDashboardSummaryDialog({ open, setOpen }: any) {
  const { t } = useTranslation();

  const { dashboard, setSnackbarOpen, setSnackbarIsError } =
    React.useContext(DashboardContext);

  const [enabled, setEnabled] = React.useState<any>({
    homeOverviewInterventionsCountDistinct:
      dashboard.homeOverviewInterventionsCountDistinct,
    homeOverviewEnableFilter: dashboard.homeOverviewEnableFilter,
    homeOverviewShowInterventionsDetails:
      dashboard.homeOverviewShowInterventionsDetails,
    homeOverviewShowTargets: dashboard.homeOverviewShowTargets,
  });
  const [loading, setLoading] = React.useState<any>({});

  const [dashboardUpdateMutation] = useMutation(DASHBOARD_UPDATE);

  // -------------------------------------------------------------------------------------------------------------------
  // -------------------------------------------------------------------------------------------------------------------
  // handlers
  // -------------------------------------------------------------------------------------------------------------------

  const handleChecked = ({
    homeOverviewInterventionsCountDistinct,
    homeOverviewEnableFilter,
    homeOverviewShowInterventionsDetails,
    homeOverviewShowTargets,
  }: any) => {
    const inputs = {
      homeOverviewInterventionsCountDistinct,
      homeOverviewEnableFilter,
      homeOverviewShowInterventionsDetails,
      homeOverviewShowTargets,
    } as any;

    setLoading(inputs);
    const initialEnabled = { ...enabled };

    Object.keys(inputs).forEach((key) => {
      if (inputs[key] !== undefined) {
        setEnabled({ ...enabled, [key]: inputs[key] });
      }
    });

    dashboardUpdateMutation({
      variables: {
        ...inputs,
      },
    })
      .catch((err) => {
        setEnabled(initialEnabled);
        logError(err);
        setSnackbarIsError(true);
      })
      .finally(() => {
        setLoading({});
        setSnackbarOpen(false);
      });
  };

  // -------------------------------------------------------------------------------------------------------------------

  return (
    <Dialog open={open} maxWidth="xs" fullWidth>
      <DialogTitle onClose={() => setOpen(false)}>
        {t('Dashboard Overview Options')}
      </DialogTitle>
      <DialogContent dividers>
        <Stack spacing={2}>
          {/* filter */}
          <AdvancedDashboardOverviewOption
            enabled={enabled}
            handleChecked={handleChecked}
            loading={loading}
            optionKey="homeOverviewEnableFilter"
            title={t('Enable Filter')}
            subtitle={t(
              'Enable dashboard overview filter. This will allow you to filter the dashboard overview by sub-area.'
            )}
          />

          {/* intervention distinct count */}
          <AdvancedDashboardOverviewOption
            enabled={enabled}
            handleChecked={handleChecked}
            loading={loading}
            optionKey="homeOverviewInterventionsCountDistinct"
            title={`${dashboard.interventionName} ${t('Distinct Count')}`}
            subtitle={t(
              'Enable distinct count of sub-areas in the aggregation. This is useful for sub-area names that appear in multiple main areas.'
            )}
          />

          {/* show interventions details */}
          <AdvancedDashboardOverviewOption
            enabled={enabled}
            handleChecked={handleChecked}
            loading={loading}
            optionKey="homeOverviewShowInterventionsDetails"
            title={`${dashboard.interventionName} ${t('Details')}`}
            subtitle={t(
              'Enable this to show the interventions details in the dashboard overview.'
            )}
          />

          {/* show targets */}
          <AdvancedDashboardOverviewOption
            enabled={enabled}
            handleChecked={handleChecked}
            loading={loading}
            optionKey="homeOverviewShowTargets"
            title={t('Targets')}
            subtitle={t(
              'Enable this to show targets in the dashboard summary.'
            )}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={() => setOpen(false)}>
          {t('Close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
