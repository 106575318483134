import React from 'react';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import SaveIcon from '@mui/icons-material/Save';
import LoadingButton from '@mui/lab/LoadingButton';

import { useDashboardContext } from '../../../_lib/context/dashboard-context';
import { logError } from '../../../_lib/error';
import { getGraphQLDate } from '../../../_utils/general-utils';

import { INTERVENTION_SETUP } from '../../../_lib/graphql/mutations';
import {
  INTERVENTIONS_PLANNING_QUERY,
  INTERVENTION_PLANNING_QUERY,
  INTERVENTION_QUERY,
} from '../../../_lib/graphql/queries';

export function PlanningInterventionFormActionsSave({
  valid,

  intervention,

  newFinanciers,
  setNewFinanciers,
  newTargets,
  setNewTargets,

  loading,
  setLoading,
  setDisabled,
}: any) {
  const { t } = useTranslation();

  const { priorityAreaId } = useParams<any>();

  const { setSnackbarOpen, setSnackbarIsError, setSnackbarMessage } =
    useDashboardContext();

  const [setupInterventionMutation] = useMutation(INTERVENTION_SETUP, {
    update: (cache, { data }) => {
      cache.writeQuery({
        query: INTERVENTIONS_PLANNING_QUERY,
        data: { interventions: data.interventionSetup.interventions },
        variables: {
          priorityAreaId,
        },
      });

      cache.writeQuery({
        query: INTERVENTION_PLANNING_QUERY,
        data: { intervention: data.interventionSetup.intervention },
        variables: {
          interventionId: data.interventionSetup.intervention.id,
        },
      });

      cache.writeQuery({
        query: INTERVENTION_QUERY,
        data: { intervention: data.interventionSetup.intervention },
        variables: {
          interventionId: data.interventionSetup.intervention.id,
        },
      });
    },
  });

  const handleSubmit = React.useCallback(() => {
    setLoading(true);

    if (valid()) {
      // combine financiers and targets
      const financiers = intervention.financiersDb?.concat(newFinanciers) || [];
      const targets = intervention.targetsDb?.concat(newTargets) || [];

      const inputFieldItem = (item: any) => {
        const newItem = structuredClone(item);
        delete newItem.__typename;
        delete newItem.chip;
        return newItem;
      };

      // variablles setting
      const variables: any = {
        id: intervention.id,
        priorityAreaId: intervention.priorityArea.id,

        reference: intervention.reference?.trim(),
        name: intervention.name.trim(),
        description: intervention.description?.trim(),

        leadId: intervention.lead?.userSettingsId || intervention.lead?.id,
        coLeadId:
          intervention.coLead?.userSettingsId || intervention.coLead?.id,
        leadsIds: intervention.leads?.map((l: any) => l.userSettingsId || l.id),
        coLeadsIds: intervention.coLeads?.map(
          (l: any) => l.userSettingsId || l.id
        ),
        leadsFreeText: intervention.leadsFreeText,
        coLeadsFreeText: intervention.coLeadsFreeText,

        budget: parseFloat(intervention.budgetDb) || 0,
        currentlySpent: parseFloat(intervention.currentlySpentDb) || 0,
        currency: intervention.currency || null,

        programmeId: intervention.programme?.id || 0,
        statusId: intervention.status?.id || 0,

        classifications: intervention.classifications.map(inputFieldItem),
        regions: intervention.regions.map(inputFieldItem),

        stakeholders: intervention.stakeholders.map(inputFieldItem),

        areaFieldValues: intervention.areaFieldValues.map((field: any) => {
          const newField = structuredClone(field);

          newField.configId = newField.config.id;
          newField.date = newField.date
            ? getGraphQLDate(newField.date)
            : undefined;
          newField.number = newField.number
            ? Number.parseFloat(newField.number)
            : undefined;
          newField.optionsIds = newField.options?.map(
            (option: any) => option.id
          );

          delete newField.__typename;
          delete newField.config;
          delete newField.options;
          delete newField.chip;

          return newField;
        }),

        financiers: financiers
          .filter((financier: any) => financier.amountDb !== undefined)
          .map((financier: any) => {
            const newFinancier = structuredClone(financier);

            newFinancier.amount = parseFloat(newFinancier.amountDb);
            newFinancier.amountSpent =
              parseFloat(newFinancier.amountSpentDb) || 0;
            delete newFinancier.amountDb;
            delete newFinancier.amountSpentDb;

            newFinancier.financierId = newFinancier.financier.id;
            delete newFinancier.financier;
            delete newFinancier.fauxId;
            delete newFinancier.__typename;

            if (newFinancier.financeMethod?.id) {
              newFinancier.financeMethodId = newFinancier.financeMethod.id;
            }

            if (newFinancier.procurementMethod?.id) {
              newFinancier.procurementMethodId =
                newFinancier.procurementMethod.id;
            }

            delete newFinancier.financeMethod;
            delete newFinancier.procurementMethod;

            return newFinancier;
          }),

        targets: targets
          .filter(
            (target: any) =>
              target.targetAmount !== undefined && !!target.targetUnit?.id
          )
          .map((target: any) => {
            const tgt = structuredClone(target);

            delete tgt.fauxId;
            delete tgt.updates;

            tgt.startedOn = getGraphQLDate(tgt.startedOn);
            tgt.deadline = getGraphQLDate(tgt.deadline);

            tgt.startAmount = Number.parseFloat(tgt.startAmount || 0);
            tgt.targetAmount = Number.parseFloat(tgt.targetAmount || 0);
            tgt.targetFinancedAmount = Number.parseFloat(
              tgt.targetFinancedAmount || 0
            );

            if (tgt.targetUnit?.id && tgt.targetUnit?.id === 'new') {
              tgt.targetUnitId = 0;
              tgt.targetUnitName = tgt.targetUnit?.name;
            } else {
              tgt.targetUnitId = tgt.targetUnit?.id;
            }
            delete tgt.targetUnit;

            delete tgt.__typename;

            return tgt;
          }),
      };

      setupInterventionMutation({ variables })
        .then(() => {
          setNewFinanciers([]);
          setNewTargets([]);

          setDisabled(true);
          setSnackbarOpen(true);
        })
        .catch((err) => {
          logError(err);
          setSnackbarIsError(true);
          setSnackbarOpen(true);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setSnackbarMessage(t('Please fill in all required fields'));
      setSnackbarIsError(true);
      setSnackbarOpen(true);
      setLoading(false);
    }
  }, [
    intervention,
    newFinanciers,
    newTargets,
    setDisabled,
    setLoading,
    setNewFinanciers,
    setNewTargets,
    setSnackbarIsError,
    setSnackbarMessage,
    setSnackbarOpen,
    setupInterventionMutation,
    t,
    valid,
  ]);

  return (
    <LoadingButton
      id="confirm"
      variant="contained"
      startIcon={<SaveIcon />}
      onClick={handleSubmit}
      loading={loading}
      disableElevation
    >
      {t('Save')}
    </LoadingButton>
  );
}
