import { useTranslation } from 'react-i18next';
import { DocumentNode, gql } from '@apollo/client';

import { PlanningFieldsInputAutocomplete } from './_autocomplete';

export function useFinancierMethodQueries(
  method_type: 'procurement' | 'finance'
) {
  const queries: {
    [key in typeof method_type]: { [k: string]: DocumentNode };
  } = {
    finance: {
      QUERY_ITEMS: gql`
        query FinanceMethods {
          financeMethods {
            id
            name
          }
        }
      `,
      SUBSCRIBE_ITEMS: gql`
        subscription FinanceMethods {
          financeMethods {
            id
            name
          }
        }
      `,
      CREATE_ITEM: gql`
        mutation FinanceMethodCreate($name: String!) {
          financeMethodCreate(name: $name) {
            financeMethods {
              id
              name
            }
          }
        }
      `,
      UPDATE_ITEM: gql`
        mutation FinanceMethodUpdate($id: ID!, $name: String!) {
          financeMethodUpdate(id: $id, name: $name) {
            success
            message
            financeMethods {
              id
              name
            }
          }
        }
      `,
      DELETE_ITEM: gql`
        mutation FinanceMethodDelete($id: ID!) {
          financeMethodDelete(id: $id) {
            success
            message
            financeMethods {
              id
              name
            }
          }
        }
      `,
      UPDATE_ITEM_ORDER: gql`
        mutation FinanceMethodUpdateOrder($ids: [ID!]!) {
          financeMethodUpdateOrder(ids: $ids) {
            success
            message
            financeMethods {
              id
              name
            }
          }
        }
      `,
    },
    procurement: {
      QUERY_ITEMS: gql`
        query ProcurementMethods {
          procurementMethods {
            id
            name
          }
        }
      `,
      SUBSCRIBE_ITEMS: gql`
        subscription ProcurementMethods {
          procurementMethods {
            id
            name
          }
        }
      `,
      CREATE_ITEM: gql`
        mutation ProcurementMethodCreate($name: String!) {
          procurementMethodCreate(name: $name) {
            procurementMethods {
              id
              name
            }
          }
        }
      `,
      UPDATE_ITEM: gql`
        mutation ProcurementMethodUpdate($id: ID!, $name: String!) {
          procurementMethodUpdate(id: $id, name: $name) {
            success
            message
            procurementMethods {
              id
              name
            }
          }
        }
      `,
      DELETE_ITEM: gql`
        mutation ProcurementMethodDelete($id: ID!) {
          procurementMethodDelete(id: $id) {
            success
            message
            procurementMethods {
              id
              name
            }
          }
        }
      `,
      UPDATE_ITEM_ORDER: gql`
        mutation ProcurementMethodUpdateOrder($ids: [ID!]!) {
          procurementMethodUpdateOrder(ids: $ids) {
            success
            message
            procurementMethods {
              id
              name
            }
          }
        }
      `,
    },
  };

  return { ...queries[method_type] };
}

const stateValues: any = {
  finance: {
    dataKey: 'financeMethods',
    inputLabel: 'Finance Method',
    addLabel: 'Add Finance Method',
    manageLabel: 'Manage Finance Methods',
    opNames: {
      create: 'financeMethodCreate',
      update: 'financeMethodUpdate',
      remove: 'financeMethodDelete',
      reorder: 'financeMethodUpdateOrder',
    },
  },
  procurement: {
    dataKey: 'procurementMethods',
    inputLabel: 'Procurement Method',
    addLabel: 'Add Procurement Method',
    manageLabel: 'Manage Procurement Methods',
    opNames: {
      create: 'procurementMethodCreate',
      update: 'procurementMethodUpdate',
      remove: 'procurementMethodDelete',
      reorder: 'procurementMethodUpdateOrder',
    },
  },
};

export function PlanningFieldsInputFinancierMethods({
  disabled,
  area,
  setArea,
  error,
  label,
  methodType = 'procurement',
}: any) {
  const { t } = useTranslation();
  const {
    QUERY_ITEMS,
    SUBSCRIBE_ITEMS,
    CREATE_ITEM,
    UPDATE_ITEM,
    DELETE_ITEM,
    UPDATE_ITEM_ORDER,
  } = useFinancierMethodQueries(methodType);

  // ----------------------------------------------------------------------------------------

  return (
    <PlanningFieldsInputAutocomplete
      disabled={disabled}
      area={area}
      setArea={setArea}
      GET_ITEMS={QUERY_ITEMS}
      SUBSCRIBE_ITEMS={SUBSCRIBE_ITEMS}
      CREATE_ITEM={CREATE_ITEM}
      createItemOpName={stateValues[methodType].opNames.create}
      UPDATE_ITEM={UPDATE_ITEM}
      updateItemOpName={stateValues[methodType].opNames.update}
      DELETE_ITEM={DELETE_ITEM}
      deleteItemOpName={stateValues[methodType].opNames.remove}
      UPDATE_ITEM_ORDER={UPDATE_ITEM_ORDER}
      updateItemOrderOpName={stateValues[methodType].opNames.reorder}
      dataKey={stateValues[methodType].dataKey}
      manageDialogTitle={t(stateValues[methodType].manageLabel)}
      manageDialogInputLabel={t(stateValues[methodType].inputLabel)}
      manageDialogAddLabel={t(stateValues[methodType].addLabel)}
      label={label}
      sx={{
        flexGrow: 1,
      }}
      disableClearable
      error={error}
    />
  );
}
