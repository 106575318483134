import React from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';

import Stack from '@mui/material/Stack';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';

import { IMPORT_EXPORTS_QUERY } from '../../../_lib/graphql/queries';
import { IMPORT_EXPORTS_SUBSCRIPTION } from '../../../_lib/graphql/subscriptions';

import { ReactErrorComponent } from '../../../_lib/react-error';
import { SubscribeToMoreComponent } from '../../../components/subscribe-to-more';

import { DashboardFormImportExportDetail } from './detail';

export function DashboardFormImportExportList() {
  const { t } = useTranslation();

  const { data, loading, error, refetch, subscribeToMore } =
    useQuery(IMPORT_EXPORTS_QUERY);

  // -------------------------------------------------------------------------------------------------------
  // -------------------------------------------------------------------------------------------------------
  // effects
  // -------------------------------------------------------------------------------------------------------

  // refetch
  React.useEffect(() => {
    if (!data && !loading && !error) refetch();
  }, [data, loading, error, refetch]);

  // -------------------------------------------------------------------------------------------------------

  if (error) return <ReactErrorComponent error={error} />;

  if (loading) {
    return (
      <Stack sx={{ my: 4 }} spacing={2.5}>
        {[1, 2, 3].map((k) => (
          <Skeleton key={k} variant="rectangular" height={70} />
        ))}
      </Stack>
    );
  }

  // -------------------------------------------------------------------------------------------------------

  return (
    <>
      <Stack sx={{ my: 4 }} spacing={2.5}>
        {!data?.importExports?.length ? (
          <Typography>{t('Nothing to show')}</Typography>
        ) : (
          data.importExports.map((item: any) => (
            <DashboardFormImportExportDetail key={item.id} item={item} />
          ))
        )}
      </Stack>

      {/* subscriptions */}
      {!!data && (
        <SubscribeToMoreComponent
          subscribeToMore={subscribeToMore}
          document={IMPORT_EXPORTS_SUBSCRIPTION}
        />
      )}
    </>
  );
}
