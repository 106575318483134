import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { gql, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import Skeleton from '@mui/material/Skeleton';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';

import { useDashboardContext } from '../../../_lib/context/dashboard-context';
import { ReactErrorComponent } from '../../../_lib/react-error';
import { withCommas } from '../../../_utils/general-utils';
import { getCurrencyAphaCode } from '../../../_utils/finance-utils';
import { SubscribeToMoreComponent } from '../../../components/subscribe-to-more';
import { truncateChartLabel } from '../charts-config';
import {
  FILTER_VARIABLES,
  FILTER_VARIABLES_DEF,
} from '../../../_lib/graphql/fragments';

const PAS_FRAGMENT = gql`
  fragment PriorityAreasAnalyticsFinancialsFragment on PriorityAreasReturnType {
    items {
      id
      reference
      name
      interventions {
        id
        reference
        name
        summary {
          finance {
            totalFunding
            budget
            currentlySpent
          }
        }
      }
    }
  }
`;

const PAS_QUERY = gql`
  query PriorityAreasAnalyticsFinancials(
    ${FILTER_VARIABLES_DEF}
  ) {
    priorityAreas(
      ${FILTER_VARIABLES}
    ) {
      ...PriorityAreasAnalyticsFinancialsFragment
    }
  }
  ${PAS_FRAGMENT}
`;

const PAS_SUBSCRIPTION = gql`
  subscription PriorityAreasAnalyticsFinancials(
    ${FILTER_VARIABLES_DEF}
  ) {
    priorityAreas(
      ${FILTER_VARIABLES}
    ) {
      ...PriorityAreasAnalyticsFinancialsFragment
    }
  }
  ${PAS_FRAGMENT}
`;

export function AnalyticsFinancials({
  financial = 'budget',
}: {
  financial: 'budget' | 'currentlySpent' | 'totalFunding';
}) {
  const { t } = useTranslation();

  const {
    dashboard: {
      currency,
      enableAreaLevels,
      priorityAreaName,
      colorPalette,
      language,
      enableCustomFinanceLabels,
      customFinanceBudgetLabel,
      customFinanceSpentLabel,
      customFinanceFinancedLabel,
    },
  } = useDashboardContext();

  const { data, loading, error, subscribeToMore, refetch } = useQuery(
    PAS_QUERY,
    {
      variables: {
        paginationPriorityAreasLimit: -1,
        paginationInterventionsLimit: -1,
      },
    }
  );

  const priorityAreas = React.useMemo(
    () => data?.priorityAreas?.items || [],
    [data]
  );

  const chartStateInfo = React.useMemo(
    () => ({
      budget: {
        ylabel: enableCustomFinanceLabels
          ? customFinanceBudgetLabel
          : t('Budget'),
        chartName: `${customFinanceBudgetLabel}`,
      },
      currentlySpent: {
        ylabel: enableCustomFinanceLabels
          ? customFinanceSpentLabel
          : t('Spent'),
        chartName: `${customFinanceSpentLabel}`,
      },
      totalFunding: {
        ylabel: enableCustomFinanceLabels
          ? customFinanceFinancedLabel
          : t('Financed'),
        chartName: `${customFinanceFinancedLabel}`,
      },
    }),
    [
      enableCustomFinanceLabels,
      customFinanceBudgetLabel,
      t,
      customFinanceSpentLabel,
      customFinanceFinancedLabel,
    ]
  );

  const options: any = React.useMemo(
    () => ({
      indexAxis: 'y' as const,
      responsive: true,
      plugins: {
        tooltip: {
          callbacks: {
            label(context: any) {
              return ` ${withCommas(context.parsed.x, language, 0)} (${
                getCurrencyAphaCode(currency) || 'USD'
              })`;
            },
          },
        },
        legend: {
          position: 'bottom' as const,
        },
        datalabels: {
          display: true,
          formatter: (val: number) => withCommas(val, language, 1),
          anchor: 'end',
          offset: -18,
          align: 'start',
          color: colorPalette.primary.bgcolor,
          font: { weight: 'bold' },
        },
      },
      scales: {
        y: {
          title: {
            display: true,
            text: enableAreaLevels ? t('Area') : priorityAreaName,
          },
        },
      },
    }),
    [
      colorPalette.primary.bgcolor,
      currency,
      enableAreaLevels,
      language,
      priorityAreaName,
      t,
    ]
  );

  const chartData = React.useMemo(
    () => ({
      data: {
        labels: priorityAreas.map((pa: any) =>
          truncateChartLabel(`${pa.reference}. ${pa.name}`)
        ),
        datasets: [
          {
            label: `${chartStateInfo[financial].ylabel} (${
              getCurrencyAphaCode(currency) || 'USD'
            })`,
            data: priorityAreas.map((pa: any) => {
              return pa.interventions.reduce(
                (acc: number, curr: any) =>
                  acc + curr.summary.finance[financial] || 0,
                0
              );
            }),
            backgroundColor: colorPalette.secondary.bgcolor,
            categoryPercentage: 0.5,
            barPercentage: 0.75,
          },
        ],
      },
    }),
    [chartStateInfo, colorPalette, currency, financial, priorityAreas]
  );

  const tableData = React.useMemo(() => {
    // table data
    const rows: any[] = priorityAreas.map((pa: any) => {
      const amount = pa.interventions.reduce(
        (acc: number, curr: any) => acc + curr.summary.finance[financial] || 0,
        0
      );
      return [`${pa.reference}. ${pa.name}`, withCommas(amount, language, 0)];
    });

    rows.push([
      t('Total'),
      withCommas(
        priorityAreas.reduce(
          (acc: number, curr: any) =>
            acc +
            curr.interventions.reduce(
              (accIntv: number, currIntv: any) =>
                accIntv + currIntv.summary.finance[financial] || 0,
              0
            ),
          0
        ),
        language,
        0
      ),
    ]);

    return rows;
  }, [priorityAreas, t, language, financial]);

  // ---------------------------------------------------------------------------------------------------------------------
  // ---------------------------------------------------------------------------------------------------------------------
  // effects
  // ---------------------------------------------------------------------------------------------------------------------

  // refetch
  React.useEffect(() => {
    if (!data && !loading && !error) {
      refetch();
    }
  }, [data, loading, error, refetch]);

  // ---------------------------------------------------------------------------------------------------------------------

  if (loading || !chartData || !tableData) {
    return <Skeleton variant="rectangular" height={250} />;
  }

  if (error) {
    return <ReactErrorComponent error={error} />;
  }

  return (
    <ErrorBoundary FallbackComponent={ReactErrorComponent}>
      <Box minHeight={250} padding={1} width="auto" maxWidth="98%">
        <Bar
          options={options}
          data={chartData.data}
          plugins={[ChartDataLabels]}
        />
      </Box>

      {/* table */}
      <Box padding={1} pt={2}>
        <TableContainer variant="outlined" component={Paper}>
          <Table stickyHeader sx={{ minWidth: 'auto' }} size="small">
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    textTransform: 'capitalize',
                    bgcolor: 'grey.200',
                    whiteSpace: 'nowrap',
                    fontWeight: 'bold',
                    color: 'grey.700',
                  }}
                >
                  {priorityAreaName}
                </TableCell>
                <TableCell
                  sx={{
                    textTransform: 'capitalize',
                    bgcolor: 'grey.200',
                    whiteSpace: 'nowrap',
                    fontWeight: 'bold',
                    color: 'grey.700',
                  }}
                  align="right"
                >
                  {`${chartStateInfo[financial].ylabel} (${
                    getCurrencyAphaCode(currency) || 'USD'
                  })`}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tableData.map((row: any, oindex: number) => (
                <TableRow
                  key={`cost-table-row-${String(oindex)}`}
                  sx={{
                    '&:last-child td, &:last-child th': { border: 0 },
                  }}
                >
                  {row.map((rowItem: any, iindex: number) => {
                    const isLastRow = oindex === tableData.length - 1;
                    return (
                      <TableCell
                        align={iindex > 0 ? 'right' : 'left'}
                        key={`cost-table-item-row${String(oindex)}-cell${String(
                          iindex
                        )}`}
                        sx={{ fontWeight: isLastRow ? 'bold' : 'normal' }}
                      >
                        {rowItem}
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      {/* subscribe to more */}
      <SubscribeToMoreComponent
        subscribeToMore={subscribeToMore}
        document={PAS_SUBSCRIPTION}
        variables={{
          paginationInterventionsLimit: -1,
          paginationPriorityAreasLimit: -1,
        }}
      />
    </ErrorBoundary>
  );
}
