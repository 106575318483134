import React from 'react';
import { useTranslation } from 'react-i18next';

import { useMutation } from '@apollo/client';

import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';

import { DialogModal } from '../../components/dialog-modal';
import { CONTENT_DELETE } from '../../_lib/graphql/mutations';
import { logError } from '../../_lib/error';
import { useDashboardContext } from '../../_lib/context/dashboard-context';

export function DeleteMilestone({
  milestone,
  newMilestones,
  setNewMilestones,
}: any) {
  const { t } = useTranslation();

  const {
    setSnackbarIsError,
    setSnackbarOpen,
    setRecentlyDeletedProps,
    setSnackbarIsDelete,
  } = useDashboardContext();
  const [confirmDialogOpen, setConfirmDialogOpen] = React.useState(false);

  const [deleteContentMutation, { loading }] = useMutation(CONTENT_DELETE);

  const onClick = (e: any) => {
    e.stopPropagation();
    setConfirmDialogOpen(true);
  };

  const deleteContent = () => {
    const index =
      newMilestones?.findIndex((m: any) => m.id === milestone.id) || 0;
    deleteContentMutation({
      variables: { id: milestone.id },
    })
      .then(() => {
        setConfirmDialogOpen(false);
        setSnackbarIsDelete(true);
        setRecentlyDeletedProps({
          objectId: milestone?.id,
          objectName: 'content',
          object: milestone,
          successFunc: () => {
            setNewMilestones((prev: any) => {
              const milestones = [...prev];
              milestones.splice(index, 0, milestone);
              return milestones;
            });
          },
        });
      })
      .catch((e) => {
        setSnackbarIsError(true);
        logError(e);
      })
      .finally(() => {
        setSnackbarOpen(true);
      });
  };

  const deleteNewMilestone = () => {
    setNewMilestones(
      newMilestones.filter((m: any) => m.fauxId !== milestone.fauxId)
    );
  };

  return (
    <>
      <DialogModal
        loading={loading}
        onSubmit={deleteContent}
        onDismiss={() => setConfirmDialogOpen(false)}
        title={t('Proceed to delete this milestone?')}
        type=""
        show={confirmDialogOpen}
      />
      <Button
        variant="outlined"
        color="error"
        startIcon={<DeleteIcon />}
        onClick={milestone.fauxId ? deleteNewMilestone : onClick}
        size="small"
      >
        {t('Delete')}
      </Button>
    </>
  );
}

export default DeleteMilestone;
