import React from 'react';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';

import { useDashboardContext } from '../../../_lib/context/dashboard-context';

import { AreaPermissionsLeads } from '../../../components/area-permissions/leads';

import { PlanningInterventionFormProgrammeAutocomplete } from '../programme/autocomplete';
import { PlanningInterventionFormStatusAutocomplete } from '../status/autocomplete';

import { PlanningFieldsInputBudget } from '../../fields-input/budget';

import { PlanningFieldsInputStakeholders } from '../../fields-input/stakeholders';
import { PlanningFieldsInputRegions } from '../../fields-input/regions';
import { PlanningFieldsInputClassification } from '../../fields-input/classification';

export function PlanningInterventionFormDetailFields({
  disabled,
  intervention,
  setIntervention,
  errors,
}: any) {
  const { t } = useTranslation();

  const { dashboard } = useDashboardContext();

  return (
    <>
      <Grid container spacing={2} sx={{ my: 1 }}>
        {/* Name + Programme + Status */}
        <Grid item xs={12} sm={12} md={6}>
          <Grid container spacing={{ xs: 2, sm: 1 }}>
            {/* Reference */}
            <Grid item xs={12} sm={2} md={2}>
              <TextField
                label={t('Ref')}
                variant="outlined"
                size="small"
                fullWidth
                disabled={disabled}
                value={intervention.reference || ''}
                onChange={(e) =>
                  setIntervention({
                    ...intervention,
                    reference: e.target.value,
                  })
                }
                required
              />
            </Grid>

            {/* Name */}
            <Grid item xs={12} sm={10} md={10}>
              <TextField
                id="intervention-name-input"
                label={`${t('Name')}`}
                variant="outlined"
                size="small"
                value={intervention.name || ''}
                onChange={(e) =>
                  setIntervention({
                    ...intervention,
                    name: e.target.value,
                  })
                }
                disabled={disabled}
                helperText={errors.name}
                error={!!errors.name}
                fullWidth
                required
              />
            </Grid>
          </Grid>

          {/* permissions: lead + colead + others */}
          <Box sx={{ my: 2 }}>
            <AreaPermissionsLeads
              disabled={disabled}
              area={intervention}
              setArea={setIntervention}
              errors={errors}
            />
          </Box>

          {/* programme + status */}
          <Grid container spacing={1.5}>
            <Grid item xs={12} sm={6}>
              <PlanningInterventionFormProgrammeAutocomplete
                disabled={disabled}
                intervention={intervention}
                setIntervention={setIntervention}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <PlanningInterventionFormStatusAutocomplete
                disabled={disabled}
                intervention={intervention}
                setIntervention={setIntervention}
              />
            </Grid>
          </Grid>
        </Grid>

        {/* Description */}
        <Grid item xs={12} sm={12} md={6}>
          <TextField
            id="intervention-description-input"
            label={`${t('Description')}`}
            variant="outlined"
            multiline
            fullWidth
            placeholder={`${t(
              'A general overview of the aims and goals of this'
            )} ${dashboard.interventionName}`}
            minRows={5}
            disabled={disabled}
            value={intervention.description || ''}
            onChange={(e) =>
              setIntervention({
                ...intervention,
                description: e.target.value,
              })
            }
          />
        </Grid>
      </Grid>

      {/* Classification + Regions + Budget + Spent */}
      <Grid container spacing={2} sx={{ my: 1 }}>
        {/* classification */}
        <Grid item xs={12} sm={6} md={3}>
          <PlanningFieldsInputClassification
            disabled={disabled}
            area={intervention}
            setArea={setIntervention}
          />
        </Grid>

        {/* regions */}
        <Grid item xs={12} sm={6} md={3}>
          <PlanningFieldsInputRegions
            disabled={disabled}
            area={intervention}
            setArea={setIntervention}
          />
        </Grid>

        {/* budget + spent */}
        <PlanningFieldsInputBudget
          disabled={disabled}
          errors={errors}
          area={intervention}
          setArea={setIntervention}
        />
      </Grid>

      {/* stakeholders */}
      {dashboard.enableAreaStakeholders && (
        <Box sx={{ my: 2.5 }}>
          <PlanningFieldsInputStakeholders
            disabled={disabled}
            area={intervention}
            setArea={setIntervention}
          />
        </Box>
      )}
    </>
  );
}
