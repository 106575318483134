import React from 'react';

import { ThemeProvider, createTheme } from '@mui/material/styles';

import { DashboardContext } from '../../context/dashboard-context';

import { ThemeProviderDefault } from './default';

export function ThemeProviderDashboard({ children }: any) {
  const { dashboard } = React.useContext(DashboardContext);

  const [theme, setTheme] = React.useState<any>(
    createTheme({
      palette: {
        primary: {
          main: dashboard?.colorPalette?.primary?.bgcolor || '#007681',
          contrastText: dashboard?.colorPalette?.primary?.textColor || '#fff',
        },
        secondary: {
          main: dashboard?.colorPalette?.secondary?.bgcolor || '#4DC3CE',
          contrastText: dashboard?.colorPalette?.secondary?.textColor || '#fff',
        },
      },
    })
  );

  // ----------------------------------------------------------------------------------
  // ----------------------------------------------------------------------------------
  // effects
  // ----------------------------------------------------------------------------------

  React.useEffect(() => {
    setTheme(
      createTheme({
        palette: {
          primary: {
            main: dashboard?.colorPalette?.primary?.bgcolor || '#007681',
            contrastText: dashboard?.colorPalette?.primary?.textColor || '#fff',
          },
          secondary: {
            main: dashboard?.colorPalette?.secondary?.bgcolor || '#4DC3CE',
            contrastText:
              dashboard?.colorPalette?.secondary?.textColor || '#fff',
          },
        },
      })
    );
  }, [dashboard]);
  // ----------------------------------------------------------------------------------

  return (
    <ThemeProviderDefault>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </ThemeProviderDefault>
  );
}
