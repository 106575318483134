import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';

import Checkbox from '@mui/material/Checkbox';
import DoneIcon from '@mui/icons-material/Done';
import Typography from '@mui/material/Typography';

import LoadingButton from '@mui/lab/LoadingButton';

import { DialogModal } from '../../../components/dialog-modal';
import { getGraphQLDate } from '../../../_utils/general-utils';
import { CONTENT_UPDATE } from '../../../_lib/graphql/mutations';
import { CONTENT_QUERY } from '../../../_lib/graphql/queries';

import { logError } from '../../../_lib/error';

import { ContentDialogContext } from '../../../_lib/context/content-dialog-context';
import { useDashboardContext } from '../../../_lib/context/dashboard-context';

export function MarkContentAsCompleted({ checkbox }: any) {
  const { t } = useTranslation();

  const { dashboard, setSnackbarOpen, setSnackbarIsError } =
    useDashboardContext();
  const { contentId, content } = React.useContext(ContentDialogContext);

  const [loading, setLoading] = React.useState(false);
  const [completed, setCompleted] = React.useState(
    !!content.completionDate || false
  );
  const [indeterminate, setIndeterminate] = React.useState(
    content.status === 'pending'
  );
  const [dialogConfirmOpen, setDialogConfirmOpen] = React.useState(false);

  const [changeActivityMutation] = useMutation(CONTENT_UPDATE, {
    update: (cache, { data }) => {
      cache.writeQuery({
        query: CONTENT_QUERY,
        data: { content: data.contentUpdate.content },
        variables: { id: contentId },
      });
    },
  });

  // ------------------------------------------------------------------------------------------------
  // ------------------------------------------------------------------------------------------------
  // handlers
  // ------------------------------------------------------------------------------------------------

  const markAsCompleted = ({ unComplete }: any) => {
    setLoading(true);
    const variables = {
      id: content.id,
      completionDate: unComplete ? null : getGraphQLDate(new Date()),
      unComplete: unComplete || null,
    };
    return changeActivityMutation({ variables })
      .catch((e) => {
        logError(e);
        setSnackbarIsError(true);
      })
      .finally(() => {
        if (setLoading) setLoading(false);
        setSnackbarOpen(true);
      });
  };

  const handleCheckboxChange = () => {
    return markAsCompleted({ unComplete: completed });
  };

  // ------------------------------------------------------------------------------------------------
  // ------------------------------------------------------------------------------------------------
  // effects
  // ------------------------------------------------------------------------------------------------

  React.useEffect(() => {
    if (content) {
      setIndeterminate(content.status === 'pending');
      setCompleted(!!content.completionDate);
    }
  }, [content]);

  // ------------------------------------------------------------------------------------------------

  return (
    <>
      {/* confirm save dialog */}
      <DialogModal
        open={dialogConfirmOpen}
        onDismiss={() => setDialogConfirmOpen(false)}
        onSubmit={() => {
          if (!checkbox) {
            markAsCompleted({}).then(() => {
              setDialogConfirmOpen(false);
            });
          } else {
            handleCheckboxChange().then(() => {
              setDialogConfirmOpen(false);
            });
          }
        }}
        loading={loading}
        title={t('Confirm Save')}
        message={<Typography>{t('Are you sure you want to save?')}</Typography>}
      />

      {!checkbox ? (
        <LoadingButton
          id="mark-content-as-completed"
          onClick={() => {
            if (!dashboard.enableActivityConfirmSave) {
              markAsCompleted({});
            } else {
              setDialogConfirmOpen(true);
            }
          }}
          fullWidth
          variant="outlined"
          startIcon={<DoneIcon className="text-success" />}
          loading={loading}
        >
          {t('Complete')}
        </LoadingButton>
      ) : (
        <Checkbox
          checked={completed}
          color="default"
          inputProps={{ 'aria-label': `${t('Complete')}` }}
          onClick={() => {
            if (!dashboard.enableActivityConfirmSave) {
              handleCheckboxChange();
            } else {
              setDialogConfirmOpen(true);
            }
          }}
          disabled={loading}
          indeterminate={indeterminate}
        />
      )}
    </>
  );
}
