import ReactMarkdown, { Components } from 'react-markdown';

import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';

function MarkdownListItem(props: any) {
  return <Box component="li" sx={{ mt: 1, typography: 'body1' }} {...props} />;
}

const components: Partial<Components> = {
  h1: ({ node, ...props }: any) => (
    <Typography variant="h4" gutterBottom component="h1" {...props} />
  ),
  h2: ({ node, ...props }: any) => (
    <Typography variant="h6" gutterBottom component="h2" {...props} />
  ),
  h3: ({ node, ...props }: any) => (
    <Typography variant="subtitle1" gutterBottom component="h3" {...props} />
  ),
  h4: ({ node, ...props }: any) => (
    <Typography variant="caption" paragraph component="h4" {...props} />
  ),
  p: ({ node, ...props }: any) => <Typography paragraph {...props} />,
  a: ({ node, ...props }: any) => <Link {...props} />,
  li: MarkdownListItem,
};

export function Markdown(props: any) {
  if (!props.children) return null;
  return (
    <div style={{ wordBreak: 'break-word' }}>
      <ReactMarkdown components={components} {...props} />
    </div>
  );
}
