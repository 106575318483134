import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';

import { SwitchIcon } from '../../../../components/icons/switch-icon';

export function AdvancedDashboardOverviewOption({
  enabled,
  handleChecked,
  loading,
  optionKey,
  title,
  subtitle,
}: any) {
  return (
    <FormControlLabel
      control={
        <Switch
          checked={enabled[optionKey] || false}
          onChange={(event) =>
            handleChecked({
              [optionKey]: event.target.checked,
            })
          }
          checkedIcon={
            <SwitchIcon
              enabled={enabled[optionKey]}
              loading={loading[optionKey]}
            />
          }
          icon={
            <SwitchIcon
              enabled={enabled[optionKey]}
              loading={loading[optionKey]}
            />
          }
        />
      }
      label={
        <>
          <Typography variant="body1">{title}</Typography>
          <Typography variant="caption">{subtitle}</Typography>
        </>
      }
      sx={{
        width: '100%',
        '& .MuiSwitch-root': {
          alignSelf: 'flex-start',
        },
      }}
    />
  );
}
