import React from 'react';
import { useQuery } from '@apollo/client';

import {
  PRIORITY_AREAS_PLANNING_QUERY,
  INTERVENTIONS_PLANNING_QUERY,
} from '../../../_lib/graphql/queries';
import {
  INTERVENTIONS_PLANNING_SUBSCRIPTION,
  PRIORITY_AREAS_PLANNING_SUBSCRIPTION,
} from '../../../_lib/graphql/subscriptions';

export function useAreasPlanning(
  areaType: 'priorityArea' | 'intervention',
  variables: any = {}
) {
  const { loading, error, data, subscribeToMore, refetch } = useQuery(
    areaType === 'priorityArea'
      ? PRIORITY_AREAS_PLANNING_QUERY
      : INTERVENTIONS_PLANNING_QUERY,
    {
      variables,
    }
  );

  // refetch
  React.useEffect(() => {
    if (!data && !loading && !error) refetch();
  }, [data, loading, error, refetch]);

  // subscription
  const readyToSubscribe = React.useMemo(() => !!data, [data]);

  React.useEffect(() => {
    let unsubscribe: any;
    if (readyToSubscribe) {
      unsubscribe = subscribeToMore({
        document:
          areaType === 'priorityArea'
            ? PRIORITY_AREAS_PLANNING_SUBSCRIPTION
            : INTERVENTIONS_PLANNING_SUBSCRIPTION,
        variables,
        updateQuery: (prev, { subscriptionData }) => {
          if (!subscriptionData.data) return prev;
          return subscriptionData.data;
        },
      });
    }
    return () => {
      if (unsubscribe) unsubscribe();
    };
  }, [readyToSubscribe, subscribeToMore, areaType, variables]);

  return { data, loading, error };
}
