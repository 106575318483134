import React from 'react';
import { useTranslation } from 'react-i18next';

import grey from '@mui/material/colors/grey';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

import { useDashboardContext } from '../../_lib/context/dashboard-context';

import { PlanningContextProvider } from '../../_lib/context/planning-context';
import { PlanningPlanningSteps } from '../planning-steps';
import { PlanningLayoutTabs } from './tabs';

export function PlanningLayoutBase({ children }: any) {
  const { t } = useTranslation();

  const { dashboard } = useDashboardContext();

  return (
    <PlanningContextProvider>
      <style>{`html, body { background-color: ${grey[100]}; }`}</style>
      <Container sx={{ py: 4 }}>
        {/* planning steps */}
        {dashboard.inPlanning && <PlanningPlanningSteps />}

        {/* title */}
        <Typography variant="h4" sx={{ mb: 2 }}>
          {t('Settings and Planning')}
        </Typography>

        {/* tabs */}
        <PlanningLayoutTabs />

        {/* content */}
        {children}
      </Container>
    </PlanningContextProvider>
  );
}
