import React from 'react';

import Skeleton from '@mui/material/Skeleton';

import { useSummary } from '../../../_utils/hooks/queries/use-summary';
import { TargetsSummaryCard } from '../../targets-summary/card';
import { useDashboardContext } from '../../../_lib/context/dashboard-context';

export function AreaSummaryCardTargets({ area }: any) {
  const areaType =
    area?.__typename === 'PriorityAreaType'
      ? 'priorityArea'
      : area?.__typename === 'InterventionType'
      ? 'intervention'
      : undefined;

  const { navbarFilterVariables } = useDashboardContext();

  const [rowsPerPage, setRowsPerPage] = React.useState(3);
  const [page, setPage] = React.useState(0);

  const { data, loading, error } = useSummary(
    'targets',
    {
      priorityAreaId: areaType === 'priorityArea' ? area.id : undefined,
      interventionId: areaType === 'intervention' ? area.id : undefined,
      ...navbarFilterVariables,
      paginationTargetsLimit: rowsPerPage,
      paginationTargetsOffset: page * rowsPerPage,
    },
    areaType
  );

  if (error || !areaType) return null;

  if (loading || !data)
    return <Skeleton variant="rectangular" height={120} sx={{ mb: 2 }} />;

  if (!data[areaType]?.summary?.targets?.progressPerc) return null;
  if (data[areaType].summary.targets.progressPerc === -1) return null;

  return (
    <TargetsSummaryCard
      id={data[areaType].summary.targets.id}
      key="targets"
      targetsSummary={data[areaType].summary.targets}
      cardProps={{
        sx: {
          mb: 2,
        },
      }}
      titleTypographyProps={{
        sx: { mb: 0.5, fontWeight: 500 },
        variant: 'body1',
      }}
      expandMoreIconButtonProps={{
        size: 'small',
      }}
      rowsPerPage={rowsPerPage}
      setRowsPerPage={setRowsPerPage}
      page={page}
      setPage={setPage}
    />
  );
}
