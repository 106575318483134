import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import { useDashboardContext } from '../../_lib/context/dashboard-context';
import { logError } from '../../_lib/error';
import { SubscribeToMoreComponent } from '../../components/subscribe-to-more';

import { PRIORITY_AREA_PLANNING_BREADCRUMBS_QUERY } from '../../_lib/graphql/queries';
import { PRIORITY_AREA_PLANNING_BREADCRUMBS_SUBSCRIPTION } from '../../_lib/graphql/subscriptions';

export function PlanningLayoutLoadBreadcrumbs({
  setPriorityArea,
  searchTerm,
  isIntervention,
}: any) {
  const { t } = useTranslation();

  const { priorityAreaId } = useParams<any>();

  const { dashboard } = useDashboardContext();

  const { error, data, subscribeToMore } = useQuery(
    PRIORITY_AREA_PLANNING_BREADCRUMBS_QUERY,
    {
      variables: {
        priorityAreaId,
        filterSearchQuery: searchTerm,
      },
    }
  );

  // --------------------------------------------------------------------------------------------------------------------
  // --------------------------------------------------------------------------------------------------------------------
  // effects
  // --------------------------------------------------------------------------------------------------------------------

  React.useEffect(() => {
    if (error) logError(error);
  }, [error]);

  React.useEffect(() => {
    if (data) {
      setPriorityArea({
        priorityArea: data.priorityArea,
        breadcrumbs: [
          ...data.priorityArea.urlBreadcrumbsPlanning.map((bc: any) => ({
            ...bc,
            to: `${t('/planning')}${bc.to}`,
          })),
          {
            label: isIntervention
              ? dashboard.interventionName
              : data.priorityArea.levelNext?.name,
            to: window.location.pathname,
          },
        ],
      });
    }
  }, [dashboard.interventionName, data, isIntervention, setPriorityArea, t]);

  // --------------------------------------------------------------------------------------------------------------------

  if (!data) return null;

  return (
    <SubscribeToMoreComponent
      subscribeToMore={subscribeToMore}
      document={PRIORITY_AREA_PLANNING_BREADCRUMBS_SUBSCRIPTION}
      variables={{ priorityAreaId, filterSearchQuery: searchTerm }}
    />
  );
}
