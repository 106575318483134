import React from 'react';

import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

import { useDashboardContext } from '../../_lib/context/dashboard-context';
import { logError } from '../../_lib/error';

import { DraggableDialogDetail } from './detail';

export function DragggableDialogList({
  details,
  setDetails,
  ogDetails,
  inputLabel,
  updateMutation,
  deleteMutation,
  updateOrderMutation,
  loadingData,
  includeColorPicker,
  includeWeight,
  includeCategory,
}: any) {
  const { setSnackbarOpen, setSnackbarIsError } = useDashboardContext();

  // --------------------------------------------------------------------------------------------------------------------
  // --------------------------------------------------------------------------------------------------------------------
  // handlers
  // --------------------------------------------------------------------------------------------------------------------

  const onDragEnd = (result: any) => {
    const { destination, source, draggableId } = result;

    // check if not moved
    if (!destination) {
      return;
    }
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    // moved
    const newOrderedDetailsIds = details.map((group: any) => group.id);
    newOrderedDetailsIds.splice(source.index, 1);
    newOrderedDetailsIds.splice(destination.index, 0, draggableId);

    // update order locally
    if (setDetails) {
      const newDetails = newOrderedDetailsIds.map((id: any) =>
        details.find((detail: any) => detail.id === id)
      );
      setDetails(newDetails);
    }

    // update order on server
    updateOrderMutation(newOrderedDetailsIds)
      .catch((err: any) => {
        logError(err);
        setSnackbarIsError(true);
        setDetails(ogDetails);
      })
      .finally(() => {
        setSnackbarOpen(true);
      });
  };

  // --------------------------------------------------------------------------------------------------------------------

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="draggable-dialog-list">
        {(provided) => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            {details.map((detail: any, index: number) => {
              return (
                <Draggable
                  key={detail.id}
                  draggableId={detail.id}
                  index={index}
                  isDragDisabled={loadingData || detail.isDragDisabled}
                >
                  {(providedDraggable) => (
                    <div
                      ref={providedDraggable.innerRef}
                      {...providedDraggable.draggableProps}
                      {...providedDraggable.dragHandleProps}
                    >
                      <DraggableDialogDetail
                        detail={detail}
                        inputLabel={inputLabel}
                        deleteMutation={deleteMutation}
                        updateMutation={updateMutation}
                        loadingData={loadingData}
                        includeColorPicker={includeColorPicker}
                        includeWeight={includeWeight}
                        includeCategory={includeCategory}
                      />
                    </div>
                  )}
                </Draggable>
              );
            })}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}
