import React from 'react';

import { useTranslation } from 'react-i18next';

import { useDashboardContext } from '../_lib/context/dashboard-context';

import { MemberAutocomplete } from '../components/member-autocomplete';

export function ContentAssigneeSelect({
  content,
  setContent,
  loading,
  errors,
}: any) {
  const { t } = useTranslation();

  const { dashboard, users } = useDashboardContext();

  return (
    <MemberAutocomplete
      label={t('Assigned to')}
      multiple={dashboard.enableActivityMultipleAssignees}
      value={
        !dashboard.enableActivityMultipleAssignees
          ? users.find((u: any) => u.id === content?.assignee?.id)
          : users.filter((u: any) =>
              content?.assignees?.map((a: any) => a.id).includes(u.id)
            )
      }
      onChange={(event: any, value: any) => {
        if (!dashboard.enableActivityMultipleAssignees) {
          setContent(
            structuredClone({
              ...content,
              assignee: value,
            })
          );
        } else {
          setContent(
            structuredClone({
              ...content,
              assignees: value,
            })
          );
        }
      }}
      required={content?.type === 'activity'}
      disableClearable={content?.type === 'activity'}
      disabled={loading}
      error={errors?.assignee}
    />
  );
}
