import { Grid, GridProps } from '@mui/material';
import { styled } from '@mui/material/styles';

export const ExecutiveSummaryBreakdownContainerGrid = styled(
  (props: GridProps) => (
    <Grid
      container
      columnSpacing={2}
      rowSpacing={{
        xs: 2,
        md: 0,
      }}
      flexDirection={{
        md: 'row',
        xs: 'column-reverse',
      }}
      {...props}
    />
  )
)(() => ({
  display: 'flex',
}));
