import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';

import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

import { useDashboardContext } from '../_lib/context/dashboard-context';
import { logError } from '../_lib/error';

import { DialogModal } from '../components/dialog-modal';
import { FINANCIER_AMOUNT_DELETE } from '../_lib/graphql/mutations';

export function DeleteFinancier({
  financier,

  newFinanciers,
  setNewFinanciers,

  area,
  setArea,
}: any) {
  const { t } = useTranslation();

  const {
    setSnackbarIsError,
    setSnackbarOpen,
    setSnackbarIsDelete,
    setRecentlyDeletedProps,
  } = useDashboardContext();

  const [confirmDialogOpen, setConfirmDialogOpen] = React.useState(false);

  const [deleteFinancierMutation, { loading }] = useMutation(
    FINANCIER_AMOUNT_DELETE
  );

  const deleteFinancier = () => {
    if (!financier.id) {
      setNewFinanciers(
        newFinanciers.filter((f: any) => f.fauxId !== financier.fauxId)
      );
    } else {
      const ogFinancier = area.financiersDb.find(
        (fin: any) => fin.id === financier.id
      );
      const index = area.financiersDb.findIndex(
        (fin: any) => fin.id === financier.id
      );
      deleteFinancierMutation({
        variables: { id: financier.id },
      })
        .then(() => {
          setConfirmDialogOpen(false);
          setArea({
            ...area,
            financiersDb: area.financiersDb.filter(
              (d: any) => d.id !== financier.id
            ),
          });
          setRecentlyDeletedProps({
            objectId: financier.id,
            objectName: 'financier',
            object: ogFinancier,
            successFunc: () => {
              setArea((prev: any) => {
                const fins = [...prev.financiersDb];
                fins.splice(index, 0, ogFinancier);
                return {
                  ...prev,
                  financiersDb: fins,
                };
              });
            },
          });
          setSnackbarIsDelete(true);
        })
        .catch((e) => {
          logError(e);
          setSnackbarIsError(true);
        })
        .finally(() => {
          setSnackbarOpen(true);
        });
    }
  };

  return (
    <>
      <DialogModal
        loading={loading}
        show={confirmDialogOpen}
        onSubmit={deleteFinancier}
        onDismiss={() => setConfirmDialogOpen(false)}
        title={t('Are you sure you want to delete this financier?')}
        type="confirm-only"
      />

      <Tooltip title={t('Delete') as string}>
        <IconButton
          color="secondary"
          component="span"
          id="delete-financier"
          onClick={() =>
            financier.id ? setConfirmDialogOpen(true) : deleteFinancier()
          }
        >
          <DeleteForeverIcon />
        </IconButton>
      </Tooltip>
    </>
  );
}

export default DeleteFinancier;
