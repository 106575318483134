import React from 'react';
import { gql, useQuery } from '@apollo/client';

import Typography from '@mui/material/Typography';

import { logError } from '../../../../../_lib/error';

import { ContentDialogBlockersDetailTextPopover } from './popover';
import { SubscribeToMore } from '../../../../../components/subscribe-to-more';

const C_FRAGMENT = gql`
  fragment ContentDialogBlockersDetailTextContentFragment on ContentType {
    id
    title
    status
    isMilestone
  }
`;

const C_QUERY = gql`
  query ContentDialogBlockersDetailTextContent($contentId: ID!) {
    content(contentId: $contentId) {
      ...ContentDialogBlockersDetailTextContentFragment
    }
  }
  ${C_FRAGMENT}
`;

const C_SUBSCRIPTION = gql`
  subscription ContentDialogBlockersDetailTextContent($contentId: ID!) {
    content(contentId: $contentId) {
      ...ContentDialogBlockersDetailTextContentFragment
    }
  }
  ${C_FRAGMENT}
`;

export function ContentDialogBlockersDetailTextContent({
  contentId,
  setContentStatus,
}: any) {
  const popoverAnchor = React.useRef(null);

  const [open, setOpen] = React.useState(false);

  const { data, loading, error, refetch, subscribeToMore } = useQuery(C_QUERY, {
    variables: { contentId },
  });

  // ----------------------------------------------------------------------------------------------------
  // ----------------------------------------------------------------------------------------------------
  // handlers
  // ----------------------------------------------------------------------------------------------------

  const handlePopoverOpen = () => {
    setOpen(true);
  };

  const handlePopoverClose = () => {
    setOpen(false);
  };

  // ----------------------------------------------------------------------------------------------------
  // ----------------------------------------------------------------------------------------------------
  // effects
  // ----------------------------------------------------------------------------------------------------

  React.useEffect(() => {
    if (error) logError(error);
  }, [error]);

  React.useEffect(() => {
    if (data?.content?.status && setContentStatus)
      setContentStatus(data?.content?.status);
  }, [data?.content?.status, setContentStatus]);

  React.useEffect(() => {
    if (!loading && !data && !error) refetch();
  }, [loading, data, error, refetch]);

  return (
    <>
      <Typography
        ref={popoverAnchor}
        component="span"
        variant="body2"
        aria-owns={open ? 'mouse-over-popover' : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
        sx={{
          bgcolor: 'white',
          textDecoration: !data?.content
            ? 'none'
            : data?.content?.status === 'completed'
            ? 'line-through'
            : 'none',
          cursor: 'pointer',
        }}
      >
        #{contentId} {data?.content?.title}
      </Typography>

      <ContentDialogBlockersDetailTextPopover
        key={contentId}
        open={open}
        popoverAnchor={popoverAnchor}
        handlePopoverOpen={handlePopoverOpen}
        handlePopoverClose={handlePopoverClose}
        contentId={contentId.replace('#', '').trim()}
      />

      {/* subscribe to more */}
      <SubscribeToMore
        subscribeToMore={() =>
          subscribeToMore({
            document: C_SUBSCRIPTION,
            updateQuery: (prev, { subscriptionData }) => {
              if (!subscriptionData.data) return prev;
              return subscriptionData.data;
            },
            variables: { contentId },
          })
        }
        variables={{ contentId }}
      />
    </>
  );
}
