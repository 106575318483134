import { useTranslation } from 'react-i18next';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Chip from '@mui/material/Chip';
import LoadingButton from '@mui/lab/LoadingButton';

import TableChartOutlinedIcon from '@mui/icons-material/TableChartOutlined';
import BackupTableOutlinedIcon from '@mui/icons-material/BackupTableOutlined';

import { DialogTitle } from '../../../../../DialogTitle/dialog-title';

export function MyAccountPreferencesDetailTitleConfirmDialog({
  title,
  description,

  confirmButtonLabel,
  confirmButtonIcon,
  confirmButtonColor,
  confirmButtonOnClick,

  loading,
  open,
  setOpen,
  setting,
}: any) {
  const { t } = useTranslation();
  return (
    <Dialog open={open} onClose={() => setOpen(false)} maxWidth="xs" fullWidth>
      <DialogTitle onClose={() => setOpen(false)}>{title}</DialogTitle>
      <DialogContent>
        <Typography>{description}</Typography>

        <Paper
          variant="outlined"
          sx={{ bgcolor: 'grey.100', py: 1, px: 1.5, mt: 2 }}
        >
          <Typography variant="body2">{setting.dashboard.name}</Typography>
          <Typography variant="caption" component="div">
            {setting.dashboard.country} . {setting.dashboard.language}
            {(setting.dashboard.mode === 'TRACKER' ||
              setting.dashboard.mode === 'SUPRANATIONAL') && (
              <Chip
                label={
                  setting.dashboard.mode === 'TRACKER'
                    ? t('Tracker')
                    : t('Dashboard of Dashboards')
                }
                size="small"
                sx={{
                  ml: 1,
                  cursor: 'pointer',
                }}
                variant="outlined"
                color="primary"
                icon={
                  setting.dashboard.mode === 'TRACKER' ? (
                    <TableChartOutlinedIcon />
                  ) : (
                    <BackupTableOutlinedIcon />
                  )
                }
              />
            )}
          </Typography>
        </Paper>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => setOpen(false)}
          color="primary"
          disabled={loading}
        >
          {t('Cancel')}
        </Button>
        <LoadingButton
          onClick={confirmButtonOnClick}
          color={confirmButtonColor}
          autoFocus
          variant="contained"
          disableElevation
          loading={loading}
          startIcon={confirmButtonIcon}
        >
          {confirmButtonLabel}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
