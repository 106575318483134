import { useTranslation } from 'react-i18next';

import Alert from '@mui/material/Alert';
import Container from '@mui/material/Container';

import { useDashboardContext } from '../../_lib/context/dashboard-context';

export function DashboardDeletedAlert() {
  const { t } = useTranslation();

  const { dashboard } = useDashboardContext();

  return dashboard?.deletedAt ? (
    <Container maxWidth="sm" sx={{ my: 4 }}>
      <Alert severity="error" variant="filled">
        {t(
          'This dashboard has been deleted. Contact your administrator to restore it.'
        )}
      </Alert>
    </Container>
  ) : null;
}
