import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import DialogActions from '@mui/material/DialogActions';
import LoadingButton from '@mui/lab/LoadingButton';
import Paper from '@mui/material/Paper';

import ArchiveIcon from '@mui/icons-material/Archive';
import UnarchiveIcon from '@mui/icons-material/Unarchive';

import { useDashboardContext } from '../../../../_lib/context/dashboard-context';

import { CONTENT_UPDATE } from '../../../../_lib/graphql/mutations';
import { CONTENT_QUERY } from '../../../../_lib/graphql/queries';

import { logError } from '../../../../_lib/error';

import { DialogTitle } from '../../../../DialogTitle/dialog-title';

export function ContentDialogActionsArchiveDialog({
  open,
  setOpen,
  content,
}: any) {
  const { t } = useTranslation();

  const { setSnackbarOpen, setSnackbarIsError } = useDashboardContext();

  const [loading, setLoading] = React.useState(false);

  const [changeActivityMutation] = useMutation(CONTENT_UPDATE, {
    update: (cache, { data }) => {
      cache.writeQuery({
        query: CONTENT_QUERY,
        data: { content: data.contentUpdate.content },
        variables: { id: content?.id },
      });
    },
  });

  // -------------------------------------------------------------------------------------------------------
  // -------------------------------------------------------------------------------------------------------
  // handlers
  // -------------------------------------------------------------------------------------------------------

  const handleArchive = React.useCallback(() => {
    setLoading(true);
    const variables = {
      id: content?.id,
      archive: content?.status !== 'archived',
    };
    changeActivityMutation({ variables })
      .then(() => {
        setOpen(false);
        setLoading(false);
        setSnackbarOpen(true);
      })
      .catch((e) => {
        setLoading(false);
        logError(e);
        setSnackbarIsError(true);
        setSnackbarOpen(true);
      });
  }, [
    changeActivityMutation,
    content?.id,
    content?.status,
    setOpen,
    setSnackbarIsError,
    setSnackbarOpen,
  ]);

  // -------------------------------------------------------------------------------------------------------

  if (!content) return null;

  return (
    <Dialog open={open} maxWidth="xs" fullWidth>
      <DialogTitle onClose={() => setOpen(false)}>
        {content.status !== 'archived' ? (
          <ArchiveIcon sx={{ mr: 0.5 }} />
        ) : (
          <UnarchiveIcon sx={{ mr: 0.5 }} />
        )}
        {content.status !== 'archived' ? t('Archive') : t('Unarchive')}
      </DialogTitle>
      <DialogContent>
        <Typography>
          {content.status !== 'archived'
            ? t('Are you sure you want to archive?')
            : t('Are you sure you want to unarchive this?')}
        </Typography>
        <Paper
          sx={{ px: 1.5, py: 1, mt: 2, bgcolor: 'grey.200' }}
          variant="outlined"
        >
          <Typography>
            <b>{t('Title')}:</b> {content.title}
          </Typography>
        </Paper>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={() => setOpen(false)}
          disabled={loading}
        >
          {t('Cancel')}
        </Button>
        <LoadingButton
          variant="contained"
          disableElevation
          loading={loading}
          onClick={handleArchive}
        >
          {t('Confirm')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
