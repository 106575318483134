import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import AttachFile from '@mui/icons-material/AttachFile';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';

function FileUploadButton({
  isIcon,
  children,
  disabled,
}: {
  isIcon?: boolean;
  children: any;
  disabled?: boolean;
}) {
  const { t } = useTranslation();
  if (isIcon) {
    return (
      <Tooltip title={t('Attach file') as string}>
        <IconButton
          component="label"
          size="large"
          color="primary"
          disabled={disabled}
        >
          <AttachFile />
          {children}
        </IconButton>
      </Tooltip>
    );
  }
  return (
    <Button
      component="label"
      size="small"
      variant="outlined"
      startIcon={<AttachFile />}
      disabled={disabled}
    >
      {t('Attach file')}
      {children}
    </Button>
  );
}

FileUploadButton.defaultProps = {
  isIcon: true,
  disabled: false,
};

export function FileUpload({
  file,
  setFile,
  filetypes,
  isIcon,
  disabled,
}: any) {
  const { t } = useTranslation();
  return (
    <Box id="file-upload" sx={{ display: 'flex', alignItems: 'center' }}>
      {!!file && (
        <Box>
          <Button
            size="small"
            sx={{ mr: 1 }}
            onClick={() => setFile(null)}
            disabled={disabled}
          >
            {t('Clear')}
          </Button>
        </Box>
      )}
      <Box>
        <FileUploadButton isIcon={isIcon} disabled={disabled}>
          <input
            id="file-upload-input"
            type="file"
            hidden
            accept={filetypes}
            onChange={(event: any) => {
              if (event.target.files.length > 0) {
                setFile(event.target.files[0]);
              }
            }}
            style={{
              opacity: 0,
              position: 'absolute',
              zIndex: -1,
            }}
          />
        </FileUploadButton>
      </Box>
    </Box>
  );
}

export default FileUpload;
