import React from 'react';
import { useTranslation } from 'react-i18next';

import { useDashboardContext } from '../../../_lib/context/dashboard-context';

import { ContentGroupsManage } from './content-groups-manage';
import { ContentStatusColors } from './content-status-colors';

import { AdvancedDashboardSummary } from './dashboard-overview';
import { AdvancedReportsTemplate } from './reports-template';
import { AdvancedDashboardColorPalette } from './dashboard-color-palette';
import { AdvancedContentStatusLongOverdue } from './content-status-long-overdue';
import { AdvancedCustomClassificationLabel } from './custom-classification-label';
import { AdvancedCustomRegionLabel } from './custom-region-label';
import { AdvancedCustomFinanceLabel } from './custom-finance-label';
import { AdvancedAreaLevels } from './area-levels';
import { AdvancedCustomWeeklyDigest } from './custom-weekly-digest';
import { AdvancedMapView } from './dashboard-map-view';
import { AdvancedAreaFields } from './area-fields';
import { AdvancedManageTargetCategories } from './target-unit-categories';
import { AdvancedOrderSummaryItems } from './order-summary-items';

export const SettingsAdvancedContext = React.createContext<any>('');

export function SettingsAdvancedContextProvider({ children }: any) {
  const { t } = useTranslation();
  const { user, dashboard } = useDashboardContext();

  const ogSettings = React.useMemo(() => {
    const settings: any = {
      dashboard: {
        title: t('Dashboard'),
        options: [],
        order: 0,
      },
      area: {
        title: t('Areas'),
        options: [],
        order: 1,
      },
      targets: {
        title: t('Targets'),
        options: [],
        order: 2,
      },
      activity: {
        title: t('Activities'),
        options: [],
        order: 3,
      },
    };

    // --------------------------------------------------------------------------------------------------------------------------
    // --------------------------------------------------------------------------------------------------------------------------
    // all dashboards

    settings.dashboard.options.push(
      {
        key: 'enableCustomPalette',
        title: t('Custom Dashboard Colour Palette'),
        label: t('Enable custom colour palette'),
        desc: t(
          'Activate this setting to allow you to customize your dashboard colour palette.'
        ),
        actions: <AdvancedDashboardColorPalette />,
        learnmore:
          'https://www.youtube.com/watch?v=b6z_R8zR29Q&list=PL-vB1EQe8SEShtp69tW3MoT4EoYbk8OJD&index=15',
      },
      {
        key: 'enableCalendarLink',
        title: t('Google Calendar / Outlook Calendar Integration'),
        label: t('Enable calendar integration'),
        desc: t(
          "Activate this setting to allow you to generate a calendar link to import your activities' and targets' deadline to Google Calendar, Outlook calendar, etc. in iCal / ICS format."
        ),
        beta: true,
        learnmore:
          'https://www.youtube.com/watch?v=_m-_JijwOV0&list=PL-vB1EQe8SEShtp69tW3MoT4EoYbk8OJD&index=28',
      },
      {
        key: 'enableEmailNotifications',
        title: t('Email Notifications'),
        label: t('Enable email notifications'),
        desc: t(
          'Activate or deactivate email notifications that are sent to users when activities and milestones are created, updated, or deleted.'
        ),
      },
      {
        key: 'enableImportExport',
        title: t('Excel Import / Export'),
        label: t('Enable Excel import / export'),
        desc: t(
          'Activate this setting to allow you to import and export your activities and milestones in Excel format.'
        ),
        experimental: true,
      }
    );

    // --------------------------------------------------------------------------------------------------------------------------
    // --------------------------------------------------------------------------------------------------------------------------
    // if full or supranational

    if (dashboard.mode === 'FULL' || dashboard.mode === 'SUPRANATIONAL') {
      settings.dashboard.options.push(
        {
          key: 'enableHomeOverview',
          title: t('Homepage Dashboard Summary'),
          label: t('Enable homepage dashboard summary'),
          desc: t(
            'Activate this setting to allow you to display an overview of all activities and milestones on the homepage.'
          ),
          actions: <AdvancedDashboardSummary />,
        },
        {
          key: 'enableAnalytics',
          title: t('Analytics'),
          label: t('Enable dashboard analytics'),
          desc: t(
            'Activate this setting to enable the analytics page that displays use summary charts.'
          ),
          beta: true,
        },
        {
          key: 'enableMapView',
          title: t('Dashboard Homepage Map View'),
          label: t('Enable map view'),
          desc: t(
            "Activate this setting to allow you to display and customize a map with clickable regions to filter your main areas' summary on the homepage."
          ),
          experimental: true,
          actions: <AdvancedMapView />,
        },
        {
          key: 'enableCustomFinanceLabels',
          title: t('Custom Finance Labels'),
          label: t('Enable custom finance labels'),
          desc: t(
            'Activate this setting to allow you to set custom labels for finance sections e.g. instead of "Budget" you can set this to "Project cost", "Disbursed" instead of "Spent", etc.'
          ),
          beta: true,
          actions: <AdvancedCustomFinanceLabel />,
        }
      );

      if (user.isSuperuser) {
        settings.dashboard.options.push({
          key: 'enableExecutiveView',
          title: t('Executive Summary View'),
          label: t('Enable executive summary view'),
          desc: t(
            'Activate this setting to allow you to display a summary view for executives.'
          ),
          experimental: true,
        });
      }
    }

    // --------------------------------------------------------------------------------------------------------------------------
    // --------------------------------------------------------------------------------------------------------------------------
    // full dashboard settings

    if (dashboard.mode === 'FULL') {
      // dashboard
      settings.dashboard.options.push({
        key: 'enableReports',
        title: t('PDF Reports Export'),
        label: t('Enable PDF reports export'),
        desc: t(
          'Activate this setting to allow you to export your activities and milestones as PDF reports.'
        ),
        beta: true,
        actions: <AdvancedReportsTemplate />,
        learnmore: 'https://youtu.be/YPzjR4k480Q',
      });

      // areas
      settings.area.options.push(
        {
          key: 'enableAreaLevels',
          title: t(`Priority Area Levels and Ancestry`),
          label: t(`Enable main area levels and ancestry`),
          desc: t(
            'Activate this setting to allow you to set custom levels and ancestry for each priority area.'
          ),
          beta: true,
          actions: dashboard.enableAreaLevels ? <AdvancedAreaLevels /> : null,
          learnmore:
            'https://www.youtube.com/watch?v=2Ru5KYH1fYQ&list=PL-vB1EQe8SEShtp69tW3MoT4EoYbk8OJD&index=23',
        },
        {
          key: 'enableLeadsMulti',
          title: t(`Multiple Leads and Co-Leads`),
          label: t(`Enable multiple leads and co-leads`),
          desc: t(
            'Activate this setting to allow you to assign multiple leads and co-leads to priority areas and interventions.'
          ),
          learnmore:
            'https://www.youtube.com/watch?v=L7o4LYnGCJQ&list=PL-vB1EQe8SEShtp69tW3MoT4EoYbk8OJD&index=25',
        },
        {
          key: 'enableLeadsFreeText',
          title: t(`Free Text for Leads and Co-Leads`),
          label: t(`Allow free text for leads and co-leads`),
          desc: t(
            'Activate this setting to allow you to use free text for leads and co-leads.'
          ),
          beta: true,
          learnmore:
            'https://www.youtube.com/watch?v=46QydCznpCg&list=PL-vB1EQe8SEShtp69tW3MoT4EoYbk8OJD&index=45',
        },
        {
          key: 'enableAreaPermissions',
          title: t('Area Permissions / Share'),
          label: t('Enable area permissions / share'),
          desc: t(
            'Activate this setting to allow users to share access to areas / interventions with read-only, comment, and write/edit permissions.'
          ),
          beta: true,
          learnmore:
            'https://www.youtube.com/watch?v=GQ45_YvtWww&list=PL-vB1EQe8SEShtp69tW3MoT4EoYbk8OJD&index=12',
        },
        {
          key: 'enableAreaArchive',
          title: t('Archiving of Areas / Interventions'),
          label: t('Enable the archiving of priority areas and interventions'),
          desc: t(
            'Activate this setting to enable the archiving of areas and interventions. Archived items can be unarchived from the list of archive items under the planning section.'
          ),
          beta: true,
        },
        {
          key: 'enableAreaFields',
          title: t(`Additional Area Fields`),
          label: t(`Enable additional area fields`),
          desc: t(
            'Activate this setting to allow you to add custom fields to each area.'
          ),
          beta: true,
          actions: dashboard.enableAreaFields ? <AdvancedAreaFields /> : null,
          learnmore:
            'https://www.youtube.com/watch?v=jc4W0kg8TLo&list=PL-vB1EQe8SETpCqF7-thK7s2Zg7hOpTzh&index=26',
        },
        {
          key: 'enableFilter',
          title: `${dashboard.interventionName} ${t('Filter')}`,
          label: `${t('Enable')} ${dashboard.interventionName} ${t('Filter')}`,
          desc: t(
            'The sub-area filter allows users to filter according to the different fields of sub-areas / interventions / projects such as "status" and financiers. Use it if you have many interventions or cross-cutting programmes.'
          ),
          beta: true,
        },
        {
          key: 'enableSummaryItemsOrder',
          title: t('Summary Items Ordering'),
          label: t('Custom Summary Items Ordering'),
          beta: true,
          actions: <AdvancedOrderSummaryItems />,
          desc: t(
            'Activate this to re-order summary items in area summary card e.g. targets summary to show before financials.'
          ),
          learnmore:
            'https://www.youtube.com/watch?v=6NwhyUlmGN8&list=PL-vB1EQe8SEShtp69tW3MoT4EoYbk8OJD&index=41',
        },

        {
          key: 'enableFinancierAmountSpent',
          title: t(`Financier Amount Spent`),
          label: t(`Enable financier amount spent`),
          desc: t(
            'Activate this setting to allow you to display the amount spent from each financier.'
          ),
          beta: true,
        },
        {
          key: 'enableCustomClassificationLabel',
          title: t('Custom Classification Label'),
          label: t('Enable custom classification label'),
          desc: t(
            'Activate this setting to allow you to display a custom classification label.'
          ),
          beta: true,
          actions: <AdvancedCustomClassificationLabel />,
          learnmore:
            'https://www.youtube.com/watch?v=U6SvDHmViAM&list=PL-vB1EQe8SEShtp69tW3MoT4EoYbk8OJD&index=21',
        },
        {
          key: 'enableCustomRegionLabel',
          title: t('Custom Region Label'),
          label: t('Enable custom region label'),
          desc: t(
            'Activate this setting to allow you to display a custom region label.'
          ),
          beta: true,
          actions: <AdvancedCustomRegionLabel />,
          learnmore:
            'https://www.youtube.com/watch?v=U6SvDHmViAM&list=PL-vB1EQe8SEShtp69tW3MoT4EoYbk8OJD&index=21',
        },
        {
          key: 'enableAreaStakeholders',
          title: t('Stakeholders List'),
          label: t('Enable list of stakeholders'),
          desc: t(
            'Activate this setting to allow you to display a list of stakeholders in your main or sub areas.'
          ),
          beta: true,
          learnmore: 'https://youtu.be/r7MXu5UCkw0',
        },

        {
          key: 'enablePriorityAreaFinancials',
          title: t('Main Area / Priority Area Financials'),
          label: t('Enable main area / priority area financials'),
          desc: t(
            'Activate this setting to allow you to display financials for main or priority areas.'
          ),
          beta: true,
          learnmore:
            'https://www.youtube.com/watch?v=vIi_yrP3SeE&list=PL-vB1EQe8SEShtp69tW3MoT4EoYbk8OJD&index=36',
        },
        {
          key: 'enableMultipleCurrencies',
          title: t('Multiple Currencies'),
          label: t('Enable multiple currencies for financials sections'),
          desc: t(
            'Activate this setting to allow you to set different currencies for your budgets and financiers.'
          ),
          beta: true,
        }
      );

      // targets
      settings.targets.options.push(
        {
          key: 'enableTargetBaselineValue',
          title: t('Targets Baseline Value'),
          label: t('Enable targets baseline value'),
          desc: t(
            'Activate this setting to allow you to set and display a baseline value for your targets.'
          ),
          beta: true,
        },
        {
          key: 'enableTargetTrendline',
          title: t('Targets Trendline'),
          label: t('Enable targets trendline'),
          desc: t(
            'Activate this setting to display a trendline for your targets.'
          ),
          beta: true,
          learnmore:
            'https://www.youtube.com/watch?v=PaPDT8fVcO0&list=PL-vB1EQe8SEShtp69tW3MoT4EoYbk8OJD&index=39',
        },
        {
          key: 'enableTargetFinancedAmount',
          title: t('Targets Financed Amount'),
          label: t('Enable targets financed amount'),
          desc: t(
            'Activate this setting to allow you to display the amount financed on targets.'
          ),
          beta: true,
        },
        {
          key: 'enableTargetCategories',
          title: t('Target Unit Categories'),
          label: t('Enable target unit categories'),
          desc: t(
            'Activate this setting to allow you to add custom categories to your target units.'
          ),
          actions: <AdvancedManageTargetCategories />,
          beta: true,
        },
        {
          key: 'enableTargetPartner',
          title: t('Targets Partner Label'),
          label: t('Enable partner label on targets'),
          desc: t(
            'Activate this setting to allow you to display a partner label on targets.'
          ),
          beta: true,
        }
      );

      // activities
      settings.activity.options.push({
        key: 'enableMilestonesCalculation',
        title: `${t('Milestones Calculation')}`,
        label: `${t(
          'Enable inclusion of milestones in calculation of progress'
        )}`,
        desc: t(
          'Activate this setting to allow you to include milestones in the calculation of progress.'
        ),
        beta: true,
      });
    }

    // --------------------------------------------------------------------------------------------------------------------------
    // --------------------------------------------------------------------------------------------------------------------------
    // supranational dashboard
    if (dashboard.mode === 'SUPRANATIONAL') {
      settings.area.options.push({
        key: 'enableDodPaSwitch',
        title: t('Switch Dashboard on Priority Area Click'),
        label: t('Enable dashboard switch on priority area click'),
        desc: t(
          'Activate this setting to allow you to switch the dashboard to the selected dashboard on click.'
        ),
        beta: true,
      });
    }

    // --------------------------------------------------------------------------------------------------------------------------
    // --------------------------------------------------------------------------------------------------------------------------
    // not supranational (mainly activities)

    if (dashboard.mode !== 'SUPRANATIONAL') {
      // dashboard
      settings.dashboard.options.push({
        key: 'enableCustomWeeklyDigest',
        title: t('Custom Weekly Digest Day and Time'),
        label: t('Enable custom weekly digest'),
        desc: t(
          'Activate this setting to send the weekly digest on a custom day and time of the week.'
        ),
        actions: <AdvancedCustomWeeklyDigest />,
      });

      // activities
      settings.activity.options.push(
        {
          key: 'enableActivityStartDate',
          title: `${t('Activity Start Date')}`,
          label: `${t('Add start date field to activities')}`,
          desc: t(
            'This feature adds a start date field to each activity and milestone.'
          ),
        },
        {
          key: 'enableActivityPercentage',
          title: `${t('Activity Percentage')}`,
          label: `${t('Enable activity percentage field')}`,
          desc: t(
            'This feature adds a percentage field to each activity allowing a quantitative progress update. If "Activity Phase" advanced setting is enabled, it will add an aggregate figure to each phase.'
          ),
          beta: true,
          learnmore:
            'https://www.youtube.com/watch?v=otYSqsgI-Ms&list=PL-vB1EQe8SEShtp69tW3MoT4EoYbk8OJD&index=37',
        },
        {
          key: 'enableActivityMultipleAssignees',
          title: `${t('Activity Multiple Assignees')}`,
          label: `${t('Enable assigning activities to multiple members')}`,
          desc: t(
            'This feature allows you to assign activities to multiple members.'
          ),
          beta: true,
        },
        {
          key: 'enableActivityGroup',
          title: t('Activity Phase'),
          label: t('Enable activity phase / grouping'),
          desc: t(
            'Actvity Phases / Grouping setting provides a feature to allow creating phases or categories that activities can be organized under.'
          ),
          beta: true,
          actions: <ContentGroupsManage />,
        },
        {
          key: 'enableActivityBlockers',
          title: t('Activity Blockers / Dependants / Tasks'),
          label: t(
            'Enable activity, milestone, and issue blockers / dependants / tasks feature'
          ),
          desc: t(
            "Blockers and dependants are a useful way to automatically connect activities, issues, milestones or issues to each other. NOTE: By marking one issue as blocking another, the start date and deadline will move according to the blocking activity/issue/milestone's dates."
          ),
          beta: true,
          learnmore: 'https://youtu.be/vnwFtbdJD2U',
        },
        {
          key: 'enableActivityStatusLongOverdue',
          title: `${t('Activity "Long Overdue" Status')}`,
          label: `${t('Enable "long overdue" status for activities')}`,
          desc: t(
            'Activate this setting to allow activities to be shown as "long overdue" when they are past their deadline by more than a certain amount of days.'
          ),
          beta: true,
          actions: <AdvancedContentStatusLongOverdue />,
        },
        {
          key: 'enableCustomActivityStatusColors',
          title: t('Custom Activity Status Colours'),
          label: t('Enable custom activity status colours'),
          desc: t(
            'Activate this setting to allow you to customize your activity status colours.'
          ),
          beta: true,
          actions: <ContentStatusColors />,
          learnmore: 'https://youtu.be/hBP6PzW8yEc',
        },
        {
          key: 'enableActivityCompletedOnEdit',
          title: t('Edit Activity Completion Date'),
          label: t('Enable activity completion date change'),
          desc: t(
            "Activate this setting to be able to change an activity's completion date."
          ),
          beta: true,
        },
        {
          key: 'enableActivityConfirmSave',
          title: `${t('Activity Edit Confirm Before Save')}`,
          label: `${t('Enable confirmation before save')}`,
          desc: t(
            'Avoid accidental edits to activities with this confirm before save feature.'
          ),
          beta: true,
          learnmore:
            'https://www.youtube.com/watch?v=PpX_5GgOuTk&list=PL-vB1EQe8SEShtp69tW3MoT4EoYbk8OJD&index=17',
        }
      );
    }

    return settings;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dashboard, t]);

  // context
  const contextValue = React.useMemo(
    () => ({
      settings: ogSettings,
    }),
    [ogSettings]
  );

  return (
    <SettingsAdvancedContext.Provider value={contextValue}>
      {children}
    </SettingsAdvancedContext.Provider>
  );
}

export function useSettingsAdvancedContext() {
  const context = React.useContext<any>(SettingsAdvancedContext);
  if (context === undefined) {
    throw new Error(
      'useSettingsAdvancedContext must be used within a SettingsAdvancedContextProvider'
    );
  }
  return context;
}
