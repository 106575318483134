import React from 'react';
import { useTranslation } from 'react-i18next';

import CardContent from '@mui/material/CardContent';
import Skeleton from '@mui/material/Skeleton';

import { randomId } from '@mui/x-data-grid-generator';

import { useQuery } from '@apollo/client';

import { useDashboardContext } from '../../../_lib/context/dashboard-context';
import { logError } from '../../../_lib/error';
import { SubscribeToMoreComponent } from '../../../components/subscribe-to-more';

import { PRIORITY_AREA_PLANNING_QUERY } from '../../../_lib/graphql/queries';
import { PRIORITY_AREA_PLANNING_SUBSCRIPTION } from '../../../_lib/graphql/subscriptions';

import { PlanningPriorityAreaDetailFormFull } from './form/full';
import { PlanningPriorityAreaDetailTracker } from './form/tracker';
import { PlanningPriorityAreaDetailSupranational } from './form/supranational';

import { PlanningAreaActions } from '../../area-actions';

export function PlanningPriorityAreaFormDetailContent({
  priorityAreaInitial,
  disabled,
  setDisabled,
  setOpenPermissionsDialog,
  listVariables,
}: any) {
  const { t } = useTranslation();

  const { dashboard } = useDashboardContext();

  const [loading, setLoading] = React.useState(false);
  const [errors, setErrors] = React.useState<any>({});

  const [priorityArea, setPriorityArea] = React.useState<any>(null);

  const [paChildren, setPaChildren] = React.useState<any>([]);
  const [interventions, setInterventions] = React.useState<any>([]);

  const [newFinanciers, setNewFinanciers] = React.useState<any>([]);
  const [newTargets, setNewTargets] = React.useState<any>([]);

  // -------------------------------------------------------------------------------------------------------

  const {
    data,
    loading: dataLoading,
    error: dataError,
    refetch: dataRefetch,
    subscribeToMore: dataSubscribeToMore,
  } = useQuery(PRIORITY_AREA_PLANNING_QUERY, {
    variables: {
      priorityAreaId: priorityAreaInitial.id,
    },
  });

  // -------------------------------------------------------------------------------------------------------
  // -------------------------------------------------------------------------------------------------------
  // handlers
  // -------------------------------------------------------------------------------------------------------

  // interventions or children
  const addArea = React.useCallback(({ areas, setAreas }: any) => {
    setAreas([
      ...areas,
      {
        fauxId: randomId(),
        name: '',
      },
    ]);
  }, []);

  const addPaChild = React.useCallback(() => {
    addArea({ areas: paChildren, setAreas: setPaChildren });
  }, [addArea, paChildren]);
  const addIntervention = React.useCallback(() => {
    addArea({ areas: interventions, setAreas: setInterventions });
  }, [addArea, interventions]);

  // financiers
  const addFinancier = React.useCallback(() => {
    setNewFinanciers((prev: any) => [
      ...prev,
      {
        amountDb: 0,

        fauxId: `${randomId()}`,
      },
    ]);
  }, []);

  // targets
  const addTarget = React.useCallback(() => {
    setNewTargets((prev: any) => [
      ...prev,
      {
        fauxId: randomId(),

        targetAmount: 0,
        targetFinancedAmount: 0,
        startAmount: 0,
        targetUnit: {
          id: null,
        },
        deadline: '2023-12-31',
        startedOn: '2023-01-01',
      },
    ]);
  }, []);

  // validation
  const valid = () => {
    const newErrors = {} as any;

    if (!priorityArea.name.trim()) {
      newErrors.name = t('You must include a name');
    }

    // leads
    if (dashboard.mode === 'FULL' || dashboard.mode === 'TRACKER') {
      const missingLeads = {
        lead: false,
        coLead: false,
      };
      if (!dashboard.enableLeadsMulti) {
        missingLeads.lead = !priorityArea.lead?.name;
        missingLeads.coLead = !priorityArea.coLead?.name;
      } else {
        missingLeads.lead = !priorityArea.leads?.length;
        missingLeads.coLead = !priorityArea.coLeads?.length;
      }
      if (dashboard.enableLeadsFreeText) {
        missingLeads.lead = missingLeads.lead && !priorityArea.leadsFreeText;
        missingLeads.coLead =
          missingLeads.coLead && !priorityArea.coLeadsFreeText;
      }
      if (missingLeads.lead) {
        newErrors.lead = t('You must include a lead');
      }
      if (missingLeads.coLead) {
        newErrors.coLead = t('You must include a co-lead');
      }
    }

    if (dashboard.mode === 'FULL') {
      // financials
      if (dashboard.enablePriorityAreaFinancials) {
        // budget
        if (priorityArea.budget && !Number.isInteger(priorityArea.budget)) {
          newErrors.budget = t('Invalid budget. Must be a whole number');
        }
        if (
          priorityArea.currentlySpent &&
          !Number.isInteger(priorityArea.currentlySpent)
        ) {
          newErrors.currenltySpent = t(
            'Invalid amount. Must be a whole number'
          );
        }

        // financiers
        if (priorityArea.financiersDb?.length) {
          priorityArea.financiersDb.forEach((financier: any, i: number) => {
            if (!financier.financier?.id) {
              newErrors[`financiers[${i}].financier`] = t(
                'You must include a financier'
              );
            }
          });
        }
        if (newFinanciers.length) {
          newFinanciers.forEach((financier: any, i: number) => {
            if (!financier.financier?.id) {
              newErrors[`newFinanciers[${i}].financier`] = t(
                'You must include a financier'
              );
            }
          });
        }
      }

      // targets
      if (priorityArea.targetsDb?.length) {
        priorityArea.targetsDb.forEach((target: any) => {
          if (!target.targetUnit?.id) {
            newErrors.targets = t(
              'You must include an indicator for each target'
            );
          }
        });
      }
      if (newTargets.length) {
        newTargets.forEach((target: any) => {
          if (!target.targetUnit?.id) {
            newErrors.targets = t(
              'You must include an indicator for each target'
            );
          }
        });
      }
    }

    setErrors({ ...newErrors });
    return Object.keys(newErrors).length === 0;
  };

  // -------------------------------------------------------------------------------------------------------
  // -------------------------------------------------------------------------------------------------------
  // effects
  // -------------------------------------------------------------------------------------------------------

  // update from data
  React.useEffect(() => {
    if (data?.priorityArea) {
      setPriorityArea((prev: any) => {
        // if no previous data, or disabled, return the data from the query
        if (!prev || disabled) {
          return data.priorityArea;
        }
        return prev;
      });

      // children
      setPaChildren((prev: any) => {
        // if no previous data, or disabled, return the data from the query
        if (!prev || disabled) {
          return data.priorityArea.children;
        }
        // remove children that have been deleted
        const newChildren: any = prev.filter(
          (child: any) =>
            !child.id ||
            !!data.priorityArea.children?.find((c: any) => c.id === child.id)
        );

        // include any new children
        data.priorityArea.children?.forEach((child: any) => {
          if (!prev.find((c: any) => c.id === child.id)) {
            newChildren.push(child);
          }
        });
        return newChildren;
      });

      // interventions
      setInterventions((prev: any) => {
        // if no previous data, or disabled, return the data from the query
        if (!prev || disabled) {
          return data.priorityArea.interventionsDb?.length
            ? data.priorityArea.interventionsDb
            : [
                {
                  name: '',
                  reference: `${data.priorityArea.reference}.1`,
                },
              ];
        }

        // remove interventions that have been deleted
        const newInterventions: any = prev.filter(
          (intervention: any) =>
            !intervention.id ||
            !!data.priorityArea.interventionsDb?.find(
              (i: any) => i.id === intervention.id
            )
        );

        // include any new interventions
        data.priorityArea.interventionsDb?.forEach((intervention: any) => {
          if (!prev.find((i: any) => i.id === intervention.id)) {
            newInterventions.push(intervention);
          }
        });

        return newInterventions;
      });
    }
  }, [data?.priorityArea, disabled]);

  // error
  React.useEffect(() => {
    if (dataError) logError(dataError);
  }, [dataError]);

  // refetch if need
  React.useEffect(() => {
    if (!data && !dataError && !dataLoading) dataRefetch();
  }, [data, dataError, dataLoading, dataRefetch]);

  // -------------------------------------------------------------------------------------------------------

  if (!priorityArea)
    return <Skeleton variant="rectangular" height={200} sx={{ m: 2, mt: 0 }} />;

  // -------------------------------------------------------------------------------------------------------

  // component
  return (
    <>
      <CardContent sx={{ pt: 1, px: 3, pb: 3 }}>
        {/* Actions */}
        <PlanningAreaActions
          area={priorityArea}
          disabled={disabled}
          setDisabled={setDisabled}
          openLink={
            dashboard.enableAreaLevels
              ? priorityArea.urlRelative
              : `/${dashboard.priorityAreasSlug}/${priorityArea.id}`
          }
          setOpenPermissionsDialog={setOpenPermissionsDialog}
          listVariables={listVariables}
        />

        {/* FULL DASHBOARD */}
        {dashboard.mode === 'FULL' && (
          <PlanningPriorityAreaDetailFormFull
            disabled={disabled || loading}
            loading={loading}
            priorityArea={priorityArea}
            setPriorityArea={setPriorityArea}
            errors={errors}
            paChildren={paChildren}
            setPaChildren={setPaChildren}
            addPaChild={addPaChild}
            interventions={interventions}
            setInterventions={setInterventions}
            addIntervention={addIntervention}
            newFinanciers={newFinanciers}
            setNewFinanciers={setNewFinanciers}
            addFinancier={addFinancier}
            newTargets={newTargets}
            setNewTargets={setNewTargets}
            addTarget={addTarget}
            setDisabled={setDisabled}
            setLoading={setLoading}
            valid={valid}
          />
        )}

        {/* TRACKER ONLY */}
        {dashboard.mode === 'TRACKER' && (
          <PlanningPriorityAreaDetailTracker
            disabled={disabled || loading}
            loading={loading}
            priorityArea={priorityArea}
            setPriorityArea={setPriorityArea}
            errors={errors}
            interventions={interventions}
            setInterventions={setInterventions}
            addIntervention={addIntervention}
            setDisabled={setDisabled}
            valid={valid}
            setLoading={setLoading}
            paChildren={paChildren}
            newFinanciers={newFinanciers}
            setNewFinanciers={setNewFinanciers}
            newTargets={newTargets}
            setNewTargets={setNewTargets}
          />
        )}

        {/* SUPRANATIONAL */}
        {dashboard.mode === 'SUPRANATIONAL' && (
          <PlanningPriorityAreaDetailSupranational
            disabled={disabled || loading}
            priorityArea={priorityArea}
            setPriorityArea={setPriorityArea}
            errors={errors}
            loading={loading}
            setDisabled={setDisabled}
            valid={valid}
            setLoading={setLoading}
            paChildren={paChildren}
            newFinanciers={newFinanciers}
            setNewFinanciers={setNewFinanciers}
            newTargets={newTargets}
            setNewTargets={setNewTargets}
          />
        )}
      </CardContent>

      {/* ------------------------------------------------------------------------------------------------------- */}

      {/* subscribe to more */}
      <SubscribeToMoreComponent
        subscribeToMore={dataSubscribeToMore}
        document={PRIORITY_AREA_PLANNING_SUBSCRIPTION}
        variables={{
          priorityAreaId: priorityAreaInitial.id,
        }}
      />
    </>
  );
}
