import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  subDays,
  subWeeks,
  startOfWeek,
  endOfWeek,
  subMonths,
  startOfMonth,
  endOfMonth,
  subQuarters,
  startOfQuarter,
  endOfQuarter,
  subYears,
  startOfYear,
  endOfYear,
} from 'date-fns';
import { isEqual } from 'lodash';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListSubheader from '@mui/material/ListSubheader';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { useReportsContext } from '../../../_lib/context/reports-context';
import { getGraphQLDate } from '../../../_utils/general-utils';
import { useOnlineStatus } from '../../../_lib/offline/use-online-status';

const DATE_OPTIONS = {
  none: {
    startDate: null,
    endDate: null,
  },
  custom: null,
  last_7_days: {
    startDate: getGraphQLDate(subDays(new Date(), 7)),
    endDate: getGraphQLDate(new Date()),
  },
  last_30_days: {
    startDate: getGraphQLDate(subDays(new Date(), 30)),
    endDate: getGraphQLDate(new Date()),
  },
  prev_week: {
    startDate: getGraphQLDate(
      startOfWeek(subWeeks(new Date(), 1), { weekStartsOn: 1 })
    ),
    endDate: getGraphQLDate(
      endOfWeek(subWeeks(new Date(), 1), { weekStartsOn: 1 })
    ),
  },
  prev_1_month: {
    startDate: getGraphQLDate(startOfMonth(subMonths(new Date(), 1))),
    endDate: getGraphQLDate(endOfMonth(subMonths(new Date(), 1))),
  },
  prev_1_quarter: {
    startDate: getGraphQLDate(startOfQuarter(subQuarters(new Date(), 1))),
    endDate: getGraphQLDate(endOfQuarter(subQuarters(new Date(), 1))),
  },
  prev_1_year: {
    startDate: getGraphQLDate(startOfYear(subYears(new Date(), 1))),
    endDate: getGraphQLDate(endOfYear(subYears(new Date(), 1))),
  },
  year_to_date: {
    startDate: getGraphQLDate(startOfYear(new Date())),
    endDate: getGraphQLDate(new Date()),
  },
} as any;

export function ReportsEditFiltersDates() {
  const { t } = useTranslation();

  const { report, filterDates, updateFilterDates, loading, reportDataReset } =
    useReportsContext();

  const isOnline = useOnlineStatus();

  const [datesOption, setDatesOption] = React.useState('none');
  const [localDates, updateLocalDates] = React.useReducer(
    (state: any, newState: any) => ({ ...state, ...newState }),
    null
  );

  // ---------------------------------------------------------------------------------------------------
  // ---------------------------------------------------------------------------------------------------
  // handlers
  // ---------------------------------------------------------------------------------------------------

  const handleSelectChange = React.useCallback((event: any) => {
    setDatesOption(event.target.value);

    if (DATE_OPTIONS[event.target.value]) {
      updateLocalDates({
        startDate: DATE_OPTIONS[event.target.value].startDate,
        endDate: DATE_OPTIONS[event.target.value].endDate,
      });
    }
  }, []);

  const handleStartChange = React.useCallback(
    (newValue: Date | null) => {
      if (
        !localDates?.startDate ||
        newValue?.getTime() !== new Date(localDates?.startDate).getTime()
      ) {
        setDatesOption('custom');
        updateLocalDates({
          startDate: newValue ? getGraphQLDate(newValue) : null,
        });
      }
    },
    [localDates?.startDate]
  );

  const handleEndChange = React.useCallback(
    (newValue: Date | null) => {
      if (
        !localDates?.endDate ||
        newValue?.getTime() !== new Date(localDates?.endDate).getTime()
      ) {
        setDatesOption('custom');
        updateLocalDates({
          endDate: newValue ? getGraphQLDate(newValue) : null,
        });
      }
    },
    [localDates?.endDate]
  );

  const handleMouseDown = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const handleUpdate = React.useCallback(async () => {
    updateFilterDates(localDates);
    reportDataReset?.reverse().forEach((reset: any) => reset.fn());
  }, [localDates, reportDataReset, updateFilterDates]);

  // ---------------------------------------------------------------------------------------------------
  // ---------------------------------------------------------------------------------------------------
  // effects
  // ---------------------------------------------------------------------------------------------------

  // if report, update local dates
  React.useEffect(() => {
    if (report && !localDates) {
      updateLocalDates({
        startDate: report.filterStartDate,
        endDate: report.filterEndDate,
      });
    }
  }, [localDates, report]);

  // update dates option according to local dates
  React.useEffect(() => {
    if (localDates?.startDate && localDates?.endDate) {
      setDatesOption((prev: any) => {
        const newDatesOption = Object.keys(DATE_OPTIONS).find((key) => {
          const option = DATE_OPTIONS[key];
          return (
            option &&
            option.startDate === localDates?.startDate &&
            option.endDate === localDates?.endDate
          );
        });
        return newDatesOption || prev;
      });
    }
  }, [localDates]);

  // ---------------------------------------------------------------------------------------------------

  return (
    <List
      sx={{ width: '100%', bgcolor: 'background.paper' }}
      aria-labelledby="areas-list-subheader"
      subheader={
        <ListSubheader
          component="div"
          id="areas-list-subheader"
          sx={{
            position: 'initial',
          }}
        >
          {t('Dates')}
        </ListSubheader>
      }
    >
      <ListItem>
        <FormControl fullWidth size="small" disabled={loading || !isOnline}>
          <InputLabel id="dates-option-label">{t('Dates Option')}</InputLabel>
          <Select
            labelId="dates-option-label"
            id="dates-option"
            value={datesOption}
            label={t('Dates Option')}
            onChange={handleSelectChange}
          >
            <MenuItem value="none">
              <Typography
                component="span"
                sx={{ fontStyle: 'italic', color: 'text.disabled' }}
              >
                {t('None')}
              </Typography>
            </MenuItem>
            <MenuItem value="custom">{t('Custom')}</MenuItem>
            <MenuItem value="last_7_days">{t('Last 7 days')}</MenuItem>
            <MenuItem value="last_30_days">{t('Last 30 days')}</MenuItem>
            <MenuItem value="prev_week">{t('Previous week')}</MenuItem>
            <MenuItem value="prev_1_month">{t('Previous 1 month')}</MenuItem>
            <MenuItem value="prev_1_quarter">{t('Previous quarter')}</MenuItem>
            <MenuItem value="prev_1_year">{t('Previous year')}</MenuItem>
            <MenuItem value="year_to_date">{t('Year to date')}</MenuItem>
          </Select>
        </FormControl>
      </ListItem>
      <ListItem>
        <DatePicker
          label={t('Start date')}
          format="dd/MM/yyyy"
          value={localDates?.startDate ? new Date(localDates?.startDate) : null}
          maxDate={
            localDates?.endDate ? new Date(localDates?.endDate) : undefined
          }
          onChange={handleStartChange}
          slotProps={{
            actionBar: {
              actions: ['clear'],
            },
            textField: {
              size: 'small',
              fullWidth: true,
            },
          }}
          disabled={loading || !isOnline}
        />
      </ListItem>
      <ListItem>
        <DatePicker
          label={t('End date')}
          format="dd/MM/yyyy"
          value={localDates?.endDate ? new Date(localDates?.endDate) : null}
          minDate={
            localDates?.startDate ? new Date(localDates?.startDate) : undefined
          }
          onChange={handleEndChange}
          slotProps={{
            actionBar: {
              actions: ['clear'],
            },
            textField: {
              size: 'small',
              fullWidth: true,
            },
          }}
          disabled={loading || !isOnline}
        />
      </ListItem>

      {/* update button */}
      {!isEqual(localDates, filterDates) && (
        <ListItem sx={{ justifyContent: 'flex-end' }}>
          <Button
            variant="outlined"
            color="primary"
            size="small"
            onClick={handleUpdate}
            disabled={loading || !isOnline}
            disableElevation
          >
            {t('Update')}
          </Button>
        </ListItem>
      )}
    </List>
  );
}
