import React from 'react';
import { gql } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import Button from '@mui/material/Button';

import { PlanningFieldsInputManageDialog } from '../../../../fields-input/_manage-dialog';

const GET_ITEMS = gql`
  query AreaFieldOptions($areaFieldConfigId: ID!) {
    areaFieldOptions(areaFieldConfigId: $areaFieldConfigId) {
      id
      name
    }
  }
`;

const SUBSCRIBE_ITEMS = gql`
  subscription AreaFieldOptions($areaFieldConfigId: ID!) {
    areaFieldOptions(areaFieldConfigId: $areaFieldConfigId) {
      id
      name
    }
  }
`;

const CREATE_ITEM = gql`
  mutation AreaFieldOptionCreate($areaFieldConfigId: ID!, $name: String!) {
    areaFieldOptionCreate(areaFieldConfigId: $areaFieldConfigId, name: $name) {
      areaFieldOption {
        id
        name
      }
      areaFieldOptions {
        id
        name
      }
    }
  }
`;

const UPDATE_ITEM = gql`
  mutation AreaFieldOptionUpdate($id: ID!, $name: String!) {
    areaFieldOptionUpdate(id: $id, name: $name) {
      success
      message
      areaFieldOptions {
        id
        name
      }
    }
  }
`;

const DELETE_ITEM = gql`
  mutation AreaFieldOptionDelete($id: ID!) {
    areaFieldOptionDelete(id: $id) {
      success
      message
      areaFieldOptions {
        id
        name
      }
    }
  }
`;

const UPDATE_ITEM_ORDER = gql`
  mutation AreaFieldOptionsUpdateOrder($ids: [ID!]!) {
    areaFieldOptionsUpdateOrder(ids: $ids) {
      success
      message
      areaFieldOptions {
        id
        name
      }
    }
  }
`;

export function AdvancedAreaFieldsDetailManageSelectOptions({ config }: any) {
  const { t } = useTranslation();

  const [openManageDialog, setOpenManageDialog] = React.useState(false);

  return (
    <>
      {/* button */}
      <Button
        variant="outlined"
        size="small"
        onClick={() => setOpenManageDialog(true)}
      >
        {t('Manage Options')}
      </Button>

      {/* dialog */}
      <PlanningFieldsInputManageDialog
        open={openManageDialog}
        setOpen={setOpenManageDialog}
        GET_ITEMS={GET_ITEMS}
        SUBSCRIBE_ITEMS={SUBSCRIBE_ITEMS}
        getItemsVariables={{ areaFieldConfigId: config.id }}
        CREATE_ITEM={CREATE_ITEM}
        createItemOpName="areaFieldOptionCreate"
        createItemVariables={{ areaFieldConfigId: config.id }}
        UPDATE_ITEM={UPDATE_ITEM}
        updateItemOpName="areaFieldOptionUpdate"
        DELETE_ITEM={DELETE_ITEM}
        deleteItemOpName="areaFieldOptionDelete"
        UPDATE_ITEM_ORDER={UPDATE_ITEM_ORDER}
        updateItemOrderOpName="areaFieldOptionsUpdateOrder"
        dataKey="areaFieldOptions"
        dialogTitle={`${t('Manage')}: ${config.label}`}
        dialogInputLabel={t('Name')}
        dialogAddLabel={`${t('Add')}: ${config.label}`}
      />
    </>
  );
}
