import React from 'react';
import { useTranslation } from 'react-i18next';

import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';

import Link from '@mui/material/Link';
import { removeCommas, withCommas } from '../../_utils/general-utils';
import { CurrencySymbol } from '../../components/currency-text';
import { DashboardContext } from '../../_lib/context/dashboard-context';
import { CurrencyChangeDialog } from '../../components/currency-dialog';

export function PlanningFieldsInputBudget({
  disabled,
  errors,
  area,
  setArea,
}: any) {
  const { t } = useTranslation();

  const { dashboard } = React.useContext(DashboardContext);

  const [openCurrencyDialog, setOpenCurrencyDialog] = React.useState(false);

  const [amountStates, setAmountStates] = React.useState<any>({
    budget: 'mouseout',
    spent: 'mouseout',
  });

  const budgetAmountState = {
    editing: area.budgetDb,
    mouseout: withCommas(parseFloat(area.budgetDb), dashboard.language, 2),
  } as any;

  const spentAmountState = {
    editing: area.currentlySpentDb,
    mouseout: withCommas(
      parseFloat(area.currentlySpentDb),
      dashboard.language,
      2
    ),
  } as any;

  // ------------------------------------------------------------------------------------------------------------------
  // ------------------------------------------------------------------------------------------------------------------
  // handlers
  // ------------------------------------------------------------------------------------------------------------------

  const handleClick = (e: React.MouseEvent) => {
    e.preventDefault();
    setOpenCurrencyDialog(true);
  };

  const handleSelectedValue = (value: string) => {
    setArea({
      ...area,
      currency: value,
    });
    setOpenCurrencyDialog(false);
  };

  // ------------------------------------------------------------------------------------------------------------------

  return (
    <>
      {/* Set currency for intervention dialog */}
      <CurrencyChangeDialog
        open={openCurrencyDialog}
        setOpen={setOpenCurrencyDialog}
        setValue={handleSelectedValue}
        currency={area.currency || dashboard.currency}
      />

      {/* Budget */}
      <Grid item xs={12} sm={6} md={3}>
        <TextField
          name="budget"
          label={
            dashboard.enableCustomFinanceLabels
              ? dashboard.customFinanceBudgetLabel
              : t('Budget')
          }
          type="text"
          variant="outlined"
          size="small"
          value={budgetAmountState[amountStates.budget]}
          onFocus={() =>
            setAmountStates((old: any) => ({ ...old, budget: 'editing' }))
          }
          onBlur={() =>
            setAmountStates((old: any) => ({ ...old, budget: 'mouseout' }))
          }
          onChange={(event) =>
            setArea({
              ...area,
              budgetDb: removeCommas(event.target.value),
            })
          }
          disabled={disabled}
          InputProps={{
            inputProps: {
              inputMode: 'numeric',
              pattern: '[0-9]',
              style: { textAlign: 'right' },
            },
            startAdornment: (
              <InputAdornment
                position="start"
                sx={{ color: disabled && 'text.disabled' }}
              >
                <CurrencySymbol
                  currency={
                    dashboard.enableMultipleCurrencies ? area.currency : null
                  }
                />
              </InputAdornment>
            ),
          }}
          fullWidth
          helperText={errors.budget}
          error={!!errors.budget}
        />
        {dashboard.enableMultipleCurrencies && !disabled && (
          <Link fontSize=".8rem" href="#change-currency" onClick={handleClick}>
            {t('Change currency')}
          </Link>
        )}
      </Grid>

      {/* spent */}
      <Grid item xs={12} sm={6} md={3}>
        <TextField
          name="currentlySpent"
          label={
            dashboard.enableCustomFinanceLabels
              ? dashboard.customFinanceSpentLabel
              : t('Spent')
          }
          variant="outlined"
          size="small"
          value={spentAmountState[amountStates.spent]}
          onFocus={() =>
            setAmountStates((old: any) => ({ ...old, spent: 'editing' }))
          }
          onBlur={() =>
            setAmountStates((old: any) => ({ ...old, spent: 'mouseout' }))
          }
          onChange={(event) =>
            setArea({
              ...area,
              currentlySpentDb: removeCommas(event.target.value),
            })
          }
          disabled={disabled || dashboard.enableFinancierAmountSpent}
          InputProps={{
            inputProps: {
              inputMode: 'numeric',
              pattern: '[0-9]*',
              style: { textAlign: 'right' },
            },
            startAdornment: (
              <InputAdornment
                position="start"
                sx={{ color: disabled && 'text.disabled' }}
              >
                <CurrencySymbol
                  currency={
                    dashboard.enableMultipleCurrencies ? area.currency : null
                  }
                />
              </InputAdornment>
            ),
          }}
          fullWidth
          helperText={errors.currentlySpent}
          error={!!errors.currentlySpent}
        />
      </Grid>
    </>
  );
}
