import React from 'react';
import { useTranslation } from 'react-i18next';
import { differenceInDays } from 'date-fns';

import Box from '@mui/material/Box';
import Typography, { TypographyProps } from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';

import { useDashboardContext } from '../../../_lib/context/dashboard-context';
import { daysPast, getStringDate } from '../../../_utils/general-utils';

export function ColumnDate({
  date,
  typographyProps,
  typographyEmptyProps,
  tooltipLabel,
  textDateLabel,
}: {
  date?: any;
  typographyProps?: TypographyProps | any;
  typographyEmptyProps?: TypographyProps;
  tooltipLabel?: string;
  textDateLabel?: string;
}) {
  const { t } = useTranslation();
  const {
    dashboard: { language },
  } = useDashboardContext();

  const { sx: typographySx = {}, ...typographyRest } = typographyProps;

  const tooltipLabelString = tooltipLabel ? `${tooltipLabel}: ` : '';
  const textDateLabelString = textDateLabel ? `${textDateLabel}: ` : '';

  const tooltipDate =
    differenceInDays(new Date(date), new Date()) < -5 ||
    differenceInDays(new Date(date), new Date()) > 5
      ? daysPast(date, t, language)
      : getStringDate(date, language);

  const textDate =
    differenceInDays(new Date(date), new Date()) > -5 &&
    differenceInDays(new Date(date), new Date()) < 5
      ? daysPast(date, t, language)
      : getStringDate(date, language);

  return date ? (
    <Box sx={{ display: 'flex', maxWidth: '100%' }}>
      <Tooltip title={`${tooltipLabelString}${tooltipDate}`} arrow>
        <Typography
          variant="body2"
          {...typographyRest}
          sx={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            textAlign: 'left',
            '&:hover': {
              whiteSpace: 'normal',
              overflow: 'visible',
              textOverflow: 'visible',
            },
            ...typographySx,
          }}
        >
          {textDateLabelString}
          {textDate}
        </Typography>
      </Tooltip>
    </Box>
  ) : (
    <Typography variant="body2" textAlign="center" {...typographyEmptyProps}>
      -
    </Typography>
  );
}

ColumnDate.defaultProps = {
  date: null,
  typographyProps: {},
  typographyEmptyProps: {},
  tooltipLabel: '',
  textDateLabel: '',
};
