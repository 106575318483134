import { View } from '@react-pdf/renderer';
import { Style } from '@react-pdf/types';

import {
  getFinanceMethods,
  getFinanciers,
} from '../../../../../_utils/finance-utils';

import Boxed from '../../ui/boxed';

import { ReportsPdfSectionsFinanceFunding } from './funding';
import { ReportsPdfSectionsFinanceFinanciers } from './financiers';
import { ReportsPdfSectionsFinanceMethods } from './methods';

export function ReportsPdfSectionsFinance({ t, reportData, areas }: any) {
  const boxedStyles: Style & any = {
    width: '32%',
    flexDirection: 'column',
    spacing: 2,
    titleFlexGrow: 0,
    contentFlexGrow: 1,
  };

  const financiers = getFinanciers(areas);
  const financeMethods = getFinanceMethods(areas);

  return (
    <View
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent:
          !!financiers?.length && !!financeMethods?.length
            ? 'space-between'
            : 'space-around',
      }}
    >
      <Boxed
        {...boxedStyles}
        title={t('Funding')}
        content={
          <ReportsPdfSectionsFinanceFunding
            t={t}
            dashboard={reportData.dashboard}
            areas={areas}
          />
        }
      />
      {!!financiers?.length && (
        <Boxed
          {...boxedStyles}
          title={t('Financiers')}
          content={
            <ReportsPdfSectionsFinanceFinanciers
              t={t}
              dashboard={reportData.dashboard}
              areas={areas}
            />
          }
        />
      )}
      {!!financeMethods?.length && (
        <Boxed
          {...boxedStyles}
          title={t('Finance Methods')}
          content={
            <ReportsPdfSectionsFinanceMethods
              t={t}
              reportData={reportData}
              areas={areas}
            />
          }
        />
      )}
    </View>
  );
}
