import React from 'react';

import Skeleton from '@mui/material/Skeleton';

import { TargetsSummaryCard } from '../../components/targets-summary/card';
import { useSummary } from '../../_utils/hooks/queries/use-summary';

export function DashboardSummaryTargets({
  filterPriorityAreaIds,
  filterInterventionIds,
}: any) {
  const [rowsPerPage, setRowsPerPage] = React.useState(3);
  const [page, setPage] = React.useState(0);

  const variables = React.useMemo(
    () => ({
      filterPriorityAreaIds,
      filterInterventionIds,
      paginationTargetsLimit: rowsPerPage,
      paginationTargetsOffset: page * rowsPerPage,
    }),
    [page, rowsPerPage, filterPriorityAreaIds, filterInterventionIds]
  );

  const { loading, data, error } = useSummary('targets', variables);

  if (error) return null;
  if (loading || !data)
    return <Skeleton variant="rectangular" height={70} sx={{ my: 2 }} />;

  if (data.dashboard.summary.targets.progressPerc === -1) return null;

  // ------------------------------------------------------------------------------------------------------------------

  return (
    <TargetsSummaryCard
      id="dashboard-overview-targets-table-open"
      targetsSummary={data.dashboard.summary.targets}
      expanded
      includePriorityAreas
      rowsPerPage={rowsPerPage}
      setRowsPerPage={setRowsPerPage}
      page={page}
      setPage={setPage}
    />
  );
}
