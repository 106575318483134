import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Typography } from '@mui/material';
import { Box } from '@mui/system';

import { AdvancedCustomFinanceLabelDialog } from './dialog';
import { DashboardContext } from '../../../../_lib/context/dashboard-context';

export function AdvancedCustomFinanceLabel({ enabled }: any) {
  const { t } = useTranslation();
  const [openDialog, setOpenDialog] = React.useState(false);
  const {
    dashboard: {
      enableCustomFinanceLabels,
      customFinanceBudgetLabel,
      customFinanceSpentLabel,
      customFinanceFinancedLabel,
    },
  } = React.useContext(DashboardContext);
  const defaultLabels = `${t('Budget')}, ${t('Spent')}, ${t('Financed')}`;
  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  return (
    <>
      <AdvancedCustomFinanceLabelDialog
        open={openDialog}
        setOpen={setOpenDialog}
        title="Custom Cost Label"
      />

      <Box sx={{ display: { xs: 'block', sm: 'flex' }, alignItems: 'center' }}>
        {enabled && (
          <Box sx={{ mr: 1.5 }}>
            <Button
              variant="outlined"
              size="small"
              onClick={() => handleOpenDialog()}
            >
              {t('Customize Finance Labels')}
            </Button>
          </Box>
        )}
        <Typography>
          <Typography variant="body2" component="span" color="textSecondary">
            {t('Current labels')}
            {': '}
          </Typography>
          {enableCustomFinanceLabels
            ? `${customFinanceBudgetLabel}, ${customFinanceSpentLabel}, ${customFinanceFinancedLabel}`
            : defaultLabels}
        </Typography>
      </Box>
    </>
  );
}
