import React from 'react';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import ButtonBase from '@mui/material/ButtonBase';
import Typography from '@mui/material/Typography';

import { CurrencyChangeDialog } from '../../../../components/currency-dialog';

import CurrencyListJSON from '../../../../_lib/data/currency-list.json';

export function DashboardFormDetailsCurrency({
  newDashboard,
  setNewDashboard,
  disabled,
}: any) {
  const { t } = useTranslation();

  const currencyList = React.useMemo(() => {
    return CurrencyListJSON.filter((c: any) => !!c.alpha_code);
  }, []);

  const { currency, language } = newDashboard;

  const currencyFull =
    currencyList.find(
      (c: any) => `${c.entity} :: ${c.alpha_code}` === currency
    ) ||
    currencyList.find(
      (c: any) =>
        c.alpha_code === 'USD' && c.entity === 'UNITED STATES OF AMERICA (THE)'
    );

  const [openDialog, setOpenDialog] = React.useState(false);

  const handleSelectedValue = (value: string) => {
    setNewDashboard({
      ...newDashboard,
      currency: value,
    });
    setOpenDialog(false);
  };

  return (
    <>
      {/* dialog */}
      <CurrencyChangeDialog
        open={openDialog}
        setOpen={setOpenDialog}
        setValue={handleSelectedValue}
        currency={newDashboard.currency}
        disableClearable
      />

      {/* currency */}
      <Box sx={{ mt: 1, ml: 1 }}>
        <Typography
          variant="body2"
          sx={{
            color: disabled ? 'text.disabled' : 'text.secondary',
          }}
        >
          {t('Currency')}:{' '}
          <ButtonBase
            component="a"
            disabled={disabled}
            onClick={() => setOpenDialog(true)}
            sx={{
              cursor: 'pointer',
              textDecoration: 'underline',
              '&:hover': {
                color: 'secondary.main',
              },
              alignItems: 'center',
            }}
            color="primary"
            disableRipple
          >
            {!!currencyFull && currencyFull.currency}{' '}
            <Typography variant="caption" sx={{ ml: 0.5, lineHeight: 1 }}>
              ({t('e.g')}{' '}
              {new Intl.NumberFormat(language, {
                style: 'currency',
                currency: (currencyFull && currencyFull.alpha_code) || 'USD',
              }).format(10000)}
              )
            </Typography>
          </ButtonBase>
        </Typography>
      </Box>
    </>
  );
}
