import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { ReactErrorComponent } from '../../_lib/react-error';

export function MyAccountSection({
  icon,
  title,
  titleChip,
  description,
  action,
}: {
  icon: React.ReactElement;
  title: string;
  titleChip?: React.ReactNode;
  description: string;
  action: React.ReactNode;
}) {
  // ---------------------------------------------------------------------------------------------------------------------

  return (
    <ErrorBoundary FallbackComponent={ReactErrorComponent}>
      <Box sx={{ display: 'flex' }}>
        {/* icon */}
        {React.cloneElement(icon, {
          sx: { mr: 1, mt: 0.5 },
        })}

        {/* content */}
        <Box sx={{ flexGrow: 1.5 }}>
          <Box sx={{ mb: 1 }}>
            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
              {title}{' '}
            </Typography>
            {titleChip}
          </Box>
          <Typography variant="body2" sx={{ color: 'grey.700', mb: 2 }}>
            {description}
          </Typography>
          <Box>{action}</Box>
        </Box>
      </Box>
    </ErrorBoundary>
  );
}

MyAccountSection.defaultProps = {
  titleChip: null,
};
