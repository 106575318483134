import React from 'react';
import { useTranslation } from 'react-i18next';

import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Fade from '@mui/material/Fade';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import CachedRoundedIcon from '@mui/icons-material/CachedRounded';

import * as serviceWorkerRegistration from '../../_lib/offline/serviceWorkerRegistration';

import { useOnlineStatus } from '../../_lib/offline/use-online-status';
import { LinearProgressDeterminateLoading } from '../../components/linear-progress/determinate-loading';

export function ScreenInfoLoading({ message }: any) {
  const { t } = useTranslation();

  const [showReload, setShowReload] = React.useState(false);

  const isOnline = useOnlineStatus();

  const handleReload = () => {
    if (isOnline) {
      window.localStorage.clear();
      serviceWorkerRegistration.unregister().then(() => {
        window.location.reload();
      });
    } else {
      window.location.reload();
    }
  };

  React.useEffect(() => {
    const reloadTimer = setTimeout(() => setShowReload(true), 20000);
    return () => {
      clearTimeout(reloadTimer);
    };
  }, []);

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateRows: 'auto 1fr',
        minHeight: '100vh',
        bgcolor: 'grey.200',
      }}
    >
      <LinearProgressDeterminateLoading />
      <Stack spacing={2} alignItems="center" justifyContent="center">
        <CircularProgress />
        <Typography variant="body1" align="center" color="primary">
          {message || t('Loading')}..
        </Typography>

        <Fade in={showReload}>
          <Box>
            <Button
              variant="outlined"
              startIcon={<CachedRoundedIcon />}
              onClick={handleReload}
            >
              {t('Reload')}
            </Button>
          </Box>
        </Fade>
      </Stack>
    </Box>
  );
}
