import { ErrorBoundary } from 'react-error-boundary';

import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Container from '@mui/material/Container';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';

import { useTranslation } from 'react-i18next';
import { ReactErrorComponent } from '../_lib/react-error';

import { InterventionHeader } from './header';

import { InterventionSummary } from './summary';
import { InterventionTargets } from './targets';
import { InterventionTracker } from './tracker';
import { getStringDate } from '../_utils/general-utils';
import { useDashboardContext } from '../_lib/context/dashboard-context';

export function Intervention({ intervention }: any) {
  const {
    dashboard: { language },
  } = useDashboardContext();
  const { t } = useTranslation();
  return (
    <Container>
      <Card id="intervention" sx={{ my: 3 }}>
        <ErrorBoundary FallbackComponent={ReactErrorComponent}>
          {/* show archived alert incase has been archived */}
          {!!intervention.archivedAt && (
            <Alert severity="warning">
              <AlertTitle>
                {`${t('This item has been archived since')}: ${getStringDate(
                  intervention.archivedAt,
                  language
                )}`}
              </AlertTitle>
              {t(
                'Data contained in this item will not be included in summaries and analytics unless it is unarchived.'
              )}
            </Alert>
          )}
          <InterventionHeader intervention={intervention} />

          <CardContent sx={{ px: 4, pb: 4 }}>
            {/* summary section */}
            <InterventionSummary intervention={intervention} />

            {/* targets section */}
            <InterventionTargets intervention={intervention} />

            <Divider sx={{ my: 4 }} />

            {/* activities tracker */}
            <InterventionTracker intervention={intervention} />
          </CardContent>
        </ErrorBoundary>
      </Card>
    </Container>
  );
}
