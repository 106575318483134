import React from 'react';

import { useDashboardContext } from '../../_lib/context/dashboard-context';

import currencyListJson from '../../_lib/data/currency-list.json';

export function useCurrency(currency: any) {
  const { dashboard } = useDashboardContext();

  const currencyFull = React.useMemo(
    () =>
      currencyListJson.find(
        (c: any) =>
          `${c.entity} :: ${c.alpha_code}` === (currency || dashboard.currency)
      ),
    [currency, dashboard.currency]
  );

  const symbol = React.useMemo(
    () =>
      (0)
        .toLocaleString(dashboard.language, {
          style: 'currency',
          currency: currencyFull?.alpha_code || 'USD',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        })
        .replace(/\d/g, '')
        .trim(),
    [currencyFull?.alpha_code, dashboard.language]
  );

  return { symbol };
}
