import React from 'react';

import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';

import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { PriorityArea } from '../executive-summary/executive-summary.types';
import { useLocalStorage } from '../../../../_utils/hooks/use-local-storage';
import { AnalyticsStatus } from './status-chart';
import { ExecutiveSummaryFiltersValues } from '../executive-summary-filter/executive-summary-filter';

export type ChartType = {
  title: string;
  description: string;
  component: JSX.Element;
};

type ExecutiveSummaryStatusChartProps = {
  priorityAreas: PriorityArea[];
  filters: ExecutiveSummaryFiltersValues;
};

function ExecutiveSummaryStatusChart(props: ExecutiveSummaryStatusChartProps) {
  const { t } = useTranslation();

  const [openOverview, setOpenOverview] = useLocalStorage(
    'executive-view-chart-summary',
    false
  );

  const toggleOpenOverview = () => {
    setOpenOverview(!openOverview);
  };

  return (
    <>
      <Box
        px={2}
        py={1}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Typography
          variant="h5"
          component="h2"
          fontWeight="600"
          fontSize={{
            xs: '1rem',
            sm: '1.5rem',
          }}
        >
          {t('Summary')}
        </Typography>
        <IconButton aria-label="open overview" onClick={toggleOpenOverview}>
          <KeyboardArrowRightIcon
            sx={{
              transform: `rotate(${openOverview ? '90deg' : '0deg'})`,
              transition: 'transform 0.3s',
            }}
          />
        </IconButton>
      </Box>
      <Stack>
        <Collapse in={openOverview} unmountOnExit>
          <Box pt={1} />
          <Box>
            <AnalyticsStatus
              byAreaBoxProps={{
                sx: {
                  m: 2,
                  height: '100%',
                },
              }}
              byAreaChartProps={{
                height: 120,
              }}
              currentPriorityAreas={props.priorityAreas}
              filters={props.filters}
            />
          </Box>
        </Collapse>
      </Stack>
    </>
  );
}

export default ExecutiveSummaryStatusChart;
