import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { useDashboardContext } from '../_lib/context/dashboard-context';

import { DashboardAppBar } from './appbar';
import { NavbarFilter } from './filter';

import { SwitchDashboardDialogList } from '../components/switch-dashboard/dialog-list';
import { SwitchMemberDialog } from './navbar-menu/switch-member/dialog';
import { CreateDashboardDialog } from './create-dashboard-dialog';

import { CreateMember } from '../actions/create-member';
import { LaunchDashboard } from '../actions/launch-dashboard';

import { useAppbarAvatarContext } from './appbar/avatar/context';

export function NavbarBase() {
  const { t } = useTranslation();
  const location = useLocation();

  const { dashboard, user } = useDashboardContext();
  const {
    openAddMemberDialog,
    setOpenAddMemberDialog,
    openSwitchDashboardDialog,
    setOpenSwitchDashboardDialog,
    showCreateDashboardDialog,
    setShowCreateDashboardDialog,
    showSwitchMemberDialog,
    setShowSwitchMemberDialog,
  } = useAppbarAvatarContext();

  return (
    <>
      {/* add member */}
      <CreateMember
        showAddUserDialog={openAddMemberDialog}
        setShowAddUserDialog={setOpenAddMemberDialog}
      />

      {/* switch  dashboards dialog */}
      <SwitchDashboardDialogList
        open={openSwitchDashboardDialog}
        setOpen={setOpenSwitchDashboardDialog}
      />

      {/* Create Dashboard dialog */}
      {user.canCreateDashboards && (
        <CreateDashboardDialog
          open={showCreateDashboardDialog}
          onClose={() => setShowCreateDashboardDialog(false)}
        />
      )}

      {/* Superuser items */}
      {user.isSuperuser && showSwitchMemberDialog && (
        <SwitchMemberDialog
          open={showSwitchMemberDialog}
          setOpen={setShowSwitchMemberDialog}
        />
      )}

      {/* The APPBAR */}

      <DashboardAppBar
        logoSrc={dashboard.logo}
        title={dashboard.name}
        showAreasFilter={
          !!dashboard.enableFilter &&
          !location.pathname.startsWith(t('/planning')) &&
          !location.pathname.startsWith(t('/reports')) &&
          !location.pathname.startsWith(t('/settings')) &&
          !location.pathname.startsWith(t('/my-account')) &&
          !location.pathname.startsWith(t('/my-content')) &&
          !location.pathname.startsWith(t('/executive'))
        }
        areasFilter={<NavbarFilter />}
        launchDashboard={<LaunchDashboard />}
        showLaunchDashboard={
          !!dashboard.inPlanning &&
          (location.pathname.startsWith(t('/planning')) ||
            location.pathname.startsWith(t('/settings')))
        }
      />
    </>
  );
}
