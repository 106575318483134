import React from 'react';
import { ApolloConsumer } from '@apollo/client';

import { ErrorBoundary } from 'react-error-boundary';

import LinearProgress from '@mui/material/LinearProgress';

import { DashboardContextProvider } from '../context/dashboard-context';
import { ReportsContextProvider } from '../context/reports-context';
import { RouterContextProvider } from '../context/router-context';
import { ContentDialogProvider } from '../context/content-dialog-context';

import { ThemeProviderDashboard } from './theme/dashboard';
import { LocalizationProvider } from './localization';

import { ReactError } from '../react-error';
import { GraphqlPrefetch } from '../graphql/prefetch';

export function AppProviders({ children }: any) {
  return (
    <React.Suspense fallback={<LinearProgress />}>
      <DashboardContextProvider>
        <ReportsContextProvider>
          <RouterContextProvider>
            <ContentDialogProvider>
              <ApolloConsumer>
                {(client) => (
                  <ErrorBoundary FallbackComponent={ReactError}>
                    <ThemeProviderDashboard>
                      <LocalizationProvider>{children}</LocalizationProvider>
                    </ThemeProviderDashboard>
                    <GraphqlPrefetch />
                  </ErrorBoundary>
                )}
              </ApolloConsumer>
            </ContentDialogProvider>
          </RouterContextProvider>
        </ReportsContextProvider>
      </DashboardContextProvider>
    </React.Suspense>
  );
}
