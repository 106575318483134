import React from 'react';
import { useQuery } from '@apollo/client';

import { useReportsContext } from '../../../_lib/context/reports-context';
import { SubscribeToMore } from '../../../components/subscribe-to-more';

import { REPORT_DATA_PRIORITY_AREA_QUERY } from '../../../_lib/graphql/queries';
import { REPORT_DATA_PRIORITY_AREA_SUBSCRIPTION } from '../../../_lib/graphql/subscriptions';

import { ReportsEditReportDataIntervention } from './intervention';

export function ReportsEditReportDataPriorityArea({
  priorityArea,
  setCompleteData,
  isChild,
}: any) {
  const { reportDataVariables, setReportDataReset } = useReportsContext();

  const [completePaData, setCompletePaData] = React.useState<any>(null);

  const { data, loading, error, refetch, subscribeToMore } = useQuery(
    REPORT_DATA_PRIORITY_AREA_QUERY,
    {
      variables: {
        priorityAreaId: priorityArea.id,
        ...reportDataVariables,
      },
    }
  );

  // ------------------------------------------------------------------------------------------------------------------------
  // ------------------------------------------------------------------------------------------------------------------------
  // effects
  // ------------------------------------------------------------------------------------------------------------------------

  // refetch if needed
  React.useEffect(() => {
    if (!data && !loading && !error) {
      refetch();
    }
  }, [data, loading, error, refetch]);

  // complete pa data
  React.useEffect(() => {
    if (data) {
      setCompletePaData((prev: any) => {
        if (!prev?.priorityArea) {
          return {
            priorityArea: data.priorityArea,
            _meta: {
              childrenIds: [],
              interventionIds: [],
            },
          };
        }

        // filter and merge previous children
        const children: any[] =
          prev?.priorityArea?.children?.filter((child: any) =>
            data.priorityArea.children?.find(
              (child2: any) => child2.id === child.id
            )
          ) || [];
        data.priorityArea.children?.forEach((child: any) => {
          if (!children?.find((child2: any) => child2.id === child.id)) {
            children?.push(child);
          }
        });

        // filter and merge previous interventions
        const interventionsDb: any[] =
          prev?.priorityArea?.interventionsDb?.filter((intv: any) =>
            data.priorityArea.interventionsDb?.find(
              (intv2: any) => intv2.id === intv.id
            )
          ) || [];
        data.priorityArea.interventionsDb?.forEach((intv: any) => {
          if (!interventionsDb?.find((intv2: any) => intv2.id === intv.id)) {
            interventionsDb?.push(intv);
          }
        });

        // result
        return {
          ...prev,
          priorityArea: {
            ...data.priorityArea,
            children,
            interventionsDb,
          },
          _meta: {
            ...prev?._meta,
            childrenIds: prev?._meta?.childrenIds?.filter((id: any) =>
              data.priorityArea?.children?.find((child: any) => child.id === id)
            ),
            interventionIds: prev?._meta?.interventionIds?.filter((id: any) =>
              data.priorityArea?.interventionsDb?.find(
                (intv: any) => intv.id === id
              )
            ),
          },
        };
      });
    }
  }, [data]);

  // complete data
  React.useEffect(() => {
    if (
      completePaData &&
      completePaData.priorityArea?.children?.length ===
        completePaData._meta?.childrenIds?.length &&
      completePaData.priorityArea?.interventionsDb?.length ===
        completePaData._meta?.interventionIds?.length
    ) {
      setCompleteData((prev: any) => {
        // not a child so update priority areas
        if (!isChild) {
          return {
            ...prev,
            priorityAreas: prev?.priorityAreas?.map((pa: any) => {
              if (pa.id === priorityArea.id) {
                return completePaData.priorityArea;
              }
              return pa;
            }),
            _meta: {
              ...prev?._meta,
              priorityAreaIds: Array.from(
                new Set([
                  ...(prev?._meta?.priorityAreaIds || []),
                  priorityArea.id,
                ])
              ),
            },
          };
        }

        // is a child so update parent
        return {
          ...prev,
          children: prev?.children?.map((child: any) => {
            if (child.id === priorityArea.id) {
              return completePaData.priorityArea;
            }
            return child;
          }),
          _meta: {
            ...prev?._meta,
            childrenIds: Array.from(
              new Set([...(prev?._meta?.childrenIds || []), priorityArea.id])
            ),
          },
        };
      });
    }
  }, [completePaData, isChild, priorityArea.id, setCompleteData]);

  // set report data reset
  React.useEffect(() => {
    setReportDataReset((prev: any) => {
      const resetFn = async () => {
        setCompletePaData(null);
        refetch({
          priorityAreaId: priorityArea.id,
          ...reportDataVariables,
        });
      };
      const fnId = `reset-pa-${priorityArea.id}`;
      const fnIdx = prev.findIndex((fn: any) => fn.id === fnId);
      if (fnIdx === -1) {
        return [...prev, { id: fnId, fn: resetFn }];
      }
      return [
        ...prev.slice(0, fnIdx),
        { id: fnId, fn: resetFn },
        ...prev.slice(fnIdx + 1),
      ];
    });
  }, [
    setReportDataReset,
    reportDataVariables,
    setCompleteData,
    refetch,
    priorityArea.id,
  ]);

  // ------------------------------------------------------------------------------------------------------------------------

  if (!data || loading || error) return null;

  return (
    <>
      {/* children */}
      {data?.priorityArea?.children?.map((child: any) => (
        <ReportsEditReportDataPriorityArea
          key={child.id}
          priorityArea={child}
          setCompleteData={setCompletePaData}
          isChild
        />
      ))}

      {/* interventions */}
      {data?.priorityArea?.interventionsDb?.map((intervention: any) => (
        <ReportsEditReportDataIntervention
          key={intervention.id}
          intervention={intervention}
          setCompletePaData={setCompletePaData}
        />
      ))}

      {/* subscribe to more */}
      <SubscribeToMore
        subscribeToMore={() =>
          subscribeToMore({
            document: REPORT_DATA_PRIORITY_AREA_SUBSCRIPTION,
            variables: {
              priorityAreaId: priorityArea.id,
              ...reportDataVariables,
            },
            updateQuery: (prev, { subscriptionData }) => {
              if (!subscriptionData.data) return prev;
              return subscriptionData.data;
            },
          })
        }
        variables={{
          priorityAreaId: priorityArea.id,
          ...reportDataVariables,
        }}
      />
    </>
  );
}
