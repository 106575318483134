import React from 'react';
import { useTranslation } from 'react-i18next';
import { gql, useQuery } from '@apollo/client';

import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';

import { daysPast } from '../../../_utils/general-utils';
import { DashboardContext } from '../../../_lib/context/dashboard-context';
import { logError } from '../../../_lib/error';

const LAST_LOGIN_QUERY = gql`
  query LastLogin($userId: ID!) {
    user(id: $userId) {
      id
      lastLogin
    }
  }
`;

const LAST_LOGIN_SUBSCRIPTION = gql`
  subscription LastLogin($userId: ID!) {
    user(id: $userId) {
      id
      lastLogin
    }
  }
`;

function LastLoginDateColumnSubscriptions({
  subscribeToMore,
}: {
  subscribeToMore: () => () => void;
}) {
  React.useEffect(() => {
    return subscribeToMore();
  }, [subscribeToMore]);
  return null;
}

export function LastLoginDateColumn({ user }: any) {
  const { t } = useTranslation();
  const { dashboard } = React.useContext(DashboardContext);

  const { loading, error, data, subscribeToMore } = useQuery(LAST_LOGIN_QUERY, {
    variables: { userId: user.id },
  });

  // ----------------------------------------------------------------------------------------------------
  // ----------------------------------------------------------------------------------------------------
  // effects
  // ----------------------------------------------------------------------------------------------------

  React.useEffect(() => {
    if (error) {
      logError(error);
    }
  }, [error]);

  // ----------------------------------------------------------------------------------------------------

  if (loading || !data) {
    return (
      <Typography variant="body1">
        <Skeleton />
      </Typography>
    );
  }

  // ----------------------------------------------------------------------------------------------------

  return (
    <>
      <Typography variant="body1">
        {data?.user?.lastLogin
          ? daysPast(data?.user?.lastLogin, t, dashboard.language)
          : '-'}
      </Typography>

      {/* subscription */}
      <LastLoginDateColumnSubscriptions
        subscribeToMore={() =>
          subscribeToMore({
            document: LAST_LOGIN_SUBSCRIPTION,
            variables: { userId: user.id },
            updateQuery: (prev, { subscriptionData }) => {
              if (!subscriptionData.data) return prev;
              return subscriptionData.data;
            },
          })
        }
      />
    </>
  );
}
