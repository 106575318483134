import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

import { useDashboardContext } from '../../_lib/context/dashboard-context';

export function PlanningSetupMilestonesFormListHeader({
  addMilestone,
  intervention,
}: any) {
  const { t } = useTranslation();
  const history = useHistory();

  const { dashboard } = useDashboardContext();

  return (
    <Box
      sx={{
        display: 'flex',
        my: 3,
        alignItems: { xs: 'flex-start', sm: 'center' },
        flexDirection: { xs: 'column', sm: 'row' },
      }}
    >
      <Box sx={{ flex: 1 }}>
        <Button
          variant="outlined"
          startIcon={<ArrowBackIcon />}
          size="small"
          sx={{ mb: 1, textTransform: 'none' }}
          onClick={() =>
            history.push(
              `${t('/planning')}${intervention.priorityArea.urlRelative}/${
                dashboard.interventionsSlug
              }${!intervention?.archivedAt ? `?id=${intervention.id}` : ''}`
            )
          }
        >
          {`${intervention?.reference} - ${intervention?.name}`}
        </Button>
        <Typography variant="h5" sx={{ fontSize: '1.4rem' }}>
          {`${intervention?.reference} - ${intervention?.name} : ${t(
            'Milestones'
          )}`}
        </Typography>
      </Box>
      <Box>
        <Button
          startIcon={<AddCircleOutlineIcon />}
          variant="contained"
          disableElevation
          onClick={addMilestone}
        >
          {t('Add Milestone')}
        </Button>
      </Box>
    </Box>
  );
}
