// sourced from - https://github.com/heofs/trendline

function getAvg(arr: any) {
  const total = arr.reduce((acc: any, c: any) => acc + c, 0);
  return total / arr.length;
}

function getSum(arr: any) {
  return arr.reduce((acc: any, c: any) => acc + c, 0);
}

export function calculateTrendline(data: any, xKey: any, yKey: any) {
  const xData = data.map((value: any) => value[xKey]);
  const yData = data.map((value: any) => value[yKey]);

  // average of X values and Y values
  const xMean = getAvg(xData);
  const yMean = getAvg(yData);

  // Subtract X or Y mean from corresponding axis value
  const xMinusxMean = xData.map((val: any) => val - xMean);
  const yMinusyMean = yData.map((val: any) => val - yMean);

  const xMinusxMeanSq = xMinusxMean.map((val: any) => val ** 2);

  const xy = [];
  for (let x = 0; x < data.length; x += 1) {
    xy.push(xMinusxMean[x] * yMinusyMean[x]);
  }

  // const xy = xMinusxMean.map((val, index) => val * yMinusyMean[index]);

  const xySum = getSum(xy);

  // b1 is the slope
  const b1 = xySum / getSum(xMinusxMeanSq);
  // b0 is the start of the slope on the Y axis
  const b0 = yMean - b1 * xMean;

  return {
    slope: b1,
    yStart: b0,
    calcY: (x: any) => b0 + b1 * x,
  };
}
