import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import CardHeader from '@mui/material/CardHeader';

import IconButton from '@mui/material/IconButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditIcon from '@mui/icons-material/Edit';
import PersonAddIcon from '@mui/icons-material/PersonAdd';

import { useDashboardContext } from '../../_lib/context/dashboard-context';
import { AreaPermissionsManage } from '../../components/area-permissions/manage';

export function PriorityAreaSummaryHeader({ priorityArea }: any) {
  const { t } = useTranslation();

  const {
    user,
    dashboard: { priorityAreaName, enableAreaPermissions, enableAreaLevels },
  } = useDashboardContext();

  // actions menu
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorEl);

  const [openPermissionsDialog, setOpenPermissionsDialog] =
    React.useState(false);

  // --------------------------------------------------------------------------------------------------
  // --------------------------------------------------------------------------------------------------
  // handlers
  // --------------------------------------------------------------------------------------------------

  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMenuShare = () => {
    setOpenPermissionsDialog(true);
    handleMenuClose();
  };

  // --------------------------------------------------------------------------------------------------
  // --------------------------------------------------------------------------------------------------
  // effects
  // --------------------------------------------------------------------------------------------------

  // --------------------------------------------------------------------------------------------------

  return (
    <>
      {/* area permissions dialog */}
      {openPermissionsDialog && (
        <AreaPermissionsManage
          open={openPermissionsDialog}
          setOpen={setOpenPermissionsDialog}
          area={priorityArea}
        />
      )}

      <CardHeader
        title={!enableAreaLevels ? priorityAreaName : priorityArea.level?.name}
        titleTypographyProps={{
          variant: 'h5',
          sx: {
            lineHeight: 1.2,
            color: 'grey.800',
          },
        }}
        subheader={`${priorityArea.reference} - ${priorityArea.name}`}
        subheaderTypographyProps={{
          variant: 'h4',
          sx: { lineHeight: 1.2, color: 'black' },
        }}
        sx={{ px: 4, pt: 3, pb: 1 }}
        action={
          user.isAdmin && (
            <>
              <IconButton
                aria-label="actions"
                aria-controls="priority-area-summary-menu"
                aria-haspopup="true"
                aria-expanded={openMenu ? 'true' : undefined}
                onClick={handleMenuClick}
              >
                <MoreVertIcon />
              </IconButton>
              <Menu
                id="priority-area-summary-menu"
                anchorEl={anchorEl}
                open={openMenu}
                onClose={handleMenuClose}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
              >
                {enableAreaPermissions && (
                  <MenuItem
                    key="share"
                    onClick={handleMenuShare}
                    disabled={!priorityArea}
                  >
                    <ListItemIcon>
                      <PersonAddIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>{t('Share')}</ListItemText>
                  </MenuItem>
                )}
                <MenuItem
                  component={RouterLink}
                  to={`${t('/planning')}${priorityArea.urlRelativeLevel}/?id=${
                    priorityArea.id
                  }&edit=true`}
                >
                  <ListItemIcon>
                    <EditIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>{t('Edit')}</ListItemText>
                </MenuItem>
              </Menu>
            </>
          )
        }
      />
    </>
  );
}
