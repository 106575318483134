import React from 'react';
import { useTranslation } from 'react-i18next';
import { gql, useMutation } from '@apollo/client';

import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Alert from '@mui/material/Alert';
import LoadingButton from '@mui/lab/LoadingButton';
import { logError } from '../../../../_lib/error';

const VERIFY_PASSWORD = gql`
  mutation TwoFactorVerifyPassword($password: String!) {
    twoFactorVerifyPassword(password: $password) {
      message
      success
    }
  }
`;

export function VerifyPassword({
  onSuccess,
  disabled,
  loading,
  setLoading,
}: any) {
  const { t } = useTranslation();

  const [password, setPassword] = React.useState('');
  const [error, setError] = React.useState('');

  const [verifyMutation] = useMutation(VERIFY_PASSWORD);

  // ---------------------------------------------------------------------------------------------------------------------
  // ---------------------------------------------------------------------------------------------------------------------
  // handlers
  // ---------------------------------------------------------------------------------------------------------------------

  const handleVerifyPassword = () => {
    setError('');

    // verify todo
    if (!password.length) {
      setError(`${t('You must enter your password to proceed.')}`);
      return;
    }
    setLoading(true);
    verifyMutation({
      variables: {
        password,
      },
    })
      .then((res: any) => {
        const { twoFactorVerifyPassword } = res.data;
        if (twoFactorVerifyPassword && twoFactorVerifyPassword.success) {
          onSuccess();
        } else {
          setError(
            twoFactorVerifyPassword
              ? twoFactorVerifyPassword.message
              : t('Unspecified error occured.')
          );
          setLoading(false);
        }
      })
      .catch((err: any) => {
        logError(err);
        setError(err.message);
        setLoading(false);
      });
  };

  // ---------------------------------------------------------------------------------------------------------------------

  return (
    <Stack spacing={1}>
      <Typography>{t('Confirm your password to proceed')}:</Typography>

      <TextField
        value={password}
        label={t('Enter password')}
        variant="outlined"
        disabled={loading || disabled}
        type="password"
        onChange={(e) => setPassword(e.target.value.trim())}
        size="small"
        autoFocus
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            handleVerifyPassword();
          }
        }}
      />

      {/* if errror */}
      {error && <Alert severity="error">{t(error)}</Alert>}

      <LoadingButton
        variant="contained"
        sx={{ textTransform: 'none' }}
        onClick={handleVerifyPassword}
        loading={loading}
        disabled={disabled}
      >
        {t('Confirm password')}
      </LoadingButton>
    </Stack>
  );
}
