import React from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Switch } from 'react-router-dom';

import { useDashboardContext } from '../context/dashboard-context';

import { HomepageLoader } from '../../homepage/loader';
import { InterventionLoader } from '../../intervention/loader';
import { PlanningLoader } from '../../planning/loader';
import { PriorityAreaLoader } from '../../priority-area/loader';
import { PriorityAreaLoaderTracker } from '../../priority-area/loader/tracker';
import { ReportsScreen } from '../../_screens/reports';

export function FullDashboardRouter() {
  const { t } = useTranslation();

  const {
    dashboard: { interventionsSlug, areaLevels },
  } = useDashboardContext();

  const priorityAreaPaths = React.useMemo(
    () =>
      areaLevels?.reduce((acc: any, level: any, index: number) => {
        const paPath = `${
          acc.length === 0
            ? ''
            : `${acc[index - 1]}_${areaLevels[index - 1].id}`
        }/${level.slug}/:priorityAreaId`;
        acc.push(paPath);
        return acc;
      }, []) || [],
    [areaLevels]
  );

  return (
    <Switch>
      <Route exact path="/" component={HomepageLoader} />

      {/* ------------------------------------------------------------------------------------------- */}

      {/* area paths */}
      {priorityAreaPaths.map((path: string) => [
        <Route
          key={`${path}/landing`}
          exact
          path={path}
          component={PriorityAreaLoader}
        />,
        <Route
          key={`${path}/tracker`}
          exact
          path={`${path}/${t('tracker')}`}
          component={PriorityAreaLoaderTracker}
        />,
        <Route
          key={`${path}/intervention`}
          exact
          path={`${path}/${interventionsSlug}/:interventionId`}
          component={InterventionLoader}
        />,
      ])}

      {/* ------------------------------------------------------------------------------------------- */}

      {/* settings */}
      <Route path={t('/settings')} component={PlanningLoader} />

      {/* planning */}
      <Route path={t('/planning')} component={PlanningLoader} />

      {/* analytics */}
      <Route path={t('/analytics')} component={PlanningLoader} />

      {/* ------------------------------------------------------------------------------------------- */}

      {/* reports */}
      <Route path={t('/reports')} component={ReportsScreen} />
    </Switch>
  );
}
