import React from 'react';
import { useTranslation } from 'react-i18next';

import { useMutation } from '@apollo/client';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';

import { DashboardContext } from '../../../../_lib/context/dashboard-context';
import { DASHBOARD_UPDATE } from '../../../../_lib/graphql/mutations';

import { logError } from '../../../../_lib/error';

import { DialogTitle } from '../../../../DialogTitle/dialog-title';

export function AdvancedCustomClassificationLabelDialog({
  open,
  setOpen,
}: any) {
  const { t } = useTranslation();

  const { dashboard, setSnackbarIsError, setSnackbarOpen } =
    React.useContext(DashboardContext);

  const [customClassificationLabel, setCustomClassificationLabel] =
    React.useState(dashboard.customClassificationLabel);

  const [loading, setLoading] = React.useState(false);

  const [dashboardUpdateMutation] = useMutation(DASHBOARD_UPDATE);

  // --------------------------------------------------------------------------------------------------
  // --------------------------------------------------------------------------------------------------
  // handlers
  // --------------------------------------------------------------------------------------------------

  const handleSave = () => {
    setLoading(true);
    dashboardUpdateMutation({
      variables: { customClassificationLabel },
    })
      .then(() => {
        setOpen(false);
      })
      .catch((err) => {
        logError(err);
        setSnackbarIsError(true);
      })
      .finally(() => {
        setLoading(false);
        setSnackbarOpen(true);
      });
  };

  const handleCancel = () => {
    setCustomClassificationLabel(dashboard.customClassificationLabel);
    setOpen(false);
  };

  // --------------------------------------------------------------------------------------------------
  // --------------------------------------------------------------------------------------------------
  // effects
  // --------------------------------------------------------------------------------------------------

  React.useEffect(() => {
    setCustomClassificationLabel(dashboard.customClassificationLabel);
  }, [dashboard]);

  // --------------------------------------------------------------------------------------------------

  return (
    <Dialog open={open} maxWidth="xs" fullWidth>
      <DialogTitle>{t('Custom Classification Label')}</DialogTitle>
      <DialogContent dividers>
        <TextField
          size="small"
          fullWidth
          label={t('Label')}
          value={customClassificationLabel}
          onChange={(e) => setCustomClassificationLabel(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} disabled={loading}>
          {t('Cancel')}
        </Button>
        <LoadingButton
          onClick={handleSave}
          variant="contained"
          disableElevation
          loading={loading}
        >
          {t('Save')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
