import React from 'react';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';

import { DashboardContext } from '../../_lib/context/dashboard-context';

import { ColumnDate } from '../tracker-table/columns/date';

export function ContentSummaryDate({ activity }: any) {
  const { t } = useTranslation();

  const { dashboard } = React.useContext(DashboardContext);

  const hasDate =
    !!activity.completionDate || !!activity.deadline || !!activity.createdAt;

  if (!hasDate) return null;

  return (
    <>
      {/* date completed / deadline */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <ColumnDate
          date={
            activity.status === 'completed'
              ? activity.completionDate
              : activity.status === 'issue'
              ? activity.createdAt
              : activity.deadline
          }
          typographyProps={{
            component: 'div',
            sx: {
              textAlign: 'right',
            },
          }}
          typographyEmptyProps={{
            sx: {
              textAlign: 'right',
            },
          }}
          tooltipLabel={
            activity.status === 'completed'
              ? `${t('Completed on')}`
              : activity.status === 'issue'
              ? `${t('Created on')}`
              : `${t('Deadline')}`
          }
        />
      </Box>

      {/* deadline */}
      {activity.status === 'completed' && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <ColumnDate
            date={activity.deadline}
            typographyProps={{
              variant: 'caption',
              color: 'textSecondary',
              sx: {
                textAlign: 'right',
                ...(activity.status === 'completed'
                  ? {
                      color:
                        new Date(activity.deadline) >=
                        new Date(activity.completionDate)
                          ? dashboard.metaStatusColors.completed?.bgcolor
                          : dashboard.metaStatusColors.overdue?.bgcolor,
                    }
                  : {}),
              },
            }}
            typographyEmptyProps={{
              sx: {
                textAlign: 'right',
              },
            }}
            tooltipLabel={`${t('Deadline')}`}
            textDateLabel={`${t('Deadline')}}`[0]}
          />
        </Box>
      )}
    </>
  );
}
