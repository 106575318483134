import React from 'react';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

import { useDashboardContext } from '../../../_lib/context/dashboard-context';
import { useContentDialogContext } from '../../../_lib/context/content-dialog-context';

import BackgroundLetterAvatar from '../../../components/avatar-bg-letter';
import { ContentDialogSectionTitle } from '../section-title';

export function ContentDialogMembers() {
  const { t } = useTranslation();

  const { dashboard } = useDashboardContext();
  const { content } = useContentDialogContext();

  const assignees = React.useMemo(
    () =>
      dashboard.enableActivityMultipleAssignees
        ? content.assignees
        : [content.assignee],
    [content, dashboard.enableActivityMultipleAssignees]
  );

  return (
    <Box sx={{ mb: 2 }}>
      <ContentDialogSectionTitle>{t('Members')}</ContentDialogSectionTitle>

      <Stack spacing={1} direction="row">
        {!!assignees && !!assignees.length ? (
          assignees.map((a: any) => (
            <BackgroundLetterAvatar
              key={a.id}
              name={a.name}
              email={a.email}
              includeName={assignees.length === 1}
            />
          ))
        ) : (
          <Typography
            variant="body1"
            color="text.secondary"
            sx={{ fontStyle: 'italic' }}
          >
            {t('Unassigned')}
          </Typography>
        )}
      </Stack>
    </Box>
  );
}
