import React from 'react';

import { useTranslation } from 'react-i18next';

import { useDashboardContext } from '../../../_lib/context/dashboard-context';

import { TARGET_UNITS_QUERY } from '../../../_lib/graphql/queries';
import { TARGET_UNITS_SUBSCRIPTION } from '../../../_lib/graphql/subscriptions';
import {
  TARGET_DELETE,
  TARGET_UNIT_CREATE,
  TARGET_UNIT_UPDATE,
  TARGET_UNIT_UPDATE_ORDER,
} from '../../../_lib/graphql/mutations';

import { PlanningFieldsInputAutocomplete } from '../../fields-input/_autocomplete';

export function PlanningTargetsInputTargetUnitAutocomplete({
  label,
  disabled,
  area,
  setArea,
}: any) {
  const { t } = useTranslation();
  const {
    dashboard: { enableTargetCategories },
  } = useDashboardContext();

  // ----------------------------------------------------------------------------------------

  return (
    <PlanningFieldsInputAutocomplete
      disabled={disabled}
      label={label}
      area={area}
      setArea={setArea}
      GET_ITEMS={TARGET_UNITS_QUERY}
      SUBSCRIBE_ITEMS={TARGET_UNITS_SUBSCRIPTION}
      CREATE_ITEM={TARGET_UNIT_CREATE}
      createItemOpName="targetUnitCreate"
      UPDATE_ITEM={TARGET_UNIT_UPDATE}
      updateItemOpName="targetUnitUpdate"
      DELETE_ITEM={TARGET_DELETE}
      deleteItemOpName="targetUnitDelete"
      UPDATE_ITEM_ORDER={TARGET_UNIT_UPDATE_ORDER}
      updateItemOrderOpName="targetUnitUpdateOrder"
      dataKey="targetUnits"
      manageDialogTitle={t('Manage Indicators')}
      manageDialogInputLabel={t('Name')}
      manageDialogAddLabel={t('Add Indicator')}
      includeUnitCategory={enableTargetCategories}
      sx={{
        flexGrow: 1,
      }}
      addOption
      forceManageOption
    />
  );
}
