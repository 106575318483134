import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';

import Badge from '@mui/material/Badge';
import ButtonBase from '@mui/material/ButtonBase';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import { useDashboardContext } from '../../_lib/context/dashboard-context';
import { ContentSummaryDate } from './date';

export function ContentSummaryRow({ activity }: any) {
  const { t } = useTranslation();

  const location = useLocation();
  const history = useHistory();

  const {
    dashboard: { metaStatusColors, mode: dashboardMode },
  } = useDashboardContext();

  const handleClick = React.useCallback(
    (activity: any) => {
      const params = new URLSearchParams(location.search);
      params.set('content', activity.id);
      history.push(`${location.pathname}?${params.toString()}`);
    },
    [history, location.pathname, location.search]
  );

  return (
    <ButtonBase
      component="div"
      disableRipple
      onClick={() => handleClick(activity)}
      key={activity.id}
    >
      <Grid container spacing={1}>
        <Grid
          item
          xs={8}
          sx={{
            '& p': {
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              '&:hover': {
                textOverflow: 'clip',
                whiteSpace: 'normal',
              },
            },
          }}
        >
          <Typography variant="body1">
            {activity.isMilestone && (
              <Tooltip title={t('Milestone') as string} arrow>
                <Badge
                  badgeContent={t('Milestone')[0]}
                  color="info"
                  componentsProps={{
                    badge: {
                      style: {
                        zIndex: 'auto',
                        position: 'initial',
                        transform: 'none',
                        marginBottom: '4px',
                      },
                    },
                  }}
                />
              </Tooltip>
            )}
            {activity.isIssue && (
              <Tooltip title={t('Issue') as string} arrow>
                <Badge
                  badgeContent={t('Issue')[0]}
                  color="warning"
                  componentsProps={{
                    badge: {
                      style: {
                        zIndex: 'auto',
                        position: 'initial',
                        transform: 'none',
                        marginBottom: '4px',
                        backgroundColor: metaStatusColors.issue?.bgcolor,
                      },
                    },
                  }}
                />
              </Tooltip>
            )}{' '}
            {activity.title}
          </Typography>

          <Typography variant="body2" color="text.secondary">
            {(dashboardMode === 'TRACKER' ||
              window.location.pathname === '/') && (
              <>
                {dashboardMode !== 'TRACKER' &&
                activity.intervention.priorityArea.reference
                  ? `${activity.intervention.priorityArea.reference} - `
                  : ''}
                {activity.intervention.priorityArea.name}
                {' . '}
              </>
            )}
            {dashboardMode !== 'TRACKER' && activity.intervention?.reference
              ? `${activity.intervention?.reference} - `
              : ''}
            {activity.intervention?.name}
          </Typography>
        </Grid>

        {/* date */}
        <Grid item xs={4} sx={{ textAlign: 'right' }}>
          <ContentSummaryDate activity={{ ...activity }} />
        </Grid>
      </Grid>
    </ButtonBase>
  );
}
