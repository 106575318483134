import { ErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';

import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';

import { ReportsEditFiltersDates } from './dates';
import { ReportsEditFiltersAreas } from './areas';
import { ReportsEditFiltersMembers } from './members';
import { ReactErrorNull } from '../../../_lib/react-error';

export function ReportsEditFilters() {
  const { t } = useTranslation();

  return (
    <>
      <Typography variant="h6" sx={{ mb: 2 }}>
        {t('Filters')}
      </Typography>
      <Typography variant="body2" sx={{ mb: 2 }}>
        {t('Filters are used to filter the results of the report.')}
      </Typography>

      <Stack sx={{ mb: 3 }} spacing={2} divider={<Divider />}>
        <ErrorBoundary FallbackComponent={ReactErrorNull}>
          <ReportsEditFiltersDates />
        </ErrorBoundary>
        <ErrorBoundary FallbackComponent={ReactErrorNull}>
          <ReportsEditFiltersAreas />
        </ErrorBoundary>
        <ErrorBoundary FallbackComponent={ReactErrorNull}>
          <ReportsEditFiltersMembers />
        </ErrorBoundary>
      </Stack>
    </>
  );
}
