import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import { ErrorBoundary } from 'react-error-boundary';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';

import { useLocalStorage } from '../../../../_utils/hooks/use-local-storage';
import { useDashboardContext } from '../../../../_lib/context/dashboard-context';
import { ReactErrorComponent } from '../../../../_lib/react-error';

import { ArrowForwardLink } from '../../../../components/arrow-forward-link';
import { AreaPermissionsLeads } from '../../../../components/area-permissions/leads';

import {
  PRIORITY_AREA_DELETE,
  INTERVENTION_DELETE,
  PRIORITY_AREAS_UPDATE_ORDER,
  INTERVENTIONS_UPDATE_ORDER,
} from '../../../../_lib/graphql/mutations';
import {
  CONTEXT_USER_QUERY,
  INTERVENTIONS_PLANNING_QUERY,
  PRIORITY_AREAS_PLANNING_QUERY,
  PRIORITY_AREA_PLANNING_QUERY,
} from '../../../../_lib/graphql/queries';

import { PlanningPriorityAreaFormDetailChildren } from '../children';
import { PlanningFieldsInputStakeholders } from '../../../fields-input/stakeholders';
import { PlanningFieldsInputCustomFields } from '../../../fields-input/custom-fields';
import { PlanningFieldsInputBudget } from '../../../fields-input/budget';

import { FinanciersInput } from '../../../financiers-input';
import { TargetsInput } from '../../../targets-input';
import { PlanningPriorityAreaFormActionsCancel } from '../../actions/cancel';
import { PlanningPriorityAreaFormActionsSave } from '../../actions/save';

export function PlanningPriorityAreaDetailFormFull({
  disabled,
  loading,
  priorityArea,
  setPriorityArea,
  errors,
  interventions,
  setInterventions,
  addIntervention,
  paChildren,
  setPaChildren,
  addPaChild,
  newFinanciers,
  setNewFinanciers,
  addFinancier,
  newTargets,
  setNewTargets,
  addTarget,
  setDisabled,
  setLoading,
  valid,
}: any) {
  const { t } = useTranslation();

  const { dashboard } = useDashboardContext();

  const [openFinanciersCollapse, setOpenFinanciersCollapse] = useLocalStorage(
    `planning-financiers-input-priorityarea-${priorityArea.id}`,
    false
  );
  const [openTargetsCollapse, setOpenTargetsCollapse] = useLocalStorage(
    `planning-targets-input-priorityarea-${priorityArea.id}`,
    false
  );

  const isInBottomLevel = !priorityArea.levelNext;

  // --------------------------------------------------------------------------------------------------------------------------------

  // prirotity area (children) mutations
  const [deletePriorityAreaMutation] = useMutation(PRIORITY_AREA_DELETE, {
    update: (cache, { data: { priorityAreaDelete } }) => {
      // sibling priority areas update
      cache.writeQuery({
        query: PRIORITY_AREAS_PLANNING_QUERY,
        variables: {
          parentId: dashboard.enableAreaLevels
            ? priorityAreaDelete.priorityArea?.id
            : undefined,
        },
        data: {
          priorityAreas: priorityAreaDelete.siblingPriorityAreas,
        },
      });

      // update parent priority area
      cache.writeQuery({
        query: PRIORITY_AREA_PLANNING_QUERY,
        variables: {
          priorityAreaId: dashboard.enableAreaLevels
            ? priorityAreaDelete.priorityArea?.id
            : undefined,
        },
        data: {
          priorityArea: priorityAreaDelete.parentPriorityArea,
        },
      });

      // update user context
      cache.writeQuery({
        query: CONTEXT_USER_QUERY,
        data: { user: priorityAreaDelete.user },
      });
    },
  });

  const [updatePriorityAreasOrderMutation] = useMutation(
    PRIORITY_AREAS_UPDATE_ORDER,
    {
      update: (cache, { data: { priorityAreasUpdateOrder } }) => {
        // sibling priority areas update
        cache.writeQuery({
          query: PRIORITY_AREAS_PLANNING_QUERY,
          variables: {
            parentId: dashboard.enableAreaLevels
              ? priorityAreasUpdateOrder.parentPriorityArea?.id
              : undefined,
          },
          data: {
            priorityAreas: priorityAreasUpdateOrder.siblingPriorityAreas,
          },
        });

        // parent priority area
        cache.writeQuery({
          query: PRIORITY_AREA_PLANNING_QUERY,
          variables: {
            priorityAreaId: dashboard.enableAreaLevels
              ? priorityAreasUpdateOrder.parentPriorityArea?.id
              : undefined,
          },
          data: {
            priorityArea: priorityAreasUpdateOrder.parentPriorityArea,
          },
        });
      },
    }
  );

  // intervention mutations
  const [deleteInterventionMutation] = useMutation(INTERVENTION_DELETE, {
    update: (cache, { data: { interventionDelete } }) => {
      // update parent priority area
      cache.writeQuery({
        query: PRIORITY_AREA_PLANNING_QUERY,
        variables: {
          priorityAreaId: interventionDelete.priorityArea.id,
        },
        data: {
          priorityArea: interventionDelete.priorityArea,
        },
      });

      // update sibling interventions
      cache.writeQuery({
        query: INTERVENTIONS_PLANNING_QUERY,
        variables: {
          priorityAreaId: interventionDelete.priorityArea.id,
        },
        data: {
          interventions: interventionDelete.interventions,
        },
      });

      // update user context
      cache.writeQuery({
        query: CONTEXT_USER_QUERY,
        data: { user: interventionDelete.user },
      });
    },
  });

  const [updateInterventionsOrderMutation] = useMutation(
    INTERVENTIONS_UPDATE_ORDER,
    {
      update: (cache, { data: { interventionsUpdateOrder } }) => {
        // update parent priority area
        cache.writeQuery({
          query: PRIORITY_AREA_PLANNING_QUERY,
          variables: {
            priorityAreaId: interventionsUpdateOrder.priorityArea.id,
          },
          data: {
            priorityArea: interventionsUpdateOrder.priorityArea,
          },
        });

        // update sibling interventions
        cache.writeQuery({
          query: INTERVENTIONS_PLANNING_QUERY,
          variables: {
            priorityAreaId: interventionsUpdateOrder.priorityArea.id,
          },
          data: {
            interventions: interventionsUpdateOrder.interventions,
          },
        });
      },
    }
  );

  // --------------------------------------------------------------------------------------------------------------------------------
  // --------------------------------------------------------------------------------------------------------------------------------
  // handlers
  // --------------------------------------------------------------------------------------------------------------------------------

  // --------------------------------------------------------------------------------------------------------------------------------

  return (
    <>
      {/* main content */}
      <Grid container spacing={2} sx={{ my: 1 }}>
        <Grid item xs={12} sm={6}>
          {/* reference + name */}
          <Grid container spacing={1.5} sx={{ mb: 2 }}>
            <Grid item xs={3} sm={2}>
              <TextField
                label={t('Ref')}
                disabled={disabled}
                value={priorityArea.reference || ''}
                onChange={(e) =>
                  setPriorityArea({
                    ...priorityArea,
                    reference: e.target.value,
                  })
                }
                size="small"
                fullWidth
                required
              />
            </Grid>
            <Grid item sx={{ flexGrow: 1 }}>
              <TextField
                label={t('Name')}
                disabled={disabled}
                value={priorityArea.name || ''}
                onChange={(e) =>
                  setPriorityArea({
                    ...priorityArea,
                    name: e.target.value,
                  })
                }
                size="small"
                fullWidth
                helperText={errors.name}
                error={!!errors.name}
                required
              />
            </Grid>
          </Grid>

          {/* permissions: leads */}
          <Box sx={{ mb: 2 }}>
            <AreaPermissionsLeads
              disabled={disabled}
              area={priorityArea}
              setArea={setPriorityArea}
              errors={errors}
            />
          </Box>

          {/* description */}
          <Box sx={{ mt: 3, mb: 1 }}>
            <TextField
              label={t('Description / Goals')}
              minRows={8}
              multiline
              disabled={disabled}
              value={priorityArea.description || ''}
              onChange={(e) =>
                setPriorityArea({
                  ...priorityArea,
                  description: e.target.value,
                })
              }
              size="small"
              fullWidth
            />
          </Box>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Stack spacing={2}>
            {/* priority areas children */}
            {dashboard.enableAreaLevels && !isInBottomLevel && (
              <Box>
                {disabled && !!priorityArea.levelNext?.name && (
                  <Box sx={{ textAlign: 'right', mb: 1 }}>
                    <ArrowForwardLink
                      to={`${t('/planning')}${priorityArea.urlRelative}/${
                        priorityArea.levelNext?.slug
                      }`}
                      text={`${priorityArea.levelNext?.name} ${t('Details')} (${
                        priorityArea.childrenCount
                      })`}
                      disabled={loading}
                      size={priorityArea.childrenCount < 6 ? 'medium' : 'large'}
                      variant={
                        priorityArea.childrenCount < 6
                          ? 'outlined'
                          : 'contained'
                      }
                      disableElavation={!(priorityArea.childrenCount < 11)}
                    />
                  </Box>
                )}
                {priorityArea.childrenCount < 6 && (
                  <PlanningPriorityAreaFormDetailChildren
                    parent={priorityArea}
                    areaName={priorityArea.levelNext?.name}
                    areas={paChildren}
                    setAreas={setPaChildren}
                    addArea={addPaChild}
                    disabled={disabled}
                    errors={errors}
                    deleteMutation={deletePriorityAreaMutation}
                    updateOrderMutation={updatePriorityAreasOrderMutation}
                  />
                )}
              </Box>
            )}

            {/* interventions */}
            <Box>
              {disabled && (
                <Box sx={{ textAlign: 'right', mb: 1 }}>
                  <ArrowForwardLink
                    to={`${t('/planning')}${priorityArea.urlRelative}/${
                      dashboard.interventionsSlug
                    }`}
                    text={`${dashboard.interventionName} ${t('Details')} (${
                      priorityArea.interventionsDbCount
                    })`}
                    disabled={loading}
                    size={
                      priorityArea.interventionsDbCount < 6 ? 'medium' : 'large'
                    }
                    variant={
                      priorityArea.interventionsDbCount < 6
                        ? 'outlined'
                        : 'contained'
                    }
                    disableElavation={!(priorityArea.interventionsDbCount < 6)}
                  />
                </Box>
              )}
              {priorityArea.interventionsDbCount < 11 && (
                <PlanningPriorityAreaFormDetailChildren
                  parent={priorityArea}
                  areaName={dashboard.interventionName}
                  areas={interventions}
                  setAreas={setInterventions}
                  addArea={addIntervention}
                  disabled={disabled}
                  errors={errors}
                  deleteMutation={deleteInterventionMutation}
                  updateOrderMutation={updateInterventionsOrderMutation}
                />
              )}
            </Box>
          </Stack>
        </Grid>
      </Grid>

      {/* stakeholders */}
      {dashboard.enableAreaStakeholders && (
        <Box sx={{ my: 2.5 }}>
          <PlanningFieldsInputStakeholders
            disabled={disabled}
            area={priorityArea}
            setArea={setPriorityArea}
          />
        </Box>
      )}

      {/* custom fields */}
      <ErrorBoundary FallbackComponent={ReactErrorComponent}>
        <PlanningFieldsInputCustomFields
          disabled={disabled || loading}
          area={priorityArea}
          setArea={setPriorityArea}
        />
      </ErrorBoundary>

      {/* budget + spent */}
      {dashboard.enablePriorityAreaFinancials && (
        <Grid container spacing={2} sx={{ my: 0.5 }}>
          <PlanningFieldsInputBudget
            disabled={disabled}
            errors={errors}
            area={priorityArea}
            setArea={setPriorityArea}
          />
        </Grid>
      )}

      {/* ------------------------------------------------------------------------------------------------------- */}

      {/* financiers */}

      {dashboard.enablePriorityAreaFinancials && (
        <ErrorBoundary FallbackComponent={ReactErrorComponent}>
          <FinanciersInput
            disabled={disabled || loading}
            errors={errors}
            area={priorityArea}
            setArea={setPriorityArea}
            addFinancier={addFinancier}
            newFinanciers={newFinanciers}
            setNewFinanciers={setNewFinanciers}
            openCollapse={openFinanciersCollapse}
            setOpenCollapse={setOpenFinanciersCollapse}
          />
        </ErrorBoundary>
      )}

      {/* targets */}

      <TargetsInput
        disabled={disabled}
        errors={errors}
        area={priorityArea}
        setArea={setPriorityArea}
        addTarget={addTarget}
        newTargets={newTargets}
        setNewTargets={setNewTargets}
        openCollapse={openTargetsCollapse}
        setOpenCollapse={setOpenTargetsCollapse}
      />

      {/* save and cancel buttons */}

      {!disabled && (
        <Stack
          spacing={2}
          direction={{ xs: 'column', sm: 'row' }}
          justifyContent="flex-end"
          sx={{
            mt: 2,
          }}
        >
          <PlanningPriorityAreaFormActionsCancel
            priorityAreaInitial={priorityArea}
            setPriorityArea={setPriorityArea}
            setInterventions={setInterventions}
            setDisabled={setDisabled}
            setNewTargets={setNewTargets}
            disabled={loading}
          />
          <PlanningPriorityAreaFormActionsSave
            valid={valid}
            priorityArea={priorityArea}
            paChildren={paChildren}
            interventions={interventions}
            newFinanciers={newFinanciers}
            setNewFinanciers={setNewFinanciers}
            newTargets={newTargets}
            setNewTargets={setNewTargets}
            setDisabled={setDisabled}
            loading={loading}
            setLoading={setLoading}
          />
        </Stack>
      )}
    </>
  );
}
