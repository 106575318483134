import React from 'react';

import { useTranslation } from 'react-i18next';

import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';

import { useMutation } from '@apollo/client';
import { DialogTitle } from '../../../../DialogTitle/dialog-title';
import { EntriesTable } from './import-entries-table';
import { useDashboardContext } from '../../../../_lib/context/dashboard-context';
import { IMPORT_EXPORT_UPDATE } from '../../../../_lib/graphql/mutations';
import { logError } from '../../../../_lib/error';
import { DialogModal } from '../../../../components/dialog-modal';

export function ImportDialogStepper({ open, setOpen, onDismiss, item }: any) {
  const { t } = useTranslation();
  const {
    dashboard: { colorPalette },
    setSnackbarOpen,
    setSnackbarMessage,
    setSnackbarIsError,
  } = useDashboardContext();
  const [updateMutation, { loading: updateLoading }] =
    useMutation(IMPORT_EXPORT_UPDATE);

  const [activeStep, setActiveStep] = React.useState(0);
  const [confirmDialogOpen, setConfirmDialogOpen] = React.useState(false);
  const [importError, setImportError] = React.useState(false);
  const [completed, setCompleted] = React.useState<{
    [k: number]: boolean;
  }>({});

  const steps = item.draftData?.map((dataItem: any) => t(dataItem.label));

  const isLastStep = () => {
    return activeStep === steps.length - 1;
  };

  const allStepsCompleted = () => {
    return Object.keys(completed).length === steps.length;
  };

  const stepComponents = item.draftData.map((dataItem: any) => {
    return (
      <Stack key={`step-component-${dataItem.label}`} spacing={1}>
        {/* created items */}
        <EntriesTable dataItem={dataItem} mode="new" />

        {/* modified items */}
        <EntriesTable dataItem={dataItem} mode="modified" />

        {/* deleted items */}
        <EntriesTable dataItem={dataItem} mode="deleted" />
      </Stack>
    );
  });

  // handlers
  const handleStep = (step: number) => () => {
    setActiveStep(step);
  };

  const completeStep = (step: number) => {
    const newCompleted = { ...completed };
    newCompleted[step] = true;
    setCompleted(newCompleted);
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
          steps.findIndex((step: any, i: number) => !(i in completed))
        : activeStep + 1;
    completeStep(activeStep);
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    const newCompleted = { ...completed };
    newCompleted[activeStep - 1] = false;
    setCompleted(newCompleted);
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
    if (importError) {
      setImportError(false);
    }
  };

  const updateToPendingStatus = () => {
    updateMutation({ variables: { id: item.id, status: 'PENDING' } })
      .then(() => {
        setSnackbarMessage(t('Import submitted successfully.'));
      })
      .catch((e) => {
        setSnackbarIsError(true);
        logError(e);
        setSnackbarMessage(
          t(
            'An error occured during import. Kinldy contact your dahsboard administrator.'
          )
        );
      })
      .finally(() => {
        setSnackbarOpen(true);
        setOpen(false);
      });
  };

  const handleFinish = () => {
    completeStep(activeStep);

    if (
      allStepsCompleted() ||
      Object.keys(completed).length >= steps.length - 1
    ) {
      if (importError) {
        setImportError(false);
      }
      // show confirm dialog
      setConfirmDialogOpen(true);
    } else {
      // if not all steps completed, show warning
      setImportError(true);
    }
  };

  return (
    <>
      {/* confirm cancel dialog */}
      <DialogModal
        open={confirmDialogOpen}
        loading={updateLoading}
        onDismiss={() => setConfirmDialogOpen(false)}
        onSubmit={updateToPendingStatus}
        title={t('Proceed with import?')}
        message={t('Once imported you cannot reverse the data changes.')}
      />
      <Dialog open={open} maxWidth="lg" fullWidth>
        <DialogTitle
          sx={{
            backgroundColor: colorPalette.primary?.bgcolor,
            color: 'white',
          }}
          onClose={() => {
            handleReset();
            onDismiss();
          }}
        >
          {t('Review the changes to be made.')}
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Box sx={{ minHeight: '60vh' }}>
            {importError && (
              <Alert
                severity="warning"
                sx={{ mb: 1, mx: 'auto', width: 'fit-content' }}
              >
                {t(
                  'You need to confirm all import sections inorder to proceed!'
                )}
              </Alert>
            )}
            <Stepper
              nonLinear
              activeStep={activeStep}
              sx={{ mb: 1 }}
              alternativeLabel
            >
              {steps.map((label: any, index: number) => (
                <Step key={label} completed={completed[index]}>
                  <StepButton color="inherit" onClick={handleStep(index)}>
                    {label}
                  </StepButton>
                </Step>
              ))}
            </Stepper>
            <Divider />
            <Box mt={2}>{stepComponents[activeStep]}</Box>
          </Box>
        </DialogContent>
        <Divider />
        {/* actions */}
        <DialogActions
          sx={{
            p: 2,
            px: 4,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
            rowGap: 1,
          }}
        >
          <Button
            color="secondary"
            disabled={activeStep === 0}
            onClick={handleBack}
            size="small"
            variant="outlined"
          >
            {t('Back')}
          </Button>
          {!isLastStep() ? (
            <Button onClick={handleNext} size="small" variant="outlined">
              {t('Confirm & next')}
            </Button>
          ) : (
            <Button
              variant="contained"
              size="small"
              onClick={handleFinish}
              disabled={updateLoading}
            >
              {t('Finish Import')}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
}
