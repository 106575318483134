import React from 'react';
import { useParams } from 'react-router-dom';

import { DragDropContext, Droppable } from 'react-beautiful-dnd';

import { useDashboardContext } from '../../_lib/context/dashboard-context';
import { logError } from '../../_lib/error';

export function DraggableAreaContainer({
  details,
  setDetails,
  filtered,
  children,
  droppableId,
  loading,
  setLoading,
  updateOrderMutation,
}: any) {
  const { setSnackbarOpen, setSnackbarIsError, setAppBarLoading } =
    useDashboardContext();

  const { priorityAreaId } = useParams<any>();

  const onDragEnd = (result: any) => {
    if (filtered) return;

    const { destination, source, draggableId } = result;

    // Check if not moved
    if (!destination) {
      return;
    }
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    const oldOrderDetailsIds = details.map(
      (detail: any) => detail.id || detail.fauxId
    );
    const newOrderedDetailsIds = details.map(
      (detail: any) => detail.id || detail.fauxId
    );

    newOrderedDetailsIds.splice(source.index, 1);
    newOrderedDetailsIds.splice(destination.index, 0, draggableId);

    setDetails(
      newOrderedDetailsIds.map((id: any) => {
        const detail = details.find((d: any) => d.id === id || d.fauxId === id);
        return {
          ...detail,
        };
      })
    );

    // update server
    if (!updateOrderMutation) return;
    if (setLoading) setLoading(true);
    setAppBarLoading(true);

    updateOrderMutation({
      variables: {
        ids: newOrderedDetailsIds.filter((id: any) => {
          const newOrderedDetail = details.find(
            (d: any) => d.id === id || d.fauxId === id
          );
          return newOrderedDetail.id;
        }),
        parentId: priorityAreaId,
        priorityAreaId,
      },
    })
      .catch((err: any) => {
        logError(err);
        setSnackbarIsError(true);
        setDetails(
          oldOrderDetailsIds.map((id: any) =>
            details.find((detail: any) => detail.id === id)
          )
        );
      })
      .finally(() => {
        setSnackbarOpen(true);
        if (setLoading) setLoading(false);
        setAppBarLoading(false);
      });
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable
        droppableId={droppableId || 'planning-intervention-form-list'}
        isDropDisabled={loading}
      >
        {(provided) => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            {children}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}
