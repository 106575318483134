import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';

import { BreadcrumbLayout } from './_layout';

// TODO: use an array<{label, link}> for breadcrumbs

export function ExecutivePriorityAreaBreadcrumbs({ priorityArea }: any) {
  const { t } = useTranslation();

  const history = useHistory();

  return (
    <BreadcrumbLayout id="breadcrumb-dashboard">
      <Link
        component="button"
        underline="hover"
        color="inherit"
        id="dashboard-crumb"
        onClick={() => history.push('/')}
        sx={{ display: 'flex', alignItems: 'center' }}
      >
        <HomeRoundedIcon
          fontSize="inherit"
          sx={{
            mr: { xs: 0, sm: 1 },
            fontSize: { xs: 'initial', sm: 'inherit' },
          }}
        />
        <Box sx={{ display: { xs: 'none', sm: 'inline' } }}>{t('Home')}</Box>
      </Link>
      <Link
        onClick={() => history.push('/executive/')}
        underline="hover"
        color="inherit"
      >
        {t('Executive view')}
      </Link>
      {priorityArea.parent && (
        <Link
          onClick={() =>
            history.push(`/executive${priorityArea.parent.urlRelative}`)
          }
          underline="hover"
          color="inherit"
        >
          {priorityArea.parent.name}
        </Link>
      )}
      <Typography color="text.primary">{priorityArea.name}</Typography>
    </BreadcrumbLayout>
  );
}
