import React from 'react';
import { useTranslation } from 'react-i18next';

import TextField from '@mui/material/TextField';
import Skeleton from '@mui/material/Skeleton';

import { useReportsContext } from '../../_lib/context/reports-context';

export function ReportsEditTitle({ formErrors }: any) {
  const { t } = useTranslation();
  const { report, pdfBlob, loading } = useReportsContext();

  if (!pdfBlob) return <Skeleton variant="rectangular" height={60} />;

  return (
    <TextField
      label={t('Title')}
      variant="outlined"
      fullWidth
      required
      defaultValue={report?.title || new Date().toDateString()}
      name="title"
      error={!!formErrors?.title}
      helperText={formErrors?.title}
      disabled={loading}
    />
  );
}
