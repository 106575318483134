import React from 'react';

import CardHeader from '@mui/material/CardHeader';
import IconButton from '@mui/material/IconButton';
import DragHandleIcon from '@mui/icons-material/DragHandle';

import { useDashboardContext } from '../../../_lib/context/dashboard-context';

import { PlanningAreaActionsHeader } from '../../area-actions/header';

export function PlanningPriorityAreaFormDetailHeader({
  priorityAreaInitial,
  filtered,
  toggleCollapse,
  setOpenPermissionsDialog,
  disabled,
  setDisabled,
  openCollapse,
  setOpenCollapse,
  listVariables,
}: any) {
  const { dashboard } = useDashboardContext();

  const reference = React.useMemo(
    () =>
      dashboard.mode !== 'TRACKER' ? `${priorityAreaInitial.reference} - ` : '',
    [dashboard.mode, priorityAreaInitial.reference]
  );

  return (
    <CardHeader
      title={`${reference}${priorityAreaInitial.name}`}
      titleTypographyProps={{
        variant:
          openCollapse.includes(priorityAreaInitial.id) || !disabled
            ? 'h5'
            : 'h6',
        sx: {
          transition: 'all 0.3s ease-in-out',
          fontWeight: 'normal',
        },
        onClick: () =>
          disabled ? toggleCollapse(priorityAreaInitial.id) : null,
      }}
      subheader={
        dashboard.enableAreaLevels
          ? priorityAreaInitial.level?.name
          : dashboard.priorityAreaName
      }
      avatar={
        !filtered &&
        !openCollapse.includes(priorityAreaInitial.id) &&
        !priorityAreaInitial.archivedAt &&
        disabled && (
          <IconButton
            sx={{
              cursor: 'grab !important',
            }}
            disableRipple
          >
            <DragHandleIcon />
          </IconButton>
        )
      }
      action={
        <PlanningAreaActionsHeader
          area={priorityAreaInitial}
          openLink={priorityAreaInitial.urlRelative}
          toggleCollapse={toggleCollapse}
          setOpenPermissionsDialog={setOpenPermissionsDialog}
          disabled={disabled}
          setDisabled={setDisabled}
          openCollapse={openCollapse}
          setOpenCollapse={setOpenCollapse}
          listVariables={listVariables}
        />
      }
      sx={{
        '& .MuiCardHeader-action': { alignSelf: 'center' },
      }}
    />
  );
}
