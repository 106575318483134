import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';

import { useAnalytics } from './use-analytics';
import { slugify } from '../../_utils/general-utils';

export function AnalyticsToC() {
  const { t } = useTranslation();
  const { charts } = useAnalytics();
  return (
    <Box sx={{ width: '100%', my: 2, position: 'sticky', top: 50 }}>
      <nav aria-label="table of contents">
        <List
          subheader={
            <ListSubheader
              component="div"
              sx={{
                backgroundColor: 'transparent',
              }}
            >
              {t('Charts')}
            </ListSubheader>
          }
        >
          {charts.map((chart: any) => {
            return (
              <ListItem disablePadding key={chart.title}>
                <ListItemButton href={`#${slugify(chart.title)}`}>
                  <ListItemText
                    primary={chart.title}
                    title={chart.description}
                  />
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>
      </nav>
    </Box>
  );
}
