import React from 'react';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

import { useTranslation } from 'react-i18next';

import { DialogTitle } from '../../../../DialogTitle/dialog-title';
import { MapTab } from './map-tab';

export function CustomMapUploadDialog({ open, setOpen }: any) {
  const { t } = useTranslation();

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog open={open} maxWidth="xs" fullWidth>
      <DialogTitle onClose={handleClose}>{t('Map Details')}</DialogTitle>
      <DialogContent dividers>
        <MapTab />
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" sx={{ mx: 2 }} onClick={handleClose}>
          {t('Close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
