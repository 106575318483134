import { Column } from '@material-table/core';

import Typography from '@mui/material/Typography';

// Priority Area
export const priorityAreaColumn: (props: any) => Column<any> = ({
  dashboard,
  t,
  priorityAreaName,
}: any) => ({
  id: 'id',
  title: priorityAreaName,
  field: 'intervention.priorityArea.name',
  emptyValue: () => (
    <Typography
      component="span"
      variant="body2"
      color="text.secondary"
      sx={{
        whiteSpace: 'nowrap',
        maxWidth: '180px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      }}
    >
      {t('Unassigned')}
    </Typography>
  ),
  render: (content: any) => {
    if (typeof content === 'string') {
      return (
        <Typography component="span" variant="body1">
          {content}
        </Typography>
      );
    }

    return (
      <>
        <Typography
          variant="body2"
          sx={{
            whiteSpace: 'nowrap',
            maxWidth: '180px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {content.intervention
            ? `${
                dashboard.mode !== 'TRACKER'
                  ? `${content.intervention.priorityArea.reference}. `
                  : ''
              }${content.intervention.priorityArea.name}`
            : '--'}
        </Typography>
        {dashboard.enableAreaLevels && (
          <Typography
            variant="caption"
            component="div"
            color="textSecondary"
            sx={{
              whiteSpace: 'nowrap',
              maxWidth: '180px',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {content.intervention.priorityArea.level?.name}
          </Typography>
        )}
      </>
    );
  },
  customExport: (content: any) =>
    content.intervention
      ? `${
          dashboard.mode !== 'TRACKER'
            ? `${content.intervention.priorityArea.reference}. `
            : ''
        }${content.intervention.priorityArea.name}`.trim()
      : '',
});

// intervention
export const interventionColumn: (props: any) => Column<any> = ({
  t,
  interventionName,
  dashboard,
}: any) => ({
  id: 'id',
  title: interventionName,
  field: 'intervention.name',
  emptyValue: () => (
    <Typography
      component="span"
      variant="body2"
      color="text.secondary"
      sx={{
        whiteSpace: 'nowrap',
        maxWidth: '180px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      }}
    >
      {t('Unassigned')}
    </Typography>
  ),
  render: (content: any) => {
    if (typeof content === 'string') {
      return (
        <Typography component="span" variant="body1">
          {content}
        </Typography>
      );
    }

    return (
      <Typography
        variant="body2"
        sx={{
          whiteSpace: 'nowrap',
          maxWidth: '180px',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {content.intervention ? (
          <>
            {dashboard.mode !== 'TRACKER'
              ? `${content.intervention.reference}. `
              : ''}
            {content.intervention.name}
          </>
        ) : (
          '--'
        )}
      </Typography>
    );
  },
  customExport: (content: any) =>
    content.intervention
      ? `
        ${
          dashboard.mode !== 'TRACKER'
            ? `${content.intervention.reference}. `
            : ''
        }${content.intervention.name}
      `.trim()
      : '',
});
