import React from 'react';
import { gql, useQuery } from '@apollo/client';

import { logError } from '../_lib/error';
import { SubscribeToMoreComponent } from '../components/subscribe-to-more';

const FRAGMENT_PA = gql`
  fragment PriorityAreaBreadcrumbFragment on PriorityAreaType {
    id
    reference
    name
    urlBreadcrumbs {
      id
      label
      to
    }
  }
`;
const QUERY_PA = gql`
  ${FRAGMENT_PA}
  query GetPriorityAreaBreadcrumbs($priorityAreaId: ID!) {
    priorityArea(priorityAreaId: $priorityAreaId) {
      ...PriorityAreaBreadcrumbFragment
    }
  }
`;
const SUBSCRIPTION_PA = gql`
  ${FRAGMENT_PA}
  subscription GetPriorityAreaBreadcrumbs($priorityAreaId: ID!) {
    priorityArea(priorityAreaId: $priorityAreaId) {
      ...PriorityAreaBreadcrumbFragment
    }
  }
`;

const FRAGMENT_INTV = gql`
  fragment InterventionBreadcrumbFragment on InterventionType {
    id
    reference
    name
    urlBreadcrumbs {
      id
      label
      to
    }
  }
`;
const QUERY_INTV = gql`
  ${FRAGMENT_INTV}
  query GetInterventionBreadcrumbs($interventionId: ID!) {
    intervention(interventionId: $interventionId) {
      ...InterventionBreadcrumbFragment
    }
  }
`;
const SUBSCRIPTION_INTV = gql`
  ${FRAGMENT_INTV}
  subscription GetInterventionBreadcrumbs($interventionId: ID!) {
    intervention(interventionId: $interventionId) {
      ...InterventionBreadcrumbFragment
    }
  }
`;

export function BreadcrumbsLoad({
  setBreadcrumbs,
  interventionId,
  priorityAreaId,
  setLoading,
}: any) {
  const QUERY_AREA = interventionId ? QUERY_INTV : QUERY_PA;
  const SUBSCRIPTION_AREA = interventionId
    ? SUBSCRIPTION_INTV
    : SUBSCRIPTION_PA;

  const { error, data, loading, subscribeToMore } = useQuery(QUERY_AREA, {
    variables: {
      priorityAreaId: interventionId ? undefined : priorityAreaId,
      interventionId: interventionId || undefined,
    },
  });

  // --------------------------------------------------------------------------------------------------------------------
  // --------------------------------------------------------------------------------------------------------------------
  // effects
  // --------------------------------------------------------------------------------------------------------------------

  // error
  React.useEffect(() => {
    if (error) {
      logError(error);
    }
  }, [error]);

  // loading
  React.useEffect(() => {
    setLoading(loading);
  }, [loading, setLoading]);

  // data
  React.useEffect(() => {
    if (data) {
      if (interventionId) {
        setBreadcrumbs(data.intervention?.urlBreadcrumbs || []);
      } else {
        setBreadcrumbs(data.priorityArea?.urlBreadcrumbs || []);
      }
    }
  }, [data, interventionId, setBreadcrumbs]);

  // --------------------------------------------------------------------------------------------------------------------

  if (!data) return null;

  return (
    <SubscribeToMoreComponent
      subscribeToMore={subscribeToMore}
      document={SUBSCRIPTION_AREA}
      variables={{
        priorityAreaId: interventionId ? undefined : priorityAreaId,
        interventionId: interventionId || undefined,
      }}
    />
  );
}
