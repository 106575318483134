import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';

import { useDashboardContext } from '../_lib/context/dashboard-context';
import { ReactErrorComponent } from '../_lib/react-error';

import { PriorityAreaSummary } from './summary';
import { PriorityAreaTargets } from './targets';
import { PriorityAreaTrackerOverview } from './tracker-overview';
import { PriorityAreaChildAreas } from './child-areas';
import { PriorityAreaMilestones } from './milestones';

export function PriorityArea() {
  const {
    dashboard: { enableAreaLevels, mode: dashboardMode },
  } = useDashboardContext();

  return (
    <>
      {/* summary: description + financials + interventions */}
      <ErrorBoundary FallbackComponent={ReactErrorComponent}>
        <PriorityAreaSummary />
      </ErrorBoundary>

      {/* targets */}
      <ErrorBoundary FallbackComponent={ReactErrorComponent}>
        <PriorityAreaTargets />
      </ErrorBoundary>

      {/* activity tracker section */}
      <ErrorBoundary FallbackComponent={ReactErrorComponent}>
        <PriorityAreaTrackerOverview />
      </ErrorBoundary>

      {/* children */}
      {(enableAreaLevels || dashboardMode === 'SUPRANATIONAL') && (
        <ErrorBoundary FallbackComponent={ReactErrorComponent}>
          <PriorityAreaChildAreas />
        </ErrorBoundary>
      )}

      {/* interventions cards section */}
      <ErrorBoundary FallbackComponent={ReactErrorComponent}>
        <PriorityAreaChildAreas isIntervention />
      </ErrorBoundary>

      {/* milestones slider section */}
      <ErrorBoundary FallbackComponent={ReactErrorComponent}>
        <PriorityAreaMilestones />
      </ErrorBoundary>
    </>
  );
}

export default PriorityArea;
