import React from 'react';
import { useTranslation } from 'react-i18next';

import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

import DownloadIcon from '@mui/icons-material/Download';

import { getApiURL } from '../../../../_utils/general-utils';

export function BackupCodesView({ onSuccess }: any) {
  const { t } = useTranslation();

  const downloadCodes = () => {
    document.getElementById('backup-codes-link')?.click();
    onSuccess();
  };

  return (
    <Stack spacing={1}>
      <Typography sx={{ fontWeight: 'bold' }}>
        {t('Two Factor Backup/Recovery Codes')}
      </Typography>
      <Typography variant="body2">
        {t(
          'You must download backup/recovery codes to use in case you lose your authenticator app or device.'
        )}{' '}
        {t(
          'Click on the button below to download backup codes and complete the two factor authentication setup.'
        )}
      </Typography>

      <Button
        sx={{ textTransform: 'none' }}
        variant="contained"
        color="primary"
        size="small"
        onClick={downloadCodes}
        startIcon={<DownloadIcon />}
      >
        {t('Download Backup Codes')}
      </Button>
      <Link
        id="backup-codes-link"
        download
        href={`${getApiURL()}/auth/totp/recovery-codes/`}
        sx={{ display: 'none' }}
      >
        {t('Download')}
      </Link>
    </Stack>
  );
}
