import Box from '@mui/material/Box';
import { ContentDialogControlsId } from './id';

export function ContentDialogControls() {
  return (
    <Box sx={{ mb: 2 }}>
      <ContentDialogControlsId />
    </Box>
  );
}
