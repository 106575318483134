import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import FilterListIcon from '@mui/icons-material/FilterList';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import UnfoldLessIcon from '@mui/icons-material/UnfoldLess';

import { useDashboardContext } from '../../_lib/context/dashboard-context';

export function PlanningInterventionFormListHeader({
  filterId,
  searchTerm,
  setSearchTerm,
  priorityArea,
  setOpenCollapse,
}: any) {
  const { t } = useTranslation();
  const history = useHistory();

  const { dashboard } = useDashboardContext();

  // ----------------------------------------------------------------------------------------------------
  // ----------------------------------------------------------------------------------------------------
  // effects
  // ----------------------------------------------------------------------------------------------------

  // menu for collapse and expand
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleOpenMenu = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCollapseAll = () => {
    setOpenCollapse([]);
    setAnchorEl(null);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  // ----------------------------------------------------------------------------------------------------

  return (
    <Box
      sx={{
        my: 2.5,
        display: 'flex',
        alignItems: { xs: 'flex-start', sm: 'center' },
        flexDirection: { xs: 'column', sm: 'row' },
      }}
    >
      <Box sx={{ flexGrow: 1, pr: { xs: 0, sm: 2 }, mb: { xs: 2, sm: 0 } }}>
        {!!priorityArea && (
          <Button
            variant="outlined"
            startIcon={<ArrowBackIcon />}
            size="small"
            sx={{ mb: 1.5, textTransform: 'none' }}
            onClick={() =>
              history.push(
                `${t('/planning')}${priorityArea.urlRelativeLevel}${
                  !priorityArea.archivedAt ? `?id=${priorityArea.id}` : ''
                }`
              )
            }
          >
            {priorityArea.reference}. {priorityArea.name}
          </Button>
        )}
        <Typography variant="h5" sx={{ fontSize: '1.3rem' }}>
          {priorityArea?.reference} - {priorityArea?.name}
        </Typography>
        <Typography variant="body1" color="textSecondary">
          {dashboard.interventionName}{' '}
          {!filterId && `(${priorityArea?.interventionsDbCount})`}
        </Typography>
      </Box>

      {/* filter + menu */}

      {!filterId && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {/* search */}
          <TextField
            label={t('Filter')}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            variant="outlined"
            size="small"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <FilterListIcon />
                </InputAdornment>
              ),
            }}
            sx={{
              '& .MuiInputBase-input': {
                bgcolor: 'white',
                pl: 1,
              },
            }}
            placeholder={`${t('Name')}, ${t('Reference')}`}
            fullWidth
          />

          {/* collapse and expand all */}
          <IconButton
            sx={{ ml: 1 }}
            aria-controls={open ? 'collapse-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleOpenMenu}
          >
            <MoreVertIcon />
          </IconButton>
          <Menu
            id="collapse-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleCloseMenu}
            MenuListProps={{
              'aria-labelledby': 'c-button',
            }}
          >
            <MenuItem onClick={handleCollapseAll}>
              <ListItemIcon>
                <UnfoldLessIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>{t('Collapse All')}</ListItemText>
            </MenuItem>
          </Menu>
        </Box>
      )}
    </Box>
  );
}
