import React from 'react';

import { useMutation } from '@apollo/client';

import { useDashboardContext } from '../../../_lib/context/dashboard-context';
import { logError } from '../../../_lib/error';

import { AREA_PERMISSIONS_QUERY } from '../../../_lib/graphql/queries';
import {
  AREA_PERMISSION_ADD,
  AREA_PERMISSION_REMOVE,
  AREA_PERMISSION_UPDATE,
} from '../../../_lib/graphql/mutations';

import { AreaPermissionsManageDialog } from './dialog';
import { AreaPermissionsManageConfirmRemoveDialog } from './confirm-remove-dialog';

export function AreaPermissionsManage({ open, setOpen, area }: any) {
  const [loading, setLoading] = React.useState(false);

  const [newMembers, setNewMembers] = React.useState([]);
  const [newPermission, setNewPermission] = React.useState('VIEW');
  const [sendNotification, setSendNotification] = React.useState(false);

  const [openConfirmRemove, setOpenConfirmRemove] = React.useState(false);
  const [removePermissionId, setRemovePermissionId] = React.useState('');

  const { setSnackbarIsError, setSnackbarOpen } = useDashboardContext();

  const [areaPermissionAddMutation] = useMutation(AREA_PERMISSION_ADD, {
    update(cache, { data: { areaPermissionAdd } }) {
      cache.writeQuery({
        query: AREA_PERMISSIONS_QUERY,
        variables: {
          areaType: area?.__typename,
          areaId: area?.id,
        },
        data: { areaPermissions: areaPermissionAdd.areaPermissions },
      });
    },
  });
  const [areaPermissionUpdateMutation] = useMutation(AREA_PERMISSION_UPDATE, {
    update(cache, { data: { areaPermissionUpdate } }) {
      cache.writeQuery({
        query: AREA_PERMISSIONS_QUERY,
        variables: {
          areaType: area?.__typename,
          areaId: area?.id,
        },
        data: { areaPermissions: areaPermissionUpdate.areaPermissions },
      });
    },
  });
  const [areaPermissionRemoveMutation] = useMutation(AREA_PERMISSION_REMOVE, {
    update(cache, { data: { areaPermissionRemove } }) {
      cache.writeQuery({
        query: AREA_PERMISSIONS_QUERY,
        variables: {
          areaType: area?.__typename,
          areaId: area?.id,
        },
        data: { areaPermissions: areaPermissionRemove.areaPermissions },
      });
    },
  });

  // --------------------------------------------------------------------------------------------------
  // --------------------------------------------------------------------------------------------------
  // handlers
  // --------------------------------------------------------------------------------------------------

  // share
  const handleShare = () => {
    setLoading(true);
    areaPermissionAddMutation({
      variables: {
        areaType: area?.__typename,
        areaId: area?.id,
        memberIds: newMembers.map((m: any) => m.id),
        permission: newPermission,
      },
    })
      .then(() => {
        setNewMembers([]);
        setSendNotification(false);
      })
      .catch((e) => {
        logError(e);
        setSnackbarIsError(true);
      })
      .finally(() => {
        setLoading(false);
        setSnackbarOpen(true);
      });
  };

  // remove
  const handleRemove = () => {
    setLoading(true);
    areaPermissionRemoveMutation({
      variables: {
        permissionId: removePermissionId,
      },
    })
      .then(() => {
        setOpenConfirmRemove(false);
      })
      .catch((e) => {
        logError(e);
        setSnackbarIsError(true);
      })
      .finally(() => {
        setSnackbarOpen(true);
        setLoading(false);
      });
  };

  // change
  const handleChange = (permissionId: any, newPerm: any) => {
    if (newPerm === 'REMOVE') {
      setRemovePermissionId(permissionId);
      setOpenConfirmRemove(true);
    } else {
      setLoading(true);
      areaPermissionUpdateMutation({
        variables: {
          permissionId,
          permission: newPerm,
        },
      })
        .catch((e) => {
          logError(e);
          setSnackbarIsError(true);
        })
        .finally(() => {
          setSnackbarOpen(true);
          setLoading(false);
        });
    }
  };

  // --------------------------------------------------------------------------------------------------

  return (
    <>
      {/* confirm remove */}
      <AreaPermissionsManageConfirmRemoveDialog
        open={openConfirmRemove}
        setOpen={setOpenConfirmRemove}
        handleRemove={handleRemove}
        loading={loading}
        setLoading={setLoading}
      />

      {/* dialog */}
      <AreaPermissionsManageDialog
        open={open}
        setOpen={setOpen}
        area={area}
        loading={loading}
        setLoading={setLoading}
        newMembers={newMembers}
        setNewMembers={setNewMembers}
        newPermission={newPermission}
        setNewPermission={setNewPermission}
        sendNotification={sendNotification}
        setSendNotification={setSendNotification}
        handleShare={handleShare}
        handleChange={handleChange}
      />
    </>
  );
}
