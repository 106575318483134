import React from 'react';

import { Box, Button, Popover, TableCell } from '@mui/material';
import { ChromePicker } from 'react-color';

export function MapColorsRow({ color, setOptions, colorPickerType }: any) {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const handleColorClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleColorClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? 'color-picker-popover' : undefined;

  // color picker
  // color picker
  const handleColorPicker = (c: any) => {
    if (colorPickerType === 'borderColor') {
      setOptions((old: any) => ({ ...old, borderColor: c.hex }));
    }
    if (colorPickerType === 'bgColor') {
      setOptions((old: any) => ({ ...old, bgColor: c.hex }));
    }
  };

  return (
    <>
      <TableCell>
        <Button
          aria-describedby={id}
          onClick={(e) => handleColorClick(e)}
          fullWidth
          sx={{ minWidth: '100px' }}
          variant="outlined"
          startIcon={
            <Box sx={{ p: 0.5, border: 1, borderColor: 'grey.500' }}>
              <Box
                sx={{
                  bgcolor: color,
                  width: '10px',
                  height: '10px',
                }}
              />
            </Box>
          }
        >
          {color}
        </Button>
      </TableCell>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleColorClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <ChromePicker color={color} onChangeComplete={handleColorPicker} />
      </Popover>
    </>
  );
}
