import React from 'react';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
  title?: string;
  actions?: React.ReactNode;
}

export function TabPanel(props: TabPanelProps) {
  const { children, value, index, title, actions, ...other } = props;
  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`dashboard-form-tabpanel-${index}`}
      aria-labelledby={`dashboard-form-tab-${index}`}
      style={{
        minWidth: 0,
        flexGrow: 1,
      }}
      {...other}
    >
      {value === index && (
        <Box
          sx={{
            p: 4,
            [theme.breakpoints.down('md')]: {
              p: 2,
            },
          }}
        >
          {(!!title || !!actions) && (
            <Stack
              spacing={2}
              sx={{ mb: 3, width: '100%' }}
              direction={{
                xs: 'column',
                sm: 'row',
              }}
              alignItems="center"
            >
              <Typography
                variant="h5"
                sx={{ flexGrow: 1, width: smDown ? '100%' : 'auto' }}
                id={`dashboard-form-tab-${index}`}
              >
                {title}
              </Typography>
              <Box sx={{ width: smDown ? '100%' : 'auto' }}>{actions}</Box>
            </Stack>
          )}
          {children}
        </Box>
      )}
    </div>
  );
}

TabPanel.defaultProps = {
  children: undefined,
  title: undefined,
  actions: undefined,
};
