import React from 'react';

import { useTranslation } from 'react-i18next';

import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';

import { getMediaUrl } from '../../../_utils/general-utils';

export function ReportActionDownload({
  report,
  disabled,
  isOutlined,
  textTransformNone,
}: any) {
  const { t } = useTranslation();

  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);

  const options = React.useMemo(
    () => [t('Download PDF'), t('Download PDF + CSV')],
    [t]
  );

  // ------------------------------------------------------------------------------------------------------------------------
  // ------------------------------------------------------------------------------------------------------------------------
  // handlers
  // ------------------------------------------------------------------------------------------------------------------------

  const handleClick = React.useCallback(() => {
    if (selectedIndex === 1 && !!report?.csvs?.length) {
      report.csvs.forEach((csv: any) => {
        if (csv.url) {
          window.open(getMediaUrl(csv.url), '_blank');
        } else if (csv.exportFile) {
          csv.exportFile();
        }
      });
    }
    if (report?.pdf?.url) {
      window.open(getMediaUrl(report.pdf.url), '_blank');
    }
  }, [report?.csvs, report?.pdf?.url, selectedIndex]);

  const handleMenuItemClick = React.useCallback(
    (event: React.MouseEvent<HTMLLIElement, MouseEvent>, index: number) => {
      setSelectedIndex(index);
      setOpen(false);
    },
    []
  );

  const handleToggle = React.useCallback(() => {
    setOpen((prevOpen) => !prevOpen);
  }, []);

  const handleClose = React.useCallback((event: Event) => {
    if (!anchorRef.current?.contains(event.target as HTMLElement)) {
      setOpen(false);
    }
  }, []);

  // ------------------------------------------------------------------------------------------------------------------------

  return (
    <>
      <ButtonGroup
        variant={!isOutlined ? 'contained' : 'outlined'}
        ref={anchorRef}
        aria-label="split button"
        sx={{ zIndex: 3 }}
        disableElevation
        disabled={disabled}
      >
        <Button
          sx={{
            flexGrow: 1,
            textTransform: textTransformNone ? 'none' : undefined,
          }}
          onClick={handleClick}
        >
          {options[selectedIndex]}
        </Button>
        <Button
          size="small"
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label="select download option"
          aria-haspopup="menu"
          onClick={handleToggle}
        >
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        sx={{ zIndex: 2, margin: 'unset !important' }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper sx={{ backgroundColor: 'white' }}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu">
                  {options.map((option, index) => (
                    <MenuItem
                      key={option}
                      selected={index === selectedIndex}
                      onClick={(event) => handleMenuItemClick(event, index)}
                    >
                      {option}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}
