import { TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';

export function ContentFormTitle({
  content,
  loading,
  changeValue,
  errors,
}: any) {
  const { t } = useTranslation();

  return (
    <TextField
      id="title-input"
      label={t('Title')}
      variant="outlined"
      multiline
      fullWidth
      value={content.title || ''}
      onChange={(event) => changeValue(event.target.value, 'title')}
      disabled={loading}
      error={!!errors.title}
      helperText={errors.title}
      required
    />
  );
}
