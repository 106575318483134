import React from 'react';
import { isEqual } from 'lodash';
import { useTranslation } from 'react-i18next';

import { makeStyles, createStyles } from '@mui/styles';
import { Grid, Paper, Theme, Typography } from '@mui/material';
import { green } from '@mui/material/colors';

import { AcceptContentUpdate } from '../actions/content-update/accept';
import { RejectContentUpdate } from '../actions/content-update/reject';
import { getStringDate } from '../../../_utils/general-utils';

import { DashboardContext } from '../../../_lib/context/dashboard-context';
import { ContentDialogContext } from '../../../_lib/context/content-dialog-context';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1, 1, 1, 2),
      margin: theme.spacing(0.5, 0, 1),
    },
    successText: {
      color: green[700],
    },
  })
);

export function ContentDialogContentUpdateAlert({ updateId }: any) {
  const classes = useStyles();
  const { t } = useTranslation();

  const {
    dashboard: { language },
  } = React.useContext(DashboardContext);
  const { content } = React.useContext(ContentDialogContext);

  const [update, setUpdate] = React.useState(
    content.updates.find((u: any) => u.id === updateId)
  );

  React.useEffect(() => {
    const contentUpdate = content.updates.find((u: any) => u.id === updateId);
    if (!isEqual(contentUpdate, update)) {
      setUpdate(contentUpdate);
    }
  }, [content, update, updateId]);

  return (
    <Paper variant="outlined" className={classes.root}>
      {/* pending */}
      {(update.status === 'PENDING' || update.status === 'OBSOLETE') && (
        <Grid
          container
          spacing={2}
          alignItems="center"
          justifyContent="space-between"
          style={{ flexWrap: 'nowrap' }}
        >
          <Grid item>
            <Typography variant="body2">
              {!!update.extensionRequest && (
                <>
                  {t('Extension requested until')}{' '}
                  {getStringDate(update.extensionRequest, language)}
                </>
              )}

              {!!update.suggestCompleted && (
                <>
                  {(content.isMilestone && t('Milestone')) || t('Activity')}{' '}
                  {t('suggested as completed')}
                </>
              )}
            </Typography>
          </Grid>
          <Grid item>
            {content.access === 'EDIT' &&
              (update.status === 'PENDING' || update.status === 'OBSOLETE') && (
                <Grid container spacing={1}>
                  <Grid item>
                    <AcceptContentUpdate content={content} update={update} />
                  </Grid>
                  <Grid item>
                    <RejectContentUpdate content={content} update={update} />
                  </Grid>
                </Grid>
              )}
          </Grid>
        </Grid>
      )}

      {/* rejected */}
      {!!update.rejectionMessage && (
        <Typography variant="body1" color="error">
          {update.rejectionMessage}
        </Typography>
      )}

      {/* accepted */}
      {update.status === 'ACCEPTED' && (
        <Typography variant="body1" className={classes.successText}>
          {t('Accepted')}
        </Typography>
      )}
    </Paper>
  );
}
