import { getMediaUrl } from '../general-utils';

export function getReportTemplateMetas(reportTemplateMetas: any[]) {
  const countryFlag = reportTemplateMetas.find(
    (meta: any) => meta.key === 'countryFlag'
  );
  return {
    countryFocalPoint: reportTemplateMetas.find(
      (meta: any) => meta.key === 'countryFocalPoint'
    )?.value,
    countryLead: reportTemplateMetas.find(
      (meta: any) => meta.key === 'countryLead'
    )?.value,
    countryFlag: getMediaUrl(countryFlag?.file?.url),
    countryFlagId: countryFlag?.id,
    countryFlagName: countryFlag?.file?.name,
    milestonesDisplay: reportTemplateMetas.find(
      (meta: any) => meta.key === 'milestonesDisplay'
    )?.value,
    pageOrientation: reportTemplateMetas.find(
      (meta: any) => meta.key === 'pageOrientation'
    )?.value,
  };
}
