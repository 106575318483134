import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';

import { ReportActionDelete } from './delete';
import { ReportActionShare } from './share';
import { ReportsEditActionSave } from './save';

export function ReportsEditActionsMenu({
  report,
  disabled,
  hideEdit,
  hideShare,
  hideDelete,
  showUnpublish,
  setShowTemplateMeta,
  setDisableEditTemplateMeta,
}: any) {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();

  const { reportId } = useParams<any>();

  // more button
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const openMore = React.useMemo(() => Boolean(anchorEl), [anchorEl]);

  const hasReport = React.useMemo(
    () => !!report && Object.keys(report).length,
    [report]
  );

  // ---------------------------------------------------------------------------------------------------
  // ---------------------------------------------------------------------------------------------------
  // handlers
  // ---------------------------------------------------------------------------------------------------

  const handleMoreClick = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    },
    []
  );

  const handleMoreClose = React.useCallback(() => {
    setAnchorEl(null);
  }, []);

  // ---------------------------------------------------------------------------------------------------

  return (
    <>
      <IconButton
        id="more-button"
        aria-label="more"
        aria-controls="more-menu"
        aria-haspopup="true"
        aria-expanded={openMore ? 'true' : undefined}
        onClick={handleMoreClick}
        disabled={disabled}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="more-menu"
        anchorEl={anchorEl}
        open={openMore}
        onClose={handleMoreClose}
        MenuListProps={{
          'aria-labelledby': 'more-button',
        }}
      >
        {!!hasReport &&
          !hideEdit &&
          (!reportId ||
            location.pathname.split('/')[
              location.pathname.split('/').length - 1
            ] !== t('edit')) && [
            <MenuItem
              onClick={() =>
                history.push(`${t('/reports')}/${report.id}${t('/edit')}`)
              }
              key="edit"
            >
              <ListItemIcon>
                <EditOutlinedIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>{t('Edit')}</ListItemText>
            </MenuItem>,
          ]}

        {!!setShowTemplateMeta && [
          <MenuItem
            onClick={() => {
              setShowTemplateMeta(true);
              setDisableEditTemplateMeta(false);
              handleMoreClose();
            }}
            key="edit template"
          >
            <ListItemIcon>
              <DashboardOutlinedIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>{t('Edit Template')}</ListItemText>
          </MenuItem>,
        ]}

        {((!!hasReport && !hideEdit) || !!setShowTemplateMeta) &&
          !!hasReport &&
          (!hideShare || !hideDelete || showUnpublish) && (
            <Divider key="edit divider" />
          )}

        {!!hasReport && !hideShare && (
          <ReportActionShare report={report} isMoreMenuItem />
        )}

        {!!hasReport && !hideDelete && (
          <ReportActionDelete report={report} isMoreMenuItem />
        )}

        {!!hasReport && showUnpublish && (
          <ReportsEditActionSave report={report} isMoreMenuItem isUnpublish />
        )}
      </Menu>
    </>
  );
}
