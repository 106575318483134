import React from 'react';
import { useTranslation } from 'react-i18next';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';

import { useDashboardContext } from '../../../_lib/context/dashboard-context';

import { CurrencyText } from '../../currency-text';
import { LinearProgressWithLabel } from '../../linear-progress/with-label';

import { AreaSummaryFinancialsFinanceMethods } from './finance-methods';
import { AreaSummaryFinancialsFinanciers } from './financiers';

export function AreaSummaryFinancials({ id, area }: any) {
  const { t } = useTranslation();
  const { dashboard } = useDashboardContext();

  const budgetPercentage =
    React.useMemo(
      () => area.budget && Math.round((area.totalFunding / area.budget) * 100),
      [area]
    ) || 0;
  const spentPercentage =
    React.useMemo(
      () =>
        area.budget && Math.round((area.currentlySpent / area.budget) * 100),
      [area]
    ) || 0;

  return (
    <Card variant="outlined">
      <CardContent>
        <Typography variant="h5" sx={{ flexGrow: 1 }}>
          {t('Financials')}
        </Typography>

        <Typography variant="body1" sx={{ mb: 0.5 }}>
          {dashboard.enableCustomFinanceLabels
            ? dashboard.customFinanceBudgetLabel
            : t('Budget')}
          :{' '}
          {(area.budget > 0 && (
            <CurrencyText
              amount={area.budget}
              currency={
                dashboard.enableMultipleCurrencies ? area.currency : null
              }
            />
          )) ||
            ' ---'}
        </Typography>

        <Box sx={{ mb: 1 }}>
          <Typography variant="body2">
            %{' '}
            {dashboard.enableCustomFinanceLabels
              ? dashboard.customFinanceFinancedLabel
              : t('Financed')}{' '}
            <Typography variant="caption">
              (
              <CurrencyText
                amount={area.totalFunding}
                currency={
                  dashboard.enableMultipleCurrencies ? area.currency : null
                }
              />
              )
            </Typography>
          </Typography>
          <LinearProgressWithLabel
            value={budgetPercentage}
            sx={{ height: '24px', borderRadius: '3px' }}
          />
        </Box>

        <Typography variant="body2">
          %{' '}
          {dashboard.enableCustomFinanceLabels
            ? dashboard.customFinanceSpentLabel
            : t('Spent')}{' '}
          <Typography variant="caption">
            (
            <CurrencyText
              amount={area.currentlySpent}
              currency={
                dashboard.enableMultipleCurrencies ? area.currency : null
              }
            />
            )
          </Typography>
        </Typography>
        <LinearProgressWithLabel
          value={spentPercentage}
          sx={{ height: '24px', borderRadius: '3px' }}
          color={
            (spentPercentage >= 100 && 'error') ||
            (spentPercentage >= 80 && 'warning') ||
            'primary'
          }
        />

        <Divider sx={{ my: 3 }} />

        <Stack spacing={2}>
          {/* financiers */}
          <AreaSummaryFinancialsFinanciers
            id={id}
            financiers={area.financiers}
          />

          {/* methods */}
          <AreaSummaryFinancialsFinanceMethods
            id={id}
            financiers={area.financiers}
          />
        </Stack>
      </CardContent>
    </Card>
  );
}

AreaSummaryFinancials.defaultProps = {
  id: 'summary-finance-open-more',
};
