import React from 'react';
import { useLocation } from 'react-router-dom';

import {
  AnalyticsProvider,
  FirebaseAppProvider,
  useAnalytics,
  useFirebaseApp,
  useInitPerformance,
} from 'reactfire';
import { getAnalytics, logEvent } from 'firebase/analytics';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

function FirebaseServices({ children }: any) {
  const app = useFirebaseApp();

  // init performance
  useInitPerformance(async (firebaseApp) => {
    const { getPerformance } = await import('firebase/performance');
    return getPerformance(firebaseApp);
  });

  return (
    <AnalyticsProvider sdk={getAnalytics(app)}>{children}</AnalyticsProvider>
  );
}

export function FirebaseEffects() {
  const location = useLocation();
  const analytics = useAnalytics();

  // log analytics
  React.useEffect(() => {
    logEvent(analytics, 'page_view', {
      page_location: location.pathname + location.search,
    });
  }, [analytics, location.pathname, location.search]);

  return null;
}

export function FirebaseProvider({ children }: any) {
  if (!process.env.REACT_APP_FIREBASE_ENABLED) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>;
  }

  return (
    <FirebaseAppProvider firebaseConfig={firebaseConfig}>
      <FirebaseServices>
        {children}
        <FirebaseEffects />
      </FirebaseServices>
    </FirebaseAppProvider>
  );
}
