import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';

import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';

import { useDashboardContext } from '../../../../_lib/context/dashboard-context';
import { logError } from '../../../../_lib/error';

import { DASHBOARD_UPDATE } from '../../../../_lib/graphql/mutations';

import { SwitchIcon } from '../../../../components/icons/switch-icon';

export function AdvancedContentGroupsActivateWeights() {
  const { t } = useTranslation();
  const { dashboard } = useDashboardContext();

  const [enabled, setEnabled] = React.useState(
    dashboard.enableActivityGroupWeights
  );
  const [loading, setLoading] = React.useState(false);

  const [dashboardUpdateMutation] = useMutation(DASHBOARD_UPDATE);

  // --------------------------------------------------------------------------------------------------------------------
  // --------------------------------------------------------------------------------------------------------------------
  // effects
  // --------------------------------------------------------------------------------------------------------------------

  React.useEffect(() => {
    setEnabled(dashboard.enableActivityGroupWeights);
  }, [dashboard.enableActivityGroupWeights]);

  // --------------------------------------------------------------------------------------------------------------------
  // --------------------------------------------------------------------------------------------------------------------
  // handlers
  // --------------------------------------------------------------------------------------------------------------------

  const toggleChecked = () => {
    setEnabled(!dashboard.enableActivityGroupWeights);
    setLoading(true);

    const inputs = {} as any;
    inputs.enableActivityGroupWeights = !dashboard.enableActivityGroupWeights;

    dashboardUpdateMutation({
      variables: {
        ...inputs,
      },
    })
      .catch((err) => {
        setEnabled(dashboard.enableActivityGroupWeights);
        logError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // --------------------------------------------------------------------------------------------------------------------

  return (
    <FormControl component="fieldset" variant="standard">
      <FormGroup>
        <FormControlLabel
          control={
            <Switch
              checked={enabled}
              onChange={() => toggleChecked()}
              checkedIcon={
                <SwitchIcon enabled={enabled} loading={loading} size={16} />
              }
              icon={
                <SwitchIcon enabled={enabled} loading={loading} size={16} />
              }
              size="small"
            />
          }
          label={
            <Typography
              variant="body2"
              color={
                dashboard.enableActivityGroupWeights
                  ? 'primary'
                  : 'text.primary'
              }
            >
              {t('Enable weights')}
            </Typography>
          }
          disabled={loading}
          sx={{
            alignItems: 'flex-start',
            m: 0,
          }}
        />
      </FormGroup>
    </FormControl>
  );
}
