import React from 'react';

import Skeleton from '@mui/material/Skeleton';

import { useSummary } from '../../../_utils/hooks/queries/use-summary';
import { AreaSummaryChartFinance } from '../chart/finance';

export function AreaSummaryCardFinance({ area }: any) {
  const areaType =
    area?.__typename === 'PriorityAreaType'
      ? 'priorityArea'
      : area?.__typename === 'InterventionType'
      ? 'intervention'
      : undefined;

  const { data, loading, error } = useSummary(
    'finance',
    {
      priorityAreaId: areaType === 'priorityArea' ? area.id : undefined,
      interventionId: areaType === 'intervention' ? area.id : undefined,
    },
    areaType
  );

  if (error || !areaType) return null;

  if (loading || !data)
    return <Skeleton variant="rectangular" height={120} sx={{ mb: 2 }} />;

  return <AreaSummaryChartFinance area={data[areaType]} />;
}
