import React from 'react';
import { useTranslation } from 'react-i18next';

import Button from '@mui/material/Button';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { useLocalStorage } from '../../../_utils/hooks/use-local-storage';
import { consolidateFinanciers } from '../../../_utils/finance-utils';

import { AreaSummaryFinancialsFinanciersRow } from './financiers-row';

export function AreaSummaryFinancialsFinanciers({
  id,
  financiers: financiersOg,
  disableCollapse,
  useAmountDb,
}: any) {
  const { t } = useTranslation();

  const [openMore, setOpenMore] = useLocalStorage(`${id}-financiers`, false);

  const financiers = React.useMemo(
    () => consolidateFinanciers(financiersOg),
    [financiersOg]
  );

  if (Object.keys(financiers).length === 0) return null;

  return (
    <Box>
      {!disableCollapse && (
        <Button
          fullWidth
          onClick={() => setOpenMore(!openMore)}
          endIcon={
            <ExpandMoreIcon
              sx={{
                transform: openMore ? 'rotate(0deg)' : 'rotate(-90deg)',
                transition: 'transform 0.2s ease-in-out',
                displayPrint: 'none',
              }}
            />
          }
          variant="outlined"
          sx={{
            justifyContent: 'space-between',
            textTransform: 'none',
            borderRadius: openMore ? '4px 4px 0 0' : '4px',
            transition: 'border-radius 0.2s ease-in-out',
            displayPrint: openMore ? 'flex' : 'none',
          }}
        >
          {t('Financiers Summary')}
        </Button>
      )}

      {/* table */}
      <Collapse in={openMore || disableCollapse}>
        <TableContainer
          component={Paper}
          variant="outlined"
          sx={{ maxHeight: 440, borderRadius: '0px 0px 4px 4px', borderTop: 0 }}
        >
          <Table stickyHeader size="small">
            <TableHead>
              <TableRow>
                <TableCell sx={{ bgcolor: 'grey.200' }}>
                  {t('Financier')}
                </TableCell>
                <TableCell align="right" sx={{ bgcolor: 'grey.200' }}>
                  {t('Amount')}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.keys(financiers).map((name: any) => (
                <AreaSummaryFinancialsFinanciersRow
                  key={name.toString()}
                  financier={financiers[name]}
                  useAmountDb={useAmountDb}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Collapse>
    </Box>
  );
}
