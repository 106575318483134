import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Skeleton from '@mui/material/Skeleton';

import { CurrencyText } from '../../currency-text';
import { useDashboardContext } from '../../../_lib/context/dashboard-context';

import { InterventionStatusChip } from '../../intervention-status-chip';
import { AreaSummaryInterventionsActivitiesBar } from './activities-bar';
import { logError } from '../../../_lib/error';
import { SubscribeToMoreComponent } from '../../subscribe-to-more';
import { AreaSummaryLeadText } from '../lead-text/list';

const INTV_FRAGMENT = gql`
  fragment InterventionSummaryTableRowFragment on InterventionType {
    id
    reference
    name

    leads {
      id
      name
    }
    coLeads {
      id
      name
    }
    leadsFreeText
    coLeadsFreeText

    currency
    budget
    urlRelative

    priorityArea {
      id
      reference
      name
      level {
        id
        name
      }
    }

    priorityAreaDb {
      id
      reference
      name
      level {
        id
        name
      }
    }

    financiers {
      name
    }

    status {
      id
      name
      color
    }
    classifications {
      id
      name
    }
    regions {
      id
      name
    }
    programme {
      id
      name
    }
  }
`;

const INTV_QUERY = gql`
  query InterventionSummaryTableRowQuery($interventionId: ID!) {
    intervention(interventionId: $interventionId) {
      ...InterventionSummaryTableRowFragment
    }
  }
  ${INTV_FRAGMENT}
`;

const INTV_SUBSCRIPTION = gql`
  subscription InterventionSummaryTableRowSubscription($interventionId: ID!) {
    intervention(interventionId: $interventionId) {
      ...InterventionSummaryTableRowFragment
    }
  }
  ${INTV_FRAGMENT}
`;

export function InterventionsSummaryTableRow({
  intervention,
  hasStatus,
  hasBudget,
  showPriorityArea,
  openInNewTab,
}: any) {
  const { t } = useTranslation();
  const history = useHistory();

  const {
    dashboard: {
      enableCustomClassificationLabel,
      customClassificationLabel,
      enableCustomRegionLabel,
      customRegionLabel,
      mode: dashboardMode,
      enableLeadsMulti,
      enableLeadsFreeText,
    },
  } = useDashboardContext();

  const { data, loading, error, refetch, subscribeToMore } = useQuery(
    INTV_QUERY,
    {
      variables: {
        interventionId: intervention.id,
      },
    }
  );

  // --------------------------------------------------------------------------------------------------------
  // --------------------------------------------------------------------------------------------------------
  // handlers
  // --------------------------------------------------------------------------------------------------------

  const handleNavigateTo = (path: string) => {
    if (openInNewTab) {
      window.open(path, '_blank');
    } else {
      history.push(path);
    }
  };

  // --------------------------------------------------------------------------------------------------------
  // --------------------------------------------------------------------------------------------------------
  // effects
  // --------------------------------------------------------------------------------------------------------

  // error
  React.useEffect(() => {
    if (error) logError(error);
  }, [error]);

  // --------------------------------------------------------------------------------------------------------

  // --------------------------------------------------------------------------------------------------------

  if (error)
    return (
      <TableRow>
        <TableCell
          colSpan={[true, hasBudget, hasStatus].filter(Boolean).length}
        >
          <Typography
            variant="body2"
            color="error"
            sx={{ fontStyle: 'italic', textAlign: 'center' }}
          >
            {t('Something went wrong. Please try again later.')}
          </Typography>
        </TableCell>
      </TableRow>
    );

  if (loading || !data?.intervention) {
    if (!data?.intervention && !loading) refetch();
    return (
      <TableRow>
        <TableCell
          colSpan={[true, hasBudget, hasStatus].filter(Boolean).length}
        >
          <Skeleton variant="rectangular" height={50} />
        </TableCell>
      </TableRow>
    );
  }

  return (
    <>
      <TableRow
        hover={dashboardMode !== 'SUPRANATIONAL'}
        sx={{
          '&:last-child td, &:last-child th': { border: 0 },
          '& .MuiTableCell-root': {
            borderBottom: 0,
          },
          cursor: dashboardMode !== 'SUPRANATIONAL' ? 'pointer' : undefined,
        }}
        onClick={
          dashboardMode !== 'SUPRANATIONAL'
            ? () => handleNavigateTo(data.intervention.urlRelative)
            : undefined
        }
      >
        <TableCell sx={{ maxWidth: '400px' }}>
          <Typography variant="body2">
            {`${
              data.intervention?.reference
                ? `${data.intervention.reference} - `
                : ''
            }${data.intervention?.name}`}
          </Typography>

          {/* priority area */}

          {(showPriorityArea || dashboardMode === 'SUPRANATIONAL') &&
            !!data.intervention.priorityArea && (
              <Typography
                variant="caption"
                color="textSecondary"
                component="div"
              >
                {data.intervention.priorityArea.id !==
                  data.intervention.priorityAreaDb.id &&
                  window.location.pathname === '/' && (
                    <>
                      <b>{data.intervention.priorityArea.level.name}: </b>
                      {`${data.intervention.priorityArea.reference} - ${data.intervention.priorityArea.name}`}
                      {' . '}
                    </>
                  )}
                <b>{data.intervention.priorityAreaDb.level.name}: </b>
                {`${data.intervention.priorityAreaDb.reference} - ${data.intervention.priorityAreaDb.name}`}
              </Typography>
            )}

          {/* classifications and regions */}
          <Typography variant="caption" color="textSecondary" component="div">
            {data.intervention.classifications?.length > 0 && (
              <>
                <b>
                  {enableCustomClassificationLabel
                    ? customClassificationLabel
                    : t('Classification')}
                  :
                </b>{' '}
                {data.intervention.classifications
                  .map((c: any) => c.name)
                  .join(', ')}
              </>
            )}
            {data.intervention.classifications?.length > 0 &&
              data.intervention.regions?.length > 0 && <b> . </b>}
            {data.intervention.regions?.length > 0 && (
              <>
                <b>
                  {enableCustomRegionLabel ? customRegionLabel : t('Regions')}:
                </b>{' '}
                {data.intervention.regions.map((r: any) => r.name).join(', ')}
              </>
            )}
          </Typography>

          {/* programme */}
          {data.intervention?.programme && (
            <Typography variant="caption" color="textSecondary" component="div">
              <b>{t('Programme')}: </b>
              {data.intervention.programme.name || t('Unspecified')}
            </Typography>
          )}
        </TableCell>

        {/* leads */}
        <TableCell>
          <AreaSummaryLeadText
            area={data.intervention}
            variant="body2"
            isLead
          />
          <AreaSummaryLeadText area={data.intervention} variant="body2" />
        </TableCell>

        {/* status */}
        {hasStatus && (
          <TableCell sx={{ whiteSpace: 'nowrap' }}>
            <InterventionStatusChip
              status={data.intervention.status}
              size="small"
            />
          </TableCell>
        )}

        {/* budget */}
        {hasBudget && (
          <TableCell
            align="left"
            sx={{ whiteSpace: 'nowrap', maxWidth: '250px' }}
          >
            {data.intervention.budget ? (
              <CurrencyText amount={data.intervention.budget} />
            ) : (
              'N/A'
            )}
            <Box>
              <Typography
                variant="caption"
                color="textSecondary"
                sx={{
                  whiteSpace: 'pre-wrap',
                  wordWrap: 'normal',
                }}
              >
                <b>{t('Financier')}: </b>
                {data.intervention.financiers
                  .map((ff: any) => ff.name)
                  .join(', ')}
              </Typography>
            </Box>
          </TableCell>
        )}
      </TableRow>

      {/* activities progress bar */}
      <TableRow
        sx={{
          borderTop: '0',
          '&:last-child td, &:last-child th': { border: 0 },
        }}
      >
        <TableCell
          colSpan={
            [hasStatus, hasBudget].every((b: any) => b)
              ? 5
              : [hasStatus, hasBudget].some((b: any) => b)
              ? 4
              : 2
          }
          sx={{
            paddingTop: '0',
          }}
        >
          <AreaSummaryInterventionsActivitiesBar
            intervention={data.intervention}
          />
        </TableCell>
      </TableRow>

      {/* subscribe to more */}
      {!!data && (
        <SubscribeToMoreComponent
          document={INTV_SUBSCRIPTION}
          variables={{
            interventionId: intervention.id,
          }}
          subscribeToMore={subscribeToMore}
        />
      )}
    </>
  );
}
