import { useTranslation } from 'react-i18next';

import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

export function PlanningAreaPermissionsManagePermissionSelect({
  value,
  onChange,
  disabled,
  includeRemove,
}: any) {
  const { t } = useTranslation();
  return (
    <FormControl
      sx={{ width: '100px', maxWidth: '100%' }}
      size="small"
      fullWidth
      disabled={disabled}
    >
      <InputLabel id="permission-select-label">{t('Permission')}</InputLabel>
      <Select
        labelId="permission-select-label"
        id="permission-select"
        value={value}
        label={t('Permission')}
        onChange={onChange}
        size="small"
        disabled={disabled}
      >
        <MenuItem value="VIEW">{t('Viewer')}</MenuItem>
        <MenuItem value="COMMENT">{t('Commenter')}</MenuItem>
        <MenuItem value="EDIT">{t('Editor')}</MenuItem>
        {includeRemove && [
          <Divider key="removeDivider" />,
          <MenuItem key="remove" value="REMOVE">
            {t('Remove')}
          </MenuItem>,
        ]}
      </Select>
    </FormControl>
  );
}
