import React from 'react';
import { useLocation } from 'react-router-dom';

import { DashboardContext } from './dashboard-context';

export const RouterContext = React.createContext<any>('');

export function RouterContextProvider({ children }: any) {
  const location = useLocation();

  const {
    dashboard: { interventionsSlug },
  } = React.useContext(DashboardContext);

  // data
  const [priorityAreaId, setPriorityAreaId] = React.useState<any>(undefined);
  const [interventionId, setInterventionId] = React.useState<any>(undefined);

  // context
  const contextValue = React.useMemo(
    () => ({
      priorityAreaId,
      interventionId,
    }),
    [interventionId, priorityAreaId]
  );

  React.useEffect(() => {
    // e.g /priorityarea/1/intervention/2
    const paths = location.pathname.split('/');
    let intvId;
    let paId;

    if (
      paths?.length &&
      !Number.isNaN(Number.parseInt(paths[paths.length - 1], 10))
    ) {
      intvId = paths[paths.length - 1];
      if (
        paths.length > 4 &&
        paths[paths.length - 2] === interventionsSlug &&
        !Number.isNaN(Number.parseInt(paths[paths.length - 3], 10))
      ) {
        // we have found a priority area
        paId = Number.parseInt(paths[paths.length - 1], 10);
      } else {
        // only priority area
        [paId, intvId] = [intvId, paId];
      }
    }

    setPriorityAreaId(paId);
    setInterventionId(intvId);
  }, [location, interventionsSlug]);

  return (
    <RouterContext.Provider value={contextValue}>
      {children}
    </RouterContext.Provider>
  );
}

export function useRouterContext() {
  return React.useContext(RouterContext);
}
