import React from 'react';
import { gql, useQuery } from '@apollo/client';

import { logError } from '../../../_lib/error';
import {
  FILTER_VARIABLES,
  FILTER_VARIABLES_DEF,
} from '../../../_lib/graphql/fragments';

const PAS_PARTIAL_FRAGMENT = gql`
  fragment UseAreasPriorityAreasPartialFragment on PriorityAreaType {
    id
    reference
    name
    dashboardOfDashboards {
      id
      dashboard {
        id
        name
      }
    }
    level {
      id
      name
    }
  }
`;

const PAS_FRAGMENT = gql`
  fragment UseAreasPriorityAreasFragment on PriorityAreaType {
    ...UseAreasPriorityAreasPartialFragment
    urlRelative
    lead {
      id
      name
      email
    }
    coLead {
      id
      name
      email
    }
    leads {
      id
      name
      email
    }
    coLeads {
      id
      name
      email
    }
    leadsFreeText
    coLeadsFreeText
  }
  ${PAS_PARTIAL_FRAGMENT}
`;

const PAS_QUERY = gql`
  query UseAreasPriorityAreas(
    $parentId: ID
    ${FILTER_VARIABLES_DEF}
  ) {
    priorityAreas(
      parentId: $parentId
      ${FILTER_VARIABLES}
    ) {
      items { ...UseAreasPriorityAreasFragment }
      meta { count }
    }
  }
  ${PAS_FRAGMENT}
`;
const PAS_SUBSCRIPTION = gql`
  subscription UseAreasPriorityAreas(
    $parentId: ID
    ${FILTER_VARIABLES_DEF}
  ) {
    priorityAreas(
      parentId: $parentId
      ${FILTER_VARIABLES}
    ) {
      items { ...UseAreasPriorityAreasFragment }
      meta { count }
    }
  }
  ${PAS_FRAGMENT}
`;

const INTVS_FRAGMENT = gql`
  fragment UseAreasInterventionsFragment on InterventionType {
    id
    reference
    name
    urlRelative
    priorityArea {
      ...UseAreasPriorityAreasPartialFragment
    }
    lead {
      id
      name
      email
    }
    coLead {
      id
      name
      email
    }
    leads {
      id
      name
      email
    }
    coLeads {
      id
      name
      email
    }
    leadsFreeText
    coLeadsFreeText
  }
  ${PAS_PARTIAL_FRAGMENT}
`;

const INTVS_QUERY = gql`
  ${INTVS_FRAGMENT}
  query UseAreasInterventions(
    $priorityAreaId: ID
    ${FILTER_VARIABLES_DEF}
  ) {
    interventions(
      priorityAreaId: $priorityAreaId
      ${FILTER_VARIABLES}
    ) {
      items{
        ...UseAreasInterventionsFragment
      }
      meta { count }
    }
  }
`;
const INTVS_SUBSCRIPTION = gql`
  ${INTVS_FRAGMENT}
  subscription UseAreasInterventions(
    $priorityAreaId: ID
    ${FILTER_VARIABLES_DEF}
  ) {
    interventions(
      priorityAreaId: $priorityAreaId
      ${FILTER_VARIABLES}
    ) {
      items {...UseAreasInterventionsFragment }
      meta { count }
    }
  }
`;

export function useAreas(
  areaType: 'intervention' | 'priorityArea',
  variables?: any
) {
  const { data, loading, error, refetch, subscribeToMore } = useQuery(
    areaType === 'priorityArea' ? PAS_QUERY : INTVS_QUERY,
    {
      variables,
    }
  );

  // error
  React.useEffect(() => {
    if (error) logError(error);
  }, [error]);

  // refetch
  React.useEffect(() => {
    if (!data && !loading && !error) refetch();
  }, [data, loading, error, refetch]);

  const readyToSubscribe = React.useMemo(() => !!data, [data]);

  // subscription
  React.useEffect(() => {
    let unsubscribe: any;
    if (readyToSubscribe) {
      unsubscribe = subscribeToMore({
        document:
          areaType === 'priorityArea' ? PAS_SUBSCRIPTION : INTVS_SUBSCRIPTION,
        variables,
        updateQuery: (prev, { subscriptionData }) => {
          if (!subscriptionData.data) return prev;
          return subscriptionData.data;
        },
      });
    }
    return () => {
      if (unsubscribe) unsubscribe();
    };
  }, [readyToSubscribe, subscribeToMore, areaType, variables]);

  return { data, loading, error };
}
