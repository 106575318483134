import React from 'react';
import { useTranslation } from 'react-i18next';

import Alert from '@mui/material/Alert';
import PublicIcon from '@mui/icons-material/Public';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import { useDashboardContext } from '../../../../_lib/context/dashboard-context';
import { useDashboardUpdate } from '../../../../_utils/hooks/use-dashboard-update';
import { logError } from '../../../../_lib/error';

export function MapForm() {
  const { t } = useTranslation();

  const { setSnackbarOpen, setSnackbarMessage, setSnackbarIsError } =
    useDashboardContext();
  const { dashboardUpdateMutation, loading: updating } = useDashboardUpdate();
  const [fileName, setFileName] = React.useState('');

  const customMapviewMapRef = React.useRef<null | any>(null);

  const [formError, setFormError] = React.useState<{
    error: boolean;
    msg: string;
  }>({
    error: false,
    msg: `${t('Please select a valid map file')} (.json or .geo.json)`,
  });

  const clearError = () => {
    if (formError.error) {
      setFormError((prev) => ({ ...prev, error: false }));
    }
  };

  // ------------------------------------------------------------------------------------------------------------------
  // ------------------------------------------------------------------------------------------------------------------
  // handlers
  // ------------------------------------------------------------------------------------------------------------------

  const handleFileChange = () => {
    if (customMapviewMapRef.current?.files[0]) {
      setFileName(customMapviewMapRef.current?.files[0].name);
      clearError();
    }
  };

  const handleSave = () => {
    if (!customMapviewMapRef.current?.files[0]) {
      setFormError((prev) => ({ ...prev, error: true }));
      return;
    }
    clearError();
    dashboardUpdateMutation({
      variables: {
        ...(customMapviewMapRef.current?.files[0]
          ? { customMapviewMap: customMapviewMapRef.current?.files[0] }
          : {}),
      },
    })
      .then((res) => {
        if (res.data.dashboardUpdate.success) {
          setSnackbarMessage(t('Map saved successfully.'));
          if (setFileName) setFileName('');
        }
      })
      .catch((e) => {
        logError(e);
        setSnackbarIsError(true);
      })
      .finally(() => setSnackbarOpen(true));
  };

  // ------------------------------------------------------------------------------------------------------------------

  return (
    <>
      <Box
        sx={{
          my: 2,
          display: 'flex',
          flexWrap: 'wrap',
          alignItems: 'center',
          columnGap: 2,
          rowGap: 1,
        }}
        component="div"
      >
        <Typography color="grey.800" fontSize=".9rem" component="span">
          {`${t('Select Map File')} (GeoJSON):`}
        </Typography>
        <Button
          variant="outlined"
          component="label"
          sx={{ textTransform: 'none' }}
          size="small"
        >
          {t('Choose File')}
          <input
            type="file"
            aria-label="choose map file"
            ref={customMapviewMapRef}
            hidden
            accept=".json, .geojson, .topojson"
            onChange={handleFileChange}
          />
        </Button>
      </Box>
      <Stack spacing={2}>
        {fileName && (
          <Typography color="grey.700" fontSize=".8rem" component="span">
            {t('Selected file')}: {fileName}
          </Typography>
        )}
        {formError.error && <Alert severity="error">{formError.msg}</Alert>}
        <LoadingButton
          loading={updating}
          variant="contained"
          startIcon={<PublicIcon />}
          size="small"
          onClick={handleSave}
          disabled={!fileName}
        >
          {t('Save')}
        </LoadingButton>
      </Stack>
    </>
  );
}
