import React from 'react';
import { useTranslation } from 'react-i18next';

import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import DeleteIcon from '@mui/icons-material/DeleteOutlined';

import Button from '@mui/material/Button';
import { useDashboardContext } from '../../_lib/context/dashboard-context';
import { removeCommas, withCommas } from '../../_utils/general-utils';

import { PlanningTargetsInputTargetUnit } from './target-unit';
import { DateInput } from '../../components/date-input';

export function PlanningTargetsInputList({
  disabled,
  area,
  setArea,
  newTargets,
  setNewTargets,
  handleDelete,
}: any) {
  const { t } = useTranslation();

  const { dashboard } = useDashboardContext();

  const [amountStates, setAmountStates] = React.useState<any>({
    startAmount: 'mouseout',
    targetAmount: 'mouseout',
    targetFinancedAmount: 'mouseout',
  });

  // -------------------------------------------------------------------------------------------------------------------
  // -------------------------------------------------------------------------------------------------------------------
  // handlers
  // -------------------------------------------------------------------------------------------------------------------

  // blur and focus
  const handleFieldFocus = (fieldName: string) => {
    setAmountStates((old: any) => ({
      ...old,
      [fieldName]: 'editing',
    }));
  };

  const handleFieldBlur = (fieldName: string) => {
    setAmountStates((old: any) => ({
      ...old,
      [fieldName]: 'mouseout',
    }));
  };

  // handle change
  const handleChange = React.useCallback(
    (value: any, target: any, field: string) => {
      if (target.fauxId) {
        setNewTargets((prev: any) =>
          prev.map((tgt: any) => {
            if (tgt.fauxId === target.fauxId) {
              return {
                ...tgt,
                [field]: value,
              };
            }
            return tgt;
          })
        );
      } else {
        setArea((prev: any) => ({
          ...prev,
          targetsDb: prev.targetsDb.map((tgt: any) => {
            if (tgt.id === target.id) {
              return {
                ...tgt,
                [field]: value,
              };
            }
            return tgt;
          }),
        }));
      }
    },
    [setArea, setNewTargets]
  );

  // -------------------------------------------------------------------------------------------------------------------

  // columns
  const columns = React.useMemo(() => {
    const cols: any = {};

    const getStartAmountState = (target: any) =>
      ({
        editing: target.startAmount,
        mouseout: withCommas(
          parseFloat(target.startAmount),
          dashboard.language,
          2
        ),
      } as any);

    const getTargetAmountState = (target: any) =>
      ({
        editing: target.targetAmount,
        mouseout: withCommas(
          parseFloat(target.targetAmount),
          dashboard.language,
          2
        ),
      } as any);

    const getFinancedAmountState = (target: any) =>
      ({
        editing: target.targetFinancedAmount,
        mouseout: withCommas(
          parseFloat(target.targetFinancedAmount),
          dashboard.language,
          2
        ),
      } as any);

    cols.targetUnitName = (target: any) => (
      <PlanningTargetsInputTargetUnit
        label={`${t('Indicator')}`}
        target={target}
        disabled={disabled}
        area={area}
        setArea={setArea}
        setNewTargets={setNewTargets}
      />
    );

    if (dashboard.enableTargetBaselineValue) {
      cols.startAmount = (target: any) => (
        <TextField
          label={`${t('Baseline')}`}
          onFocus={() => handleFieldFocus('startAmount')}
          onBlur={() => handleFieldBlur('startAmount')}
          value={getStartAmountState(target)[amountStates.startAmount] || 0}
          onChange={(e: any) =>
            handleChange(removeCommas(e.target.value), target, 'startAmount')
          }
          disabled={disabled}
          inputProps={{
            inputMode: 'numeric',
            pattern: '[0-9]*',
            style: { textAlign: 'right' },
          }}
          size="small"
          fullWidth
        />
      );
    }

    cols.targetAmount = (target: any) => (
      <TextField
        label={`${t('Target')}`}
        onFocus={() => handleFieldFocus('targetAmount')}
        onBlur={() => handleFieldBlur('targetAmount')}
        value={getTargetAmountState(target)[amountStates.targetAmount] || 0}
        onChange={(e: any) =>
          handleChange(removeCommas(e.target.value), target, 'targetAmount')
        }
        disabled={disabled}
        inputProps={{
          inputMode: 'numeric',
          pattern: '[0-9]*',
          style: { textAlign: 'right' },
        }}
        size="small"
        fullWidth
      />
    );

    if (dashboard.enableTargetFinancedAmount) {
      cols.targetFinancedAmount = (target: any) => (
        <TextField
          label={`${t('Financed')}`}
          onFocus={() => handleFieldFocus('targetFinancedAmount')}
          onBlur={() => handleFieldBlur('targetFinancedAmount')}
          value={
            getFinancedAmountState(target)[amountStates.targetFinancedAmount] ||
            0
          }
          onChange={(e: any) =>
            handleChange(
              removeCommas(e.target.value),
              target,
              'targetFinancedAmount'
            )
          }
          disabled={disabled}
          inputProps={{
            inputMode: 'numeric',
            pattern: '[0-9]*',
            style: { textAlign: 'right' },
          }}
          size="small"
          fullWidth
        />
      );
    }

    cols.startedOn = (target: any) => (
      <DateInput
        label={`${t('Start Date')}`}
        date={target.startedOn}
        setDate={(date: any) => handleChange(date, target, 'startedOn')}
        max={target.deadline}
        disabled={disabled}
        size="small"
        fullWidth
      />
    );

    cols.deadline = (target: any) => (
      <DateInput
        label={`${t('Deadline')}`}
        date={target.deadline}
        setDate={(date: any) => handleChange(date, target, 'deadline')}
        min={target.startedOn}
        disabled={disabled}
        size="small"
        fullWidth
      />
    );

    if (dashboard.enableTargetPartner) {
      cols.partner = (target: any) => (
        <TextField
          label={`${t('Partner')}`}
          value={target.partner}
          onChange={(e: any) => handleChange(e.target.value, target, 'partner')}
          disabled={disabled}
          size="small"
          fullWidth
        />
      );
    }

    cols.actions = (target: any) => (
      <Box>
        {/* smUp */}
        <IconButton
          onClick={() => handleDelete(target)}
          disabled={disabled}
          size="small"
          sx={{
            display: { xs: 'none', sm: 'block' },
          }}
        >
          <DeleteIcon />
        </IconButton>

        {/* xs */}
        <Button
          onClick={() => handleDelete(target)}
          disabled={disabled}
          size="small"
          variant="outlined"
          color="error"
          startIcon={<DeleteIcon />}
          sx={{
            display: { xs: 'flex', sm: 'none' },
          }}
          fullWidth
        >
          {t('Delete')}
        </Button>
      </Box>
    );

    return cols;
  }, [
    area,
    dashboard.enableTargetBaselineValue,
    dashboard.enableTargetFinancedAmount,
    dashboard.enableTargetPartner,
    disabled,
    handleChange,
    handleDelete,
    setArea,
    setNewTargets,
    amountStates,
    dashboard.language,
    t,
  ]);

  const colsSizes: any = {
    targetUnitName: {
      xs: 12,
      sm: 3,
    },
    startAmount: {
      xs: 12,
      sm: 1,
    },
    targetAmount: {
      xs: 12,
      sm: 1,
    },
    targetFinancedAmount: {
      xs: 12,
      sm: 1.2,
    },
    startedOn: {
      xs: 12,
      sm: 2,
    },
    deadline: {
      xs: 12,
      sm: 2,
    },
    partner: {
      xs: 12,
      sm: 2,
    },
    actions: {
      xs: 12,
      sm: 1,
    },
  };

  // -------------------------------------------------------------------------------------------------------------------

  if ([...area.targetsDb, ...newTargets].length === 0) {
    return (
      <Typography
        color="textSecondary"
        sx={{ fontStyle: 'italic', textAlign: 'center' }}
      >
        ({t('No targets added')})
      </Typography>
    );
  }

  return (
    <Stack spacing={2}>
      {[...area.targetsDb, ...newTargets].map((target: any) => (
        <Paper
          key={target.id || target.fauxId}
          variant="outlined"
          sx={{ p: 2 }}
        >
          <Grid container spacing={1}>
            {Object.keys(columns).map((key: string) => (
              <Grid
                item
                xs={colsSizes[key].xs}
                sm={colsSizes[key].sm}
                key={key}
              >
                {columns[key](target)}
              </Grid>
            ))}
          </Grid>
        </Paper>
      ))}
    </Stack>
  );
}
