const filterMyContent = ({ c, activeUser, dashboard }: any) => {
  return (
    (!dashboard?.enableActivityMultipleAssignees
      ? c.assignee?.id === activeUser.id
      : c.assignees.find((a: any) => a.id === activeUser.id)) ||
    c.updates?.find(
      (u: any) =>
        u.mentions && u.mentions.map((m: any) => m.id).includes(activeUser.id)
    )
  );
};

export const getPercDone = (content: any, dashboard: any) => {
  const contentPerc = content?.filter((c: any) => c.type !== 'issue');
  if (!contentPerc || !contentPerc.length) return 0;
  if (!dashboard?.enableActivityGroupWeights) {
    return Number(
      (
        contentPerc.reduce((acc: any, c: any) => acc + c.percDone, 0) /
        contentPerc.length
      ).toFixed(2)
    );
  }

  // if activity group weights are enabled
  const groupsData: any = [];
  dashboard.contentGroups?.forEach((g: any) => {
    const groupContent = contentPerc.filter((c: any) => c.group?.id === g.id);
    groupsData.push({
      id: g.id,
      percDone: groupContent.length
        ? groupContent.reduce((acc: any, c: any) => acc + c.percDone, 0) /
          groupContent.length
        : 0,
      weight: groupContent.length ? g.weight : 0,
    });
  });

  // for unassigned activities
  const unassignedContent = contentPerc.filter((c: any) => !c.group);
  groupsData.push({
    id: 'unassigned',
    percDone: unassignedContent.length
      ? unassignedContent.reduce((acc: any, c: any) => acc + c.percDone, 0) /
        unassignedContent.length
      : 0,
    weight: unassignedContent.length
      ? dashboard.activityGroupUnassignedWeight
      : 0,
  });

  const values = [] as any;
  const weights = [] as any;
  groupsData.forEach((group: any) => {
    values.push(group.percDone);
    weights.push(group.weight);
  });

  const valuesWeighted = values.map((v: any, i: number) => v * weights[i]);
  const valuesSum = valuesWeighted.reduce((acc: any, v: any) => acc + v, 0);
  const weightsSum = weights.reduce((acc: any, v: any) => acc + v, 0);

  return Number((valuesSum / weightsSum).toFixed(2));
};

export function getContentCount({ content, activeUser, dashboard }: any) {
  const filteredContent = {
    all: content.filter((c: any) => c.status !== 'archived'),
    milestones: content.filter(
      (c: any) => c.type === 'milestone' && c.status !== 'archived'
    ),
    activities: content.filter(
      (c: any) => c.type === 'activity' && c.status !== 'archived'
    ),
    issue:
      (activeUser.isRegular &&
        content.filter(
          (a: any) => a.author && a.author.id === activeUser.id
        )) ||
      content.filter((c: any) => c.status === 'issue'),
    my: content
      .filter((c: any) => filterMyContent({ c, activeUser, dashboard }))
      .filter((c: any) => c.status !== 'archived'),
    archived: content.filter((a: any) => a.status === 'archived'),
  };

  return {
    all: {
      count: filteredContent.all.length,
      percDone: getPercDone(
        dashboard.enableMilestonesCalculation
          ? filteredContent.all
          : filteredContent.all.filter((c: any) => c.type !== 'milestone'),
        dashboard
      ),
    },
    milestones: {
      count: filteredContent.milestones.length,
      percDone: dashboard.enableMilestonesCalculation
        ? getPercDone(filteredContent.milestones, dashboard)
        : 0,
    },
    activities: {
      count: filteredContent.activities.length,
      percDone: getPercDone(
        dashboard.enableMilestonesCalculation
          ? filteredContent.activities
          : filteredContent.activities.filter(
              (c: any) => c.type !== 'milestone'
            ),
        dashboard
      ),
    },
    issue: {
      count: filteredContent.issue.length,
    },
    my: {
      count: filteredContent.my.length,
      percDone: getPercDone(
        dashboard.enableMilestonesCalculation
          ? filteredContent.my
          : filteredContent.my.filter((c: any) => c.type !== 'milestone'),
        dashboard
      ),
    },
    archived: {
      count: filteredContent.archived.length,
      percDone: getPercDone(
        dashboard.enableMilestonesCalculation
          ? filteredContent.archived
          : filteredContent.archived.filter((c: any) => c.type !== 'milestone'),
        dashboard
      ),
    },
  };
}
getContentCount.defaultProps = {
  percDone: false,
};

export const getFilteredContent: any = ({
  content,
  filterObject,
  activeUser,
  users,
  dashboard,
}: any) => {
  // Extract the filter options from object
  const {
    status: statusFilter,
    user: userFilter,
    intervention: interventionFilter,
    priorityArea: priorityAreaFilter,
    flagged: flaggedFilter,
  } = filterObject;

  let updatedContent = structuredClone(content);

  // Filter users to those available
  const filteredUsers = users.filter((u: any) =>
    content
      .flatMap((c: any) => [
        c.assignee && c.assignee.id,
        c.author && c.author.id,
      ])
      .includes(u.id)
  );

  // Filter according to PriorityArea
  if (priorityAreaFilter)
    updatedContent = updatedContent?.filter(
      (a: any) => a.intervention.priorityArea.id === priorityAreaFilter.id
    );

  // Filter according to Intervention
  if (interventionFilter)
    updatedContent = updatedContent.filter(
      (a: any) => a.intervention.id === interventionFilter.id
    );

  // Filter by flag/star
  if (flaggedFilter)
    updatedContent = updatedContent?.filter((c: any) => c.flagged);

  // Filter by user selected
  if (userFilter)
    updatedContent = updatedContent?.filter(
      (c: any) => c.assignee && c.assignee.id === userFilter.id
    );

  // --------------------------------------------------------------------
  // STATUS FILTER (TABS)
  // --------------------

  // Filter by milestone status
  if (statusFilter === 'milestones') {
    updatedContent = updatedContent?.filter(
      (c: any) => c.type === 'milestone' && c.status !== 'archived'
    );
  }

  // filter by activities status
  if (statusFilter === 'activities') {
    updatedContent = updatedContent?.filter(
      (c: any) => c.type === 'activity' && c.status !== 'archived'
    );
  }

  // Filter by issue status
  if (statusFilter === 'issue') {
    updatedContent = updatedContent?.sort(
      (a: any, b: any) =>
        new Date(b.createdAt).valueOf() - new Date(a.createdAt).valueOf()
    );
    if (activeUser.isRegular)
      updatedContent = updatedContent?.filter(
        (a: any) => a.author && a.author.id === activeUser.id
      );
    else
      updatedContent = updatedContent?.filter(
        (c: any) => c.status === statusFilter
      );
  }

  // Filter by archived status else everything else do not show archived
  if (statusFilter === 'archived') {
    updatedContent = updatedContent
      ?.filter((c: any) => c.status === statusFilter)
      ?.sort(
        (a: any, b: any) =>
          new Date(b.completionDate).valueOf() -
          new Date(a.completionDate).valueOf()
      );
  } else
    updatedContent = updatedContent.filter((c: any) => c.status !== 'archived');

  // Filter by my status
  if (statusFilter === 'my') {
    updatedContent = updatedContent?.filter((c: any) =>
      filterMyContent({ c, activeUser, dashboard })
    );
  }

  // Filter by all or my status
  if (['all', 'my'].includes(statusFilter)) {
    const date = (x: any) => x.completionDate || x.deadline || x.createdAt;
    updatedContent = updatedContent?.sort(
      (a: any, b: any) =>
        new Date(date(a)).valueOf() - new Date(date(b)).valueOf()
    );
  }

  return { content: updatedContent, users: filteredUsers };
};
