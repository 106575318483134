import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

import { ErrorBoundary } from 'react-error-boundary';
import { usePlanningContext } from '../../_lib/context/planning-context';
import { ReactErrorComponent } from '../../_lib/react-error';

export interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  title?: string;
  subheader?: string;
  breadcrumbs?: any;
  actions?: React.ReactNode;
  value?: number;
}

export function PlanningLayoutTabPanel(props: TabPanelProps) {
  const { children, index, title, subheader, breadcrumbs, actions, ...other } =
    props;
  const theme = useTheme();

  const { tab } = usePlanningContext();

  return (
    <ErrorBoundary FallbackComponent={ReactErrorComponent}>
      <div
        role="tabpanel"
        hidden={tab !== index}
        id={`planning-tabpanel-${index}`}
        aria-labelledby={`planning-tab-${index}`}
        {...other}
      >
        {tab === index && (
          <Box>
            {(!!title || !!subheader || !!actions) && (
              <Box
                sx={{
                  py: 3,
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  borderBottom: 1,
                  borderColor: 'divider',
                  [theme.breakpoints.down('sm')]: {
                    display: 'block',
                  },
                }}
              >
                <Box>
                  {!!title && <Typography variant="h5">{title}</Typography>}
                  {!!subheader && (
                    <Typography variant="body1" color="text.secondary">
                      {subheader}
                    </Typography>
                  )}

                  {/* breadcrumbs */}
                  {!!breadcrumbs && (
                    <Breadcrumbs
                      aria-label="breadcrumb"
                      sx={{
                        mt: 0.7,
                        backgroundImage:
                          'linear-gradient(to right, rgba(255,255,255,1), rgba(255,255,255,0))',
                        borderRadius: 1,
                        py: 0.5,
                        px: 1.25,
                      }}
                    >
                      {breadcrumbs.map((crumb: any, i: number) =>
                        (i < breadcrumbs.length - 1 &&
                          !!crumb.to.match(/\d$/)) ||
                        i === 0 ? (
                          <Link
                            key={crumb.to}
                            component={RouterLink}
                            to={crumb.to}
                            underline="hover"
                            color="inherit"
                          >
                            {crumb.label}
                          </Link>
                        ) : (
                          <Typography color="text.primary" key={crumb.to}>
                            {crumb.label}
                          </Typography>
                        )
                      )}
                    </Breadcrumbs>
                  )}
                </Box>
                {!!actions && (
                  <Box
                    sx={{
                      display: 'flex',
                      gap: 1.5,
                      flexWrap: 'wrap',
                      ml: 1,
                      [theme.breakpoints.down('sm')]: {
                        ml: 0,
                        mt: 2,
                      },
                    }}
                  >
                    {actions}
                  </Box>
                )}
              </Box>
            )}
            {children}
          </Box>
        )}
      </div>
    </ErrorBoundary>
  );
}

PlanningLayoutTabPanel.defaultProps = {
  children: undefined,
  title: undefined,
  subheader: undefined,
  breadcrumbs: undefined,
  actions: undefined,
  value: undefined,
};

export default PlanningLayoutTabPanel;
