import { useTranslation } from 'react-i18next';

import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';

export function PlanningPriorityAreaFormActionsCancel({
  priorityAreaInitial,
  setPriorityArea,
  setInterventions,
  setNewTargets,
  setDisabled,
  disabled,
}: any) {
  const { t } = useTranslation();

  const handleCancel = () => {
    setPriorityArea(structuredClone(priorityAreaInitial));
    if (setInterventions) {
      setInterventions(
        priorityAreaInitial.interventionsDb?.length > 0
          ? structuredClone(priorityAreaInitial.interventionsDb)
          : []
      );
    }
    setNewTargets([] as any);
    setDisabled(true);
  };

  return (
    <Button
      variant="outlined"
      disabled={disabled}
      onClick={handleCancel}
      startIcon={<CloseIcon />}
    >
      {t('Cancel')}
    </Button>
  );
}
