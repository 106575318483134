import React from 'react';
import { isEqual } from 'lodash';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import LinearProgress from '@mui/material/LinearProgress';
import ClearIcon from '@mui/icons-material/Clear';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

import { MentionsInput } from '../../../components/mentions-input';
import { FileUpload } from '../../../components/file-upload';

import { useDashboardContext } from '../../../_lib/context/dashboard-context';
import { useContentDialogContext } from '../../../_lib/context/content-dialog-context';

import {
  CONTENT_UPDATE_UPDATE,
  CONTENT_UPDATE_CREATE,
} from '../../../_lib/graphql/mutations';

import { logError } from '../../../_lib/error';
import { CONTENT_QUERY } from '../../../_lib/graphql/queries';

export function ContentDialogContentUpdateEdit({
  updateId,
  edit,
  setEdit,
}: any) {
  const { t } = useTranslation();

  const { users, setSnackbarOpen, setSnackbarIsError } = useDashboardContext();

  const { content, contentId } = useContentDialogContext();

  const [update, setUpdate] = React.useState(
    content.updates.find((u: any) => u.id === updateId)
  );

  const [file, setFile] = React.useState<any>(update ? update.attachment : '');
  const [mentions, setMentions] = React.useState<any>([]);
  const [text, setText] = React.useState<any>(update ? update.text : '');
  const [loading, setLoading] = React.useState<boolean>(false);

  // mutation
  const [addUpdateMutation] = useMutation(
    edit ? CONTENT_UPDATE_UPDATE : CONTENT_UPDATE_CREATE,
    {
      update: (cache, { data }) => {
        cache.writeQuery({
          query: CONTENT_QUERY,
          data: {
            content: edit
              ? data.contentUpdateUpdate.content
              : data.contentUpdateCreate.content,
          },
          variables: { id: contentId },
        });
      },
    }
  );

  // ----------------------------------------------------------------------------------------------------
  // ----------------------------------------------------------------------------------------------------
  // handlers
  // ----------------------------------------------------------------------------------------------------

  // submit update

  const submitUpdate = () => {
    setLoading(true);
    addUpdateMutation({
      variables: {
        contentId: !edit ? content.id : null,
        updateId: edit ? update.id : null,
        mentions,
        text,
        attachment: file,
        removeAttachment: !file && !!update?.attachment,
        status: !edit
          ? 'ACCEPTED'
          : ((update.suggestCompleted || update.extensionRequest) &&
              'PENDING') ||
            'ACCEPTED',
      },
    })
      .then(() => {
        // reset and close edit
        if (edit && setEdit) {
          setEdit(false);
        } else {
          setText('');
          setFile(null);
        }
      })
      .catch((err) => {
        logError(err);
        setSnackbarIsError(true);
      })
      .finally(() => {
        setLoading(false);
        setSnackbarOpen(true);
      });
  };

  // ----------------------------------------------------------------------------------------------------
  // ----------------------------------------------------------------------------------------------------
  // effects
  // ----------------------------------------------------------------------------------------------------

  // update updates
  React.useEffect(() => {
    const contentUpdate = content.updates.find((u: any) => u.id === updateId);
    if (!isEqual(contentUpdate, update)) {
      setUpdate(contentUpdate);
      setFile(contentUpdate.attachment);
      setMentions(contentUpdate.mentions);
      setText(contentUpdate.text);
    }
  }, [content, update, updateId]);

  // ----------------------------------------------------------------------------------------------------

  return ['EDIT', 'COMMENT'].includes(content.access) ? (
    <Box id={`${edit ? 'change' : 'create'}-update`}>
      {/* loading progress bar */}
      {loading && <LinearProgress />}

      {/* update input */}
      <MentionsInput
        users={users}
        setMentions={setMentions}
        setText={setText}
        text={text}
        disabled={loading}
      />

      {/* attach and save buttons */}
      <Box
        sx={{
          display: 'flex',
          textAlign: 'right',
          justifyContent: 'space-between',
          alignItems: 'center',
          mt: 1,
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box>
            <Button
              id={`submit-${edit ? 'change' : 'create'}-update`}
              variant="contained"
              color="primary"
              onClick={submitUpdate}
              disabled={!text || loading}
              disableElevation
            >
              {t('Save')}
            </Button>
          </Box>

          {!!edit && (
            <IconButton
              sx={{ ml: 2 }}
              onClick={() => setEdit(false)}
              size="large"
              disabled={loading}
            >
              <ClearIcon />
            </IconButton>
          )}
        </Box>

        {loading ? (
          <CircularProgress size={30} />
        ) : (
          <Box
            sx={{ display: 'flex', alignItems: 'center', minWidth: 0, ml: 3 }}
          >
            <Box sx={{ minWidth: 0 }}>
              <Typography
                variant="body2"
                sx={{ overflow: 'hidden', textOverflow: 'ellipsis', mr: 0.5 }}
              >
                {file?.name || ' '}
              </Typography>
            </Box>
            <Box sx={{ flexGrow: 1 }}>
              <FileUpload file={file} setFile={setFile} />
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  ) : null;
}
