import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';

import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';

import DriveFileMoveRoundedIcon from '@mui/icons-material/DriveFileMoveRounded';

import {
  INTERVENTION_MOVE,
  PRIORITY_AREA_MOVE,
} from '../../../_lib/graphql/mutations';
import {
  CONTEXT_USER_QUERY,
  INTERVENTIONS_PLANNING_QUERY,
  PRIORITY_AREAS_PLANNING_QUERY,
  PRIORITY_AREA_PLANNING_QUERY,
} from '../../../_lib/graphql/queries';

import { PlanningAreaActionsMoveDialog } from './dialog';
import { useDashboardContext } from '../../../_lib/context/dashboard-context';

export function PlanningAreaActionsMove({
  child,
  parent,
  parentAreas,
  setParentAreas,
  button,
  color,
  listVariables,
}: any) {
  const { t } = useTranslation();

  const { dashboard } = useDashboardContext();

  const [openDialog, setOpenDialog] = React.useState(false);

  const [movePriorityAreaMutation] = useMutation(PRIORITY_AREA_MOVE, {
    update: (cache, { data: { priorityAreaMove } }) => {
      // update old parent
      cache.writeQuery({
        query: PRIORITY_AREA_PLANNING_QUERY,
        data: { priorityArea: priorityAreaMove.oldParentPriorityArea },
        variables: {
          priorityAreaId: priorityAreaMove.oldParentPriorityArea?.id,
        },
      });

      // update old siblings list
      cache.writeQuery({
        query: PRIORITY_AREAS_PLANNING_QUERY,
        data: { priorityAreas: priorityAreaMove.oldSiblingPriorityAreas },
        variables: {
          ...listVariables,
          parentId: dashboard.enableAreaLevels
            ? priorityAreaMove.oldParentPriorityArea?.id
            : undefined,
        },
      });

      // update new parent
      cache.writeQuery({
        query: PRIORITY_AREA_PLANNING_QUERY,
        data: { priorityArea: priorityAreaMove.newParentPriorityArea },
        variables: {
          priorityAreaId: priorityAreaMove.newParentPriorityArea?.id,
        },
      });

      // update new siblings list
      cache.writeQuery({
        query: PRIORITY_AREAS_PLANNING_QUERY,
        data: { priorityAreas: priorityAreaMove.newSiblingPriorityAreas },
        variables: {
          ...listVariables,
          parentId: dashboard.enableAreaLevels
            ? priorityAreaMove.newParentPriorityArea?.id
            : undefined,
        },
      });

      // update user context
      cache.writeQuery({
        query: CONTEXT_USER_QUERY,
        data: {
          user: priorityAreaMove.user,
        },
      });
    },
  });

  const [moveInterventionMutation] = useMutation(INTERVENTION_MOVE, {
    update: (cache, { data: { interventionMove } }) => {
      // old priority area
      cache.writeQuery({
        query: PRIORITY_AREA_PLANNING_QUERY,
        variables: {
          priorityAreaId: interventionMove.oldPriorityArea.id,
        },
        data: {
          priorityArea: interventionMove.oldPriorityArea,
        },
      });

      // old sibling interventions
      cache.writeQuery({
        query: INTERVENTIONS_PLANNING_QUERY,
        variables: {
          ...listVariables,
          priorityAreaId: interventionMove.oldPriorityArea.id,
        },
        data: {
          interventions: interventionMove.oldSiblingInterventions,
        },
      });

      // new priority area
      cache.writeQuery({
        query: PRIORITY_AREA_PLANNING_QUERY,
        variables: {
          priorityAreaId: interventionMove.newPriorityArea.id,
        },
        data: {
          priorityArea: interventionMove.newPriorityArea,
        },
      });

      // old sibling interventions
      cache.writeQuery({
        query: INTERVENTIONS_PLANNING_QUERY,
        variables: {
          ...listVariables,
          priorityAreaId: interventionMove.newPriorityArea.id,
        },
        data: {
          interventions: interventionMove.newSiblingInterventions,
        },
      });

      // update user context
      cache.writeQuery({
        query: CONTEXT_USER_QUERY,
        data: {
          user: interventionMove.user,
        },
      });
    },
  });

  const handleMovePriorityArea = ({
    variables: { id, newParentId, preserveRef },
  }: any) =>
    movePriorityAreaMutation({
      variables: {
        priorityAreaId: id,
        newParentId,
        preserveRef,
      },
    });

  const handleMoveIntervention = ({
    variables: { id, newParentId, preserveRef },
  }: any) =>
    moveInterventionMutation({
      variables: {
        interventionId: id,
        newParentId,
        preserveRef,
      },
    });

  if (!child) return null;

  return (
    <>
      <PlanningAreaActionsMoveDialog
        open={openDialog}
        setOpen={setOpenDialog}
        child={child}
        parent={parent}
        parentAreas={parentAreas}
        setParentAreas={setParentAreas}
        moveMutation={
          child.__typename === 'InterventionType'
            ? handleMoveIntervention
            : handleMovePriorityArea
        }
      />

      {!button ? (
        <Tooltip title={t('Move')} arrow>
          <IconButton
            color={color}
            onClick={() => setOpenDialog(true)}
            aria-label={`${t('move')}`}
          >
            <DriveFileMoveRoundedIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Button
          onClick={() => setOpenDialog(true)}
          size="small"
          variant="outlined"
          startIcon={<DriveFileMoveRoundedIcon />}
          color={color}
        >
          {t('Move')}
        </Button>
      )}
    </>
  );
}
