import { ErrorBoundary } from 'react-error-boundary';

// eslint-disable-next-line import/no-named-default
import { default as MuiCard } from '@mui/material/Card';

import { ReactErrorComponent } from '../../../../_lib/react-error';

import CardHeader from './card-header';
import CardContent from './card-content';

export function CardContainer({
  simple,
  headerItems,
  contentItems,
  isChildInfo,
}: any) {
  return (
    <>
      {/* component */}
      <ErrorBoundary FallbackComponent={ReactErrorComponent}>
        {/* card */}
        <MuiCard
          sx={{
            width: '100%',
            pl: 4,
            height: '100%',
          }}
          variant={simple ? 'outlined' : 'elevation'}
        >
          {/* header */}
          <CardHeader headerItems={headerItems} />
          {/* body */}
          <CardContent contentItems={contentItems} isChildInfo={isChildInfo} />
        </MuiCard>
      </ErrorBoundary>
    </>
  );
}
