import React from 'react';
import { useTranslation } from 'react-i18next';

import Alert from '@mui/material/Alert';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

import { useDashboardContext } from '../../../../_lib/context/dashboard-context';

import { MapForm } from './map-form';
import { FeaturesTable } from './features-table';

interface TabPanelProps {
  children: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`map-tabpanel-${index}`}
      aria-labelledby={`map-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ py: 1 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `map-tab-${index}`,
    'aria-controls': `map-tabpanel-${index}`,
  };
}

export function MapTab() {
  const { t } = useTranslation();
  const { dashboard } = useDashboardContext();

  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  if (!dashboard.customMapviewMap)
    return (
      <Box sx={{ mt: 1, mb: 1.5 }}>
        <Alert severity="info" sx={{ mb: 3 }}>
          {t('Upload a map to use this feature.')}
        </Alert>
        <MapForm />
      </Box>
    );

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="map-details-tab"
        >
          <Tab label={t('Map Features')} {...a11yProps(0)} />
          <Tab label={t('Upload Map')} {...a11yProps(1)} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <FeaturesTable />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <MapForm />
      </TabPanel>
    </Box>
  );
}
