import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import TextField from '@mui/material/TextField';
import LoadingButton from '@mui/lab/LoadingButton';

import { DialogTitle } from '../../../../DialogTitle/dialog-title';

import { DashboardContext } from '../../../../_lib/context/dashboard-context';
import { DASHBOARD_UPDATE } from '../../../../_lib/graphql/mutations';
import { logError } from '../../../../_lib/error';

export function AdvancedContentStatusLongOverdueDialog({ open, setOpen }: any) {
  const { t } = useTranslation();

  const { dashboard, setSnackbarIsError, setSnackbarOpen } =
    React.useContext(DashboardContext);

  const [days, setDays] = React.useState<any>(
    dashboard.activityStatusLongOverdueDays
  );

  const [loading, setLoading] = React.useState(false);

  const [dashboardUpdateMutation] = useMutation(DASHBOARD_UPDATE);

  // -----------------------------------------------------------------------------------------
  // -----------------------------------------------------------------------------------------
  // handlers
  // -----------------------------------------------------------------------------------------

  const handleSave = () => {
    setLoading(true);
    dashboardUpdateMutation({
      variables: { activityStatusLongOverdueDays: Number.parseInt(days, 10) },
    })
      .then(() => {
        setOpen(false);
      })
      .catch((err) => {
        logError(err);
        setSnackbarIsError(true);
      })
      .finally(() => {
        setLoading(false);
        setSnackbarOpen(true);
      });
  };

  // -----------------------------------------------------------------------------------------
  // -----------------------------------------------------------------------------------------
  // effects
  // -----------------------------------------------------------------------------------------

  React.useEffect(() => {
    setDays(dashboard.activityStatusLongOverdueDays);
  }, [dashboard]);

  // -----------------------------------------------------------------------------------------

  return (
    <Dialog open={open} maxWidth="xs" fullWidth>
      <DialogTitle onClose={() => setOpen(false)}>
        {t('Set "Long Overdue" Days')}
      </DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ mb: 1.5 }}>
          {t(
            'How many days after the deadline should an activity be considered "long overdue"?'
          )}
        </DialogContentText>
        <TextField
          type="number"
          label={t('Days')}
          value={days}
          onChange={(e) => setDays(e.target.value)}
          fullWidth
          disabled={loading}
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setDays(dashboard.activityStatusLongOverdueDays);
            setOpen(false);
          }}
          disabled={loading}
        >
          {t('Cancel')}
        </Button>
        <LoadingButton
          onClick={handleSave}
          variant="contained"
          disableElevation
          loading={loading}
        >
          {t('Save')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
