import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { gql, useLazyQuery } from '@apollo/client';

import Box from '@mui/material/Box';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';

import { CreateContent } from '../actions/create-content';
import { DashboardContext } from '../_lib/context/dashboard-context';
import { logError } from '../_lib/error';
import { SubscribeToMore } from '../components/subscribe-to-more';

const INTV_ACCESS_QUERY = gql`
  query IntvAccess($id: ID!) {
    intervention(interventionId: $id) {
      access
    }
  }
`;
const INTV_ACCESS_SUBSCRIPTION = gql`
  subscription IntvAccess($id: ID!) {
    intervention(interventionId: $id) {
      access
    }
  }
`;

const PA_ACCESS = gql`
  query PaAccess($id: ID!) {
    priorityArea(priorityAreaId: $id) {
      access
    }
  }
`;
const PA_ACCESS_SUBSCRIPTION = gql`
  subscription PaAccess($id: ID!) {
    priorityArea(priorityAreaId: $id) {
      access
    }
  }
`;

export function TrackerSpeedDial() {
  const { t } = useTranslation();

  const { priorityAreaId, interventionId } = useParams<any>();

  const { user, dashboard } = React.useContext(DashboardContext);

  const [open, setOpen] = React.useState(false);
  const [hidden, setHidden] = React.useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const buttonsObj = {
    milestone: {
      icon: <CreateContent type="milestone" setHidden={setHidden} />,
      name: t('Add Milestone'),
    },
    activity: {
      icon: <CreateContent type="activity" setHidden={setHidden} />,
      name: t('Add Activity'),
    },
    issue: {
      icon: <CreateContent type="issue" setHidden={setHidden} />,
      name: t('Raise Issue'),
    },
  };

  const buttonsView = [buttonsObj.issue];

  const buttonsEdit = [
    ...(dashboard.mode === 'FULL' ? [buttonsObj.milestone] : ([] as any)),
    buttonsObj.activity,
    buttonsObj.issue,
  ];

  const [buttons, setButtons] = React.useState(
    user.isAdmin || dashboard.mode === 'TRACKER' ? buttonsEdit : buttonsView
  );

  // -------------------------------------------------------------------------------------------------------------------------

  const [
    fetchAreaAccess,
    {
      data: areaData,
      loading: areaLoading,
      error: areaError,
      subscribeToMore: areaSubscribeToMore,
    },
  ] = useLazyQuery(interventionId ? INTV_ACCESS_QUERY : PA_ACCESS, {
    variables: {
      id: interventionId || priorityAreaId,
    },
  });

  if (
    !(user.isAdmin || dashboard.mode === 'TRACKER') &&
    (interventionId || priorityAreaId) &&
    !areaData &&
    !areaLoading &&
    !areaError
  ) {
    fetchAreaAccess();
  }

  // -------------------------------------------------------------------------------------------------------------------------
  // -------------------------------------------------------------------------------------------------------------------------
  // effects
  // -------------------------------------------------------------------------------------------------------------------------

  // error
  React.useEffect(() => {
    if (areaError) logError(areaError);
  }, [areaError]);

  // types
  React.useEffect(() => {
    if (areaData) {
      const opName = interventionId ? 'intervention' : 'priorityArea';
      const { access } = areaData[opName];
      if (access === 'EDIT') {
        setButtons(buttonsEdit);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [areaData]);

  // -------------------------------------------------------------------------------------------------------------------------

  return (
    <>
      <Box
        sx={{
          position: 'fixed',
          bottom: 16,
          right: 16,
          zIndex: 20,
          displayPrint: 'none',
        }}
      >
        <SpeedDial
          ariaLabel={t('Add Milestone / Activity / Raise Issue')}
          sx={{
            position: 'absolute',
            bottom: 16,
            right: 16,
          }}
          hidden={hidden}
          icon={<SpeedDialIcon />}
          onClose={handleClose}
          onOpen={handleOpen}
          open={open}
        >
          {buttons.map((button) => (
            <SpeedDialAction
              key={button.name}
              icon={button.icon}
              tooltipTitle={button.name}
              tooltipOpen
              onClick={handleClose}
              hidden={hidden}
              sx={{
                zIndex: 20,
                '& .MuiSpeedDialAction-staticTooltipLabel': {
                  width: '200px',
                },
              }}
            />
          ))}
        </SpeedDial>
      </Box>

      {/* subscribe to more */}
      {!!areaData && (
        <SubscribeToMore
          subscribeToMore={() =>
            areaSubscribeToMore({
              document: interventionId
                ? INTV_ACCESS_SUBSCRIPTION
                : PA_ACCESS_SUBSCRIPTION,
              variables: {
                id: interventionId || priorityAreaId,
              },
              updateQuery: (prev: any, { subscriptionData }: any) => {
                if (!subscriptionData.data) return prev;
                return subscriptionData.data;
              },
            })
          }
        />
      )}
    </>
  );
}
