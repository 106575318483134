import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { useTheme } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';

import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import { useDashboardContext } from '../../_lib/context/dashboard-context';
import { usePlanningContext } from '../../_lib/context/planning-context';

import { LaunchDashboard } from '../../actions/launch-dashboard';

export function PlanningPlanningStepsStepper({
  priorityAreas,
  activeStep,
  setActiveStep,
}: any) {
  const { t } = useTranslation();
  const history = useHistory();

  const theme = useTheme();
  const matchesDownSm = useMediaQuery(theme.breakpoints.down('sm'));

  const { dashboard, users } = useDashboardContext();
  const { setInPlanningEdit } = usePlanningContext();

  const steps = React.useMemo(
    () => [
      {
        title: 'Details',
        description: 'Edit the name, country, and goal',
        buttons: [
          {
            text: 'Edit',
            onClick: () => {
              history.push(`${t('/planning')}`);
              setInPlanningEdit(true);
            },
            component: null,
          },
        ],
      },
      {
        title: dashboard.priorityAreaName,
        description: t('Create the workplan buckets'),
        buttons: [
          {
            text: 'Plan',
            onClick: () =>
              history.push(`${t('/planning')}/${dashboard.priorityAreasSlug}`),
            component: null,
          },
        ],
      },
      {
        title: t('Members'),
        description: t('Add members and assign roles'),
        buttons: [
          {
            text: 'Add',
            onClick: () => history.push(`${t('/settings')}/members`),
            component: null,
          },
        ],
      },
      {
        title: t('Launch'),
        description: t('Start using your dashboard'),
        buttons: [
          {
            text: 'Launch',
            onClick: () => {},
            component: <LaunchDashboard inStep />,
          },
        ],
      },
    ],
    [
      dashboard.priorityAreaName,
      dashboard.priorityAreasSlug,
      history,
      setInPlanningEdit,
      t,
    ]
  );

  // -----------------------------------------------------------------------------------------------------------------
  // -----------------------------------------------------------------------------------------------------------------
  // effects
  // -----------------------------------------------------------------------------------------------------------------

  React.useEffect(() => {
    if (dashboard.mode === 'SUPRANATIONAL' || dashboard.country) {
      setActiveStep(1);
      if (priorityAreas.length > 0 && priorityAreas[0].name !== 'Example') {
        if (
          dashboard.mode === 'SUPRANATIONAL' ||
          (priorityAreas[0].interventionsDb?.length > 0 &&
            priorityAreas[0].interventionsDb[0].name !== 'Example')
        ) {
          setActiveStep(2);
          if (users?.length > 1) {
            setActiveStep(3);
          }
        }
      }
    }
  }, [dashboard, priorityAreas, setActiveStep, users]);

  // -----------------------------------------------------------------------------------------------------------------

  return (
    <Stepper
      activeStep={activeStep}
      alternativeLabel={!matchesDownSm}
      orientation={matchesDownSm ? 'vertical' : 'horizontal'}
      sx={{
        '& .MuiStepIcon-root.Mui-active': {
          color: 'info.dark',
        },
        '& .MuiStepLabel-label.Mui-active': {
          color: 'info.dark',
          '& .MuiTypography-caption': {
            color: 'info.main',
          },
          '& .MuiButton-root': {
            color: 'info.dark',
          },
        },
        '& .MuiStepLabel-label.Mui-completed': {
          color: 'primary.main',
          '& .MuiTypography-caption': {
            color: 'primary.light',
          },
          '& .MuiButton-root': {
            color: 'primary.main',
          },
        },
      }}
    >
      {steps?.map((step, i) => (
        <Step key={step.title}>
          <StepLabel>
            <span>{step.title}</span>
            <br />
            <Typography variant="caption">{step.description}</Typography>
            <br />
            {step.buttons[0].component ? (
              React.cloneElement(step.buttons[0].component, {
                disabled: i > activeStep,
              })
            ) : (
              <Button
                size="small"
                onClick={step.buttons[0].onClick}
                disabled={i > activeStep}
              >
                {t(step.buttons[0].text)}
              </Button>
            )}
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  );
}
