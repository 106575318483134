import React from 'react';
import { useTranslation } from 'react-i18next';

import Button from '@mui/material/Button';

import { AdvancedAreaFieldsDialog } from './dialog';

export function AdvancedAreaFields() {
  const { t } = useTranslation();

  const [open, setOpen] = React.useState(false);

  return (
    <>
      {/* button */}
      <Button variant="outlined" size="small" onClick={() => setOpen(true)}>
        {t('Manage Area Fields')}
      </Button>

      {/* dialog */}
      <AdvancedAreaFieldsDialog open={open} setOpen={setOpen} />
    </>
  );
}
