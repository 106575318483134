import { AppbarAvatarProvider } from './appbar/avatar/context';
import { NavbarBase } from './base';

export function Navbar() {
  return (
    <AppbarAvatarProvider>
      <NavbarBase />
    </AppbarAvatarProvider>
  );
}
