import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';

import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';

import { useDashboardContext } from '../../_lib/context/dashboard-context';

import { PlanningAreaActionsMove } from './move';
import { PlanningAreaActionsDelete } from './delete';

export function PlanningAreaActions({
  area,
  disabled,
  setDisabled,
  openLink,
  setOpenPermissionsDialog,
  forwardButton,
}: any) {
  const { t } = useTranslation();

  const { dashboard } = useDashboardContext();

  return (
    <Stack
      spacing={1.5}
      direction={{ xs: 'column', sm: 'row' }}
      justifyContent="flex-end"
    >
      {disabled && (
        <>
          <Stack spacing={1.5} direction="row" justifyContent="flex-end">
            {/* open */}
            {!dashboard.inPlanning && dashboard.mode === 'FULL' && (
              <Button
                variant="outlined"
                color="success"
                component={RouterLink}
                to={openLink}
                size="small"
              >
                {t('Open')}
              </Button>
            )}

            {/* archive */}
            {dashboard.enableAreaArchive && (
              <PlanningAreaActionsDelete area={area} isButton isArchive />
            )}

            {/* delete */}
            <PlanningAreaActionsDelete area={area} isButton />

            {/* move */}
            {((dashboard.enableAreaLevels &&
              area.__typename !== 'InterventionType') ||
              area.__typename === 'InterventionType') &&
              !area.archivedAt && (
                <PlanningAreaActionsMove
                  child={area}
                  parent={
                    area.__typename === 'InterventionType'
                      ? area.priorityArea
                      : area.parent
                  }
                  button
                  color="info"
                />
              )}

            {/* edit */}
            {!area.archivedAt && (
              <Button
                startIcon={<EditIcon />}
                variant="outlined"
                onClick={() => setDisabled(false)}
                size="small"
              >
                {t('Edit')}
              </Button>
            )}

            {/* Permissions */}
            {dashboard.enableAreaPermissions && !area.archivedAt && (
              <IconButton
                aria-label="add people"
                size="small"
                color="primary"
                onClick={() => setOpenPermissionsDialog(true)}
              >
                <PersonAddAlt1Icon fontSize="small" />
              </IconButton>
            )}
          </Stack>
          {forwardButton}
        </>
      )}
    </Stack>
  );
}
