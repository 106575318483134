import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';

import LoadingButton from '@mui/lab/LoadingButton';
import Tooltip from '@mui/material/Tooltip';
import DownloadIcon from '@mui/icons-material/Download';

import { DashboardContext } from '../../../../_lib/context/dashboard-context';
import { logError } from '../../../../_lib/error';
import { IMPORT_EXPORT_CREATE } from '../../../../_lib/graphql/mutations';
import { IMPORT_EXPORTS_QUERY } from '../../../../_lib/graphql/queries';

export function DashboardFormImportExportActionsExport() {
  const { t } = useTranslation();

  const { dashboard, setSnackbarMessage, setSnackbarIsError, setSnackbarOpen } =
    React.useContext(DashboardContext);

  // query import export

  const [createMutation, { loading }] = useMutation(IMPORT_EXPORT_CREATE, {
    update(cache, { data: { importExportCreate } }) {
      cache.writeQuery({
        query: IMPORT_EXPORTS_QUERY,
        data: { importExports: importExportCreate.importExports },
      });
    },
  });

  // ----------------------------------------------------------------------------------------------------
  // ----------------------------------------------------------------------------------------------------
  // hanlders
  // ----------------------------------------------------------------------------------------------------

  const handleClick = () => {
    createMutation({ variables: { mode: 'EXPORT' } })
      .then((res) => {
        if (res.data.importExportCreate.ok) {
          setSnackbarMessage(t('Export started successfully.'));
        } else {
          setSnackbarIsError(true);
        }
      })
      .catch((err) => {
        logError(err);
        setSnackbarIsError(true);
      })
      .finally(() => {
        setSnackbarOpen(true);
      });
  };

  // ----------------------------------------------------------------------------------------------------

  return (
    <Tooltip title={t('Export all data from your dashboard') as string} arrow>
      <span>
        <LoadingButton
          loading={loading}
          loadingIndicator={t('Exporting...')}
          variant="contained"
          startIcon={<DownloadIcon />}
          disableElevation
          onClick={handleClick}
          disabled={dashboard.importExportPending || loading}
        >
          {t('Export to XLSX')}
        </LoadingButton>
      </span>
    </Tooltip>
  );
}
