import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';

import {
  DashboardContext,
  useDashboardContext,
} from '../../_lib/context/dashboard-context';
import { ReactErrorComponent } from '../../_lib/react-error';
import {
  SubscribeToMore,
  SubscribeToMoreComponent,
} from '../../components/subscribe-to-more';

import { PriorityAreaMilestonesSlider } from './slider';

const PA_FRAGMENT = gql`
  fragment PriorityAreaMilestonesFragment on PriorityAreaType {
    id
    milestones {
      id
      title

      status

      deadline
      createdAt
      completionDate

      percDone

      intervention {
        id
        reference
        name
      }
    }
  }
`;

const PA_QUERY = gql`
  ${PA_FRAGMENT}
  query PriorityAreaMilestones(
    $priorityAreaId: ID!
    $filterInterventionIds: [ID!]
    $filterProgrammeIds: [ID!]
    $filterStatusIds: [ID!]
    $filterFinanciersIds: [ID!]
    $filterStakeholdersIds: [ID!]
    $filterRegionsIds: [ID!]
    $filterClassificationsIds: [ID!]
    $filterAreaFieldOptionsIds: [ID!]
    $filterAndOr: String = "AND"
  ) {
    priorityArea(
      priorityAreaId: $priorityAreaId
      filters: {
        interventionIds: $filterInterventionIds
        programmeIds: $filterProgrammeIds
        statusIds: $filterStatusIds
        financiersIds: $filterFinanciersIds
        stakeholdersIds: $filterStakeholdersIds
        regionsIds: $filterRegionsIds
        classificationsIds: $filterClassificationsIds
        areaFieldOptionsIds: $filterAreaFieldOptionsIds
        andOr: $filterAndOr
      }
    ) {
      ...PriorityAreaMilestonesFragment
    }
  }
`;

const PA_SUBSCRIPTION = gql`
  ${PA_FRAGMENT}
  subscription PriorityAreaMilestones(
    $priorityAreaId: ID!
    $filterInterventionIds: [ID!]
    $filterProgrammeIds: [ID!]
    $filterStatusIds: [ID!]
    $filterFinanciersIds: [ID!]
    $filterStakeholdersIds: [ID!]
    $filterRegionsIds: [ID!]
    $filterClassificationsIds: [ID!]
    $filterAreaFieldOptionsIds: [ID!]
    $filterAndOr: String = "AND"
  ) {
    priorityArea(
      priorityAreaId: $priorityAreaId
      filters: {
        interventionIds: $filterInterventionIds
        programmeIds: $filterProgrammeIds
        statusIds: $filterStatusIds
        financiersIds: $filterFinanciersIds
        stakeholdersIds: $filterStakeholdersIds
        regionsIds: $filterRegionsIds
        classificationsIds: $filterClassificationsIds
        areaFieldOptionsIds: $filterAreaFieldOptionsIds
        andOr: $filterAndOr
      }
    ) {
      ...PriorityAreaMilestonesFragment
    }
  }
`;

export function PriorityAreaMilestones() {
  const { t } = useTranslation();
  const { priorityAreaId } = useParams<any>();

  const { navbarFilterVariables } = useDashboardContext();

  const { data, loading, error, refetch, subscribeToMore } = useQuery(
    PA_QUERY,
    {
      variables: {
        priorityAreaId,
        ...navbarFilterVariables,
      },
    }
  );

  // ----------------------------------------------------------------------------------------------------

  if (error) return <ReactErrorComponent error={error} />;

  if (loading || !data) {
    if (!data && !loading) refetch();
    return <Skeleton variant="rectangular" height={400} sx={{ my: 3 }} />;
  }

  // ----------------------------------------------------------------------------------------------------

  return (
    <>
      {/* slider */}
      <Card sx={{ my: 3 }}>
        <CardContent sx={{ p: 4 }}>
          <Typography variant="h4">{t('Milestones')}</Typography>
          <Typography variant="body1" sx={{ mb: 4 }}>
            {t('Drag the slider to see future milestones')}
          </Typography>
          <PriorityAreaMilestonesSlider
            milestones={data?.priorityArea?.milestones || []}
          />
        </CardContent>
      </Card>

      {/* subscribe to more */}
      <SubscribeToMoreComponent
        subscribeToMore={subscribeToMore}
        document={PA_SUBSCRIPTION}
        variables={{
          priorityAreaId,
          ...navbarFilterVariables,
        }}
      />
    </>
  );
}
