import React from 'react';
import { useTranslation } from 'react-i18next';

import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';

import { useDashboardContext } from '../../../../_lib/context/dashboard-context';

import { MemberAutocomplete } from '../../../../components/member-autocomplete';

import { ReportTemplateDialogMetaInputsFlag } from './flag';
import { ReportTemplateDialogMetaInputsPageOrientation } from './page-orientation';
import { ReportTemplateDialogMetaInputsMilestones } from './milestones';

export function ReportTemplateDialogMetaInputs({
  disabled,
  templateMeta,
  updateTemplateMeta,
}: any) {
  const { t } = useTranslation();

  const { users } = useDashboardContext();

  // -------------------------------------------------------------------------------------------------------

  return (
    <Stack spacing={2}>
      {/* country focal point */}
      <TextField
        label={t('Country Focal Point')}
        variant="outlined"
        size="small"
        value={templateMeta.countryFocalPoint || ''}
        onChange={(e: any) =>
          updateTemplateMeta({ countryFocalPoint: e.target.value })
        }
        disabled={disabled}
        InputProps={{
          autoComplete: 'new-password',
        }}
      />
      {/* country lead */}
      <MemberAutocomplete
        label={t('Country Lead')}
        value={
          templateMeta.countryLead
            ? users.find((u: any) => u.id === templateMeta.countryLead)
            : null
        }
        onChange={(event: any, value: any) => {
          updateTemplateMeta({ countryLead: value?.id || null });
        }}
        disabled={disabled}
      />
      {/* country flag */}
      <ReportTemplateDialogMetaInputsFlag
        disabled={disabled}
        templateMeta={templateMeta}
        updateTemplateMeta={updateTemplateMeta}
      />

      <Box>
        <Divider sx={{ my: 1 }} />
      </Box>

      <Stack spacing={3}>
        {/* page orientation */}
        <ReportTemplateDialogMetaInputsPageOrientation
          disabled={disabled}
          templateMeta={templateMeta}
          updateTemplateMeta={updateTemplateMeta}
        />

        {/* milestones */}
        <ReportTemplateDialogMetaInputsMilestones
          disabled={disabled}
          templateMeta={templateMeta}
          updateTemplateMeta={updateTemplateMeta}
        />
      </Stack>
    </Stack>
  );
}
