import { View, Text } from '@react-pdf/renderer';

export function ReportsPdfUiHeaderArea({ area, areaName }: any) {
  return (
    <View>
      <View
        style={{
          display: 'flex',
          flexDirection: 'row',
          minHeight: '40px',
          justifyContent: 'flex-start',
          alignContent: 'center',
          alignItems: 'center',
        }}
      >
        <Text
          style={{
            fontSize: '17px',
            fontWeight: 'bold',
          }}
        >
          {`${areaName} - ${area.reference}. ${area.name}`}
        </Text>
      </View>
    </View>
  );
}
