import dashboardCreate from './mutations/dashboard/dashboard-create.graphql';
import dashboardSetup from './mutations/dashboard/dashboard-setup.graphql';
import dashboardUpdate from './mutations/dashboard/dashboard-update.graphql';
import dashboardLaunch from './mutations/dashboard/dashboard-launch.graphql';

import switchDashboard from './mutations/switch-dashboard.graphql';
import switchMember from './mutations/switch-member.graphql';

import importExportCreate from './mutations/dashboard/import-export/create-import-export.graphql';
import importExportUpdate from './mutations/dashboard/import-export/update-import-export.graphql';
import importExportDelete from './mutations/dashboard/import-export/delete.graphql';

import contentCreate from './mutations/content/content/content-create.graphql';
import contentUpdate from './mutations/content/content/content-update.graphql';
import contentDelete from './mutations/content/content/content-delete.graphql';
import contentUpdateCreate from './mutations/content/content-update/content-update-create.graphql';
import contentUpdateUpdate from './mutations/content/content-update/content-update-update.graphql';
import contentUpdateAccept from './mutations/content/content-update/content-update-accept.graphql';
import contentUpdateReject from './mutations/content/content-update/content-update-reject.graphql';
import contentUpdateDelete from './mutations/content/content-update/content-update-delete.graphql';
import contentUpdatesMarkViewed from './mutations/content/content-update/content-updates-mark-viewed.graphql';

import userCreate from './mutations/accounts/user-create.graphql';
import userPasswordUpdate from './mutations/accounts/user-password-update.graphql';
import memberRefreshCalendarToken from './mutations/accounts/member-refresh-calendar-token.graphql';
import memberRemove from './mutations/accounts/member-remove.graphql';
import memberUpdate from './mutations/accounts/member-update.graphql';

import financierAmountDelete from './mutations/content/financier/financier-amount-delete.graphql';

import priorityAreaSetup from './mutations/area/priority-area/priority-area-setup.graphql';
import priorityAreaMove from './mutations/area/priority-area/move.graphql';
import priorityAreaDelete from './mutations/area/priority-area/delete.graphql';
import priorityAreaArchive from './mutations/area/priority-area/archive.graphql';
import priorityAreasCreate from './mutations/area/priority-area/multi-create.graphql';
import priorityAreasUpdateOrder from './mutations/area/priority-area/update-order.graphql';

import interventionSetup from './mutations/area/intervention/intervention-setup.graphql';
import interventionMove from './mutations/area/intervention/move.graphql';
import interventionDelete from './mutations/area/intervention/delete.graphql';
import interventionArchive from './mutations/area/intervention/archive.graphql';
import interventionsCreate from './mutations/area/intervention/multi-create.graphql';
import interventionsUpdate from './mutations/area/intervention/interventions-update.graphql';
import interventionsUpdateOrder from './mutations/area/intervention/interventions-update-order.graphql';

import areaPermissionAdd from './mutations/area/permission/add.graphql';
import areaPermissionUpdate from './mutations/area/permission/update.graphql';
import areaPermissionRemove from './mutations/area/permission/remove.graphql';

import interventionStatusCreate from './mutations/dashboard/status/create.graphql';
import interventionStatusUpdate from './mutations/dashboard/status/update.graphql';
import interventionStatusDelete from './mutations/dashboard/status/delete.graphql';
import interventionStatusUpdateOrder from './mutations/dashboard/status/update-order.graphql';

import targetDelete from './mutations/target/target-delete.graphql';
import targetUpdateCreate from './mutations/target/target-update-create.graphql';
import targetUpdateDelete from './mutations/target/target-update-delete.graphql';

import contentBlockerCreate from './mutations/content/content-blocker/create.graphql';
import contentBlockerEdit from './mutations/content/content-blocker/edit.graphql';
import contentBlockerComplete from './mutations/content/content-blocker/complete.graphql';
import contentBlockerDelete from './mutations/content/content-blocker/delete.graphql';
import contentBlockerUpdateOrder from './mutations/content/content-blocker/update-order.graphql';

import reportUpdate from './mutations/report/update.graphql';
import reportDelete from './mutations/report/delete.graphql';

import programmeCreate from './mutations/content/programme/create.graphql';
import programmeUpdate from './mutations/content/programme/update.graphql';
import programmeDelete from './mutations/content/programme/delete.graphql';
import programmeUpdateOrder from './mutations/content/programme/update-order.graphql';

import contentGroupCreate from './mutations/dashboard/content-group/create.graphql';
import contentGroupUpdate from './mutations/dashboard/content-group/update.graphql';
import contentGroupDelete from './mutations/dashboard/content-group/delete.graphql';
import contentGroupUpdateOrder from './mutations/dashboard/content-group/update-order.graphql';

import areaLevelCreate from './mutations/area/area-level/create.graphql';
import areaLevelUpdate from './mutations/area/area-level/update.graphql';
import areaLevelDelete from './mutations/area/area-level/delete.graphql';
import areaLevelUpdateOrder from './mutations/area/area-level/update-order.graphql';

import areaFieldConfigCreate from './mutations/area/area-field/area-field-config-create.graphql';
import areaFieldConfigUpdate from './mutations/area/area-field/area-field-config-update.graphql';
import areaFieldConfigDelete from './mutations/area/area-field/area-field-config-delete.graphql';

import twoFactorEnable from './mutations/accounts/two-factor-enable.graphql';
import twoFactorDisable from './mutations/accounts/two-factor-disable.graphql';

import targetUnitCreate from './mutations/target/target-units/target-unit-create.graphql';
import targetUnitDelete from './mutations/target/target-units/target-unit-delete.graphql';
import targetUnitUpdate from './mutations/target/target-units/target-unit-update.graphql';
import targetUnitUpdateOrder from './mutations/target/target-units/target-unit-update-order.graphql';

export const FINANCIER_AMOUNT_DELETE = financierAmountDelete;

export const CONTENT_CREATE = contentCreate;
export const CONTENT_UPDATE = contentUpdate;
export const CONTENT_DELETE = contentDelete;
export const CONTENT_UPDATE_CREATE = contentUpdateCreate;
export const CONTENT_UPDATE_UPDATE = contentUpdateUpdate;
export const CONTENT_UPDATE_ACCEPT = contentUpdateAccept;
export const CONTENT_UPDATE_REJECT = contentUpdateReject;
export const CONTENT_UPDATE_DELETE = contentUpdateDelete;
export const CONTENT_UPDATES_MARK_VIEWED = contentUpdatesMarkViewed;

export const USER_CREATE = userCreate;
export const USER_PASSWORD_UPDATE = userPasswordUpdate;
export const MEMBER_REFRESH_CALENDAR_TOKEN = memberRefreshCalendarToken;
export const MEMBER_REMOVE = memberRemove;
export const MEMBER_UPDATE = memberUpdate;

export const SWITCH_DASHBOARD = switchDashboard;
export const SWITCH_MEMBER = switchMember;

export const DASHBOARD_SETUP = dashboardSetup;
export const DASHBOARD_UPDATE = dashboardUpdate;
export const DASHBOARD_CREATE = dashboardCreate;
export const DASHBOARD_LAUNCH = dashboardLaunch;

export const IMPORT_EXPORT_CREATE = importExportCreate;
export const IMPORT_EXPORT_UPDATE = importExportUpdate;
export const IMPORT_EXPORT_DELETE = importExportDelete;

export const AREA_PERMISSION_ADD = areaPermissionAdd;
export const AREA_PERMISSION_UPDATE = areaPermissionUpdate;
export const AREA_PERMISSION_REMOVE = areaPermissionRemove;

export const PRIORITY_AREAS_CREATE = priorityAreasCreate;
export const PRIORITY_AREA_SETUP = priorityAreaSetup;
export const PRIORITY_AREA_MOVE = priorityAreaMove;
export const PRIORITY_AREA_DELETE = priorityAreaDelete;
export const PRIORITY_AREA_ARCHIVE = priorityAreaArchive;
export const PRIORITY_AREAS_UPDATE_ORDER = priorityAreasUpdateOrder;

export const INTERVENTIONS_CREATE = interventionsCreate;
export const INTERVENTION_SETUP = interventionSetup;
export const INTERVENTION_MOVE = interventionMove;
export const INTERVENTION_DELETE = interventionDelete;
export const INTERVENTION_ARCHIVE = interventionArchive;
export const INTERVENTIONS_UPDATE = interventionsUpdate;
export const INTERVENTIONS_UPDATE_ORDER = interventionsUpdateOrder;

export const INTERVENTION_STATUS_CREATE = interventionStatusCreate;
export const INTERVENTION_STATUS_UPDATE = interventionStatusUpdate;
export const INTERVENTION_STATUS_DELETE = interventionStatusDelete;
export const INTERVENTION_STATUS_UPDATE_ORDER = interventionStatusUpdateOrder;

export const TARGET_DELETE = targetDelete;
export const TARGET_UPDATE_CREATE = targetUpdateCreate;
export const TARGET_UPDATE_DELETE = targetUpdateDelete;

export const CONTENT_BLOCKER_CREATE = contentBlockerCreate;
export const CONTENT_BLOCKER_EDIT = contentBlockerEdit;
export const CONTENT_BLOCKER_COMPLETE = contentBlockerComplete;
export const CONTENT_BLOCKER_DELETE = contentBlockerDelete;
export const CONTENT_BLOCKER_UPDATE_ORDER = contentBlockerUpdateOrder;

export const REPORT_UPDATE = reportUpdate;
export const REPORT_DELETE = reportDelete;

export const PROGRAMME_CREATE = programmeCreate;
export const PROGRAMME_UPDATE = programmeUpdate;
export const PROGRAMME_DELETE = programmeDelete;
export const PROGRAMME_UPDATE_ORDER = programmeUpdateOrder;

export const CONTENT_GROUP_CREATE = contentGroupCreate;
export const CONTENT_GROUP_UPDATE = contentGroupUpdate;
export const CONTENT_GROUP_DELETE = contentGroupDelete;
export const CONTENT_GROUP_UPDATE_ORDER = contentGroupUpdateOrder;

export const AREA_LEVEL_CREATE = areaLevelCreate;
export const AREA_LEVEL_UPDATE = areaLevelUpdate;
export const AREA_LEVEL_DELETE = areaLevelDelete;
export const AREA_LEVEL_UPDATE_ORDER = areaLevelUpdateOrder;

export const AREA_FIELD_CONFIG_CREATE = areaFieldConfigCreate;
export const AREA_FIELD_CONFIG_UPDATE = areaFieldConfigUpdate;
export const AREA_FIELD_CONFIG_DELETE = areaFieldConfigDelete;

export const TWO_FACTOR_ENABLE = twoFactorEnable;
export const TWO_FACTOR_DISABLE = twoFactorDisable;

export const TARGET_UNIT_CREATE = targetUnitCreate;
export const TARGET_UNIT_DELETE = targetUnitDelete;
export const TARGET_UNIT_UPDATE = targetUnitUpdate;
export const TARGET_UNIT_UPDATE_ORDER = targetUnitUpdateOrder;
