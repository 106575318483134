import React from 'react';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import Box from '@mui/material/Box';
import LoadingButton from '@mui/lab/LoadingButton';

import { CONTEXT_DASHBOARD_QUERY } from '../../../../_lib/graphql/queries';
import { PROGRAMME_CREATE } from '../../../../_lib/graphql/mutations';

import { logError } from '../../../../_lib/error';
import { useDashboardContext } from '../../../../_lib/context/dashboard-context';
import { DialogTitle } from '../../../../DialogTitle/dialog-title';

export function PlanningInterventionFormProgrammeDialogCreate({
  open,
  setOpen,
  intervention,
  setIntervention,
}: any) {
  const { t } = useTranslation();
  const { setSnackbarIsError, setSnackbarOpen } = useDashboardContext();

  const formRef = React.useRef<HTMLFormElement>(null);
  const [loading, setLoading] = React.useState(false);

  const [createProgrammeMutation] = useMutation(PROGRAMME_CREATE, {
    update: (cache, { data: { programmeCreate } }) => {
      cache.writeQuery({
        query: CONTEXT_DASHBOARD_QUERY,
        data: {
          dashboard: programmeCreate.dashboard,
        },
      });
    },
  });

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setLoading(true);

    const formData = new FormData(event.currentTarget);
    const name = formData.get('name') as string;

    createProgrammeMutation({ variables: { name } })
      .then((result) => {
        setOpen(false);
        const newProgramme = result.data.programmeCreate.programme;
        setIntervention({
          ...intervention,
          programme: newProgramme,
        });
      })
      .catch((e) => {
        setSnackbarIsError(true);
        logError(e);
      })
      .finally(() => {
        setSnackbarOpen(true);
        setLoading(false);
      });
  };

  const handleCancel = () => {
    setOpen(false);
    formRef.current?.reset();
  };

  return (
    <Dialog open={open} maxWidth="xs" fullWidth>
      <DialogTitle onClose={() => setOpen(false)}>
        {t('Add a new programme')}
      </DialogTitle>
      <DialogContent>
        <Box sx={{ pt: 1 }}>
          <form
            onSubmit={handleSubmit}
            ref={formRef}
            id="create-programme-form"
          >
            <TextField
              size="small"
              fullWidth
              name="name"
              label={t('Name')}
              autoFocus
              required
              disabled={loading}
              InputProps={{
                autoComplete: 'off',
              }}
            />
          </form>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleCancel} disabled={loading}>
          {t('Cancel')}
        </Button>
        <LoadingButton
          loading={loading}
          variant="contained"
          type="submit"
          form="create-programme-form"
        >
          {t('Save')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

export default PlanningInterventionFormProgrammeDialogCreate;
