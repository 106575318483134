import { PlanningTargetsInputTargetUnitAutocomplete } from './autocomplete';

export function PlanningTargetsInputTargetUnit({
  target,
  disabled,
  area,
  setArea,
  setNewTargets,
  label,
}: any) {
  return (
    <PlanningTargetsInputTargetUnitAutocomplete
      label={label}
      disabled={disabled}
      area={{ ...area, targetUnits: [target.targetUnit] }}
      setArea={(newArea: any) => {
        const newTargetUnit = newArea.targetUnits[0];
        if (newTargetUnit?.inputValue) {
          setNewTargets((prev: any) =>
            prev.map((t: any) => {
              if (t.fauxId === target.fauxId) {
                return {
                  ...t,
                  targetUnit: {
                    id: 'new',
                    name: newTargetUnit?.inputValue,
                  },
                };
              }
              return t;
            })
          );
        } else if (target.fauxId) {
          setNewTargets((prev: any) =>
            prev.map((t: any) => {
              if (t.fauxId === target.fauxId) {
                return {
                  ...t,
                  targetUnit: newTargetUnit,
                };
              }
              return t;
            })
          );
        } else {
          setArea({
            ...area,
            targetsDb: area.targetsDb.map((t: any) => {
              if (t.id === target.id) {
                return {
                  ...t,
                  targetUnit: newTargetUnit,
                };
              }
              return t;
            }),
          });
        }
      }}
    />
  );
}
