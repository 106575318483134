import Container from '@mui/material/Container';

import { PriorityArea } from '..';
import { LayoutDashboard } from '../../_layout/dashboard';

export function PriorityAreaLoader() {
  // ------------------------------------------------------------------------------------------------------------------

  return (
    <LayoutDashboard>
      <Container>
        <PriorityArea />
      </Container>
    </LayoutDashboard>
  );
}

export default PriorityAreaLoader;
