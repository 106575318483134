import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import Divider from '@mui/material/Divider';

import { DASHBOARD_SETUP } from '../_lib/graphql/mutations';
import { CONTEXT_DASHBOARD_QUERY } from '../_lib/graphql/queries';
import { DashboardContext } from '../_lib/context/dashboard-context';
import { logError } from '../_lib/error';

import TbiLogo from '../../assets/img/tbilogo/dashboard.jpg';

export function SetupDashboard({
  valid,

  newDashboard,
  setNewDashboard,

  logo,

  disabled,
  setDisabled,

  loading,
  setLoading,
}: any) {
  const { t } = useTranslation();

  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));
  const { dashboard, setSnackbarOpen, setSnackbarIsError } =
    React.useContext(DashboardContext);

  const [setupDashboardMutation] = useMutation(DASHBOARD_SETUP, {
    update(cache, { data: { dashboardSetup } }) {
      cache.writeQuery({
        query: CONTEXT_DASHBOARD_QUERY,
        data: { dashboard: dashboardSetup.dashboard },
      });
    },
  });

  const handleCancel = () => {
    setDisabled(true);
    setNewDashboard(dashboard);
  };

  const handleEdit = () => {
    setDisabled(false);
  };

  const handleSubmit = () => {
    if (valid()) {
      setLoading(true);

      const variables = {
        id: newDashboard.id,
        country: newDashboard.country,
        currency: newDashboard.currency,
        name: newDashboard.name.trim(),
        goal: newDashboard.goal,
        interventionName: newDashboard.interventionName.trim(),
        priorityAreaName: newDashboard.priorityAreaName.trim(),
        permContentEditLevel: newDashboard.permContentEditLevel,
        activitiesArchiveFrequency: Number.parseInt(
          newDashboard.activitiesArchiveFrequency,
          10
        ),
        language: newDashboard.language,
      } as any;

      if (typeof logo !== 'string' && logo.name) {
        variables.logo = logo;
      }

      if (logo === TbiLogo) {
        variables.logoRemove = true;
      }

      setupDashboardMutation({ variables })
        .then(() => {
          setDisabled(true);
        })
        .catch((e) => {
          logError(e);
          setSnackbarIsError(true);
        })
        .finally(() => {
          setLoading(false);
          setSnackbarOpen(true);
        });
    }
  };

  return (
    <Stack spacing={1.5} direction={{ xs: 'column', sm: 'row' }}>
      {!disabled && (
        <Button
          onClick={handleCancel}
          disabled={loading}
          fullWidth={smDown}
          variant={smDown ? 'outlined' : 'text'}
          size={smDown ? 'small' : 'medium'}
        >
          {t('Cancel')}
        </Button>
      )}
      <LoadingButton
        id={disabled ? 'save-changes' : 'edit-dashboard'}
        variant="contained"
        onClick={!disabled ? handleSubmit : handleEdit}
        loading={loading}
        disableElevation
        fullWidth={smDown}
      >
        {!disabled ? t('Save') : `${t('Edit')}`}
      </LoadingButton>
      <Divider sx={{ display: smDown ? 'block' : 'none' }} />
    </Stack>
  );
}
