import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';

import LogoutIcon from '@mui/icons-material/Logout';

export function AppbarAvatarSignOut() {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <MenuItem onClick={() => history.push('/accounts/logout/')}>
      <ListItemIcon>
        <LogoutIcon fontSize="small" />
      </ListItemIcon>

      <ListItemText
        primary={t('Sign out')}
        primaryTypographyProps={{
          noWrap: true,
          display: 'flex',
          alignItems: 'center',
        }}
      />
    </MenuItem>
  );
}
