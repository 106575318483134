import React from 'react';

import CardHeader from '@mui/material/CardHeader';
import IconButton from '@mui/material/IconButton';
import DragHandleIcon from '@mui/icons-material/DragHandle';

import { PlanningAreaActionsHeader } from '../../area-actions/header';

export function PlanningInterventionFormDetailHeader({
  intvInitial,
  filtered,
  toggleCollapse,
  setOpenPermissionsDialog,
  disabled,
  setDisabled,
  openCollapse,
  setOpenCollapse,
  listVariables,
}: any) {
  return (
    <CardHeader
      title={`${intvInitial.reference} - ${intvInitial.name}`}
      titleTypographyProps={{
        variant:
          openCollapse.includes(intvInitial.id) || !disabled ? 'h5' : 'h6',
        sx: {
          transition: 'all 0.3s ease-in-out',
          fontWeight: 'normal',
        },
        onClick: () => (disabled ? toggleCollapse(intvInitial.id) : null),
      }}
      avatar={
        !filtered &&
        !openCollapse.includes(intvInitial.id) &&
        !intvInitial.archivedAt &&
        disabled && (
          <IconButton
            sx={{
              cursor: 'grab !important',
            }}
            disableRipple
          >
            <DragHandleIcon />
          </IconButton>
        )
      }
      action={
        <PlanningAreaActionsHeader
          area={intvInitial}
          openLink={intvInitial.urlRelative}
          toggleCollapse={toggleCollapse}
          setOpenPermissionsDialog={setOpenPermissionsDialog}
          disabled={disabled}
          setDisabled={setDisabled}
          openCollapse={openCollapse}
          setOpenCollapse={setOpenCollapse}
          listVariables={listVariables}
        />
      }
    />
  );
}
