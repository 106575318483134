import React from 'react';
import { useTranslation } from 'react-i18next';

import Button from '@mui/material/Button';
import Fade from '@mui/material/Fade';
import Box from '@mui/material/Box';

import CachedRoundedIcon from '@mui/icons-material/CachedRounded';

import { useOnlineStatus } from '../../../_lib/offline/use-online-status';
import * as serviceWorkerRegistration from '../../../_lib/offline/serviceWorkerRegistration';

export function SwitchDashboardDialogReload() {
  const { t } = useTranslation();

  const [showReload, setShowReload] = React.useState(false);

  const isOnline = useOnlineStatus();

  const handleReload = () => {
    if (isOnline) {
      window.localStorage.clear();
      serviceWorkerRegistration.unregister().then(() => {
        window.location.reload();
      });
    } else {
      window.location.reload();
    }
  };

  React.useEffect(() => {
    const reloadTimer = setTimeout(() => setShowReload(true), 10000);
    return () => {
      clearTimeout(reloadTimer);
    };
  }, []);

  return (
    <Fade in={showReload}>
      <Box
        sx={{
          display: showReload ? 'block' : 'none',
        }}
      >
        <Button
          variant="outlined"
          startIcon={<CachedRoundedIcon />}
          onClick={handleReload}
        >
          {t('Reload')}
        </Button>
      </Box>
    </Fade>
  );
}
