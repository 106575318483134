import React from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorBoundary } from 'react-error-boundary';

import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';

import AddIcon from '@mui/icons-material/Add';

import { slugify } from '../../../../_utils/general-utils';
import { ReactErrorComponent } from '../../../../_lib/react-error';

import { DraggableAreaContainer } from '../../../../components/draggable/area-container';

import { PlanningPriorityAreaFormDetailChildrenItem } from './item';

export function PlanningPriorityAreaFormDetailChildren({
  parent,
  areaName,
  areas,
  setAreas,
  addArea,
  disabled,
  updateOrderMutation,
}: any) {
  const { t } = useTranslation();

  const [loading, setLoading] = React.useState(false);

  return (
    <div>
      <ErrorBoundary FallbackComponent={ReactErrorComponent}>
        <Typography
          variant="body1"
          paragraph
          color={disabled ? 'text.secondary' : 'text.primary'}
          sx={{ fontWeight: 700 }}
        >
          {areaName || t('Undefined')}:
        </Typography>

        {!areaName && (
          <Alert severity="error" sx={{ mb: 3 }}>
            {t(
              'The below items do not belong to any area level. Please move them to an appropriate area level or create a new one in dashboard settings.'
            )}
          </Alert>
        )}

        {/* existing areas */}
        <DraggableAreaContainer
          parent={parent}
          details={areas}
          setDetails={setAreas}
          droppableId={`priority-area-form-${slugify(
            areaName || t('Undefined')
          )}-list-existing`}
          loading={loading}
          setLoading={setLoading}
          updateOrderMutation={updateOrderMutation}
        >
          {areas
            ?.filter((area: any) => !!area.id)
            ?.map((area: any, index: any) => (
              <PlanningPriorityAreaFormDetailChildrenItem
                key={area.id || area.fauxId || 'new'}
                area={area}
                index={index}
                parent={parent}
                areas={areas}
                setAreas={setAreas}
                disabled={disabled}
              />
            ))}
        </DraggableAreaContainer>

        {/* new areas */}
        <DraggableAreaContainer
          parent={parent}
          details={areas}
          setDetails={setAreas}
          droppableId={`priority-area-form-${slugify(
            areaName || t('Undefined')
          )}-list-new`}
          loading={loading}
        >
          {areas
            ?.filter((area: any) => !area.id)
            ?.map((area: any, index: any) => (
              <PlanningPriorityAreaFormDetailChildrenItem
                key={area.id || area.fauxId || 'new'}
                area={area}
                index={index + (areas?.filter((a: any) => !!a.id)?.length || 0)}
                parent={parent}
                areas={areas}
                setAreas={setAreas}
                disabled={disabled}
              />
            ))}
        </DraggableAreaContainer>

        {!areas?.length && (
          <Typography
            variant="body1"
            paragraph
            color={disabled ? 'text.secondary' : 'text.primary'}
            sx={{ pl: 2, fontWeight: 300 }}
          >
            {t('Nothing to show')}
          </Typography>
        )}

        {!disabled && !!areaName && (
          <Button
            startIcon={<AddIcon />}
            variant="outlined"
            size="small"
            onClick={addArea}
          >
            {t('Add')} {areaName}
          </Button>
        )}
      </ErrorBoundary>
    </div>
  );
}
