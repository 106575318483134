import React from 'react';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { logError } from '../../../_lib/error';

import { useDashboardContext } from '../../../_lib/context/dashboard-context';
import { DragggableDialogDialog } from '../../../components/draggable-dialog/dialog';

import {
  INTERVENTION_STATUS_CREATE,
  INTERVENTION_STATUS_UPDATE,
  INTERVENTION_STATUS_DELETE,
  INTERVENTION_STATUS_UPDATE_ORDER,
} from '../../../_lib/graphql/mutations';

export function PlanningInterventionFormStatusDialogManage({
  open,
  setOpen,
  disableEnforceFocus,
}: any) {
  const { t } = useTranslation();

  const { priorityAreaId } = useParams() as any;

  const { dashboard, setSnackbarOpen, setSnackbarIsError } =
    useDashboardContext();

  const [details, setDetails] = React.useState<any>([]);

  const [createInterventionStatusMutation] = useMutation(
    INTERVENTION_STATUS_CREATE
  );
  const [updateInterventionStatusMutation] = useMutation(
    INTERVENTION_STATUS_UPDATE
  );
  const [deleteInterventionStatusMutation] = useMutation(
    INTERVENTION_STATUS_DELETE
  );
  const [updateInterventionStatusOrderMutation] = useMutation(
    INTERVENTION_STATUS_UPDATE_ORDER
  );

  // ----------------------------------------------------------------------------------------------------

  const createMutation = (value: string, color: string) =>
    createInterventionStatusMutation({
      variables: {
        name: value,
        color,
      },
    })
      .catch((error) => {
        logError(error);
        setSnackbarIsError(true);
      })
      .finally(() => setSnackbarOpen(true));

  const updateMutation = ({ id, value, color }: any) =>
    updateInterventionStatusMutation({
      variables: {
        id,
        name: value,
        color,
        priorityAreaId,
      },
    })
      .catch((error) => {
        logError(error);
        setSnackbarIsError(true);
      })
      .finally(() => setSnackbarOpen(true));

  const deleteMutation = (id: number) =>
    deleteInterventionStatusMutation({ variables: { id, priorityAreaId } })
      .then((r: any) => {
        return Promise.resolve(r);
      })
      .catch((error) => {
        logError(error);
        setSnackbarIsError(true);
      })
      .finally(() => setSnackbarOpen(true));

  const updateOrderMutation = (statusIds: number[]) =>
    updateInterventionStatusOrderMutation({
      variables: {
        statusIds,
      },
    })
      .catch((error) => {
        logError(error);
        setSnackbarIsError(true);
      })
      .finally(() => setSnackbarOpen(true));

  // ----------------------------------------------------------------------------------------------------
  // ----------------------------------------------------------------------------------------------------
  // effects
  // ----------------------------------------------------------------------------------------------------

  React.useEffect(() => {
    if (dashboard.statuses) {
      setDetails(
        dashboard.statuses.map((status: any) => ({
          id: status.id,
          value: status.name,
          color: status.color,
        }))
      );
    }
  }, [dashboard]);

  // ----------------------------------------------------------------------------------------------------

  return (
    <DragggableDialogDialog
      open={open}
      setOpen={setOpen}
      details={details}
      setDetails={setDetails}
      title={t('Manage Statuses')}
      inputLabel={t('Name')}
      addLabel={t('Add Status')}
      createMutation={createMutation}
      updateMutation={updateMutation}
      deleteMutation={deleteMutation}
      updateOrderMutation={updateOrderMutation}
      disableEnforceFocus={disableEnforceFocus}
      includeColorPicker
    />
  );
}
