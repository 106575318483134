import { Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  item: {
    flexDirection: 'row',
    marginBottom: 5,
    flexWrap: 'wrap',
  },
  number: {
    marginRight: 3,
  },
  bulletPoint: {
    width: 10,
    paddingTop: 1,
  },
  itemContent: {
    flex: 1,
  },
});

export const List = ({ children }: any) => children;

export function Item({ children, view, number }: any) {
  return (
    <View style={styles.item} wrap={false}>
      {number ? (
        <Text style={styles.number}>{number}.</Text>
      ) : (
        <Text style={styles.bulletPoint}>•</Text>
      )}
      {view ? (
        <View style={styles.itemContent}>{children}</View>
      ) : (
        <Text style={styles.itemContent}>{children}</Text>
      )}
    </View>
  );
}

export default List;
