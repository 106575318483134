import React from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined';

import { MyAccountDetails } from '../../components/my-account/details';
import { MyAccountPreferences } from '../../components/my-account/preferences';

import { ImpressumReleaseVersion } from '../../auth/_layout';
import { useDashboardContext } from '../../_lib/context/dashboard-context';

export function ScreenMyAccount() {
  const { t } = useTranslation();

  const { dashboard } = useDashboardContext();

  return (
    <>
      <Helmet>
        <title>{`${t('My Account')} - ${dashboard.name}`}</title>
      </Helmet>
      <Container maxWidth="lg" sx={{ pb: 7 }}>
        <Typography
          variant="h5"
          sx={{ my: 3, fontWeight: 500, display: 'flex', alignItems: 'center' }}
        >
          <BadgeOutlinedIcon sx={{ mr: 1 }} /> {t('My Account')}
        </Typography>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={5}>
            <MyAccountDetails />
          </Grid>
          <Grid item xs={12} sm={7}>
            <MyAccountPreferences />
          </Grid>
        </Grid>

        <ImpressumReleaseVersion />
      </Container>
    </>
  );
}
