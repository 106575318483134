import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';

import { useDashboardContext } from '../../../../_lib/context/dashboard-context';
import { useAreas } from '../../../../_utils/hooks/queries/use-areas';

export function NavbarAppBarSearchResultsAreas({
  isPriorityArea,
  search,
  handleNavigate,
}: any) {
  const { t } = useTranslation();
  const { dashboard } = useDashboardContext();

  const { data, loading, error } = useAreas(
    isPriorityArea ? 'priorityArea' : 'intervention',
    {
      filterSearchQuery: search,
      paginationInterventionsLimit: 5,
    }
  );

  const filteredAreas = React.useMemo(
    () =>
      data
        ? isPriorityArea
          ? data.priorityAreas.items
          : data.interventions.items
        : [],
    [data, isPriorityArea]
  );

  if (error) return null;

  if (loading || !data) return <Skeleton height={100} variant="rectangular" />;

  return (
    <List
      component="nav"
      subheader={
        <ListSubheader component="div">
          {isPriorityArea
            ? !dashboard.enableAreaLevels
              ? dashboard.priorityAreaName
              : ''
            : dashboard.interventionName}
        </ListSubheader>
      }
    >
      {!filteredAreas?.length && (
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{
            ml: 2,
          }}
        >
          {t('No results found')}
        </Typography>
      )}
      {filteredAreas?.map((area: any) => (
        <ListItem key={area.id} disablePadding>
          <ListItemButton
            onClick={() => handleNavigate(area)}
            component={
              dashboard.mode === 'SUPRANATIONAL' &&
              dashboard.enableDodPaSwitch &&
              area.__typename === 'PriorityAreaType'
                ? 'div'
                : RouterLink
            }
            to={area.urlRelative}
          >
            <ListItemText
              primary={`${area.reference} - ${area.name}`}
              primaryTypographyProps={{
                sx: {
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                },
              }}
              secondary={
                isPriorityArea
                  ? dashboard.mode === 'SUPRANATIONAL'
                    ? area.dashboardOfDashboards?.dashboard?.name
                    : dashboard.enableAreaLevels
                    ? area.level?.name
                    : ''
                  : area.priorityArea
                  ? `${area.priorityArea.reference} - ${area.priorityArea.name}`
                  : ''
              }
              secondaryTypographyProps={{
                sx: {
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                },
              }}
            />
          </ListItemButton>
        </ListItem>
      ))}
    </List>
  );
}
