import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';

import BackgroundLetterAvatar from '../../../components/avatar-bg-letter';

import { useDashboardContext } from '../../../_lib/context/dashboard-context';

export function AppbarAvatarProfile() {
  const { user } = useDashboardContext();
  return (
    <>
      <ListItem>
        <ListItemAvatar>
          <BackgroundLetterAvatar name={user.name} email={user.email} />
        </ListItemAvatar>
        <ListItemText
          primary={user.name}
          secondary={user.email}
          primaryTypographyProps={{ noWrap: true, maxWidth: '220px' }}
          secondaryTypographyProps={{ noWrap: true, maxWidth: '220px' }}
        />
      </ListItem>
      <Divider />
    </>
  );
}
