import { View } from '@react-pdf/renderer';
import Boxed from '../ui/boxed';

export function PdftTemplateSectionsAreaHeader({
  t,
  reportData,
  area,
  areaName,
}: any) {
  if (!area) return <View />;

  const priorityArea =
    area.__typename === 'InterventionType'
      ? reportData.priorityAreas.find((pa: any) =>
          pa.interventions?.find((intv: any) => intv.id === area.id)
        )
      : reportData.dashboard.enableAreaLevels &&
        area.__typename === 'PriorityAreaType'
      ? reportData.priorityAreas.find((pa: any) => pa.id === area.parent?.id)
      : null;

  const boxWidth = priorityArea ? '32%' : '48%';

  return (
    <View>
      <View
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        {!!priorityArea && (
          <Boxed
            width={boxWidth}
            spacing={1}
            title={
              !reportData.dashboard.enableAreaLevels
                ? reportData.dashboard.priorityAreaName
                : priorityArea.level.name
            }
            content={`${
              priorityArea.reference && `${priorityArea.reference} - `
            }${priorityArea.name}`}
          />
        )}
        <Boxed
          width={boxWidth}
          spacing={1}
          title={areaName}
          content={`${area.reference && `${area.reference} - `}${area.name}`}
        />
        <Boxed
          width={boxWidth}
          spacing={1}
          title={
            !reportData.dashboard.enableLeadsMulti ? t('Lead') : t('Leads')
          }
          content={
            !reportData.dashboard.enableLeadsMulti
              ? area.lead?.name || '-'
              : area.leads?.map((lead: any) => lead.name).join(', ') || '-'
          }
        />
      </View>

      {/* description */}
      {!!area.description && (
        <View
          style={{
            marginTop: 10,
          }}
        >
          <Boxed
            spacing={2}
            widthRatio={[1, 3]}
            title={t('Description')}
            content={area.description}
            contentTextAlign="left"
            contentAlignItems="flex-start"
          />
        </View>
      )}
    </View>
  );
}
