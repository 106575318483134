import React from 'react';
import { useTranslation } from 'react-i18next';

import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import Tooltip from '@mui/material/Tooltip';

import { useDashboardContext } from '../../../_lib/context/dashboard-context';

import { MemberRow } from './row';

export function DashboardFormMembersTable({ searchTerm }: any) {
  const { t } = useTranslation();

  const { users } = useDashboardContext();

  // ----------------------------------------------------------------------------------------------------------------
  // ----------------------------------------------------------------------------------------------------------------
  // effects
  // ----------------------------------------------------------------------------------------------------------------

  // data
  const filteredUsers = React.useMemo(() => {
    if (searchTerm && searchTerm.length > 0) {
      return (
        users?.filter((userSetting: any) => {
          return (
            userSetting.name
              ?.toLowerCase()
              .includes(searchTerm.toLowerCase()) ||
            userSetting.position
              ?.toLowerCase()
              .includes(searchTerm.toLowerCase()) ||
            userSetting.email?.toLowerCase().includes(searchTerm.toLowerCase())
          );
        }) || []
      );
    }
    return users || [];
  }, [users, searchTerm]);

  // ----------------------------------------------------------------------------------------------------------------

  return (
    <TableContainer
      component={Paper}
      sx={{ maxHeight: '80vh' }}
      variant="outlined"
    >
      <Table
        stickyHeader
        size="small"
        sx={{
          '& .MuiTableCell-root': {
            whiteSpace: 'nowrap',
          },
        }}
      >
        <TableHead>
          <TableRow>
            <TableCell>#</TableCell>
            <TableCell>{t('Name')}</TableCell>
            <TableCell>{t('Position')}</TableCell>
            <TableCell>{t('Email Address')}</TableCell>
            <TableCell>{t('Last Login')}</TableCell>
            <TableCell sx={{ textAlign: 'center' }}>
              {t('2FA Enabled?')}
            </TableCell>
            <TableCell sx={{ textAlign: 'center' }}>{t('Is Admin?')}</TableCell>
            <Tooltip
              title={`${t(
                'If this is enabled, the user will be able to see the entire dashboard with view-only permissions.'
              )}`}
            >
              <TableCell sx={{ textAlign: 'center' }}>
                {`${t('Has Dashboard View Permission?')}`}
              </TableCell>
            </Tooltip>
            <TableCell> </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredUsers.map((userSetting: any, i: any) => (
            <MemberRow key={userSetting.id} userSetting={userSetting} i={i} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
