import React from 'react';
import { gql, useQuery } from '@apollo/client';

import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';

import { logError } from '../../_lib/error';
import { DashboardContext } from '../../_lib/context/dashboard-context';
import { getStringDate, withCommas } from '../../_utils/general-utils';
import { SubscribeToMore } from '../subscribe-to-more';

const AREA_FIELD_VALUES_FRAGMENT = gql`
  fragment AreaFieldValuesFragment on AreaFieldValueType {
    id
    value
    number
    date
    options {
      id
      name
    }
    config {
      id
      label
      fieldType
    }
  }
`;

const AREA_FIELD_VALUES_QUERY = gql`
  ${AREA_FIELD_VALUES_FRAGMENT}
  query AreaFieldValues($priorityAreaId: ID, $interventionId: ID) {
    areaFieldValues(
      priorityAreaId: $priorityAreaId
      interventionId: $interventionId
    ) {
      ...AreaFieldValuesFragment
    }
  }
`;

const AREA_FIELD_VALUES_SUBSCRIPTION = gql`
  ${AREA_FIELD_VALUES_FRAGMENT}
  subscription AreaFieldValues($priorityAreaId: ID, $interventionId: ID) {
    areaFieldValues(
      priorityAreaId: $priorityAreaId
      interventionId: $interventionId
    ) {
      ...AreaFieldValuesFragment
    }
  }
`;

export function AreaSummaryCustomFields({ area }: any) {
  const { dashboard } = React.useContext(DashboardContext);

  const { data, error, loading, subscribeToMore } = useQuery(
    AREA_FIELD_VALUES_QUERY,
    {
      variables:
        area.__typename === 'PriorityAreaType'
          ? {
              priorityAreaId: area.id,
            }
          : {
              interventionId: area.id,
            },
    }
  );

  // ---------------------------------------------------------------------------------------------------------------
  // ---------------------------------------------------------------------------------------------------------------
  // effects
  // ---------------------------------------------------------------------------------------------------------------

  // error
  React.useEffect(() => {
    if (error) {
      logError(error);
    }
  }, [error]);

  // ---------------------------------------------------------------------------------------------------------------

  return (
    <>
      {/* loading */}
      {loading && (
        <Typography variant="body1">
          <Skeleton />
        </Typography>
      )}

      {/* list of areafield values  */}
      {!loading &&
        !error &&
        data?.areaFieldValues?.map((areaFieldValue: any) => {
          const { config, value, number, date, options } = areaFieldValue;

          return (
            <Typography key={areaFieldValue.id} variant="body1">
              <b>{config.label}:</b> {config.fieldType === 'TEXT' && value}
              {config.fieldType === 'NUMBER' &&
                withCommas(number, dashboard.language, 20)}
              {config.fieldType === 'DATE' &&
                getStringDate(date, dashboard.language)}
              {config.fieldType === 'SELECT' && options[0]?.name}
              {config.fieldType === 'MULTI_SELECT' &&
                options.map((option: any) => option.name).join(', ')}
            </Typography>
          );
        })}

      {/* subscription */}
      {!!data && (
        <SubscribeToMore
          subscribeToMore={() =>
            subscribeToMore({
              document: AREA_FIELD_VALUES_SUBSCRIPTION,
              variables:
                area.__typename === 'PriorityAreaType'
                  ? {
                      priorityAreaId: area.id,
                    }
                  : {
                      interventionId: area.id,
                    },
              updateQuery: (prev, { subscriptionData }) => {
                if (!subscriptionData.data) return prev;
                return subscriptionData.data;
              },
            })
          }
          variables={
            area.__typename === 'PriorityAreaType'
              ? {
                  priorityAreaId: area.id,
                }
              : {
                  interventionId: area.id,
                }
          }
        />
      )}
    </>
  );
}
