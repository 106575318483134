import React from 'react';

import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { ChartOptions, ChartData, ChartDataset } from 'chart.js';

import { useTranslation } from 'react-i18next';
import { ErrorBoundary } from 'react-error-boundary';
import { gql, useQuery } from '@apollo/client';

import Skeleton from '@mui/material/Skeleton';
import Box from '@mui/material/Box';

import {
  ChartDefaultProps,
  getRandomColor,
  truncateChartLabel,
} from '../charts-config';

import {
  FILTER_VARIABLES,
  FILTER_VARIABLES_DEF,
} from '../../../_lib/graphql/fragments';
import { SubscribeToMoreComponent } from '../../../components/subscribe-to-more';
import { ReactErrorComponent } from '../../../_lib/react-error';
import { useDashboardContext } from '../../../_lib/context/dashboard-context';

const PAS_FRAGMENT = gql`
  fragment PriorityAreasAnalyticsContentGroupFragment on PriorityAreasReturnType {
    items {
      id
      reference
      name
      content {
        id
        group {
          id
          title
        }
      }
    }
  }
`;

const PAS_QUERY = gql`
  query PriorityAreasAnalyticsContentGroupQuery(
    ${FILTER_VARIABLES_DEF}
  ) {
    priorityAreas(
      ${FILTER_VARIABLES}
    ) {
      ...PriorityAreasAnalyticsContentGroupFragment
    }
  }
  ${PAS_FRAGMENT}
`;

const PAS_SUBSCRIPTION = gql`
  subscription PriorityAreasAnalyticsContentGroupSubscription(
    ${FILTER_VARIABLES_DEF}
  ) {
    priorityAreas(
      ${FILTER_VARIABLES}
    ) {
      ...PriorityAreasAnalyticsContentGroupFragment
    }
  }
  ${PAS_FRAGMENT}
`;

export function AnalyticsActivityGroups({
  title,
  description,
}: ChartDefaultProps) {
  const {
    dashboard: { colorPalette, priorityAreaName },
  } = useDashboardContext();

  const { t } = useTranslation();
  const [chartData, setChartData] = React.useState<{
    groupedByArea: any;
  }>({
    groupedByArea: null,
  });

  const { data, loading, error, subscribeToMore, refetch } = useQuery(
    PAS_QUERY,
    {
      variables: {
        paginationInterventionsLimit: -1,
        paginationPriorityAreasLimit: -1,
      },
      fetchPolicy: 'cache-and-network',
    }
  );

  // ------------------------------------------------------- useEffects ----------------------------------------------------------------------------------
  // setChartData
  React.useEffect(() => {
    if (data && data.priorityAreas?.items) {
      const consolidatedPAStatuses = { byArea: {}, byLead: {} } as any;

      // byArea
      data.priorityAreas?.items?.forEach((pa: any) => {
        if (!consolidatedPAStatuses.byArea[pa.name])
          consolidatedPAStatuses.byArea[pa.name] = {} as any;
        pa.content?.forEach((content: any) => {
          if (!content.group) {
            if (!consolidatedPAStatuses.byArea[pa.name].Ungrouped)
              consolidatedPAStatuses.byArea[pa.name].Ungrouped = 0;
            consolidatedPAStatuses.byArea[pa.name].Ungrouped += 1;
          } else {
            if (!consolidatedPAStatuses.byArea[pa.name][content.group.title])
              consolidatedPAStatuses.byArea[pa.name][content.group.title] = 0;
            consolidatedPAStatuses.byArea[pa.name][content.group.title] += 1;
          }
        });
        consolidatedPAStatuses.byArea[pa.name].stackTotal =
          pa.content?.length || 0;
      });

      let ungroupedExists = false;

      const groups: any[] = data.priorityAreas?.items.reduce(
        (acc: any, pa: any) => {
          pa.content?.forEach((content: any) => {
            if (content.group) {
              if (!acc.find((grp: any) => grp.title === content.group?.title)) {
                acc.push({
                  color: getRandomColor(),
                  title: content.group?.title,
                });
              }
            } else {
              ungroupedExists = true;
            }
          });
          return acc;
        },
        []
      );

      // add unknown status where interventions have no status
      if (ungroupedExists) {
        groups.push({ title: 'Ungrouped', color: 'darkgrey' });
      }

      const stackTotal = Object.keys(consolidatedPAStatuses.byArea).map(
        (areaNameKey) => consolidatedPAStatuses.byArea[areaNameKey].stackTotal
      );

      const byAreaDatasets = Array.from(
        new Set(groups.map((grp: any) => grp.title))
      ).map((group: any, index) => {
        const groupName =
          groups.find((grp: any) => grp.title === group).title || 'Ungrouped';
        const label = groupName === 'Ungrouped' ? t('Ungrouped') : groupName;
        return {
          label,
          data: data.priorityAreas.items.map((pa: any) => {
            return (
              consolidatedPAStatuses.byArea[pa.name][group || 'Ungrouped'] || 0
            );
          }),
          backgroundColor: groups[index].color,
          categoryPercentage: 0.75,
          barPercentage: 0.75,
          stackTotal,
        } as ChartDataset & {
          stackTotal: number[];
        };
      });

      const getOptions = (axisLabel: string) =>
        ({
          plugins: {
            title: {
              display: true,
              text: title,
            },
            legend: {
              position: 'bottom' as const,
            },
            datalabels: {
              color: colorPalette.secondary.textColor,
              formatter(value, context) {
                if (value === 0) return '';
                const theDataset = context.dataset as ChartDataset & {
                  stackTotal: number[];
                };
                const total = theDataset.stackTotal?.at(context.dataIndex) || 0;
                if (total === 0) return '';
                return `${Math.floor((value / total) * 100)}%`;
              },
              labels: {
                title: {
                  font: {
                    weight: 'bold',
                  },
                },
                value: {
                  color: colorPalette.secondary.textColor,
                },
              },
            },
          },
          responsive: true,
          scales: {
            x: {
              stacked: true,
              title: {
                display: true,
                text: axisLabel,
              },
            },
            y: {
              stacked: true,
            },
          },
        } as ChartOptions);

      setChartData((old: any) => {
        return {
          ...old,
          groupedByArea: {
            data: {
              labels: data.priorityAreas.items.map((paItem: any) =>
                truncateChartLabel(
                  `${paItem?.reference}. ${paItem?.name}`,
                  data.priorityAreas?.items?.length < 5 ? 18 : 10
                )
              ),
              datasets: byAreaDatasets,
            } as ChartData,
            options: getOptions(priorityAreaName),
          },
        };
      });
    }
  }, [data, t, title, colorPalette, priorityAreaName]);

  // refetch
  React.useEffect(() => {
    if (!data && !loading && !error) refetch();
  }, [data, loading, error, refetch]);

  // -------------------------------------------------------- JSX -----------------------------------------------------------------------------------------
  return (
    <ErrorBoundary FallbackComponent={ReactErrorComponent}>
      {chartData.groupedByArea && !loading ? (
        <Box
          sx={{
            m: 2,
            minHeight: '250px',
            maxHeight: '700px',
          }}
          component="div"
          title={`${title} - ${description}`}
        >
          <Bar
            options={chartData.groupedByArea?.options}
            data={chartData.groupedByArea?.data}
            plugins={[ChartDataLabels]}
          />
        </Box>
      ) : (
        <Skeleton variant="rectangular" height={250} />
      )}

      {/* subscribe to updates */}
      {!!data && (
        <SubscribeToMoreComponent
          subscribeToMore={subscribeToMore}
          document={PAS_SUBSCRIPTION}
          variables={{
            paginationInterventionsLimit: -1,
            paginationPriorityAreasLimit: -1,
          }}
        />
      )}
    </ErrorBoundary>
  );
}
