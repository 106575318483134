import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';

import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import LoadingButton from '@mui/lab/LoadingButton';

import { useDashboardContext } from '../../../_lib/context/dashboard-context';
import { logError } from '../../../_lib/error';

import { CONTENT_BLOCKER_CREATE } from '../../../_lib/graphql/mutations';
import {
  CONTENT_QUERY,
  CONTENT_BLOCKERS_QUERY,
} from '../../../_lib/graphql/queries';

export function ContentDialogBlockersAdd({ contentId }: any) {
  const { t } = useTranslation();

  const { setSnackbarOpen, setSnackbarIsError } = useDashboardContext();

  const [isEdit, setIsEdit] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState('');

  const [createMutation] = useMutation(CONTENT_BLOCKER_CREATE, {
    update(cache, { data: { contentBlockerCreate } }) {
      cache.writeQuery({
        query: CONTENT_QUERY,
        data: { content: contentBlockerCreate.content },
        variables: { contentId },
      });

      cache.writeQuery({
        query: CONTENT_BLOCKERS_QUERY,
        data: { contentBlockers: contentBlockerCreate.contentBlockers },
        variables: { contentId },
      });
    },
  });

  const handleEdit = () => {
    setError('');
    setIsEdit(true);
  };

  const handleCancel = () => {
    setIsEdit(false);
  };

  const handleAdd = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    const data = new FormData(event.currentTarget);
    const text = data.get('text');
    createMutation({
      variables: { text, contentId },
    })
      .then(() => {
        setIsEdit(false);
      })
      .catch((e) => {
        setError(`${t('Something went wrong')}`);
        logError(e);
        setSnackbarIsError(true);
        setSnackbarOpen(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Box sx={{ mt: 0.7, mb: 1.5 }}>
      {!isEdit ? (
        <Button
          variant="contained"
          disableElevation
          fullWidth
          startIcon={<AddIcon />}
          sx={{
            textTransform: 'none',
            justifyContent: 'flex-start',
            bgcolor: 'grey.300',
            color: 'grey.700',
            ':hover': {
              bgcolor: 'grey.400',
              color: 'grey.700',
            },
            px: 1,
          }}
          onClick={handleEdit}
        >
          <span
            style={{
              minWidth: 0,
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {t('Add blocker or impediment')}
          </span>
        </Button>
      ) : (
        <Button
          component="div"
          variant="contained"
          disableElevation
          disableFocusRipple
          disableRipple
          fullWidth
          startIcon={<AddIcon sx={{ my: '5px' }} />}
          sx={{
            textTransform: 'none',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            cursor: 'default',
            bgcolor: 'grey.300',
            color: 'grey.700',
            ':hover': {
              bgcolor: 'grey.300',
              cursor: 'default',
            },
            px: 1,
          }}
        >
          <Box component="form" onSubmit={handleAdd} sx={{ flexGrow: 1 }}>
            <Box sx={{ mb: 1 }}>
              <TextField
                name="text"
                size="small"
                fullWidth
                sx={{
                  '& legend': {
                    width: 0,
                  },
                  '.MuiInputBase-root': {
                    backgroundColor: 'white',
                  },
                }}
                required
                error={!!error}
                helperText={!!error && error}
                disabled={loading}
                multiline
              />
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'end' }}>
              <Button
                size="small"
                onClick={handleCancel}
                sx={{ textTransform: 'none', padding: '2px 6px' }}
                disabled={loading}
              >
                {t('Cancel')}
              </Button>
              <LoadingButton
                size="small"
                variant="contained"
                disableElevation
                sx={{ ml: 1, textTransform: 'none', padding: '2px 6px' }}
                type="submit"
                loading={loading}
              >
                {t('Add')}
              </LoadingButton>
            </Box>
          </Box>
        </Button>
      )}
    </Box>
  );
}
