import React from 'react';
import { isEqual } from 'lodash';
import { useMutation, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import clsx from 'clsx';

import { Theme, useTheme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import { TransitionProps } from '@mui/material/transitions';
import useMediaQuery from '@mui/material/useMediaQuery';

import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import Slide from '@mui/material/Slide';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import LinkOffIcon from '@mui/icons-material/LinkOff';

import { Markdown } from '../../components/markdown';

import { DashboardContext } from '../../_lib/context/dashboard-context';
import { ContentDialogContext } from '../../_lib/context/content-dialog-context';
import { logError } from '../../_lib/error';

import { CONTENT_QUERY } from '../../_lib/graphql/queries';
import { CONTENT_SUBSCRIPTION } from '../../_lib/graphql/subscriptions';
import { CONTENT_UPDATES_MARK_VIEWED } from '../../_lib/graphql/mutations';

import { DialogTitle } from '../../DialogTitle/dialog-title';

import { MarkContentAsCompleted } from './actions/complete';
import { SuggestContentCompleted } from './actions/suggest-completed';
import { ConvertIssueToActivity } from './actions/convert-issue-to-activity';
import { ContentDialogActionRequestExtension } from './actions/request-extension';
import { ChangeContent } from '../../actions/change-content';

import { ContentDialogContentUpdateEdit } from './content-update/edit';
import { ContentDialogContentUpdate } from './content-update';

import { ContentDialogPercentageDone } from './percentage';
import { ContentDialogDatesInput } from './dates-input';
import { ContentDialogDates } from './dates';
import { ContentDialogSectionTitle } from './section-title';
import { ContentDialogTitle } from './title';
import { ContentDialogBlockers } from './blockers';
import { ContentDialogMembers } from './members';
import { ContentDialogControls } from './controls';
import { ContentDialogAlert } from './alert';
import { ContentDialogActionsArchive } from './actions/archive';
import { ContentDialogActionsState } from './actions/state';
import { ContentDialogActionsDelete } from './actions/delete';
import { ReactErrorComponent } from '../../_lib/react-error';
import { useOnlineStatus } from '../../_lib/offline/use-online-status';
import { ScreenInfoOffline } from '../../_screens/_info/offline';
import {
  SubscribeToMore,
  SubscribeToMoreComponent,
} from '../../components/subscribe-to-more';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    leftPadding: {
      [theme.breakpoints.up('sm')]: {
        paddingLeft: '80px !important',
      },
    },
    dialogContent: {
      padding: theme.spacing(3),
    },
    dialogContentGridContainer: {
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column-reverse',
      },
    },
  })
);

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export function ContentDialog({
  showContentDialog,
  setShowContentDialog,
}: any) {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();

  const isOnline = useOnlineStatus();

  const {
    user,
    dashboard: { enableActivityPercentage, enableActivityMultipleAssignees },
  } = React.useContext(DashboardContext);

  const { content, setContent, contentId } =
    React.useContext(ContentDialogContext);

  const [loading, setLoading] = React.useState(true);
  const [showError, setShowError] = React.useState(false);

  // -----------------------------------------------------------------------------------------

  const {
    loading: loadingContent,
    data: dataContent,
    error: errorContent,
    refetch: refetchContent,
    subscribeToMore: subscribeToMoreContent,
  } = useQuery(CONTENT_QUERY, {
    variables: {
      contentId,
    },
  });

  const [markUpdatesAsViewedMutation] = useMutation(
    CONTENT_UPDATES_MARK_VIEWED
  );
  const [newUpdates, setNewUpdates] = React.useState(null) as any;

  // ---------------------------------------------------------------------------------------------------------
  // ---------------------------------------------------------------------------------------------------------
  // handlers
  // ---------------------------------------------------------------------------------------------------------

  const handleCloseDialog = () => {
    const params = new URLSearchParams(location.search);
    params.delete('content');
    history.push(
      `${location.pathname}${params.toString() ? `?${params.toString()}` : ''}`
    );
    setShowContentDialog(false);
  };

  // ---------------------------------------------------------------------------------------------------------
  // ---------------------------------------------------------------------------------------------------------
  // effects
  // ---------------------------------------------------------------------------------------------------------

  React.useEffect(() => {
    setLoading(loadingContent);
  }, [loadingContent]);

  React.useEffect(() => {
    if (errorContent) {
      setShowError(true);
    }
  }, [errorContent]);

  // refetch if not loading and not data
  React.useEffect(() => {
    if (!loadingContent && !dataContent && !errorContent) {
      refetchContent();
    }
  }, [loadingContent, dataContent, refetchContent, errorContent]);

  // set content on fetch

  React.useEffect(() => {
    if (dataContent?.content && !errorContent) {
      if (dataContent.content) {
        const newContent = dataContent?.content;
        if (!isEqual(newContent, content)) {
          setContent(newContent);
        }
      } else {
        setShowError(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataContent, errorContent]);

  // mark updates as viewed

  React.useEffect(() => {
    if (content) {
      const contentUpdatesNew = content.updates
        .filter((u: any) => u.isNew)
        .map((u: any) => u.id);
      if (!isEqual(contentUpdatesNew, newUpdates)) {
        setNewUpdates(contentUpdatesNew);

        if (contentUpdatesNew.length > 0) {
          markUpdatesAsViewedMutation({
            variables: { updates: contentUpdatesNew },
          }).catch((err) => logError(err));
        }
      }
    }
  }, [content, newUpdates, markUpdatesAsViewedMutation]);

  // ---------------------------------------------------------------------------------------------------------

  const classes = useStyles({ type: !!content && content.type });
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // ---------------------------------------------------------------------------------------------------------

  return (
    <Dialog
      fullWidth
      maxWidth={showError ? 'xs' : 'md'}
      className={`${content?.type}-modal`}
      open={showContentDialog}
      fullScreen={isMobile}
      TransitionComponent={isMobile ? Transition : undefined}
    >
      {/* error */}
      {showError && (
        <>
          <DialogTitle onClose={() => handleCloseDialog()}>
            {t('Error')}
          </DialogTitle>
          <DialogContent sx={{ pt: 2, pb: 3 }}>
            {!isOnline ? (
              <ScreenInfoOffline />
            ) : (
              <Alert
                severity="error"
                sx={{
                  '& .MuiAlert-message': {
                    minWidth: 0,
                  },
                }}
                icon={<LinkOffIcon />}
              >
                <AlertTitle>{t('Something went wrong')}</AlertTitle>
                {t(
                  'Either the content does not exist or you do not have permission to view it.'
                )}
                <Typography
                  variant="body2"
                  sx={{
                    mt: 1,
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                  }}
                >
                  <a href={window.location.href}>{window.location.href}</a>
                </Typography>
              </Alert>
            )}
          </DialogContent>
        </>
      )}

      {/* title */}
      {loading && (
        <DialogTitle
          className={classes.leftPadding}
          onClose={() => handleCloseDialog()}
        >
          <Skeleton
            variant="rectangular"
            sx={{ width: '300px', maxWidth: '100%' }}
          />
        </DialogTitle>
      )}
      {!loading && !showError && (
        <ContentDialogTitle
          classes={classes}
          handleCloseDialog={handleCloseDialog}
        />
      )}

      {/* --------------------------------------------------------------------------------------------------------- */}
      {/* --------------------------------------------------------------------------------------------------------- */}
      {/* content */}
      {/* --------------------------------------------------------------------------------------------------------- */}

      {/* loading */}
      {loading && (
        <DialogContent
          sx={{ pt: 2 }}
          className={clsx(classes.dialogContent, classes.leftPadding)}
          dividers
        >
          <Skeleton variant="rectangular" width="100%" height={300} />
        </DialogContent>
      )}

      {/* loaded fine */}
      {!loading && !showError && !!content && (
        <>
          <DialogContent
            sx={{ pt: 2 }}
            className={clsx(classes.dialogContent, classes.leftPadding)}
            dividers
          >
            <ErrorBoundary FallbackComponent={ReactErrorComponent}>
              <Grid
                container
                spacing={3}
                className={classes.dialogContentGridContainer}
              >
                <Grid item sm={9}>
                  {/* --------------------------------------------------------------------------------------------------------- */}
                  {/* CONTENT */}
                  {/* --------------------------------------------------------------------------------------------------------- */}

                  {/* TODO: Add breakpoint d-none for xs */}
                  {/* TODO: Add actions menu for xs */}

                  {/* content controls */}
                  <ContentDialogControls />

                  {/* assignee */}
                  {(enableActivityMultipleAssignees
                    ? !!content.assignees && !!content.assignees.length
                    : !!content.assignee) && <ContentDialogMembers />}

                  {/* state */}
                  {(content.type === 'activity' ||
                    content.type === 'milestone') && (
                    <ContentDialogActionsState />
                  )}

                  {/* percentage done */}
                  {enableActivityPercentage &&
                    (content.type === 'activity' ||
                      content.type === 'milestone') && (
                      <ContentDialogPercentageDone />
                    )}

                  {/* dates */}
                  <ContentDialogDates />

                  {/* --------------------------------------------------------------------------------------------------------- */}
                  {/* blockers */}
                  {/* --------------------------------------------------------------------------------------------------------- */}

                  <ContentDialogBlockers />

                  {/* --------------------------------------------------------------------------------------------------------- */}

                  {/* alert */}
                  <ContentDialogAlert />

                  {/* description */}
                  <ContentDialogSectionTitle main>
                    {t('Description')}
                  </ContentDialogSectionTitle>
                  <Markdown>{content.description || '--'}</Markdown>

                  <Divider sx={{ my: 4 }} />

                  {/* --------------------------------------------------------------------------------------------------------- */}
                  {/* updates */}
                  {/* --------------------------------------------------------------------------------------------------------- */}

                  {/* new update text area */}
                  <ContentDialogContentUpdateEdit />

                  {/* updates feed/stream */}
                  {content.updates.map((update: any, i: number) => (
                    <Box
                      key={update.id}
                      style={{ marginTop: `${i === 0 ? '24px' : '0px'}` }}
                    >
                      <ContentDialogContentUpdate updateId={update.id} />
                    </Box>
                  ))}
                </Grid>

                <Grid item sm={3}>
                  {/* --------------------------------------------------------------------------------------------------------- */}
                  {/* ACTIONS */}
                  {/* --------------------------------------------------------------------------------------------------------- */}

                  <Stack
                    spacing={2}
                    sx={{
                      '& .MuiButton-root': {
                        justifyContent: 'flex-start',
                      },
                    }}
                  >
                    {/* edit the activity */}
                    {content.access === 'EDIT' && <ChangeContent />}

                    {/* issue: convert to activity */}
                    {content.access === 'EDIT' &&
                      content.type === 'issue' &&
                      !user.isRegular && <ConvertIssueToActivity />}

                    {/* deadline / start date activity buttons */}
                    {content.type !== 'issue' &&
                      content.status !== 'deleted' &&
                      !!content.deadline &&
                      !content.completionDate &&
                      ['EDIT', 'COMMENT'].includes(content.access) && (
                        <>
                          <Divider />
                          {content.access === 'EDIT' ? (
                            <MarkContentAsCompleted />
                          ) : (
                            <SuggestContentCompleted />
                          )}
                          {content.access === 'EDIT' ? (
                            <ContentDialogDatesInput
                              fullWidth
                              style={{ justifyContent: 'flex-start' }}
                            />
                          ) : (
                            <ContentDialogActionRequestExtension />
                          )}
                          <Divider />
                        </>
                      )}

                    {/* archive */}
                    {content.access === 'EDIT' && content.type !== 'issue' && (
                      <ContentDialogActionsArchive />
                    )}

                    {/* delete */}
                    {(content.access === 'EDIT' ||
                      content.status === 'deleted') && (
                      <ContentDialogActionsDelete />
                    )}
                  </Stack>
                </Grid>
              </Grid>
            </ErrorBoundary>
          </DialogContent>

          {/* --------------------------------------------------------------------------------------------------------- */}
          {/* subscriptions */}
          <SubscribeToMoreComponent
            subscribeToMore={subscribeToMoreContent}
            document={CONTENT_SUBSCRIPTION}
            variables={{
              contentId,
            }}
          />
        </>
      )}
    </Dialog>
  );
}
