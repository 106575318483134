import React from 'react';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';

import { useDashboardContext } from '../../../_lib/context/dashboard-context';
import { useContentDialogContext } from '../../../_lib/context/content-dialog-context';

import { ContentDialogSectionTitle } from '../section-title';
import { ContentDialogBlockersList } from './list';
import { ContentDialogBlockersAdd } from './add';
import { ContentDialogBlockersBlocks } from './blocks';

export function ContentDialogBlockers() {
  const { t } = useTranslation();

  const {
    dashboard: { enableActivityBlockers },
  } = useDashboardContext();

  const { content } = useContentDialogContext();

  return (
    (enableActivityBlockers && content && (
      <Box sx={{ mb: 2 }}>
        <ContentDialogSectionTitle>
          {t('Blockers')} / {t('Dependants')} / {t('Tasks')}
        </ContentDialogSectionTitle>
        <Box sx={{ maxWidth: '400px' }}>
          <ContentDialogBlockersList contentId={content.id} />
          <ContentDialogBlockersAdd contentId={content.id} />
          <ContentDialogBlockersBlocks />
        </Box>
      </Box>
    )) ||
    null
  );
}
