import React from 'react';

import Stack from '@mui/material/Stack';

import { useDashboardContext } from '../../../../_lib/context/dashboard-context';
import { NavbarAppBarSearchResultsAreas } from './areas';
import { SwitchDashboardDialog } from '../../../../components/switch-dashboard/dialog';

export function NavbarAppBarSearchResults({
  search,
  setSearch,
  setDialogOpen,
}: any) {
  const { dashboard } = useDashboardContext();

  const [switchDashboardDialogOpen, setSwitchDashboardDialogOpen] =
    React.useState(false);
  const [switchDashboardId, setSwitchDashboardId] = React.useState<any>('');

  // -------------------------------------------------------------------------------------------------
  // -------------------------------------------------------------------------------------------------
  // handlers
  // -------------------------------------------------------------------------------------------------

  const handleNavigate = React.useCallback(
    (area: any) => {
      if (
        dashboard.mode === 'SUPRANATIONAL' &&
        dashboard.enableDodPaSwitch &&
        area.__typename === 'PriorityAreaType'
      ) {
        setSwitchDashboardId(area.dashboardOfDashboards?.dashboard?.id);
        setSwitchDashboardDialogOpen(true);
      } else {
        setSearch('');
        setDialogOpen(false);
      }
    },
    [dashboard.enableDodPaSwitch, dashboard.mode, setDialogOpen, setSearch]
  );

  // -------------------------------------------------------------------------------------------------

  return (
    <>
      {/* switch dashboard dialog */}
      <SwitchDashboardDialog
        open={switchDashboardDialogOpen}
        setOpen={setSwitchDashboardDialogOpen}
        dashboardId={switchDashboardId}
      />

      {/* results */}
      <Stack spacing={1}>
        {/* priority areas */}
        <NavbarAppBarSearchResultsAreas
          isPriorityArea
          search={search}
          handleNavigate={handleNavigate}
        />

        {/* interventions */}
        {dashboard.mode !== 'SUPRANATIONAL' && (
          <NavbarAppBarSearchResultsAreas
            search={search}
            handleNavigate={handleNavigate}
          />
        )}
      </Stack>
    </>
  );
}
