import React from 'react';
import { useTranslation } from 'react-i18next';

import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';

import { useSettingsAdvancedContext } from './dashboard-form-advanced-context';

export function AdvancedSettingsFilter({ setFilteredSettings }: any) {
  const { t } = useTranslation();

  const { settings } = useSettingsAdvancedContext();

  const [searchTerm, setSearchTerm] = React.useState('');

  // ---------------------------------------------------------------------------------------------------------
  // ---------------------------------------------------------------------------------------------------------
  // effects
  // ---------------------------------------------------------------------------------------------------------

  React.useEffect(() => {
    if (!searchTerm) {
      setFilteredSettings(settings);
    } else {
      const filtered = {} as any;

      Object.keys(settings).forEach((key) => {
        const options = settings[key].options.filter(
          ({ title, label, desc }: any) => {
            const valuesToFilter = { title, label, desc };
            const valuesString = Object.values(valuesToFilter)
              .join(' ')
              .toLowerCase();
            if (valuesString.indexOf(searchTerm.toLowerCase()) > -1) {
              return true;
            }
            return false;
          }
        );
        if (options.length) {
          filtered[key] = { ...settings[key], options };
        }
      });

      setFilteredSettings(filtered);
    }
  }, [searchTerm, settings, setFilteredSettings, t]);

  // ---------------------------------------------------------------------------------------------------------

  return (
    <TextField
      id="settings-search"
      label={t('Search settings')}
      type="search"
      size="small"
      sx={{ width: '300px' }}
      placeholder={`${t('Type to search...')}`}
      value={searchTerm}
      onChange={(e) => setSearchTerm(e.target.value)}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon fontSize="small" />
          </InputAdornment>
        ),
        autoFocus: true,
      }}
    />
  );
}
