import Box from '@mui/material/Box';

import grey from '@mui/material/colors/grey';

import { ScrollToTop } from './scroll-to-top';
import { Navbar } from '../navbar';
import { Snackbar } from '../components/snackbar';

import { useDashboardContext } from '../_lib/context/dashboard-context';

export function BaseLayout({ children }: any) {
  const {
    snackbarOpen,
    setSnackbarOpen,
    snackbarIsDelete,
    setSnackbarIsDelete,
    snackbarIsError,
    setSnackbarIsError,
    snackbarMessage,
    setSnackbarMessage,
    recentlyDeletedProps,
    setRecentlyDeletedProps,
  } = useDashboardContext();

  return (
    <>
      <style>{`html, body { background-color: ${grey[200]}; }`}</style>
      <ScrollToTop>
        {/* navbar / appbar */}
        <Navbar />

        <Box component="main">{children}</Box>
      </ScrollToTop>

      {/* Snackbar */}
      <Snackbar
        open={snackbarOpen}
        setOpen={setSnackbarOpen}
        isDelete={snackbarIsDelete}
        setIsDelete={setSnackbarIsDelete}
        isError={snackbarIsError}
        setIsError={setSnackbarIsError}
        message={snackbarMessage}
        setMessage={setSnackbarMessage}
        recentlyDeletedProps={recentlyDeletedProps}
        setRecentlyDeletedProps={setRecentlyDeletedProps}
      />
    </>
  );
}
