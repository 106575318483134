import { useTranslation } from 'react-i18next';

import { alpha, styled } from '@mui/material/styles';

import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { useOnlineStatus } from '../../../_lib/offline/use-online-status';
import { AppBarFilterAndOr } from './and-or';

const DarkTextField: typeof TextField | any = styled(TextField)(
  ({ theme }) => ({
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    'label, legend, & .MuiInputLabel-root, & .MuiInputLabel-root.Mui-focused, & .MuiOutlinedInput-root, & .MuiButtonBase-root':
      {
        color: theme.palette.common.white,
      },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 0,
    },
  })
);

export function AppBarFilter({
  isDark,
  label,
  options,
  selectedOptions,
  onChange,
  loading,
}: any) {
  const { t } = useTranslation();
  const isOnline = useOnlineStatus();

  // -----------------------------------------------------------------------------------------------------------

  if (!options || options.length === 0) return null;

  // -----------------------------------------------------------------------------------------------------------

  return (
    <Box
      sx={{
        display: 'flex',
        maxWidth: '100%',
        width: '270px',
        alignItems: 'center',
        columnGap: '7px',
      }}
    >
      <Autocomplete
        multiple
        disabled={!isOnline || loading}
        options={options}
        getOptionLabel={(option: any) => `${option.typeLabel}: ${option.name}`}
        value={selectedOptions}
        isOptionEqualToValue={(option: any, value: any) =>
          option.id === value.id && option.type === value.type
        }
        filterSelectedOptions
        size="small"
        fullWidth
        limitTags={1}
        renderTags={(value, getTagProps) =>
          value.map((option: any, index) => (
            <Chip
              variant="outlined"
              label={
                <>
                  <small
                    style={{
                      opacity: 0.7,
                      textTransform: 'uppercase',
                    }}
                  >
                    {option.typeLabel}:
                  </small>{' '}
                  {option.name}
                </>
              }
              size="small"
              {...getTagProps({ index })}
            />
          ))
        }
        renderInput={(params) =>
          isDark ? (
            <DarkTextField {...params} label={label} placeholder={label} />
          ) : (
            <TextField {...params} label={label} placeholder={label} />
          )
        }
        renderOption={(props, option: any) => (
          <li {...props}>
            <Box>
              <Typography
                variant="caption"
                sx={{
                  opacity: 0.7,
                  textTransform: 'uppercase',
                }}
              >
                {option.typeLabel || t('-')}
              </Typography>
              <Typography variant="body2">
                {option.name || t('Uknown')}
              </Typography>
            </Box>
          </li>
        )}
        onChange={onChange}
        sx={{
          '& .MuiAutocomplete-tag': {
            maxWidth: isDark && 'calc(100% - 70px)',
          },
        }}
      />

      {/* and or */}
      <AppBarFilterAndOr />
    </Box>
  );
}
