import { useTranslation } from 'react-i18next';
import { TextField } from '@mui/material';

export function DashboardDetailsFormName({
  disabled,
  errors,
  newDashboard,
  setNewDashboard,
}: any) {
  const { t } = useTranslation();
  return (
    <TextField
      id="name-input"
      label={t('Name')}
      placeholder={`${t('e.g. Ministry of Finance Project Tracker')}`}
      value={newDashboard.name || ''}
      onChange={(e) =>
        setNewDashboard({ ...newDashboard, name: e.target.value })
      }
      disabled={disabled}
      error={!!errors.name}
      helperText={errors.name}
      variant="outlined"
      fullWidth
    />
  );
}
