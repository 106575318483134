import React from 'react';

import { ErrorBoundary } from 'react-error-boundary';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';

import enLocale from 'date-fns/locale/en-GB';

import LinearProgress from '@mui/material/LinearProgress';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';

import '../language/i18n';

import { ReactErrorComponent } from '../react-error';

import { ProvideOnlineStatus } from '../offline/use-online-status';

import { ThemeProviderDefault } from './theme/default';
import { ProvideApollo } from '../../_utils/hooks/use-apollo';
import { ProvideAuth } from '../../_utils/hooks/use-auth';
import { FirebaseProvider } from './firebase';

import { PortalContainerProvider } from './portal';

import { AppContextProvider } from '../context/app-context';

import { OfllineUpdateAvailable } from '../offline/update-available';
import { OfflineSnackbar } from '../offline/snackbar';

export function CommonProviders({ children }: any) {
  return (
    <React.Suspense fallback={<LinearProgress sx={{ width: '100%' }} />}>
      <HelmetProvider>
        <BrowserRouter>
          <PortalContainerProvider>
            <ProvideOnlineStatus>
              <FirebaseProvider>
                <ErrorBoundary FallbackComponent={ReactErrorComponent}>
                  <ThemeProviderDefault>
                    <LocalizationProvider
                      dateAdapter={AdapterDateFns}
                      adapterLocale={enLocale}
                    >
                      <ProvideApollo>
                        <ProvideAuth>
                          <AppContextProvider>
                            {children}
                            <OfllineUpdateAvailable />
                            <OfflineSnackbar />
                          </AppContextProvider>
                        </ProvideAuth>
                      </ProvideApollo>
                    </LocalizationProvider>
                  </ThemeProviderDefault>
                </ErrorBoundary>
              </FirebaseProvider>
            </ProvideOnlineStatus>
          </PortalContainerProvider>
        </BrowserRouter>
      </HelmetProvider>
    </React.Suspense>
  );
}
