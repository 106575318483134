import React from 'react';
import { useTranslation } from 'react-i18next';
import { isEqual, uniqWith } from 'lodash';

import { fr, enGB } from 'date-fns/locale';

import 'chart.js/auto';
import 'chartjs-adapter-date-fns';
import {
  Chart as ChartJS,
  ChartType,
  ChartData,
  ChartOptions,
  ChartDataset,
} from 'chart.js';
import ChartAnnotation from 'chartjs-plugin-annotation';

import { Line } from 'react-chartjs-2';

import Box, { BoxProps } from '@mui/material/Box';

import { withCommas } from '../../_utils/general-utils';
import { useDashboardContext } from '../../_lib/context/dashboard-context';
import { calculateTrendline } from '../../_utils/chart-trendline';

ChartJS.register([ChartAnnotation]);

export function AreaTargetsLineGraph({
  target,
  ...other
}: { target: any } & BoxProps) {
  const { t } = useTranslation();
  const {
    dashboard: {
      language,
      colorPalette,
      enableTargetFinancedAmount,
      enableTargetTrendline,
    },
  } = useDashboardContext();

  const { sx, ...rest } = other;

  // data
  const data = React.useMemo<ChartData<'line'>>(() => {
    const targetUpdates = [...target.updates]
      .sort(
        (a: any, b: any) =>
          new Date(a.date).getTime() - new Date(b.date).getTime()
      )
      .map((update: any) => ({
        x: update.date,
        y: update.value,
      }));

    const targetIntervals =
      [...target.targetIntervals]
        .sort(
          (a: any, b: any) =>
            new Date(a.date).getTime() - new Date(b.date).getTime()
        )
        .map((interval: any) => ({
          x: interval.date,
          y: interval.amount,
        })) || [];

    const targetUpdatesDataset: ChartDataset<'line'> = {
      label: `${t('Update')}`,
      data: targetUpdates,
      backgroundColor: colorPalette?.primary?.bgcolor || 'rgb(0,123,125)',
      pointBorderColor: colorPalette?.secondary?.bgcolor || 'rgb(48,237,9)',
      fill: true,
      borderWidth: 1,
    };

    const targetIntervalsDataset: ChartDataset<'line'> = {
      label: `${t('Target')}`,
      data: targetIntervals,
      borderColor: colorPalette?.secondary?.bgcolor || 'rgb(48,237,9)',
      borderWidth: 2,
      borderDash: [1, 1],
    };

    return {
      datasets: [
        targetUpdatesDataset,
        ...(targetIntervals.length > 2 ? [targetIntervalsDataset] : []),
      ],
    } as ChartData<'line'>;
  }, [
    target.updates,
    target.targetIntervals,
    t,
    colorPalette?.primary?.bgcolor,
    colorPalette?.secondary?.bgcolor,
  ]);

  // chart options
  const chartOptions = React.useMemo<ChartOptions<'line'>>(() => {
    const {
      startAmount,
      startedOn,
      targetAmount,
      targetFinancedAmount,
      deadline,
    } = target;

    const suggestedMaxYOptions = [targetAmount, startAmount];
    if (enableTargetFinancedAmount) {
      suggestedMaxYOptions.push(targetFinancedAmount);
    }
    const suggestedMaxY = Math.max(...suggestedMaxYOptions);

    const annotations = [] as any;

    // trendline
    if (
      enableTargetTrendline &&
      uniqWith(data.datasets[0].data, isEqual).length > 1
    ) {
      const trend = calculateTrendline(
        data.datasets[0].data.map((u: any) => ({
          x: new Date(u.x).getTime(),
          y: u.y,
        })),
        'x',
        'y'
      );

      annotations.push({
        type: 'line' as ChartType,
        yMin: trend.calcY(new Date(startedOn).getTime()),
        yMax: trend.calcY(new Date(deadline).getTime()),
        borderColor: colorPalette?.secondary?.bgcolor || 'rgb(48,237,9)',
        borderWidth: 2,
        borderDash: [3, 4],
      });
    }

    // target financed amount
    if (enableTargetFinancedAmount) {
      annotations.push({
        type: 'line' as ChartType,
        yMin: targetFinancedAmount,
        yMax: targetFinancedAmount,
        borderColor: colorPalette?.secondary?.bgcolor || 'rgb(75, 192, 192)',
        borderWidth: 2,
        label: {
          enabled: true,
          content: `${t('Financed')}: ${withCommas(targetFinancedAmount)}`,
          position: 'end',
        },
      });
    }

    // target amount
    annotations.push({
      type: 'line' as ChartType,
      yMin: targetAmount,
      yMax: targetAmount,
      borderColor: colorPalette?.secondary?.bgcolor || 'rgb(75, 192, 192)',
      borderWidth: 3,
      label: {
        enabled: true,
        content: `${t('Target')}: ${withCommas(targetAmount)}`,
        position: enableTargetFinancedAmount ? 'start' : 'center',
      },
    });

    // deadline
    annotations.push({
      type: 'line' as ChartType,
      xMin: deadline,
      xMax: deadline,
      borderColor: '#e53935',
      borderWidth: 4,
      borderDash: [3, 4],
      label: {
        enabled: true,
        content: `${t('Deadline')}: ${deadline}`,
      },
    });

    const newChartOptions = {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        annotation: {
          annotations,
        },
      },
      scales: {
        y: {
          type: 'linear' as const,
          display: true,
          position: 'left' as const,
          suggestedMin: startAmount > targetAmount ? targetAmount : startAmount,
          suggestedMax: Math.round(suggestedMaxY * 1.1),
        },
        x: {
          type: 'time',
          time: {
            tooltipFormat: 'MMM dd, yyyy',
          },
          adapters: {
            date: language === 'fr' ? fr : enGB,
          },
          suggestedMin: startedOn,
          suggestedMax: deadline,
        },
      },
    } as any;

    return newChartOptions;
  }, [
    target,
    enableTargetFinancedAmount,
    enableTargetTrendline,
    colorPalette?.secondary?.bgcolor,
    t,
    language,
    data.datasets,
  ]);

  return (
    <Box sx={{ minHeight: '210px', ...sx }} {...rest}>
      <Line data={data} options={chartOptions} height="100%" />
    </Box>
  );
}
