import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useMutation } from '@apollo/client';

import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import DialogActions from '@mui/material/DialogActions';
import LoadingButton from '@mui/lab/LoadingButton';
import Paper from '@mui/material/Paper';

import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import RestoreFromTrashOutlinedIcon from '@mui/icons-material/RestoreFromTrashOutlined';

import { useDashboardContext } from '../../../../_lib/context/dashboard-context';

import { DialogTitle } from '../../../../DialogTitle/dialog-title';
import { CONTENT_DELETE } from '../../../../_lib/graphql/mutations';
import {
  ACTIVITY_TRACKER_QUERY,
  CONTENT_QUERY,
  INTERVENTION_QUERY,
  MY_CONTENT_QUERY,
  PRIORITY_AREA_TRACKER_QUERY,
} from '../../../../_lib/graphql/queries';
import { logError } from '../../../../_lib/error';

export function ContentDialogActionsDeleteDialog({
  open,
  setOpen,
  content,
}: any) {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const { interventionId, priorityAreaId } = useParams<any>();

  const {
    setSnackbarIsDelete,
    setSnackbarOpen,
    setSnackbarIsError,
    setRecentlyDeletedProps,
    navbarFilterVariables,
  } = useDashboardContext();

  const [loading, setLoading] = React.useState(false);

  const [deleteMutation] = useMutation(CONTENT_DELETE, {
    update: (cache, { data: { contentDelete } }) => {
      cache.writeQuery({
        query: CONTENT_QUERY,
        data: { content: contentDelete.content },
        variables: { id: content?.id },
      });

      try {
        if (contentDelete.ok) {
          // in intervention page
          if (interventionId) {
            const data: any = cache.readQuery({
              query: INTERVENTION_QUERY,
              variables: { interventionId },
            });

            cache.writeQuery({
              query: INTERVENTION_QUERY,
              variables: { interventionId },
              data: {
                intervention: {
                  ...data.intervention,
                  content: data.intervention.content.filter(
                    (c: any) => c.id !== content.id
                  ),
                },
              },
            });
          }

          // in priority area tracker page
          if (priorityAreaId && !interventionId) {
            const data: any = cache.readQuery({
              query: PRIORITY_AREA_TRACKER_QUERY,
              variables: {
                priorityAreaId,
                ...navbarFilterVariables,
              },
            });

            cache.writeQuery({
              query: PRIORITY_AREA_TRACKER_QUERY,
              variables: {
                priorityAreaId,
                ...navbarFilterVariables,
              },
              data: {
                priorityArea: {
                  ...data.priorityArea,
                  interventions: data.priorityArea?.interventions?.map(
                    (intv: any) => ({
                      ...intv,
                      content: intv.content.filter(
                        (c: any) => c.id !== content.id
                      ),
                    })
                  ),
                },
              },
            });
          }

          // activity tracker page
          if (window.location.pathname === t('/tracker')) {
            const data: any = cache.readQuery({
              query: ACTIVITY_TRACKER_QUERY,
            });

            cache.writeQuery({
              query: ACTIVITY_TRACKER_QUERY,
              data: {
                priorityAreas: {
                  items: data.priorityAreas.items.map((pa: any) => ({
                    ...pa,
                    interventions: pa.interventions.map((intv: any) => ({
                      ...intv,
                      content: intv.content.filter(
                        (c: any) => c.id !== content.id
                      ),
                    })),
                  })),
                },
              },
            });
          }

          // in my content page
          if (window.location.pathname === t('/my-content')) {
            const data: any = cache.readQuery({
              query: MY_CONTENT_QUERY,
            });

            cache.writeQuery({
              query: MY_CONTENT_QUERY,
              data: {
                myContent: data.myContent.filter(
                  (c: any) => c.id !== content.id
                ),
              },
            });
          }
        }
      } catch (e: any) {
        const error = new Error(
          `An erorr occured while updating cache after content deletion in tracker.\n 
          Info: contentId:${content?.id}. More: ${e.toString()}`
        );
        logError(error);
      }
    },
  });

  // -------------------------------------------------------------------------------------------------------------------
  // -------------------------------------------------------------------------------------------------------------------
  // handlers
  // -------------------------------------------------------------------------------------------------------------------

  const handleDelete = React.useCallback(() => {
    if (!content) return;
    setLoading(true);
    const contentStatus = content?.status;
    deleteMutation({
      variables: { id: content?.id, isRestore: contentStatus === 'deleted' },
    })
      .then(() => {
        setLoading(false);
        setOpen(false);

        if (contentStatus !== 'deleted') {
          setSnackbarIsDelete(true);
          setRecentlyDeletedProps({
            objectId: content?.id,
            objectName: 'content',
            object: content,
            successFunc: null,
          });
        }

        if (contentStatus !== 'deleted') {
          history.push(location.pathname);
        }
      })
      .catch((e) => {
        setLoading(false);
        setSnackbarIsError(true);
        logError(e);
      })
      .finally(() => setSnackbarOpen(true));
  }, [
    content,
    deleteMutation,
    history,
    location.pathname,
    setOpen,
    setSnackbarIsDelete,
    setSnackbarIsError,
    setSnackbarOpen,
    setRecentlyDeletedProps,
  ]);

  // -------------------------------------------------------------------------------------------------------------------

  if (!content) return null;

  return (
    <Dialog open={open} maxWidth="xs" fullWidth>
      <DialogTitle onClose={() => setOpen(false)}>
        {content.status !== 'deleted' ? (
          <DeleteForeverRoundedIcon sx={{ mr: 0.5 }} />
        ) : (
          <RestoreFromTrashOutlinedIcon sx={{ mr: 0.5 }} />
        )}
        {content.status !== 'deleted' ? t('Delete') : t('Restore')}
      </DialogTitle>
      <DialogContent>
        {content.status !== 'deleted' ? (
          <Alert severity="warning">
            {t(
              'Deleting this can only be undone immediately by clicking on undo in the success notification that will appear.'
            )}
          </Alert>
        ) : (
          <Typography>{t('Are you sure you want to restore this?')}</Typography>
        )}
        <Paper
          sx={{ px: 1.5, py: 1, mt: 2, bgcolor: 'grey.200' }}
          variant="outlined"
        >
          <Typography>
            <b>{t('Title')}:</b> {content.title}
          </Typography>
        </Paper>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={() => setOpen(false)}
          disabled={loading}
        >
          {t('Cancel')}
        </Button>
        <LoadingButton
          variant="contained"
          disableElevation
          loading={loading}
          onClick={handleDelete}
          color={content.status !== 'deleted' ? 'error' : 'success'}
        >
          {t('Confirm')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
