import React from 'react';
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import Stack from '@mui/material/Stack';
import LoadingButton from '@mui/lab/LoadingButton';

import { AREA_PERMISSIONS_QUERY } from '../../../_lib/graphql/queries';
import { AREA_PERMISSIONS_SUBSCRIPTION } from '../../../_lib/graphql/subscriptions';

import { MemberAutocomplete } from '../../member-autocomplete';
import { DialogTitle } from '../../../DialogTitle/dialog-title';
import { PlanningAreaPermissionsManagePermissionSelect } from './permission-select';
import { useDashboardContext } from '../../../_lib/context/dashboard-context';
import { logError } from '../../../_lib/error';
import { SubscribeToMoreComponent } from '../../subscribe-to-more';

export function AreaPermissionsManageDialog({
  open,
  setOpen,
  area,
  loading,
  setLoading,
  newMembers,
  setNewMembers,
  newPermission,
  setNewPermission,
  sendNotification,
  setSendNotification,
  handleShare,
  handleChange,
}: any) {
  const { t } = useTranslation();

  const { setSnackbarOpen, setSnackbarIsError } = useDashboardContext();

  const {
    loading: permsLoading,
    data: permsData,
    error: permsError,
    subscribeToMore: permsSubscribeToMore,
  } = useQuery(AREA_PERMISSIONS_QUERY, {
    variables: {
      areaType: area?.__typename,
      areaId: area?.id,
    },
  });

  // --------------------------------------------------------------------------------------------------------------------
  // --------------------------------------------------------------------------------------------------------------------
  // handlers
  // --------------------------------------------------------------------------------------------------------------------

  const handleClose = () => {
    setOpen(false);
    setLoading(false);
  };

  const isEditable = (areaPermission: any) => {
    return (
      !areaPermission.parent &&
      !areaPermission.isLead &&
      !areaPermission.isCoLead
    );
  };

  // --------------------------------------------------------------------------------------------------------------------
  // --------------------------------------------------------------------------------------------------------------------
  // effects
  // --------------------------------------------------------------------------------------------------------------------

  // remove duplicate items
  const areaPermissions = React.useMemo(() => {
    if (!permsData) return [];

    const newAreaPermissions: any[] = [];

    permsData.areaPermissions.forEach((areaPermission: any) => {
      if (
        !newAreaPermissions.find(
          (newAreaPermission) =>
            newAreaPermission.userSettings.id === areaPermission.userSettings.id
        )
      ) {
        newAreaPermissions.push(areaPermission);
      } else {
        // if already exists, check if permission is higher
        const existingAreaPermission = newAreaPermissions.find(
          (newAreaPermission) =>
            newAreaPermission.userSettings.id === areaPermission.userSettings.id
        );

        const PERMISSIONS: any = {
          VIEW: 1,
          COMMENT: 2,
          EDIT: 3,
        };

        if (
          existingAreaPermission &&
          PERMISSIONS[existingAreaPermission.permission] <
            PERMISSIONS[areaPermission.permission]
        ) {
          // if higher, replace
          newAreaPermissions[
            newAreaPermissions.indexOf(existingAreaPermission)
          ] = areaPermission;
        }
      }
    });

    return newAreaPermissions.sort((a, b) => (a.parent ? -1 : 1));
  }, [permsData]);

  // loading
  React.useEffect(() => {
    setLoading(permsLoading);
  }, [permsLoading, setLoading]);

  // error
  React.useEffect(() => {
    if (permsError) {
      logError(permsError);
      setSnackbarIsError(true);
      setSnackbarOpen(true);
    }
  }, [permsError, setSnackbarIsError, setSnackbarOpen]);

  // --------------------------------------------------------------------------------------------------------------------

  return (
    <>
      <Dialog open={open} maxWidth="xs" fullWidth>
        <DialogTitle onClose={handleClose}>
          {t('Share')}: &quot;{area?.reference}. {area?.name}&quot;
        </DialogTitle>

        <DialogContent sx={{ pb: 1.5 }}>
          <Stack
            sx={{ mt: 1, flexDirection: { xs: 'column', sm: 'row,' } }}
            spacing={1}
          >
            <Box sx={{ flexGrow: 1 }}>
              {/* Member Select */}
              <MemberAutocomplete
                label={t('Members')}
                placeholder={t('Add people')}
                multiple
                disabled={loading}
                value={newMembers}
                onChange={(event: any, value: any) => {
                  setNewMembers(value);
                }}
                filterAdmins
              />
            </Box>

            {/* Permission Select */}
            <Box>
              <PlanningAreaPermissionsManagePermissionSelect
                value={newPermission}
                onChange={(e: any) => setNewPermission(e.target.value)}
                disabled={loading}
              />
            </Box>
          </Stack>

          {/* main section */}
          {newMembers?.length === 0 ? (
            <>
              {/* IF NOT EDIT */}
              {/* Area leads */}
              {/* Users who have permission: Name + Email */}
              {/* User Actions: Select permission */}
              <List>
                {areaPermissions?.map((areaPermission: any) => (
                  <ListItem
                    key={areaPermission.id}
                    secondaryAction={
                      <PlanningAreaPermissionsManagePermissionSelect
                        value={areaPermission.permission}
                        onChange={(e: any) =>
                          handleChange(areaPermission.id, e.target.value)
                        }
                        disabled={!isEditable(areaPermission) || loading}
                        includeRemove
                      />
                    }
                    disablePadding
                  >
                    <ListItemButton
                      sx={{
                        py: 1,
                        '&.MuiListItemButton-root': {
                          paddingRight: '128px',
                          cursor: 'text',
                          minWidth: '0',
                          '& .MuiTypography-root': {
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                          },
                        },
                      }}
                      disableRipple
                      disabled={!isEditable(areaPermission)}
                    >
                      <ListItemText
                        primary={areaPermission.userSettings.name}
                        secondary={areaPermission.userSettings.user.email}
                      />
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            </>
          ) : (
            <>
              {/* IF EDIT / ADD */}
              {/* Notification */}
              {/* Message box if notification */}
              {/* Name of area in chip */}
              {/* Notification select
            <br />
            Message Box
            <br />
            Area nem */}
            </>
          )}
        </DialogContent>

        <DialogActions>
          {newMembers?.length === 0 ? (
            <LoadingButton loading={loading} onClick={() => setOpen(false)}>
              {t('Done')}
            </LoadingButton>
          ) : (
            <>
              <Button disabled={loading} onClick={() => setOpen(false)}>
                {t('Cancel')}
              </Button>
              <LoadingButton
                variant="contained"
                disableElevation
                onClick={handleShare}
                loading={loading}
              >
                {sendNotification ? t('Send') : t('Share')}
              </LoadingButton>
            </>
          )}
        </DialogActions>
      </Dialog>

      {/* subscriptions */}
      {!!permsData && (
        <SubscribeToMoreComponent
          subscribeToMore={permsSubscribeToMore}
          document={AREA_PERMISSIONS_SUBSCRIPTION}
          variables={{
            areaType: area?.__typename,
            areaId: area?.id,
          }}
        />
      )}
    </>
  );
}
