import { useTranslation } from 'react-i18next';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import CheckIcon from '@mui/icons-material/Check';

import LoadingButton from '@mui/lab/LoadingButton';

import { DialogTitle } from '../../../DialogTitle/dialog-title';

export function MemberRemoveConfirmDialog({
  userSetting,
  open,
  onClose,
  handleRemoveConfirmed,
  loading,
}: any) {
  const { t } = useTranslation();

  return (
    <Dialog open={open} fullWidth maxWidth="xs">
      <DialogTitle onClose={onClose}>{t('Remove Member')}?</DialogTitle>
      <DialogContent dividers>
        <Typography variant="body1">
          {t(
            'Are you sure you would like to remove this user from the dashboard'
          )}
          ?
        </Typography>
        <Card
          variant="outlined"
          sx={{ bgcolor: 'grey.100', mt: 2, mb: 1, p: 1 }}
        >
          <Typography variant="body1">
            {userSetting.name} {userSetting.isAdmin && <>({t('Admin')})</>}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {userSetting.user.email}
          </Typography>
        </Card>
      </DialogContent>
      <DialogActions sx={{ p: 2 }}>
        <Button variant="outlined" disabled={loading} onClick={onClose}>
          {t('Cancel')}
        </Button>
        <LoadingButton
          variant="contained"
          color="primary"
          loading={loading}
          onClick={handleRemoveConfirmed}
          startIcon={<CheckIcon />}
        >
          {t('Confirm')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
