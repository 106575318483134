import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import Tooltip from '@mui/material/Tooltip';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddIcon from '@mui/icons-material/Add';

import { FinancierInput } from './financier-input';

export function FinanciersInput({
  disabled,
  errors,

  addFinancier,
  newFinanciers,
  setNewFinanciers,

  area,
  setArea,

  openCollapse,
  setOpenCollapse,
}: any) {
  const { t } = useTranslation();

  // collapse
  const toggleCollapse = () => {
    setOpenCollapse(!openCollapse);
  };

  // handle add financier
  const handleAddFinancier = () => {
    setOpenCollapse(true);
    addFinancier();
  };

  return (
    <Box sx={{ mt: 3, mb: 0.5, display: 'flex' }}>
      {/* Collapse */}
      <Box sx={{ flexGrow: 0, mr: 1 }}>
        <Tooltip title={`${t('Expand')}`}>
          <IconButton onClick={toggleCollapse}>
            <ExpandMoreIcon
              sx={{
                transform: openCollapse ? 'rotate(0deg)' : 'rotate(-90deg)',
                transition: 'transform 0.2s ease-in-out',
              }}
            />
          </IconButton>
        </Tooltip>
      </Box>

      {/* main content */}
      <Box
        sx={{
          flexGrow: 1,
        }}
      >
        {/* financiers */}
        <Box
          sx={{
            display: { xs: 'block', sm: 'flex' },
            alignItems: 'center',
            justifyContent: 'space-between',
            mb: 1,
            mt: 0.5,
          }}
        >
          <Box sx={{ flexGrow: 1 }}>
            <Typography
              variant="h6"
              color={disabled ? 'textSecondary' : 'textPrimary'}
              onClick={toggleCollapse}
            >
              {t('Financiers')}{' '}
              <Typography variant="body2" component="span">
                ( {area?.financiersDb?.length || 0} )
              </Typography>
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {t(
                'Financiers will be used to calculate the total funding amount'
              )}
              <Typography variant="caption" component="span" sx={{ ml: 1 }}>
                <Link
                  href="https://docs.digitaldashboard.cc/docs/#track-budgets"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t('Learn More')} &rarr;
                </Link>
              </Typography>
            </Typography>
          </Box>

          {!disabled && (
            <Box sx={{ ml: { xs: 0, sm: 1 } }}>
              <Button
                startIcon={<AddIcon />}
                variant="outlined"
                size="small"
                onClick={handleAddFinancier}
                sx={{
                  mt: { xs: 0.5, sm: 0 },
                  alignSelf: 'center',
                  whiteSpace: 'nowrap',
                }}
              >
                {t('Add Financier')}
              </Button>
            </Box>
          )}
        </Box>

        <Collapse in={openCollapse}>
          {!area.financiersDb?.length && !newFinanciers?.length && (
            <Typography
              variant="body1"
              color="textSecondary"
              sx={{ fontStyle: 'italic', textAlign: 'center' }}
            >
              ( {t('No financiers added')} )
            </Typography>
          )}

          <Stack
            spacing={{ xs: 3, sm: 2 }}
            sx={{ mt: 1.5 }}
            divider={<Divider sx={{ display: { xs: 'block', sm: 'none' } }} />}
          >
            {/* current financiers */}
            {area.financiersDb?.map((financier: any, index: any) => (
              <FinancierInput
                key={financier.id}
                index={index}
                financier={financier}
                disabled={disabled}
                area={area}
                setArea={setArea}
                errors={errors}
              />
            ))}

            {/* new financiers */}
            {newFinanciers?.map((financier: any, index: any) => (
              <FinancierInput
                key={financier.fauxId}
                index={index}
                financier={financier}
                disabled={disabled}
                newFinanciers={newFinanciers}
                setNewFinanciers={setNewFinanciers}
                errors={errors}
              />
            ))}
          </Stack>

          {!!errors.financiers && (
            <Typography variant="body2" color="error">
              {errors.financiers}
            </Typography>
          )}
        </Collapse>
      </Box>
    </Box>
  );
}
