import { useTranslation } from 'react-i18next';

import Alert from '@mui/material/Alert';

import { useAppContext } from '../../../../_lib/context/app-context';

export function AppbarAvatarAlertsUpdate() {
  const { t } = useTranslation();

  const { waitingWorker } = useAppContext();

  if (!waitingWorker) return null;

  return (
    <Alert severity="info" sx={{ width: '210px', minWidth: '100%' }}>
      {t(
        'A new update is available and will be installed after all tabs are closed.'
      )}
    </Alert>
  );
}
