import React, { ReactElement, useRef } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import {
  VariableSizeList as LibraryVirtualizedList,
  VariableSizeListProps,
} from 'react-window';

import { useWindowSize } from '../../_utils/hooks/use-window-resize';

export type ListVirtualizedChildrenProps = {
  index: number;
  style: React.CSSProperties;
  setRowHeight: (index: number, size: number) => void;
  windowWidth: number;
};

export type ListVirtualizedProps = {
  children: ({
    index,
    style,
    setRowHeight,
    windowWidth,
  }: ListVirtualizedChildrenProps) => ReactElement<any, any> | null;
  itemsCount: number;
  defaultRowHeight?: number;
};

export default function ListVirtualized({
  children,
  itemsCount,
  defaultRowHeight = 48,
}: ListVirtualizedProps) {
  const listRef = useRef<LibraryVirtualizedList<VariableSizeListProps>>(null);
  const rowHeights = useRef<{ [key in number]: number }>({});

  const setRowHeight = (index: number, size: number) => {
    listRef.current?.resetAfterIndex(0);
    rowHeights.current = { ...rowHeights.current, [index]: size };
  };

  const getRowHeight = (index: number) =>
    rowHeights?.current?.[index] || defaultRowHeight;

  const [windowWidth] = useWindowSize();

  return (
    <AutoSizer
      style={{
        height: '100%',
        width: '100%',
      }}
    >
      {({ height = 0, width }: { height: number; width: number }) => (
        <LibraryVirtualizedList
          className="LibraryVirtualizedList"
          height={height}
          itemCount={itemsCount}
          itemSize={getRowHeight}
          ref={listRef}
          width={width}
        >
          {({ index, style, ...rest }) =>
            children({
              index,
              style,
              setRowHeight,
              windowWidth,
              ...rest,
            })
          }
        </LibraryVirtualizedList>
      )}
    </AutoSizer>
  );
}
