/* eslint-disable no-param-reassign */
import { fr, enGB, pt } from 'date-fns/locale';
import format from 'date-fns/format';
import {
  formatDistanceToNow,
  isToday,
  isTomorrow,
  isYesterday,
} from 'date-fns';

const languages = { fr, en: enGB, pt } as any;

// add and remove commas to numbers
export const withCommas = (
  number: any,
  language: string = 'en',
  decimals: number = 0
) => {
  if (number === undefined) return 0;
  const amountParsed = new Intl.NumberFormat(language, {
    style: 'decimal',
    maximumFractionDigits: decimals,
    ...(decimals > 0 ? { minimumFractionDigits: decimals } : {}),
  }).format(number);

  return amountParsed;
};

export const removeCommas = (val: string | any) => val.replace(/[, ]+/g, '');

export const replaceSpacesOfIntlNumberFormat = (number: string | 0) => {
  if (!number) return [<>0</>];
  return number
    .split(/[\u202f]/g)
    .flatMap((e, i) => [
      <span key={`${String(i)}`}>
        <span style={{ whiteSpace: 'nowrap' }}>&thinsp;</span>
      </span>,
      e,
    ])
    .slice(1);
};

// string to title case
export const capitalizeFirstLetter = (string: any) =>
  string.charAt(0).toUpperCase() + string.slice(1);

// format date
const addServerTimezone = (date: string) => {
  if (date && date.split('+').length === 1 && date.split('T').length === 2) {
    date = `${date}+00:00`;
  }
  return date;
};

// add days to date
export const addDays = (date: string, days: any) => {
  const result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
};

export const monthDiff = (d1: any, d2: any) => {
  let months;
  months = (d2.getFullYear() - d1.getFullYear()) * 12;
  months -= d1.getMonth();
  months += d2.getMonth();
  return months <= 0 ? 0 : months;
};

export const getStringDate = (
  date: any,
  language: any,
  shortMonth?: boolean,
  noYear?: boolean
) => {
  date = addServerTimezone(date);
  const dateObject = new Date(date);
  const d = dateObject.toString().split(' ');
  const month = dateObject.toLocaleString(
    `${language}-${language.toUpperCase()}`,
    { month: shortMonth ? 'short' : 'long' }
  );
  return [d[2], month, noYear ? [] : d[3]].join(' ');
};

export const translateMonth = (tick: any, language: any) => {
  if (language === 'en') return tick;
  const [month, year] = tick.split(' ');
  let trans;
  switch (month) {
    case 'Jan':
      trans = 'janv';
      break;
    case 'Feb':
      trans = 'févr';
      break;
    case 'Mar':
      trans = 'mars';
      break;
    case 'Apr':
      trans = 'avril';
      break;
    case 'May':
      trans = 'may';
      break;
    case 'Jun':
      trans = 'juin';
      break;
    case 'Jul':
      trans = 'juil';
      break;
    case 'Aug':
      trans = 'août';
      break;
    case 'Sep':
      trans = 'sept';
      break;
    case 'Oct':
      trans = 'oct';
      break;
    case 'Nov':
      trans = 'nov';
      break;
    case 'Dec':
      trans = 'déc';
      break;
    default:
      trans = month;
  }
  return `${trans} ${year}`;
};

export const getStringDateTime = (date: any, language: any) => {
  date = addServerTimezone(date);
  const dateObject = new Date(date);
  const d = dateObject.toString().split(' ');
  const month = dateObject.toLocaleString(
    `${language || 'en'}-${language ? language.toUpperCase() : 'EN'}`,
    { month: 'long' }
  );
  return [d[2], capitalizeFirstLetter(month), d[3], d[4].slice(0, 5)].join(' ');
};

export const getGraphQLDate = (date: any) => {
  const dateObject = new Date(date);
  const d = dateObject.toString().split(' ');
  const month = `0${dateObject.getMonth() + 1}`.slice(-2);
  return [d[3], month, d[2]].join('-');
};

export const chunkDateRange = (
  startDate: any,
  endDate: any,
  numberOfIntervals: any
) => {
  startDate = new Date(startDate);
  endDate = new Date(endDate);
  const diff = endDate.getTime() - startDate.getTime();
  const intervalLength = diff / numberOfIntervals;
  const intervals = [];
  for (let i = 1; i <= numberOfIntervals; i += 1)
    intervals.push(new Date(startDate.getTime() + i * intervalLength));
  return intervals;
};

export const daysPast = (date: any, t: any, language: any) => {
  if (!date) return '--';
  const providedDate = new Date(date);
  if (isToday(providedDate)) {
    return t && t('Today');
  }
  if (isTomorrow(providedDate)) {
    return t('Tomorrow');
  }
  if (isYesterday(providedDate)) {
    return t('Yesterday');
  }
  return formatDistanceToNow(providedDate, {
    addSuffix: true,
    locale: languages[language],
  });
};

export const slugify = (text: any) =>
  text
    .toString()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase()
    .trim()
    .replace(/\s+/g, '-')
    .replace(/[^\w-]+/g, '')
    .replace(/--+/g, '-');

export const getCookie = (name: any) => {
  let cookieValue = null;
  if (document.cookie && document.cookie !== '') {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i += 1) {
      const cookie = cookies[i].trim();
      if (cookie.substring(0, name.length + 1) === `${name}=`) {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
};

export const hexToRGB = (hex: 'string', alpha?: number) => {
  const hX = hex.toUpperCase();
  const h = '0123456789ABCDEF';
  const r = h.indexOf(hX[1]) * 16 + h.indexOf(hX[2]);
  const g = h.indexOf(hX[3]) * 16 + h.indexOf(hX[4]);
  const b = h.indexOf(hX[5]) * 16 + h.indexOf(hX[6]);
  if (alpha) return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  return `rgb(${r}, ${g}, ${b})`;
};

export function timeStringToAmPm(timeString: string): string {
  const date = new Date(`1976-01-01T${timeString}`);
  return format(date, 'HH:mm a');
}

export function getApiURL(url = ''): string {
  const { hostname } = window.location;

  if (process.env.REACT_APP_API_URL?.length) {
    return `${process.env.REACT_APP_API_URL}${url}`;
  }

  if (
    hostname.endsWith('institute.global') ||
    hostname.endsWith('digitaldashboard.cc')
  ) {
    return `https://api.${hostname}${url}`;
  }

  return `http://localhost:8001${url}`;
}

export function getMediaUrl(url?: string) {
  if (url?.startsWith('/')) {
    return `${getApiURL()}${url}`;
  }
  return url || '';
}

export function getApiHost() {
  const apiUrl = getApiURL();

  const apiHost =
    window.location.hostname === 'localhost'
      ? `localhost:${new URL(apiUrl).port}`
      : `${new URL(apiUrl).hostname}`;

  return apiHost;
}

export function isValidEmail(email: string | undefined): boolean {
  return (
    !!email &&
    email.length > 0 &&
    !!email.match(
      /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/
    )
  );
}

// -------------------------------------------------------------------------------------------------------------------------
// weight mean / average
// source: https://gist.github.com/stekhn/a12ed417e91f90ecec14bcfa4c2ae16a?permalink_comment_id=3126118#gistcomment-3126118
// -------------------------------------------------------------------------------------------------------------------------

export const weightedMean = (arrValues: number[], arrWeights: number[]) => {
  const result = arrValues
    .map((value, i) => {
      const weight = arrWeights[i];
      const sum = value * weight;
      return [sum, weight];
    })
    .reduce((p, c) => [p[0] + c[0], p[1] + c[1]], [0, 0]);

  return result[0] / result[1];
};
