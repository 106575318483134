import React from 'react';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import { useDashboardContext } from '../../../_lib/context/dashboard-context';

import { CurrencyText } from '../../currency-text';
import { LinearProgressWithLabel } from '../../linear-progress/with-label';
import { hasSomeFinance } from '../../../_utils/finance-utils';

export function AreaSummaryChartFinance({
  area,
  titleVariant,
  bodyVariant,
  barHeight,
}: any) {
  const { t } = useTranslation();

  const { dashboard } = useDashboardContext();

  // --------------------------------------------------------------------------------------------------------------------

  if (!hasSomeFinance([area])) return null;

  // --------------------------------------------------------------------------------------------------------------------

  return (
    <Paper variant="outlined" sx={{ p: 2, mb: 2 }}>
      <Typography variant={titleVariant} sx={{ fontWeight: 500 }}>
        {t('Financing')}
      </Typography>
      <Typography variant={bodyVariant} sx={{ mb: 1 }}>
        {dashboard.enableCustomFinanceLabels
          ? dashboard.customFinanceBudgetLabel
          : t('Budget')}
        : <CurrencyText amount={area.summary.finance.budget} />
      </Typography>

      <Typography variant={bodyVariant}>
        %{' '}
        {dashboard.enableCustomFinanceLabels
          ? dashboard.customFinanceFinancedLabel
          : t('Financed')}{' '}
        <Typography
          variant={bodyVariant === 'body1' ? 'body2' : 'caption'}
          component="span"
        >
          (
          <CurrencyText amount={area.summary.finance.totalFunding} />)
        </Typography>
      </Typography>
      <Box sx={{ mb: 0.7 }}>
        <LinearProgressWithLabel
          value={area.summary.finance.budgetFundedPercentage}
          sx={{ height: barHeight, borderRadius: '3px' }}
        />
      </Box>

      <Typography variant={bodyVariant}>
        %{' '}
        {dashboard.enableCustomFinanceLabels
          ? dashboard.customFinanceSpentLabel
          : t('Spent')}{' '}
        <Typography
          variant={bodyVariant === 'body1' ? 'body2' : 'caption'}
          component="span"
        >
          (
          <CurrencyText amount={area.summary.finance.currentlySpent} />)
        </Typography>
      </Typography>
      <LinearProgressWithLabel
        value={area.summary.finance.budgetSpentPercentage}
        sx={{ height: barHeight, borderRadius: '3px' }}
        color={
          (area.summary.finance.budgetSpentPercentage >= 100 && 'error') ||
          (area.summary.finance.budgetSpentPercentage >= 80 && 'warning') ||
          'primary'
        }
      />
    </Paper>
  );
}

AreaSummaryChartFinance.defaultProps = {
  titleVariant: 'body1',
  bodyVariant: 'body2',
  barHeight: 24,
};
