import React from 'react';
import { useTranslation } from 'react-i18next';
import { gql, useLazyQuery } from '@apollo/client';

import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';

import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import DoneIcon from '@mui/icons-material/Done';
import FlagIcon from '@mui/icons-material/Flag';

import { DashboardContext } from '../../_lib/context/dashboard-context';
import { logError } from '../../_lib/error';
import { SubscribeToMore } from '../../components/subscribe-to-more';

const USER_AREAS_FRAGMENT = gql`
  fragment UserAreasContentFormTypeSelectFragment on UserType {
    id
    permissions {
      priorityAreas {
        items {
          id
          access
        }
      }
      interventions {
        items {
          id
          access
        }
      }
    }
  }
`;

const USER_AREAS_QUERY = gql`
  query UserAreasContentFormTypeSelect {
    user {
      ...UserAreasContentFormTypeSelectFragment
    }
  }
  ${USER_AREAS_FRAGMENT}
`;

const USER_AREAS_SUBSCRIPTION = gql`
  subscription UserAreasContentFormTypeSelect {
    user {
      ...UserAreasContentFormTypeSelectFragment
    }
  }
  ${USER_AREAS_FRAGMENT}
`;

export function ContentFormTypeSelect({ content, changeValue, loading }: any) {
  const { t } = useTranslation();

  const { user, dashboard } = React.useContext(DashboardContext);

  const typesObj = React.useMemo(
    () => ({
      issue: {
        id: 'issue',
        label: t('Issue'),
        icon: <ErrorOutlineIcon color="warning" fontSize="small" />,
      },
      activity: {
        id: 'activity',
        label: t('Activity'),
        icon: <DoneIcon color="success" fontSize="small" />,
      },
      milestone: {
        id: 'milestone',
        label: t('Milestone'),
        icon: <FlagIcon color="info" fontSize="small" />,
      },
    }),
    [t]
  );

  const typesView = [typesObj.issue];
  const typesEdit = [
    ...typesView,
    typesObj.activity,
    ...(dashboard.mode === 'FULL' ? [typesObj.milestone] : ([] as any)),
  ];

  const [types, setTypes] = React.useState(
    user.isAdmin || dashboard.mode === 'TRACKER' ? typesEdit : typesView
  );

  // -------------------------------------------------------------------------------------------------------------------------

  const [
    fetchAreaAccess,
    {
      data: areasData,
      loading: areasLoading,
      error: areasError,
      subscribeToMore: areasSubscribeToMore,
    },
  ] = useLazyQuery(USER_AREAS_QUERY);

  if (
    !(user.isAdmin || dashboard.mode === 'TRACKER') &&
    !areasData &&
    !areasLoading &&
    !areasError
  ) {
    fetchAreaAccess();
  }

  // -------------------------------------------------------------------------------------------------------------------------
  // -------------------------------------------------------------------------------------------------------------------------
  // handlers
  // -------------------------------------------------------------------------------------------------------------------------

  const handleChange = (event: any) => {
    if (event.target.value) {
      changeValue(event.target.value, 'type');
    }
  };

  // -------------------------------------------------------------------------------------------------------------------------
  // -------------------------------------------------------------------------------------------------------------------------
  // effects
  // -------------------------------------------------------------------------------------------------------------------------

  // error
  React.useEffect(() => {
    if (areasError) logError(areasError);
  }, [areasError]);

  // types
  React.useEffect(() => {
    if (areasData) {
      const areas = [
        ...(areasData.user.permissions.priorityAreas?.items || []),
        ...(areasData.user.permissions.interventions?.items || []),
      ];

      if (areas.some((a) => a.access === 'EDIT')) {
        setTypes(typesEdit);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [areasData]);

  // -------------------------------------------------------------------------------------------------------------------------

  return (
    <>
      <ToggleButtonGroup
        color="primary"
        value={content.type}
        defaultValue="issue"
        exclusive
        onChange={handleChange}
        disabled={loading}
        size="small"
      >
        {types.map((type: any) => (
          <ToggleButton
            value={type.id}
            key={type.id}
            disabled={content.type === type.id}
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 0.7,
              textTransform: 'none',
            }}
          >
            {type.icon}
            {type.label}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>

      {/* subscribe to more */}
      {!!areasData && (
        <SubscribeToMore
          subscribeToMore={() =>
            areasSubscribeToMore({
              document: USER_AREAS_SUBSCRIPTION,
              updateQuery: (prev: any, { subscriptionData }: any) => {
                if (!subscriptionData.data) return prev;
                return subscriptionData.data;
              },
            })
          }
        />
      )}
    </>
  );
}
