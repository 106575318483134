import React from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';

import Autocomplete, {
  createFilterOptions,
  autocompleteClasses,
} from '@mui/material/Autocomplete';

import TextField from '@mui/material/TextField';
import EditIcon from '@mui/icons-material/Edit';
import Popper from '@mui/material/Popper';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme, styled } from '@mui/material/styles';

import { VariableSizeList, ListChildComponentProps } from 'react-window';

import { logError } from '../../_lib/error';
import { SubscribeToMoreComponent } from '../../components/subscribe-to-more';

import { PlanningFieldsInputManageDialog } from './_manage-dialog';

const LISTBOX_PADDING = 8; // px

function renderRow(props: ListChildComponentProps) {
  const { data, index, style } = props;
  const dataSet = data[index];
  const inlineStyle = {
    ...style,
    top: (style.top as number) + LISTBOX_PADDING,
  };

  if (dataSet[1].id === 'manageItems') {
    return (
      <Typography
        component="li"
        {...dataSet[0]}
        onClick={() => {
          dataSet[1].setOpenManageDialog(true);
        }}
        noWrap
        style={inlineStyle}
        sx={{
          display: 'flex',
          alignItems: 'center',
          borderTop: (theme) => `1px solid ${theme.palette.divider}`,
        }}
      >
        <EditIcon
          sx={{
            mr: 1,
            color: 'text.secondary',
          }}
        />
        {dataSet[1].manageDialogTitle}
      </Typography>
    );
  }

  return (
    <Typography component="li" {...dataSet[0]} noWrap style={inlineStyle}>
      {`${dataSet[1].name}`}
      {dataSet[1].chip}
    </Typography>
  );
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data: any) {
  const ref = React.useRef<VariableSizeList>(null);
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}

// Adapter for react-window
const ListboxComponent = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLElement>
>(function ListboxComponent(props, ref) {
  const { children, ...other } = props;
  const itemData: React.ReactChild[] = [];
  (children as React.ReactChild[]).forEach(
    (item: React.ReactChild & { children?: React.ReactChild[] }) => {
      itemData.push(item);
      itemData.push(...(item.children || []));
    }
  );

  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up('sm'), {
    noSsr: true,
  });
  const itemCount = itemData.length;
  const itemSize = smUp ? 36 : 48;

  const getChildSize = (child: React.ReactChild) => {
    if (Object.prototype.hasOwnProperty.call(child, 'group')) {
      return 48;
    }

    return itemSize;
  };

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize;
    }
    return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
  };

  const gridRef = useResetCache(itemCount);

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width="100%"
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={(index) => getChildSize(itemData[index])}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});

const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: 'border-box',
    '& ul': {
      padding: 0,
      margin: 0,
    },
  },
});

const filter = createFilterOptions();

export function PlanningFieldsInputAutocomplete({
  disabled,
  area,
  setArea,
  GET_ITEMS,
  SUBSCRIBE_ITEMS,
  CREATE_ITEM,
  createItemOpName,
  UPDATE_ITEM,
  updateItemOpName,
  DELETE_ITEM,
  deleteItemOpName,
  UPDATE_ITEM_ORDER,
  updateItemOrderOpName,
  dataKey,
  manageDialogTitle,
  manageDialogInputLabel,
  manageDialogAddLabel,
  label,
  sx,
  multiple,
  addOption,
  forceManageOption,
  disableClearable,
  error,
  includeUnitCategory,
  limitTags,
  chip,
}: any) {
  const { t } = useTranslation();

  const [openManageDialog, setOpenManageDialog] = React.useState(false);

  const {
    data,
    loading,
    error: errorData,
    refetch,
    subscribeToMore,
  } = useQuery(GET_ITEMS);

  // -------------------------------------------------------------------------------------------------------
  // -------------------------------------------------------------------------------------------------------
  // memoized
  // -------------------------------------------------------------------------------------------------------

  // the available options
  const options: any = React.useMemo(() => {
    let results = [] as any;
    if (data && data[dataKey]) {
      results = data[dataKey];
    }

    // add chip
    if (chip) {
      results = results.map((item: any) => {
        return {
          ...item,
          chip: chip(item),
        };
      });
    }
    return results;
  }, [chip, data, dataKey]);

  // the selected items
  const items: any = React.useMemo(() => {
    let results = [] as any;
    if (area) {
      results = area[dataKey] || [];
    }

    // add chip
    if (chip) {
      results = results.map((item: any) => {
        return {
          ...item,
          chip: chip(item),
        };
      });
    }
    return results;
  }, [area, chip, dataKey]);

  // -------------------------------------------------------------------------------------------------------
  // -------------------------------------------------------------------------------------------------------
  // effects
  // -------------------------------------------------------------------------------------------------------

  React.useEffect(() => {
    if (errorData) logError(errorData);
  }, [errorData]);

  // refetch if needed
  React.useEffect(() => {
    if (!data && !loading && !errorData) refetch();
  }, [data, loading, errorData, refetch]);

  // -------------------------------------------------------------------------------------------------------

  return (
    <>
      {/* manage dialog */}
      <PlanningFieldsInputManageDialog
        open={openManageDialog}
        setOpen={setOpenManageDialog}
        GET_ITEMS={GET_ITEMS}
        SUBSCRIBE_ITEMS={SUBSCRIBE_ITEMS}
        CREATE_ITEM={CREATE_ITEM}
        createItemOpName={createItemOpName}
        UPDATE_ITEM={UPDATE_ITEM}
        updateItemOpName={updateItemOpName}
        DELETE_ITEM={DELETE_ITEM}
        deleteItemOpName={deleteItemOpName}
        UPDATE_ITEM_ORDER={UPDATE_ITEM_ORDER}
        updateItemOrderOpName={updateItemOrderOpName}
        dataKey={dataKey}
        dialogTitle={manageDialogTitle}
        dialogInputLabel={manageDialogInputLabel}
        dialogAddLabel={manageDialogAddLabel}
        area={area}
        setArea={setArea}
        includeUnitCategory={includeUnitCategory}
        chip={chip}
      />

      {/* autocomplete */}
      <Autocomplete
        disableListWrap
        PopperComponent={StyledPopper}
        ListboxComponent={ListboxComponent}
        disabled={disabled || loading}
        multiple={multiple}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        freeSolo
        autoHighlight
        disableClearable={disableClearable}
        sx={{ ...sx }}
        id={`add-${area.id}-${dataKey}-input`}
        options={options}
        getOptionLabel={(option: any) => {
          // Value selected with enter, right from the input
          if (typeof option === 'string') {
            return option;
          }

          // Add "xxx" option created dynamically
          if (option.inputValue) {
            return option.inputValue;
          }

          // Regular option
          return option.name || '';
        }}
        renderOption={(props, option: any, state) =>
          [
            props,
            { ...option, manageDialogTitle, setOpenManageDialog },
            state.index,
          ] as React.ReactNode
        }
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label={label}
            size="small"
            inputProps={{
              ...params.inputProps,
              autoComplete: 'nope', // disable autocomplete and autofill
            }}
            error={!!error}
            helperText={error}
          />
        )}
        filterOptions={(opts: any, params: any) => {
          const filtered = filter(opts, params);

          // remove selected options
          if (multiple) {
            items?.forEach((item: any) => {
              if (!item) return;
              if (filtered.find((opt: any) => opt.id === item.id)) {
                filtered.splice(
                  filtered.findIndex((opt: any) => opt.id === item.id),
                  1
                );
              }
            });
          }

          const { inputValue } = params;
          // suggest the creation of a new value
          const isExisting = opts.some(
            (option: any) => inputValue === option.name
          );
          if (inputValue !== '' && !isExisting && addOption) {
            filtered.push({
              inputValue,
              name: `${t('Add')} "${inputValue}"`,
            });
          }

          // manage items
          if (!addOption || forceManageOption) {
            filtered.push({
              id: 'manageItems',
              name: manageDialogTitle,
            });
          }

          return filtered;
        }}
        limitTags={limitTags || 2}
        value={multiple ? items : items[0] || null}
        onChange={(event, value) => {
          if (multiple) {
            const valueItems = value.map((item: any) => {
              if (item.id) {
                return item;
              }
              if (item.inputValue) {
                return {
                  name: item.inputValue,
                };
              }
              if (item.name) {
                return {
                  name: item.name,
                };
              }
              return {
                name: item,
              };
            });

            // filter out duplicates
            const newItems = [] as any;
            valueItems.forEach((item: any) => {
              if (!newItems.find((opt: any) => opt.name === item.name)) {
                newItems.push(item);
              }
            });
            setArea({
              ...area,
              [dataKey]: structuredClone(newItems),
            });
          } else {
            setArea({
              ...area,
              [dataKey]: [value],
            });
          }
        }}
      />

      {/* subscriptions */}
      {SUBSCRIBE_ITEMS && !!data && (
        <SubscribeToMoreComponent
          document={SUBSCRIBE_ITEMS}
          subscribeToMore={subscribeToMore}
        />
      )}
    </>
  );
}
