import React from 'react';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Fade from '@mui/material/Fade';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import CachedRoundedIcon from '@mui/icons-material/CachedRounded';

import * as serviceWorkerRegistration from '../offline/serviceWorkerRegistration';

import { useOnlineStatus } from '../offline/use-online-status';
import { logError } from '../error';
import { LinearProgressDeterminateLoading } from '../../components/linear-progress/determinate-loading';

export function DashboardContextLoading({ loading, error }: any) {
  const { t } = useTranslation();

  const [showLoading, setShowLoading] = React.useState(loading);
  const [showReload, setShowReload] = React.useState(!!error);

  const isOnline = useOnlineStatus();

  // ---------------------------------------------------------------------------------------------------------
  // ---------------------------------------------------------------------------------------------------------
  // handlers
  // ---------------------------------------------------------------------------------------------------------

  const handleReload = React.useCallback(() => {
    if (isOnline) {
      logError(Error('Reloaded the app from context loading'));
      window.localStorage.clear();
      serviceWorkerRegistration.unregister().then(() => {
        window.location.reload();
      });
    } else {
      window.location.reload();
    }
  }, [isOnline]);

  // ---------------------------------------------------------------------------------------------------------
  // ---------------------------------------------------------------------------------------------------------
  // effects
  // ---------------------------------------------------------------------------------------------------------

  React.useEffect(() => {
    setShowLoading(loading);
  }, [loading]);

  React.useEffect(() => {
    setShowReload(!!error);
  }, [error]);

  // if not loading, show the reload button after 3 seconds
  React.useEffect(() => {
    if (!loading) {
      const timer = setTimeout(() => {
        setShowReload(true);
      }, 2000);
      return () => clearTimeout(timer);
    }
    return () => {};
  }, [loading]);

  // ---------------------------------------------------------------------------------------------------------

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <LinearProgressDeterminateLoading />

      <Box
        sx={{
          my: 'auto',
          textAlign: 'center',
          py: 3,
          px: 2,
        }}
      >
        <Stack spacing={2}>
          <Fade in={showLoading}>
            <Typography variant="body1" color="primary">
              {t('Loading')}...
            </Typography>
          </Fade>
          <Fade in={showReload}>
            <Box>
              <Button
                variant="outlined"
                startIcon={<CachedRoundedIcon />}
                onClick={handleReload}
              >
                {t('Reload')}
              </Button>
            </Box>
          </Fade>
        </Stack>
      </Box>
    </Box>
  );
}
