import React from 'react';
import { useTranslation } from 'react-i18next';

import Snackbar from '@mui/material/Snackbar';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';

import CloseIcon from '@mui/icons-material/Close';

import { useAppContext } from '../context/app-context';

export function OfllineUpdateAvailable() {
  const { t } = useTranslation();

  const { waitingWorker } = useAppContext();

  const [showUpdateAvailable, setShowUpdateAvailable] = React.useState(false);

  // --------------------------------------------------------------------------------------------------------
  // --------------------------------------------------------------------------------------------------------
  // handlers
  // --------------------------------------------------------------------------------------------------------

  const handleUpdate = () => {
    if (waitingWorker) {
      waitingWorker.postMessage({ type: 'SKIP_WAITING' });
      window.localStorage.clear();
      window.location.reload();
    }
  };

  // --------------------------------------------------------------------------------------------------------
  // --------------------------------------------------------------------------------------------------------
  // effects
  // --------------------------------------------------------------------------------------------------------

  React.useEffect(() => {
    const countOfTabsOpen = Object.keys(
      JSON.parse(window.localStorage.getItem('TabsOpen') || '{}')
    ).length;

    if (waitingWorker && countOfTabsOpen === 1) {
      setShowUpdateAvailable(true);
    }
  }, [waitingWorker]);

  // --------------------------------------------------------------------------------------------------------

  return (
    <Snackbar
      open={showUpdateAvailable}
      color="warning"
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <Alert
        severity="info"
        action={
          <Box sx={{ display: 'flex', alignSelf: 'center' }}>
            <Box>
              <Button
                color="info"
                size="small"
                onClick={handleUpdate}
                variant="contained"
                disableElevation
                sx={{ whiteSpace: 'nowrap' }}
              >
                {t('Update Now')}
              </Button>
            </Box>
            <Box>
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={() => setShowUpdateAvailable(false)}
                sx={{ ml: 0.7 }}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </Box>
          </Box>
        }
      >
        {t('There is a new update available.')}
      </Alert>
    </Snackbar>
  );
}
