import React from 'react';
import { useTranslation } from 'react-i18next';

import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import CloseIcon from '@mui/icons-material/Close';

import { logError } from '../../../_lib/error';
import { useDashboardContext } from '../../../_lib/context/dashboard-context';

import { DraggableDialogDetailInputColorPicker } from './color-picker';

export function DraggableDialogDetailInput({
  label,
  setIsEditing,
  detail,
  createMutation,
  updateMutation,
  includeColorPicker,
  includeWeight,
  includeCategory,
  disableNameInput,
  italicTitle,
}: any) {
  const { t } = useTranslation();

  const { setSnackbarIsError, setSnackbarOpen } = useDashboardContext();

  const [value, setValue] = React.useState(detail?.value || '');
  const [color, setColor] = React.useState(detail?.color || '#e6e6e6');
  const [weight, setWeight] = React.useState(detail?.weight ?? 0);
  const [category, setCategory] = React.useState(detail?.category || '');

  const [loading, setLoading] = React.useState(false);

  // ----------------------------------------------------------------------------------------------------
  // ----------------------------------------------------------------------------------------------------
  // handlers
  // ----------------------------------------------------------------------------------------------------

  const handleCancel = () => {
    setIsEditing(false);
  };

  const handleSave = () => {
    if (!value) return;
    setLoading(true);

    if (!detail?.id) {
      // Create Detail
      let createMutationFinal = () => createMutation(value);
      if (includeColorPicker) {
        createMutationFinal = () => createMutation(value, color);
      }
      if (includeWeight) {
        createMutationFinal = () => createMutation(value, weight);
      }
      if (includeCategory) {
        createMutationFinal = () => createMutation(value, category);
      }
      createMutationFinal()
        .then(() => {
          setValue('');
          setIsEditing(false);
        })
        .catch((err: any) => {
          logError(err);
          setSnackbarIsError(true);
        })
        .finally(() => {
          setLoading(false);
          setSnackbarOpen(true);
        });
    } else {
      // Update Detail
      let updateMutationFinal = () => updateMutation({ id: detail.id, value });
      if (includeColorPicker) {
        updateMutationFinal = () =>
          updateMutation({ id: detail.id, value, color });
      }
      if (includeWeight) {
        updateMutationFinal = () =>
          updateMutation({ id: detail.id, value, weight });
      }
      if (includeCategory) {
        updateMutationFinal = () =>
          updateMutation({ id: detail.id, value, category });
      }
      updateMutationFinal()
        .then(() => {
          setLoading(false);
          setIsEditing(false);
        })
        .catch((err: any) => {
          setLoading(false);
          logError(err);
          setSnackbarIsError(true);
        })
        .finally(() => {
          setSnackbarOpen(true);
        });
    }
  };

  const handleKeyPress = (e: any) => {
    if (e.key === 'Enter') {
      handleSave();
    }
  };

  // ------------------------------------------------------------------------------------------------------------------
  // ------------------------------------------------------------------------------------------------------------------
  // effects
  // ------------------------------------------------------------------------------------------------------------------

  React.useLayoutEffect(() => {
    window.document.getElementById('detail-input-id')?.scrollIntoView(true);
  }, [detail]);

  // ------------------------------------------------------------------------------------------------------------------

  return (
    <Box>
      <Box sx={{ display: 'flex', alignItems: 'stretch', mb: 0.5 }}>
        {/* color */}
        {!!includeColorPicker && (
          <DraggableDialogDetailInputColorPicker
            value={value}
            color={color}
            setColor={setColor}
            loading={loading}
          />
        )}

        <Stack spacing={1.2} sx={{ flexGrow: 1, justifyContent: 'center' }}>
          {/* title */}
          {!disableNameInput ? (
            <TextField
              id="detail-input-id"
              label={label || t('Name')}
              variant="outlined"
              size="small"
              fullWidth
              autoFocus
              value={value}
              onChange={(e) => setValue(e.target.value)}
              disabled={loading}
              required
              sx={{ flexGrow: 1 }}
              onKeyPress={!includeWeight ? handleKeyPress : undefined}
            />
          ) : typeof value === 'string' ? (
            <Typography
              sx={{
                fontStyle: italicTitle ? 'italic' : undefined,
              }}
            >
              {value}
            </Typography>
          ) : (
            value
          )}

          {/* category */}
          {includeCategory && (
            <TextField
              label={t('Category')}
              variant="outlined"
              size="small"
              fullWidth
              value={category}
              onChange={(e) => setCategory(e.target.value)}
              disabled={loading}
              sx={{ flexGrow: 1 }}
              onKeyPress={!includeWeight ? handleKeyPress : undefined}
            />
          )}
        </Stack>

        {/* weight */}
        {includeWeight && (
          <TextField
            label={t('Weight')}
            variant="outlined"
            size="small"
            value={weight}
            onChange={(e) => setWeight(e.target.value)}
            disabled={loading}
            required
            sx={{ ml: 1, flexShrink: 1 }}
            onKeyPress={handleKeyPress}
          />
        )}
      </Box>

      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <LoadingButton
          variant="contained"
          color="primary"
          onClick={handleSave}
          loading={loading}
          size="small"
          sx={{ mr: 0.5 }}
        >
          {t('Save')}
        </LoadingButton>
        <IconButton onClick={handleCancel} disabled={loading}>
          <CloseIcon />
        </IconButton>
      </Box>
    </Box>
  );
}
