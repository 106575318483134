import MuiDialogTitle, {
  DialogTitleProps as MuiDialogTitleProps,
} from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Variant } from '@mui/material/styles/createTypography';

export interface DialogTitleProps extends MuiDialogTitleProps {
  id?: string;
  onClose?: () => void;
  disabled?: boolean;
  variant?: Variant;
}

export function DialogTitle(props: DialogTitleProps) {
  const { children, onClose, sx, disabled, variant, ...other } = props;

  return (
    <MuiDialogTitle
      sx={{
        m: 0,
        py: 2,
        pl: 2,
        paddingRight: '48px',
        display: 'flex',
        alignItems: 'center',
        ...sx,
      }}
      variant={variant}
      {...other}
    >
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={(event) => {
            event.stopPropagation();
            onClose();
          }}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
          color="inherit"
          disabled={disabled}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
}

DialogTitle.defaultProps = {
  id: 'dialog-title',
  onClose: undefined,
  disabled: false,
  variant: 'h6',
};
