import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

export function ReportsTitle({
  primary,
  secondary,
  actions,
}: {
  primary?: any;
  secondary?: any;
  actions?: any;
}) {
  return (
    <Container>
      <Box sx={{ display: 'flex', alignItems: 'center', pt: 2, pb: 3 }}>
        <Box sx={{ flexGrow: 1 }}>
          <Typography variant="h5" component="div">
            {primary}
          </Typography>
          {!!secondary && (
            <Typography variant="body1" component="div" sx={{ mt: 0.5 }}>
              {secondary}
            </Typography>
          )}
        </Box>

        <Box sx={{ display: 'flex' }}>{actions}</Box>
      </Box>
      <Divider />
    </Container>
  );
}

ReportsTitle.defaultProps = {
  primary: null,
  secondary: null,
  actions: null,
};
