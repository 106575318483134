import React from 'react';
import { isEqual } from 'lodash';
import { Doughnut } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import DataSaverOffIcon from '@mui/icons-material/DataSaverOff';
import grey from '@mui/material/colors/grey';

import { useDashboardContext } from '../../_lib/context/dashboard-context';

import { DoughnutChartTooltip } from './tooltip';

export function DoughnutChart({
  id,
  contentSummary,
  height,
  legendPosition,
  disableTooltip,
}: any) {
  const { t } = useTranslation();
  const {
    dashboard: {
      metaStatusColors,
      enableActivityStartDate,
      enableActivityStatusLongOverdue,
    },
  } = useDashboardContext();

  const [chartTooltipContext, setChartTooltipContext] = React.useState<any>({
    content: [],
  });

  // ----------------------------------------------------------------------------------------------------------
  // ----------------------------------------------------------------------------------------------------------
  // memoized values
  // ----------------------------------------------------------------------------------------------------------

  // total activity count for percentages calc
  const totalActivities = (contentSummary?.total ||
    Object.keys(contentSummary || {}).reduce(
      (prev, curr) => prev + (contentSummary[curr].total || 0),
      0
    ) ||
    0) as number;

  const chartData = React.useMemo(() => {
    const filteredDataKeys = contentSummary
      ? Object.keys(contentSummary)
          .filter(
            (k) =>
              k !== '__typename' &&
              k !== 'percDone' &&
              k !== 'id' &&
              k !== 'total'
          )
          .filter(
            (k) => !(k === 'longoverdue' && !enableActivityStatusLongOverdue)
          )
          .filter(
            (k) =>
              !(
                k === 'unstarted' &&
                !contentSummary.unstarted?.total &&
                !enableActivityStartDate
              )
          )
          .filter((k) => !(k === 'pending' && !contentSummary.pending?.total))
      : [];

    const newTotals = filteredDataKeys.map(
      (k) => contentSummary[k]?.total || 0
    );

    const labels = filteredDataKeys?.map(
      (k) =>
        `${t(metaStatusColors[k]?.text)}: ${
          contentSummary[k]?.total || 0
        } (${Math.floor(
          ((contentSummary[k]?.total || 0) / totalActivities) * 100
        )}%)`
    );
    const bgcolors = filteredDataKeys?.map((k) => metaStatusColors[k]?.bgcolor);
    const content = filteredDataKeys?.map((k) => contentSummary[k]?.content);

    const newChartData = {
      labels,
      datasets: [
        {
          data: newTotals,
          backgroundColor: bgcolors,
          statusKeys: filteredDataKeys,
          content,
        },
      ],
    };

    return newChartData;
  }, [
    contentSummary,
    enableActivityStartDate,
    enableActivityStatusLongOverdue,
    metaStatusColors,
    t,
    totalActivities,
  ]);

  // ----------------------------------------------------------------------------------------------------------

  if (
    !chartData ||
    chartData?.datasets[0].data.every((item: any) => item === 0)
  )
    return (
      <Box
        sx={{
          textAlign: 'center',
          color: grey[600],
          fontStyle: 'italic',
          m: 3,
          p: 3,
          bgcolor: grey[50],
        }}
      >
        <DataSaverOffIcon />
        <Typography variant="body1">
          {t('No Current Activities Found')}
        </Typography>
        <Typography variant="body2">
          {t('You might have no, archived, or unstarted activities')}
        </Typography>
      </Box>
    );

  // ----------------------------------------------------------------------------------------------------------

  return (
    <>
      <Doughnut
        style={{ cursor: 'pointer' }}
        id={id}
        data={chartData}
        options={{
          maintainAspectRatio: false,
          responsive: true,
          plugins: {
            legend: {
              position: legendPosition,
            },
            tooltip: {
              enabled: false,
              external: (context: any) => {
                const opacity = context?.tooltip?.opacity || 0;
                const dataset = context?.tooltip?.dataPoints?.[0]?.dataset;
                const dataIndex = context?.tooltip?.dataPoints?.[0]?.dataIndex;
                const position = context.chart.canvas.getBoundingClientRect();

                const content = dataset?.content[dataIndex];
                const status = dataset?.statusKeys[dataIndex];
                const count = dataset?.data[dataIndex];

                const anchorPosition = {
                  top: `${
                    position.top + window.scrollY + context.tooltip.caretY
                  }px`,
                  left: `${
                    position.left + window.scrollX + context.tooltip.caretX
                  }px`,
                };

                const newContext = {
                  dataIndex,
                  opacity,
                  content,
                  status,
                  count,
                  anchorPosition,
                  totalActivities,
                };

                setChartTooltipContext((old: any) => {
                  if (!isEqual(old, newContext)) return newContext;
                  return old;
                });
              },
            },
          },
        }}
        height={height || 270}
      />

      {/* tooltip */}
      {!disableTooltip && (
        <DoughnutChartTooltip tooltipContext={chartTooltipContext} />
      )}
    </>
  );
}

DoughnutChart.defaultProps = {
  legendPosition: 'bottom',
};
