import React from 'react';

import { useTranslation } from 'react-i18next';

import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import EditIcon from '@mui/icons-material/Edit';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import AddCircleIcon from '@mui/icons-material/AddCircle';

import { DashboardContext } from '../../../_lib/context/dashboard-context';

import { PlanningInterventionFormProgrammeDialogCreate } from './dialog/create';
import { PlanningInterventionFormProgrammeDialogManage } from './dialog/manage';

export function PlanningInterventionFormProgrammeAutocomplete({
  intervention,
  setIntervention,
  disabled,
}: any) {
  const { t } = useTranslation();
  const { user, dashboard } = React.useContext(DashboardContext);

  const [programmeOptions, setProgrammeOptions] = React.useState<any>([]);
  const [programme, setProgramme] = React.useState<any>(intervention.programme);

  const [openDialogCreate, setOpenDialogCreate] = React.useState(false);
  const [openDialogManage, setOpenDialogManage] = React.useState(false);

  // -----------------------------------------------------------------------------------------
  // -----------------------------------------------------------------------------------------
  // handlers
  // -----------------------------------------------------------------------------------------

  const handleChange = (event: any, newValue: any) => {
    if (newValue?.id !== 'moreOptions') {
      setIntervention({
        ...intervention,
        programme: newValue,
      });
    } else {
      setIntervention({
        ...intervention,
        programme: null,
      });
    }
  };

  // -----------------------------------------------------------------------------------------
  // -----------------------------------------------------------------------------------------
  // effects
  // -----------------------------------------------------------------------------------------

  React.useEffect(() => {
    setProgrammeOptions([
      ...dashboard.programmes,
      {
        id: 'moreOptions',
        name: 'More Options',
      },
    ]);
  }, [dashboard.programmes]);

  React.useEffect(() => {
    const pr = dashboard.programmes?.find(
      (p: any) => p.id === intervention.programme?.id
    );
    setProgramme(pr);
  }, [dashboard.programmes, intervention.programme]);

  // -----------------------------------------------------------------------------------------

  return (
    <>
      <PlanningInterventionFormProgrammeDialogCreate
        open={openDialogCreate}
        setOpen={setOpenDialogCreate}
        intervention={intervention}
        setIntervention={setIntervention}
      />

      <PlanningInterventionFormProgrammeDialogManage
        open={openDialogManage}
        setOpen={setOpenDialogManage}
      />

      <Autocomplete
        options={programmeOptions || []}
        fullWidth
        size="small"
        disabled={disabled}
        renderInput={(params) => (
          <TextField {...params} label={t('Programme')} disabled={disabled} />
        )}
        getOptionLabel={(option: any) => {
          return option.name;
        }}
        renderOption={(props, option: any) => {
          return option.id === 'moreOptions' && user.isAdmin ? (
            [
              <Divider key="dividerMore" />,
              <MenuItem
                key="add"
                value="moreOptions"
                onClick={() => setOpenDialogCreate(true)}
              >
                <ListItemIcon>
                  <AddCircleIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>{t('Add Programme')}</ListItemText>
              </MenuItem>,
              <MenuItem
                key="edit"
                value="moreOptions"
                onClick={() => setOpenDialogManage(true)}
              >
                <ListItemIcon>
                  <EditIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>{t('Manage')}</ListItemText>
              </MenuItem>,
            ]
          ) : (
            <MenuItem {...props} key={option.id}>
              <ListItemText
                primary={option.name}
                sx={{
                  '& .MuiListItemText-primary, & .MuiListItemText-secondary': {
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                  },
                }}
              />
            </MenuItem>
          );
        }}
        value={programme || null}
        isOptionEqualToValue={(option, newValue) => option.id === newValue.id}
        onChange={handleChange}
        filterOptions={(options: any, params) => {
          const filtered = structuredClone(options) || [];

          return filtered.filter(
            (option: any) =>
              option.name
                ?.toLowerCase()
                .includes(params.inputValue.toLowerCase()) ||
              option.id === 'moreOptions'
          );
        }}
      />
    </>
  );
}
