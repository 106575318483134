import React from 'react';

function getStorageValue<K extends string, V>(key: K, defaultValue: V) {
  // getting stored value
  const saved = localStorage.getItem(key);
  const initial = saved ? JSON.parse(saved) : false;
  return initial || defaultValue;
}

export const useLocalStorage = <K extends string, V>(
  key: K,
  defaultValue: V
): [V, React.Dispatch<React.SetStateAction<V>>] => {
  const [value, setValue] = React.useState<V>(
    getStorageValue(key, defaultValue)
  );

  React.useEffect(() => {
    // storing input name
    localStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);

  return [value, setValue];
};
