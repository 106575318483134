import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useLocation, useParams } from 'react-router-dom';

import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import CircleIcon from '@mui/icons-material/Circle';

import {
  getStringDate,
  getStringDateTime,
  withCommas,
} from '../../../_utils/general-utils';
import { useDashboardContext } from '../../../_lib/context/dashboard-context';

export function TargetUpdateUpdate({
  update,
  target,
  container,
  setShowConfirmDelete,
}: any) {
  const { t } = useTranslation();
  const location = useLocation();

  const { interventionId, priorityAreaId } = useParams<any>();

  const { dashboard } = useDashboardContext();

  const params = new URLSearchParams(location.search);
  if (update?.target?.intervention) params.set('target', update.target.id);
  else params.delete('target');
  const routeToIntervention = `${
    update?.target?.intervention?.urlRelative
  }?${params.toString()}`;

  return (
    <Box>
      {/* priority area */}
      {!!update.priorityArea && update.priorityArea.id !== priorityAreaId && (
        <Typography variant="caption" color="textSecondary" component="div">
          {update.priorityArea.reference}. {update.priorityArea.name}
        </Typography>
      )}

      {/* intervention */}
      {!interventionId && !!update.intervention && (
        <Typography variant="caption" color="textSecondary" component="div">
          {dashboard.mode !== 'SUPRANATIONAL' ? (
            <Link component={RouterLink} to={routeToIntervention}>
              {update.intervention.reference}. {update.intervention.name}
            </Link>
          ) : (
            update.intervention.name
          )}{' '}
        </Typography>
      )}

      {/* date */}
      <Typography
        variant="body2"
        color="primary"
        sx={{ display: 'flex', alignItems: 'flex-end', my: 0.5 }}
      >
        {getStringDate(update.date, dashboard.language)}
        {`${update.id}`.startsWith('start-') && (
          <Chip
            size="small"
            color="primary"
            variant="outlined"
            label={t('Baseline')}
            sx={{
              ml: 1,
              alignSelf: 'flex-end',
              fontSize: '0.7rem',
              height: '20px',
            }}
            component="span"
          />
        )}
      </Typography>

      {/* update text */}
      <Typography variant="body1">
        {withCommas(update.value)} {target.targetUnit?.name}
      </Typography>

      {/* author . createdAt . delete */}

      <Stack
        direction="row"
        divider={<CircleIcon sx={{ color: 'text.secondary', fontSize: 2 }} />}
        spacing={0.7}
        sx={{
          alignItems: 'center',
        }}
      >
        {!!update?.author?.name && (
          <Typography variant="caption" color="textSecondary">
            {update.author.name}
          </Typography>
        )}
        {!!update.createdAt && (
          <Typography variant="caption" color="textSecondary">
            {getStringDateTime(update.createdAt, dashboard.language)}
          </Typography>
        )}
        {dashboard.mode !== 'SUPRANATIONAL' &&
          container?.access === 'EDIT' &&
          !`${update.id}`.startsWith('start-') &&
          !!setShowConfirmDelete && (
            <Typography variant="caption" color="textSecondary">
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <Link
                component="button"
                onClick={() => setShowConfirmDelete(true)}
              >
                {t('Delete')}
              </Link>
            </Typography>
          )}
      </Stack>
    </Box>
  );
}
