import { useTranslation } from 'react-i18next';
import { Mention, MentionsInput as RmMentionsInput } from 'react-mentions';

const style = {
  control: {
    backgroundColor: '#fff',
    fontSize: '1rem',
    fontWeight: 'normal',
  },

  '&multiLine': {
    control: {
      fontFamily: '"Noto Sans", "Helvetica", "Arial", sans-serif',
      minHeight: 63,
    },
    highlighter: {
      padding: 9,
      border: '1px solid transparent',
    },
    input: {
      padding: 9,
      border: '1px solid silver',
    },
  },

  suggestions: {
    list: {
      backgroundColor: 'white',
      border: '1px solid rgba(0,0,0,0.15)',
      fontSize: 14,
    },
    item: {
      padding: '5px 15px',
      borderBottom: '1px solid rgba(0,0,0,0.15)',
      '&focused': {
        backgroundColor: '#cee4e5',
      },
    },
  },
};

export function MentionsInput({
  text,
  setText,
  setMentions,
  users,
  disabled,
}: any) {
  const { t } = useTranslation();

  const formattedUsers = [...users].map((u) => ({
    id: u.id,
    display: u.name,
  }));

  const handleMentionChange = (event: any, _: any, __: any, mentions: any) => {
    setText(event.target.value);
    setMentions(mentions.map((m: any) => m.id));
  };

  return (
    <RmMentionsInput
      id="mentions-input"
      singleLine={false}
      value={text}
      onChange={handleMentionChange}
      placeholder={`${t("Add an update (tag people using '@')")}`}
      style={style}
      disabled={disabled}
    >
      <Mention
        trigger="@"
        data={formattedUsers}
        style={{ backgroundColor: '#cee4e5' }}
        markup="@**__display__**<!--__id__-->"
      />
    </RmMentionsInput>
  );
}
