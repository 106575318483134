/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable prefer-template */
/* eslint-disable no-plusplus */
/* eslint-disable react/forbid-prop-types */
import React from 'react';

import { useTranslation } from 'react-i18next';

import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import Checkbox from '@mui/material/Checkbox';

// @ts-ignore
import * as store from '@material-table/core/dist/store';

import DDDTableCell from './table-cell';

function MTableGroupRow(props: any) {
  const { t } = useTranslation();

  const options = store.useOptionStore();
  const icons = store.useIconStore();
  const rotateIconStyle = (isOpen: Boolean) => ({
    transform: isOpen ? 'rotate(90deg)' : 'none',
  });

  let colSpan = props.columns.filter(
    (columnDef: any) => !columnDef.hidden
  ).length;
  options.selection && colSpan++;
  props.detailPanel && colSpan++;
  props.actions && props.actions.length > 0 && colSpan++;
  const column = props.groups[props.level];

  let detail;
  if (props.groupData.isExpanded) {
    if (props.groups.length > props.level + 1) {
      // Is there another group
      detail = props.groupData.groups.map((groupData: any, index: number) => (
        <props.components.GroupRow
          actions={props.actions}
          key={groupData.value || '' + index}
          columns={props.columns}
          components={props.components}
          detailPanel={props.detailPanel}
          getFieldValue={props.getFieldValue}
          groupData={groupData}
          groups={props.groups}
          level={props.level + 1}
          path={[...props.path, index]}
          onGroupExpandChanged={props.onGroupExpandChanged}
          onGroupSelected={props.onGroupSelected}
          onRowSelected={props.onRowSelected}
          onRowClick={props.onRowClick}
          onToggleDetailPanel={props.onToggleDetailPanel}
          onTreeExpandChanged={props.onTreeExpandChanged}
          onEditingCanceled={props.onEditingCanceled}
          onEditingApproved={props.onEditingApproved}
          hasAnyEditingRow={props.hasAnyEditingRow}
          isTreeData={props.isTreeData}
          cellEditable={props.cellEditable}
          onCellEditStarted={props.onCellEditStarted}
          onCellEditFinished={props.onCellEditFinished}
          scrollWidth={props.scrollWidth}
          treeDataMaxLevel={props.treeDataMaxLevel}
        />
      ));
    } else {
      detail = props.groupData.data.map((rowData: any, index: number) => {
        if (rowData.tableData.editing) {
          return (
            <props.components.EditRow
              columns={props.columns}
              components={props.components}
              data={rowData}
              path={[...props.path, rowData.tableData.uuid]}
              localization={props.localization}
              key={index}
              mode={rowData.tableData.editing}
              isTreeData={props.isTreeData}
              detailPanel={props.detailPanel}
              onEditingCanceled={props.onEditingCanceled}
              onEditingApproved={props.onEditingApproved}
              getFieldValue={props.getFieldValue}
              onBulkEditRowChanged={props.onBulkEditRowChanged}
              scrollWidth={props.scrollWidth}
            />
          );
        }
        return (
          <props.components.Row
            actions={props.actions}
            key={index}
            columns={props.columns}
            components={props.components}
            data={rowData}
            detailPanel={props.detailPanel}
            level={(props.level || 0) + 1}
            getFieldValue={props.getFieldValue}
            path={[...props.path, rowData.tableData.uuid]}
            onRowSelected={props.onRowSelected}
            onRowClick={props.onRowClick}
            onToggleDetailPanel={props.onToggleDetailPanel}
            isTreeData={props.isTreeData}
            onTreeExpandChanged={props.onTreeExpandChanged}
            onEditingCanceled={props.onEditingCanceled}
            onEditingApproved={props.onEditingApproved}
            hasAnyEditingRow={props.hasAnyEditingRow}
            cellEditable={props.cellEditable}
            onCellEditStarted={props.onCellEditStarted}
            onCellEditFinished={props.onCellEditFinished}
            scrollWidth={props.scrollWidth}
            treeDataMaxLevel={props.treeDataMaxLevel}
          />
        );
      });
    }
  }

  const freeCells = [];
  for (let i = 0; i < props.level; i++) {
    freeCells.push(<TableCell padding="checkbox" key={i} />);
  }

  let { value } = props.groupData;
  if (column.lookup) {
    value = column.lookup[value];
  }

  let { title } = column;
  if (typeof options.groupTitle === 'function') {
    title = options.groupTitle(props.groupData);
  } else if (typeof column.groupTitle === 'function') {
    title = column.groupTitle(props.groupData);
  } else if (typeof title !== 'string') {
    title = React.cloneElement(title);
  }

  const separator = options.groupRowSeparator || ': ';

  const showSelectGroupCheckbox =
    options.selection && options.showSelectGroupCheckbox;

  const mapSelectedRows = (groupData: any) => {
    let totalRows = 0;
    let selectedRows = 0;

    if (showSelectGroupCheckbox) {
      if (groupData.data.length) {
        totalRows += groupData.data.length;
        groupData.data.forEach(
          (row: any) => row.tableData.checked && selectedRows++
        );
      } else {
        groupData.groups.forEach((group: any) => {
          const [groupTotalRows, groupSelectedRows] = mapSelectedRows(group);

          totalRows += groupTotalRows;
          selectedRows += groupSelectedRows;
        });
      }
    }

    return [totalRows, selectedRows];
  };

  const [totalRows, selectedRows] = mapSelectedRows(props.groupData);

  if (options.showGroupingCount) {
    value = `${value || '-'} (${props.groupData.data?.length ?? 0})`;
  }

  const CustomTableCell =
    title === t('Phase') ? DDDTableCell : props.components.Cell;

  const customTableCelProps = {
    colSpan,
    padding: 'none',
    columnDef: column,
    value,
    icons,
    ...(title === t('Phase') && { groupData: props.groupData }),
  };

  return (
    <>
      <TableRow ref={props.forwardedRef}>
        {freeCells}
        <CustomTableCell {...customTableCelProps}>
          <>
            <IconButton
              style={{
                transition: 'all ease 200ms',
                ...rotateIconStyle(props.groupData.isExpanded),
              }}
              onClick={(event) => {
                props.onGroupExpandChanged(props.path);
              }}
              size="large"
            >
              <icons.DetailPanel row={props} level={props.path.length - 1} />
            </IconButton>
            {showSelectGroupCheckbox && (
              <Checkbox
                indeterminate={selectedRows > 0 && totalRows !== selectedRows}
                checked={totalRows === selectedRows}
                onChange={(event, checked) =>
                  props.onGroupSelected &&
                  props.onGroupSelected(checked, props.groupData.path)
                }
                style={{ marginRight: 8 }}
              />
            )}
            <b>
              {title}
              {separator}
            </b>
          </>
        </CustomTableCell>
      </TableRow>
      {detail}
    </>
  );
}

MTableGroupRow.defaultProps = {
  columns: [],
  groups: [],
  level: 0,
};

export default React.forwardRef(function MTableGroupRowRef(
  props: any,
  ref: any
) {
  return <MTableGroupRow {...props} forwardedRef={ref} />;
});
