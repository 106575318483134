import { TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';

export function ContentFormDescription({
  content,
  loading,
  changeValue,
  errors,
}: any) {
  const { t } = useTranslation();

  return (
    <TextField
      id="description-input"
      label={t('Further instructions / details')}
      variant="outlined"
      minRows={3}
      multiline
      fullWidth
      value={content.description || ''}
      onChange={(event) => changeValue(event.target.value, 'description')}
      disabled={loading}
      error={!!errors.description}
      helperText={errors.description}
    />
  );
}
