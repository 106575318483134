import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

import { DialogModal } from '../../../components/dialog-modal';

import { getStringDateTime } from '../../../_utils/general-utils';
import { useDashboardContext } from '../../../_lib/context/dashboard-context';
import { logError } from '../../../_lib/error';

import {
  IMPORT_EXPORT_DELETE,
  IMPORT_EXPORT_UPDATE,
} from '../../../_lib/graphql/mutations';
import { IMPORT_EXPORT_STATUS } from '../../../_lib/data/constants';

import { ImportDialogStepper } from './actions/import-dialog';
import { ImportExportActionsDownload } from './actions/download';
import { IMPORT_EXPORTS_QUERY } from '../../../_lib/graphql/queries';

export function DashboardFormImportExportDetail({ item }: any) {
  const { t } = useTranslation();

  const {
    dashboard,
    user,
    setSnackbarIsError,
    setSnackbarOpen,
    setSnackbarIsDelete,
  } = useDashboardContext();

  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  const [openCancelDialog, setOpenCancelDialog] = React.useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const [updateMutation] = useMutation(IMPORT_EXPORT_UPDATE);

  const [deleteMutation] = useMutation(IMPORT_EXPORT_DELETE, {
    update(cache, { data: { importExportDelete } }) {
      cache.writeQuery({
        query: IMPORT_EXPORTS_QUERY,
        data: { importExports: importExportDelete.importExports },
      });
    },
  });

  // ----------------------------------------------------------------------------------------------------

  const isAuthor = React.useMemo(
    () => user.isSuperuser || item.author.id === user.id,
    [item.author.id, user.id, user.isSuperuser]
  );
  const showStepper = React.useMemo(
    () => item.status === 'AWAITING_CONFIRMATION' && isAuthor,
    [item.status, isAuthor]
  );

  // ----------------------------------------------------------------------------------------------------
  // ----------------------------------------------------------------------------------------------------
  // handlers
  // ----------------------------------------------------------------------------------------------------

  const handleDelete = () => {
    setLoading(true);
    deleteMutation({ variables: { id: item.id } })
      .then(() => {
        setOpenDeleteDialog(false);
        setSnackbarIsDelete(true);
        setSnackbarOpen(true);
      })
      .catch((err) => {
        logError(err);
        setSnackbarIsError(true);
        setSnackbarOpen(true);
        setLoading(false);
      });
  };

  const handleCancelImport = () => {
    setLoading(true);
    updateMutation({ variables: { id: item.id, status: 'CANCELLED' } })
      .then(() => {
        setOpenCancelDialog(false);
      })
      .catch((err) => {
        logError(err);
        setSnackbarIsError(true);
      })
      .finally(() => {
        setSnackbarOpen(true);
        setLoading(false);
      });
  };

  // ----------------------------------------------------------------------------------------------------

  return (
    <>
      {/* confirm delete dialog */}
      <DialogModal
        open={openDeleteDialog}
        loading={loading}
        onDismiss={() => setOpenDeleteDialog(false)}
        onSubmit={handleDelete}
        title={t('Delete Import & Export Entry')}
        message={t('Are you sure you would like to delete this?')}
      />

      {/* confirm cancel dialog */}
      <DialogModal
        open={openCancelDialog}
        loading={loading}
        onDismiss={() => setOpenCancelDialog(false)}
        onSubmit={handleCancelImport}
        title={t('Cancel Excel Import Entry')}
        message={t('Are you sure you would like cancel this?')}
      />

      {/* import confirm dialog */}
      {showStepper && (
        <ImportDialogStepper
          open={openConfirmDialog}
          setOpen={setOpenConfirmDialog}
          onDismiss={() => setOpenConfirmDialog(false)}
          item={item}
        />
      )}

      {/* detail */}
      <Box key={item.id}>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 0.5 }}>
          <Typography variant="body1">{t(item.mode)}</Typography>
          <Chip
            label={t(IMPORT_EXPORT_STATUS[item.status].label)}
            size="small"
            icon={
              IMPORT_EXPORT_STATUS[item.status].icon ||
              IMPORT_EXPORT_STATUS.UNKNOWN.icon
            }
            sx={{
              ml: 1.5,
              fontSize: '0.75rem',
              textTransform: 'capitalize',
            }}
            color={
              item.status === 'SUCCESS'
                ? 'success'
                : item.status === 'FAILED'
                ? 'error'
                : undefined
            }
          />

          {/* download */}
          {!!item.file && <ImportExportActionsDownload item={item} />}
        </Box>

        <Stack
          flexDirection="row"
          sx={{ alignItems: 'center', flexWrap: 'wrap', columnGap: 1 }}
        >
          <Stack
            flexDirection="row"
            divider={
              <Typography
                variant="caption"
                color="textSecondary"
                component="div"
              >
                {' • '}
              </Typography>
            }
            columnGap={0.5}
          >
            <Typography variant="caption" color="textSecondary" component="div">
              {item.author.name}
            </Typography>

            <Typography variant="caption" color="textSecondary" component="div">
              {getStringDateTime(item.createdAt, dashboard.language)}
            </Typography>

            {['SUCCESS', 'CANCELLED', 'FAILED'].includes(item.status) && (
              <Typography
                variant="caption"
                color="textSecondary"
                component="div"
              >
                <Link
                  component="button"
                  variant="caption"
                  onClick={() => setOpenDeleteDialog(true)}
                  underline="hover"
                >
                  {t('Delete')}
                </Link>
              </Typography>
            )}
          </Stack>

          {item.draftData &&
            Array.isArray(item.draftData) &&
            item.status === 'AWAITING_CONFIRMATION' && (
              <Box flexDirection="row">
                <Button
                  size="small"
                  sx={{
                    px: 0.6,
                    py: 0.1,
                    fontSize: '0.75rem',
                    textTransform: 'none',
                  }}
                  variant="outlined"
                  color="error"
                  onClick={() => setOpenCancelDialog(true)}
                >
                  {t('Cancel')}
                </Button>
                <Button
                  size="small"
                  sx={{
                    ml: 1.5,
                    px: 0.6,
                    py: 0.2,
                    fontSize: '0.75rem',
                    textTransform: 'none',
                  }}
                  variant="contained"
                  color="primary"
                  onClick={() => setOpenConfirmDialog(true)}
                >
                  {t('Confirm')}
                </Button>
              </Box>
            )}
        </Stack>
      </Box>
    </>
  );
}
