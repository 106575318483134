import { makeStyles } from '@mui/styles';

import CircularProgress from '@mui/material/CircularProgress';

export function SwitchIcon({ enabled, loading, size }: any) {
  const classes = makeStyles(({ palette, shadows }: any) => ({
    circle: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: size,
      height: size,
      borderRadius: '50%',
      backgroundColor: palette.background.default,
      boxShadow: shadows[1],
    },
    active: {
      backgroundColor: palette.primary.main,
    },
  }))();

  return (
    <div
      className={`${classes.circle} ${
        enabled && !loading ? classes.active : ''
      }`}
    >
      {loading && (
        <CircularProgress
          size={size / 2 + size / 4}
          color="secondary"
          thickness={size / 4 + 1}
        />
      )}
    </div>
  );
}
SwitchIcon.defaultProps = {
  size: 20,
};

export default SwitchIcon;
