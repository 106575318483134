import React from 'react';

import * as topojson from 'topojson-client';
import * as turf from '@turf/turf';

import { getApiURL } from '../general-utils';

export function useJSONMap(url: string) {
  const [data, setData] = React.useState<any>(null);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState<any>(null);

  React.useEffect(() => {
    if (url) {
      if (setLoading) setLoading(true);
      fetch(
        url,
        url.startsWith(getApiURL())
          ? {
              method: 'GET',
              credentials: 'include',
              headers: { 'Content-Type': 'application/json' },
            }
          : undefined
      )
        .then((res) => res.json())
        .then((json) => {
          if (setData) setData(json);
        })
        .catch((e) => {
          if (setError) setError(e);
        })
        .finally(() => {
          if (setLoading) setLoading(false);
        });
    } else if (setError) setError('No URL provided');
  }, [url]);

  const map = React.useMemo(() => {
    if (!data) return null;

    // If the file looks like TopoJSON convert it to geoJSON
    let newMap = { ...data };
    if (newMap.type === 'Topology') {
      newMap = topojson.feature(data, data.objects.collection);
    }
    const fixedGeoJSON = { ...newMap };
    fixedGeoJSON.features = fixedGeoJSON.features.map((f: any) =>
      turf.rewind(f, { reverse: true })
    );
    return fixedGeoJSON;
  }, [data]);

  return { data: map, loading, error };
}
