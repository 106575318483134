import React from 'react';
import { useTranslation } from 'react-i18next';

import { ChromePicker, CirclePicker } from 'react-color';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';
import Popper from '@mui/material/Popper';
import Paper from '@mui/material/Paper';
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';
import ArrowDropUpOutlinedIcon from '@mui/icons-material/ArrowDropUpOutlined';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';

import CloseIcon from '@mui/icons-material/Close';

export function DraggableDialogDetailInputColorPicker({
  value,
  color,
  setColor,
  loading,
}: any) {
  const { t } = useTranslation();

  const [hoverColor, setHoverColor] = React.useState(color);

  // --------------------------------------------------------------------------------------------------
  // popover

  const buttonRef = React.useRef<HTMLButtonElement>(null);

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const [anchorElCustomize, setAnchorElCustomize] =
    React.useState<HTMLButtonElement | null>(null);

  const handleClickCustomize = () => {
    setAnchorElCustomize(buttonRef.current);
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl || anchorElCustomize);

  // --------------------------------------------------------------------------------------------------
  // --------------------------------------------------------------------------------------------------
  // handlers
  // --------------------------------------------------------------------------------------------------

  const handleColorChangeComplete = (c: any) => {
    setColor(c.hex);
  };

  // --------------------------------------------------------------------------------------------------
  // --------------------------------------------------------------------------------------------------
  // effects
  // --------------------------------------------------------------------------------------------------

  React.useEffect(() => {
    setHoverColor(color);
  }, [color]);

  // --------------------------------------------------------------------------------------------------

  return (
    <Box sx={{ display: 'flex', mr: 1 }}>
      <ClickAwayListener
        onClickAway={() => {
          setAnchorEl(null);
          setAnchorElCustomize(null);
        }}
      >
        <Box>
          <Button
            variant="outlined"
            onClick={() => setAnchorEl(buttonRef.current)}
            sx={{ minWidth: 0, p: 0, pl: 1, width: 'auto', height: '40px' }}
            disabled={loading}
            ref={buttonRef}
          >
            <Box sx={{ height: 20, width: 20, bgcolor: color }} />
            {open ? (
              <ArrowDropUpOutlinedIcon fontSize="small" />
            ) : (
              <ArrowDropDownOutlinedIcon fontSize="small" />
            )}
          </Button>

          {/* normal / default picker */}
          <Popper
            open={!!anchorEl}
            anchorEl={anchorEl}
            placement="bottom-start"
            sx={(theme) => ({
              zIndex: theme.zIndex.modal,
            })}
            components={undefined}
            componentsProps={undefined}
          >
            <Paper>
              <Box
                sx={{
                  p: 2,
                  '& .circle-picker': {
                    width: 'auto !important',
                  },
                }}
              >
                <Box sx={{ mb: 1, display: 'flex', alignItems: 'center' }}>
                  <Typography
                    variant="caption"
                    color="textSecondary"
                    sx={{
                      textTransform: 'uppercase',
                      fontWeight: 500,
                      flexGrow: 1,
                      mr: 1,
                    }}
                  >
                    {t('Colours')}
                  </Typography>
                  <IconButton size="small" onClick={() => setAnchorEl(null)}>
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                </Box>

                <CirclePicker
                  onChangeComplete={handleColorChangeComplete}
                  colors={[
                    '#e6e6e6',
                    '#ffcfc9',
                    '#ffc8aa',
                    '#ffe5a0',
                    '#d4edbc',
                    '#bfe1f6',
                    '#c6dbe1',
                    '#e6cff2',
                  ]}
                  circleSize={20}
                  circleSpacing={7}
                  onSwatchHover={(c, event) => {
                    setHoverColor(c.hex);
                    event.target?.addEventListener('mouseout', () => {
                      setHoverColor(color);
                    });
                  }}
                />

                <Box sx={{ mb: 1 }} />

                <CirclePicker
                  onChangeComplete={handleColorChangeComplete}
                  colors={[
                    '#3d3d3d',
                    '#b10202',
                    '#753800',
                    '#473822',
                    '#11734b',
                    '#0a53a8',
                    '#215a6c',
                    '#5a3286',
                  ]}
                  circleSize={20}
                  circleSpacing={7}
                  onSwatchHover={(c, event) => {
                    setHoverColor(c.hex);
                    event.target?.addEventListener('mouseout', () => {
                      setHoverColor(color);
                    });
                  }}
                />

                <Button
                  size="small"
                  sx={{ mt: 2 }}
                  onClick={handleClickCustomize}
                >
                  {t('Customize')}
                </Button>
              </Box>

              <Divider />

              <Box
                sx={{ px: 2, py: 1.5, display: 'flex', alignItems: 'center' }}
              >
                <Typography variant="body2" sx={{ mr: 1 }}>
                  {t('Preview')}
                </Typography>
                <Chip
                  label={value || t('Name')}
                  color="default"
                  size="small"
                  sx={(theme) => ({
                    bgcolor: hoverColor,
                    color: theme.palette.getContrastText(hoverColor),
                  })}
                />
              </Box>
            </Paper>
          </Popper>

          {/* -------------------------------------------------------------------------------------------- */}
          {/* custom picker */}
          <Popper
            open={!!anchorElCustomize}
            anchorEl={anchorElCustomize}
            placement="bottom-start"
            sx={(theme) => ({
              zIndex: theme.zIndex.modal,
            })}
            components={undefined}
            componentsProps={undefined}
          >
            <Paper>
              <Box
                sx={{ px: 2, py: 1.5, display: 'flex', alignItems: 'center' }}
              >
                <Typography variant="body2" sx={{ mr: 1 }}>
                  {t('Preview')}
                </Typography>
                <Chip
                  label={value || t('Name')}
                  color="default"
                  size="small"
                  sx={(theme) => ({
                    bgcolor: hoverColor,
                    color: theme.palette.getContrastText(hoverColor),
                  })}
                />
              </Box>

              <Divider />

              <Box
                sx={{
                  p: 2,
                  '& .circle-picker': {
                    width: 'auto !important',
                  },
                }}
              >
                <ChromePicker
                  color={hoverColor}
                  onChangeComplete={(c) => setHoverColor(c.hex)}
                />

                <Stack
                  direction="row"
                  spacing={1}
                  sx={{ mt: 2 }}
                  justifyContent="flex-end"
                >
                  <Button
                    variant="outlined"
                    onClick={() => {
                      setHoverColor(color);
                      setAnchorElCustomize(null);
                      setAnchorEl(buttonRef.current);
                    }}
                  >
                    {t('Cancel')}
                  </Button>
                  <Button
                    variant="contained"
                    disableElevation
                    onClick={() => {
                      setColor(hoverColor);
                      setAnchorElCustomize(null);
                    }}
                  >
                    {t('OK')}
                  </Button>
                </Stack>
              </Box>
            </Paper>
          </Popper>
        </Box>
      </ClickAwayListener>
    </Box>
  );
}
