import { useTranslation } from 'react-i18next';

import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

export function DashboardFormDetailsActivitiesArchive({
  disabled,
  newDashboard,
  setNewDashboard,
}: any) {
  const { t } = useTranslation();

  return (
    <FormControl disabled={disabled} fullWidth size="small">
      <InputLabel id="activities-archive-frequency-label">
        {t('Automatic Activities Archive Frequency')}
      </InputLabel>
      <Select
        labelId="activities-archive-frequency-label"
        id="activities-archive-frequency"
        label={t('Automatic Activities Archive Frequency')}
        value={newDashboard.activitiesArchiveFrequency}
        onChange={(e) =>
          setNewDashboard({
            ...newDashboard,
            activitiesArchiveFrequency: e.target.value,
          })
        }
      >
        <MenuItem value="1">
          <ListItemText primary={t('1 Month')} secondary={t('Every month')} />
        </MenuItem>
        {[3, 6, 12].map((months) => (
          <MenuItem key={months} value={months}>
            <ListItemText
              primary={t('{{count}} Months', { count: months })}
              secondary={t('Every {{count}} months', { count: months })}
            />
          </MenuItem>
        ))}
        <MenuItem value="0">
          <ListItemText
            primary={t('Never')}
            secondary={t('Never archive activities')}
          />
        </MenuItem>
      </Select>
    </FormControl>
  );
}
