import React from 'react';
import { useTranslation } from 'react-i18next';
import { gql, useMutation } from '@apollo/client';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import LockOpenIcon from '@mui/icons-material/LockOpen';

import {
  AUTH_IS_LOGGED_IN_QUERY,
  CONTEXT_USER_QUERY,
} from '../../../../_lib/graphql/queries';

import { DashboardContext } from '../../../../_lib/context/dashboard-context';
import { logError } from '../../../../_lib/error';

const ENABLE_2FA_COMPLETE = gql`
  mutation TwoFactorEnableComplete {
    twoFactorEnableComplete {
      message
      success
    }
  }
`;

export function TwoFactorEnableComplete({
  disabled,
  loading,
  setLoading,
}: any) {
  const { t } = useTranslation();

  const { setSnackbarOpen, setSnackbarIsError } =
    React.useContext(DashboardContext);

  const [enable2faCompleteMutation] = useMutation(ENABLE_2FA_COMPLETE, {
    refetchQueries: [
      { query: AUTH_IS_LOGGED_IN_QUERY },
      {
        query: CONTEXT_USER_QUERY,
      },
    ],
  });

  // ---------------------------------------------------------------------------------------------------------------------
  // ---------------------------------------------------------------------------------------------------------------------
  // handlers
  // ---------------------------------------------------------------------------------------------------------------------

  const handleFiinish = () => {
    setLoading(true);
    enable2faCompleteMutation()
      .then(() => {
        window.location.reload();
      })
      .catch((e) => {
        setLoading(false);
        logError(e);
        setSnackbarIsError(true);
        setSnackbarOpen(true);
      });
  };

  // ---------------------------------------------------------------------------------------------------------------------

  return (
    <Stack spacing={1}>
      <Typography variant="body2">
        {t(
          'Setup is almost complete. Click the button below to enable two factor authentication.'
        )}
      </Typography>

      <LoadingButton
        variant="contained"
        onClick={handleFiinish}
        sx={{ textTransform: 'none' }}
        loading={loading}
        startIcon={<LockOpenIcon />}
        disabled={disabled}
      >
        {t('Enable 2FA')}
      </LoadingButton>
    </Stack>
  );
}
