import React from 'react';
import { useParams } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import SaveIcon from '@mui/icons-material/Save';
import LoadingButton from '@mui/lab/LoadingButton';

import { useDashboardContext } from '../../../_lib/context/dashboard-context';
import { logError } from '../../../_lib/error';
import { getGraphQLDate } from '../../../_utils/general-utils';

import { PRIORITY_AREA_SETUP } from '../../../_lib/graphql/mutations';
import {
  CONTEXT_USER_QUERY,
  PRIORITY_AREAS_PLANNING_QUERY,
  PRIORITY_AREA_PLANNING_QUERY,
} from '../../../_lib/graphql/queries';

export function PlanningPriorityAreaFormActionsSave({
  valid,

  priorityArea,
  interventions = [],
  paChildren,

  newFinanciers,
  setNewFinanciers,
  newTargets,
  setNewTargets,

  setDisabled,

  loading,
  setLoading,
}: any) {
  const { t } = useTranslation();

  const { priorityAreaId } = useParams<any>();

  const { dashboard, setSnackbarOpen, setSnackbarIsError, setSnackbarMessage } =
    useDashboardContext();

  const [setupPriorityAreaMutation] = useMutation(PRIORITY_AREA_SETUP, {
    update: (cache, { data }) => {
      // update this priority area
      cache.writeQuery({
        query: PRIORITY_AREA_PLANNING_QUERY,
        data: { priorityArea: data.priorityAreaSetup.priorityArea },
        variables: {
          priorityAreaId: data.priorityAreaSetup.priorityArea.id,
        },
      });

      // update parent priority area
      cache.writeQuery({
        query: PRIORITY_AREA_PLANNING_QUERY,
        data: { priorityArea: data.priorityAreaSetup.parentPriorityArea },
        variables: {
          priorityAreaId: dashboard.enableAreaLevels
            ? data.priorityAreaSetup.parentPriorityArea?.id
            : undefined,
        },
      });

      // update sibling priority areas
      cache.writeQuery({
        query: PRIORITY_AREAS_PLANNING_QUERY,
        data: { priorityAreas: data.priorityAreaSetup.siblingPriorityAreas },
        variables: {
          parentId: dashboard.enableAreaLevels
            ? data.priorityAreaSetup.parentPriorityArea?.id
            : undefined,
        },
      });

      // update user context
      cache.writeQuery({
        query: CONTEXT_USER_QUERY,
        data: { user: data.priorityAreaSetup.user },
      });
    },
  });

  const handleSubmit = () => {
    if (valid()) {
      setLoading(true);

      const financiers = priorityArea.financiersDb?.concat(newFinanciers) || [];
      const targets = priorityArea.targetsDb.concat(newTargets) || [];

      const variables = {
        id: priorityArea.id,
        name: priorityArea.name,
        reference: priorityArea.reference,
        description: priorityArea.description,

        leadId: priorityArea.lead?.userSettingsId || priorityArea.lead?.id,
        coLeadId:
          priorityArea.coLead?.userSettingsId || priorityArea.coLead?.id,
        leadsIds: priorityArea.leads?.map((l: any) => l.userSettingsId || l.id),
        coLeadsIds: priorityArea.coLeads?.map(
          (l: any) => l.userSettingsId || l.id
        ),
        leadsFreeText: priorityArea.leadsFreeText,
        coLeadsFreeText: priorityArea.coLeadsFreeText,

        regionsIds: priorityArea.regions.map((r: any) => r.id),

        dashboardOfDashboardsId:
          priorityArea.dashboardOfDashboards?.dashboard?.id,

        budget: dashboard.enablePriorityAreaFinancials
          ? parseFloat(priorityArea.budgetDb) || 0
          : undefined,
        currentlySpent: dashboard.enablePriorityAreaFinancials
          ? parseFloat(priorityArea.currentlySpentDb) || 0
          : undefined,
        currency: priorityArea.currency || null,

        stakeholders: priorityArea.stakeholders.map((s: any) => {
          const newS = structuredClone(s);

          delete newS.__typename;
          return newS;
        }),

        children: paChildren
          .filter((i: any) => !!i.name && !!i.name.trim())
          .map((i: any, k: any) => ({
            name: i.name,
            id: i.id,
            reference:
              (i.reference && i.reference.toString()) ||
              `${priorityArea.reference}.${(k + 1).toString()}`,
          })),

        interventions: interventions
          .filter((i: any) => !!i.name && !!i.name.trim())
          .map((i: any, k: any) => ({
            name: i.name,
            id: i.id,
            reference:
              (i.reference && i.reference.toString()) ||
              `${priorityArea.reference}.${(k + 1).toString()}`,
          })),

        financiers: dashboard.enablePriorityAreaFinancials
          ? financiers
              .filter((financier: any) => financier.amountDb !== undefined)
              .map((financier: any) => {
                const newFinancier = structuredClone(financier);

                newFinancier.amount = parseFloat(newFinancier.amountDb);
                newFinancier.amountSpent = parseFloat(
                  newFinancier.amountSpentDb || 0
                );
                delete newFinancier.amountDb;
                delete newFinancier.amountSpentDb;

                newFinancier.financierId = newFinancier.financier.id;
                if (newFinancier.financeMethod?.id) {
                  newFinancier.financeMethodId = newFinancier.financeMethod.id;
                }

                if (newFinancier.procurementMethod?.id) {
                  newFinancier.procurementMethodId =
                    newFinancier.procurementMethod.id;
                }

                delete newFinancier.financeMethod;
                delete newFinancier.procurementMethod;
                delete newFinancier.financier;
                delete newFinancier.fauxId;
                delete newFinancier.__typename;

                return newFinancier;
              })
          : undefined,

        targets: targets
          .filter(
            (target: any) =>
              target.targetAmount !== undefined && !!target.targetUnit?.id
          )
          .map((target: any) => {
            const tgt = { ...target };

            tgt.startedOn = getGraphQLDate(tgt.startedOn);
            tgt.deadline = getGraphQLDate(tgt.deadline);

            tgt.startAmount = Number.parseFloat(tgt.startAmount || 0);
            tgt.targetAmount = Number.parseFloat(tgt.targetAmount || 0);
            tgt.targetFinancedAmount = Number.parseFloat(
              tgt.targetFinancedAmount || 0
            );

            delete tgt.updates;
            delete tgt.fauxId;
            delete tgt.intervention;
            delete tgt.priorityArea;

            if (tgt.targetUnit?.id && tgt.targetUnit?.id === 'new') {
              tgt.targetUnitId = 0;
              tgt.targetUnitName = tgt.targetUnit?.name;
            } else {
              tgt.targetUnitId = tgt.targetUnit?.id;
            }
            delete tgt.targetUnit;

            delete tgt.__typename;

            return tgt;
          }),

        areaFieldValues: priorityArea.areaFieldValues.map((field: any) => {
          const newField = structuredClone(field);

          newField.configId = newField.config.id;
          newField.date = newField.date
            ? getGraphQLDate(newField.date)
            : undefined;
          newField.number = newField.number
            ? Number.parseFloat(newField.number)
            : undefined;
          newField.optionsIds = newField.options?.map(
            (option: any) => option.id
          );

          delete newField.__typename;
          delete newField.config;
          delete newField.options;

          return newField;
        }),

        // area levels response
        parentId: dashboard.enableAreaLevels ? priorityAreaId : undefined,
      };

      setupPriorityAreaMutation({ variables })
        .then(() => {
          setNewFinanciers([]);
          setNewTargets([]);

          setDisabled(true);
        })
        .catch((err) => {
          logError(err);
          setSnackbarIsError(true);
        })
        .finally(() => {
          setSnackbarOpen(true);
          setLoading(false);
        });
    } else {
      setSnackbarMessage(t('Please fill in all required fields'));
      setSnackbarIsError(true);
      setSnackbarOpen(true);
    }
  };

  return (
    <LoadingButton
      id="save-button"
      variant="contained"
      startIcon={<SaveIcon />}
      onClick={handleSubmit}
      loading={loading}
      disableElevation
    >
      {t('Save')}
    </LoadingButton>
  );
}

export default PlanningPriorityAreaFormActionsSave;
