import React from 'react';
import { DashboardContext } from './dashboard-context';

import {
  getContentCount,
  getFilteredContent,
} from '../../_utils/content-filter';

export const TrackerContext = React.createContext<any>(null);

export function TrackerProvider({ content, children }: any) {
  const { dashboard, user, users } = React.useContext(DashboardContext);

  // filter object and for status all
  const [filterObject, setFilterObject] = React.useState<any>({
    status: 'all',
  });

  // content count of all status content
  const contentCount = React.useMemo(
    () => getContentCount({ content, activeUser: user, dashboard }),
    [content, user, dashboard]
  );

  // filtered content and with those with status all
  const filteredContent = React.useMemo(
    () =>
      getFilteredContent({
        content,
        filterObject,
        activeUser: user,
        users,
        dashboard,
      }),
    [content, filterObject, user, users, dashboard]
  );

  // --------------------------------------------------------------------------------------------------------------
  // --------------------------------------------------------------------------------------------------------------
  // handlers
  // --------------------------------------------------------------------------------------------------------------
  // On change filter function
  const changeFilter = React.useCallback((filter: any, value: any) => {
    // Update all the other states
    setFilterObject((prev: any) => ({ ...prev, [filter]: value }));
  }, []);

  // --------------------------------------------------------------------------------------------------------------
  // --------------------------------------------------------------------------------------------------------------
  // memo
  // --------------------------------------------------------------------------------------------------------------

  const tableContext = React.useMemo(() => {
    return {
      filteredContent: filteredContent.content,
      contentCount,
      filterObject,
      setFilterObject: changeFilter,
      filteredUsers: filteredContent.users,
    };
  }, [
    changeFilter,
    contentCount,
    filterObject,
    filteredContent.content,
    filteredContent.users,
  ]);

  // --------------------------------------------------------------------------------------------------------------

  return (
    <TrackerContext.Provider value={tableContext}>
      {children}
    </TrackerContext.Provider>
  );
}

export function useTrackerContext() {
  const context = React.useContext<any>(TrackerContext);
  if (context === undefined) {
    throw new Error('useTrackerContext must be used within a TrackerProvider');
  }
  return context;
}
