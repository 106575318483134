import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { useDashboardContext } from '../../_lib/context/dashboard-context';
import { useReportsContext } from '../../_lib/context/reports-context';

import { ReportsTitle } from '../title';
import { ReportsEditTitle } from './title';
import { ReportsEditFilters } from './filters';
import { ReportEditPdf } from './pdf';
import { ReportsEditActionSave } from '../actions/save';
import { ReportsEditActionsMenu } from '../actions/menu';
import { ReportActionDownload } from '../actions/download';
import { ReportsTemplateDialog } from './template-dialog';
import { ReportsEditReportData } from './report-data';

export function ReportsEdit() {
  const { t } = useTranslation();

  const history = useHistory();
  const { reportId } = useParams() as any;

  const { dashboard } = useDashboardContext();

  const {
    isNew,

    formEl,
    formErrors,

    report,
    setReport,
    reportData,
    setReportData,

    pdfBlob,
    csvBuilders,

    updateFilterDates,

    loading,
    setLoading,
  } = useReportsContext();

  const [showTemplateMeta, setShowTemplateMeta] = React.useState(false);
  const [disableEditTemplateMeta, setDisableEditTemplateMeta] =
    React.useState(false);

  // ------------------------------------------------------------------------------------------------------------------------
  // queries
  // ------------------------------------------------------------------------------------------------------------------------

  // ------------------------------------------------------------------------------------------------------------------------
  // ------------------------------------------------------------------------------------------------------------------------
  // effects
  // ------------------------------------------------------------------------------------------------------------------------

  // reset values in context if new
  React.useLayoutEffect(() => {
    if (isNew) {
      setReport(null);
      updateFilterDates({
        startDate: null,
        endDate: null,
      });
    }
  }, [isNew, setReport, setReportData, updateFilterDates]);

  // loading
  React.useEffect(() => {
    setLoading(!report || !reportData || !pdfBlob || !csvBuilders?.length);
  }, [csvBuilders?.length, pdfBlob, report, reportData, setLoading]);

  // ------------------------------------------------------------------------------------------------------------------------

  return (
    <>
      <form ref={formEl} noValidate autoComplete="off">
        <ReportsTitle
          primary={isNew ? t('New Report') : t('Edit Report')}
          actions={
            <Stack spacing={1} direction="row">
              <Button
                onClick={() =>
                  isNew || !report?.pdf
                    ? history.push(`${t('/reports')}`)
                    : history.push(`${t('/reports')}/${reportId}`)
                }
                disabled={loading}
              >
                {t('Close')}
              </Button>

              {!report?.publishedAt && (
                <ReportsEditActionSave isDraft disabled={loading} />
              )}

              <ReportsEditActionSave disabled={loading} />

              <ReportsEditActionsMenu
                disabled={loading}
                report={report}
                showUnpublish={!!report?.publishedAt}
                setShowTemplateMeta={setShowTemplateMeta}
                setDisableEditTemplateMeta={setDisableEditTemplateMeta}
              />
            </Stack>
          }
        />

        <Container sx={{ py: 2 }}>
          <Paper sx={{ my: 3, px: 3, py: 4 }}>
            <Grid container spacing={3}>
              {/* pdf */}
              <Grid item sm={8} xs={12}>
                <ReportsEditTitle formErrors={formErrors} />

                <Box sx={{ my: 3 }}>
                  <Typography variant="body1" sx={{ mb: 1, fontWeight: 500 }}>
                    {`${t('Preview')}:`}
                  </Typography>

                  <ReportEditPdf />
                </Box>
              </Grid>

              {/* side bar / actions */}
              <Grid item sm={4} xs={12}>
                <Box>
                  <Stack spacing={2}>
                    <ReportActionDownload
                      report={{
                        pdf: {
                          url: pdfBlob ? URL.createObjectURL(pdfBlob) : '',
                          name: dashboard.name,
                        },
                        csvs: csvBuilders,
                      }}
                      disabled={loading}
                    />
                  </Stack>
                </Box>

                <Divider sx={{ mt: 3, mb: 2 }} />

                <ReportsEditFilters />

                {/* todo: add report updates as a drawer */}
                {/* <Divider sx={{ mt: 3, mb: 2 }} /> */}
                {/* <ReportUpdates /> */}
              </Grid>
            </Grid>
          </Paper>
        </Container>
      </form>

      {/* ------------------------------------------------------------------------------------------------------------------------ */}
      {/* dialogs */}
      {/* ------------------------------------------------------------------------------------------------------------------------ */}

      <ReportsTemplateDialog
        isReport
        open={showTemplateMeta}
        setOpen={setShowTemplateMeta}
        disableEdit={disableEditTemplateMeta}
        setDisableEdit={setDisableEditTemplateMeta}
      />

      {/* ------------------------------------------------------------------------------------------------------------------------ */}
      {/* data  */}
      {/* ------------------------------------------------------------------------------------------------------------------------ */}

      <ReportsEditReportData />
    </>
  );
}
