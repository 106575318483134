import { Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';

import { useQuery } from '@apollo/client';
import { ReactErrorComponent } from '../../../../_lib/react-error';
import { mergeArrays } from '../../../../_utils/array';
import { LoadingSpinner } from '../../../../components/loading';
import { formatExecutiveSummaryTableRowData } from '../executive-summary/executive-summary.helpers';
import { EXECUTIVE_SUMMARY_CONTENT_SUMMARY_QUERY } from './executive-summary-content-summary-data';
import ExecutiveSummaryContentTable, {
  ExecutiveSummaryContentTableRows,
  ExecutiveSummaryContentTableRowsProps,
} from './executive-summary-content-table';
import {
  ExecutiveSummaryContentTableContainer,
  TabType,
} from './executive-summary-content-table.styles';
import { ExecutiveSummaryFiltersValues } from '../executive-summary-filter/executive-summary-filter';

type ExecutiveSummaryContent = { id: string } & Partial<{
  archivedAt: string;
  completionDate: string;
  deadline: string;
  deletedAt: string;
  isIssue: boolean;
  title: string;
  url: string;
  urlRelative: string;
}>;

type ExecutiveSummaryContentSummaryProps = {
  longoverdue?: ExecutiveSummaryContent[];
  issues?: ExecutiveSummaryContent[];
  filters?: ExecutiveSummaryFiltersValues;
};

type UseExecutiveSummaryContentSummaryProps = {
  tableData?: ExecutiveSummaryContent[];
  filters?: ExecutiveSummaryFiltersValues;
};

const useExecutiveSummaryContentSummary = (
  props: UseExecutiveSummaryContentSummaryProps
) => {
  const [rowsPerPage, setRowsPerPage] = useState<number>(5);
  const [page, setPage] = useState<number>(0);

  const handleChangePage = (
    _event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const rows =
    rowsPerPage > 0
      ? props?.tableData?.slice(
          page * rowsPerPage,
          page * rowsPerPage + rowsPerPage
        )
      : [];

  const contentIds =
    (rows || [])?.map(
      (
        item: Exclude<
          UseExecutiveSummaryContentSummaryProps['tableData'],
          undefined
        >[number]
      ) => item?.id
    ) || [];

  const { data, loading, error } = useQuery(
    EXECUTIVE_SUMMARY_CONTENT_SUMMARY_QUERY,
    {
      skip: !contentIds?.length,
      variables: {
        contentIds,
      },
    }
  );

  const mergedArrays = mergeArrays<
    'id',
    Exclude<
      UseExecutiveSummaryContentSummaryProps['tableData'],
      undefined
    >[number]
  >('id', data?.contents?.content || [], rows || []);

  useEffect(() => {
    const maxPage = Math.floor(
      (Number((props?.tableData || [])?.length || 0) - 1) / rowsPerPage
    );

    if (maxPage < page) {
      setPage(0);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.filters, rowsPerPage, page]);

  return {
    rows: mergedArrays,
    rowsPerPage,
    page,
    onPageChange: handleChangePage,
    onRowsPerPageChange: handleChangeRowsPerPage,
    isLoading: loading,
    error,
  };
};

function TableLoadingSpinner() {
  return (
    <tr>
      <td aria-label="tt">
        <LoadingSpinner
          sx={{
            padding: '1rem',
          }}
        />
      </td>
    </tr>
  );
}

function ExecutiveSummaryContentSummary(
  props: ExecutiveSummaryContentSummaryProps
) {
  const { t } = useTranslation();

  const formatExecutiveSummaryContentTableRowsData: ExecutiveSummaryContentTableRowsProps<ExecutiveSummaryContent>['formatExecutiveSummaryContentTableRowData'] =
    ({ item }) => {
      const data = formatExecutiveSummaryTableRowData?.({
        item,
        fallbackValue: t('Unknown'),
      });
      return data;
    };

  const {
    rows: overdueRows,
    isLoading: isLoadingOverdueRows,
    error: errorOverdueRows,
    ...paginationPropsOverdue
  } = useExecutiveSummaryContentSummary({
    tableData: props.longoverdue,
    filters: props?.filters,
  });

  const {
    rows: issuesRows,
    isLoading: isLoadingIssuesRows,
    error: errorIssuesRows,
    ...paginationPropsIssues
  } = useExecutiveSummaryContentSummary({
    tableData: props?.issues,
    filters: props?.filters,
  });

  return (
    <Stack spacing={2}>
      <ErrorBoundary FallbackComponent={ReactErrorComponent}>
        <ExecutiveSummaryContentTableContainer>
          <ExecutiveSummaryContentTable
            sectionTitle={t('Long Overdue')}
            tabTitle={`${props.longoverdue?.length || 0} ${t('Most overdue')}`}
            paginationProps={{
              count: props.longoverdue?.length,
              ...paginationPropsOverdue,
            }}
          >
            {isLoadingOverdueRows ? (
              <TableLoadingSpinner />
            ) : (
              <ExecutiveSummaryContentTableRows
                formatExecutiveSummaryContentTableRowData={
                  formatExecutiveSummaryContentTableRowsData
                }
                tableData={overdueRows || []}
              />
            )}
          </ExecutiveSummaryContentTable>
        </ExecutiveSummaryContentTableContainer>
      </ErrorBoundary>

      <ErrorBoundary FallbackComponent={ReactErrorComponent}>
        <ExecutiveSummaryContentTableContainer>
          <ExecutiveSummaryContentTable
            sectionTitle={t('Recently Raised Issues')}
            tabTitle={`${props.issues?.length} ${t('Recently Raised Issues')}`}
            paginationProps={{
              count: props.issues?.length,
              ...paginationPropsIssues,
            }}
            tabProps={{
              tabType: TabType.ISSUE,
            }}
          >
            {isLoadingIssuesRows ? (
              <TableLoadingSpinner />
            ) : (
              <ExecutiveSummaryContentTableRows
                formatExecutiveSummaryContentTableRowData={
                  formatExecutiveSummaryContentTableRowsData
                }
                tableData={issuesRows || []}
              />
            )}
          </ExecutiveSummaryContentTable>
        </ExecutiveSummaryContentTableContainer>
      </ErrorBoundary>
    </Stack>
  );
}

export default ExecutiveSummaryContentSummary;
