import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@mui/material';
import DateRangeIcon from '@mui/icons-material/DateRange';

import { ContentDialogDatesInputPopover } from './popover';

export function ContentDialogDatesInput({ fullWidth, style }: any) {
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'content-dialog-dates-input-popover' : undefined;

  return (
    <>
      {/* Button */}
      <Button
        variant="outlined"
        fullWidth={fullWidth}
        style={style}
        startIcon={<DateRangeIcon />}
        onClick={handleClick}
        aria-describedby={id}
      >
        {t('Dates')}
      </Button>

      <ContentDialogDatesInputPopover
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        open={open}
        id={id}
      />
    </>
  );
}
