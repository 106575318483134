import React from 'react';
import { useQuery } from '@apollo/client';

import Container from '@mui/material/Container';
import Skeleton from '@mui/material/Skeleton';

import { useDashboardContext } from '../_lib/context/dashboard-context';
import { ReactErrorComponent } from '../_lib/react-error';

import { PRIORITY_AREAS_HOME_QUERY } from '../_lib/graphql/queries';
import { PRIORITY_AREAS_HOME_SUBSCRIPTION } from '../_lib/graphql/subscriptions';

import { Homepage } from '.';
import { LayoutDashboard } from '../_layout/dashboard';
import { SubscribeToMoreComponent } from '../components/subscribe-to-more';

export function HomepageLoader() {
  const { navbarFilterVariables } = useDashboardContext();

  const { loading, error, data, subscribeToMore, refetch } = useQuery(
    PRIORITY_AREAS_HOME_QUERY,
    {
      variables: navbarFilterVariables,
    }
  );

  // ------------------------------------------------------------------------------------------------------------------
  // ------------------------------------------------------------------------------------------------------------------
  // effects
  // ------------------------------------------------------------------------------------------------------------------

  // if not data, error, loading refetch
  React.useEffect(() => {
    if (!data && !error && !loading) refetch();
  }, [data, error, loading, refetch]);

  // ------------------------------------------------------------------------------------------------------------------

  // if error
  if (error) {
    return <ReactErrorComponent error={error} />;
  }

  // ------------------------------------------------------------------------------------------------------------------

  return (
    <LayoutDashboard>
      <Container maxWidth={false}>
        {!data?.priorityAreas ? (
          <Container sx={{ my: 4 }}>
            <Skeleton variant="rectangular" height="400px" />
          </Container>
        ) : (
          <Homepage priorityAreas={data.priorityAreas.items} />
        )}
      </Container>

      {/* subscribe to more */}
      {!!data && (
        <SubscribeToMoreComponent
          document={PRIORITY_AREAS_HOME_SUBSCRIPTION}
          variables={navbarFilterVariables}
          subscribeToMore={subscribeToMore}
        />
      )}
    </LayoutDashboard>
  );
}
