import React from 'react';
import { useTranslation } from 'react-i18next';

import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import EditIcon from '@mui/icons-material/Edit';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';

import InputAdornment from '@mui/material/InputAdornment';
import Box from '@mui/material/Box';

import { useDashboardContext } from '../../../_lib/context/dashboard-context';

import { PlanningInterventionFormStatusDialogManage } from './dialog-manage';
import { InterventionStatusChip } from '../../../components/intervention-status-chip';

export function PlanningInterventionFormStatusAutocomplete({
  disabled,
  intervention,
  setIntervention,
}: any) {
  const { t } = useTranslation();

  const {
    dashboard: { statuses },
    user,
  } = useDashboardContext();

  const [statusOptions, setStatusOptions] = React.useState<any>([]);

  const [openManage, setOpenManage] = React.useState(false);

  const [status, setStatus] = React.useState(intervention.status);

  // -------------------------------------------------------------------------------------------------------
  // -------------------------------------------------------------------------------------------------------
  // handlers
  // -------------------------------------------------------------------------------------------------------

  const handleChange = (event: any, newValue: any) => {
    if (newValue?.id !== 'moreOptions') {
      setIntervention({
        ...intervention,
        status: newValue,
      });
    } else {
      setIntervention({
        ...intervention,
        status: null,
      });
    }
  };

  // -------------------------------------------------------------------------------------------------------
  // -------------------------------------------------------------------------------------------------------
  // effects
  // -------------------------------------------------------------------------------------------------------

  React.useEffect(() => {
    setStatusOptions([
      ...statuses,
      {
        id: 'moreOptions',
        name: 'More Options',
      },
    ]);
  }, [statuses]);

  React.useEffect(() => {
    const st = statuses.find((s: any) => s.id === intervention.status?.id);
    setStatus(st);
  }, [intervention.status, statuses]);

  // -------------------------------------------------------------------------------------------------------

  return (
    <>
      <PlanningInterventionFormStatusDialogManage
        open={openManage}
        setOpen={setOpenManage}
      />

      <Autocomplete
        options={statusOptions || []}
        fullWidth
        size="small"
        disabled={disabled}
        renderInput={(params) => (
          <TextField
            {...params}
            label={t('Status')}
            disabled={disabled}
            InputProps={{
              ...params.InputProps,
              inputProps: {
                ...params.inputProps,
                style: {
                  paddingRight: disabled ? '28px' : '0px',
                },
              },
              startAdornment: status ? (
                <InputAdornment position="start">
                  <Box
                    sx={{
                      height: 20,
                      width: 20,
                      bgcolor: status.color,
                      ml: 0.5,
                      opacity: disabled ? 0.7 : 1,
                    }}
                  />
                </InputAdornment>
              ) : null,
            }}
          />
        )}
        getOptionLabel={(option: any) => {
          return option.name;
        }}
        renderOption={(props, option: any) => {
          return option.id === 'moreOptions' && user.isAdmin ? (
            [
              <Divider key="dividerMore" />,
              <MenuItem
                key="edit"
                value="moreOptions"
                onClick={() => setOpenManage(true)}
              >
                <ListItemIcon>
                  <EditIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>{t('Manage')}</ListItemText>
              </MenuItem>,
            ]
          ) : (
            <MenuItem {...props} key={option.id}>
              <InterventionStatusChip status={option} />
            </MenuItem>
          );
        }}
        value={status || null}
        isOptionEqualToValue={(option, newValue) => option.id === newValue.id}
        onChange={handleChange}
        filterOptions={(options: any, params) => {
          const filtered = structuredClone(options) || [];

          return filtered.filter(
            (option: any) =>
              option.name
                ?.toLowerCase()
                .includes(params.inputValue.toLowerCase()) ||
              option.id === 'moreOptions'
          );
        }}
      />
    </>
  );
}
