import React from 'react';

import frLocale from 'date-fns/locale/fr';
import enLocale from 'date-fns/locale/en-GB';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider as MuiLocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import { DashboardContext } from '../context/dashboard-context';

export function LocalizationProvider({ children }: any) {
  const { dashboard } = React.useContext(DashboardContext);
  return (
    <MuiLocalizationProvider
      dateAdapter={AdapterDateFns}
      adapterLocale={dashboard.language === 'fr' ? frLocale : enLocale}
    >
      {children}
    </MuiLocalizationProvider>
  );
}
