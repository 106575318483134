import React from 'react';
import { useTranslation } from 'react-i18next';

import Button from '@mui/material/Button';

import { AdvancedContentStatusLongOverdueDialog } from './dialog';

export function AdvancedContentStatusLongOverdue({ enabled }: any) {
  const { t } = useTranslation();

  const [openDialog, setOpenDialog] = React.useState(false);

  if (!enabled) return null;

  return (
    <>
      <AdvancedContentStatusLongOverdueDialog
        open={openDialog}
        setOpen={setOpenDialog}
      />

      <Button
        onClick={() => setOpenDialog(true)}
        size="small"
        variant="outlined"
      >
        {t('Set "Long Overdue" Days')}
      </Button>
    </>
  );
}
