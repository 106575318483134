import React from 'react';
import { useTranslation } from 'react-i18next';

import SwapHorizRoundedIcon from '@mui/icons-material/SwapHorizRounded';

import { SwitchDashboardDialog } from '../../../../switch-dashboard/dialog';
import { MyAccountPreferencesDetailTitleConfirmDialog } from './confirm-dialog';

export function MyAccountPreferencesDetailTitleSwitchDashboardDialog({
  open,
  setOpen,
  setting,
}: any) {
  const { t } = useTranslation();

  const [openSwitch, setOpenSwitch] = React.useState(false);

  return (
    <>
      <SwitchDashboardDialog
        open={openSwitch}
        setOpen={setOpenSwitch}
        dashboardId={setting.dashboard.id}
      />

      <MyAccountPreferencesDetailTitleConfirmDialog
        title={t('Switch Dashboard?')}
        description={t('Are you sure you want to switch to this dashboard?')}
        confirmButtonLabel={t('Switch')}
        confirmButtonIcon={<SwapHorizRoundedIcon />}
        confirmButtonOnClick={() => setOpenSwitch(true)}
        open={open}
        setOpen={setOpen}
        setting={setting}
      />
    </>
  );
}
