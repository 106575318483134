import { useTranslation } from 'react-i18next';

import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import EditIcon from '@mui/icons-material/Edit';

export function ReportTemplateDialogActions({
  handleClose,
  disableEdit,
  setDisableEdit,
  loading,
}: any) {
  const { t } = useTranslation();

  return (
    <DialogActions>
      <Button variant="outlined" disabled={loading} onClick={handleClose}>
        {t('Close')}
      </Button>
      {!disableEdit ? (
        <LoadingButton
          loading={loading}
          variant="contained"
          type="submit"
          form="report-template-dialog-form"
          startIcon={<SaveIcon />}
          disableElevation
        >
          {t('Save')}
        </LoadingButton>
      ) : (
        <Button
          variant="contained"
          onClick={() => setDisableEdit(false)}
          startIcon={<EditIcon />}
          disableElevation
        >
          {t('Edit')}
        </Button>
      )}
    </DialogActions>
  );
}
