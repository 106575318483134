import React from 'react';
import { gql, useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';

import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';

import { DashboardContext } from '../../_lib/context/dashboard-context';
import { ReactErrorComponent } from '../../_lib/react-error';
import { hasSomeFinance } from '../../_utils/finance-utils';

import { AreaSummaryFinancials } from '../../components/area-summary/financials';
import { SubscribeToMore } from '../../components/subscribe-to-more';

const PA_FRAGMENT = gql`
  fragment PriorityAreaSummaryFinancialsCombinedFragment on PriorityAreaType {
    id

    budget
    currentlySpent

    financiers {
      id
      financier {
        id
        name
      }

      currency

      amount
      amountSpent

      financeMethod {
        id
        name
      }
      procurementMethod {
        id
        name
      }
    }
  }
`;

const PA_QUERY = gql`
  ${PA_FRAGMENT}
  query PriorityAreaSummaryFinancialsCombined(
    $priorityAreaId: ID!
    $filterInterventionIds: [ID!]
    $filterProgrammeIds: [ID!]
    $filterStatusIds: [ID!]
    $filterFinanciersIds: [ID!]
    $filterStakeholdersIds: [ID!]
    $filterRegionsIds: [ID!]
    $filterClassificationsIds: [ID!]
    $filterAreaFieldOptionsIds: [ID!]
    $filterAndOr: String = "AND"
  ) {
    priorityArea(
      priorityAreaId: $priorityAreaId
      filters: {
        interventionIds: $filterInterventionIds
        programmeIds: $filterProgrammeIds
        statusIds: $filterStatusIds
        financiersIds: $filterFinanciersIds
        stakeholdersIds: $filterStakeholdersIds
        regionsIds: $filterRegionsIds
        classificationsIds: $filterClassificationsIds
        areaFieldOptionsIds: $filterAreaFieldOptionsIds
        andOr: $filterAndOr
      }
    ) {
      ...PriorityAreaSummaryFinancialsCombinedFragment
    }
  }
`;

const PA_SUBSCRIPTION = gql`
  ${PA_FRAGMENT}
  subscription PriorityAreaSummaryFinancialsCombined(
    $priorityAreaId: ID!
    $filterInterventionIds: [ID!]
    $filterProgrammeIds: [ID!]
    $filterStatusIds: [ID!]
    $filterFinanciersIds: [ID!]
    $filterStakeholdersIds: [ID!]
    $filterRegionsIds: [ID!]
    $filterClassificationsIds: [ID!]
    $filterAreaFieldOptionsIds: [ID!]
  ) {
    priorityArea(
      priorityAreaId: $priorityAreaId
      filters: {
        interventionIds: $filterInterventionIds
        programmeIds: $filterProgrammeIds
        statusIds: $filterStatusIds
        financiersIds: $filterFinanciersIds
        stakeholdersIds: $filterStakeholdersIds
        regionsIds: $filterRegionsIds
        classificationsIds: $filterClassificationsIds
        areaFieldOptionsIds: $filterAreaFieldOptionsIds
      }
    ) {
      ...PriorityAreaSummaryFinancialsCombinedFragment
    }
  }
`;

export function PriorityAreaSummaryFinancialsCombined({
  hasFinance,
  setHasFinance,
}: any) {
  const { priorityAreaId } = useParams<any>();

  const { dashboard, navbarFilterVariables } =
    React.useContext(DashboardContext);

  const { loading, error, data, refetch, subscribeToMore } = useQuery(
    PA_QUERY,
    {
      variables: {
        priorityAreaId,
        ...navbarFilterVariables,
      },
    }
  );

  // ----------------------------------------------------------------------------------------------------------------
  // ----------------------------------------------------------------------------------------------------------------
  // effects
  // ----------------------------------------------------------------------------------------------------------------

  React.useEffect(() => {
    if (data) {
      setHasFinance(hasSomeFinance([data.priorityArea]));
    }
  }, [data, setHasFinance]);

  // ----------------------------------------------------------------------------------------------------------------

  if (error) return <ReactErrorComponent error={error} />;

  if (loading || !data) {
    if (!data && !loading) refetch();
    return (
      <Grid item xs={12} sm={6} md={5}>
        <Skeleton variant="rectangular" width="100%" height={200} />
      </Grid>
    );
  }

  // ----------------------------------------------------------------------------------------------------------------

  return (
    <Grid item xs={12} sm={hasFinance ? 6 : 0} md={hasFinance ? 5 : 0}>
      {hasFinance && (
        <AreaSummaryFinancials
          id={`pa-${priorityAreaId}-summary-financials`}
          area={{
            ...data.priorityArea,
            totalFunding:
              data.priorityArea?.financiers?.reduce(
                (sum: any, f: any) => sum + f.amount,
                0
              ) || 0,
            currency: dashboard.currency,
          }}
        />
      )}

      {/* subscribe to more */}
      <SubscribeToMore
        subscribeToMore={() =>
          subscribeToMore({
            document: PA_SUBSCRIPTION,
            variables: {
              priorityAreaId,
              ...navbarFilterVariables,
            },
            updateQuery: (prev, { subscriptionData }) => {
              if (!subscriptionData.data) return prev;
              return subscriptionData.data;
            },
          })
        }
        variables={{
          priorityAreaId,
          ...navbarFilterVariables,
        }}
      />
    </Grid>
  );
}
