import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';

import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Typography from '@mui/material/Typography';

import { DashboardContext } from '../../../../_lib/context/dashboard-context';
import { logError } from '../../../../_lib/error';

import {
  TWO_FACTOR_ENABLE,
  TWO_FACTOR_DISABLE,
} from '../../../../_lib/graphql/mutations';
import { CONTEXT_USER_QUERY } from '../../../../_lib/graphql/queries';

import { DialogModal } from '../../../dialog-modal';
import { VerifyPassword } from './verify-password';
import { QRCodeView } from './qrcode-view';
import { BackupCodesView } from './backup-codes';
import { TwoFactorEnableComplete } from './enable-complete';

export function TwoFactorSteps({
  handleClose,
  disabled,
  loading,
  setLoading,
}: any) {
  const { t } = useTranslation();

  const { user, setSnackbarOpen, setSnackbarIsError } =
    React.useContext(DashboardContext);

  const [activeStep, setActiveStep] = React.useState(0);

  const [showCancelDialog, setShowCancelDialog] = React.useState(false);

  const [enableTwoFactorMutation] = useMutation(TWO_FACTOR_ENABLE);
  const [disableTwoFactorMutation] = useMutation(TWO_FACTOR_DISABLE, {
    refetchQueries: [{ query: CONTEXT_USER_QUERY }],
  });

  // ---------------------------------------------------------------------------------------------------------------------
  // ---------------------------------------------------------------------------------------------------------------------
  // handlers
  // ---------------------------------------------------------------------------------------------------------------------

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const enableTwoFactor = () => {
    setLoading(true);
    enableTwoFactorMutation()
      .then(() => {
        handleNext();
      })
      .catch((err) => {
        logError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const disableTwoFactor = () => {
    setLoading(true);
    disableTwoFactorMutation()
      .then(() => {
        setSnackbarOpen(true);
        setLoading(false);
        handleClose();
      })
      .catch((e) => {
        logError(e.message);
        setSnackbarIsError(true);
        setSnackbarOpen(true);
        setLoading(false);
      });
  };

  // ---------------------------------------------------------------------------------------------------------------------

  const enableSteps = [
    {
      label: t('Verify your password'),
      element: (
        <VerifyPassword
          onSuccess={enableTwoFactor}
          disabled={disabled}
          loading={loading}
          setLoading={setLoading}
        />
      ),
    },
    {
      label: t('Setup Authenticator App'),
      element: (
        <QRCodeView
          onSuccess={handleNext}
          disabled={disabled}
          loading={loading}
          setLoading={setLoading}
        />
      ),
    },
    {
      label: t('Download Backup Codes'),
      element: <BackupCodesView onSuccess={handleNext} />,
    },
    {
      label: t('Complete Two Factor Authentication Setup'),
      element: (
        <TwoFactorEnableComplete
          onSuccess={handleClose}
          disabled={disabled}
          loading={loading}
          setLoading={setLoading}
        />
      ),
    },
  ];

  const disableSteps = [
    {
      label: t('Verify your password'),
      element: (
        <VerifyPassword
          onSuccess={disableTwoFactor}
          setLoading={setLoading}
          loading={loading}
        />
      ),
    },
  ];

  const steps = !user?.isTwoFactorEnabled ? enableSteps : disableSteps;

  // ---------------------------------------------------------------------------------------------------------------------

  return (
    <>
      <Box sx={{ maxWidth: 400 }}>
        <Typography>
          {!user?.isTwoFactorEnabled
            ? t('Setup Two Factor Authentication')
            : t('Disable Two Factor Authentication')}
        </Typography>
        <Stepper activeStep={activeStep} orientation="vertical">
          {steps.map((step) => (
            <Step key={step.label}>
              <StepLabel>{step.label}</StepLabel>
              <StepContent>{step.element}</StepContent>
            </Step>
          ))}
        </Stepper>
      </Box>

      {/* confirm cancel dialog */}
      <DialogModal
        open={showCancelDialog}
        onDismiss={() => setShowCancelDialog(false)}
        onSubmit={() => {
          setActiveStep(0);
          handleClose();
          setShowCancelDialog(false);
        }}
        title={t('Cancel Two Factor Setup?')}
        message={t('Are you sure you would like to proceed?')}
      />
    </>
  );
}
