/* eslint-disable no-console */
import * as Sentry from '@sentry/react';

export function logError(error: Error) {
  if (!error) return;
  if (error instanceof ReferenceError) {
    console.warn(error);
  } else {
    console.error(error);
  }
  Sentry.captureException(error);
}
