import React from 'react';
import { useTranslation } from 'react-i18next';

import Button from '@mui/material/Button';

import { AdvancedDashboardColorPaletteDialog } from './dialog';

export function AdvancedDashboardColorPalette({ enabled }: any) {
  const { t } = useTranslation();

  const [openDialog, setOpenDialog] = React.useState(false);

  if (!enabled) return null;

  return (
    <>
      <AdvancedDashboardColorPaletteDialog
        open={openDialog}
        setOpen={setOpenDialog}
      />
      <Button
        variant="outlined"
        size="small"
        onClick={() => setOpenDialog(true)}
      >
        {t('Manage Dashboard Colours')}
      </Button>
    </>
  );
}
