import React from 'react';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { Column } from '@material-table/core';

import { useDashboardContext } from '../../../_lib/context/dashboard-context';

export function StatusColumn({ content }: any) {
  const { t } = useTranslation();

  const {
    dashboard: { metaStatusColors },
  } = useDashboardContext();

  const isGroup = typeof content === 'string';
  const contentStatus = isGroup ? content : content?.status;

  return metaStatusColors[contentStatus] ? (
    <Box
      sx={{
        display: isGroup ? 'inline-flex' : 'flex',
        justifyContent: 'center',
      }}
    >
      <Box
        sx={{
          bgcolor: metaStatusColors[contentStatus].bgcolor,
          color: metaStatusColors[contentStatus].textColor,
          borderRadius: 1,
          px: 1.4,
          py: 0.8,
          whiteSpace: 'nowrap',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Typography component="span" variant="caption" sx={{ lineHeight: 1 }}>
          {t(metaStatusColors[contentStatus].text)}
        </Typography>
      </Box>
    </Box>
  ) : null;
}

// Status
export const statusColumn = ({ t }: any) =>
  ({
    title: t('Status'),
    headerStyle: {
      textAlign: 'center',
    },
    field: 'status',
    render: (content: any) => <StatusColumn content={content} />,
  } as Column<any>);
