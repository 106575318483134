import React from 'react';
import { isEqual } from 'lodash';
import { useTranslation } from 'react-i18next';

import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Checkbox from '@mui/material/Checkbox';
import Divider from '@mui/material/Divider';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

import LaunchIcon from '@mui/icons-material/Launch';
import EditIcon from '@mui/icons-material/Edit';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { useDashboardContext } from '../../_lib/context/dashboard-context';
import { MemberAutocomplete } from '../../components/member-autocomplete';

import { getGraphQLDate } from '../../_utils/general-utils';
import { SetupMilestones } from '../../actions/setup-milestones';
import { DeleteMilestone } from './delete-milestone';

export function SetupMilestoneDetail({
  content,
  intervention,
  newMilestones,
  setNewMilestones,
}: any) {
  const { t } = useTranslation();

  const { dashboard, users } = useDashboardContext();

  const [ogMilestone, setOgMilestone] = React.useState(content);
  const [milestone, setMilestone] = React.useState(content);

  const [errors, setErrors] = React.useState<any>({});
  const [disabled, setDisabled] = React.useState(!!content.id);
  const [loading, setLoading] = React.useState(false);

  // --------------------------------------------------------------------------------------------------------
  // --------------------------------------------------------------------------------------------------------
  // handlers
  // --------------------------------------------------------------------------------------------------------

  const valid = () => {
    const newErrors = {} as any;
    if (!milestone.title.trim()) newErrors.title = 'You must include a title';
    setErrors({ ...newErrors });
    return Object.keys(newErrors).length === 0;
  };

  const handleCancel = () => {
    if (milestone.fauxId) {
      setNewMilestones(
        newMilestones.filter((m: any) => m.fauxId !== milestone.fauxId)
      );
    } else {
      setMilestone(structuredClone(content));
      setDisabled(true);
    }
  };

  const handleChange = (value: any) => {
    if (!dashboard.enableActivityMultipleAssignees) {
      setMilestone((old: any) => ({ ...old, assignee: value }));
    } else {
      setMilestone((old: any) => ({ ...old, assignees: value }));
    }
  };

  // --------------------------------------------------------------------------------------------------------
  // --------------------------------------------------------------------------------------------------------
  // effects
  // --------------------------------------------------------------------------------------------------------

  // content update
  React.useEffect(() => {
    if (!isEqual(content, ogMilestone)) {
      setOgMilestone(content);
    }
    if (!isEqual(content, milestone) && disabled) {
      setMilestone(content);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content]);

  // --------------------------------------------------------------------------------------------------------

  return (
    <Card id="content-form-card" sx={{ my: 3 }} variant="outlined">
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={8} md={8}>
            <Stack spacing={3}>
              {/* Title */}
              <TextField
                label={t('Title')}
                disabled={disabled || loading}
                value={milestone.title || ''}
                onChange={(event) =>
                  setMilestone({
                    ...milestone,
                    title: event.target.value,
                  })
                }
                fullWidth
                size="small"
                helperText={errors.title}
                error={!!errors.title}
                required
              />

              {/* Description */}
              <TextField
                label={t('Further instructions / details')}
                disabled={disabled || loading}
                value={milestone.description || ''}
                onChange={(event) =>
                  setMilestone({
                    ...milestone,
                    description: event.target.value,
                  })
                }
                fullWidth
                size="small"
                helperText={errors.description}
                error={!!errors.description}
                multiline
                minRows={4}
              />
            </Stack>
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <Stack spacing={2}>
              {/* Assignee */}
              <MemberAutocomplete
                label={t('Assignee')}
                disabled={disabled || loading}
                multiple={dashboard.enableActivityMultipleAssignees}
                value={
                  !dashboard.enableActivityMultipleAssignees
                    ? users.find((u: any) => u.id === milestone?.assignee?.id)
                    : users.filter((u: any) =>
                        milestone?.assignees
                          ?.map((a: any) => a.id)
                          .includes(u.id)
                      )
                }
                onChange={(event: any, user: any) => handleChange(user)}
              />

              {/* Already completed */}
              <FormGroup>
                <FormControlLabel
                  disabled={disabled || loading}
                  control={
                    <Checkbox
                      checked={!!milestone.completionDate || false}
                      onChange={() =>
                        setMilestone({
                          ...milestone,
                          completionDate: !milestone.completionDate,
                        })
                      }
                      size="small"
                    />
                  }
                  label={t('Already completed?') as string}
                />
              </FormGroup>

              {/* Start date */}
              {dashboard.enableActivityStartDate && (
                <DatePicker
                  label={t('Start Date')}
                  value={
                    milestone.startDate ? new Date(milestone.startDate) : null
                  }
                  onChange={(newValue: any) => {
                    setMilestone((oldVal: any) => ({
                      ...oldVal,
                      startDate: newValue ? getGraphQLDate(newValue) : null,
                    }));
                  }}
                  slotProps={{
                    actionBar: {
                      actions: ['clear'],
                    },
                    textField: {
                      size: 'small',
                    },
                  }}
                  disabled={disabled || loading}
                  maxDate={new Date(milestone.deadline)}
                />
              )}

              {/* Deadline */}
              <DatePicker
                label={
                  milestone.completionDate
                    ? t('Completion date')
                    : t('Deadline')
                }
                value={milestone.deadline ? new Date(milestone.deadline) : null}
                onChange={(newValue: any) => {
                  setMilestone({
                    ...milestone,
                    deadline: getGraphQLDate(newValue),
                  });
                }}
                slotProps={{
                  textField: {
                    size: 'small',
                    required: true,
                  },
                }}
                disabled={disabled || loading}
                minDate={
                  milestone.startDate
                    ? new Date(milestone.startDate)
                    : undefined
                }
              />
            </Stack>
          </Grid>
        </Grid>
      </CardContent>
      <CardActions sx={{ justifyContent: 'flex-end', p: 2 }}>
        {/* View / Delete / Edit Butons */}
        {disabled ? (
          <>
            {/* View */}
            {!dashboard.inPlanning && !milestone.fauxId && (
              <>
                <Button
                  startIcon={<LaunchIcon />}
                  onClick={() => window.open(milestone.url, '_blank')!.focus()}
                  color="info"
                  size="small"
                >
                  {t('View')}
                </Button>
                <Divider orientation="vertical" flexItem sx={{ mx: 1 }} />
              </>
            )}

            {/* Delete */}
            <DeleteMilestone
              milestone={milestone}
              newMilestones={newMilestones}
              setNewMilestones={setNewMilestones}
            />

            {/* Edit */}
            <Button
              id="edit-content"
              startIcon={<EditIcon />}
              onClick={() => setDisabled(false)}
              variant="outlined"
              size="small"
            >
              {t('Edit')}
            </Button>
          </>
        ) : (
          <>
            {/* Cancel */}
            <Button
              onClick={handleCancel}
              variant="outlined"
              size="small"
              disabled={loading}
            >
              {t('Cancel')}
            </Button>

            {/* Save */}
            <SetupMilestones
              valid={valid}
              intervention={intervention}
              milestone={milestone}
              setDisabled={setDisabled}
              newMilestones={newMilestones}
              setNewMilestones={setNewMilestones}
              loading={loading}
              setLoading={setLoading}
            />
          </>
        )}
      </CardActions>
    </Card>
  );
}
