import React from 'react';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Popover from '@mui/material/Popover';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import CloseIcon from '@mui/icons-material/Close';

import { useDashboardContext } from '../../../_lib/context/dashboard-context';
import { useContentDialogContext } from '../../../_lib/context/content-dialog-context';

import { DateInput } from '../../../components/date-input';

import { ContentDialogDatesInputSaveBtn } from './save-btn';

export function ContentDialogDatesInputPopover({
  anchorEl,
  setAnchorEl,
  open,
  id,
}: any) {
  const { t } = useTranslation();

  const {
    dashboard: { enableActivityStartDate, enableActivityCompletedOnEdit },
  } = useDashboardContext();
  const { content } = useContentDialogContext();

  const [startDate, setStartDate] = React.useState(content.startDate);
  const [deadline, setDeadline] = React.useState(content.deadline);
  const [completionDate, setCompletionDate] = React.useState(
    content.completionDate
  );

  const [loading, setLoading] = React.useState(false);
  const [showError, setShowError] = React.useState(false);

  // ------------------------------------------------------------------------------------------------------------------
  // ------------------------------------------------------------------------------------------------------------------
  // handlers
  // ------------------------------------------------------------------------------------------------------------------

  const handleClose = () => {
    setDeadline(content.deadline);
    setStartDate(content.startDate);
    setCompletionDate(content.completionDate);
    setAnchorEl(null);
  };

  // ------------------------------------------------------------------------------------------------------------------
  // ------------------------------------------------------------------------------------------------------------------
  // effects
  // ------------------------------------------------------------------------------------------------------------------

  React.useEffect(() => {
    if (setDeadline) setDeadline(content.deadline);
    if (setStartDate) setStartDate(content.startDate);
    if (setCompletionDate) setCompletionDate(content.completionDate);
  }, [content.deadline, content.startDate, content.completionDate]);

  // ------------------------------------------------------------------------------------------------------------------

  return (
    <>
      {/* Popover */}

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
      >
        <Box
          sx={{
            pb: 2,
            px: 2,
            width: '305px',
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
            <Typography variant="h6" sx={{ flexGrow: 1 }}>
              {t('Dates')}
            </Typography>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              disabled={loading}
              size="large"
            >
              <CloseIcon />
            </IconButton>
          </Box>

          <Divider sx={{ mt: 1, mb: 2 }} />

          <Stack spacing={2}>
            {/* Start Date */}
            {enableActivityStartDate && (
              <DateInput
                date={startDate}
                setDate={setStartDate}
                label={t('Start Date')}
                id="content-dialog-start-date-input"
                fullWidth
                max={deadline}
                loading={loading}
              />
            )}

            {/* Deadline */}
            <DateInput
              date={deadline}
              setDate={setDeadline}
              label={`${t('Deadline')} *`}
              id="content-dialog-deadline-input"
              fullWidth
              min={startDate}
              loading={loading}
            />

            {/* Completion Date */}
            {enableActivityCompletedOnEdit && content.completionDate && (
              <DateInput
                date={completionDate}
                setDate={setCompletionDate}
                label={`${t('Completed On')} *`}
                id="content-dialog-completed-on-input"
                fullWidth
                min={startDate}
                loading={loading}
                max={new Date()}
              />
            )}
          </Stack>

          {/* buttons */}
          <Stack spacing={1.5} sx={{ mt: 2 }}>
            {showError && (
              <Typography variant="body2" gutterBottom color="error">
                {t('Something went wrong. Please try again.')}
              </Typography>
            )}
            <ContentDialogDatesInputSaveBtn
              setAnchorEl={setAnchorEl}
              setLoading={setLoading}
              loading={loading}
              content={content}
              startDate={startDate}
              deadline={deadline}
              completionDate={completionDate}
              setShowError={setShowError}
            />

            <Button
              fullWidth
              variant="outlined"
              onClick={handleClose}
              disabled={loading}
            >
              {t('Cancel')}
            </Button>
          </Stack>
        </Box>
      </Popover>
    </>
  );
}
