import { Draggable } from 'react-beautiful-dnd';

export function DraggableAreaDetail({
  children,
  detail,
  index,
  isDragDisabled,
}: any) {
  return (
    <Draggable
      draggableId={detail.id || detail.fauxId || 'new'}
      index={index}
      isDragDisabled={isDragDisabled}
    >
      {(providedDraggable) => (
        <div
          ref={providedDraggable.innerRef}
          {...providedDraggable.draggableProps}
          {...providedDraggable.dragHandleProps}
        >
          {children}
        </div>
      )}
    </Draggable>
  );
}
