import React from 'react';
import { useTranslation } from 'react-i18next';

import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import Skeleton from '@mui/material/Skeleton';
import Box from '@mui/material/Box';

import { useReportsContext } from '../../../../_lib/context/reports-context';
import { useDashboardContext } from '../../../../_lib/context/dashboard-context';

import { ReportsEditFiltersAreasMenu } from './menu';
import { ReportsEditFiltersAreasListPa } from './list-pa';

export function ReportsEditFiltersAreas() {
  const { t } = useTranslation();

  const { dashboard } = useDashboardContext();
  const { report, reportData, isNew, setFilterAreas } = useReportsContext();

  const [open, setOpen] = React.useState<any>([]);
  const [checked, setChecked] = React.useState<any>([]);

  const priorityAreas = React.useMemo(
    () => [
      {
        id: 'overview',
        name: t('Overview'),
      },
      ...(reportData?.priorityAreas || []),
    ],
    [reportData?.priorityAreas, t]
  );

  // -------------------------------------------------------------------------------------------------------------
  // -------------------------------------------------------------------------------------------------------------
  // handlers
  // -------------------------------------------------------------------------------------------------------------

  const handleOpen = React.useCallback((paId: any) => {
    setOpen((prev: any) => {
      const currentIndex = prev.indexOf(paId);
      const newOpen = [...prev];

      if (currentIndex === -1) {
        newOpen.push(paId);
      } else {
        newOpen.splice(currentIndex, 1);
      }
      return newOpen;
    });
  }, []);

  // check or uncheck all pa interventions and children
  const checkOrUncheck = React.useCallback(
    (pa: any, newChecked: any, check = true) => {
      // interventions
      pa?.interventionsDb?.forEach((intervention: any) => {
        const intvIndex = newChecked.indexOf(`intv-${intervention.id}`);
        if (intvIndex === -1 && check) {
          newChecked.push(`intv-${intervention.id}`);
        }
        if (intvIndex !== -1 && !check) {
          newChecked.splice(intvIndex, 1);
        }
      });

      // children
      if (dashboard.enableAreaLevels) {
        pa?.children?.forEach((child: any) => {
          const childIndex = newChecked.indexOf(`pa-${child.id}`);
          if (childIndex === -1 && check) {
            newChecked.push(`pa-${child.id}`);
          }
          if (childIndex !== -1 && !check) {
            newChecked.splice(childIndex, 1);
          }

          const childPa = reportData?.priorityAreas.find(
            (paf: any) => paf.id === child.id
          );
          checkOrUncheck(childPa, newChecked, check);
        });
      }
    },
    [dashboard.enableAreaLevels, reportData?.priorityAreas]
  );

  const handleToggle = React.useCallback(
    (areaId: any) => () => {
      setChecked((prev: any) => {
        const newChecked = [...prev];
        const currentIndex = newChecked.indexOf(areaId);

        if (currentIndex === -1) {
          newChecked.push(areaId);
        } else {
          newChecked.splice(currentIndex, 1);
        }

        // check or uncheck all interventions of this pa
        if (areaId.startsWith('pa-')) {
          const paId = areaId.split('-')[1];
          const pa = reportData?.priorityAreas.find(
            (paf: any) => paf.id === paId
          );

          checkOrUncheck(pa, newChecked, currentIndex === -1);
        }

        return newChecked;
      });
    },
    [checkOrUncheck, reportData?.priorityAreas]
  );

  const checkAll = React.useCallback(() => {
    const getPaIds = (pa: any) => {
      const paIds = [pa.id];
      pa.children?.forEach((child: any) => {
        paIds.push(...getPaIds(child));
      });
      return paIds;
    };

    const getIntvsIds = (pa: any) => {
      const intvsIds = pa.interventionsDb?.map((intv: any) => intv.id) || [];
      pa.children?.forEach((child: any) => {
        intvsIds.push(...getIntvsIds(child));
      });
      return intvsIds;
    };

    const priorityAreasIds: any = [];
    const interventionsIds: any = [];

    priorityAreas?.forEach((pa: any) => {
      priorityAreasIds.push(...getPaIds(pa));
      interventionsIds.push(...getIntvsIds(pa));
    });

    setChecked([
      ...priorityAreasIds.map((paId: any) => `pa-${paId}`),
      ...interventionsIds.map((intvId: any) => `intv-${intvId}`),
    ]);
  }, [priorityAreas]);

  // -------------------------------------------------------------------------------------------------------------
  // -------------------------------------------------------------------------------------------------------------
  // effects
  // -------------------------------------------------------------------------------------------------------------

  // set default values if new
  React.useEffect(() => {
    if (isNew && reportData?.priorityAreas) {
      checkAll();
    }
  }, [reportData, isNew, checkAll]);

  //  set values from report
  React.useEffect(() => {
    if (!isNew && report?.id) {
      setChecked([
        ...(report.hasFilterOverview ? ['pa-overview'] : []),
        ...report.filterAreas
          .map((fa: any) => {
            if (fa.priorityArea) return `pa-${fa.priorityArea.id}`;
            if (fa.intervention) return `intv-${fa.intervention.id}`;
            return null;
          })
          .filter((fa: any) => fa),
      ]);
    }
  }, [report, isNew]);

  // set filterareas from checked
  React.useEffect(() => {
    setFilterAreas(checked);
  }, [checked, setFilterAreas]);

  // -------------------------------------------------------------------------------------------------------------

  if (Object.keys(reportData || {}).length === 0)
    return <Skeleton variant="rectangular" height={400} />;

  // -------------------------------------------------------------------------------------------------------------

  return (
    <List
      sx={{ width: '100%', bgcolor: 'background.paper' }}
      aria-labelledby="areas-list-subheader"
      subheader={
        <ListSubheader
          component="div"
          id="areas-list-subheader"
          sx={{ display: 'flex', columnGap: 1, alignItems: 'center' }}
        >
          {!dashboard.enableAreaLevels
            ? dashboard.priorityAreaName
            : dashboard.areaLevels[0].name}{' '}
          &amp; {dashboard.interventionName}
          <Box sx={{ ml: 'auto' }}>
            <ReportsEditFiltersAreasMenu
              setChecked={setChecked}
              checkAll={checkAll}
            />
          </Box>
        </ListSubheader>
      }
    >
      {/* pas */}
      {reportData?.priorityAreas?.length ? (
        priorityAreas?.map((pa: any) => (
          <ReportsEditFiltersAreasListPa
            key={pa.id}
            priorityArea={pa}
            checked={checked}
            open={open}
            handleOpen={handleOpen}
            handleToggle={handleToggle}
          />
        ))
      ) : (
        <ListItem>
          <ListItemText primary={t('No areas found')} />
        </ListItem>
      )}
    </List>
  );
}
