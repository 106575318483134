import { GraphqlPrefetchQueriesHomepage } from './homepage';
import { GraphqlPrefetchQueriesMyContent } from './my-content';

export function GraphqlPrefetchQueries() {
  // --------------------------------------------------------------------------------------------------------

  return (
    <>
      {/* my-content */}
      <GraphqlPrefetchQueriesMyContent />

      {/* homepage + todo: areas */}
      <GraphqlPrefetchQueriesHomepage />

      {/* todo: /tracker */}

      {/* todo: reports + report-data */}
    </>
  );
}
