import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  split,
  concat,
} from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';
import { persistCache, LocalStorageWrapper } from 'apollo3-cache-persist';

import { RetryLink } from '@apollo/client/link/retry';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';

import { getMainDefinition } from '@apollo/client/utilities';
import { getApiHost, getApiURL } from '../general-utils';

// ------------------------------------------------------------------------------------------------------

const apiHost = getApiHost();

const apiUrl = getApiURL();

// ------------------------------------------------------------------------------------------------------

const uploadLink = createUploadLink({
  uri: `${apiUrl}/graphql/`,
  credentials: 'include',
}) as any;

const retryLink = new RetryLink({
  delay: {
    initial: 300,
    max: Infinity,
    jitter: true,
  },
  attempts: {
    max: 5,
    retryIf: (error) => !!error,
  },
});

const wsLink = new GraphQLWsLink(
  createClient({
    url: `${
      new URL(apiUrl).protocol === 'http:' ? ' ws' : 'wss'
    }://${apiHost}/graphql/`,
  })
);

// ------------------------------------------------------------------------------------------------------

// The split function takes three parameters:
//
// * A function that's called for each operation to execute
// * The Link to use for an operation if the function returns a "truthy" value
// * The Link to use for an operation if the function returns a "falsy" value
const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  concat(retryLink, uploadLink)
);

// ------------------------------------------------------------------------------------------------------

// cache

const cache = new InMemoryCache();

async function runPersistCache() {
  await persistCache({
    cache,
    storage: new LocalStorageWrapper(window.localStorage),
  });
}
runPersistCache();

// ------------------------------------------------------------------------------------------------------

const client = new ApolloClient({
  cache,
  link: splitLink,
  credentials: 'include',
  defaultOptions: {
    watchQuery: {
      nextFetchPolicy: 'cache-only',
    },
  },
});

// ------------------------------------------------------------------------------------------------------

export function ProvideApollo({ children }: any) {
  return <ApolloProvider client={client}>{children}</ApolloProvider>;
}
