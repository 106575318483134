import React from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorBoundary } from 'react-error-boundary';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import LinearProgress from '@mui/material/LinearProgress';

import LoadingButton from '@mui/lab/LoadingButton';

import { isEqual } from 'lodash';

import { useContentDialogContext } from '../../_lib/context/content-dialog-context';
import { useDashboardContext } from '../../_lib/context/dashboard-context';
import { useRouterContext } from '../../_lib/context/router-context';

import { ReactErrorComponent } from '../../_lib/react-error';

import { DialogTitle } from '../../DialogTitle/dialog-title';
import { DialogModal } from '../../components/dialog-modal';

import { ContentGroupSelect } from './content-group-select';
import { ContentFormInterventionSelect } from './intervention-select';
import { ContentFormTitle } from './title';
import { ContentFormDescription } from './description';
import { ContentFormTypeSelect } from './type-select';
import { ContentFormDates } from './dates';
import { ContentAssigneeSelect } from '../assignee-select';

export function ContentFormForm({
  loading,
  handleSubmit: handleMutationSubmit,
}: any) {
  const { t } = useTranslation();

  const { user, dashboard } = useDashboardContext();
  const { interventionId } = useRouterContext();

  const {
    content: dialogContent,
    contentForm,
    setContentForm,
  } = useContentDialogContext();

  const [content, setContent] = React.useState<any>(
    contentForm?.isEdit
      ? dialogContent
      : {
          type: contentForm?.defaultContentType,
          ...(interventionId ? { intervention: { id: interventionId } } : {}),
        }
  );

  const [errors, setErrors] = React.useState<any>({});

  const [openConfirmDialog, setOpenConfirmDialog] = React.useState(false);

  // -------------------------------------------------------------------------------------------------------------
  // -------------------------------------------------------------------------------------------------------------
  // handlers
  // -------------------------------------------------------------------------------------------------------------

  // change value
  const changeValue = (value: any, valueType: any) => {
    setContent((prev: any) => ({
      ...prev,
      [valueType]: value,
    }));

    // reset on type change
    if (valueType === 'type') {
      setContent((prev: any) => ({
        ...prev,
        isIssue: value === 'issue',
        isMilestone: value === 'milestone',
      }));
    }

    // reset errors
    let errorType = valueType;
    if (errorType === 'interventionId') errorType = 'intervention';
    if (errorType === 'assigneeId') errorType = 'assignee';
    if (value) {
      setErrors((prev: any) => ({
        ...prev,
        [errorType]: false,
      }));
    }
  };

  const valid = () => {
    const newErrors = {} as any;
    if (!content.title || !content.title.trim()) {
      newErrors.title = t('You must include a title');
    }
    if (content?.type === 'activity') {
      if (
        dashboard.enableActivityMultipleAssignees
          ? !content?.assignees?.length
          : !content?.assignee?.id
      ) {
        newErrors.assignee = t(
          'You must select an assignee. Alternatively, you can raise an issue.'
        );
      }
    }
    if (!content.deadline && content?.type !== 'issue') {
      newErrors.deadline = t(
        'You must include a deadline. Alternatively, you can raise an issue.'
      );
    }

    if (!content.intervention?.id && content?.type !== 'issue') {
      newErrors.intervention = `${t('You must select an')} ${
        dashboard.interventionName
      }`;
    }

    setErrors({ ...newErrors });
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = () => {
    const contentObject = {
      id: content.id,

      assigneeId:
        content.type !== 'issue' ? content.assignee?.id || null : undefined,
      assigneesIds:
        content.type !== 'issue'
          ? content.assignees?.map((a: any) => a.id) || null
          : undefined,
      unAssign: content.type !== 'issue' ? content.unAssign : undefined,

      title: content.title,
      description: content.description,
      interventionId: content?.intervention?.id,

      groupId: content.group?.id,
      unGroup: content.unGroup,

      isIssue: content.type === 'issue',
      isMilestone: content.type === 'milestone',

      startDate: content.type !== 'issue' ? content.startDate : undefined,
      deadline: content.type !== 'issue' ? content.deadline : undefined,
      completionDate:
        content.type !== 'issue'
          ? content?.completionDate
            ? content?.deadline
            : null
          : undefined,
    } as any;

    return handleMutationSubmit(contentObject);
  };

  const handleConfirm = () => {
    if (valid()) {
      if (dashboard.enableActivityConfirmSave) {
        setOpenConfirmDialog(true);
      } else {
        handleSubmit();
      }
    }
  };

  const handleDismiss = () => {
    setContentForm(null);
  };

  // -------------------------------------------------------------------------------------------------------------
  // -------------------------------------------------------------------------------------------------------------
  // effects
  // -------------------------------------------------------------------------------------------------------------

  // confirm form set confirm dialog closed
  React.useEffect(() => {
    if (!contentForm || isEqual(contentForm, {})) {
      setOpenConfirmDialog(false);
    }
  }, [contentForm]);

  // -------------------------------------------------------------------------------------------------------------

  if (!contentForm) return null;

  return (
    <>
      {/* confirm save dialog */}
      <DialogModal
        open={openConfirmDialog}
        onDismiss={() => setOpenConfirmDialog(false)}
        onSubmit={() => handleSubmit().then(() => setOpenConfirmDialog(false))}
        loading={loading}
        title={t('Confirm Save')}
        message={<Typography>{t('Are you sure you want to save?')}</Typography>}
      />

      {/* content form dialog */}
      <Dialog fullWidth maxWidth="sm" open>
        <DialogTitle onClose={handleDismiss}>
          <Box
            sx={{
              display: 'flex',
              flexGrow: 1,
              gap: 1,
              alignItems: { xs: 'flex-start', sm: 'center' },
              flexDirection: {
                xs: 'column',
                sm: 'row',
              },
            }}
          >
            <Box sx={{ mr: 2, whiteSpace: 'nowrap' }}>
              {contentForm?.isEdit ? t('Modify') : t('Create')}
              {' : '}
            </Box>
            <Box sx={{ flexGrow: 1 }}>
              <ContentFormTypeSelect
                content={content}
                changeValue={changeValue}
                loading={loading}
                errors={errors}
              />
            </Box>
          </Box>
        </DialogTitle>

        {/* loading linear progress */}
        {loading && <LinearProgress />}

        <DialogContent dividers>
          <ErrorBoundary FallbackComponent={ReactErrorComponent}>
            <Stack spacing={3}>
              {/* title */}
              <ContentFormTitle
                content={content}
                changeValue={changeValue}
                loading={loading}
                errors={errors}
              />

              {/* assignee */}
              {content?.type !== 'issue' && (
                <Box>
                  <ContentAssigneeSelect
                    content={content}
                    setContent={setContent}
                    loading={loading}
                    errors={errors}
                  />
                </Box>
              )}

              {/* description */}
              <ContentFormDescription
                content={content}
                changeValue={changeValue}
                loading={loading}
                errors={errors}
              />

              {/* activity phase */}
              {dashboard.enableActivityGroup && (
                <Box>
                  <ContentGroupSelect
                    content={content}
                    setContent={setContent}
                    disabled={loading}
                  />
                </Box>
              )}

              {/* new activity / milestone : already completed ? and deadline */}
              {content?.type !== 'issue' && (
                <ContentFormDates
                  content={content}
                  setContent={setContent}
                  loading={loading}
                  errors={errors}
                />
              )}

              {/* intervention select */}
              {user.hasAnyInterventionEditPermission && (
                <>
                  <Divider />
                  <ContentFormInterventionSelect
                    content={content}
                    setContent={setContent}
                    errors={errors}
                  />
                </>
              )}
            </Stack>
          </ErrorBoundary>
        </DialogContent>

        <DialogActions>
          <Button
            variant="outlined"
            onClick={handleDismiss}
            disabled={loading}
            disableElevation
          >
            {t('Cancel')}
          </Button>
          <LoadingButton
            variant="contained"
            onClick={handleConfirm}
            loading={loading}
            disableElevation
          >
            {t('Save')}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
