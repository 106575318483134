import React from 'react';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

export function AdvancedAreaFieldsDetailTypeSelect({
  configEdit,
  setConfigEdit,
  disabled,
  fieldTypes,
}: any) {
  const { t } = useTranslation();

  const handleChange = (event: SelectChangeEvent) => {
    setConfigEdit({ ...configEdit, fieldType: event.target.value });
  };

  return (
    <Box>
      <FormControl fullWidth required size="small" disabled={disabled}>
        <InputLabel id="area-field-type-select-label">{t('Type')}</InputLabel>
        <Select
          labelId="area-field-type-select-label"
          id="area-field-type-select"
          value={configEdit.fieldType}
          label={t('Type')}
          onChange={handleChange}
        >
          {Object.keys(fieldTypes).map((key) => (
            <MenuItem key={key} value={key}>
              {fieldTypes[key]}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}
