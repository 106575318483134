export const chartColors = [
  '#007681',
  '#673ab7',
  '#358928',
  '#8fd24c',
  '#4DC3CE',
  'gray',
  'green',
  'aqua',
  'blue',
  'lightgreen',
  'lime',
  'maroon',
  'navy',
  'olive',
  'orange',
  'purple',
  'red',
  'silver',
  'teal',
  'orangered',
  'lightcoral',
  'burlywood',
  'teal',
  'turquoise',
];

// get random color hex code: https://stackoverflow.com/a/1484514/18322745
export const getRandomColor = () => {
  const letters = '0123456789ABCDEF';
  let color = '#';
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

export const chartFonts = {
  chartHeader: '.95rem',
  tableHeader: '0.75rem',
  tableBody: '0.75rem',
};

export const tableHeadBgColor = 'grey.200';

export const tableHeaderStyles = {
  bgcolor: tableHeadBgColor,
  fontWeight: 'bold',
  fontSize: chartFonts.tableHeader,
  color: 'grey.800',
};

export const tableBodyStyles = {
  fontSize: chartFonts.tableBody,
  px: 0.8,
  py: 0.4,
};

export const chartTitleStyles = {
  fontSize: chartFonts.chartHeader,
  mb: 1,
  fontWeight: '600',
  color: 'grey.800',
  textAlign: 'center',
};

// Truncate long names e.g. priority area name for chart axis labels
const LABEL_LENGTH = 10;

export const truncateChartLabel = (
  name: string,
  length: number | null = null
) => {
  return `${name.substring(0, length || LABEL_LENGTH)}...`;
};

export interface ChartDefaultProps {
  title?: string;
  description?: string;
}
