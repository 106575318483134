import React from 'react';
import { useTranslation } from 'react-i18next';
import { gql, useQuery } from '@apollo/client';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Skeleton from '@mui/material/Skeleton';

import FlagOfNone from '../../../../../assets/img/icons/flag-of-none.svg';
import { getMediaUrl } from '../../../../_utils/general-utils';
import { logError } from '../../../../_lib/error';

const RTM_FRAGMENT = gql`
  fragment ReportTemplateDialogMetaInputsFlagFragment on ReportTemplateMetaType {
    id
    key
    value
    file {
      name
      url
    }
  }
`;

const RTM_QUERY = gql`
  query ReportTemplateDialogMetaInputsFlagQuery($id: ID) {
    reportTemplateMeta(id: $id) {
      ...ReportTemplateDialogMetaInputsFlagFragment
    }
  }
  ${RTM_FRAGMENT}
`;

export function ReportTemplateDialogMetaInputsFlag({
  disabled,
  templateMeta,
  updateTemplateMeta,
}: any) {
  const { t } = useTranslation();

  const countryFlagRef = React.useRef<HTMLInputElement>(null);
  const flag = React.useMemo(() => {
    if (typeof templateMeta.countryFlag === 'string') {
      return templateMeta.countryFlag;
    }
    if (templateMeta.countryFlag instanceof File) {
      return URL.createObjectURL(templateMeta.countryFlag);
    }
    return FlagOfNone;
  }, [templateMeta.countryFlag]);

  const { data, loading, error, refetch } = useQuery(RTM_QUERY, {
    variables: { id: templateMeta.countryFlagId },
  });

  // -------------------------------------------------------------------------------------------------------
  // -------------------------------------------------------------------------------------------------------
  // handlers
  // -------------------------------------------------------------------------------------------------------

  const handleRemove = React.useCallback(() => {
    updateTemplateMeta({ countryFlag: null, countryFlagRemove: true });
    if (countryFlagRef.current) countryFlagRef.current.value = '';
  }, [updateTemplateMeta]);

  const handleFlagChange = React.useCallback(
    (event: any) => {
      updateTemplateMeta({
        countryFlag: event.target.files[0],
        countryFlagRemove: false,
      });
    },
    [updateTemplateMeta]
  );

  // -------------------------------------------------------------------------------------------------------
  // -------------------------------------------------------------------------------------------------------
  // effects
  // -------------------------------------------------------------------------------------------------------

  // data
  React.useEffect(() => {
    if (data) {
      // to be removed
      if (templateMeta.countryFlagRemove) return;
      // to be updated
      if (templateMeta.countryFlag instanceof File) return;

      // no change
      if (
        data?.reportTemplateMeta?.file?.name === templateMeta.countryFlagName &&
        !!templateMeta.countryFlag
      )
        return;

      // otherwise fine to update from data
      updateTemplateMeta({
        countryFlag: getMediaUrl(data?.reportTemplateMeta?.file?.url),
        countryFlagName: data?.reportTemplateMeta?.file?.name,
      });
    }
  }, [
    data,
    templateMeta.countryFlag,
    templateMeta.countryFlagName,
    templateMeta.countryFlagRemove,
    updateTemplateMeta,
  ]);

  // error
  React.useEffect(() => {
    if (error) logError(error);
  }, [error]);

  // refetch if country flag and url don't match
  React.useEffect(() => {
    if (templateMeta.countryFlagRemove) return;
    if (templateMeta.countryFlag instanceof File) return;

    if (data?.reportTemplateMeta?.file?.name === templateMeta.countryFlagName)
      return;

    refetch({
      id: templateMeta.countryFlagId,
    });
  }, [
    data?.reportTemplateMeta?.file?.name,
    refetch,
    templateMeta.countryFlag,
    templateMeta.countryFlagId,
    templateMeta.countryFlagName,
    templateMeta.countryFlagRemove,
  ]);

  // -------------------------------------------------------------------------------------------------------

  return (
    <Box>
      <Box sx={{ display: 'flex', alignItems: 'flex-end', mt: 1 }}>
        <Box
          sx={{
            border: 1,
            borderColor: 'grey.400',
            borderRadius: 1,
            p: 2,
            position: 'relative',
          }}
        >
          {loading ? (
            <Skeleton variant="rectangular" width={70} height={50} />
          ) : (
            <img
              style={{ width: 70 }}
              alt={`${t('Country Flag')}`}
              src={flag || FlagOfNone}
            />
          )}
          <Typography
            component="span"
            variant="caption"
            color={disabled ? 'text.disabled' : 'text.secondary'}
            sx={{
              px: 0.8,
              mb: 1,
              position: 'absolute',
              bgcolor: 'white',
              left: 0,
              top: '-10px',
              whiteSpace: 'nowrap',
              insetInlineStart: '8px',
            }}
          >
            {t('Country Flag')}
          </Typography>
        </Box>

        {!disabled && (
          <Box sx={{ ml: 2, pt: 1 }}>
            <Box sx={{ mb: 2 }}>
              <input
                type="file"
                aria-label="country flag"
                accept="image/png,image/jpeg"
                style={{ fontSize: '90%', width: '100%' }}
                disabled={disabled}
                onChange={handleFlagChange}
                ref={countryFlagRef}
              />
            </Box>
            {!!flag && (
              <Button
                variant="contained"
                disableElevation
                size="small"
                onClick={handleRemove}
              >
                {t('Remove')}
              </Button>
            )}
          </Box>
        )}
      </Box>

      {/* on error img, refetch */}
      {data?.reportTemplateMeta?.file?.url && (
        <img
          src={getMediaUrl(data?.reportTemplateMeta?.file?.url)}
          onError={() => refetch()}
          alt=""
          style={{ display: 'none' }}
        />
      )}
    </Box>
  );
}
