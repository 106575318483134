import { View, Text } from '@react-pdf/renderer';

import { getFinanceMethods } from '../../../../../_utils/finance-utils';

import { CurrencyText } from '../../../../../components/currency-text';

export function ReportsPdfSectionsFinanceMethods({
  t,
  reportData,
  areas,
}: any) {
  const financeMethods = getFinanceMethods(areas);

  return (
    <View style={{ width: '100%', textAlign: 'left' }}>
      {financeMethods
        .sort((a: any, b: any) => b.amount - a.amount)
        .slice(0, 4)
        .map((fm: any) => {
          return (
            <View key={fm.name} style={{ margin: '5px 3px' }}>
              <Text
                style={{
                  fontSize: '8px',
                  fontStyle: fm.name === 'N/A' ? 'italic' : 'normal',
                }}
              >
                {fm.name === 'N/A' ? t('Unassigned') : fm.name}
              </Text>
              <Text>
                <CurrencyText
                  amount={fm.amount}
                  dashboard={reportData.dashboard}
                />
              </Text>
            </View>
          );
        })}

      {financeMethods.length > 5 && (
        <View style={{ margin: '3px' }}>
          <Text>
            {t('and')}{' '}
            <Text style={{ fontWeight: 'bold' }}>
              {financeMethods.length - 4}{' '}
            </Text>
            {financeMethods.length - 4 > 1 ? t('others') : t('other')}...
          </Text>
        </View>
      )}
    </View>
  );
}

export default ReportsPdfSectionsFinanceMethods;
