import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import Box, { BoxProps } from '@mui/material/Box';

import {
  Button,
  ButtonProps,
  Stack,
  StackProps,
  SvgIconProps,
  Typography,
  TypographyProps,
  TypographyTypeMap,
} from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { styled } from '@mui/material/styles';

export const ExecutiveSummaryPriorityAreaBreakdownButtonLabel = styled(
  (props: TypographyProps) => (
    <Typography
      variant="body1"
      component="span"
      borderRadius="4px"
      fontSize="16px"
      bgcolor="#FFA500"
      color="#FFFFFF"
      px={1}
      textAlign="start"
      textTransform="capitalize"
      {...props}
    />
  )
)(() => ({}));

export const ExecutiveSummaryPriorityAreaBreakdownButtonInnerContainer = styled(
  (props: BoxProps) => (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
      width={1}
      px={2}
      py={1}
      borderRadius="4px"
      {...props}
    />
  )
)(() => ({}));

export const ExecutiveSummaryPriorityAreaBreakdownButton = styled(
  (props: ButtonProps) => (
    <Button disableRipple disableTouchRipple fullWidth {...props} />
  )
)(() => ({
  color: '#000000',
  background: '#FFFFFF',
  // '&:hover': {
  //   background: '#E6E6F8',
  // },
}));

export const ExecutiveSummaryPriorityAreaBreakdownKeyboardArrowRightIcon =
  styled((props: SvgIconProps) => <KeyboardArrowRightIcon {...props} />)(
    () => ({
      fill: 'rgba(0, 0, 0, 0.54)',
    })
  );

export const ExecutiveSummaryPriorityAreaBreakdownHeadingContainer = styled(
  (props: BoxProps) => <Box width={1} px={2} py={1} {...props} />
)(() => ({
  backgroundColor: '#FFFFFF',
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'row',
  borderRadius: '4px',
  justifyContent: 'space-between',
}));

export const ExecutiveSummaryPriorityAreaBreakdownHeadingInnerContainer =
  styled((props: StackProps) => <Stack spacing={1} py={2} {...props} />)(
    () => ({
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'center',
      flexDirection: 'column',
    })
  );

export const ExecutiveSummaryPriorityAreaBreakdownHeading = styled<any>(
  (
    props: OverridableComponent<
      TypographyTypeMap<{
        children: React.ReactNode;
      }>
    >
  ) => <Typography variant="h5" component="h2" {...props} />
)(() => ({
  textDecoration: 'underline',
}));

export const ExecutiveSummaryPriorityAreaBreakdownPanelContentContainer =
  styled((props: BoxProps) => <Box {...props} />)(() => ({
    backgroundColor: '#EEEEEE',
  }));

export const ExecutiveSummaryPriorityAreaBreakdownPanelContainer = styled(
  (props: BoxProps) => <Box {...props} />
)(() => ({
  border: '1px solid #EEEEEE',
}));

export const ExecutiveSummaryPriorityAreaBreakdownInformationItemTitle = styled(
  (props: TypographyProps) => (
    <Typography
      variant="body1"
      component="span"
      fontSize="16px"
      fontWeight="600"
      {...props}
    />
  )
)(() => ({}));
