import React from 'react';
import { isEmpty } from 'lodash';

import Box from '@mui/material/Box';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';

import ExecutiveSummaryBreakdown from './executive-summary-breakdown/executive-summary-breakdown';
import ExecutiveSummaryContentSummary from './executive-summary-content-summary/executive-summary-content-summary';
import {
  ExecutiveSummaryFilters,
  ExecutiveSummaryFiltersValues,
} from './executive-summary-filter/executive-summary-filter';
import ExecutiveSummaryStatusChart from './executive-summary-status-chart/executive-summary-status-chart';
import { useExecutiveSummary } from './use-executive-summary/use-executive-summary';

// TODO: use dashboard content to check if area level is enabled
// move app bar filter down to other filters
// May need to show all leads and coleads with expandable list
// loading/error state
// Adjust font sizes for smaller screens
// fix analytics chart and make generic with all priority areas being filtered and appearing
// add rate limiting for queries
// prefetch data on pagination of tables
// test offline functionality and refetching on reconnecting
// content blockers for status calculation - confirm functionality

const initialFiltersValue = {} as ExecutiveSummaryFiltersValues;

export function ExcecutiveSummaryScreen() {
  const [filters, setFilters] =
    React.useState<ExecutiveSummaryFiltersValues>(initialFiltersValue);

  const {
    contentByStatus,
    groupedPriorityAreasByLevel,
    parentAreaLevel,
    contentExecutiveLoading,
    contentExecutiveError,
    priorityAreasLevelsFilterOptions,
  } = useExecutiveSummary({ filters });

  const handleFilter = ({
    values,
    filterElementId,
  }: {
    values: ExecutiveSummaryFiltersValues;
    filterElementId: keyof ExecutiveSummaryFiltersValues;
  }) => {
    const newValuesAtKey = values?.[filterElementId] || {};

    setFilters((prev) => {
      delete prev[filterElementId];
      const newValue = {
        ...prev,
        ...(Object.keys(newValuesAtKey)?.length
          ? {
              [filterElementId]: newValuesAtKey,
            }
          : {}),
      };

      if (filterElementId === '0' && isEmpty(newValue[filterElementId])) {
        return initialFiltersValue;
      }

      return newValue;
    });
  };

  const issues = React.useMemo(
    () => contentByStatus?.issues,
    [contentByStatus]
  );

  const longoverdue = React.useMemo(
    () => contentByStatus?.longoverdue,
    [contentByStatus]
  );

  const priorityAreas: any = React.useMemo(
    () => groupedPriorityAreasByLevel,
    [groupedPriorityAreasByLevel]
  );

  return (
    <Container
      maxWidth={false}
      sx={{
        px: {
          xs: 1,
          sm: 3,
        },
        '& .MuiPaper-root': {
          my: {
            xs: 1,
            sm: 2,
          },
        },
      }}
    >
      <Card
        id="ExcecutiveSummaryCard"
        sx={{
          p: {
            xs: 1,
            sm: 3,
          },
          my: 4,
        }}
      >
        <CardContent
          sx={{
            p: 0,
            height: '100%',
            '&.MuiCardContent-root.MuiCardContent-root:last-child': {
              p: 0,
            },
          }}
        >
          {contentExecutiveLoading ? (
            <Box
              width={1}
              height={1}
              p={0}
              margin={0}
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{ minHeight: '500px' }}
            >
              <CircularProgress />
            </Box>
          ) : null}

          {!contentExecutiveLoading && !contentExecutiveError ? (
            <Stack spacing={2}>
              <Box>
                <ExecutiveSummaryFilters
                  onFilter={handleFilter}
                  filterOptions={priorityAreasLevelsFilterOptions}
                  filters={filters}
                />
              </Box>
              <Box
                sx={{
                  border: '1px solid #EEEEEE',
                  borderRadius: '4px',
                }}
              >
                <ExecutiveSummaryStatusChart
                  priorityAreas={priorityAreas}
                  filters={filters}
                />
              </Box>
              <Box id="ExecutiveSummaryContentSummary">
                <ExecutiveSummaryContentSummary
                  issues={issues}
                  longoverdue={longoverdue}
                  filters={filters}
                />
              </Box>
              <Box id="ExecutiveSummaryBreakdown">
                <ExecutiveSummaryBreakdown
                  title={parentAreaLevel?.name}
                  priorityAreas={priorityAreas}
                />
              </Box>
            </Stack>
          ) : null}
        </CardContent>
      </Card>
    </Container>
  );
}
