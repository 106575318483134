import React from 'react';
import { useParams } from 'react-router-dom';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Skeleton from '@mui/material/Skeleton';

import { useDashboardContext } from '../_lib/context/dashboard-context';
import { ReactErrorComponent } from '../_lib/react-error';

import { AreaTargets } from '../components/area-targets';

import { useSummary } from '../_utils/hooks/queries/use-summary';

export function PriorityAreaTargets({ tableOnly }: any) {
  const { priorityAreaId } = useParams<any>();

  const { navbarFilterVariables } = useDashboardContext();

  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [page, setPage] = React.useState(0);

  const { loading, error, data } = useSummary(
    'targets',
    {
      priorityAreaId,
      ...navbarFilterVariables,
      paginationTargetsLimit: rowsPerPage,
      paginationTargetsOffset: page * rowsPerPage,
    },
    'priorityArea'
  );

  if (error) return <ReactErrorComponent error={error} />;

  if (loading || !data) {
    return <Skeleton variant="rectangular" height={300} sx={{ my: 3 }} />;
  }

  if (!data.priorityArea?.summary?.targets?.targetsByUnit?.meta.count)
    return null;

  return (
    <Card sx={{ my: 3, pb: 1.5 }}>
      <CardContent sx={{ p: 4 }}>
        <AreaTargets
          area={data.priorityArea}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          page={page}
          setPage={setPage}
          tableOnly={tableOnly}
        />
      </CardContent>
    </Card>
  );
}
