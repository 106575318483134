import React from 'react';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import {
  PROGRAMME_CREATE,
  PROGRAMME_DELETE,
  PROGRAMME_UPDATE,
  PROGRAMME_UPDATE_ORDER,
} from '../../../../_lib/graphql/mutations';

import { DashboardContext } from '../../../../_lib/context/dashboard-context';
import { logError } from '../../../../_lib/error';

import { DragggableDialogDialog } from '../../../../components/draggable-dialog/dialog';

export function PlanningInterventionFormProgrammeDialogManage({
  open,
  setOpen,
  disableEnforceFocus,
}: any) {
  const { t } = useTranslation();

  const { dashboard, setSnackbarOpen, setSnackbarIsError } =
    React.useContext(DashboardContext);

  const [details, setDetails] = React.useState<any>([]);

  const [createProgrammeMutation] = useMutation(PROGRAMME_CREATE);
  const [updateProgrammeMutation] = useMutation(PROGRAMME_UPDATE);
  const [deleteProgrammeMutation] = useMutation(PROGRAMME_DELETE);
  const [updateProgrammeOrderMutation] = useMutation(PROGRAMME_UPDATE_ORDER);

  // -----------------------------------------------------------------------------------------
  // -----------------------------------------------------------------------------------------
  // handlers
  // -----------------------------------------------------------------------------------------

  const createMutation = (value: any) =>
    createProgrammeMutation({
      variables: {
        name: value,
      },
    })
      .catch((error) => {
        logError(error);
        setSnackbarIsError(true);
      })
      .finally(() => setSnackbarOpen(true));

  const updateMutation = ({ id, value }: any) =>
    updateProgrammeMutation({
      variables: {
        id,
        name: value,
      },
    })
      .catch((error) => {
        logError(error);
        setSnackbarIsError(true);
      })
      .finally(() => setSnackbarOpen(true));

  const deleteMutation = (id: number) =>
    deleteProgrammeMutation({ variables: { id } })
      .then((r: any) => {
        return Promise.resolve(r);
      })
      .catch((error) => {
        logError(error);
        setSnackbarIsError(true);
      })
      .finally(() => setSnackbarOpen(true));

  const updateOrderMutation = (programmeIds: number[]) =>
    updateProgrammeOrderMutation({
      variables: {
        programmeIds,
      },
    })
      .catch((error) => {
        logError(error);
        setSnackbarIsError(true);
      })
      .finally(() => setSnackbarOpen(true));

  // -----------------------------------------------------------------------------------------
  // -----------------------------------------------------------------------------------------
  // effects
  // -----------------------------------------------------------------------------------------

  React.useEffect(() => {
    setDetails(
      dashboard.programmes?.map((p: any) => ({
        id: p.id,
        value: p.name,
      }))
    );
  }, [dashboard.programmes]);

  // -----------------------------------------------------------------------------------------

  return (
    <DragggableDialogDialog
      open={open}
      setOpen={setOpen}
      details={details}
      setDetails={setDetails}
      title={t('Manage Programmes')}
      inputLabel={t('Name')}
      addLabel={t('Add Programme')}
      createMutation={createMutation}
      updateMutation={updateMutation}
      deleteMutation={deleteMutation}
      updateOrderMutation={updateOrderMutation}
      disableEnforceFocus={disableEnforceFocus}
    />
  );
}
