import React from 'react';

import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { BarProps } from 'chart.js';

import { gql, useQuery } from '@apollo/client';
import { ErrorBoundary } from 'react-error-boundary';

import Box, { BoxProps } from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import { ReactErrorComponent } from '../../../../_lib/react-error';
import { useExecutiveSummaryStatusChart } from './use-executive-summary-status-chart';
import { ExecutiveSummaryFiltersValues } from '../executive-summary-filter/executive-summary-filter';
import { PriorityArea } from '../executive-summary/executive-summary.types';
import { LoadingSpinner } from '../../../../components/loading';

const PAS_QUERY = gql`
  query ExecutiveViewInterventionsStatusQuery(
    $paginationInterventionsLimit: Int
    $filterPriorityAreaIds: [ID!]
    $filterAll: Boolean
    $paginationPriorityAreasLimit: Int = -1
  ) {
    priorityAreas(
      filters: { all: $filterAll, priorityAreaIds: $filterPriorityAreaIds }
      pagination: {
        interventionsLimit: $paginationInterventionsLimit
        priorityAreasLimit: $paginationPriorityAreasLimit
      }
    ) {
      items {
        id
        reference
        name
        parent {
          id
        }
        interventions {
          id
          status {
            id
            name
            color
          }
        }
      }
    }
  }
`;

export function AnalyticsStatus({
  title,
  byAreaBoxProps,
  byAreaChartProps,
  currentPriorityAreas,
  filters,
}: {
  title?: string;
  description?: string;
  byAreaBoxProps?: BoxProps;
  byAreaChartProps?: Partial<BarProps>;
  currentPriorityAreas?: PriorityArea[];
  filters: ExecutiveSummaryFiltersValues;
}) {
  const priorityAreaIds = React.useMemo(() => {
    const newPriorityAreaIds: string[] = [];
    currentPriorityAreas?.forEach((area) => {
      if (!filters?.['1']) {
        newPriorityAreaIds.push(area.id);
      }
      area.children?.forEach((child) => {
        newPriorityAreaIds.push(child.id);
      });
    });
    return newPriorityAreaIds;
  }, [currentPriorityAreas, filters]);

  const { data, error, loading, refetch } = useQuery(PAS_QUERY, {
    variables: {
      paginationInterventionsLimit: -1,
      filterPriorityAreaIds: priorityAreaIds,
      filterAll: true,
    },
  });

  const { chartData } = useExecutiveSummaryStatusChart({
    data,
    title,
    filters,
  });

  // refetch
  React.useEffect(() => {
    if (!data && !loading && !error) refetch();
  }, [data, loading, error, refetch]);

  // refetch when filters change
  React.useEffect(() => {
    if (data && !loading && !error) refetch();
  }, [data, loading, error, refetch, priorityAreaIds]);

  return (
    <ErrorBoundary FallbackComponent={ReactErrorComponent}>
      {chartData.groupedByArea ? (
        !loading ? (
          <Box
            sx={{
              m: 2,
              height: '350px',
              ...(byAreaBoxProps?.sx ? { ...byAreaBoxProps?.sx } : {}),
            }}
            {...byAreaBoxProps}
          >
            <Bar
              options={chartData.groupedByArea?.options as any}
              data={chartData.groupedByArea?.data as any}
              {...byAreaChartProps}
              plugins={[ChartDataLabels]}
            />
          </Box>
        ) : (
          <LoadingSpinner height={250} />
        )
      ) : (
        <Skeleton variant="rectangular" height={250} />
      )}
    </ErrorBoundary>
  );
}
