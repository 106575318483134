import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActionArea from '@mui/material/CardActionArea';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { getStringDate, hexToRGB } from '../../_utils/general-utils';
import { useDashboardContext } from '../../_lib/context/dashboard-context';

export function PriorityAreaMilestonesSliderCard({ milestone }: any) {
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();

  const {
    dashboard: { metaStatusColors, language },
  } = useDashboardContext();

  const { title, deadline, status, intervention, completionDate } = milestone;

  const handleClick = () => {
    const params = new URLSearchParams(location.search);
    params.set('content', milestone.id);
    history.push(`${location.pathname}?${params.toString()}`);
  };

  return (
    <Card
      id="milestone-overview-card"
      variant="outlined"
      sx={{
        my: 2,
        bgcolor: hexToRGB(metaStatusColors[status]?.bgcolor, 0.2) || '#fff',
      }}
    >
      <CardActionArea onClick={handleClick}>
        <CardContent>
          <Grid spacing={2} container>
            <Grid item xs={12} md={9}>
              <Typography
                sx={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  '&:hover': {
                    textOverflow: 'clip',
                    whiteSpace: 'normal',
                  },
                }}
              >
                {title}
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  '&:hover': {
                    textOverflow: 'clip',
                    whiteSpace: 'normal',
                  },
                }}
              >
                {intervention.reference ? `${intervention.reference} - ` : ''}
                {intervention.name}
              </Typography>
            </Grid>
            <Grid item xs={12} md={3}>
              <Typography sx={{ textAlign: { xs: 'left', md: 'right' } }}>
                {`${completionDate ? t('Completed') : t('Due')} ${getStringDate(
                  completionDate || deadline,
                  language
                )}`}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
