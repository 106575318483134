import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';

import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

import { useDashboardContext } from '../../../../_lib/context/dashboard-context';

import { AreaPermissionsLeads } from '../../../../components/area-permissions/leads';

import {
  INTERVENTION_DELETE,
  INTERVENTION_MOVE,
  INTERVENTIONS_UPDATE_ORDER,
} from '../../../../_lib/graphql/mutations';
import {
  INTERVENTIONS_PLANNING_QUERY,
  PRIORITY_AREA_PLANNING_QUERY,
} from '../../../../_lib/graphql/queries';

import { PlanningPriorityAreaFormDetailChildren } from '../children';
import { PlanningPriorityAreaFormActionsCancel } from '../../actions/cancel';
import { PlanningPriorityAreaFormActionsSave } from '../../actions/save';

export function PlanningPriorityAreaDetailTracker({
  disabled,
  priorityArea,
  setPriorityArea,
  errors,
  interventions,
  setInterventions,
  addIntervention,
  setDisabled,
  valid,
  loading,
  setLoading,
  paChildren,
  newFinanciers,
  setNewFinanciers,
  newTargets,
  setNewTargets,
}: any) {
  const { t } = useTranslation();

  const { dashboard } = useDashboardContext();

  const [deleteMutation] = useMutation(INTERVENTION_DELETE);
  const [moveInterventionMutation] = useMutation(INTERVENTION_MOVE);
  const [updateInterventionsOrderMutation] = useMutation(
    INTERVENTIONS_UPDATE_ORDER,
    {
      update: (cache, { data: { interventionsUpdateOrder } }) => {
        // update priority area
        cache.writeQuery({
          query: PRIORITY_AREA_PLANNING_QUERY,
          variables: {
            priorityAreaId: interventionsUpdateOrder.priorityArea.id,
          },
          data: {
            priorityArea: interventionsUpdateOrder.priorityArea.id,
          },
        });

        // update interventions
        cache.writeQuery({
          query: INTERVENTIONS_PLANNING_QUERY,
          variables: {
            priorityAreaId: interventionsUpdateOrder.priorityArea.id,
          },
          data: {
            interventions: interventionsUpdateOrder.interventions,
          },
        });
      },
    }
  );

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          {/* Name */}
          <TextField
            id="name-input"
            label={t('Name')}
            value={priorityArea.name || ''}
            onChange={(event) =>
              setPriorityArea({
                ...priorityArea,
                name: event.target.value,
              })
            }
            fullWidth
            disabled={disabled}
            error={errors.name}
            helperText={errors.name}
            sx={{ my: 2 }}
            required
          />

          {/* Permissions: Leads + Others */}
          <AreaPermissionsLeads
            disabled={disabled}
            area={priorityArea}
            setArea={setPriorityArea}
            errors={errors}
            sx={{ mb: 2 }}
          />
        </Grid>

        {/* Categories */}
        <Grid item xs={12} sm={6}>
          <PlanningPriorityAreaFormDetailChildren
            areaName={dashboard.interventionName}
            areas={interventions}
            setAreas={setInterventions}
            addArea={addIntervention}
            disabled={disabled}
            errors={errors}
            deleteMutation={deleteMutation}
            moveMutation={moveInterventionMutation}
            updateOrderMutation={updateInterventionsOrderMutation}
          />
        </Grid>
      </Grid>

      {/* save and cancel buttons */}

      {!disabled && (
        <Stack
          spacing={2}
          direction={{ xs: 'column', sm: 'row' }}
          justifyContent="flex-end"
          sx={{
            mt: 2,
          }}
        >
          <PlanningPriorityAreaFormActionsCancel
            priorityAreaInitial={priorityArea}
            setPriorityArea={setPriorityArea}
            setInterventions={setInterventions}
            setDisabled={setDisabled}
            setNewTargets={setNewTargets}
            disabled={loading}
          />
          <PlanningPriorityAreaFormActionsSave
            valid={valid}
            priorityArea={priorityArea}
            paChildren={paChildren}
            interventions={interventions}
            newFinanciers={newFinanciers}
            setNewFinanciers={setNewFinanciers}
            newTargets={newTargets}
            setNewTargets={setNewTargets}
            setDisabled={setDisabled}
            loading={loading}
            setLoading={setLoading}
          />
        </Stack>
      )}
    </>
  );
}
