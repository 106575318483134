import React from 'react';

import { useTranslation } from 'react-i18next';

import MenuItem from '@mui/material/MenuItem';
import LoadingButton from '@mui/lab/LoadingButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';

import { ReportActionDeleteDialog } from './dialog';

export function ReportActionDelete({ report, disabled, isMoreMenuItem }: any) {
  const { t } = useTranslation();

  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  // --------------------------------------------------------------------------------------------------------
  // --------------------------------------------------------------------------------------------------------
  // handlers
  // --------------------------------------------------------------------------------------------------------

  const handleOpen = React.useCallback(() => {
    setOpen(true);
  }, []);

  // --------------------------------------------------------------------------------------------------------

  return (
    <>
      {/* confirm dialog */}
      <ReportActionDeleteDialog
        report={report}
        open={open}
        setOpen={setOpen}
        loading={loading}
        setLoading={setLoading}
      />

      {/* button */}
      {!isMoreMenuItem ? (
        <LoadingButton
          variant="outlined"
          color="error"
          onClick={handleOpen}
          loading={loading}
          disabled={disabled}
        >
          {t('Move to trash')}
        </LoadingButton>
      ) : (
        <MenuItem onClick={handleOpen}>
          <ListItemIcon>
            <DeleteOutlineOutlinedIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>{t('Delete')}</ListItemText>
        </MenuItem>
      )}
    </>
  );
}
