import React from 'react';
import { useHistory } from 'react-router-dom';

export function ScrollToTop({ children }: any) {
  const history = useHistory();

  // scroll to element with id
  React.useEffect(() => {
    const { hash } = window.location;

    if (hash !== '') {
      const id = hash.replace('#', '');
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView();
      }
    }
  }, [history.location]);

  // scroll to top on pathname change
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [history.location.pathname]);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
}
