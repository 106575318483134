import React from 'react';
import { useTranslation } from 'react-i18next';

import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';

import { ReportsTemplateDialog } from '../../../../reports/edit/template-dialog';

export function AdvancedReportsTemplate({ enabled }: any) {
  const { t } = useTranslation();

  const [openDialog, setOpenDialog] = React.useState(false);
  const [disableEdit, setDisableEdit] = React.useState(false);

  if (!enabled) return null;

  return (
    <>
      <ReportsTemplateDialog
        open={openDialog}
        setOpen={setOpenDialog}
        disableEdit={disableEdit}
        setDisableEdit={setDisableEdit}
      />

      <Stack spacing={2} direction="row">
        <Button
          variant="outlined"
          size="small"
          onClick={() => {
            setOpenDialog(true);
            setDisableEdit(false);
          }}
          sx={{ mb: 1 }}
        >
          {t('Edit Default Report Template Details')}
        </Button>
      </Stack>
    </>
  );
}
