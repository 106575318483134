import React from 'react';
import { useTranslation } from 'react-i18next';

import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import InsertLinkIcon from '@mui/icons-material/InsertLink';
import FingerprintIcon from '@mui/icons-material/Fingerprint';
import CheckIcon from '@mui/icons-material/Check';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { capitalizeFirstLetter } from '../../../_utils/general-utils';
import { ContentDialogContext } from '../../../_lib/context/content-dialog-context';

export function ContentDialogControlsId() {
  const { t } = useTranslation();

  const { content } = React.useContext(ContentDialogContext);

  const [bubbleHeight, setBubbleHeight] = React.useState(0);
  const [bubbleWidth, setBubbleWidth] = React.useState(0);
  const [bubbleMessage, setBubbleMessage] = React.useState('Copied');
  const [bubbleShow, setBubbleShow] = React.useState(false);

  const handleUrlClick = () => {
    navigator.clipboard.writeText(content.url);
    setBubbleMessage(
      `${t(capitalizeFirstLetter(content.type))} URL ${t('copied')}`
    );
    setBubbleShow(true);
  };
  const handleIdClick = () => {
    navigator.clipboard.writeText(`#${content.id}`);
    setBubbleMessage(
      ` ${t(capitalizeFirstLetter(content.type))} ID ${t('copied')}`
    );
    setBubbleShow(true);
  };

  // Set bubble dimensions
  React.useEffect(() => {
    const paperDoc = document.getElementById(
      'content-dialog-controls-id-paper'
    );
    if (paperDoc) {
      const { clientHeight, clientWidth } = paperDoc;
      setBubbleHeight(clientHeight);
      setBubbleWidth(clientWidth);
    }
  }, [setBubbleHeight, setBubbleWidth]);

  // Show and hide bubble
  React.useEffect(() => {
    const timer = setTimeout(() => {
      if (bubbleShow) setBubbleShow(!bubbleShow);
    }, 2000);
    return () => clearTimeout(timer);
  }, [bubbleShow]);

  return (
    <Paper
      id="content-dialog-controls-id-paper"
      component="form"
      variant="outlined"
      sx={{
        pl: 0.5,
        display: 'inline-flex',
        alignItems: 'center',
        bgcolor: 'grey.100',
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          display: bubbleShow ? 'flex' : 'none',
          alignItems: 'center',
          justifyContent: 'space-between',
          zIndex: 40,
          bgcolor: 'green',
          color: 'white',
          ml: -0.5,
          height: bubbleHeight,
          width: bubbleWidth,
          transition: 'display 150ms ease-in-out',
          px: 1,
        }}
      >
        <Typography variant="body2">{bubbleMessage}</Typography>
        <CheckIcon fontSize="small" />
      </Box>
      <IconButton aria-label="copy url" size="small" onClick={handleUrlClick}>
        <InsertLinkIcon fontSize="inherit" />
      </IconButton>
      <Divider
        sx={{ height: 'auto', alignSelf: 'stretch', mx: 0.5 }}
        orientation="vertical"
      />
      <IconButton aria-label="copy id" size="small" onClick={handleIdClick}>
        <FingerprintIcon fontSize="inherit" />
      </IconButton>
      <Divider
        sx={{ height: 'auto', alignSelf: 'stretch', ml: 0.5 }}
        orientation="vertical"
      />
      <InputBase
        sx={{
          pl: 1.5,
          flex: 1,
          color: 'text.secondary',
          bgcolor: 'white',
          alignSelf: 'stretch',
        }}
        value={`#${content.id}`}
        inputProps={{
          'aria-label': 'content id',
          style: { paddingBottom: 0, fontSize: '80%' },
        }}
        size="small"
        readOnly
        onFocus={(event) => {
          event.target.select();
        }}
      />
    </Paper>
  );
}
