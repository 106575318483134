import React from 'react';
import { useTranslation } from 'react-i18next';
import { gql, useMutation } from '@apollo/client';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

import LoadingButton from '@mui/lab/LoadingButton';

import DeleteIcon from '@mui/icons-material/Delete';
import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrash';

import { DashboardContext } from '../../../../_lib/context/dashboard-context';
import { logError } from '../../../../_lib/error';

import { DialogTitle } from '../../../../DialogTitle/dialog-title';

const DASHBOARD_DELETE = gql`
  mutation DashboardDelete {
    dashboardDelete {
      ok
    }
  }
`;

const DASHBOARD_UNDELETE = gql`
  mutation DashboardUnDelete {
    dashboardUnDelete {
      ok
    }
  }
`;

export function DashboardFormDetailsActionsDelete({
  loading,
  disabled,
}: {
  loading: boolean;
  disabled: boolean;
}) {
  const { t } = useTranslation();

  const { dashboard, setSnackbarOpen, setSnackbarIsError } =
    React.useContext(DashboardContext);

  const [openConfirm, setOpenConfirm] = React.useState(false);
  const [loadingUpdate, setLoadingUpdate] = React.useState(false);

  const [dashboardDeleteMutation] = useMutation(DASHBOARD_DELETE);
  const [dashboardUnDeleteMutation] = useMutation(DASHBOARD_UNDELETE);

  // ----------------------------------------------------------------------------------------------------
  // ----------------------------------------------------------------------------------------------------
  // handlers
  // ----------------------------------------------------------------------------------------------------

  const handleDelete = () => {
    setLoadingUpdate(true);

    const mutation = dashboard.deletedAt
      ? dashboardUnDeleteMutation
      : dashboardDeleteMutation;
    mutation()
      .then(() => {
        setLoadingUpdate(false);
        window.localStorage.clear();
        window.location.reload();
      })
      .catch((err: any) => {
        setLoadingUpdate(false);
        logError(err);
        setSnackbarIsError(true);
        setSnackbarOpen(true);
      });
  };

  // ----------------------------------------------------------------------------------------------------

  return (
    <>
      <Divider sx={{ mb: 4 }} />
      <Typography variant="h6" gutterBottom>
        {t('Delete Dashboard')}
      </Typography>
      <Typography variant="body2" sx={{ mb: 2.5, color: 'text.secondary' }}>
        {t(
          'Moving the dashboard to the trash will remove it from the dashboard list and disable all email notifications. You can restore the dashboard from the trash at any time.'
        )}
      </Typography>
      <Button
        variant="contained"
        color={dashboard.deletedAt ? 'success' : 'error'}
        onClick={() => setOpenConfirm(true)}
        startIcon={
          !dashboard.deletedAt ? <DeleteIcon /> : <RestoreFromTrashIcon />
        }
        disabled={loading || loadingUpdate || !disabled}
      >
        {!dashboard.deletedAt ? t('Delete Dashboard') : t('Restore Dashboard')}
      </Button>

      {/* confirm dialog */}
      <Dialog open={openConfirm}>
        <DialogTitle
          onClose={() => setOpenConfirm(false)}
          color={!dashboard.deletedAt ? 'error' : 'success'}
        >
          {!dashboard.deletedAt
            ? t('Delete Dashboard')
            : t('Restore Dashboard')}
          ?
        </DialogTitle>
        <DialogContent>
          <Box>
            <Typography>
              {!dashboard.deletedAt
                ? t('Are you sure you want to delete this dashboard?')
                : t('Are you sure you want to restore this dashboard?')}
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setOpenConfirm(false)}
            variant="contained"
            disableElevation
            disabled={loadingUpdate}
          >
            {t('Cancel')}
          </Button>
          <LoadingButton
            onClick={handleDelete}
            color={dashboard.deletedAt ? 'success' : 'error'}
            variant="outlined"
            loading={loadingUpdate}
            startIcon={
              !dashboard.deletedAt ? <DeleteIcon /> : <RestoreFromTrashIcon />
            }
            disabled={loading}
          >
            {!dashboard.deletedAt ? t('Delete') : t('Restore')}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
