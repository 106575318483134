import React from 'react';
import { GraphqlPrefetchQueries } from './queries';

export function GraphqlPrefetch() {
  const [load, setLoad] = React.useState(false);

  // set timeout to load data after 40 seconds
  // React.useEffect(() => {
  //   const timeout = setTimeout(() => {
  //     setLoad(true);
  //     console.log('prefetching data');
  //   }, 40000);

  //   return () => {
  //     clearTimeout(timeout);
  //   };
  // }, []);

  return load ? <GraphqlPrefetchQueries /> : null;
}
