import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';

import DoneIcon from '@mui/icons-material/Done';

import LoadingButton from '@mui/lab/LoadingButton';

import { useDashboardContext } from '../_lib/context/dashboard-context';
import { CONTENT_UPDATE, CONTENT_CREATE } from '../_lib/graphql/mutations';
import { logError } from '../_lib/error';

export function SetupMilestones({
  valid,
  intervention,
  milestone,
  newMilestones,
  setNewMilestones,
  setDisabled,
  loading,
  setLoading,
}: any) {
  const { t } = useTranslation();

  const { setSnackbarOpen, setSnackbarIsError } = useDashboardContext();

  const [updateContentMutation] = useMutation(
    milestone.id ? CONTENT_UPDATE : CONTENT_CREATE
  );

  const handleSubmit = () => {
    if (valid()) {
      setLoading(true);

      const variables = {
        id: milestone.id,
        interventionId: intervention.id,
        assigneeId: milestone.assignee?.id || null,
        assigneesIds: milestone.assignees?.map((m: any) => m.id) || null,
        startDate: milestone.startDate,
        deadline: milestone.deadline,
        title: milestone.title,
        description: milestone.description,
        isMilestone: true,
        completionDate:
          (milestone.completionDate && milestone.deadline) || null,
      };

      updateContentMutation({ variables })
        .then(() => {
          // if new milestone, remove from the new milestones list
          if (milestone.fauxId) {
            setNewMilestones([
              ...newMilestones.filter(
                (m: any) => m.fauxId !== milestone.fauxId
              ),
            ]);
          } else {
            setDisabled(true);
          }
        })
        .catch((e) => {
          logError(e);
          setSnackbarIsError(true);
        })
        .finally(() => {
          setLoading(false);
          setSnackbarOpen(true);
        });
    }
  };

  return (
    <LoadingButton
      id="confirm"
      onClick={handleSubmit}
      startIcon={<DoneIcon />}
      variant="contained"
      color="primary"
      size="small"
      disableElevation
      loading={loading}
    >
      {t('Save Changes')}
    </LoadingButton>
  );
}
