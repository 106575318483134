import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';

import LoadingButton from '@mui/lab/LoadingButton';
import Tooltip from '@mui/material/Tooltip';
import UploadIcon from '@mui/icons-material/Upload';

import { DashboardContext } from '../../../../_lib/context/dashboard-context';
import { logError } from '../../../../_lib/error';
import { IMPORT_EXPORT_CREATE } from '../../../../_lib/graphql/mutations';
import { IMPORT_EXPORTS_QUERY } from '../../../../_lib/graphql/queries';

export function DashboardFormImportExportActionsImport() {
  const { t } = useTranslation();
  const { dashboard, setSnackbarMessage, setSnackbarIsError, setSnackbarOpen } =
    React.useContext(DashboardContext);

  const fileInputRef = React.useRef<null | any>(null);
  const [loading, setLoading] = React.useState(false);

  const [createImportMutation] = useMutation(IMPORT_EXPORT_CREATE, {
    update(cache, { data: { importExportCreate } }) {
      cache.writeQuery({
        query: IMPORT_EXPORTS_QUERY,
        data: {
          importExports: importExportCreate.importExports,
        },
      });
    },
  });

  // ----------------------------------------------------------------------------------------------------
  // ----------------------------------------------------------------------------------------------------
  // hanlders
  // ----------------------------------------------------------------------------------------------------

  const handleClick = () => {
    fileInputRef.current?.click();
  };

  const uploadExcel = () => {
    setLoading(true);
    createImportMutation({
      variables: {
        mode: 'IMPORT',
        file: fileInputRef.current?.files[0],
      },
    })
      .then(() => {
        setLoading(false);

        setSnackbarMessage(t('Excel file has been uploaded successfully.'));
        setSnackbarOpen(true);
      })
      .catch((err) => {
        setLoading(false);

        logError(err);
        setSnackbarIsError(true);
        setSnackbarOpen(true);
      })
      .finally(() => {
        fileInputRef.current.value = '';
      });
  };

  const handleUpload = () => {
    // show dialog
    if (fileInputRef.current?.files[0]) {
      uploadExcel();
    }
  };

  // ----------------------------------------------------------------------------------------------------

  return (
    <Tooltip title={t('Import data from Excel Sheet')} arrow>
      <span>
        <div style={{ width: '0px', height: '0px', overflow: 'hidden' }}>
          <input
            type="file"
            ref={fileInputRef}
            id="upload_file_id"
            name="upload_file"
            // You can't have multiple sheets in CSV, because CSV doesn't have sheets
            accept=".xlsx, .xls"
            onChange={handleUpload}
          />
        </div>
        <LoadingButton
          variant="outlined"
          startIcon={<UploadIcon />}
          onClick={handleClick}
          disabled={dashboard.importExportPending || loading}
          loading={loading}
          loadingIndicator={t('Importing...')}
        >
          {t('Import XLSX')}
        </LoadingButton>
      </span>
    </Tooltip>
  );
}
