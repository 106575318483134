import React from 'react';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import { useDashboardContext } from '../../_lib/context/dashboard-context';

import { DraggableDialogDetail } from './detail';
import { DASHBOARD_UPDATE } from '../../_lib/graphql/mutations';
import {
  CONTEXT_DASHBOARD_QUERY,
  CONTEXT_USER_QUERY,
} from '../../_lib/graphql/queries';

export function DraggableDialogUnassignedWeight({ details }: any) {
  const { t } = useTranslation();
  const { dashboard } = useDashboardContext();

  const weightSum = React.useMemo(
    () => details.reduce((acc: any, curr: any) => acc + curr.weight, 0),
    [details]
  );

  const [dashboardUpdateMutation] = useMutation(DASHBOARD_UPDATE, {
    update(cache, { data: { dashboardUpdate } }) {
      cache.writeQuery({
        query: CONTEXT_DASHBOARD_QUERY,
        data: { dashboard: dashboardUpdate.dashboard },
      });

      cache.writeQuery({
        query: CONTEXT_USER_QUERY,
        data: { user: dashboardUpdate.user },
      });
    },
  });

  const updateMutation = ({ weight }: any) =>
    dashboardUpdateMutation({
      variables: {
        activityGroupUnassignedWeight: Number.parseFloat(weight),
      },
    });

  return (
    <Box>
      <DraggableDialogDetail
        detail={{
          id: 'unassigned',
          value: (
            <Box>
              <Tooltip
                title={t(
                  'This weight will be applied to activities that are not assigned to a phase.'
                )}
                arrow
              >
                <Typography variant="body2" color="textSecondary">
                  {t('Unassigned')}
                </Typography>
              </Tooltip>
            </Box>
          ),
          weight: dashboard.activityGroupUnassignedWeight,
          weightRatio: dashboard.activityGroupUnassignedWeight / weightSum,
        }}
        updateMutation={updateMutation}
        includeWeight
        disableNameInput
        disableDelete
        italicTitle
      />
    </Box>
  );
}
