import React from 'react';

import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { getGraphQLDate } from '../_utils/general-utils';

export function DateInput({
  date,
  setDate,
  label,
  fullWidth,
  min,
  max,
  loading,
  errorText,
  clearable,
  size,
  disabled,
  required,
}: any) {
  const handleChange = (newValue: Date | null) => {
    setDate(newValue ? getGraphQLDate(newValue) : null);
  };

  return (
    <DatePicker
      disabled={disabled || loading}
      format="dd/MM/yyyy"
      value={(!!date && new Date(date)) || null}
      onChange={handleChange}
      maxDate={(!!max && new Date(max)) || undefined}
      minDate={(!!min && new Date(min)) || undefined}
      slotProps={{
        actionBar: {
          actions: clearable ? ['clear'] : [],
        },
        textField: {
          label,
          fullWidth,
          disabled: loading || disabled,
          error: !!errorText,
          helperText: errorText,
          size: size || 'medium',
          required,
        },
      }}
    />
  );
}
