import BaseBuilder from 'filefy/dist/core/BaseBuilder';
import IFileExporter from 'filefy/dist/core/IFileExporter';

function escapeCell(cellData: string): string {
  if (typeof cellData === 'string') {
    return `"${cellData.replace(/"/g, '""')}"`;
  }
  return cellData;
}

export default class CsvBuilder extends BaseBuilder implements IFileExporter {
  private _FileName: string = '';

  private _Delimeter: string = ',';

  private _Columns: string[] = [];

  private _RowData: string[][] = [];

  constructor(fileName: string) {
    super();
    this._FileName = fileName;
  }

  public getFileName(): string {
    return this._FileName;
  }

  public setColumns(columns: string[]): CsvBuilder {
    this._Columns = columns;
    return this;
  }

  public setDelimeter(delimeter: string): CsvBuilder {
    this._Delimeter = delimeter;
    return this;
  }

  public addRow(row: string[]): CsvBuilder {
    this._RowData.push(row);
    return this;
  }

  public addRows(rows: string[][]): CsvBuilder {
    this._RowData = [...this._RowData, ...rows];
    return this;
  }

  private getRowData(row: string[]): string {
    return row.map(escapeCell).join(this._Delimeter);
  }

  public getCsvContent(): string {
    const dataArray: string[] = [];
    if (this._Columns && this._Columns.length > 0) {
      dataArray.push(this.getRowData(this._Columns));
    }

    this._RowData.forEach((row) => {
      dataArray.push(this.getRowData(row));
    });

    return dataArray.join('\r\n');
  }

  public getBlob(): Blob {
    return new Blob([this.getCsvContent()], {
      type: 'text/csv;charset=utf-8;',
    });
  }

  public exportFile(): void {
    super.exportFile('csv', `${this._FileName}.csv`, this.getCsvContent());
  }
}
