import React from 'react';
import { useTranslation } from 'react-i18next';

import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Box from '@mui/material/Box';

import { useDashboardContext } from '../../../_lib/context/dashboard-context';

export function AppBarFilterAndOr() {
  const { t } = useTranslation();

  const { navbarFilters, setNavbarFilters } = useDashboardContext();

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newAndOr: string
  ) => {
    setNavbarFilters((prev: any) => ({
      ...prev,
      andOr: [newAndOr],
    }));
  };

  // only show if filters present
  if (
    Object.keys(navbarFilters)
      .filter((k) => k !== 'andOr')
      .filter((k) => !!navbarFilters[k]?.length).length < 2
  ) {
    return null;
  }

  return (
    <Box>
      <ToggleButtonGroup
        size="small"
        value={navbarFilters.andOr}
        exclusive
        onChange={handleChange}
        aria-label="filter and/or"
        sx={{
          backgroundColor: 'rgba(255, 255, 255, 0.15)',
          '& .MuiToggleButton-root': {
            padding: '0px 5px',
            '&.Mui-selected': {
              color: '#fff',
            },
          },
        }}
      >
        <ToggleButton value="AND">{t('AND')}</ToggleButton>
        <ToggleButton value="OR">{t('OR')}</ToggleButton>
      </ToggleButtonGroup>
    </Box>
  );
}
