import Box, { BoxProps } from '@mui/material/Box';

import { CircularProgress } from '@mui/material';

type LoadingSpinnerProps = {} & BoxProps;

export function LoadingSpinner(props: LoadingSpinnerProps) {
  return (
    <Box
      width={1}
      height={1}
      p={0}
      margin={0}
      display="flex"
      justifyContent="center"
      alignItems="center"
      {...props}
    >
      <CircularProgress />
    </Box>
  );
}
