import React from 'react';
import { useTranslation } from 'react-i18next';

import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { DashboardContext } from '../../../../_lib/context/dashboard-context';

import { AdvancedCustomClassificationLabelDialog } from './dialog';

export function AdvancedCustomClassificationLabel({ enabled }: any) {
  const { t } = useTranslation();

  const [openDialog, setOpenDialog] = React.useState(false);

  const { dashboard } = React.useContext(DashboardContext);

  return (
    <>
      <AdvancedCustomClassificationLabelDialog
        open={openDialog}
        setOpen={setOpenDialog}
      />

      <Box sx={{ display: { xs: 'block', sm: 'flex' }, alignItems: 'center' }}>
        <Typography sx={{ mr: { xs: 0, sm: 2 }, mt: { xs: 1.5, sm: 0 } }}>
          <Typography variant="body2" component="span" color="textSecondary">
            {t('Classification Label')}
            {': '}
          </Typography>
          {dashboard.customClassificationLabel}
        </Typography>
        {enabled && (
          <Button
            variant="outlined"
            size="small"
            onClick={() => setOpenDialog(true)}
          >
            {t('Set Custom Classification Label')}
          </Button>
        )}
      </Box>
    </>
  );
}
