import 'core-js/stable';
import React from 'react';
import ReactDOM from 'react-dom';

import * as Sentry from '@sentry/react';

import { App } from './App';
import { reportWebVitals } from './reportWebVitals';

function checkTrue(value: string | undefined) {
  return ['true', '1', 'yes', 'on'].includes(`${value || ''}`.toLowerCase());
}

// Sentry
// Initialize sentry
if (checkTrue(process.env.REACT_APP_SENTRY_ENABLE)) {
  Sentry.init({
    dsn: `${process.env.REACT_APP_SENTRY_DSN}`,
    integrations: [new Sentry.BrowserTracing()],

    // capture all errors
    sampleRate: 1.0,

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate:
      parseFloat(process.env.REACT_APP_SENTRY_TRACES_SAMPLE_RATE || '') || 0.5,
  });
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
