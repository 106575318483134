import React from 'react';
import { useParams, Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
  CardHeader,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditIcon from '@mui/icons-material/Edit';
import PersonAddIcon from '@mui/icons-material/PersonAdd';

import { useDashboardContext } from '../_lib/context/dashboard-context';
import { AreaPermissionsManage } from '../components/area-permissions/manage';

export function InterventionHeader({ intervention }: any) {
  const { t } = useTranslation();

  const { priorityAreaId } = useParams<any>();

  const {
    user,
    dashboard: {
      priorityAreasSlug,
      interventionsSlug,
      interventionName,
      enableAreaPermissions,
      enableAreaLevels,
      mode: dashboardMode,
    },
  } = useDashboardContext();

  // 1. Header Action Menu
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorEl);
  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const editPath = React.useMemo(() => {
    const path = `/${priorityAreasSlug}/${priorityAreaId}/${interventionsSlug}`;
    const newPath = `${intervention.urlRelativeLevel}`;
    return `${t('/planning')}${enableAreaLevels ? newPath : path}?id=${
      intervention.id
    }&edit=true`;
  }, [
    enableAreaLevels,
    intervention.id,
    intervention.urlRelativeLevel,
    interventionsSlug,
    priorityAreaId,
    priorityAreasSlug,
    t,
  ]);

  // 1.1. Share
  const [openPermissionsDialog, setOpenPermissionsDialog] =
    React.useState(false);
  const handleMenuShare = () => {
    setOpenPermissionsDialog(true);
    handleMenuClose();
  };

  return (
    <>
      <AreaPermissionsManage
        open={openPermissionsDialog}
        setOpen={setOpenPermissionsDialog}
        area={intervention}
      />
      <CardHeader
        title={interventionName}
        titleTypographyProps={{
          variant: 'h5',
          sx: {
            lineHeight: 1.2,
            color: 'grey.800',
          },
        }}
        subheader={`${intervention.reference} - ${intervention.name}`}
        subheaderTypographyProps={{
          variant: 'h4',
          sx: { lineHeight: 1.2, color: 'black' },
        }}
        sx={{ px: 4, pt: 3, pb: 1 }}
        action={
          user.isAdmin &&
          dashboardMode !== 'SUPRANATIONAL' && (
            <>
              <IconButton
                aria-label="actions"
                aria-controls="priority-area-summary-menu"
                aria-haspopup="true"
                aria-expanded={openMenu ? 'true' : undefined}
                onClick={handleMenuClick}
              >
                <MoreVertIcon />
              </IconButton>
              <Menu
                id="priority-area-summary-menu"
                anchorEl={anchorEl}
                open={openMenu}
                onClose={handleMenuClose}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
              >
                {enableAreaPermissions && (
                  <MenuItem key="share" onClick={handleMenuShare}>
                    <ListItemIcon>
                      <PersonAddIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>{t('Share')}</ListItemText>
                  </MenuItem>
                )}
                <MenuItem component={RouterLink} to={editPath}>
                  <ListItemIcon>
                    <EditIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>{t('Edit')}</ListItemText>
                </MenuItem>
              </Menu>
            </>
          )
        }
      />
    </>
  );
}
