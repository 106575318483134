import { useTranslation } from 'react-i18next';

import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';

export function PlanningInterventionFormActionsCancel({
  intvInitial,
  setIntervention,
  setNewTargets,
  setNewFinanciers,
  setDisabled,
  disabled,
}: any) {
  const { t } = useTranslation();

  const handleCancel = () => {
    setIntervention(structuredClone(intvInitial));
    setNewTargets([] as any);
    setNewFinanciers([] as any);
    setDisabled(true);
  };

  return (
    <Button
      variant="outlined"
      onClick={handleCancel}
      disabled={disabled}
      startIcon={<CloseIcon />}
    >
      {t('Cancel')}
    </Button>
  );
}
