import CsvBuilder from './CsvBuilder';

import { slugify } from '../../../_utils/general-utils';
import { getTargets } from '../../../_utils/targets-utils';

function getCsvBuilder(columns: string[], rows: string[][], fileName: string) {
  const builder = new CsvBuilder(fileName);
  return builder.setDelimeter(',').setColumns(columns).addRows(rows);
}

function getColumns(dataRowObj: any[]) {
  return Object.keys(dataRowObj).filter(
    (key) => key !== '__typename' && key !== 'percDone' && key !== 'id'
  );
}

function getRows(columns: string[], dataRowsObj: any[]) {
  return dataRowsObj.map((row: any) =>
    columns.map((key) => {
      return row[key];
    })
  );
}

function getAreasCsvBuilders({
  t,
  dashboard,
  areas,
  areaName,
  includeFinanciers,
  includeMilestones,
}: any) {
  const csvBuilders: CsvBuilder[] = [];
  const areaFileName = slugify(areaName);

  // area
  const areaColumns = [
    t('Reference'),
    t('Name'),
    t('Description / Goal'),
    ...(!dashboard?.enableLeadsMulti
      ? [t('Lead'), t('Co-Lead')]
      : [t('Leads'), t('Co-Leads')]),
    ...(dashboard?.enableStakeholders ? [t('Stakeholders')] : []),
  ];
  const areaRows = areas.reduce((acc: any, area: any) => {
    acc.push([
      area.reference,
      area.name,
      area.description,
      ...(dashboard?.enableLeadsMulti
        ? [
            area.leads?.map((lead: any) => lead?.name).join(', '),
            area.coLeads?.map((coLead: any) => coLead?.name).join(', '),
          ]
        : [area.lead?.name, area.coLead?.name]),
      ...(dashboard?.enableStakeholders
        ? [
            area.stakeholders
              .map((stakeholder: any) => stakeholder.name)
              .join(', '),
          ]
        : []),
    ]);
    return acc;
  }, []);
  csvBuilders.push(getCsvBuilder(areaColumns, areaRows, areaFileName));

  // financiers
  if (includeFinanciers) {
    const financiersColumns = [
      `${areaName}`,
      t('Name'),
      `${t('Amount')} (${dashboard?.currency.split('::'[1] || 'USD')})`,
      t('Finance Method'),
      t('Procurement Method'),
    ];
    const financiersRows = areas.reduce((acc: any, area: any) => {
      area.financiers?.forEach((financier: any) => {
        acc.push([
          `${area.reference} - ${area.name}`,
          financier.name,
          financier.amount,
          financier.financeMethod?.name,
          financier.procurementMethod?.name,
        ]);
      });
      return acc;
    }, []);
    csvBuilders.push(
      getCsvBuilder(
        financiersColumns,
        financiersRows,
        `${areaFileName}-financiers`
      )
    );
  }

  // targets
  const targetColumns = [
    `${areaName}`,
    t(`Indicator`),
    ...(dashboard?.enableTargetCategories ? [t('Category')] : []),
    ...(dashboard?.enableTargetPartner ? [t('Partner')] : []),
    ...(dashboard?.enableTargetBaselineValue ? [t('Baseline Value')] : []),
    t('Target Value'),
    t('Start Date'),
    t('Deadline'),
    t('Latest Amount'),
    t('Latest Date'),
    ...(dashboard?.enableTargetFinancedAmount ? [t('Financed Amount')] : []),
  ];
  const targetRows = areas.reduce((acc: any, area: any) => {
    const targets = getTargets([area]);
    return [
      ...acc,
      ...targets.map((target: any) => [
        `${area.reference} - ${area.name}`,
        target.targetUnit.name,
        ...(dashboard?.enableTargetCategories
          ? [target.targetUnit.category]
          : []),
        ...(dashboard?.enableTargetPartner ? [target.partner] : []),
        ...(dashboard?.enableTargetBaselineValue ? [target.startAmount] : []),
        target.targetAmount,
        target.startedOn,
        target.deadline,
        target.latestAmount,
        target.latestDate,
        ...(dashboard?.enableTargetFinancedAmount
          ? [target.targetFinancedAmount]
          : []),
      ]),
    ];
  }, []);
  csvBuilders.push(
    getCsvBuilder(targetColumns, targetRows, `${areaFileName}-targets`)
  );

  // milestones
  if (includeMilestones) {
    const milestoneColumns = [
      `${areaName}`,
      t('Title'),
      t('Description'),
      ...(dashboard?.enableActivityMultipleAssignees
        ? [t('Assignees')]
        : [t('Assignee')]),
      ...(dashboard?.enableActivityStartDate ? [t('Start Date')] : []),
      t('Deadline'),
      t('Status'),
      ...(dashboard?.enableActivityPercentage ? [t('Percentage Done')] : []),
    ];
    const milestones = areas.reduce((acc: any, area: any) => {
      return [...acc, ...(area.milestones || [])];
    }, []);

    const milestoneRows = areas.reduce((acc: any, area: any) => {
      return [
        ...acc,
        ...milestones.map((milestone: any) => [
          `${area.reference} - ${area.name}`,
          milestone.title,
          milestone.description,
          ...(dashboard?.enableActivityMultipleAssignees
            ? [
                milestone.assignees
                  .map((assignee: any) => assignee.name)
                  .join(', '),
              ]
            : [milestone.assignee?.name || '']),
          ...(dashboard?.enableActivityStartDate ? [milestone.startedOn] : []),
          milestone.deadline,
          milestone.status,
          ...(dashboard?.enableActivityPercentage ? [milestone.percDone] : []),
        ]),
      ];
    }, []);
    csvBuilders.push(
      getCsvBuilder(
        milestoneColumns,
        milestoneRows,
        `${areaFileName}-milestones`
      )
    );
  }
  return csvBuilders;
}

export const getCsvBuilders = ({ reportData, filterMembers, t }: any) => {
  const csvBuilders: CsvBuilder[] = [];

  // dashboard members
  if (!filterMembers) {
    const members = reportData.dashboard.users.map((user: any) => ({
      name: user.name,
      email: user.email,
      isAdmin: user.isAdmin || false,
    }));
    const membersColumns = getColumns(members[0]);
    csvBuilders.push(
      getCsvBuilder(membersColumns, getRows(membersColumns, members), 'members')
    );
  }

  if (reportData.priorityAreas) {
    const { priorityAreas } = reportData;

    // priority areas
    const priorityAreasByLevel = !reportData?.dashboard?.enableAreaLevels
      ? { [reportData.dashboard.priorityAreaName]: priorityAreas }
      : priorityAreas.reduce((acc: any, area: any) => {
          const levelName =
            area.level?.name || reportData.dashboard.priorityAreaName;
          if (!acc[levelName]) {
            acc[levelName] = [];
          }
          acc[levelName].push(area);
          return acc;
        }, {});
    Object.keys(priorityAreasByLevel).forEach((levelName: string) => {
      const areas = priorityAreasByLevel[levelName];
      csvBuilders.push(
        ...getAreasCsvBuilders({
          dashboard: reportData.dashboard,
          areas,
          areaName: levelName,
          includeFinanciers: reportData.dashboard.enablePriorityAreaFinancials,
          t,
        })
      );
    });

    // interventions
    const interventions = priorityAreas.reduce((acc: any, area: any) => {
      return [...acc, ...(area.interventions || [])];
    }, []);
    if (interventions.length > 0) {
      csvBuilders.push(
        ...getAreasCsvBuilders({
          t,
          dashboard: reportData.dashboard,
          areas: interventions,
          areaName: reportData.dashboard.interventionName,
          includeFinanciers: true,
          includeMilestones: true,
        })
      );
    }
  }

  return csvBuilders;
};
