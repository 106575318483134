import React from 'react';
import { useTranslation } from 'react-i18next';

import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Chip from '@mui/material/Chip';

import PersonAddIcon from '@mui/icons-material/PersonAdd';

import { useDashboardContext } from '../_lib/context/dashboard-context';

import { CreateMember } from '../actions/create-member';
import { EMOJI_DELIMITER } from '../_lib/data/constants';

const filter = createFilterOptions<any>();

export function MemberAutocomplete({
  label,
  placeholder,

  filterIds,
  filterAdmins,
  value,
  valueFreeText,
  onChange,

  error,
  disabled,

  required,
  multiple,
  disableClearable,
  freeSolo,
}: any) {
  const { t } = useTranslation();
  const { user, users } = useDashboardContext();

  const [addedMember, setAddedMember] = React.useState<any>(null);
  const [openCreateMemberDialog, setOpenCreateMemberDialog] =
    React.useState(false);

  const combinedValue = React.useMemo(() => {
    const valueFreeTextArray = valueFreeText ? JSON.parse(valueFreeText) : [];

    if (!multiple) {
      return value?.id
        ? value
        : valueFreeTextArray[0]
        ? { id: 'freeText', name: valueFreeTextArray[0] }
        : null;
    }

    const valueArray = Array.isArray(value) ? value : [value];
    return [
      ...valueArray,
      ...valueFreeTextArray.map((v: any) => ({ id: 'freeText', name: v })),
    ];
  }, [multiple, value, valueFreeText]);

  // ----------------------------------------------------------------------------------------------------
  // ----------------------------------------------------------------------------------------------------
  // handlers
  // ----------------------------------------------------------------------------------------------------

  const handleNewMember = (newMember: any) => {
    setAddedMember(newMember);
  };

  const handleChange = (event: any, val: any, reason: any) => {
    if (!multiple) {
      if (val.id === 'addUser') return;
      onChange(event, val);
    } else {
      onChange(
        event,
        val.filter((v: any) => v.id !== 'addUser')
      );
    }
  };

  // ----------------------------------------------------------------------------------------------------
  // ----------------------------------------------------------------------------------------------------
  // effects
  // ----------------------------------------------------------------------------------------------------

  // added member
  React.useEffect(() => {
    if (addedMember) {
      if (users.find((u: any) => u.id === addedMember.id)) {
        if (multiple) {
          const newValue = structuredClone(value);
          newValue.push(addedMember);
          onChange(null, newValue);
        } else {
          onChange(null, addedMember);
        }
        setAddedMember(null);
      }
    }
  }, [users, value, onChange, multiple, addedMember]);

  // ----------------------------------------------------------------------------------------------------

  return (
    <>
      {/* ---------------------------------------------------------------------------------------------------- */}
      {/* dialogs */}
      {/* ---------------------------------------------------------------------------------------------------- */}
      {/* create member */}
      <CreateMember
        setSelect={handleNewMember}
        showAddUserDialog={openCreateMemberDialog}
        setShowAddUserDialog={setOpenCreateMemberDialog}
      />

      {/* ---------------------------------------------------------------------------------------------------- */}
      {/* autocomplete */}
      {/* ---------------------------------------------------------------------------------------------------- */}
      <Autocomplete
        options={users}
        fullWidth
        size="small"
        disabled={disabled}
        disableClearable={disableClearable}
        multiple={multiple}
        freeSolo={freeSolo}
        selectOnFocus={freeSolo}
        handleHomeEndKeys={freeSolo}
        placeholder={placeholder}
        value={combinedValue || (multiple ? [] : null)}
        renderTags={(val: readonly string[], getTagProps) =>
          val.map((option: any, index: number) => (
            <Chip
              variant="outlined"
              label={option.name}
              {...getTagProps({ index })}
            />
          ))
        }
        renderInput={(params) => {
          const inputValue = params.inputProps.value as string;
          return (
            <TextField
              {...params}
              label={label}
              placeholder={placeholder}
              required={required}
              disabled={disabled}
              error={!!error}
              helperText={error}
              inputProps={{
                ...params.inputProps,
                style: {
                  ...params.inputProps.style,
                  paddingRight: '28px',
                },
                value: inputValue?.split(EMOJI_DELIMITER)[0] || '',
              }}
            />
          );
        }}
        getOptionLabel={(option: any) =>
          option.id === 'freeText'
            ? option.name
            : `${option.name}${EMOJI_DELIMITER}${option.email}`
        }
        renderOption={(props, option: any) => {
          // add user free text option
          if (option.id === 'addUserFreeText') {
            return (
              <MenuItem {...props} key="addUserFreeTextMenuItem">
                <ListItemText>&quot;{option.name}&quot;</ListItemText>
              </MenuItem>
            );
          }

          // add user option
          if (option.id === 'addUser') {
            return [
              <Divider key="addUserDivider" />,
              <MenuItem
                {...props}
                key="addUserMenuItem"
                onClick={() => {
                  setOpenCreateMemberDialog(true);
                }}
              >
                <ListItemIcon>
                  <PersonAddIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>{option.name}</ListItemText>
              </MenuItem>,
            ];
          }

          return (
            <MenuItem {...props} key={option.email}>
              <ListItemText
                primary={option.name}
                secondary={option.email}
                sx={{
                  '& .MuiListItemText-primary, & .MuiListItemText-secondary': {
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                  },
                }}
              />
            </MenuItem>
          );
        }}
        isOptionEqualToValue={(option, newValue) => option.id === newValue.id}
        onChange={handleChange}
        filterOptions={(options: any, params) => {
          let filtered = filter(options, params);

          // remove users that are already selected
          if (multiple) {
            filtered = filtered.filter((option: any) => {
              return !value.map((u: any) => u.id).includes(option.id);
            });
          } else {
            filtered = filtered.filter((option: any) => option.id !== value);
          }

          // remove users that are in the filteredOptionsIds
          if (filterIds) {
            filtered = filtered.filter((option: any) => {
              return !filterIds.includes(option.id);
            });
          }

          // remove users that are admins
          if (filterAdmins) {
            filtered = filtered.filter((option: any) => {
              return !option.isAdmin;
            });
          }

          // if no results and enable free text
          if (filtered.length === 0 && freeSolo) {
            filtered.push({
              id: 'addUserFreeText',
              name: params.inputValue,
              inputValue: params.inputValue,
            });
          }

          // add add user option
          if (user.isAdmin) {
            filtered.push({
              id: 'addUser',
              name: t('Add User'),
            });
          }

          return filtered;
        }}
      />
    </>
  );
}
