import React from 'react';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import Card, { CardProps } from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography, { TypographyProps } from '@mui/material/Typography';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { useLocalStorage } from '../../_utils/hooks/use-local-storage';

import { LinearProgressWithLabel } from '../linear-progress/with-label';

import { TargetsSummaryTable } from './table';

export function TargetsSummaryCard({
  id,
  targetsSummary,
  rowsPerPage,
  setRowsPerPage,
  page,
  setPage,
  titleTypographyProps,
  cardProps,
  expanded,
  expandMoreIconButtonProps,
  includePriorityAreas,
}: {
  id: string;
  targetsSummary: any;
  rowsPerPage: number;
  setRowsPerPage: any;
  page: number;
  setPage: any;
  titleTypographyProps?: TypographyProps;
  cardProps?: CardProps;
  expanded?: boolean;
  expandMoreIconButtonProps?: IconButtonProps;
  includePriorityAreas?: boolean;
}) {
  const { t } = useTranslation();
  const [open, setOpen] = useLocalStorage(id, expanded);

  // ----------------------------------------------------------------------------------------

  const { sx: cardSxProps, ...cardOtherProps } = cardProps || {};
  const { sx: expandMoreIconButtonSxProps, ...expandMoreIconButtonOtherProps } =
    expandMoreIconButtonProps || {};

  // ----------------------------------------------------------------------------------------

  return targetsSummary?.targetsByUnit?.meta?.count ? (
    <Card
      {...{
        variant: 'outlined',
        sx: {
          ...cardSxProps,
        },
        ...cardOtherProps,
      }}
    >
      <CardContent>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography
            {...titleTypographyProps}
            onClick={(event: any) => {
              event.stopPropagation();
              if (titleTypographyProps?.onClick) {
                titleTypographyProps.onClick(event);
              }
              setOpen(!open);
            }}
          >
            {t('Targets')}{' '}
            <Typography variant="body2" component="span">
              {`(${targetsSummary?.targetsByUnit?.meta.count})`}
              {t('% Progress')}
            </Typography>
          </Typography>
          <IconButton
            onClick={(event: any) => {
              event.preventDefault();
              event.stopPropagation();
              setOpen(!open);
            }}
            {...{
              component: 'span',
              sx: {
                ml: 'auto',
                displayPrint: 'none',
                ...expandMoreIconButtonSxProps,
              },
              ...expandMoreIconButtonOtherProps,
            }}
          >
            <ExpandMoreIcon
              sx={{
                transform: open ? 'rotate(0deg)' : 'rotate(-90deg)',
                transition: 'transform 0.2s ease-in-out',
              }}
              fontSize="inherit"
            />
          </IconButton>
        </Box>

        {/* total */}
        <LinearProgressWithLabel
          sx={{ height: '15px', borderRadius: '3px' }}
          value={targetsSummary?.progressPerc}
        />

        {/* table */}
        <Collapse in={open}>
          <Box sx={{ my: 2 }} />
          <TargetsSummaryTable
            targetsSummary={targetsSummary}
            includePriorityAreas={includePriorityAreas}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            page={page}
            setPage={setPage}
          />
        </Collapse>
      </CardContent>
    </Card>
  ) : null;
}

TargetsSummaryCard.defaultProps = {
  expanded: false,
  titleTypographyProps: {
    variant: 'h6',
    sx: { mb: 0.5, flexGrow: 1 },
  },
  cardProps: {},
  expandMoreIconButtonProps: {},
  includePriorityAreas: false,
};
