import React from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorBoundary } from 'react-error-boundary';

import { useTheme } from '@mui/material/styles';

import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

import IconButton from '@mui/material/IconButton';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import FilterListIcon from '@mui/icons-material/FilterList';
import Tooltip from '@mui/material/Tooltip';

import { TabPanel } from '../tab-panel';

import { CreateMember } from '../../../actions/create-member';
import { DashboardFormMembersTable } from './table';
import { ReactErrorComponent } from '../../../_lib/react-error';

export function SettingsMembers({ tabVal, tabInd }: any) {
  const { t } = useTranslation();

  const theme = useTheme();

  const [showAddUserDialog, setShowAddUserDialog] = React.useState(false);
  const [searchTerm, setSearchTerm] = React.useState('');

  return (
    <TabPanel
      value={tabVal}
      index={tabInd}
      title={`${t('Members')}`}
      actions={
        <Stack spacing={2} direction="row" alignItems="center">
          <TextField
            variant="standard"
            label={t('Filter members')}
            size="small"
            value={searchTerm}
            onChange={(e: any) => setSearchTerm(e.target.value)}
            InputProps={{
              startAdornment: (
                <FilterListIcon sx={{ color: 'grey.700', mr: 0.5 }} />
              ),
              inputProps: {
                autoComplete: 'off',
              },
            }}
            sx={{
              [theme.breakpoints.down('sm')]: {
                width: '100%',
              },
            }}
          />
          <Tooltip title={t('Add Member') as string}>
            <IconButton
              size="large"
              color="primary"
              onClick={() => setShowAddUserDialog(true)}
            >
              <PersonAddIcon />
            </IconButton>
          </Tooltip>
        </Stack>
      }
    >
      <CreateMember
        showAddUserDialog={showAddUserDialog}
        setShowAddUserDialog={setShowAddUserDialog}
      />

      {/* list of members */}
      <ErrorBoundary FallbackComponent={ReactErrorComponent}>
        <DashboardFormMembersTable searchTerm={searchTerm} />
      </ErrorBoundary>
    </TabPanel>
  );
}
