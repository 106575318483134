import React from 'react';
import { gql, useQuery } from '@apollo/client';

const CONTENT_ACCESS_QUERY = gql`
  query ContentAccess($contentId: ID!) {
    content(contentId: $contentId) {
      id
      access
    }
  }
`;

const CONTENT_ACCESS_SUBSCRIPTION = gql`
  subscription ContentAccess($contentId: ID!) {
    content(contentId: $contentId) {
      id
      access
    }
  }
`;

export const useContentAccess = (contentId: string) => {
  const { data, loading, error, subscribeToMore } = useQuery(
    CONTENT_ACCESS_QUERY,
    {
      variables: { contentId },
    }
  );

  React.useEffect(() => {
    let unsubscribe: any;
    if (data) {
      unsubscribe = subscribeToMore({
        document: CONTENT_ACCESS_SUBSCRIPTION,
        variables: { contentId },
        updateQuery: (prev, { subscriptionData }) => {
          if (!subscriptionData.data) return prev;
          return subscriptionData.data;
        },
      });
    }
    return () => {
      if (unsubscribe) unsubscribe();
    };
  }, [data, subscribeToMore, contentId]);

  const content = data?.content;

  return { content, loading, error };
};
