import React from 'react';
import parseISO from 'date-fns/parseISO';

/* eslint-disable no-useless-escape */
export const isoDateRegex =
  /^\d{4}-(0[1-9]|1[0-2])-([12]\d|0[1-9]|3[01])([T\s](([01]\d|2[0-3])\:[0-5]\d|24\:00)(\:[0-5]\d([\.,]\d+)?)?([zZ]|([\+-])([01]\d|2[0-3])\:?([0-5]\d)?)?)?$/;
/* eslint-enable no-useless-escape */

export function getEmptyValue(emptyValue = '', props: any = {}) {
  if (typeof emptyValue === 'function') {
    return props.columnDef.emptyValue(props.rowData);
  }
  return emptyValue;
}

export function getCurrencyValue(currencySetting: any, value: any) {
  if (currencySetting !== undefined) {
    return new Intl.NumberFormat(
      currencySetting.locale !== undefined ? currencySetting.locale : 'en-US',
      {
        style: 'currency',
        currency:
          currencySetting.currencyCode !== undefined
            ? currencySetting.currencyCode
            : 'USD',
        minimumFractionDigits:
          currencySetting.minimumFractionDigits !== undefined
            ? currencySetting.minimumFractionDigits
            : 2,
        maximumFractionDigits:
          currencySetting.maximumFractionDigits !== undefined
            ? currencySetting.maximumFractionDigits
            : 2,
      }
    ).format(value !== undefined ? value : 0);
  }
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(value !== undefined ? value : 0);
}

export function getRenderValue(props: any, icons: any, type: any = null) {
  const dateLocale =
    props.columnDef.dateSetting && props.columnDef.dateSetting.locale
      ? props.columnDef.dateSetting.locale
      : undefined;
  if (
    props.columnDef.emptyValue !== undefined &&
    (props.value === undefined || props.value === null)
  ) {
    return getEmptyValue(props.columnDef.emptyValue, props);
  }
  if (
    props.rowData === undefined &&
    props.value &&
    props.columnDef.groupRender
  ) {
    return props.columnDef.groupRender(props.groupData);
  }
  if (props.columnDef.render && props.rowData) {
    return props.columnDef.render(props.rowData);
  }
  if (props.columnDef.type === 'boolean') {
    const style = { textAlign: 'left', verticalAlign: 'middle', width: 48 };
    if (props.value) {
      return React.cloneElement(icons.Check, { style });
    }
    return React.cloneElement(icons.ThirdStateCheck, { style });
  }
  if (props.columnDef.type === 'date') {
    if (props.value instanceof Date) {
      return props.value.toLocaleDateString(dateLocale);
    }
    if (isoDateRegex.exec(props.value)) {
      return parseISO(props.value).toLocaleDateString(dateLocale);
    }
    return props.value;
  }
  if (props.columnDef.type === 'time') {
    if (props.value instanceof Date) {
      return props.value.toLocaleTimeString();
    }
    if (isoDateRegex.exec(props.value)) {
      return parseISO(props.value).toLocaleTimeString(dateLocale);
    }
    return props.value;
  }
  if (props.columnDef.type === 'datetime') {
    if (props.value instanceof Date) {
      return props.value.toLocaleString();
    }
    if (isoDateRegex.exec(props.value)) {
      return parseISO(props.value).toLocaleString(dateLocale);
    }
    return props.value;
  }
  if (props.columnDef.type === 'currency') {
    return getCurrencyValue(props.columnDef.currencySetting, props.value);
  }
  if (typeof props.value === 'boolean') {
    // To avoid forwardref boolean children.
    return props.value.toString();
  }

  return props.value;
}
