import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';

import Button from '@mui/material/Button';
import ClearIcon from '@mui/icons-material/Clear';

import { DialogModal } from '../../../../components/dialog-modal';
import { DashboardContext } from '../../../../_lib/context/dashboard-context';
import { CONTENT_UPDATE_REJECT } from '../../../../_lib/graphql/mutations';
import { logError } from '../../../../_lib/error';
import { ContentDialogContext } from '../../../../_lib/context/content-dialog-context';
import { CONTENT_QUERY } from '../../../../_lib/graphql/queries';

export function RejectContentUpdate({ update }: any) {
  const { t } = useTranslation();

  const { setSnackbarOpen, setSnackbarIsError } =
    React.useContext(DashboardContext);
  const { contentId } = React.useContext(ContentDialogContext);

  const [confirmDialogOpen, setConfirmDialogOpen] = React.useState(false);

  const [rejectUpdateSuggestionMutation, { loading }] = useMutation(
    CONTENT_UPDATE_REJECT,
    {
      update: (cache, { data }) => {
        cache.writeQuery({
          query: CONTENT_QUERY,
          data: { content: data.contentUpdateReject.content },
          variables: { id: contentId },
        });
      },
    }
  );

  const rejectContentUpdate = (message: any) => {
    rejectUpdateSuggestionMutation({
      variables: {
        id: update.id,
        rejectionMessage: message.text,
      },
    }).catch((e) => {
      logError(e);
      setSnackbarIsError(true);
      setSnackbarOpen(true);
    });
  };

  return (
    <>
      <DialogModal
        loading={loading}
        show={confirmDialogOpen}
        onSubmit={rejectContentUpdate}
        onDismiss={() => setConfirmDialogOpen(false)}
        title={t('Please give reasons for rejecting this update')}
        type="text-input"
      />

      <Button
        variant="outlined"
        size="small"
        startIcon={<ClearIcon />}
        onClick={() => setConfirmDialogOpen(true)}
        disabled={update.status === 'OBSOLETE'}
        color="error"
      >
        {t('Reject')}
      </Button>
    </>
  );
}

export default RejectContentUpdate;
