import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

import { ReportsTitle } from '../title';
import { ReportsHomeList } from './list';

export function ReportsHome() {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <>
      <ReportsTitle
        primary={
          <>
            {t('Reports')}
            <Typography variant="caption" sx={{ ml: 1, display: 'inline' }}>
              {t('BETA')}
            </Typography>
          </>
        }
        actions={
          <Button
            variant="contained"
            onClick={() => history.push(`${t('/reports')}${t('/new')}`)}
            disableElevation
          >
            {t('Create')}
          </Button>
        }
      />
      <Container sx={{ py: 3 }}>
        <ReportsHomeList />
      </Container>
    </>
  );
}
