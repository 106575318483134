import { uniq, map } from 'lodash';
import { PriorityArea } from '../executive-summary/executive-summary.types';

export const filterPriorityAreasByLevel = ({
  priorityAreas,
  filters,
}: {
  priorityAreas: PriorityArea[];
  filters?: {
    onId: {
      [x: string]: PriorityArea;
    };
    priorityAreas: PriorityArea[];
  };
}) => {
  if (!filters?.priorityAreas?.length) return priorityAreas;

  if (
    filters?.priorityAreas?.length &&
    filters?.priorityAreas?.every((pa: PriorityArea) => pa?.level?.order === 0)
  ) {
    return (priorityAreas || [])?.filter((pa: PriorityArea) =>
      (filters?.priorityAreas || [])?.find(
        (priorityAreaFilter: PriorityArea) => {
          return (
            String(priorityAreaFilter?.id) === String(pa?.id) ||
            String(pa?.parent?.id) === String(priorityAreaFilter?.id)
          );
        }
      )
    );
  }

  const filteredPriorityAreasByLevel = (priorityAreas || [])?.filter(
    (pa: PriorityArea) =>
      (filters?.priorityAreas || [])?.find(
        (priorityAreaFilter: PriorityArea) => {
          return String(priorityAreaFilter?.id) === String(pa?.id);
        }
      )
  );
  return filteredPriorityAreasByLevel;
};

export const getFilterOptions = ({
  allPriorityAreas,
  currentPriorityAreas,
  filters,
}: {
  allPriorityAreas: PriorityArea[];
  currentPriorityAreas: PriorityArea[];
  filters?: {
    onId: {
      [x: string]: PriorityArea;
    };
    priorityAreas: PriorityArea[];
  };
}): PriorityArea[] => {
  if (
    filters?.priorityAreas?.length &&
    filters?.priorityAreas?.some((pa: PriorityArea) => pa?.level?.order === 0)
  ) {
    return (allPriorityAreas || [])
      ?.filter((pa: PriorityArea) =>
        (filters?.priorityAreas || [])?.find(
          (priorityAreaFilter: PriorityArea) => {
            return String(pa?.parent?.id) === String(priorityAreaFilter?.id);
          }
        )
      )
      .concat(
        filters?.priorityAreas?.filter(
          (pa: PriorityArea) => pa?.level?.order === 0
        )
      );
  }
  if (
    filters?.priorityAreas?.length &&
    filters?.priorityAreas?.every((pa: PriorityArea) => pa?.level?.order === 1)
  ) {
    const parentPriorityAreas = (allPriorityAreas || [])?.filter(
      (pa: PriorityArea) =>
        (filters?.priorityAreas || [])?.find(
          (priorityAreaFilter: PriorityArea) => {
            return String(pa?.id) === String(priorityAreaFilter?.parent?.id);
          }
        )
    );
    const childPriorityAreas = (allPriorityAreas || [])?.reduce(
      (acc: PriorityArea[], pa: PriorityArea) => {
        const result = (parentPriorityAreas || [])?.find(
          (priorityAreaFilter: PriorityArea) => {
            return String(pa?.parent?.id) === String(priorityAreaFilter?.id);
          }
        );
        if (result) {
          acc.push(pa);
        }
        return acc;
      },
      []
    );

    return parentPriorityAreas.concat(childPriorityAreas);
  }

  return currentPriorityAreas;
};

function getArrayIntersection(...arrays: any[]) {
  if (arrays.length === 0) {
    return [];
  }
  const flattenedArrays = arrays.map((arr) => [].concat(...arr));

  let intersection = flattenedArrays[0];
  // eslint-disable-next-line no-plusplus
  for (let i = 1; i < flattenedArrays.length; i++) {
    intersection = intersection.filter((element) =>
      flattenedArrays[i].includes(element)
    );
  }
  intersection = [...new Set(intersection)];

  return intersection;
}

export const filterContentsByPriorityAreas = ({
  contents,
  filteredPriorityAreas,
}: {
  contents: any[];
  filteredPriorityAreas: {
    onId: { [x: string]: PriorityArea };
    priorityAreas: PriorityArea[];
  };
}) => {
  if (!filteredPriorityAreas?.priorityAreas?.length) return contents;
  const levelOrder0PriorityAreas = filteredPriorityAreas?.priorityAreas?.filter(
    (pa: PriorityArea) => Number(pa?.level?.order) === 0
  );

  const levelOrderGreaterThan0PriorityAreas =
    filteredPriorityAreas?.priorityAreas?.filter(
      (pa: PriorityArea) => Number(pa?.level?.order) > 0
    );
  if (!levelOrderGreaterThan0PriorityAreas?.length) return contents;

  const getAllContents = (arr: any[]) => {
    const nestedArray = arr?.reduce((acc: any[], item: any) => {
      if (item?.content?.length) {
        acc.push(item?.content);
      }
      return acc;
    }, []);
    return nestedArray;
  };

  const flatArray = getAllContents(levelOrderGreaterThan0PriorityAreas).flat();

  const intersection = getArrayIntersection(
    uniq(map(contents, 'id')) || [],
    uniq(map(flatArray, 'id')) || []
  );

  return contents.filter((obj1) => intersection.find((id) => obj1.id === id));
};
