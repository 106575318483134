import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React, { MouseEventHandler, useEffect, useRef, useState } from 'react';

import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { useTranslation } from 'react-i18next';

import type {
  ExecutiveSummaryPriorityAreaListPanelProps,
  PriorityAreaVirtualizedRowProps,
  PriorityArea,
} from '../executive-summary/executive-summary.types';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '../executive-summary/executive-summary.styles';
import ListVirtualized from '../../../../components/list/list-virtualized';

function ContentStatusTab({ label, count }: { label: string; count: number }) {
  return (
    <Typography
      variant="body1"
      component="p"
      sx={{
        fontSize: {
          xs: '12px',
          md: '16px',
        },
      }}
      bgcolor="#FDEDED"
      color="#5F2120"
      borderColor="A80000"
      border="1px solid"
      px={1}
      borderRadius="4px"
    >
      <Typography
        component="span"
        fontWeight="600"
        mr={1}
        sx={{
          fontSize: {
            xs: '12px',
            md: '16px',
          },
        }}
      >
        {count}
      </Typography>
      {label}
    </Typography>
  );
}

function PriorityAreaVirtualizedRow({
  index,
  style,
  setRowHeight,
  windowWidth,
  onClick,
  items,
  selectedItem,
  tabLabel,
}: PriorityAreaVirtualizedRowProps) {
  const rowRef = useRef<HTMLLIElement>(null);

  useEffect(() => {
    if (rowRef?.current) {
      setRowHeight(index, rowRef?.current?.clientHeight || 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowRef, windowWidth]);

  const handleClick: MouseEventHandler<HTMLLIElement> = (event) => {
    onClick({
      event,
      index,
      item: items[index],
    });
  };

  const longoverdue = items?.[index]?.contentByStatus?.longoverdue || [];

  return items?.[index] ? (
    <div style={style}>
      <ListItem
        id={`priority-area-list-item-${index}`}
        sx={{
          display: 'flex',
          flex: '0 0 auto',
          justifyContent: 'flex-start',
          width: '100%',
        }}
        onClick={handleClick}
        key={`Row${index}`}
        disablePadding
        ref={rowRef}
      >
        <ListItemButton
          id={`priority-area-list-item-${index}`}
          sx={{
            '&.MuiButtonBase-root.Mui-selected': {
              background: '#E6E6F8',
            },
            flexDirection: {
              xs: 'column',
              md: 'row',
            },
            paddingX: 6,
            display: 'flex',
            flex: '0 0 auto',
            justifyContent: 'flex-start',
            width: '100%',
          }}
          selected={
            Number(selectedItem?.level?.order) > 0 &&
            selectedItem?.id === items[index]?.id
          }
        >
          <ListItemText
            sx={{ width: 'auto' }}
          >{`${items?.[index]?.reference} - ${items?.[index]?.name}`}</ListItemText>
          <ContentStatusTab label={tabLabel} count={longoverdue?.length} />
        </ListItemButton>
      </ListItem>
    </div>
  ) : null;
}

function ExecutiveSummaryPriorityAreaListPanel(
  props: ExecutiveSummaryPriorityAreaListPanelProps
) {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState<string | false>('panel-0');

  const priorityAreas = props?.priorityAreas;
  const selectedItem = props?.selectedItem;

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(panel);
    };

  const handleRowClick = ({
    item,
    event,
  }: {
    event: React.MouseEvent<
      HTMLLIElement | HTMLDivElement,
      globalThis.MouseEvent
    >;
    index: number;
    item: PriorityArea;
  }) => {
    props?.onRowClick?.({
      item,
    });
  };

  useEffect(() => {
    setExpanded('panel-0');
  }, [priorityAreas?.length]);

  return (
    <div>
      <Box bgcolor="#EEEEEE" px={2} py={1}>
        <Typography variant="h5" component="h2">
          {props?.panelTitle}
        </Typography>
      </Box>
      {priorityAreas?.map((priorityArea: PriorityArea, index: number) => {
        const longoverdue = priorityArea?.contentByStatus?.longoverdue || [];
        const key = `${priorityArea?.name}${index}`;
        return (
          <Accordion
            key={key}
            TransitionProps={{ unmountOnExit: true }}
            expanded={expanded === `panel-${index}`}
            onChange={handleChange(`panel-${index}`)}
            id="accordion-tab"
            sx={{
              margin: '0 !important',
            }}
          >
            <AccordionSummary
              id="panel1d-header"
              aria-controls="panel1d-content"
              onClick={(event) =>
                handleRowClick({ event, item: priorityArea, index })
              }
              className={
                selectedItem?.level?.order === 0 &&
                selectedItem?.id === priorityArea?.id
                  ? 'selected'
                  : ''
              }
            >
              <Typography fontSize="20px">
                {priorityArea?.reference && priorityArea?.name
                  ? `${priorityArea?.reference} ${priorityArea?.name}`
                  : `${t('Unknown')}`}
              </Typography>
              <Box pl={1} />
              <ContentStatusTab
                label={t('Most Overdue')}
                count={longoverdue?.length}
              />
            </AccordionSummary>
            {priorityArea?.children ? (
              <AccordionDetails>
                <ListVirtualized itemsCount={priorityArea?.children?.length}>
                  {({ index, style, setRowHeight, windowWidth }) => (
                    <PriorityAreaVirtualizedRow
                      key={`${priorityArea.name}${priorityArea.id}`}
                      index={index}
                      style={style}
                      setRowHeight={setRowHeight}
                      windowWidth={windowWidth}
                      onClick={handleRowClick}
                      items={priorityArea?.children || []}
                      selectedItem={selectedItem}
                      tabLabel={t('Most Overdue')}
                    />
                  )}
                </ListVirtualized>
              </AccordionDetails>
            ) : null}
          </Accordion>
        );
      })}
    </div>
  );
}

export default ExecutiveSummaryPriorityAreaListPanel;
