import React from 'react';
import { useTranslation } from 'react-i18next';

import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';

import RefreshIcon from '@mui/icons-material/Refresh';

import * as serviceWorkerRegistration from '../../../_lib/offline/serviceWorkerRegistration';

import { useOnlineStatus } from '../../../_lib/offline/use-online-status';

export function AppbarAvatarReload() {
  const { t } = useTranslation();

  const isOnline = useOnlineStatus();

  const handleReload = React.useCallback(() => {
    if (isOnline) {
      window.localStorage.clear();
      serviceWorkerRegistration.unregister().then(() => {
        window.location.reload();
      });
    } else {
      window.location.reload();
    }
  }, [isOnline]);

  return (
    <MenuItem onClick={() => handleReload()}>
      <ListItemIcon>
        <RefreshIcon fontSize="small" />
      </ListItemIcon>

      <ListItemText
        primary={t('Reload Page')}
        primaryTypographyProps={{
          noWrap: true,
          display: 'flex',
          alignItems: 'center',
        }}
      />
    </MenuItem>
  );
}
