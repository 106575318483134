import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';

import { useDashboardContext } from '../../_lib/context/dashboard-context';
import { ReactErrorComponent } from '../../_lib/react-error';

import { LinearProgressWithParts } from '../linear-progress/with-parts';
import { ContentSummary } from '../../tracker/content-summary';

export function AreaSummaryBarChart({
  contentSummary,
  height,
  borderRadius,
}: any) {
  const { t } = useTranslation();

  const {
    dashboard: { metaStatusColors },
  } = useDashboardContext();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [popperAnchorEl, setPopperAnchorEl] =
    React.useState<null | HTMLElement>(null);

  const [mouseOver, setMouseOver] = React.useState(false);
  const [mouseOverStatus, setMouseOverStatus] = React.useState('');

  const linearProgressParts = React.useMemo(() => {
    const newLinearProgressParts = [] as any;
    Object.keys(metaStatusColors).forEach((key: string) => {
      if (contentSummary?.[key]?.total) {
        newLinearProgressParts.push({
          value: contentSummary[key].total,
          label: t(metaStatusColors[key].text),
          color: metaStatusColors[key].bgcolor,
          onMouseEnter: (e: any) => {
            setAnchorEl(e.currentTarget);
            setPopperAnchorEl(e.currentTarget);
            setMouseOverStatus(key);
          },
          onMouseLeave: () => {
            setAnchorEl(null);
          },
        });
      }
    });
    return newLinearProgressParts;
  }, [contentSummary, metaStatusColors, t]);

  // ------------------------------------------------------------------------------------------------------------------

  if (linearProgressParts.length === 0) return null;

  // ------------------------------------------------------------------------------------------------------------------

  return (
    <>
      <LinearProgressWithParts
        sx={{ height, borderRadius }}
        values={linearProgressParts}
        disableTooltip
      />

      {/* content summary popper */}
      <Popper
        open={Boolean(anchorEl) || (Boolean(popperAnchorEl) && mouseOver)}
        anchorEl={anchorEl || popperAnchorEl}
        onClick={(event) => {
          event.stopPropagation();
        }}
        sx={{
          zIndex: 15,
        }}
      >
        <Paper
          onMouseEnter={() => {
            setMouseOver(true);
          }}
          onMouseLeave={() => {
            setMouseOver(false);
            setPopperAnchorEl(null);
          }}
          sx={{
            backgroundColor: 'white',
            width: '350px',
            maxWidth: '90vw',
            padding: 0.8,
            boxShadow: 4,
          }}
        >
          <ErrorBoundary FallbackComponent={ReactErrorComponent}>
            <ContentSummary
              content={contentSummary[mouseOverStatus]?.content || []}
              status={mouseOverStatus}
              count={contentSummary[mouseOverStatus]?.total || 0}
              totalActivities={Object.keys(contentSummary || {}).reduce(
                (prev, curr) => prev + (contentSummary[curr].total || 0),
                0
              )}
              onClose={(e: any) => {
                e.stopPropagation();
                setMouseOver(false);
              }}
            />
          </ErrorBoundary>
        </Paper>
      </Popper>
    </>
  );
}
