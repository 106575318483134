import React from 'react';
import { useTranslation } from 'react-i18next';

import { useMutation } from '@apollo/client';

import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import LoadingButton from '@mui/lab/LoadingButton';
import IconButton from '@mui/material/IconButton';
import HourglassTopTwoToneIcon from '@mui/icons-material/HourglassTopTwoTone';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';

import { useDashboardContext } from '../../../../_lib/context/dashboard-context';
import { logError } from '../../../../_lib/error';

import {
  CONTENT_BLOCKER_DELETE,
  CONTENT_BLOCKER_EDIT,
} from '../../../../_lib/graphql/mutations';
import {
  CONTENT_BLOCKERS_QUERY,
  CONTENT_QUERY,
} from '../../../../_lib/graphql/queries';

import { ContentDialogBlockersDetailText } from './text';
import { ContentDialogBlockersDetailComplete } from './complete';

export function ContentDialogBlockersDetail({ blocker, contentId }: any) {
  const { t } = useTranslation();

  const {
    setSnackbarIsError,
    setSnackbarOpen,
    dashboard: { metaStatusColors },
  } = useDashboardContext();

  const [isEdit, setIsEdit] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState('');

  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);

  const [contentStatus, setContentStatus] = React.useState('');

  const [editMutation] = useMutation(CONTENT_BLOCKER_EDIT, {
    update(cache, { data: { contentBlockerEdit } }) {
      cache.writeQuery({
        query: CONTENT_QUERY,
        data: { content: contentBlockerEdit.content },
        variables: { contentId },
      });

      cache.writeQuery({
        query: CONTENT_BLOCKERS_QUERY,
        data: { contentBlockers: contentBlockerEdit.contentBlockers },
        variables: { contentId },
      });
    },
  });

  const [deleteMutation] = useMutation(CONTENT_BLOCKER_DELETE, {
    update(cache, { data: { contentBlockerDelete } }) {
      cache.writeQuery({
        query: CONTENT_QUERY,
        data: { content: contentBlockerDelete.content },
        variables: { contentId },
      });

      cache.writeQuery({
        query: CONTENT_BLOCKERS_QUERY,
        data: { contentBlockers: contentBlockerDelete.contentBlockers },
        variables: { contentId },
      });
    },
  });

  // -------------------------------------------------------------------------------------------------
  // -------------------------------------------------------------------------------------------------
  // handlers
  // -------------------------------------------------------------------------------------------------

  const handleEdit = () => {
    setError('');
    setIsEdit(true);
  };

  const handleCancel = () => {
    setIsEdit(false);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    const data = new FormData(event.currentTarget);
    const text = data.get('text');
    editMutation({
      variables: { text, contentId, contentBlockerId: blocker.id },
    })
      .then(() => {
        setIsEdit(false);
      })
      .catch((err) => {
        setError(`${t('Something went wrong')}`);
        logError(err);
        setSnackbarIsError(true);
        setSnackbarOpen(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleDelete = () => {
    setOpenDeleteDialog(false);
    setLoading(true);
    deleteMutation({
      variables: { contentId, contentBlockerId: blocker.id },
    }).catch((err) => {
      setError(`${t('Something went wrong')}`);
      logError(err);
      setSnackbarIsError(true);
      setSnackbarOpen(true);
      setLoading(false);
    });
  };

  // -------------------------------------------------------------------------------------------------

  if (!blocker) return <div />;

  return (
    <>
      <Box sx={{ pb: 1 }}>
        {/* not edit */}
        {!isEdit && (
          <Button
            component="div"
            variant="contained"
            disableElevation
            disableRipple
            fullWidth
            startIcon={
              <Box
                sx={{
                  ml: 0.5,
                  color: loading
                    ? 'action.disabled'
                    : contentStatus
                    ? metaStatusColors[contentStatus].bgcolor
                    : blocker.isCompleted
                    ? 'success.main'
                    : 'info.main',
                }}
              >
                <HourglassTopTwoToneIcon color="inherit" fontSize="small" />
              </Box>
            }
            sx={{
              textTransform: 'none',
              justifyContent: 'flex-start',
              alignItems: 'flex-start',
              bgcolor: 'grey.200',
              color: 'grey.700',
              ':hover': {
                bgcolor: 'grey.300',
                color: 'grey.800',
                cursor: 'grab',
              },
              px: 1,
              cursor: 'grab',
            }}
            disabled={loading}
          >
            <Button
              disableRipple
              sx={{
                minWidth: 0,
                flexGrow: 1,
                color: 'grey.800',
                textTransform: 'none',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                textAlign: 'left',
                padding: '0 0 0',
                mt: '3px',
                '&:hover': {
                  bgcolor: 'transparent',
                  color: 'grey.800',
                },
              }}
              onDoubleClick={handleEdit}
              disabled={loading}
            >
              <ContentDialogBlockersDetailText
                blocker={blocker}
                setContentStatus={setContentStatus}
              />
            </Button>
            <ContentDialogBlockersDetailComplete
              contentId={contentId}
              blocker={blocker}
              loading={loading}
              setLoading={setLoading}
            />
            <IconButton
              size="small"
              sx={{ justifySelf: 'flex-end' }}
              onClick={() => setOpenDeleteDialog(true)}
              disabled={loading}
            >
              <DeleteOutlineOutlinedIcon fontSize="inherit" />
            </IconButton>
          </Button>
        )}

        {/* edit */}
        {isEdit && (
          <Button
            component="div"
            variant="contained"
            disableElevation
            disableFocusRipple
            disableRipple
            fullWidth
            startIcon={
              <Box sx={{ ml: 0.5 }}>
                <HourglassTopTwoToneIcon color="disabled" fontSize="small" />
              </Box>
            }
            sx={{
              textTransform: 'none',
              justifyContent: 'flex-start',
              alignItems: 'flex-start',
              cursor: 'default',
              bgcolor: 'grey.300',
              color: 'grey.700',
              ':hover': {
                bgcolor: 'grey.300',
                cursor: 'default',
              },
              px: 1,
            }}
          >
            <Box component="form" onSubmit={handleSubmit} sx={{ flexGrow: 1 }}>
              <Box sx={{ mb: 1 }}>
                <TextField
                  name="text"
                  size="small"
                  fullWidth
                  defaultValue={blocker.text}
                  sx={{
                    '& legend': {
                      width: 0,
                    },
                    '.MuiInputBase-root': {
                      backgroundColor: 'white',
                    },
                  }}
                  required
                  error={!!error}
                  helperText={!!error && error}
                  disabled={loading}
                  multiline
                />
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'end' }}>
                <Button
                  size="small"
                  onClick={handleCancel}
                  sx={{ textTransform: 'none', padding: '2px 6px' }}
                  disabled={loading}
                >
                  {t('Cancel')}
                </Button>
                <LoadingButton
                  size="small"
                  variant="contained"
                  disableElevation
                  sx={{ ml: 1, textTransform: 'none', padding: '2px 6px' }}
                  type="submit"
                  loading={loading}
                >
                  {t('Save')}
                </LoadingButton>
              </Box>
            </Box>
          </Button>
        )}
      </Box>

      {/* confirm delete dialog */}
      <Dialog
        open={openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle id="alert-dialog-title">
          {t('Delete blocker?')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t('Are you sure you want to delete the blocker:')}
            <br />
            {`"${blocker.text}"`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDeleteDialog(false)}>
            {t('Cancel')}
          </Button>
          <Button onClick={handleDelete} variant="contained" disableElevation>
            {t('Confirm')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
