import React from 'react';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

import { DialogTitle } from '../../../DialogTitle/dialog-title';

import { hexToRGB } from '../../../_utils/general-utils';
import { DashboardContext } from '../../../_lib/context/dashboard-context';
import { ContentDialogContext } from '../../../_lib/context/content-dialog-context';

import { ContentDialogTitleGroup } from './group';

export function ContentDialogTitle({ classes, handleCloseDialog }: any) {
  const { t } = useTranslation();

  const {
    dashboard: {
      mode: dashboardMode,
      enableActivityGroup,
      priorityAreaName,
      interventionName,
      metaStatusColors,
    },
  } = React.useContext(DashboardContext);
  const { content } = React.useContext(ContentDialogContext);

  const issueBgColor = hexToRGB(metaStatusColors.issue.bgcolor, 0.1);

  return (
    <DialogTitle
      className={classes.leftPadding}
      onClose={() => handleCloseDialog()}
      variant="h5"
      sx={{
        backgroundColor:
          (content?.type === 'milestone' && 'rgba(170, 170, 255, 0.2)') ||
          (content?.type === 'issue' && issueBgColor) ||
          'white',
        flexDirection: 'column',
        alignItems: 'flex-start',
      }}
    >
      <Box
        component="span"
        sx={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          display: '-webkit-box',
          lineClamp: 2,
          WebkitLineClamp: 2,
          WebkitBoxOrient: 'vertical',
          '&:hover': {
            textOverflow: 'clip',
            WebkitLineClamp: 'unset',
          },
        }}
      >
        {content?.title || '-'}
      </Box>

      {/* content group/phase */}
      {enableActivityGroup && (
        <Typography variant="body2" color="text.secondary">
          <small>{t('Phase')}:</small>{' '}
          {content?.group ? (
            <ContentDialogTitleGroup id={content?.group?.id} />
          ) : (
            '-'
          )}
        </Typography>
      )}

      {/* priority area + intervention */}
      {!!content?.intervention && (
        <Typography variant="body1" sx={{ mt: 1 }}>
          {dashboardMode === 'TRACKER' && (
            <>
              <small>{priorityAreaName}:</small>{' '}
              {content?.intervention?.priorityArea?.name}
              <Box component="span" color="text.secondary" sx={{ mx: 1 }}>
                {' // '}
              </Box>
            </>
          )}
          <small>{interventionName}:</small>{' '}
          {dashboardMode === 'TRACKER' ? (
            content?.intervention?.name
          ) : (
            <Link href={content?.intervention?.urlRelative}>
              {content?.intervention?.reference}. {content?.intervention?.name}
            </Link>
          )}
        </Typography>
      )}
    </DialogTitle>
  );
}
