import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import TbiLogo from '../../../../assets/img/tbilogo/dashboard.jpg';

export function DashboardDetailsFormLogo({
  disabled,
  newDashboard,
  handleLogoChange,
}: any) {
  const { t } = useTranslation();

  return (
    <Box>
      <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
        <Box
          sx={{
            border: 1,
            borderColor: 'grey.400',
            borderRadius: 1,
            p: 1,
            position: 'relative',
          }}
        >
          <Box
            sx={{
              p: 1,
              bgcolor: 'primary.main',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {!!newDashboard.logo && (
              <img
                style={{ width: 70 }}
                alt="Logo"
                src={
                  newDashboard.logo.includes('/static/images/tbilogo.jpg') &&
                  process.env.REACT_APP_IS_V3
                    ? TbiLogo
                    : newDashboard.logo
                }
                onError={(e: any) => {
                  if (e.target.src !== TbiLogo) {
                    e.target.src = TbiLogo;
                  }
                }}
              />
            )}
          </Box>
          <Typography
            component="span"
            variant="caption"
            color={disabled ? 'text.disabled' : 'text.secondary'}
            sx={{
              px: 0.8,
              mb: 1,
              position: 'absolute',
              bgcolor: 'white',
              left: 0,
              top: '-10px',
              whiteSpace: 'nowrap',
              insetInlineStart: '8px',
            }}
          >
            {t('Dashboard Icon')}
          </Typography>
        </Box>

        {!disabled && (
          <Box sx={{ ml: 2, pt: 1 }}>
            <Box sx={{ mb: 2 }}>
              <input
                id="logo-upload-input"
                type="file"
                onChange={(event: any) =>
                  handleLogoChange(event.target.files[0])
                }
                accept="image/png,image/jpeg"
                style={{ fontSize: '90%', width: '100%' }}
                aria-label={`${t('Upload logo')}`}
              />
            </Box>
            <Button
              variant="contained"
              disableElevation
              size="small"
              onClick={() => handleLogoChange(TbiLogo)}
            >
              {t('Remove')}
            </Button>
          </Box>
        )}
      </Box>
    </Box>
  );
}
