import React from 'react';
import { useTranslation } from 'react-i18next';

import IconButton from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import MenuList from '@mui/material/MenuList';
import ListSubheader from '@mui/material/ListSubheader';
import Switch from '@mui/material/Switch';

export function AreasFilterMenu({ filterCharts, setFilterCharts }: any) {
  const { t } = useTranslation();

  // --------------------------------------------------------------------------------------------------

  // menu

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // --------------------------------------------------------------------------------------------------

  const handleToggle = (type: string) => {
    const currentIndex = filterCharts.indexOf(type);
    const newChecked = [...filterCharts];

    if (currentIndex === -1) {
      newChecked.push(type);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setFilterCharts(newChecked);
  };

  // --------------------------------------------------------------------------------------------------

  return (
    <>
      <IconButton
        aria-controls={open ? 'areas-filter-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        sx={{
          color: filterCharts.length > 0 ? 'primary.main' : 'text.secondary',
        }}
      >
        <MoreVertIcon color="inherit" />
      </IconButton>
      <Menu
        id="areas-filter-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuList
          subheader={
            <ListSubheader component="div">
              {t('Include in charts?')}
            </ListSubheader>
          }
        >
          <MenuItem onClick={() => handleToggle('unstarted')}>
            <Switch
              edge="start"
              onChange={() => handleToggle('unstarted')}
              checked={filterCharts.indexOf('unstarted') !== -1}
            />
            {t('Unstarted')}
          </MenuItem>
        </MenuList>
      </Menu>
    </>
  );
}
