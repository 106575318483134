import React from 'react';

import { useDashboardContext } from '../../../context/dashboard-context';

import { PRIORITY_AREAS_HOME_QUERY } from '../../queries';

import { usePrefetchQuery } from '../use-prefetch-query';
import { GraphqlPrefetchQueriesArea } from './area';

export function GraphqlPrefetchQueriesHomepage() {
  const { navbarFilterVariables } = useDashboardContext();

  const { data } = usePrefetchQuery({
    query: PRIORITY_AREAS_HOME_QUERY,
    variables: navbarFilterVariables,
  });

  return (
    <>
      {/* todo: summary card */}

      {/* todo: single priority area + children + interventions */}
      {data?.priorityAreas?.items?.map((area: any) => (
        <GraphqlPrefetchQueriesArea
          key={`priority-area-prefetch-${area.id}`}
          area={area}
        />
      ))}
    </>
  );
}
