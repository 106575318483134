import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import LoadingButton from '@mui/lab/LoadingButton';

import {
  AREA_FIELD_CONFIG_CREATE,
  AREA_FIELD_CONFIG_DELETE,
  AREA_FIELD_CONFIG_UPDATE,
  DASHBOARD_UPDATE,
} from '../../../../../_lib/graphql/mutations';
import { CONTEXT_DASHBOARD_QUERY } from '../../../../../_lib/graphql/queries';

import { logError } from '../../../../../_lib/error';
import { DashboardContext } from '../../../../../_lib/context/dashboard-context';

import { AdvancedAreaFieldsDetailMenu } from './menu';
import { AdvancedAreaFieldsDetailTypeSelect } from './type-select';
import { AdvancedAreaFieldsDetailInAreaCheckbox } from './in-area-checkbox';
import { AdvancedAreaFieldsDetailRequiredCheckbox } from './required-checkbox';
import { AdvancedAreaFieldsDetailManageSelectOptions } from './manage-select-options';

export function AdvancedAreaFieldsDetail({
  config,
  configs,
  setConfigs,
  isNew,
}: any) {
  const { t } = useTranslation();
  const { dashboard, setSnackbarOpen, setSnackbarIsError } =
    React.useContext(DashboardContext);

  const fieldTypes = React.useMemo(
    () =>
      ({
        TEXT: t('Free Text'),
        NUMBER: t('Number'),
        DATE: t('Date'),
        SELECT: t('Select'),
        MULTI_SELECT: t('Multi-Select'),
      } as any),
    [t]
  );

  // -------------------------------------------------------------------------------------------------------

  const [createConfigMutation] = useMutation(AREA_FIELD_CONFIG_CREATE);

  const [updateConfigMutation] = useMutation(AREA_FIELD_CONFIG_UPDATE, {
    update(cache, { data: { areaFieldConfigUpdate } }) {
      cache.writeQuery({
        query: CONTEXT_DASHBOARD_QUERY,
        data: {
          dashboard: areaFieldConfigUpdate.dashboard,
        },
      });
    },
  });

  const [deleteConfigMutation] = useMutation(AREA_FIELD_CONFIG_DELETE, {
    update(cache, { data: { areaFieldConfigDelete } }) {
      cache.writeQuery({
        query: CONTEXT_DASHBOARD_QUERY,
        data: {
          dashboard: areaFieldConfigDelete.dashboard,
        },
      });
    },
  });

  const [updateDashboard] = useMutation(DASHBOARD_UPDATE, {
    update(cache, { data: { dashboardUpdate } }) {
      cache.writeQuery({
        query: CONTEXT_DASHBOARD_QUERY,
        data: {
          dashboard: dashboardUpdate.dashboard,
        },
      });
    },
  });

  const [loading, setLoading] = React.useState(false);
  const [configEdit, setConfigEdit] = React.useState<any>(config);

  // -------------------------------------------------------------------------------------------------------
  // -------------------------------------------------------------------------------------------------------
  // handlers
  // -------------------------------------------------------------------------------------------------------

  // remove new config
  const removeCurrentConfig = () => {
    const newConfigs = { ...configs };
    delete newConfigs[config.key];
    setConfigs(newConfigs);
  };

  // cancel
  const handleCancel = () => {
    setConfigEdit(config);
    if (!isNew) {
      setConfigs({
        ...configs,
        [config.key]: {
          ...config,
          inEdit: false,
        },
      });
    } else {
      removeCurrentConfig();
    }
  };

  // save
  const handleSave = () => {
    setLoading(true);
    const newConfig = { ...configEdit };
    delete newConfig.key;
    delete newConfig.inEdit;
    delete newConfig.__typename;

    if (isNew) {
      // create
      createConfigMutation({
        variables: {
          input: {
            ...newConfig,
          },
        },
      })
        .then((res) => {
          // update dashboard
          return updateDashboard({
            variables: {
              [`${config.key}Id`]:
                res.data.areaFieldConfigCreate.areaFieldConfig.id,
            },
          }).then(() => {
            setSnackbarOpen(true);
            setLoading(false);
            removeCurrentConfig();
          });
        })
        .catch((err) => {
          logError(err);
          setSnackbarIsError(true);
          setSnackbarOpen(true);
          setLoading(false);
        });
    } else {
      // edit
      updateConfigMutation({
        variables: {
          input: {
            id: config.id,
            ...newConfig,
          },
        },
      })
        .then(() => {
          setSnackbarOpen(true);
          setLoading(false);
          setConfigs({
            ...configs,
            [config.key]: {
              ...configEdit,
              inEdit: false,
            },
          });
        })
        .catch((err) => {
          logError(err);
          setSnackbarIsError(true);
          setSnackbarOpen(true);
          setLoading(false);
        });
    }
  };

  // delete
  const handleDelete = () => {
    setLoading(true);
    deleteConfigMutation({
      variables: {
        id: config.id,
      },
    }).catch((err) => {
      logError(err);
      setSnackbarIsError(true);
      setSnackbarOpen(true);
      setLoading(false);
    });
  };
  // -------------------------------------------------------------------------------------------------------

  return (
    <Card variant="outlined">
      <Box sx={{ py: 1, px: 1.5 }}>
        {!config.inEdit ? (
          <Grid container>
            <Grid item xs={10}>
              <Typography
                variant="body1"
                onClick={() => {
                  setConfigs({
                    ...configs,
                    [config.key]: {
                      ...config,
                      inEdit: true,
                    },
                  });
                }}
              >
                {config.label}
                <Typography variant="body2" component="span">
                  {' - '}
                  {fieldTypes[config.fieldType]}
                </Typography>
                {config.required && (
                  <Typography variant="body2" component="span">
                    {' '}
                    ({t('required')})
                  </Typography>
                )}
              </Typography>

              {/* area */}
              <FormGroup sx={{ flexDirection: 'row' }}>
                <FormControlLabel
                  disabled
                  control={
                    <Checkbox checked={config.inPriorityArea} size="small" />
                  }
                  label={dashboard.priorityAreaName}
                  componentsProps={{
                    typography: {
                      variant: 'body2',
                    },
                  }}
                />
                <FormControlLabel
                  disabled
                  control={
                    <Checkbox checked={config.inIntervention} size="small" />
                  }
                  label={dashboard.interventionName}
                  componentsProps={{
                    typography: {
                      variant: 'body2',
                    },
                  }}
                />
              </FormGroup>

              {/* manage options for select types */}
              {(config.fieldType === 'SELECT' ||
                config.fieldType === 'MULTI_SELECT') && (
                <AdvancedAreaFieldsDetailManageSelectOptions config={config} />
              )}
            </Grid>
            <Grid
              item
              xs={2}
              sx={{ display: 'flex', justifyContent: 'flex-start' }}
            >
              {/* menu to manage options and delete */}
              <Box>
                <AdvancedAreaFieldsDetailMenu
                  config={config}
                  configs={configs}
                  setConfigs={setConfigs}
                  handleDelete={handleDelete}
                  loading={loading}
                />
              </Box>
            </Grid>
          </Grid>
        ) : (
          <Stack spacing={1}>
            {/* label */}
            <TextField
              label={t('Label')}
              value={configEdit.label}
              fullWidth
              size="small"
              required
              onChange={(e) => {
                setConfigEdit({
                  ...configEdit,
                  label: e.target.value,
                });
              }}
              disabled={loading}
            />

            {/* select for field type  */}
            <AdvancedAreaFieldsDetailTypeSelect
              configEdit={configEdit}
              setConfigEdit={setConfigEdit}
              disabled={loading}
              fieldTypes={fieldTypes}
            />

            {/* show in_pa and in_intv check box */}
            <AdvancedAreaFieldsDetailInAreaCheckbox
              configEdit={configEdit}
              setConfigEdit={setConfigEdit}
              disabled={loading}
            />

            {/* required */}
            {/* <AdvancedAreaFieldsDetailRequiredCheckbox
              configEdit={configEdit}
              setConfigEdit={setConfigEdit}
              disabled={loading}
            /> */}

            <Box sx={{ textAlign: 'right' }}>
              <Button
                size="small"
                sx={{ mr: 1 }}
                onClick={handleCancel}
                disabled={loading}
              >
                {t('Cancel')}
              </Button>
              <LoadingButton
                size="small"
                variant="contained"
                disableElevation
                onClick={handleSave}
                loading={loading}
              >
                {t('Save')}
              </LoadingButton>
            </Box>
          </Stack>
        )}
      </Box>
    </Card>
  );
}
