import { Typography } from '@mui/material';

export function ContentDialogSectionTitle({
  main,
  display,
  children,
  sx,
}: any) {
  return (
    <Typography
      variant={main ? 'h6' : 'body2'}
      color={main ? 'textPrimary' : 'textSecondary'}
      style={{ textTransform: main ? 'capitalize' : 'uppercase' }}
      gutterBottom
      display={display}
      sx={{ ...sx }}
    >
      {children}
    </Typography>
  );
}
