import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Divider, Grid, Stack, TextField } from '@mui/material';

import { DashboardDetailsFormLogo } from './logo';
import { DashboardDetailsFormName } from './name';
import { DashboardFormDetailsCountry } from './country';

import { usePlanningContext } from '../../../_lib/context/planning-context';
import { useDashboardContext } from '../../../_lib/context/dashboard-context';

import { SetupDashboard } from '../../../actions/setup-dashboard';
import { DashboardFormDetailsPermissions } from './activities/permissions';
import { DashboardFormDetailsActivitiesArchive } from './activities/archive';
import { TabPanel } from '../tab-panel';
import { DashboardFormDetailsActionsDelete } from './actions/delete';

import TbiLogo from '../../../../assets/img/tbilogo/dashboard.jpg';

import { AdvancedAreaLevels } from '../advanced/area-levels';
import { DashboardFormDetailsLanguage } from './languages';

export function SettingsDetails({ tabVal, tabInd }: any) {
  const { t } = useTranslation();

  const { dashboard, user } = useDashboardContext();
  const { inPlanningEdit, setInPlanningEdit } = usePlanningContext();

  const [disabled, setDisabled] = React.useState(true);
  const [loading, setLoading] = React.useState(false);
  const [errors, setErrors] = React.useState<any>({});

  const [newDashboard, setNewDashboard] = React.useState<any>(
    structuredClone(dashboard)
  );
  const [logo, setLogo] = React.useState(dashboard.logo);

  // ----------------------------------------------------------------------------------------------------
  // ----------------------------------------------------------------------------------------------------
  // handlers
  // ----------------------------------------------------------------------------------------------------

  const handleLogoChange = (file: any) => {
    if (file === TbiLogo) {
      setNewDashboard({
        ...newDashboard,
        logo: file,
      });
    } else if (file) {
      setNewDashboard({
        ...newDashboard,
        logo: URL.createObjectURL(file),
      });
    }

    if (file) {
      setLogo(file);
    } else {
      setNewDashboard({
        ...newDashboard,
        logo: dashboard.logo,
      });
      setLogo(dashboard.logo);
    }
  };

  // validation

  const valid = () => {
    const newErrors = {} as any;

    if (!newDashboard.name.trim()) {
      newErrors.name = t('You must include a name for the dashboard');
    }

    if (!newDashboard.country || !newDashboard.country.trim()) {
      newErrors.country = t('You must include a country for the dashboard');
    }
    if (!newDashboard.currency || !newDashboard.currency.trim()) {
      newErrors.currency = t('You must select a currency for the dashboard');
    }

    if (!dashboard.enableAreaLevels) {
      if (!newDashboard.priorityAreaName.trim()) {
        newErrors.priorityAreaName = t(
          'Please include a valid name for the main area'
        );
      }
    }
    if (!newDashboard.interventionName.trim()) {
      newErrors.interventionName = t(
        'Please include a valid name for the sub area'
      );
    }

    setErrors({ ...newErrors });
    return Object.keys(newErrors).length === 0;
  };

  // ----------------------------------------------------------------------------------------------------
  // ----------------------------------------------------------------------------------------------------
  // effects
  // ----------------------------------------------------------------------------------------------------

  // For inPlanning Changes
  React.useEffect(() => {
    setDisabled(!inPlanningEdit);
  }, [inPlanningEdit]);

  // dashboard
  React.useEffect(() => {
    setNewDashboard(structuredClone(dashboard));
  }, [dashboard]);

  // ----------------------------------------------------------------------------------------------------

  return (
    <TabPanel
      value={tabVal}
      index={tabInd}
      title={`${t('Overview')}`}
      actions={
        <SetupDashboard
          valid={valid}
          logo={logo}
          newDashboard={newDashboard}
          setNewDashboard={setNewDashboard}
          disabled={disabled}
          setDisabled={(d: boolean) => setInPlanningEdit(!d)}
          loading={loading}
          setLoading={setLoading}
        />
      }
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          {/* main Settings */}
          <Stack spacing={4}>
            {/* dashboard Logo */}
            <DashboardDetailsFormLogo
              disabled={disabled || loading}
              newDashboard={newDashboard}
              handleLogoChange={handleLogoChange}
            />

            {/* dashboard name */}
            <DashboardDetailsFormName
              disabled={disabled || loading}
              errors={errors}
              newDashboard={newDashboard}
              setNewDashboard={setNewDashboard}
            />

            {/* country */}
            <DashboardFormDetailsCountry
              disabled={disabled || loading}
              newDashboard={newDashboard}
              setNewDashboard={setNewDashboard}
              errors={errors}
            />

            {/* language */}
            <DashboardFormDetailsLanguage
              disabled={disabled || loading}
              newDashboard={newDashboard}
              setNewDashboard={setNewDashboard}
            />

            {/* Purpose / Goal */}
            <TextField
              id="goal-input"
              label={t('Purpose / goal')}
              placeholder={`${t(
                'e.g. To mitigate the long terms effects of covid on the economy'
              )}`}
              multiline
              minRows={3}
              value={newDashboard.goal || ''}
              onChange={(e) =>
                setNewDashboard({
                  ...newDashboard,
                  goal: e.target.value,
                })
              }
              disabled={disabled || loading}
              error={!!errors.goal}
              helperText={errors.goal}
            />
          </Stack>
        </Grid>

        <Grid
          item
          xs={12}
          sx={{
            display: { xs: 'block', sm: 'none' },
          }}
        >
          <Divider />
        </Grid>

        {/* Other settings */}
        <Grid item xs={12} sm={6}>
          <Stack spacing={3}>
            {/* Name of main areas */}
            {!dashboard.enableAreaLevels ? (
              <TextField
                id="priority-area-name-input"
                label={t('Name for main areas')}
                placeholder={`${t(
                  'e.g. Priority Area, Flagship Project, Pillar, Team'
                )}`}
                value={newDashboard.priorityAreaName || ''}
                onChange={(e) =>
                  setNewDashboard({
                    ...newDashboard,
                    priorityAreaName: e.target.value,
                  })
                }
                disabled={disabled || loading}
                error={!!errors.priorityAreaName}
                helperText={
                  errors.priorityAreaName ||
                  `${t('e.g. Priority Area, Flagship Project, Pillar, Team')}`
                }
              />
            ) : (
              <AdvancedAreaLevels
                fullWidth
                size="large"
                disabled={disabled || loading}
              />
            )}

            {/* name of sub-areas */}
            <TextField
              id="intervention-name-input"
              label={t('Name for sub areas')}
              placeholder={`${t(
                'e.g. Intervention, Project, Strategic Activity'
              )}`}
              value={newDashboard.interventionName || ''}
              onChange={(e) =>
                setNewDashboard({
                  ...newDashboard,
                  interventionName: e.target.value,
                })
              }
              disabled={disabled || loading}
              error={!!errors.interventionName}
              helperText={
                errors.interventionName ||
                `${t('e.g. Intervention, Project, Strategic Activity')}`
              }
            />

            {!dashboard.inPlanning && (
              <>
                <Divider />

                <DashboardFormDetailsPermissions
                  disabled={disabled || loading}
                  newDashboard={newDashboard}
                  setNewDashboard={setNewDashboard}
                />

                <DashboardFormDetailsActivitiesArchive
                  disabled={disabled || loading}
                  newDashboard={newDashboard}
                  setNewDashboard={setNewDashboard}
                />
              </>
            )}
          </Stack>
        </Grid>
      </Grid>

      {/* other actions */}
      {user.canCreateDashboards && (
        <Box sx={{ mt: 8, mb: 3 }}>
          <DashboardFormDetailsActionsDelete
            loading={loading}
            disabled={disabled}
          />
        </Box>
      )}
    </TabPanel>
  );
}
