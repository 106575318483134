import { useTranslation } from 'react-i18next';
import { Route, Switch } from 'react-router-dom';

import { PlanningLoader } from '../../planning/loader';
import { TrackerLandingLoader } from './tracker-landing-loader';

export function ActivityTrackerRouter() {
  const { t } = useTranslation();

  return (
    <Switch>
      <Route exact path="/" component={TrackerLandingLoader} />

      {/* settings */}
      <Route path={t('/settings')} component={PlanningLoader} />

      {/* planning */}
      <Route path={t('/planning')} component={PlanningLoader} />

      {/* analytics */}
      <Route path={t('/analytics')} component={PlanningLoader} />
    </Switch>
  );
}
