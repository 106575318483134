import React from 'react';
import { useTranslation } from 'react-i18next';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import Paper from '@mui/material/Paper';

import { useDashboardContext } from '../../_lib/context/dashboard-context';

import { TargetsSummaryTableRow } from './table-row';

export function TargetsSummaryTable({
  targetsSummary,
  area,
  includePriorityAreas,
  rowsPerPage,
  setRowsPerPage,
  page,
  setPage,
  sx = {},
}: any) {
  const { t } = useTranslation();

  const { dashboard } = useDashboardContext();

  const tableHeadBgColor = 'grey.200';

  return (
    <TableContainer
      component={Paper}
      variant="outlined"
      sx={{ maxHeight: 300, ...sx }}
    >
      <Table
        stickyHeader
        size="small"
        sx={{ minWidth: 650 }}
        aria-label="targets table"
      >
        <TableHead>
          <TableRow>
            <TableCell sx={{ bgcolor: tableHeadBgColor }}>
              {t('Indicator')}
            </TableCell>
            {dashboard.enableTargetBaselineValue ? (
              <TableCell
                align="left"
                title={`${t(
                  'This is the starting value during project launch'
                )}`}
                sx={{ bgcolor: tableHeadBgColor }}
              >
                {t('Baseline Value')}
              </TableCell>
            ) : null}
            <TableCell align="left" sx={{ bgcolor: tableHeadBgColor }}>
              {t('Target Value')}
            </TableCell>
            {dashboard.enableTargetFinancedAmount && (
              <TableCell align="left" sx={{ bgcolor: tableHeadBgColor }}>
                {t('Financed Value')}
              </TableCell>
            )}
            <TableCell align="left" sx={{ bgcolor: tableHeadBgColor }}>
              {t('Latest Value')}
            </TableCell>
            <TableCell align="left" sx={{ bgcolor: tableHeadBgColor }}>
              {t('Progress')}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {targetsSummary?.targetsByUnit?.items?.map((tgt: any) => (
            <TargetsSummaryTableRow
              key={tgt.id}
              target={tgt}
              area={area}
              includePriorityAreas={includePriorityAreas}
            />
          ))}
        </TableBody>

        {/* pagination */}
        <TableFooter>
          <TableRow>
            <TablePagination
              count={targetsSummary.targetsByUnit.meta.count}
              page={page}
              rowsPerPage={rowsPerPage}
              rowsPerPageOptions={[3, 5, 10]}
              onPageChange={(event, pg) => {
                event?.stopPropagation();
                setPage(pg);
              }}
              onRowsPerPageChange={(event) => {
                event?.stopPropagation();
                setRowsPerPage(parseInt(event.target.value, 10));
                setPage(0);
              }}
              SelectProps={{
                onClick: (event) => {
                  event?.stopPropagation();
                },
              }}
              labelRowsPerPage={t('Rows per page')}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
}
