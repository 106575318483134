import React from 'react';
import { useTranslation } from 'react-i18next';

import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';

import { TargetUpdateTargetDialog } from './dialog';

export function TargetUpdateTarget({ target }: any) {
  const { t } = useTranslation();

  const [confirmDialogOpen, setConfirmDialogOpen] = React.useState(false);

  return (
    <>
      <TargetUpdateTargetDialog
        target={target}
        open={confirmDialogOpen}
        setOpen={setConfirmDialogOpen}
      />

      <Button
        id="update-target"
        variant="outlined"
        onClick={() => setConfirmDialogOpen(true)}
        onKeyDown={() => setConfirmDialogOpen(true)}
        size="small"
        startIcon={<AddIcon />}
      >
        {t('Update')}
      </Button>
    </>
  );
}
