import { ExtendedFeatureCollection, geoMercator, geoPath } from 'd3-geo';

interface ScaleAndProjection {
  scale: number;
  translate: number[];
}

export function getScaleAndProjection(
  features: ExtendedFeatureCollection,
  width: number,
  height: number
): ScaleAndProjection {
  // Create a unit projection.
  const projection = geoMercator().scale(1).translate([0, 0]);
  // Create a path generator.
  const path = geoPath().projection(projection);
  // Compute the bounds of a feature of interest, then derive scale & translate.
  const b = path.bounds(features);

  const scale =
    0.95 / Math.max((b[1][0] - b[0][0]) / width, (b[1][1] - b[0][1]) / height);

  // XXX we need to multiply by the width and height because nivo multiply this translation: https://github.com/plouc/nivo/blob/master/packages/geo/src/hooks.js#L57
  const translate: number[] = [
    (width - scale * (b[1][0] + b[0][0])) / 2 / width,
    (height - scale * (b[1][1] + b[0][1])) / 2 / height,
  ];

  // Update the projection to use computed scale & translate.
  return { scale, translate };
}

export function hexToRGBA(hex: string, alpha: number): string {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  if (alpha) {
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  }
  return `rgb(${r}, ${g}, ${b})`;
}
