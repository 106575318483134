import { gql, useMutation } from '@apollo/client';
import { logError } from '../../_lib/error';
import { useDashboardContext } from '../../_lib/context/dashboard-context';

const UNDO_DELETE_MUTATION = gql`
  mutation UndoDeleteMuation($objectName: String!, $objectId: ID!) {
    undoObjectDelete(objectName: $objectName, objectId: $objectId) {
      success
      message
    }
  }
`;

export function useUndoDelete() {
  const {
    recentlyDeletedProps,
    setRecentlyDeletedProps,
    setSnackbarIsDelete,
    setSnackbarOpen,
  } = useDashboardContext();
  const [undoDeleteMutation, { loading }] = useMutation(UNDO_DELETE_MUTATION);

  const undo = () => {
    if (!recentlyDeletedProps || !recentlyDeletedProps.objectId) {
      return;
    }

    undoDeleteMutation({ variables: { ...recentlyDeletedProps } })
      .then(() => {
        if (recentlyDeletedProps.successFunc) {
          recentlyDeletedProps.successFunc();
        }
      })
      .catch((e) => logError(e))
      .finally(() => {
        setSnackbarIsDelete(false);
        setRecentlyDeletedProps(null);
        setSnackbarOpen(true);
      });
  };

  return { undo, loading };
}
