import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, gql } from '@apollo/client';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';
import FlagIcon from '@mui/icons-material/Flag';
import Tooltip from '@mui/material/Tooltip';

import { logError } from '../_lib/error';

const CONTENT_TOGGLE_FLAGGED = gql`
  mutation ContentToggleFlagged($id: ID!, $flagged: Boolean) {
    contentUpdate(input: { id: $id, flagged: $flagged }) {
      content {
        id
        access
        status
        flagged
        hasNewMentions
        hasNewUpdates
      }
    }
  }
`;

export function ToggleFlag({ content, C_QUERY }: any) {
  const { t } = useTranslation();

  const [toggleFlagActivityMutation, { loading }] = useMutation(
    CONTENT_TOGGLE_FLAGGED,
    {
      update: (cache, { data }) => {
        const { id, access, status, flagged, hasNewMentions, hasNewUpdates } =
          data.contentUpdate.content;
        cache.writeQuery({
          query: C_QUERY,
          data: {
            content: {
              id,
              access,
              status,
              flagged,
              hasNewMentions,
              hasNewUpdates,
            },
          },
          variables: { id: content.id },
        });
      },
    }
  );

  const toggleFlag = (c: any, e: any) => {
    e.stopPropagation();
    if (c?.access === 'EDIT') {
      toggleFlagActivityMutation({
        variables: {
          id: c?.id,
          flagged: !c?.flagged,
        },
      }).catch((err) => logError(err));
    }
  };

  return loading ? (
    <Box p={1}>
      <CircularProgress size={24} />
    </Box>
  ) : (
    <Tooltip title={t('Flag') as string} arrow>
      <IconButton onClick={(e) => toggleFlag(content, e)}>
        <FlagIcon color={content?.flagged ? 'error' : 'disabled'} />
      </IconButton>
    </Tooltip>
  );
}
