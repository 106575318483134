import React from 'react';

import { useTranslation } from 'react-i18next';

import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';

import { ReportActionsShareDialog } from './dialog';

export function ReportActionShare({ report, disabled, isMoreMenuItem }: any) {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);

  // -----------------------------------------------------------------------------------------------------------
  // -----------------------------------------------------------------------------------------------------------
  // handlers
  // -----------------------------------------------------------------------------------------------------------

  const handleOpen = React.useCallback(() => {
    setOpen(true);
  }, []);

  // -----------------------------------------------------------------------------------------------------------

  return (
    <>
      {/* dialog */}
      <ReportActionsShareDialog report={report} open={open} setOpen={setOpen} />

      {/* button */}
      {!isMoreMenuItem ? (
        <Button
          variant="contained"
          color="primary"
          onClick={handleOpen}
          disableElevation
          disabled={disabled}
        >
          {t('Share')}
        </Button>
      ) : (
        <MenuItem onClick={handleOpen}>
          <ListItemIcon>
            <ShareOutlinedIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText> {t('Share')}</ListItemText>
        </MenuItem>
      )}
    </>
  );
}
