import React from 'react';
import { useTranslation } from 'react-i18next';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';

import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';

import { useDashboardContext } from '../../../../_lib/context/dashboard-context';
import { useReportsContext } from '../../../../_lib/context/reports-context';

export function ReportsEditFiltersAreasMenu({ setChecked, checkAll }: any) {
  const { t } = useTranslation();

  const { dashboard } = useDashboardContext();
  const { reportData } = useReportsContext();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  // -------------------------------------------------------------------------------------------------------------

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // -------------------------------------------------------------------------------------------------------------

  const deselectAll = React.useCallback(() => {
    setChecked([]);
    handleClose();
  }, [setChecked]);

  const deselectIntervention = React.useCallback(() => {
    setChecked(reportData?.priorityAreas?.map((pa: any) => `pa-${pa.id}`));
    handleClose();
  }, [reportData?.priorityAreas, setChecked]);

  const selectAll = React.useCallback(() => {
    checkAll();
    handleClose();
  }, [checkAll]);

  // -------------------------------------------------------------------------------------------------------------

  return (
    <div>
      <IconButton
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        size="small"
      >
        <MoreVertOutlinedIcon fontSize="inherit" />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={deselectAll}>
          {t('Deselect')}: {t('All')}
        </MenuItem>
        <MenuItem onClick={deselectIntervention}>
          {t('Deselect')}: {dashboard.interventionName}
        </MenuItem>
        <MenuItem onClick={selectAll}>
          {t('Select')}: {t('All')}
        </MenuItem>
      </Menu>
    </div>
  );
}
