import React from 'react';
import { useLocation, Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

import HomeRoundedIcon from '@mui/icons-material/HomeRounded';

import { BreadcrumbLayout } from './_layout';

export function ActivityTrackerBreadcrumb() {
  const { t } = useTranslation();

  const location = useLocation();

  const links = React.useMemo(() => {
    const newLinks = [
      {
        label: t('Home'),
        to: '/',
        icon: <HomeRoundedIcon fontSize="inherit" />,
      },
    ] as any;

    // tracker
    if (location.pathname.startsWith(t('/tracker'))) {
      newLinks.push({
        label: `${t('Tracker')}`,
        to: `${t('/tracker')}`,
      });
    }

    return newLinks;
  }, [location.pathname, t]);

  return (
    <BreadcrumbLayout id="activity-tracker-breadcrumb">
      {links.map((link: any, i: number) =>
        i !== links.length - 1 ? (
          <Link
            key={link.to}
            component={RouterLink}
            color="inherit"
            to={link.to}
            sx={{
              display: 'flex',
              alignItems: 'center',
              textDecoration: 'none',
              columnGap: 1,
              '&:hover': {
                opacity: 0.6,
              },
            }}
          >
            {link.icon}
            {link.label}
          </Link>
        ) : (
          <Typography
            key={link.to}
            color="text.primary"
            sx={{
              display: 'flex',
              alignItems: 'center',
              columnGap: 1,
              '&:hover': {
                opacity: 0.6,
              },
            }}
          >
            {link.icon}
            {link.label}
          </Typography>
        )
      )}
    </BreadcrumbLayout>
  );
}
