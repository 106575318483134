import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { MyAccountPreferencesDetailNotificationsOption } from './option';

export function MyAccountPreferencesDetailNotificationsList({
  setting,
  title,
  options,
}: any) {
  return (
    <Box>
      <Stack spacing={1}>
        <Typography
          variant="caption"
          component="div"
          sx={{
            fontWeight: 'bold',
            textTransform: 'uppercase',
          }}
        >
          {title}
        </Typography>
        <Stack spacing={0}>
          {options.map((option: any) => (
            <MyAccountPreferencesDetailNotificationsOption
              key={option}
              setting={setting}
              option={option}
            />
          ))}
        </Stack>
      </Stack>
    </Box>
  );
}
