import React from 'react';
import { useTranslation } from 'react-i18next';

import { useMutation } from '@apollo/client';

import { Dialog } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';

import { DashboardContext } from '../../../../_lib/context/dashboard-context';
import { DASHBOARD_UPDATE } from '../../../../_lib/graphql/mutations';

import { logError } from '../../../../_lib/error';

import { DialogTitle } from '../../../../DialogTitle/dialog-title';

export function AdvancedCustomFinanceLabelDialog(props: any) {
  const { t } = useTranslation();
  const { open, setOpen } = props;

  const { dashboard, setSnackbarIsError, setSnackbarOpen } =
    React.useContext(DashboardContext);

  const [customFinanceBudgetLabel, setCustomFinanceBudgetLabel] =
    React.useState(dashboard.customFinanceBudgetLabel);
  const [customFinanceSpentLabel, setCustomFinanceSpentLabel] = React.useState(
    dashboard.customFinanceSpentLabel
  );
  const [customFinanceFinancedLabel, setCustomFinanceFinancedLabel] =
    React.useState(dashboard.customFinanceFinancedLabel);

  const [loading, setLoading] = React.useState(false);

  const [dashboardUpdateMutation] = useMutation(DASHBOARD_UPDATE);

  // --------------------------------------------------------------------------------------------------
  // --------------------------------------------------------------------------------------------------
  // handlers
  // --------------------------------------------------------------------------------------------------

  const handleCancel = () => {
    setOpen(false);
    setCustomFinanceBudgetLabel(dashboard.customFinanceBudgetLabel);
    setCustomFinanceSpentLabel(dashboard.customFinanceSpentLabel);
  };

  const handleSave = () => {
    setLoading(true);
    dashboardUpdateMutation({
      variables: {
        customFinanceBudgetLabel,
        customFinanceSpentLabel,
        customFinanceFinancedLabel,
      },
    })
      .then(() => {
        setOpen(false);
      })
      .catch((err) => {
        logError(err);
        setSnackbarIsError(true);
      })
      .finally(() => {
        setLoading(false);
        setSnackbarOpen(true);
      });
  };

  // --------------------------------------------------------------------------------------------------
  // --------------------------------------------------------------------------------------------------
  // effects
  // --------------------------------------------------------------------------------------------------

  React.useEffect(() => {
    setCustomFinanceBudgetLabel(dashboard.customFinanceBudgetLabel);
    setCustomFinanceSpentLabel(dashboard.customFinanceSpentLabel);
    setCustomFinanceFinancedLabel(dashboard.customFinanceFinancedLabel);
  }, [dashboard]);

  // --------------------------------------------------------------------------------------------------

  return (
    <Dialog open={open} maxWidth="xs" fullWidth>
      <DialogTitle>{t('Customize Finance Labels')}</DialogTitle>
      <DialogContent dividers>
        <TextField
          size="small"
          margin="dense"
          fullWidth
          label={t('Budget')}
          value={customFinanceBudgetLabel}
          helperText={`${t('e.g')} "% ${t('Budget')}"`}
          onChange={(e) => setCustomFinanceBudgetLabel(e.target.value)}
        />
        <TextField
          size="small"
          margin="dense"
          fullWidth
          label={t('Spent')}
          helperText={`${t('e.g')} "% ${t('Spent')}"`}
          value={customFinanceSpentLabel}
          onChange={(e) => setCustomFinanceSpentLabel(e.target.value)}
          sx={{ marginTop: '1rem' }}
        />
        <TextField
          size="small"
          margin="dense"
          fullWidth
          label={t('Financed')}
          helperText={`${t('e.g')} "% ${t('Financed')}"`}
          value={customFinanceFinancedLabel}
          onChange={(e) => setCustomFinanceFinancedLabel(e.target.value)}
          sx={{ marginTop: '1rem' }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} disabled={loading}>
          {t('Cancel')}
        </Button>
        <LoadingButton
          onClick={handleSave}
          variant="contained"
          disableElevation
          loading={loading}
        >
          {t('Save')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
