import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';

import DragHandleIcon from '@mui/icons-material/DragHandle';
import DeleteIcon from '@mui/icons-material/Delete';

import { useDashboardContext } from '../../../../_lib/context/dashboard-context';

import { DraggableAreaDetail } from '../../../../components/draggable/area-detail';

import { PlanningAreaActionsMove } from '../../../area-actions/move';
import { PlanningAreaActionsDelete } from '../../../area-actions/delete';

export function PlanningPriorityAreaFormDetailChildrenItem({
  area,
  index,
  parent,
  areas,
  setAreas,
  disabled,
}: any) {
  const { t } = useTranslation();

  const { dashboard } = useDashboardContext();

  const handleDeleteNewArea = () => {
    const areasUpdated = structuredClone(areas);
    areasUpdated.splice(index, 1);
    setAreas(structuredClone(areasUpdated));
  };

  const handleChange = (value: string) => {
    const areasUpdated = structuredClone(areas);
    areasUpdated[index].name = value;
    setAreas(areasUpdated);
  };

  return (
    <DraggableAreaDetail detail={area} index={index} isDragDisabled={disabled}>
      <Box sx={{ mb: 2, display: 'flex', alignItems: 'flext-start' }}>
        {dashboard.mode === 'FULL' ? (
          <Box
            sx={{
              width: '35px',
              mr: 1,
              py: 1.5,
            }}
          >
            <Typography
              variant="body1"
              sx={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
              color={disabled || !area.id ? 'text.secondary' : 'text.primary'}
            >
              {area.reference}
            </Typography>
          </Box>
        ) : (
          <Box>
            <IconButton
              size="small"
              sx={{
                cursor: 'grab !important',
                mr: 1,
              }}
              disableRipple
              disabled={disabled}
            >
              <DragHandleIcon />
            </IconButton>
          </Box>
        )}
        <Box sx={{ flexGrow: 1 }}>
          <TextField
            fullWidth
            size="small"
            label={t('Name')}
            value={area.name}
            onChange={(e) => handleChange(e.target.value)}
            disabled={disabled}
            required
            autoFocus={index === areas.length - 1}
            InputProps={{
              style: {
                backgroundColor: 'white',
              },
              autoComplete: 'off',
            }}
          />

          {/* programme */}
          {area.programme && (
            <Typography
              variant="body2"
              color={disabled ? 'text.disabled' : 'text.secondary'}
              sx={{ my: 0.5 }}
            >
              <small title={`${t('Programme')}`}>{t('PROG.')}:</small>{' '}
              {area.programme.name}
            </Typography>
          )}
        </Box>
        <Box sx={{ pl: 1 }}>
          {/* existing area */}
          {!!area.id && !disabled && (
            <>
              {/* move */}
              <PlanningAreaActionsMove
                child={area}
                parent={parent}
                color="info"
              />
              {/* delete */}
              <PlanningAreaActionsDelete area={area} />
            </>
          )}

          {/* new area */}
          {!area.id && !disabled && (
            <IconButton
              onClick={() => handleDeleteNewArea()}
              aria-label="delete"
            >
              <DeleteIcon />
            </IconButton>
          )}
        </Box>
      </Box>
    </DraggableAreaDetail>
  );
}
