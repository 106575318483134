import React from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Switch } from 'react-router-dom';

import { useDashboardContext } from '../_lib/context/dashboard-context';

import { PlanningLayoutBase } from './layout/base';

import { SettingsDashboardForm } from './settings';
import { AnalyticsScreen } from './analytics';

import { PlanningInterventionFormList } from './intervention-form/list';
import { PlanningPriorityAreaFormList } from './priority-area-form/list';
import { PlanningSetupMilestonesForm } from './setup-milestones-form';

export function PlanningLoader() {
  const { t } = useTranslation();
  const {
    dashboard: {
      priorityAreasSlug,
      interventionsSlug,
      areaLevels,
      enableAreaLevels,
    },
  } = useDashboardContext();

  const areaLevelsPaths = React.useMemo(() => {
    const areaLevelsPathsAll = enableAreaLevels
      ? areaLevels.reduce((acc: any, level: any, index: any) => {
          if (index === 0) {
            acc.push({ path: `/${level.slug}`, type: 'all' });
            return acc;
          }

          const prevPath = acc[index - 1];

          if (index === 1) {
            acc.push({
              path: `${prevPath.path}/:priorityAreaId/${level.slug}`,
              type: 'all',
            });
            return acc;
          }

          acc.push({
            path: `${prevPath.path.replace(
              '/:priorityAreaId/',
              `/:priorityAreaId_${areaLevels[index - 2].id}/`
            )}/:priorityAreaId/${level.slug}`,
            type: 'all',
          });
          return acc;
        }, [])
      : [{ path: `/${priorityAreasSlug}`, type: 'all' }];

    const areaLevelsPathsSingle = enableAreaLevels
      ? areaLevels.reduce((acc: any, level: any, index: any) => {
          if (index === 0) {
            acc.push({
              path: `/${level.slug}/:priorityAreaId`,
              type: 'single',
            });
            return acc;
          }
          acc.push({
            path: `${acc[index - 1].path}_${areaLevels[index - 1].id}/${
              level.slug
            }/:priorityAreaId`,
            type: 'single',
          });
          return acc;
        }, [])
      : [{ path: `/${priorityAreasSlug}/:priorityAreaId`, type: 'single' }];

    return [...areaLevelsPathsAll, ...areaLevelsPathsSingle].sort(
      (a: any, b: any) => b.path.length - a.path.length
    );
  }, [areaLevels, enableAreaLevels, priorityAreasSlug]);

  // ----------------------------------------------------------------------------------------------------------------

  return (
    <PlanningLayoutBase>
      <Switch>
        {/* priority areas + interventions */}
        {areaLevelsPaths.map((path: any) => {
          if (path.type === 'all') {
            return (
              <Route key={path.path} path={`${t('/planning')}${path.path}`}>
                <PlanningPriorityAreaFormList />
              </Route>
            );
          }
          return [
            <Route
              key={path.path}
              path={`${t('/planning')}${
                path.path
              }/${interventionsSlug}/:interventionId${t('/milestones')}`}
            >
              {/* milestones */}
              <PlanningSetupMilestonesForm />
            </Route>,
            <Route
              key={path.path}
              path={`${t('/planning')}${path.path}/${interventionsSlug}`}
            >
              {/* interventions */}
              <PlanningInterventionFormList />
            </Route>,
          ];
        })}

        {/* settings */}
        <Route path={`${t('/settings')}`}>
          <SettingsDashboardForm />
        </Route>

        {/* analytics */}
        <Route path={`${t('/analytics')}`}>
          <AnalyticsScreen />
        </Route>
      </Switch>
    </PlanningLayoutBase>
  );
}
