import React from 'react';

import { useParams } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';

import Dialog from '@mui/material/Dialog';
import TextField from '@mui/material/TextField';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import DialogActions from '@mui/material/DialogActions';
import Stack from '@mui/material/Stack';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';

import { DialogTitle } from '../../DialogTitle/dialog-title';
import { useDashboardContext } from '../../_lib/context/dashboard-context';
import { logError } from '../../_lib/error';

import { PRIORITY_AREAS_CREATE } from '../../_lib/graphql/mutations';
import {
  CONTEXT_USER_QUERY,
  PRIORITY_AREAS_PLANNING_QUERY,
  PRIORITY_AREA_PLANNING_QUERY,
} from '../../_lib/graphql/queries';

export function PlanningPriorityAreaFormAddDialog({
  open,
  setOpen,
  areaName,
}: any) {
  const { t } = useTranslation();

  const { priorityAreaId } = useParams<any>();

  const { dashboard, setSnackbarOpen, setSnackbarIsError } =
    useDashboardContext();

  const [loading, setLoading] = React.useState(false);

  const [priorityAreas, setPriorityAreas] = React.useState([{ name: '' }]);
  const [errors, setErrors] = React.useState([]);

  const [createPriorityArea] = useMutation(PRIORITY_AREAS_CREATE, {
    update(cache, { data }) {
      // update sibling priority areas
      cache.writeQuery({
        query: PRIORITY_AREAS_PLANNING_QUERY,
        data: { priorityAreas: data.priorityAreasCreate.siblingPriorityAreas },
        variables: {
          parentId: dashboard.enableAreaLevels
            ? data.priorityAreasCreate.parentPriorityArea?.id
            : undefined,
          filterIsArchived: false,
        },
      });

      // update parent priority area
      if (data.priorityAreasCreate.parentPriorityArea?.id) {
        cache.writeQuery({
          query: PRIORITY_AREA_PLANNING_QUERY,
          data: { priorityArea: data.priorityAreasCreate.parentPriorityArea },
          variables: {
            priorityAreaId: data.priorityAreasCreate.parentPriorityArea.id,
          },
        });
      }

      // update context user
      cache.writeQuery({
        query: CONTEXT_USER_QUERY,
        data: { user: data.priorityAreasCreate.user },
      });
    },
  });

  // ----------------------------------------------------------------------------------------------------------------------
  // ----------------------------------------------------------------------------------------------------------------------
  // handlers
  // ----------------------------------------------------------------------------------------------------------------------

  const handleCancel = () => {
    setOpen(false);
    setErrors([]);
  };

  const handleRemove = (index: number) => {
    setPriorityAreas((prev: any) => [
      ...prev.slice(0, index),
      ...prev.slice(index + 1),
    ]);
  };

  const handleSave = () => {
    setLoading(true);

    // check for errors
    const newErrors: any = [];
    priorityAreas.forEach((priorityArea: any, index: number) => {
      if (!priorityArea.name) {
        newErrors[index] = true;
      } else {
        newErrors[index] = false;
      }
    });
    setErrors(newErrors);

    // save if no errors
    if (priorityAreas.every((priorityArea: any) => priorityArea.name)) {
      createPriorityArea({
        variables: {
          names: priorityAreas.map((priorityArea: any) => priorityArea.name),
          parentId: dashboard.enableAreaLevels ? priorityAreaId : undefined,
        },
      })
        .then(() => {
          setOpen(false);
          setPriorityAreas([{ name: '' }]);
        })
        .catch((error: any) => {
          logError(error);
          setSnackbarIsError(true);
        })
        .finally(() => {
          setSnackbarOpen(true);
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  };

  // ----------------------------------------------------------------------------------------------------------------------

  return (
    <Dialog open={open} fullWidth maxWidth="xs">
      <DialogTitle onClose={handleCancel}>
        {t('Add')}: {areaName}
      </DialogTitle>

      {/* content */}
      <DialogContent>
        <Stack spacing={2} sx={{ mt: 1 }}>
          {priorityAreas.map((priorityArea, index) => (
            <TextField
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              label={t('Name')}
              value={priorityArea.name}
              onChange={(e) => {
                const newPriorityAreas = structuredClone(priorityAreas);
                newPriorityAreas[index].name = e.target.value;
                setPriorityAreas(newPriorityAreas);
              }}
              fullWidth
              required
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => handleRemove(index)}>
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              size="small"
              sx={{ '& .MuiOutlinedInput-root': { pr: 0 } }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  const newPriorityAreas = structuredClone(priorityAreas);
                  newPriorityAreas.push({ name: '' });
                  setPriorityAreas(newPriorityAreas);
                }
                if (e.key === 'Delete' && index > 0) {
                  e.preventDefault();
                  handleRemove(index);
                }
              }}
              autoFocus={index === priorityAreas.length - 1}
              error={errors[index]}
              helperText={errors[index] ? t('Required') : ''}
              disabled={loading}
            />
          ))}
          <Button
            variant="text"
            color="primary"
            onClick={() => {
              const newPriorityAreas = structuredClone(priorityAreas);
              newPriorityAreas.push({ name: '' });
              setPriorityAreas(newPriorityAreas);
            }}
            startIcon={<AddIcon />}
            sx={{ justifyContent: 'flex-start' }}
            disabled={loading}
          >
            {t('Add')}
          </Button>
        </Stack>
      </DialogContent>

      {/* actions */}
      <DialogActions>
        <Button disabled={loading} variant="outlined" onClick={handleCancel}>
          {t('Cancel')}
        </Button>
        <LoadingButton
          loading={loading}
          variant="contained"
          disableElevation
          onClick={handleSave}
        >
          {t('Save')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

export default PlanningPriorityAreaFormAddDialog;
