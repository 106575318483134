import React from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorBoundary } from 'react-error-boundary';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

import Chip from '@mui/material/Chip';

import { useDashboardContext } from '../_lib/context/dashboard-context';
import { ReactErrorComponent } from '../_lib/react-error';
import { hasSomeFinance } from '../_utils/finance-utils';

import { Markdown } from '../components/markdown';

import { AreaSummaryLeadText } from '../components/area-summary/lead-text/list';
import { AreaSummaryFinancials } from '../components/area-summary/financials';
import { AreaSummaryGoToDashboard } from '../components/area-summary/go-to-dashboard';
import { AreaSummaryCustomFields } from '../components/area-summary/custom-fields';

export function InterventionSummary({ intervention }: any) {
  const { t } = useTranslation();

  const { dashboard } = useDashboardContext();

  return (
    <ErrorBoundary FallbackComponent={ReactErrorComponent}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={7}>
          {/* switch dashboard */}
          {dashboard.mode === 'SUPRANATIONAL' && (
            <Box sx={{ mb: 2.5 }}>
              <AreaSummaryGoToDashboard area={intervention} />
            </Box>
          )}

          {/* lead & co-lead */}
          <Box sx={{ my: 2 }}>
            <AreaSummaryLeadText area={intervention} isLead />
            <AreaSummaryLeadText area={intervention} />
          </Box>

          {/* status & classification & region */}
          {!!intervention.status && (
            <Typography
              variant="body1"
              component="div"
              gutterBottom
              sx={{ display: 'flex', alignItems: 'center' }}
            >
              <b>{t('Status')}:</b>
              <Chip
                label={
                  intervention.archivedAt
                    ? t('Archived')
                    : intervention.status.name
                }
                color="default"
                size="small"
                sx={(theme) => ({
                  bgcolor: intervention.status.color,
                  color: theme.palette.getContrastText(
                    intervention.status.color
                  ),
                  ml: 1,
                })}
              />
            </Typography>
          )}
          {/* programme */}
          {!!intervention.programme && (
            <Typography variant="body1">
              <b>{t('Programme')}:</b> {intervention.programme.name}
            </Typography>
          )}
          {intervention.classifications?.length > 0 && (
            <Typography variant="body1">
              <b>
                {dashboard.enableCustomClassificationLabel
                  ? dashboard.customClassificationLabel
                  : t('Classification')}
                :
              </b>{' '}
              {intervention.classifications?.map((c: any) => c.name).join(', ')}
            </Typography>
          )}
          {intervention.regions?.length > 0 && (
            <Typography variant="body1">
              <b>
                {dashboard.enableCustomRegionLabel
                  ? dashboard.customRegionLabel
                  : t('Regions')}
                :
              </b>{' '}
              {intervention.regions
                .map((region: any) => region.name)
                .join(', ')}
            </Typography>
          )}

          {/* custom fields */}
          {dashboard.enableAreaFields && (
            <Box sx={{ my: 2 }}>
              <AreaSummaryCustomFields area={intervention} />
            </Box>
          )}

          {/* description */}
          <Box sx={{ my: 2 }}>
            <Markdown>{intervention.description || '--'}</Markdown>
          </Box>

          {/* stakeholders */}
          {dashboard.enableAreaStakeholders &&
            intervention.stakeholders.length > 0 && (
              <Box sx={{ my: 2 }}>
                <Typography variant="body1">
                  <b>{t('Stakeholders')}:</b>
                </Typography>
                <ul>
                  {intervention.stakeholders.map((stakeholder: any) => (
                    <li key={stakeholder.id}>
                      <Typography variant="body1">
                        {stakeholder.name}
                      </Typography>
                    </li>
                  ))}
                </ul>
              </Box>
            )}
        </Grid>

        {/* financials */}
        {hasSomeFinance([intervention]) && (
          <Grid item xs={12} sm={5}>
            <AreaSummaryFinancials
              id={`intv-${intervention.id}-summary-financials`}
              area={{
                ...intervention,
                totalFunding: intervention.summary.finance.totalFunding,
                currency: dashboard.currency,
              }}
            />
          </Grid>
        )}
      </Grid>
    </ErrorBoundary>
  );
}
