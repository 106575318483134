import { Link } from 'react-router-dom';
import Button, { ButtonProps } from '@mui/material/Button';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

export function ArrowForwardLink({
  to,
  text,
  variant,
  sx,
  size,
  disabled,
  disableElavation,
}: {
  to: string;
  text: string;
  variant?: ButtonProps['variant'];
  sx?: ButtonProps['sx'];
  size?: ButtonProps['size'];
  disabled?: boolean;
  disableElavation?: boolean;
}) {
  return (
    <Button
      component={Link}
      variant={variant}
      to={to}
      endIcon={<ArrowForwardIcon />}
      sx={{ textTransform: 'none', ...sx }}
      size={size}
      disabled={disabled}
      disableElevation={disableElavation}
    >
      {text}
    </Button>
  );
}

ArrowForwardLink.defaultProps = {
  variant: 'outlined',
  sx: {},
  size: 'medium',
  disabled: false,
  disableElavation: false,
};
