import React from 'react';

import { MTableToolbar } from '@material-table/core';

import { TrackerTableStatusBar } from '../status-bar';

export function TrackerTableToolbar({ status, ...props }: any) {
  return (
    <div>
      <MTableToolbar {...props} />
      {(status === 'all' ||
        status === 'my' ||
        status === 'milestones' ||
        status === 'activities') && <TrackerTableStatusBar />}
    </div>
  );
}
