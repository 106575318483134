import React from 'react';
import { useMutation } from '@apollo/client';

import { DragDropContext, Droppable } from 'react-beautiful-dnd';

import { useDashboardContext } from '../../../_lib/context/dashboard-context';
import { logError } from '../../../_lib/error';

import { PRIORITY_AREAS_UPDATE_ORDER } from '../../../_lib/graphql/mutations';
import {
  PRIORITY_AREAS_PLANNING_QUERY,
  PRIORITY_AREA_PLANNING_QUERY,
} from '../../../_lib/graphql/queries';

export function PlanningPriorityAreaFormDraggable({
  details,
  setDetails,
  filtered,
  droppableId,
  children,
}: any) {
  const { dashboard, setSnackbarOpen, setSnackbarIsError, setAppBarLoading } =
    useDashboardContext();

  const [updateOrderMutation] = useMutation(PRIORITY_AREAS_UPDATE_ORDER, {
    update(cache, { data: { priorityAreasUpdateOrder } }) {
      // sibling priority areas update
      cache.writeQuery({
        query: PRIORITY_AREAS_PLANNING_QUERY,
        variables: {
          parentId: dashboard.enableAreaLevels
            ? priorityAreasUpdateOrder.parentPriorityArea?.id
            : undefined,
        },
        data: {
          priorityAreas: priorityAreasUpdateOrder.siblingPriorityAreas,
        },
      });

      // parent priority area
      cache.writeQuery({
        query: PRIORITY_AREA_PLANNING_QUERY,
        variables: {
          priorityAreaId: dashboard.enableAreaLevels
            ? priorityAreasUpdateOrder.parentPriorityArea?.id
            : undefined,
        },
        data: {
          priorityArea: priorityAreasUpdateOrder.parentPriorityArea,
        },
      });
    },
  });

  const onDragEnd = (result: any) => {
    if (filtered) return;

    const { destination, source, draggableId } = result;

    // Check if not moved
    if (!destination) {
      return;
    }
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    // Moved
    setAppBarLoading(true);

    const oldOrderDetailsIds = details.map((detail: any) => detail.id);
    const newOrderedDetailsIds = details.map((detail: any) => detail.id);
    newOrderedDetailsIds.splice(source.index, 1);
    newOrderedDetailsIds.splice(destination.index, 0, draggableId);

    setDetails(
      newOrderedDetailsIds.map((id: any) =>
        details.find((detail: any) => detail.id === id)
      )
    );

    updateOrderMutation({
      variables: {
        ids: newOrderedDetailsIds.filter((id: any) => id),
      },
    })
      .catch((err: any) => {
        logError(err);
        setSnackbarIsError(true);
        setDetails(
          oldOrderDetailsIds.map((id: any) =>
            details.find((detail: any) => detail.id === id)
          )
        );
      })
      .finally(() => {
        setSnackbarOpen(true);
        setAppBarLoading(false);
      });
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable
        droppableId={droppableId || 'planning-priority-area-form-list'}
      >
        {(provided) => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            {children}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}
