import React from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorBoundary } from 'react-error-boundary';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Grid';

import { ReactErrorComponent } from '../../_lib/react-error';

import { PlanningLayoutTabPanel } from '../layout/tab-panel';
import { useAnalytics, ChartType } from './use-analytics';
import { AnalyticsToC } from './toc';

export function AnalyticsScreen() {
  const { t } = useTranslation();

  const { charts } = useAnalytics();

  return (
    <PlanningLayoutTabPanel
      index={2}
      title={`${t('Analytics')}`}
      subheader={`${t('Statistics and usage information')}`}
    >
      <Box>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4} md={3.5} lg={3}>
            <AnalyticsToC />
          </Grid>
          <Grid item xs={12} sm={8} md={8.5} lg={9}>
            <Stack spacing={3} sx={{ mt: 3 }}>
              {charts.map((chart: ChartType) => {
                return (
                  <Card key={chart.title} variant="outlined">
                    <ErrorBoundary FallbackComponent={ReactErrorComponent}>
                      {chart.component}
                    </ErrorBoundary>
                  </Card>
                );
              })}
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </PlanningLayoutTabPanel>
  );
}
