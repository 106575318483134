import React from 'react';
import { useTranslation } from 'react-i18next';
import { gql, useMutation } from '@apollo/client';

import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import { logError } from '../../../../../_lib/error';
import { useDashboardContext } from '../../../../../_lib/context/dashboard-context';
import { USER_SETTINGS_QUERY } from '../../../../../_lib/graphql/queries';
import { USER_SETTING_FRAGMENT } from '../../../../../_lib/graphql/fragments';

const OPTION_LABELS: any = {
  notificationsEnabled: 'All Notifications',
  notificationsEmailEnabled: 'Email Notifications',
  // admin
  adminMemberAdded: 'Member Added',
  adminMemberRemoved: 'Member Removed',
  adminMemberMadeAdmin: 'Member made Administrator',
  adminDashboardDeleted: 'Dashboard Deleted',

  // regular
  regularHourlyUpdates: 'Hourly Updates',
  regularWeeklyUpdates: 'Weekly Updates',
  regularContentAssigned: 'Assigned to Activity / Milestone',
  regularContentMentioned: 'Mentioned in Activity / Milestone',
  regularContentDeleted: 'Activity / Issue / Milestone Deleted',

  // other
  priorityAreaDeleted: 'Main Area / Sub-Area Deleted',
  interventionDeleted: 'Project / Intervention Deleted',
  targetDeleted: 'Target / KPI Deleted',
  financierDeleted: 'Financier Deleted',
};

const UPDATE_NOTIFICATIONS_PREFERENCES_MUTATION = gql`
  mutation UpdateNotificationsPreferencesMutation(
    $settingId: ID!
    $notificationsPreferences: UserSettingsNotificationsPreferencesInputType
  ) {
    memberUpdate(
      settingId: $settingId
      notificationsPreferences: $notificationsPreferences
    ) {
      success
      user {
        id
        userSettings {
          ...UserSettingFragment
        }
      }
    }
  }
  ${USER_SETTING_FRAGMENT}
`;

export function MyAccountPreferencesDetailNotificationsOption({
  setting,
  option,
}: any) {
  const { t } = useTranslation();

  const { setSnackbarOpen, setSnackbarIsError } = useDashboardContext();

  const [loading, setLoading] = React.useState(false);

  const [updateNotificationsPreferences] = useMutation(
    UPDATE_NOTIFICATIONS_PREFERENCES_MUTATION,
    {
      update(cache, { data: { memberUpdate } }) {
        if (memberUpdate.success) {
          cache.writeQuery({
            query: USER_SETTINGS_QUERY,
            data: {
              user: {
                ...memberUpdate.user,
              },
            },
          });
        }
      },
    }
  );

  const handleChange = (event: any) => {
    setLoading(true);
    updateNotificationsPreferences({
      variables: {
        settingId: setting.id,
        notificationsPreferences: {
          [option]: event.target.checked,
        },
      },
    })
      .then(() => {
        setSnackbarOpen(true);
        setLoading(false);
      })
      .catch((err) => {
        logError(err);
        setSnackbarIsError(true);
        setSnackbarOpen(true);
        setLoading(false);
      });
  };

  const disabled = React.useMemo(() => {
    if (setting.notificationsPreferences) {
      if (option === 'notificationsEnabled') {
        return false;
      }
      if (option === 'notificationsEmailEnabled') {
        return !setting.notificationsPreferences.notificationsEnabled;
      }
      return (
        !setting.notificationsPreferences.notificationsEnabled ||
        !setting.notificationsPreferences.notificationsEmailEnabled
      );
    }
    return false;
  }, [setting, option]);

  return (
    <FormGroup>
      <FormControlLabel
        control={
          <Checkbox
            checked={
              setting.notificationsPreferences
                ? setting.notificationsPreferences[option]
                : true
            }
            disabled={loading || disabled}
            indeterminate={loading}
            onChange={handleChange}
            size="small"
          />
        }
        label={t(OPTION_LABELS[option])}
      />
    </FormGroup>
  );
}
