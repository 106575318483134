import React from 'react';

import Box from '@mui/material/Box';

import { AreaSummaryLeadText } from '../lead-text/list';

export function AreaSummaryCardLeads({ area }: any) {
  return (
    <Box sx={{ textAlign: 'center' }}>
      <AreaSummaryLeadText area={area} isLead variant="body2" />
      <AreaSummaryLeadText area={area} variant="body2" />
    </Box>
  );
}
