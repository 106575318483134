import React from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorBoundary } from 'react-error-boundary';
import { useQuery } from '@apollo/client';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';

import { ReactErrorComponent } from '../_lib/react-error';

import { INTERVENTION_TRACKER_QUERY } from '../_lib/graphql/queries';
import { INTERVENTION_TRACKER_SUBSCRIPTION } from '../_lib/graphql/subscriptions';

import { SubscribeToMoreComponent } from '../components/subscribe-to-more';

import { Tracker } from '../tracker';

export function InterventionTracker({ intervention }: any) {
  const { t } = useTranslation();

  const { data, loading, error, refetch, subscribeToMore } = useQuery(
    INTERVENTION_TRACKER_QUERY,
    {
      variables: {
        interventionId: intervention.id,
      },
    }
  );

  // ------------------------------------------------------------------------------------------------------------------------
  // ------------------------------------------------------------------------------------------------------------------------
  // effects
  // ------------------------------------------------------------------------------------------------------------------------

  React.useEffect(() => {
    if (!data && !loading && !error) refetch();
  }, [data, loading, error, refetch]);

  // ------------------------------------------------------------------------------------------------------------------------

  if (error) return <ReactErrorComponent error={error} />;

  if (loading || !data) return <Skeleton variant="rectangular" height={500} />;

  return (
    <ErrorBoundary FallbackComponent={ReactErrorComponent}>
      <Box>
        <Typography variant="h4">{t('Activities')}</Typography>
        <Typography variant="body1" sx={{ mb: 2 }}>
          {t(
            'Click a row to see more details about that activity, ' +
              'or click a coloured tab to filter by status'
          )}
        </Typography>

        <Tracker
          content={data.intervention.content}
          areaTitle={`${intervention.priorityArea.reference}.${intervention.priorityArea.name} - ${intervention.reference}.${intervention.name}`}
        />
      </Box>

      {/* subscription */}
      <SubscribeToMoreComponent
        subscribeToMore={subscribeToMore}
        document={INTERVENTION_TRACKER_SUBSCRIPTION}
        variables={{ interventionId: intervention.id }}
      />
    </ErrorBoundary>
  );
}
