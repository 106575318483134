import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { differenceInDays } from 'date-fns';

import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';

import { Column } from '@material-table/core';

import { useDashboardContext } from '../../../_lib/context/dashboard-context';

import { NotificationColumn } from './notification';
import { statusColumn } from './status';
import { interventionColumn, priorityAreaColumn } from './areas';
import { assigneeColumns } from './members';
import { LastLoginDateColumn } from './last-login';
import { ColumnDate } from './date';

export function useTrackerTableColumns({ status }: any) {
  const { t } = useTranslation();

  const { dashboard } = useDashboardContext();

  const { interventionId, priorityAreaId } = useParams<any>();

  const cols = React.useMemo(() => {
    // Logins view

    if (status === 'logins') {
      const nameColumn: Column<any> = {
        id: 'id',
        title: t('Member'),
        field: 'name',
        render: (u: any) => <Typography variant="body1">{u.name}</Typography>,
        grouping: false,
        filtering: false,
      };

      const lastLoginColumn: Column<any> = {
        id: 'id',
        title: t('Last login'),
        field: 'lastLogin',
        render: (u: any) => <LastLoginDateColumn user={u} />,
      };

      return [nameColumn, lastLoginColumn];
    }

    // normal view

    // flag + notification
    const notificationColumn: Column<any> = {
      id: 'id',
      field: 'flagged',
      render: (content: any) => <NotificationColumn content={content} />,
      hiddenByColumnsButton: true,
      grouping: false,
      filtering: false,
    };

    // id
    const idColumn: Column<any> = {
      id: 'id',
      title: t('ID'),
      field: 'id',
      grouping: false,
      render: (content: any) => (
        <Typography variant="body1">{content.id}</Typography>
      ),
      hidden: true,
    };

    // title
    const titleColumn: Column<any> = {
      id: 'id',
      title: t('Title'),
      field: 'title',
      grouping: false,
      render: (content: any) => (
        <Box sx={{ minWidth: '210px' }}>
          {content.isMilestone && (
            <Chip
              label={t('Milestone')}
              color="info"
              size="small"
              sx={{ mb: 0.5 }}
            />
          )}
          <Typography variant="body1">{content.title}</Typography>
        </Box>
      ),
    };

    // days between start date and deadline
    const computeDays = (content: any) => {
      if (content.type === 'issue') {
        return differenceInDays(new Date(), new Date(content.createdAt));
      }
      if (content.startDate && content.deadline) {
        return differenceInDays(
          new Date(content.deadline),
          new Date(content.startDate)
        );
      }
      return null;
    };

    const daysColumn: Column<any> = {
      id: 'id',
      title: t('Days'),
      headerStyle: {
        textAlign: 'center',
      },
      field: 'days',
      render: (content: any) => (
        <Typography variant="body1" textAlign="center">
          {computeDays(content) || '-'}
        </Typography>
      ),
      grouping: false,
      customSort: (data1, data2, type, sortDirection) => {
        if (type === 'row') {
          let date1 = Infinity;
          if (data1.type !== 'issue' && data1.startDate && data1.deadline) {
            date1 = differenceInDays(
              new Date(data1.deadline),
              new Date(data1.startDate)
            );
          }
          let date2 = Infinity;
          if (data2.type !== 'issue' && data2.startDate && data2.deadline) {
            date2 = differenceInDays(
              new Date(data2.deadline),
              new Date(data2.startDate)
            );
          }
          return date1 - date2;
        }
        return 0;
      },
      customExport: (content: any) => computeDays(content),
    };

    // start date
    const dateSort = (
      data1: any,
      data2: any,
      type: any,
      sortDirection: any
    ) => {
      if (type === 'row') {
        const date1 = data1.startDate
          ? new Date(data1.startDate).getTime()
          : Infinity;
        const date2 = data2.startDate
          ? new Date(data2.startDate).getTime()
          : Infinity;
        return date1 - date2;
      }
      return 0;
    };
    const startDateColumn: Column<any> = {
      id: 'id',
      title: t('Start Date'),
      field: 'startDate',
      render: (content: any) => (
        <ColumnDate
          date={content?.type !== 'issue' ? content?.startDate : null}
          typographyProps={{
            sx: {
              '&:hover': {},
            },
          }}
        />
      ),
      grouping: false,
      customSort: dateSort,
    };

    // deadline
    const deadlineColumn: Column<any> = {
      id: 'id',
      title: t('Deadline'),
      field: 'deadline',
      render: (content: any) => (
        <ColumnDate
          date={content?.deadline}
          typographyProps={{
            sx: {
              '&:hover': {},
            },
          }}
        />
      ),
      customSort: dateSort,
      grouping: false,
    };

    // started at
    const startedAtColumn: Column<any> = {
      id: 'id',
      title: t('Started On'),
      field: 'startedAt',
      render: (content: any) => (
        <ColumnDate
          date={content?.type !== 'issue' ? content?.startedAt : null}
          typographyProps={{
            sx: {
              '&:hover': {},
            },
          }}
        />
      ),
      customSort: dateSort,
      // started at should be exported as date instead of datetime
      customExport: (content: any) =>
        content?.startedAt
          ? new Date(content.startedAt).toLocaleDateString()
          : '',
      grouping: false,
    };

    // completed at
    const completionColumn: Column<any> = {
      id: 'id',
      title: t('Completed On'),
      field: 'completionDate',
      render: (content: any) => (
        <ColumnDate
          date={content?.type !== 'issue' ? content?.completionDate : null}
          typographyProps={{
            sx: {
              '&:hover': {},
            },
          }}
        />
      ),
      customSort: dateSort,
      grouping: false,
    };

    // created at
    const createdAt: Column<any> = {
      id: 'id',
      field: 'content.createdAt',
      title: t('Created at'),
      render: (content: any) => (
        <ColumnDate
          date={content.createdAt}
          typographyProps={{
            sx: {
              '&:hover': {},
            },
          }}
        />
      ),
      grouping: false,
      customSort: (data1, data2, type, sortDirection) => {
        if (type === 'row') {
          const date1 = data1.createdAt
            ? new Date(data1.createdAt).getTime()
            : Infinity;
          const date2 = data2.createdAt
            ? new Date(data2.createdAt).getTime()
            : Infinity;
          return date1 - date2;
        }
        return 0;
      },
    };

    // Last Updated
    const lastUpdatedAt: Column<any> = {
      id: 'id',
      title: t('Last updated'),
      field: 'content.updatedAt',
      render: (content: any) => <ColumnDate date={content.updatedAt} />,
      grouping: false,
      customSort: (data1, data2, type, sortDirection) => {
        if (type === 'row') {
          const date1 = data1.updatedAt
            ? new Date(data1.updatedAt).getTime()
            : Infinity;
          const date2 = data2.updatedAt
            ? new Date(data2.updatedAt).getTime()
            : Infinity;
          return date1 - date2;
        }
        return 0;
      },
    };

    // Activity Percentage Done
    const percDoneColumn: Column<any> = {
      id: 'id',
      title: t('% Complete'),
      headerStyle: {
        textAlign: 'center',
      },
      field: 'content.percDone',
      render: (content: any) => (
        <Typography variant="body2" align="center">
          {!content?.isIssue ? `${content?.percDone}%` : '--'}
        </Typography>
      ),
      customSort: (a: any, b: any) =>
        parseInt(a.percDone, 10) - parseInt(b.percDone, 10),
      customExport: (content: any) => `${content.percDone}%`,
      grouping: false,
    };

    // phase / content group column
    const phaseColumn: Column<any> = {
      id: 'id',
      title: t('Phase'),
      field: 'group.title',
      customExport(rowData) {
        return rowData.group?.title || t('Unassigned');
      },
      customSort: (data1, data2, type, sortDirection) => {
        const group1id = type === 'row' ? data1.group?.id : data1;
        const group2id = type === 'row' ? data2.group?.id : data2;
        const group1 = dashboard.contentGroups?.find(
          (group: any) => group.id === group1id
        );
        const group2 = dashboard.contentGroups?.find(
          (group: any) => group.id === group2id
        );
        const group1Order = Number.isNaN(Number.parseInt(group1?.order, 10))
          ? Infinity
          : group1.order;
        const group2Order = Number.isNaN(Number.parseInt(group2?.order, 10))
          ? Infinity
          : group2.order;

        return group1Order - group2Order;
      },
      render: (content: any) => (
        <Typography
          variant="body2"
          sx={{
            whiteSpace: 'nowrap',
            minWidth: '180px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            color: content.group?.title ? 'text.primary' : 'text.secondary',
          }}
        >
          {content.group?.title || t('Unassigned')}
        </Typography>
      ),
      groupRender: (groupData: any) => {
        const group = dashboard.contentGroups?.find(
          (g: any) => g.id === groupData.value
        );

        // Percentage Done
        const mapPercDoneRows = (groupD: any) => {
          let totalRows = 0;
          let percDoneSum = 0;
          if (dashboard.enableActivityPercentage) {
            if (groupD?.data?.length) {
              groupD.data.forEach((row: any) => {
                if (row.type !== 'issue') {
                  percDoneSum += row.percDone;
                  totalRows += 1;
                }
              });
            } else {
              groupD.groups?.forEach((g: any) => {
                const [groupTotalRows, groupPercDoneSum] = mapPercDoneRows(g);

                totalRows += groupTotalRows;
                percDoneSum += groupPercDoneSum;
              });
            }
          }

          return [totalRows, percDoneSum];
        };

        const [totalRowsPerc, percDoneSumPerc] = mapPercDoneRows(groupData);

        const percDoneAvg =
          totalRowsPerc === 0
            ? '--'
            : `${Math.round(percDoneSumPerc / totalRowsPerc)}%`;

        return (
          <Typography
            sx={{
              display: 'inline',
              color: group?.title ? 'text.primary' : 'text.secondary',
              fontStyle: group?.title ? 'normal' : 'italic',
            }}
          >
            {group?.title || t('Unassigned')} {`(${totalRowsPerc})`}
            {dashboard.enableActivityPercentage && (
              <Typography
                component="span"
                variant="body2"
                sx={{ pl: 1.2, color: 'text.primary', fontStyle: 'normal' }}
              >
                [ {t('Percentage Done')}: {percDoneAvg} ]
              </Typography>
            )}
          </Typography>
        );
      },
    };

    // ---------------------------------------------------------------------------------------------------------
    // ---------------------------------------------------------------------------------------------------------
    // columns
    // ---------------------------------------------------------------------------------------------------------

    const columns: Column<any>[] = [];

    // default columns
    const defaultColumns: Column<any>[] = [
      notificationColumn,
      idColumn,
      titleColumn,
      statusColumn({ t, dashboard }),
    ];
    columns.push(...defaultColumns);

    // activity group / phase column
    if (dashboard.enableActivityGroup) columns.push(phaseColumn);

    // // areas columns
    if (!priorityAreaId || dashboard.enableAreaLevels) {
      columns.push(
        priorityAreaColumn({
          dashboard,
          t,
          priorityAreaName: dashboard.enableAreaLevels
            ? t('Area')
            : dashboard.priorityAreaName,
        })
      );
    }
    if (!interventionId) {
      columns.push(
        interventionColumn({
          t,
          interventionName: dashboard.interventionName,
          dashboard,
        })
      );
    }

    if (status !== 'issue') {
      // assignee column
      columns.push(...assigneeColumns({ t, status, dashboard }));

      // perc done column
      if (dashboard.enableActivityPercentage) columns.push(percDoneColumn);

      // dates columns
      if (dashboard.enableActivityStartDate) columns.push(startDateColumn);
      columns.push(deadlineColumn);
      // days column
      if (dashboard.enableActivityStartDate) columns.push(daysColumn);

      columns.push(startedAtColumn);
      columns.push(completionColumn);
    }

    // timestamps columns
    columns.push(createdAt);
    columns.push(lastUpdatedAt);

    return columns;
  }, [dashboard, interventionId, priorityAreaId, status, t]);

  return { columns: cols };
}
