/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { useHistory, Link as RouterLink, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';

import { BreadcrumbLayout } from './_layout';
import { BreadcrumbsLoad } from './_load';

// TODO: use an array<{label, link}> for breadcrumbs

export function DashboardBreadcrumb() {
  const { t } = useTranslation();

  const history = useHistory();
  const { priorityAreaId, interventionId } = useParams<any>();

  const [breadcrumbs, setBreadcrumbs] = React.useState<any>([]);
  const [loading, setLoading] = React.useState(true);

  if (window.location.pathname === '/') return null;

  return (
    <>
      <BreadcrumbLayout id="breadcrumb-dashboard">
        <Link
          component="button"
          underline="hover"
          color="inherit"
          id="dashboard-crumb"
          onClick={() => history.push('/')}
          sx={{ display: 'flex', alignItems: 'center' }}
        >
          <HomeRoundedIcon
            fontSize="inherit"
            sx={{
              mr: { xs: 0, sm: 1 },
              fontSize: { xs: 'initial', sm: 'inherit' },
            }}
          />
          <Box sx={{ display: { xs: 'none', sm: 'inline' } }}>{t('Home')}</Box>
        </Link>

        {/* loading */}
        {loading && (
          <Typography>
            <Skeleton width={300} sx={{ maxWidth: '100%' }} />
          </Typography>
        )}

        {/* breadcrumbs */}
        {breadcrumbs.map((crumb: any, i: number) =>
          (i < breadcrumbs.length - 1 && !!crumb.to.match(/\d$/)) || i === 0 ? (
            <Link
              key={crumb.to}
              component={RouterLink}
              to={crumb.to}
              underline="hover"
              color="inherit"
            >
              {crumb.label}
            </Link>
          ) : (
            <Typography color="text.primary" key={crumb.to}>
              {crumb.label}
            </Typography>
          )
        )}

        {/* tracker */}
        {window.location.pathname
          .replace(/\/$/, '')
          .endsWith(t('/tracker')) && (
          <Typography color="text.primary">{t('Tracker')}</Typography>
        )}
      </BreadcrumbLayout>

      {/* data loader */}
      {!!priorityAreaId && (
        <BreadcrumbsLoad
          priorityAreaId={priorityAreaId}
          interventionId={interventionId}
          setBreadcrumbs={setBreadcrumbs}
          setLoading={setLoading}
        />
      )}
    </>
  );
}
