import React from 'react';
import { useTranslation } from 'react-i18next';

import MuiTable from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TablePagination, {
  TablePaginationProps,
} from '@mui/material/TablePagination';

type TableProps = {
  ariaLabel?: string;
  children?: React.ReactNode;
  paginationProps?: Partial<TablePaginationProps>;
};

export function Table({ ariaLabel, children, paginationProps }: TableProps) {
  const { t } = useTranslation();
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number
  ) => {
    paginationProps?.onPageChange?.(event, newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    paginationProps?.onRowsPerPageChange?.(event);
  };

  return (
    <>
      <TableContainer
        sx={{
          borderWidth: '1px 0 1px 0',
          borderColor: 'grey.300',
          borderStyle: 'solid',
        }}
      >
        <MuiTable
          stickyHeader
          size="small"
          sx={{ minWidth: 650 }}
          aria-label={ariaLabel}
        >
          <TableBody>{children}</TableBody>
        </MuiTable>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        rowsPerPage={paginationProps?.rowsPerPage || 5}
        page={paginationProps?.page || 0}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        count={paginationProps?.count || 0}
        labelRowsPerPage={t('Rows per page')}
        {...paginationProps}
      />
    </>
  );
}
