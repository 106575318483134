import { useMutation } from '@apollo/client';
import {
  PRIORITY_AREA_DELETE,
  PRIORITY_AREA_ARCHIVE,
  INTERVENTION_ARCHIVE,
} from '../../../_lib/graphql/mutations';
import { useDashboardContext } from '../../../_lib/context/dashboard-context';
import {
  CONTEXT_USER_QUERY,
  PRIORITY_AREAS_PLANNING_QUERY,
  PRIORITY_AREA_PLANNING_QUERY,
  INTERVENTIONS_PLANNING_QUERY,
} from '../../../_lib/graphql/queries';

export function useDeleteArea() {
  const { dashboard } = useDashboardContext();
  const [deleteMutation] = useMutation(PRIORITY_AREA_DELETE, {
    update(cache, result) {
      // update sibling priority areas
      cache.writeQuery({
        query: PRIORITY_AREAS_PLANNING_QUERY,
        data: {
          priorityAreas: result.data.priorityAreaDelete.siblingPriorityAreas,
        },
        variables: {
          parentId: dashboard.enableAreaLevels
            ? result.data.priorityAreaDelete.parentPriorityArea?.id
            : undefined,
          filterIsArchived: false,
        },
      });
      // update sibling priority areas archived
      cache.writeQuery({
        query: PRIORITY_AREAS_PLANNING_QUERY,
        data: {
          priorityAreas:
            result.data.priorityAreaDelete.siblingPriorityAreasArchived,
        },
        variables: {
          parentId: dashboard.enableAreaLevels
            ? result.data.priorityAreaDelete.parentPriorityArea?.id
            : undefined,
          filterIsArchived: true,
        },
      });

      // update parent priority area
      cache.writeQuery({
        query: PRIORITY_AREA_PLANNING_QUERY,
        data: {
          priorityArea: result.data.priorityAreaDelete.parentPriorityArea,
        },
        variables: {
          priorityAreaId: result.data.priorityAreaDelete.parentPriorityArea?.id,
        },
      });

      // update user context
      cache.writeQuery({
        query: CONTEXT_USER_QUERY,
        data: {
          user: result.data.priorityAreaDelete.user,
        },
      });
    },
  });

  return { deleteMutation };
}

export function useArchiveArea(
  areaType: 'PriorityArea' | 'Intervention' = 'PriorityArea',
  listVariables?: any
) {
  const { dashboard } = useDashboardContext();
  const mutationVars = {
    PriorityArea: {
      mutation: PRIORITY_AREA_ARCHIVE,
      responseKey: 'priorityAreaArchive',
      areaListCacheQuery: PRIORITY_AREAS_PLANNING_QUERY,
      cacheKeyName: 'priorityAreas',
      cacheItemName: 'siblingPriorityAreas',
    },
    Intervention: {
      mutation: INTERVENTION_ARCHIVE,
      responseKey: 'interventionArchive',
      areaListCacheQuery: INTERVENTIONS_PLANNING_QUERY,
      cacheKeyName: 'interventions',
      cacheItemName: 'interventions',
    },
  };

  const [archiveMutation, { loading: archiving }] = useMutation(
    mutationVars[areaType].mutation,
    {
      update(cache, result) {
        // update parent priority area
        if (areaType === 'PriorityArea') {
          // update sibling priority areas
          cache.writeQuery({
            query: PRIORITY_AREAS_PLANNING_QUERY,
            data: {
              priorityAreas:
                result.data.priorityAreaArchive.siblingPriorityAreas,
            },
            variables: {
              parentId: dashboard.enableAreaLevels
                ? result.data.priorityAreaArchive.parentPriorityArea?.id
                : undefined,
              filterIsArchived: false,
            },
          });
          // update sibling priority areas archived
          cache.writeQuery({
            query: PRIORITY_AREAS_PLANNING_QUERY,
            data: {
              priorityAreas:
                result.data.priorityAreaArchive.siblingPriorityAreasArchived,
            },
            variables: {
              parentId: dashboard.enableAreaLevels
                ? result.data.priorityAreaArchive.parentPriorityArea?.id
                : undefined,
              filterIsArchived: true,
            },
          });

          // update parent priority area
          cache.writeQuery({
            query: PRIORITY_AREA_PLANNING_QUERY,
            data: {
              priorityArea: result.data.priorityAreaArchive.parentPriorityArea,
            },
            variables: {
              priorityAreaId:
                result.data.priorityAreaArchive.parentPriorityArea?.id,
            },
          });

          // update user context
          cache.writeQuery({
            query: CONTEXT_USER_QUERY,
            data: {
              user: result.data.priorityAreaArchive.user,
            },
          });
        } else {
          const { filterIsArchived, ...restListVariables } = listVariables;
          // update list of interventions
          cache.writeQuery({
            query: INTERVENTIONS_PLANNING_QUERY,
            data: {
              interventions: result.data.interventionArchive.interventions,
            },
            variables: {
              priorityAreaId: result.data.interventionArchive.priorityArea?.id,
              filterIsArchived: false,
              ...restListVariables,
            },
          });
          // update list of archived interventions
          cache.writeQuery({
            query: INTERVENTIONS_PLANNING_QUERY,
            data: {
              interventions:
                result.data.interventionArchive.interventionsArchived,
            },
            variables: {
              priorityAreaId: result.data.interventionArchive.priorityArea?.id,
              filterIsArchived: true,
              ...restListVariables,
            },
          });

          // update interventions list in priority area
          cache.writeQuery({
            query: PRIORITY_AREA_PLANNING_QUERY,
            data: {
              priorityArea: result.data.interventionArchive.priorityArea,
            },
            variables: {
              priorityAreaId: result.data.interventionArchive.priorityArea?.id,
            },
          });

          // update user context
          cache.writeQuery({
            query: CONTEXT_USER_QUERY,
            data: {
              user: result.data.interventionArchive.user,
            },
          });
        }
      },
    }
  );

  return { archiveMutation, archiving };
}
