import React from 'react';

import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import AddIcon from '@mui/icons-material/Add';

import { DraggableDialogDetailInput } from './detail-input';

export function DraggableDialogAdd({
  loadingData,
  addLabel,
  createMutation,
  includeColorPicker,
  includeWeight,
  includeCategory,
}: any) {
  const [isEditing, setIsEditing] = React.useState(false);

  return isEditing ? (
    <Card
      variant="outlined"
      sx={{
        mb: 2,
        py: 1,
        px: 1.5,
        bgColor: loadingData ? 'grey.200' : 'white',
      }}
    >
      <DraggableDialogDetailInput
        setIsEditing={setIsEditing}
        createMutation={createMutation}
        includeColorPicker={includeColorPicker}
        includeWeight={includeWeight}
        includeCategory={includeCategory}
      />
    </Card>
  ) : (
    <Button
      startIcon={<AddIcon />}
      onClick={() => setIsEditing(true)}
      fullWidth
      sx={{ justifyContent: 'flex-start' }}
    >
      {addLabel}
    </Button>
  );
}
