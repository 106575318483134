import React from 'react';

import Button from '@mui/material/Button';

import AddIcon from '@mui/icons-material/Add';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import { ContentDialogContext } from '../../_lib/context/content-dialog-context';

export function NavbarAppBarAdd() {
  const { setAnchorElAddMenu } = React.useContext(ContentDialogContext);
  return (
    <Button
      color="inherit"
      sx={{ minWidth: 0 }}
      onClick={(event) => setAnchorElAddMenu(event.currentTarget)}
    >
      <AddIcon />
      <ArrowDropDownIcon fontSize="small" />
    </Button>
  );
}
