import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import InputAdornment from '@mui/material/InputAdornment';
import LoadingButton from '@mui/lab/LoadingButton';

import PersonIcon from '@mui/icons-material/Person';
import BadgeIcon from '@mui/icons-material/Badge';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';

import { DialogTitle } from '../DialogTitle/dialog-title';

import { useDashboardContext } from '../_lib/context/dashboard-context';

import { DASHBOARD_CREATE } from '../_lib/graphql/mutations';
import { logError } from '../_lib/error';
import { isValidEmail } from '../_utils/general-utils';

export function CreateDashboardDialog({ onClose, open }: any) {
  const { t } = useTranslation();

  const { user, setSnackbarOpen, setSnackbarMessage, setSnackbarIsError } =
    useDashboardContext();

  const languages = React.useMemo(
    () => [
      { name: t('English'), code: 'en' },
      { name: t('French'), code: 'fr' },
      { name: t('Portuguese'), code: 'pt' },
      { name: t('Serbian'), code: 'sr' },
      { name: t('Swahili'), code: 'sw' },
    ],
    [t]
  );

  const [newDashboard, setNewDashboard] = React.useState<any>({
    language: languages[0].code,
    mode: 'FULL',
    // if not super user add default admin name, position, and email
    ...(user.isSuperuser
      ? {
          adminName: '',
          adminPosition: '',
          adminEmail: '',
        }
      : {
          adminName: user.name,
          adminPosition: user.settings?.position || 'Admin',
          adminEmail: user.email,
        }),
  });

  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState<any>(null);

  const [createDashboardMutation] = useMutation(DASHBOARD_CREATE);

  const handleConfirm = () => {
    // check if all required fields are filled
    if (
      !newDashboard.name ||
      (!!newDashboard.adminEmail && !isValidEmail(newDashboard.adminEmail))
    ) {
      setError({
        name: !newDashboard.name ? t('Name is required') : '',
        adminEmail:
          !!newDashboard.adminEmail && !isValidEmail(newDashboard.adminEmail)
            ? t('Please enter a valid email')
            : '',
      });
      return;
    }

    setError(null);
    setLoading(true);

    createDashboardMutation({ variables: newDashboard })
      .then(() => {
        setSnackbarMessage(t('Dashboard created successfully.'));
        setSnackbarOpen(true);
        window.localStorage.clear();
        window.location.reload();
      })
      .catch((err) => {
        setLoading(false);
        setSnackbarIsError(true);
        setSnackbarOpen(true);
        logError(err);
      });
  };

  return (
    <Dialog id="create-dashboard-form" maxWidth="xs" fullWidth open={open}>
      <DialogTitle onClose={onClose}>{t('Create Dashboard')}</DialogTitle>

      <DialogContent dividers>
        <Box sx={{ mt: 1 }}>
          <Stack spacing={3}>
            <TextField
              label={t('Name')}
              value={newDashboard.name || ''}
              onChange={(event) =>
                setNewDashboard({
                  ...newDashboard,
                  name: event.target.value,
                })
              }
              id="dashboard-name-input"
              fullWidth
              required
              disabled={loading}
              error={!!error?.name}
              helperText={error?.name}
            />

            <FormControl fullWidth required size="small" disabled={loading}>
              <InputLabel id="demo-simple-select-label">
                {t('Language')}
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={newDashboard.language}
                label={t('Language')}
                onChange={(event) =>
                  setNewDashboard({
                    ...newDashboard,
                    language: event.target.value,
                  })
                }
                required
                size="small"
              >
                {languages.map((l) => (
                  <MenuItem key={l.code} value={l.code}>
                    {l.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl fullWidth disabled={loading}>
              <InputLabel id="dashboard-mode-select-label">
                {t('Dashboard Type')}
              </InputLabel>
              <Select
                labelId="dashboard-mode-select-label"
                id="dashboard-mode-select"
                value={newDashboard.mode}
                label={t('Dashboard Type')}
                onChange={(event) => {
                  setNewDashboard({
                    ...newDashboard,
                    mode: event.target.value,
                  });
                }}
              >
                <MenuItem value="FULL">{t('Full Dashboard')}</MenuItem>
                <MenuItem value="TRACKER">
                  {t('Activity Tracker Only')}
                </MenuItem>
                <MenuItem value="SUPRANATIONAL">
                  {t('Dashboard of Dashboards')}
                </MenuItem>
              </Select>
            </FormControl>

            {/* admin details */}
            {user.isSuperuser && (
              <>
                <Divider />

                <TextField
                  label="Admin Name"
                  value={newDashboard.adminName || ''}
                  onChange={(event) =>
                    setNewDashboard({
                      ...newDashboard,
                      adminName: event.target.value,
                    })
                  }
                  fullWidth
                  size="small"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <PersonIcon fontSize="small" />
                      </InputAdornment>
                    ),
                  }}
                  disabled={loading}
                />

                <TextField
                  label="Admin Position"
                  value={newDashboard.adminPosition || ''}
                  onChange={(event) =>
                    setNewDashboard({
                      ...newDashboard,
                      adminPosition: event.target.value,
                    })
                  }
                  fullWidth
                  size="small"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <BadgeIcon fontSize="small" />
                      </InputAdornment>
                    ),
                  }}
                  disabled={loading}
                />

                <TextField
                  label="Admin Email"
                  value={newDashboard.adminEmail || ''}
                  onChange={(event) =>
                    setNewDashboard({
                      ...newDashboard,
                      adminEmail: event.target.value,
                    })
                  }
                  fullWidth
                  InputProps={{
                    inputProps: {
                      type: 'email',
                    },
                    startAdornment: (
                      <InputAdornment position="start">
                        <AlternateEmailIcon fontSize="small" />
                      </InputAdornment>
                    ),
                  }}
                  size="small"
                  disabled={loading}
                  error={!!error?.adminEmail}
                  helperText={error?.adminEmail}
                />
              </>
            )}
          </Stack>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={() => onClose()} disabled={loading}>
          {t('Cancel')}
        </Button>
        <LoadingButton
          variant="contained"
          onClick={() => handleConfirm()}
          loading={loading}
        >
          {t('Save')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
