import React from 'react';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import { MemberRemoveConfirmDialog } from './remove-confirm';
import { useDashboardContext } from '../../../_lib/context/dashboard-context';
import { logError } from '../../../_lib/error';

import { daysPast, getStringDateTime } from '../../../_utils/general-utils';

import { CONTEXT_USERS_QUERY } from '../../../_lib/graphql/queries';
import { MEMBER_REMOVE, MEMBER_UPDATE } from '../../../_lib/graphql/mutations';

export function MemberRow({ i, userSetting }: any) {
  const { t } = useTranslation();

  const { dashboard, setSnackbarOpen, setSnackbarIsError } =
    useDashboardContext();

  const [editMode, setEditMode] = React.useState(false);

  const [userEdit, setUserEdit] = React.useState<any>({
    ...userSetting,
  });
  const [loading, setLoading] = React.useState(false);

  const [openRemoveConfirmDialog, setShowRemoveConfirmDialog] =
    React.useState(false);

  const [memberUpdateMutation] = useMutation(MEMBER_UPDATE, {
    update(cache, { data: { memberUpdate } }) {
      cache.writeQuery({
        query: CONTEXT_USERS_QUERY,
        data: { users: memberUpdate.users },
      });
    },
  });

  const [memberRemoveMutation] = useMutation(MEMBER_REMOVE, {
    update(cache, { data: { memberRemove } }) {
      cache.writeQuery({
        query: CONTEXT_USERS_QUERY,
        data: { users: memberRemove.users },
      });
    },
  });

  // -------------------------------------------------------------------------------------------------
  // -------------------------------------------------------------------------------------------------
  // handlers
  // -------------------------------------------------------------------------------------------------

  const handleRemove = () => {
    setShowRemoveConfirmDialog(true);
  };
  const handleRemoveConfirmDialogClose = () => {
    setShowRemoveConfirmDialog(false);
  };
  const handleRemoveConfirmed = () => {
    setLoading(true);
    memberRemoveMutation({
      variables: { settingId: userSetting.userSettingsId },
    })
      .then(() => {
        setSnackbarOpen(true);
      })
      .catch((err) => {
        setLoading(false);
        setSnackbarIsError(true);
        setSnackbarOpen(true);
        logError(err);
      });
  };

  const handleSave = () => {
    setLoading(true);
    memberUpdateMutation({
      variables: {
        settingId: userSetting.userSettingsId,
        name: userEdit.name,
        position: userEdit.position,
        isAdmin: userEdit.isAdmin,
        hasDashboardViewPermission: userEdit.hasDashboardViewPermission,
      },
    })
      .then(() => {
        setEditMode(false);
      })
      .catch((err) => {
        logError(err);
        setSnackbarIsError(true);
      })
      .finally(() => {
        setLoading(false);
        setSnackbarOpen(true);
      });
  };

  const handleCancel = () => {
    setUserEdit({ ...userSetting });
    setEditMode(false);
  };

  // -------------------------------------------------------------------------------------------------
  // -------------------------------------------------------------------------------------------------
  // effects
  // -------------------------------------------------------------------------------------------------

  // update userEdit when userSetting changes
  React.useEffect(() => {
    if (userSetting && !editMode && !loading) {
      setUserEdit({ ...userSetting });
    }
  }, [editMode, loading, userSetting]);

  // -------------------------------------------------------------------------------------------------

  return (
    <>
      <MemberRemoveConfirmDialog
        userSetting={userSetting}
        open={openRemoveConfirmDialog}
        onClose={handleRemoveConfirmDialogClose}
        handleRemoveConfirmed={handleRemoveConfirmed}
        loading={loading}
      />

      <TableRow>
        <TableCell sx={{ maxWidth: '2rem' }}>{i + 1}.</TableCell>

        {/* name */}
        <TableCell
          sx={{
            minWidth: editMode ? '15rem' : '10rem',
          }}
        >
          {!editMode ? (
            userSetting.name
          ) : (
            <TextField
              value={userEdit.name || ''}
              onChange={(e) =>
                setUserEdit({ ...userEdit, name: e.target.value })
              }
              fullWidth
              size="small"
              disabled={loading}
            />
          )}
        </TableCell>

        {/* position */}
        <TableCell
          sx={{
            minWidth: editMode ? '10rem' : '7rem',
          }}
        >
          {!editMode ? (
            userSetting.position
          ) : (
            <TextField
              value={userEdit.position || ''}
              onChange={(e) =>
                setUserEdit({ ...userEdit, position: e.target.value })
              }
              fullWidth
              size="small"
              disabled={loading}
            />
          )}
        </TableCell>

        {/* email */}
        <TableCell
          sx={{
            maxWidth: '12rem',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        >
          {userSetting.user.email}
        </TableCell>

        {/* last login */}
        <TableCell>
          {!userSetting?.user?.lastLogin && '-'}
          {!!userSetting?.user?.lastLogin && (
            <Tooltip
              title={
                getStringDateTime(
                  userSetting?.user?.lastLogin,
                  dashboard.language
                ) || '-'
              }
              arrow
            >
              <span>
                {daysPast(
                  userSetting?.user?.lastLogin,
                  t,
                  dashboard.language
                ) || '-'}
              </span>
            </Tooltip>
          )}
        </TableCell>

        {/* two factor enabled? */}
        <TableCell sx={{ maxWidth: 'fit-content' }}>
          <Box sx={{ textAlign: 'center' }}>
            <Chip
              color={
                userSetting.user.isTwoFactorEnabled ? 'success' : 'warning'
              }
              size="small"
              variant={
                userSetting.user.isTwoFactorEnabled ? 'filled' : 'outlined'
              }
              label={
                userSetting.user.isTwoFactorEnabled
                  ? t('Enabled')
                  : t('Disabled')
              }
              icon={
                userSetting.user.isTwoFactorEnabled ? (
                  <LockIcon />
                ) : (
                  <LockOpenIcon />
                )
              }
            />
          </Box>
        </TableCell>

        {/* is admin */}
        <TableCell>
          <Box sx={{ textAlign: 'center' }}>
            <Checkbox
              inputProps={{ 'aria-label': 'is admin' }}
              disabled={!editMode}
              checked={userEdit.isAdmin}
              onChange={(e: any) =>
                setUserEdit({ ...userEdit, isAdmin: e.target.checked })
              }
            />
          </Box>
        </TableCell>

        {/* has dashboard view permission */}
        <TableCell>
          <Box sx={{ textAlign: 'center' }}>
            <Checkbox
              inputProps={{ 'aria-label': 'has dashboard view permission' }}
              disabled={!editMode || userEdit.isAdmin}
              checked={userEdit.isAdmin || userEdit.hasDashboardViewPermission}
              onChange={(e: any) =>
                setUserEdit({
                  ...userEdit,
                  hasDashboardViewPermission: e.target.checked,
                })
              }
            />
          </Box>
        </TableCell>

        {/* actions */}
        <TableCell>
          <Stack direction="row" spacing={1}>
            {loading ? (
              <CircularProgress size={20} color="inherit" />
            ) : !editMode ? (
              <>
                <IconButton
                  aria-label={`${t('edit')}`}
                  size="small"
                  onClick={() => setEditMode(!editMode)}
                  disabled={loading}
                >
                  <EditIcon fontSize="inherit" />
                </IconButton>

                <IconButton
                  aria-label={`${t('remove member')}`}
                  size="small"
                  onClick={
                    !userSetting.isFromParent ? () => handleRemove() : () => {}
                  }
                  disabled={loading}
                >
                  <Tooltip
                    title={`${
                      !userSetting.isFromParent
                        ? t('Remove member')
                        : t("Can't remove this member")
                    }`}
                    arrow
                  >
                    <DeleteIcon
                      fontSize="inherit"
                      color={userSetting.isFromParent ? 'disabled' : 'inherit'}
                    />
                  </Tooltip>
                </IconButton>
              </>
            ) : (
              <>
                <Button
                  size="small"
                  variant="contained"
                  color="primary"
                  disableElevation
                  onClick={() => handleSave()}
                >
                  {t('Save')}
                </Button>
                <Button size="small" onClick={() => handleCancel()}>
                  {t('Cancel')}
                </Button>
              </>
            )}
          </Stack>
        </TableCell>
      </TableRow>
    </>
  );
}
