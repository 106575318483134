import React from 'react';

export const ContentDialogContext = React.createContext<any>('');

export function ContentDialogProvider({ children }: any) {
  // data
  const [contentId, setContentId] = React.useState<any>(null);
  const [content, setContent] = React.useState<any>(null);

  const [contentForm, setContentForm] = React.useState<any>(null);

  const [anchorElAddMenu, setAnchorElAddMenu] = React.useState<any>(null);

  // context
  const contextValue = React.useMemo(
    () => ({
      contentId,
      setContentId,
      content,
      setContent,

      contentForm,
      setContentForm,

      anchorElAddMenu,
      setAnchorElAddMenu,
    }),
    [contentId, content, contentForm, anchorElAddMenu]
  );

  return (
    <ContentDialogContext.Provider value={contextValue}>
      {children}
    </ContentDialogContext.Provider>
  );
}

export function useContentDialogContext() {
  const context = React.useContext(ContentDialogContext);
  if (context === undefined) {
    throw new Error(
      'useContentDialogContext must be used within a ContentDialogProvider'
    );
  }
  return context;
}
