import React from 'react';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';

import Link from '@mui/material/Link';
import { DashboardContext } from '../../_lib/context/dashboard-context';

import { removeCommas, withCommas } from '../../_utils/general-utils';
import { CurrencySymbol } from '../../components/currency-text';

import { DeleteFinancier } from '../../actions/delete-financier';
import { PlanningFieldsInputFinancier } from '../fields-input/financier';
import { PlanningFieldsInputFinancierMethods } from '../fields-input/financier-methods';
import { CurrencyChangeDialog } from '../../components/currency-dialog';

export function FinancierInput({
  index,
  financier,
  disabled,

  newFinanciers,
  setNewFinanciers,

  area,
  setArea,

  errors,
}: any) {
  const { t } = useTranslation();

  const { dashboard } = React.useContext(DashboardContext);

  const [openCurrencyDialog, setOpenCurrencyDialog] = React.useState(false);

  const [amountStates, setAmountStates] = React.useState<any>({
    amountDb: 'mouseout',
    amountSpentDb: 'mouseout',
  });

  const financierAmountState = {
    editing: financier.amountDb,
    mouseout: withCommas(parseFloat(financier.amountDb), dashboard.language, 2),
  } as any;

  const amountSpentState = {
    editing: financier.amountSpentDb,
    mouseout: withCommas(
      parseFloat(financier.amountSpentDb || 0),
      dashboard.language,
      2
    ),
  } as any;

  // -----------------------------------------------------------------------------------------------------------------
  // -----------------------------------------------------------------------------------------------------------------
  // handlers
  // -----------------------------------------------------------------------------------------------------------------

  const changeFinanciers = (value: any, key: any) => {
    if (newFinanciers) {
      const financiersUpdated = structuredClone(newFinanciers);
      financiersUpdated[index][key] = value;
      setNewFinanciers(financiersUpdated);
    } else {
      const financiersUpdated = structuredClone(area.financiersDb);
      financiersUpdated[index][key] = value;
      setArea({ ...area, financiersDb: financiersUpdated });
    }
  };

  const handleClick = (e: React.MouseEvent) => {
    e.preventDefault();
    setOpenCurrencyDialog(true);
  };

  const handleSelectedValue = (value: string) => {
    changeFinanciers(value, 'currency');
    setOpenCurrencyDialog(false);
  };

  const handleFieldFocus = (field: any) => {
    if (field.type === 'currency') {
      if (
        [dashboard.customFinanceSpentLabel, t('Spent')].includes(field.label)
      ) {
        setAmountStates((old: any) => ({ ...old, amountSpentDb: 'editing' }));
      }
      if (field.label === 'Amount') {
        setAmountStates((old: any) => ({ ...old, amountDb: 'editing' }));
      }
    }
  };

  const handleFieldBlur = (field: any) => {
    if (field.type === 'currency') {
      if (
        [dashboard.customFinanceSpentLabel, t('Spent')].includes(field.label)
      ) {
        setAmountStates((old: any) => ({ ...old, amountSpentDb: 'mouseout' }));
      }
      if (field.label === 'Amount') {
        setAmountStates((old: any) => ({ ...old, amountDb: 'mouseout' }));
      }
    }
  };

  const fields = [
    {
      label: 'Name',
      value: financier.financier?.id,
      component: (
        <Box sx={{ minWidth: '200px' }}>
          <PlanningFieldsInputFinancier
            disabled={disabled}
            label={t('Financier Name')}
            area={{
              financiers: [financier.financier],
            }}
            setArea={(fakeArea: any) => {
              changeFinanciers(fakeArea.financiers[0], 'financier');
            }}
            error={
              newFinanciers
                ? errors[`newFinanciers[${index}].financier`]
                : errors[`financiers[${index}].financier`]
            }
          />
        </Box>
      ),
    },
    {
      label: 'Finance Method',
      value: financier.financeMethod?.id,
      component: (
        <Box sx={{ minWidth: '200px' }}>
          <PlanningFieldsInputFinancierMethods
            disabled={disabled}
            label={t('Finance Method')}
            area={{
              financeMethods: [financier.financeMethod],
            }}
            setArea={(fakeArea: any) => {
              changeFinanciers(fakeArea.financeMethods[0], 'financeMethod');
            }}
            error={
              newFinanciers
                ? errors[`newFinanciers[${index}].financier`]
                : errors[`financiers[${index}].financier`]
            }
            methodType="finance"
          />
        </Box>
      ),
    },
    {
      label: 'Procurement Method',
      value: financier.procurementMethod?.id,
      component: (
        <Box sx={{ minWidth: '200px' }}>
          <PlanningFieldsInputFinancierMethods
            disabled={disabled}
            label={t('Procurement Method')}
            area={{
              procurementMethods: [financier.procurementMethod],
            }}
            setArea={(fakeArea: any) => {
              changeFinanciers(
                fakeArea.procurementMethods[0],
                'procurementMethod'
              );
            }}
            error={
              newFinanciers
                ? errors[`newFinanciers[${index}].financier`]
                : errors[`financiers[${index}].financier`]
            }
            methodType="procurement"
          />
        </Box>
      ),
    },
    {
      type: 'currency',
      label: 'Amount',
      value: financierAmountState[amountStates.amountDb] || 0,
      onChange: (e: any) => {
        changeFinanciers(removeCommas(e.target.value), 'amountDb');
      },
    },
  ] as any;

  if (dashboard.enableFinancierAmountSpent) {
    fields.push({
      type: 'currency',
      label: dashboard.enableCustomFinanceLabels
        ? dashboard.customFinanceSpentLabel
        : t('Spent'),
      value: amountSpentState[amountStates.amountSpentDb] || 0,
      onChange: (e: any) => {
        changeFinanciers(removeCommas(e.target.value), 'amountSpentDb');
      },
    });
  }

  fields.push({
    label: 'Delete',
    component: (
      <>
        {/* current financiers */}
        {!!financier.id && !disabled && (
          <DeleteFinancier
            financier={financier}
            area={area}
            setArea={setArea}
          />
        )}

        {/* new financiers */}
        {!financier.id && !disabled && (
          <DeleteFinancier
            financier={financier}
            newFinanciers={newFinanciers}
            setNewFinanciers={setNewFinanciers}
          />
        )}
      </>
    ),
  });

  // -----------------------------------------------------------------------------------------------------------------

  return (
    <>
      {/* Set currency for intervention dialog */}
      {dashboard.enableMultipleCurrencies && (
        <CurrencyChangeDialog
          open={openCurrencyDialog}
          setOpen={setOpenCurrencyDialog}
          setValue={handleSelectedValue}
          currency={financier.currency || dashboard.currency}
        />
      )}

      <Stack spacing={1.5} direction={{ xs: 'column', sm: 'row' }}>
        {fields.map((field: any) =>
          field.component ? (
            React.cloneElement(field.component, {
              key: field.label,
            })
          ) : (
            <Stack key={field.label}>
              <TextField
                disabled={disabled}
                label={t(field.label)}
                value={field.value || ''}
                onFocus={() => handleFieldFocus(field)}
                onBlur={() => handleFieldBlur(field)}
                onChange={field.onChange}
                size="small"
                sx={{ flexGrow: 1 }}
                InputProps={{
                  inputProps: {
                    inputMode: field.type === 'currency' ? 'numeric' : 'text',
                    pattern: field.type === 'currency' ? '[0-9]*' : undefined,
                    style: {
                      textAlign: field.type === 'currency' ? 'right' : 'left',
                    },
                  },
                  startAdornment:
                    field.type === 'currency' ? (
                      <InputAdornment
                        position="start"
                        sx={{ color: disabled && 'text.disabled' }}
                      >
                        <CurrencySymbol
                          currency={
                            dashboard.enableMultipleCurrencies
                              ? financier.currency
                              : null
                          }
                        />
                      </InputAdornment>
                    ) : undefined,
                }}
              />
              {dashboard.enableMultipleCurrencies &&
                !disabled &&
                field.label === 'Amount' && (
                  <Link
                    fontSize=".8rem"
                    href="#change-currency"
                    onClick={handleClick}
                  >
                    {t('Change currency')}
                  </Link>
                )}
            </Stack>
          )
        )}
      </Stack>
    </>
  );
}
