import Box from '@mui/material/Box';
import Breadcrumbs from '@mui/material/Breadcrumbs';

export function BreadcrumbLayout({ id, children }: any) {
  return (
    <Box sx={{ my: 2 }}>
      <Breadcrumbs
        aria-label="breadcrumb"
        id={id}
        sx={{
          bgcolor: 'grey.300',
          borderRadius: 1,
          px: 2,
          py: 1,
          '& .MuiBreadcrumbs-ol': {
            flexWrap: 'nowrap',
          },
          '& .MuiBreadcrumbs-li, & .MuiBreadcrumbs-li .MuiTypography-root ': {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          },
          '& .MuiBreadcrumbs-li:first-of-type': {
            minWidth: 'fit-content',
          },
        }}
      >
        {children}
      </Breadcrumbs>
    </Box>
  );
}
