import React from 'react';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import {
  TextField,
  FormControlLabel,
  Checkbox,
  Link,
  Grid,
  Box,
  Typography,
  Paper,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';

import { useAuth } from '../../_utils/hooks/use-auth';
import { logError } from '../../_lib/error';

import { AuthScreenLayout } from '../../auth/_layout';

import { AUTH_IS_DEMO_SITE_QUERY } from '../../_lib/graphql/queries';

interface AuthError {
  failedAuth: boolean;
  lockedAuth: boolean;
  netError: boolean;
  other: boolean;
  message?: string | null;
}

export function ScreenAuthLogin() {
  const { t } = useTranslation();
  const { signIn } = useAuth();
  const history = useHistory();

  const AUTH_ERR_MSG = React.useMemo(
    () => ({
      WRONG_CREDENTIALS: `${t(
        `Incorrect email or password. Please try again`
      )} ${t('or')} <a href="/auth/forgot">${t('reset your password')}</a>.`,
      LOCKED_OUT: t(
        `Too many failed attempts, account locked out for 10 minutes.`
      ),
      OTHER_ERROR: t(`Something went wrong. Please try again.`),
      NET_ERROR: t(
        `Could not sign you in. Ensure you are connected to the internet.`
      ),
      TWO_FACTOR_REQUIRED: t(`Two-factor authentication required.`),
    }),
    [t]
  );

  const initialAuthErrorState: AuthError = {
    failedAuth: false,
    lockedAuth: false,
    netError: false,
    other: false,
    message: '',
  };
  const initialFormErrorState = {
    email: null,
    password: null,
  } as any;

  const [loading, setLoading] = React.useState(true);
  const [authError, setAuthError] = React.useState<AuthError>(
    initialAuthErrorState
  );
  const [formError, setFormError] = React.useState(initialFormErrorState);
  const [demoEmail, setDemoEmail] = React.useState(
    'digitaldashboard+demo.su@institute.global'
  );
  const [demoPassword, setDemoPassword] = React.useState('tbidashboard');

  // Demo credentials
  const { data: dataIsDemoSiteQuery, error: errorAuthIsDemoSite } = useQuery(
    AUTH_IS_DEMO_SITE_QUERY
  );

  // ----------------------------------------------------------------------------------------
  // ----------------------------------------------------------------------------------------
  // handlers
  // ----------------------------------------------------------------------------------------

  // Validate the data
  const valid = (data: any) => {
    const newErrors = {} as any;
    if (!data.get('email')) {
      newErrors.email = true;
      setFormError(newErrors);
    }
    if (!data.get('password')) {
      newErrors.password = true;
      setFormError(newErrors);
    }
    return !newErrors.email && !newErrors.password;
  };

  // Handle login
  const handleLogin = (data: any) => {
    if (valid(data)) {
      if (formError.email || formError.password) {
        setFormError(initialFormErrorState);
      }
      setLoading(true);
      signIn(data.get('email'), data.get('password'))
        .then((r: any) => {
          if (!r?.data?.authLogin?.success) {
            setAuthError((old) => ({
              ...old,
              failedAuth: true,
              message: AUTH_ERR_MSG.WRONG_CREDENTIALS,
            }));
            setLoading(false);
          } else if (r?.data?.authLogin?.user?.isTwoFactorEnabled) {
            // navigate to OTP page /auth/two-factor-auth/
            history.push(`/auth/two-factor-auth/${window.location.search}`);
          } else {
            // check if next is set
            const next = new URLSearchParams(window.location.search).get(
              'next'
            );

            // what next
            if (next) {
              if (!next.startsWith(window.location.origin)) {
                window.open(next, '_self');
              } else {
                window.location.href = next;
              }
            } else {
              window.location.href = '/';
            }
          }
        })
        .catch((err: any) => {
          const { message, networkError } = err;

          const result = `${networkError?.result} ${message}`;

          if (result) {
            if (result.toLowerCase().includes('two-factor')) {
              // two factor
              history.push(`/auth/two-factor-auth/${window.location.search}`);
              return;
            }
            if (
              result.toLowerCase().includes('network') ||
              result.toLowerCase().includes('failed to fetch')
            ) {
              // network
              setAuthError({
                ...initialAuthErrorState,
                netError: true,
                message: AUTH_ERR_MSG.NET_ERROR,
              });
            } else if (result.toLowerCase().includes('account lo')) {
              // locked out
              setAuthError({
                ...initialAuthErrorState,
                lockedAuth: true,
                message: AUTH_ERR_MSG.LOCKED_OUT,
              });
            } else {
              // other
              setAuthError({
                ...initialAuthErrorState,
                other: true,
                message: `${AUTH_ERR_MSG.OTHER_ERROR} ${result}`,
              });
            }
          } else {
            setAuthError({
              ...initialAuthErrorState,
              other: true,
              message: err.toString(),
            });
          }
          logError(err);
          setLoading(false);
        });
    }
  };

  // Handle form submit
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setAuthError(initialAuthErrorState);
    const data = new FormData(event.currentTarget);
    handleLogin(data);
  };

  // ----------------------------------------------------------------------------------------
  // ----------------------------------------------------------------------------------------
  // effects
  // ----------------------------------------------------------------------------------------

  React.useEffect(() => {
    if (dataIsDemoSiteQuery && dataIsDemoSiteQuery.authIsDemoSite) {
      setLoading(false);
      setDemoEmail(dataIsDemoSiteQuery.authIsDemoSite.email);
      setDemoPassword(dataIsDemoSiteQuery.authIsDemoSite.password);
    }
  }, [dataIsDemoSiteQuery]);

  React.useEffect(() => {
    if (errorAuthIsDemoSite) {
      logError(errorAuthIsDemoSite);
    }
  }, [errorAuthIsDemoSite]);

  // ----------------------------------------------------------------------------------------

  return (
    <AuthScreenLayout demoEmail={demoEmail} demoPassword={demoPassword}>
      <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
        {/* email */}
        <TextField
          margin="normal"
          required
          fullWidth
          id="email"
          label={t('Email Address')}
          name="email"
          type="email"
          autoComplete="email"
          autoFocus
          disabled={loading}
          error={
            (!!formError && !!authError.message?.length) ||
            (!!formError && !!formError.email)
          }
          defaultValue={
            process.env.REACT_APP_IS_DEMO_SITE ? demoEmail : undefined
          }
          key={process.env.REACT_APP_IS_DEMO_SITE ? demoEmail : undefined}
        />

        {/* password */}
        <TextField
          margin="normal"
          required
          fullWidth
          name="password"
          label={t('Password')}
          type="password"
          id="password"
          autoComplete="current-password"
          disabled={loading}
          error={
            (!!formError && !!authError.message?.length) ||
            (!!formError && !!formError.password)
          }
          defaultValue={
            process.env.REACT_APP_IS_DEMO_SITE ? demoPassword : undefined
          }
          key={process.env.REACT_APP_IS_DEMO_SITE ? demoPassword : undefined}
        />

        {/* remember me */}
        <FormControlLabel
          control={<Checkbox value="remember" color="primary" />}
          label={t('Remember me')}
          disabled={loading}
        />

        {/* error */}
        {!!authError.message && (
          <Paper
            variant="outlined"
            sx={{
              backgroundColor: '#ffcdd2',
              borderColor: '#c62828',
              py: 1,
              px: 2,
              mt: 2,
            }}
          >
            <Typography variant="body2">
              {!!formError.email || !!formError.password ? (
                t(`Email and password cannot be empty.`)
              ) : Object.values(authError).some((a: any) => a) ? (
                <span dangerouslySetInnerHTML={{ __html: authError.message }} />
              ) : null}
            </Typography>
          </Paper>
        )}

        {/* sign in button */}
        <LoadingButton
          type="submit"
          fullWidth
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
          loading={loading}
          disableElevation
        >
          {t('Sign In')}
        </LoadingButton>

        {/* forgot password */}
        <Grid container>
          <Grid item xs>
            <Link component={RouterLink} to="/auth/forgot" variant="body2">
              {t('Forgot password?')}
            </Link>
          </Grid>
        </Grid>
      </Box>
    </AuthScreenLayout>
  );
}
