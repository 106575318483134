import React from 'react';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';

export function DashboardFormDetailsPermissions({
  disabled,
  newDashboard,
  setNewDashboard,
}: any) {
  const { t } = useTranslation();

  return (
    <Box>
      <FormControl
        fullWidth
        disabled={disabled}
        size="small"
        sx={{
          '& .MuiListItemText-root *': {
            textOverflow: 'ellipsis',
            overflow: 'hidden',
          },
        }}
      >
        <InputLabel id="dashboard-permissions-select-label">
          {t('Activity Edit Permissions Level')}
        </InputLabel>
        <Select
          labelId="dashboard-permissions-select-label"
          id="dashboard-permissions-select"
          label={t('Activity Edit Permissions Level')}
          value={newDashboard.permContentEditLevel}
          onChange={(e: any) =>
            setNewDashboard({
              ...newDashboard,
              permContentEditLevel: e.target.value,
            })
          }
        >
          <MenuItem value="ADMIN">
            <ListItemText
              primary={t('Administrators')}
              secondary={t(
                'Administrators always have full access to all data on this dashboard.'
              )}
            />
          </MenuItem>
          <MenuItem value="PRIORITY_AREA">
            <ListItemText
              primary={`${newDashboard.priorityAreaName} ${t(
                'Leads and Co-Leads'
              )}`}
              secondary={`${newDashboard.priorityAreaName} ${t(
                'leads and co-ceads will be able to edit and approve completion of activities.'
              )}`}
            />
          </MenuItem>
          <MenuItem value="INTERVENTION">
            <ListItemText
              primary={`${newDashboard.interventionName} ${t(
                'Leads and Co-Leads'
              )}`}
              secondary={`${newDashboard.interventionName} ${t(
                'leads and co-ceads will be able to edit and approve completion of activities.'
              )}`}
            />
          </MenuItem>
          <MenuItem value="CONTENT">
            <ListItemText
              primary={t('Activity Authors and Assignees')}
              secondary={t(
                'Activity authors and assignees will be able to edit and approve completion of activities.'
              )}
            />
          </MenuItem>
        </Select>
        <FormHelperText>
          {t(
            'Permission level to allow edit and approve completion of activities.'
          )}
        </FormHelperText>
      </FormControl>
    </Box>
  );
}
