import { PriorityArea } from './executive-summary.types';

export const checkAndFallbackString = ({
  values,
  separator,
  fallback,
}: {
  values: string[];
  separator?: string;
  fallback?: (value: any) => string;
}): string => {
  let str = '';
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < values?.length; i++) {
    if (values[i]) {
      str += (values[i] || '') + (i < values.length - 1 ? separator : '');
    }
  }

  if (!str) {
    return fallback?.(values) || '';
  }

  return str;
};

export const formatExecutiveSummaryTableRowData = ({
  item,
  fallbackValue,
}: {
  item: any;
  fallbackValue: string;
}) => {
  return {
    title: item?.title || fallbackValue,
    lead: {
      name: item?.priorityArea?.leads?.[0]?.name || fallbackValue,
    },
    urlRelative: item?.urlRelative || '',
    priorityArea: {
      name: item?.priorityArea?.name || fallbackValue,
      level: {
        name: checkAndFallbackString({
          values: [
            item?.priorityArea?.reference,
            item?.priorityArea?.level?.name,
          ],
          fallback: () => fallbackValue,
          separator: '-',
        }),
      },
    },
  };
};

export function isPriorityArea(
  item: PriorityArea | null | string | (string | PriorityArea)[]
): item is PriorityArea {
  return (item as PriorityArea)?.id !== undefined;
}
