import React from 'react';

import { Document } from '../../../types/react-pdf';

import { PdftTemplatePageOverview } from './pages/overview';
import { PdftTemplatePageArea } from './pages/area';
import { ReportsPdfPagesMembers } from './pages/members';

export function ReportsPdfDocument({
  user,
  isNew,
  report,
  reportData,
  filterAreas,
  filterMembers,
  t,
}: any) {
  const orderedFilterAreas = React.useMemo<any[]>(() => {
    const result = [] as any;

    function pushOrderAreas(pa: any) {
      if (filterAreas.includes(`pa-${pa.id}`)) {
        result.push(`pa-${pa.id}`);
      }

      // children
      pa.children?.forEach((child: any) => {
        if (filterAreas.includes(`pa-${child.id}`)) {
          result.push(`pa-${child.id}`);
        }
      });

      // interventions
      pa.interventionsDb?.forEach((intv: any) => {
        if (filterAreas.includes(`intv-${intv.id}`)) {
          result.push(`intv-${intv.id}`);
        }
      });
    }

    reportData?.priorityAreas.forEach((pa: any) => {
      if (result.includes(`pa-${pa.id}`)) return; // already pushed
      pushOrderAreas(pa);
    });

    return result;
  }, [filterAreas, reportData?.priorityAreas]);

  return (
    <Document
      title={reportData.dashboard.name}
      language={reportData.dashboard.language}
      creator="TBI Digital Delivery Dashboard"
      producer="TBI Digital Delivery Dashboard"
      // author={user.name}
    >
      {filterAreas.indexOf('pa-overview') !== -1 && (
        <PdftTemplatePageOverview
          user={user}
          isNew={isNew}
          report={report}
          reportData={reportData}
          filterAreas={filterAreas}
          t={t}
        />
      )}

      {orderedFilterAreas.map((filterArea: any) => (
        <PdftTemplatePageArea
          key={filterArea}
          filterArea={filterArea}
          user={user}
          isNew={isNew}
          report={report}
          reportData={reportData}
          filterAreas={filterAreas}
          t={t}
        />
      ))}

      {!filterMembers && (
        <ReportsPdfPagesMembers t={t} reportData={reportData} />
      )}
    </Document>
  );
}
