import React from 'react';
import { useParams } from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';

import { useDashboardContext } from '../../../_lib/context/dashboard-context';
import { logError } from '../../../_lib/error';
import { SubscribeToMore } from '../../../components/subscribe-to-more';

import { REPORT_QUERY } from '../../../_lib/graphql/queries';
import { REPORT_SUBSCRIPTION } from '../../../_lib/graphql/subscriptions';
import { useReportsContext } from '../../../_lib/context/reports-context';

const REPORT_ASSETS_QUERY = gql`
  query ReportAssets($id: ID) {
    report(id: $id) {
      id
      pdf {
        id
        url
      }
      csvs {
        id
        url
      }
    }
  }
`;

export function ReportsEditReportDataReport({
  fetchAssets,
}: {
  fetchAssets?: boolean;
}) {
  const { reportId } = useParams() as any;

  const { setSnackbarOpen, setSnackbarIsError } = useDashboardContext();
  const { report, setReport, updateFilterDates, setFilterMembers } =
    useReportsContext();

  const variablesReport = React.useMemo(
    () => ({
      id: reportId,
    }),
    [reportId]
  );

  // report
  const {
    data: dataReport,
    loading: loadingReportQuery,
    error: errorReport,
    refetch: refetchReport,
    subscribeToMore: subscribeToMoreReport,
  } = useQuery(REPORT_QUERY, {
    variables: variablesReport,
  });

  const {
    data: dataReportAssets,
    loading: loadingReportAssetsQuery,
    error: errorReportAssets,
    refetch: refetchReportAssets,
  } = useQuery(REPORT_ASSETS_QUERY, {
    variables: fetchAssets ? variablesReport : undefined,
  });

  // ------------------------------------------------------------------------------------------------------------------------
  // ------------------------------------------------------------------------------------------------------------------------
  // effects
  // ------------------------------------------------------------------------------------------------------------------------

  // report
  React.useEffect(() => {
    if (dataReport) {
      setReport((prev: any) =>
        !prev
          ? dataReport.report || {}
          : {
              ...prev,
              ...dataReport.report,
              csv: {
                ...prev.csv,
                ...dataReport.report.csv,
              },
              pdf: {
                ...prev.pdf,
                ...dataReport.report.pdf,
              },
            }
      );
    }
  }, [dataReport, setReport]);

  // filter dates + members
  React.useEffect(() => {
    if (report) {
      updateFilterDates({
        startDate: report.filterStartDate,
        endDate: report.filterEndDate,
      });
      setFilterMembers(report.filterMembers);
    }
  }, [report, updateFilterDates, setFilterMembers]);

  // assets
  React.useEffect(() => {
    if (dataReportAssets?.report) {
      setReport((prev: any) => ({
        ...prev,
        pdf: dataReportAssets.report.pdf,
        csvs: dataReportAssets.report.csvs,
      }));
    }
  }, [dataReportAssets, setReport]);

  // check if report.pdfs and csv are same as assets
  React.useEffect(() => {
    if (!!dataReport?.report && !!dataReportAssets?.report) {
      const reportCsvIds =
        dataReport.report.csvs?.map((csv: any) => csv.id).sort() || [];
      const assetsCsvIds =
        dataReportAssets.report.csvs?.map((csv: any) => csv.id).sort() || [];
      if (
        dataReport.report.pdf?.id !== dataReportAssets.report.pdf?.id ||
        reportCsvIds.join(',') !== assetsCsvIds.join(',')
      ) {
        refetchReportAssets();
      }
    }
  }, [dataReport, dataReportAssets, refetchReportAssets]);

  // error
  React.useEffect(() => {
    if (errorReport) {
      setSnackbarIsError(true);
      setSnackbarOpen(true);
      logError(errorReport);
    }
  }, [errorReport, setSnackbarIsError, setSnackbarOpen]);

  // refetch if needed
  React.useEffect(() => {
    if (!dataReport && !loadingReportQuery && !errorReport) {
      refetchReport();
    }
  }, [dataReport, loadingReportQuery, errorReport, refetchReport]);

  React.useEffect(() => {
    if (!dataReportAssets && !loadingReportAssetsQuery && !errorReportAssets) {
      refetchReportAssets();
    }
  }, [
    dataReportAssets,
    loadingReportAssetsQuery,
    errorReportAssets,
    refetchReportAssets,
  ]);

  // check if the pdf or csv url is still valid
  React.useEffect(() => {
    if (report?.pdf?.url) {
      fetch(report.pdf.url)
        .then((res) => {
          if (res.status !== 200) {
            refetchReportAssets();
          }
        })
        .catch(() => {
          refetchReportAssets();
        });
    }
  }, [report, refetchReportAssets]);

  // ------------------------------------------------------------------------------------------------------------------------

  if (!dataReport) return null;

  return (
    <SubscribeToMore
      variables={variablesReport}
      subscribeToMore={() =>
        subscribeToMoreReport({
          document: REPORT_SUBSCRIPTION,
          variables: variablesReport,
          updateQuery: (prev, { subscriptionData }) => {
            if (!subscriptionData.data) return prev;
            return subscriptionData.data;
          },
        })
      }
    />
  );
}

ReportsEditReportDataReport.defaultProps = {
  fetchAssets: false,
};
