import React from 'react';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

export function ReportTemplateDialogMetaInputsPageOrientation({
  disabled,
  templateMeta,
  updateTemplateMeta,
}: any) {
  const { t } = useTranslation();

  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth disabled={disabled} size="small">
        <InputLabel id="page-orientation-select-label">
          {t('Page Orientation')}
        </InputLabel>
        <Select
          labelId="page-orientation-select-label"
          id="page-orientation-select"
          value={templateMeta.pageOrientation || 'LANDSCAPE'}
          label={t('Page Orientation')}
          onChange={(e: any) =>
            updateTemplateMeta({ pageOrientation: e.target.value })
          }
        >
          <MenuItem value="LANDSCAPE">{`${t('Landscape')} (${t(
            'Default'
          )})`}</MenuItem>
          <MenuItem value="PORTRAIT">{t('Portrait')}</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
}
