import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ListItem from '@mui/material/ListItem';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';

import { useReportsContext } from '../../../../_lib/context/reports-context';
import { useDashboardContext } from '../../../../_lib/context/dashboard-context';

export function ReportsEditFiltersAreasListPa({
  priorityArea,
  checked,
  open,
  handleOpen,
  handleToggle,
}: any) {
  const { dashboard } = useDashboardContext();
  const { loading } = useReportsContext();

  const labelId = `checkbox-pa-label-${priorityArea.id}`;

  // -------------------------------------------------------------------------------------------------------------

  return (
    <>
      <ListItem
        key={priorityArea.id}
        secondaryAction={
          priorityArea.children?.length ||
          priorityArea.interventionsDb?.length ? (
            <IconButton
              edge="end"
              aria-label="open"
              onClick={() => handleOpen(priorityArea.id)}
            >
              <ExpandMore
                sx={{
                  transition: 'all 0.2s ease-in-out',
                  transform:
                    open.indexOf(priorityArea.id) !== -1
                      ? 'rotate(0deg)'
                      : 'rotate(-90deg)',
                }}
              />
            </IconButton>
          ) : null
        }
        disablePadding
      >
        <ListItemButton
          role={undefined}
          onClick={handleToggle(`pa-${priorityArea.id}`)}
          dense
          disabled={loading}
        >
          <ListItemIcon>
            <Checkbox
              edge="start"
              checked={checked.indexOf(`pa-${priorityArea.id}`) !== -1}
              indeterminate={
                priorityArea.interventionsDb?.length
                  ? !priorityArea.interventionsDb
                      ?.map((i: any) => `intv-${i.id}`)
                      .every((iId: any) => checked.indexOf(iId) === -1) &&
                    checked.indexOf(`pa-${priorityArea.id}`) === -1
                  : false
              }
              tabIndex={-1}
              disableRipple
              inputProps={{ 'aria-labelledby': labelId }}
            />
          </ListItemIcon>
          <ListItemText
            id={labelId}
            primary={`${
              priorityArea.reference ? `${priorityArea.reference} - ` : ''
            }${priorityArea.name}`}
            secondary={
              priorityArea.id !== 'overview'
                ? !dashboard.enableAreaLevels
                  ? dashboard.priorityAreaName
                  : priorityArea.level.name
                : null
            }
          />
        </ListItemButton>
      </ListItem>

      {/* children */}
      <Collapse
        key={`${priorityArea.id}-children`}
        in={open.indexOf(priorityArea.id) !== -1}
        timeout="auto"
        unmountOnExit
      >
        <List component="div" disablePadding>
          {priorityArea.children?.map((child: any) => (
            <ReportsEditFiltersAreasListPa
              key={child.id}
              priorityArea={child}
              checked={checked}
              open={open}
              handleOpen={handleOpen}
              handleToggle={handleToggle}
            />
          ))}
        </List>
      </Collapse>

      {/*  interventions */}
      <Collapse
        key={`${priorityArea.id}-interventions`}
        in={open.indexOf(priorityArea.id) !== -1}
        timeout="auto"
        unmountOnExit
      >
        <List component="div" disablePadding>
          {priorityArea.interventionsDb?.map((intv: any) => {
            const labelIdIntv = `checkbox-intv-label-${intv.id}`;
            return (
              <ListItemButton
                key={intv.id}
                role={undefined}
                onClick={handleToggle(`intv-${intv.id}`)}
                dense
                sx={{ pl: 4 }}
                disabled={loading}
              >
                <ListItemIcon>
                  <Checkbox
                    edge="start"
                    checked={checked.indexOf(`intv-${intv.id}`) !== -1}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ 'aria-labelledby': labelIdIntv }}
                  />
                </ListItemIcon>
                <ListItemText
                  id={labelIdIntv}
                  primary={`${intv.reference ? `${intv.reference} - ` : ''}${
                    intv.name
                  }`}
                  secondary={dashboard.interventionName}
                />
              </ListItemButton>
            );
          })}
        </List>
      </Collapse>
    </>
  );
}
