import React from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ErrorBoundary } from 'react-error-boundary';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import Box from '@mui/material/Box';
import TablePagination from '@mui/material/TablePagination';

import { useDashboardContext } from '../_lib/context/dashboard-context';
import { useLocalStorage } from '../_utils/hooks/use-local-storage';
import { ReactErrorComponent } from '../_lib/react-error';

import { AreasFilter } from '../components/areas-filter';
import { AreaSummaryCard } from '../components/area-summary/card';

import { useAreas } from '../_utils/hooks/queries/use-areas';

export function PriorityAreaChildAreas({
  isIntervention,
}: {
  isIntervention?: boolean;
}) {
  const { t } = useTranslation();
  const { priorityAreaId } = useParams<any>();

  const {
    dashboard: { mode: dashboardMode, interventionName },
    navbarFilterVariables,
  } = useDashboardContext();

  const [page, setPage] = React.useState(0);
  const [itemsPerPage, setItemsPerPage] = React.useState(4);

  const areasFilterLoc = `pa-${priorityAreaId}-${
    isIntervention ? 'intvs' : 'children'
  }`;
  const [searchValue, setSearchValue] = useLocalStorage(
    `areas-search-filter-${areasFilterLoc}`,
    ''
  );
  const [filterCharts, setFilterCharts] = useLocalStorage(
    `filter-charts-${areasFilterLoc}`,
    ['milestones', 'unstarted']
  );

  const variables = React.useMemo(
    () => ({
      filterSearchQuery: searchValue,
      navbarFilterVariables,
      ...(isIntervention
        ? {
            priorityAreaId,
            paginationInterventionsLimit: itemsPerPage,
            paginationInterventionsOffset: page * itemsPerPage,
          }
        : {
            parentId: priorityAreaId,
            paginationPriorityAreasLimit: itemsPerPage,
            paginationPriorityAreasOffset: page * itemsPerPage,
          }),
    }),
    [
      searchValue,
      navbarFilterVariables,
      isIntervention,
      priorityAreaId,
      itemsPerPage,
      page,
    ]
  );

  const { data, error, loading } = useAreas(
    isIntervention ? 'intervention' : 'priorityArea',
    variables
  );

  const children: any = React.useMemo(
    () =>
      (isIntervention
        ? data?.interventions?.items
        : data?.priorityAreas?.items) || [],
    [isIntervention, data?.interventions?.items, data?.priorityAreas?.items]
  );
  const childrenCount = React.useMemo(
    () =>
      (isIntervention
        ? data?.interventions?.meta?.count
        : data?.priorityAreas?.meta?.count) || 0,
    [data, isIntervention]
  );

  const areasName = React.useMemo<any>(
    () =>
      isIntervention
        ? interventionName
        : data?.priorityAreas?.items[0]?.level?.name || '',
    [data, interventionName, isIntervention]
  );

  if (error) return null;

  if (!searchValue && !isIntervention && !children.length && !loading && data)
    return null;

  // --------------------------------------------------------------------------------------------

  return (
    <Card sx={{ my: 3 }}>
      <CardContent sx={{ p: 4 }}>
        <Typography variant="h4">{areasName}</Typography>
        {dashboardMode !== 'SUPRANATIONAL' && (
          <Typography variant="body1" sx={{ mb: 4 }}>
            {t('Click on card for more details')}
          </Typography>
        )}

        {/* filter */}
        <AreasFilter
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          filterCharts={filterCharts}
          setFilterCharts={setFilterCharts}
          placeholder={areasName}
        />

        {(loading || !data) && (
          <Skeleton variant="rectangular" height={500} sx={{ my: 2 }} />
        )}

        {!loading && !!data && !children.length && (
          <Typography
            variant="body1"
            color="textSecondary"
            sx={{ my: 4, textAlign: 'center', fontStyle: 'italic' }}
          >
            {t('No results found')}
          </Typography>
        )}

        {/* children or interventions */}
        <Grid container spacing={2} justifyContent="center" sx={{ my: 2 }}>
          {children?.map((child: any) => (
            <Grid
              key={child.id}
              item
              xs={12}
              sm={4}
              lg={3}
              id={`${
                child.__typename === 'InterventionType'
                  ? 'intervention'
                  : 'child'
              }-${child.id}`}
            >
              <ErrorBoundary FallbackComponent={ReactErrorComponent}>
                <AreaSummaryCard
                  area={child}
                  filterCharts={filterCharts}
                  disableClick={dashboardMode === 'SUPRANATIONAL'}
                  simple
                />
              </ErrorBoundary>
            </Grid>
          ))}
        </Grid>

        {/* pagination */}
        {childrenCount > itemsPerPage && (
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4, mb: 1 }}>
            <TablePagination
              component="div"
              count={childrenCount}
              page={page}
              onPageChange={(e, pg) => setPage(pg)}
              rowsPerPage={itemsPerPage}
              onRowsPerPageChange={(e) =>
                setItemsPerPage(parseInt(e.target.value, 10))
              }
              rowsPerPageOptions={[4, 8, 12]}
            />
          </Box>
        )}
      </CardContent>
    </Card>
  );
}

PriorityAreaChildAreas.defaultProps = {
  isIntervention: false,
};
