import Grid from '@mui/material/Grid';
import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import ExecutiveSummaryPriorityAreaBreakdownPanel from './executive-summary-priority-area-breakdown-panel';
import ExecutiveSummaryPriorityAreaListPanel from './executive-summary-priority-area-list-panel';
import { PriorityArea } from '../executive-summary/executive-summary.types';
import { ExecutiveSummaryBreakdownContainerGrid } from './executive-summary-breakdown.styles';
import { ReactErrorComponent } from '../../../../_lib/react-error';
import { usePrevious } from '../../../../_utils/hooks/use-previous';

type ExecutiveSummaryBreakdownProps = {
  priorityAreas: PriorityArea[];
  title: string;
};

function ExecutiveSummaryBreakdown(props: ExecutiveSummaryBreakdownProps) {
  const previous = usePrevious<{ priorityAreas: PriorityArea[] }>({
    priorityAreas: props?.priorityAreas,
  });
  const firstPriorityArea = props?.priorityAreas?.[0];
  const [selectedPriorityArea, setSelectedPriorityArea] = React.useState({
    item: firstPriorityArea,
  });

  const handleExecutiveSummaryPriorityAreaListPanelRowClick = ({
    item,
  }: {
    item: PriorityArea;
  }) => {
    setSelectedPriorityArea({ item });
  };

  React.useEffect(() => {
    if (
      (!selectedPriorityArea?.item && !!firstPriorityArea) ||
      !(
        props?.priorityAreas?.findIndex(
          (pa) => pa.id === selectedPriorityArea?.item?.id
        ) > -1
      ) ||
      previous?.priorityAreas?.length !== props?.priorityAreas?.length
    ) {
      setSelectedPriorityArea({ item: firstPriorityArea });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.priorityAreas]);

  return (
    <ErrorBoundary FallbackComponent={ReactErrorComponent}>
      <ExecutiveSummaryBreakdownContainerGrid>
        <Grid item xs={12} md={8}>
          <ExecutiveSummaryPriorityAreaListPanel
            panelTitle={props.title}
            priorityAreas={props.priorityAreas}
            onRowClick={handleExecutiveSummaryPriorityAreaListPanelRowClick}
            selectedItem={selectedPriorityArea?.item}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <ExecutiveSummaryPriorityAreaBreakdownPanel
            panelTitle={props.title}
            selectedItem={selectedPriorityArea?.item}
          />
        </Grid>
      </ExecutiveSummaryBreakdownContainerGrid>
    </ErrorBoundary>
  );
}

export default ExecutiveSummaryBreakdown;
