import React from 'react';
import { useTranslation } from 'react-i18next';

import { TARGET_UNITS_QUERY } from '../../../../_lib/graphql/queries';
import { PlanningFieldsInputManageDialog } from '../../../fields-input/_manage-dialog';
import { TARGET_UNITS_SUBSCRIPTION } from '../../../../_lib/graphql/subscriptions';
import {
  TARGET_UNIT_CREATE,
  TARGET_UNIT_DELETE,
  TARGET_UNIT_UPDATE,
  TARGET_UNIT_UPDATE_ORDER,
} from '../../../../_lib/graphql/mutations';
import { DashboardContext } from '../../../../_lib/context/dashboard-context';

export function TargetUnitCategoriesDialog({ open, setOpen }: any) {
  const { t } = useTranslation();
  const {
    dashboard: { enableTargetCategories },
  } = React.useContext(DashboardContext);

  return (
    <PlanningFieldsInputManageDialog
      open={open}
      setOpen={setOpen}
      GET_ITEMS={TARGET_UNITS_QUERY}
      SUBSCRIBE_ITEMS={TARGET_UNITS_SUBSCRIPTION}
      CREATE_ITEM={TARGET_UNIT_CREATE}
      createItemOpName="targetUnitCreate"
      UPDATE_ITEM={TARGET_UNIT_UPDATE}
      updateItemOpName="targetUnitUpdate"
      DELETE_ITEM={TARGET_UNIT_DELETE}
      deleteItemOpName="targetUnitDelete"
      UPDATE_ITEM_ORDER={TARGET_UNIT_UPDATE_ORDER}
      updateItemOrderOpName="targetUnitUpdateOrder"
      dataKey="targetUnits"
      dialogTitle={t('Manage Target Categories')}
      dialogInputLabel={t('Name')}
      dialogAddLabel={t('Add Indicator')}
      includeUnitCategory={enableTargetCategories}
    />
  );
}
