export const consolidateFinanciers = (financiers: any[]) =>
  financiers?.reduce((agg: any, next: any) => {
    const financierName = next.financier?.name || next.name;
    if (agg[financierName]) {
      return {
        ...agg,
        [financierName]: {
          ...agg[financierName],

          // delete currency if different
          currency:
            agg[financierName].currency &&
            agg[financierName].currency === next.currency
              ? next.currency
              : null,

          amount: agg[financierName].amount + next.amount,
          amountSpent: agg[financierName].amountSpent + next.amountSpent,

          amountDb: agg[financierName].amountDb + next.amountDb,
          amountSpentDb: agg[financierName].amountSpentDb + next.amountSpentDb,

          procurementMethods:
            next.procurementMethod?.name &&
            !agg[financierName].procurementMethods.includes(
              next.procurementMethod.name
            )
              ? [
                  ...agg[financierName].procurementMethods,
                  next.procurementMethod.name,
                ]
              : agg[financierName].procurementMethods,

          financeMethods:
            next.financeMethod?.name &&
            !agg[financierName].financeMethods.includes(next.financeMethod.name)
              ? [...agg[financierName].financeMethods, next.financeMethod.name]
              : agg[financierName].financeMethods,

          interventions:
            next.intervention &&
            !agg[financierName].interventions
              .map((intv: any) => intv.id)
              .includes(next.intervention.id)
              ? [...agg[financierName].interventions, next.intervention]
              : agg[financierName].interventions,
        },
      };
    }

    return {
      ...agg,
      [financierName]: {
        name: financierName,
        currency: next.currency,

        amount: next.amount,
        amountSpent: next.amountSpent,

        amountDb: next.amountDb,
        amountSpentDb: next.amountSpentDb,

        procurementMethods: next.procurementMethod?.name
          ? [next.procurementMethod.name]
          : [],
        financeMethods: next.financeMethod?.name
          ? [next.financeMethod.name]
          : [],

        interventions: next.intervention ? [next.intervention] : [],
        priorityAreas: next.priorityArea ? [next.priorityArea] : [],
      },
    };
  }, {});

export const consolidateFinanciersByProcurementMethod = (financiers: any[]) =>
  financiers?.reduce((agg: any, next: any) => {
    const procurementMethodName = next.procurementMethod?.name || 'N/A';
    if (agg[procurementMethodName]) {
      return {
        ...agg,
        [procurementMethodName]: {
          ...agg[procurementMethodName],
          amount: agg[procurementMethodName].amount + next.amount,
          amountSpent:
            agg[procurementMethodName].amountSpent + next.amountSpent,

          amountDb: agg[procurementMethodName].amountDb + next.amountDb,
          amountSpentDb:
            agg[procurementMethodName].amountSpentDb + next.amountSpentDb,
        },
      };
    }

    return {
      ...agg,
      [procurementMethodName]: {
        name: procurementMethodName,
        amount: next.amount,
        amountSpent: next.amountSpent,

        amountDb: next.amountDb,
        amountSpentDb: next.amountSpentDb,
      },
    };
  }, {});

export const consolidateFinanciersByFinanceMethod = (financiers: any[]) =>
  financiers?.reduce((agg: any, next: any) => {
    const financeMethodName = next.financeMethod?.name || 'N/A';
    if (agg[financeMethodName]) {
      return {
        ...agg,
        [financeMethodName]: {
          ...agg[financeMethodName],
          amount: agg[financeMethodName].amount + next.amount,
          amountSpent: agg[financeMethodName].amountSpent + next.amountSpent,

          amountDb: agg[financeMethodName].amountDb + next.amountDb,
          amountSpentDb:
            agg[financeMethodName].amountSpentDb + next.amountSpentDb,
        },
      };
    }

    return {
      ...agg,
      [financeMethodName]: {
        name: financeMethodName,
        amount: next.amount,
        amountSpent: next.amountSpent,

        amountDb: next.amountDb,
        amountSpentDb: next.amountSpentDb,
      },
    };
  }, {}) || {};

export const getFinanciers = (areas: any[]) => {
  return areas.flatMap((area: any) => {
    if (area.financiers) {
      return area.financiers;
    }
    if (area.interventions) {
      return area.interventions.flatMap((intv: any) => {
        if (!intv.financiers) {
          return [];
        }
        return intv.financiers.map((f: any) => ({
          ...f,
          intervention: {
            id: intv.id,
            reference: intv.reference,
            name: intv.name,
          },
          priorityArea: {
            id: area.id,
            reference: area.reference,
            name: area.name,
          },
        }));
      });
    }
    return [];
  });
};

export const getFinanceMethods = (areas: any[]) => {
  const financiers = getFinanciers(areas);
  const financeMethods = consolidateFinanciersByFinanceMethod(financiers);
  if (!financeMethods || !Object.keys(financeMethods).length) {
    return [];
  }
  return Object.values(financeMethods);
};

export const getFinanceSummary = (areas: any[]) => {
  return areas?.reduce(
    (acc: any, area: any) => {
      let financeSummary = {} as any;
      if (area.interventions) {
        financeSummary = getFinanceSummary([
          {
            budget: area.budgetDb,
            currentlySpent: area.currentlySpentDb,
            financiers: area.financiersDb,
          },
          ...area.interventions,
        ]);
      } else {
        financeSummary = {
          ...area,
          totalFunding:
            area?.financiers?.reduce((sum: any, f: any) => sum + f.amount, 0) ||
            0,
          totalFundingDb:
            area?.financiers?.reduce(
              (sum: any, f: any) => sum + f.amountDb,
              0
            ) || 0,
        };
      }

      acc.budget += financeSummary.budget || 0;
      acc.currentlySpent += financeSummary.currentlySpent || 0;

      acc.totalFunding += financeSummary.totalFunding || 0;
      acc.totalFundingDb += financeSummary.totalFundingDb || 0;

      acc.financiers = [
        ...acc.financiers,
        ...(financeSummary.financiers || []),
      ];

      return acc;
    },
    {
      budget: 0,
      currentlySpent: 0,

      totalFunding: 0,
      totalFundingDb: 0,

      financiers: [],
    }
  );
};

export const hasSomeFinance = (areas: any[]) => {
  if (!areas || areas.length === 0 || areas.every((a: any) => !a)) {
    return false;
  }
  const financiers = getFinanciers(areas);
  const financeMethods = getFinanceMethods(areas);
  const financeSummary = getFinanceSummary(areas);

  return [
    financeSummary.budget,
    financeSummary.budgetDb,
    financeSummary.currentlySpent,
    financeSummary.currentlySpentDb,
    financeSummary.totalFunding,
    financeSummary.totalFundingDb,

    areas[0].summary?.finance?.budget,
    areas[0].summary?.finance?.currentlySpent,
    areas[0].summary?.finance?.totalFunding,

    financiers.length,
    financeMethods.length,
  ].some((item: any) => item > 0);
};

export const getCurrencyAphaCode = (currency: string) => {
  const currencyCode = currency?.split('::')[1];
  return currencyCode?.length >= 3 ? currencyCode.slice(-3) : 'USD';
};
