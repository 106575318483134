import React from 'react';
import { useTranslation } from 'react-i18next';
import { gql } from '@apollo/client';

import { useDashboardContext } from '../../_lib/context/dashboard-context';

import { CLASSIFICATIONS_QUERY } from '../../_lib/graphql/queries';
import { CLASSIFICATIONS_SUBSCRIPTION } from '../../_lib/graphql/subscriptions';

import { PlanningFieldsInputAutocomplete } from './_autocomplete';

const CREATE_ITEM = gql`
  mutation ClassificationCreate($name: String!) {
    classificationCreate(name: $name) {
      classification {
        id
        name
      }
      classifications {
        id
        name
      }
    }
  }
`;

const UPDATE_ITEM = gql`
  mutation ClassificationUpdate($id: ID!, $name: String!) {
    classificationUpdate(id: $id, name: $name) {
      success
      message
      classifications {
        id
        name
      }
    }
  }
`;

const DELETE_ITEM = gql`
  mutation ClassificationDelete($id: ID!) {
    classificationDelete(id: $id) {
      success
      message
      classifications {
        id
        name
      }
    }
  }
`;

const UPDATE_ITEM_ORDER = gql`
  mutation ClassificationUpdateOrder($ids: [ID!]!) {
    classificationUpdateOrder(ids: $ids) {
      success
      message
      classifications {
        id
        name
      }
    }
  }
`;

export function PlanningFieldsInputClassification({
  disabled,
  area,
  setArea,
}: any) {
  const { t } = useTranslation();

  const { dashboard } = useDashboardContext();

  // ----------------------------------------------------------------------------------------

  return (
    <PlanningFieldsInputAutocomplete
      disabled={disabled}
      area={area}
      setArea={setArea}
      GET_ITEMS={CLASSIFICATIONS_QUERY}
      SUBSCRIBE_ITEMS={CLASSIFICATIONS_SUBSCRIPTION}
      CREATE_ITEM={CREATE_ITEM}
      createItemOpName="classificationCreate"
      UPDATE_ITEM={UPDATE_ITEM}
      updateItemOpName="classificationUpdate"
      DELETE_ITEM={DELETE_ITEM}
      deleteItemOpName="classificationDelete"
      UPDATE_ITEM_ORDER={UPDATE_ITEM_ORDER}
      updateItemOrderOpName="classificationUpdateOrder"
      dataKey="classifications"
      manageDialogTitle={
        dashboard.enableCustomClassificationLabel
          ? `${t('Manage')} ${dashboard.customClassificationLabel}`
          : t('Manage Classifications')
      }
      manageDialogInputLabel={t('Name')}
      manageDialogAddLabel={
        dashboard.enableCustomClassificationLabel
          ? `${t('Add')} ${dashboard.customClassificationLabel}`
          : t('Add Classification')
      }
      label={
        dashboard.enableCustomClassificationLabel
          ? dashboard.customClassificationLabel
          : t('Classifications')
      }
      multiple
      addOption
      forceManageOption
    />
  );
}
