import React from 'react';
import { Page } from '../page';

import { ReportsPdfUiHeaderOverview } from '../ui/header/overview';

import { ReportPdfSectionAreaContent } from '../sections/area-content';
import { getReportTemplateMetas } from '../../../../_utils/reports/get-report-template-meta';

export function PdftTemplatePageOverview({
  user,
  isNew,
  report,
  reportData,
  filterAreas,
  t,
}: any) {
  const reportsTemplateMetas = React.useMemo(
    () => getReportTemplateMetas(reportData.dashboard.reportsTemplateMeta),
    [reportData.dashboard.reportsTemplateMeta]
  );

  return (
    <Page
      pageOrientation={
        reportsTemplateMetas?.pageOrientation === 'LANDSCAPE'
          ? 'landscape'
          : 'portrait'
      }
    >
      <ReportsPdfUiHeaderOverview
        t={t}
        reportData={reportData}
        report={report}
      />

      <ReportPdfSectionAreaContent
        reportData={reportData}
        t={t}
        areas={reportData.priorityAreas}
      />
    </Page>
  );
}
