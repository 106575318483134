import React from 'react';
import { useTranslation } from 'react-i18next';

import Typography from '@mui/material/Typography';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Box from '@mui/material/Box';

import { CurrencyText } from '../../currency-text';
import { DashboardContext } from '../../../_lib/context/dashboard-context';
import { LinearProgressWithLabel } from '../../linear-progress/with-label';

export function AreaSummaryFinancialsFinanciersRow({
  financier,
  useAmountDb,
}: any) {
  const { t } = useTranslation();

  const { dashboard } = React.useContext(DashboardContext);

  const amount = React.useMemo(
    () => (useAmountDb ? financier.amountDb : financier.amount),
    [financier, useAmountDb]
  );
  const amountSpent = React.useMemo(
    () => (useAmountDb ? financier.amountSpentDb : financier.amountSpent),
    [financier, useAmountDb]
  );

  const amountSpentPerc = React.useMemo(
    () =>
      Number.isNaN(amountSpent / amount) ? 0 : (amountSpent / amount) * 100,
    [amountSpent, amount]
  );

  const currency = React.useMemo(
    () =>
      dashboard.enableMultipleCurrencies && useAmountDb
        ? financier.currency
        : null,
    [financier, useAmountDb, dashboard.enableMultipleCurrencies]
  );

  return (
    <>
      <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
        <TableCell
          sx={{
            borderBottom: dashboard.enableFinancierAmountSpent
              ? '0'
              : undefined,
          }}
        >
          {financier.name || (
            <Typography sx={{ fontStyle: 'italic', color: 'grey.700' }}>
              {t('Unknown')}
            </Typography>
          )}
          {!!financier.financeMethods?.length && (
            <>
              <br />
              <Typography variant="caption" sx={{ color: 'grey.700' }}>
                <b>{t('Finance Method')}:</b>{' '}
                {financier.financeMethods.join(', ')}
              </Typography>
            </>
          )}
          {!!financier.procurementMethods?.length && (
            <>
              <br />
              <Typography variant="caption" sx={{ color: 'grey.700' }}>
                <b>{t('Procurement Method')}:</b>{' '}
                {financier.procurementMethods.join(', ')}
              </Typography>
            </>
          )}
        </TableCell>
        <TableCell
          align="right"
          sx={{
            whiteSpace: 'nowrap',
            borderBottom: dashboard.enableFinancierAmountSpent
              ? '0'
              : undefined,
          }}
        >
          <CurrencyText amount={amount} currency={currency} />
        </TableCell>
      </TableRow>

      {/* financier amount spent */}
      {dashboard.enableFinancierAmountSpent && (
        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
          <TableCell colSpan={2} sx={{ pt: 0 }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant="caption">
                {t('Spent')}:{' '}
                <CurrencyText amount={amountSpent} currency={currency} />
              </Typography>
              <Box sx={{ flexGrow: 1, ml: 1 }}>
                <LinearProgressWithLabel
                  value={amountSpentPerc}
                  sx={{ height: '12px', borderRadius: '3px' }}
                  color={
                    (amountSpentPerc >= 100 && 'error') ||
                    (amountSpentPerc >= 80 && 'warning') ||
                    'primary'
                  }
                  labelTypographyProps={{
                    variant: 'caption',
                  }}
                />
              </Box>
            </Box>
          </TableCell>
        </TableRow>
      )}
    </>
  );
}
