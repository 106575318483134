import React from 'react';
import { View, Text } from '@react-pdf/renderer';

import { getReportTemplateMetas } from '../../../../../../_utils/reports/get-report-template-meta';

import { ReportsPdfSectionsActivitiesMilestonesTimeline } from './timeline';
import { ReportsPdfSectionsActivitiesMilestonesList } from './list';
import { reduceSummaries } from '../../../../../../_utils/content-utils/reduce-summaries';
import { ReportsPdfUiSectionSubTitle } from '../../../ui/section-title';

export function ReportsPdfSectionsActivitiesMilestones({
  t,
  dashboard,
  areas,
}: any) {
  const reportTemplateMetas = React.useMemo(
    () => getReportTemplateMetas(dashboard.reportsTemplateMeta),
    [dashboard.reportsTemplateMeta]
  );

  const combined = React.useMemo(
    () =>
      reduceSummaries({
        id: areas.length > 1 ? 'combined' : areas[0].id,
        name: areas.length > 1 ? dashboard.name : areas[0].name,
        priorityAreas: areas,
        interventionsCountDistinct: dashboard.interventionsCountDistinct,
      }),
    [areas, dashboard.interventionsCountDistinct, dashboard.name]
  );

  if (!combined.summary.milestonesSummary?.total) return <View />;

  return (
    <View>
      <ReportsPdfUiSectionSubTitle>
        {t('Milestones')}
      </ReportsPdfUiSectionSubTitle>

      {reportTemplateMetas?.milestonesDisplay === 'LIST' ? (
        <ReportsPdfSectionsActivitiesMilestonesList
          t={t}
          dashboard={dashboard}
          area={combined}
        />
      ) : (
        <ReportsPdfSectionsActivitiesMilestonesTimeline
          t={t}
          dashboard={dashboard}
          area={combined}
        />
      )}

      <View style={{ height: '20px' }} />
    </View>
  );
}
