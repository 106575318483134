import React from 'react';

import { ErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import Stack from '@mui/material/Stack';
import Skeleton from '@mui/material/Skeleton';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Pagination from '@mui/material/Pagination';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

import {
  INTERVENTIONS_PLANNING_QUERY,
  PRIORITY_AREA_PLANNING_QUERY,
} from '../../_lib/graphql/queries';
import { INTERVENTIONS_UPDATE_ORDER } from '../../_lib/graphql/mutations';

import { useDashboardContext } from '../../_lib/context/dashboard-context';
import { useLocalStorage } from '../../_utils/hooks/use-local-storage';

import {
  ReactErrorComponent,
  ReactErrorComponentSmall,
} from '../../_lib/react-error';

import { DraggableAreaContainer } from '../../components/draggable/area-container';

import { PlanningLayoutTabPanel } from '../layout/tab-panel';
import { PlanningInterventionFormListHeader } from './list-header';
import { PlanningInterventionFormDetail } from './detail';
import { PlanningInterventionFormAddDialog } from './add-dialog';
import { PlanningLayoutLoadBreadcrumbs } from '../layout/load-breadcrumbs';
import { useAreasPlanning } from '../../_utils/hooks/queries/use-areas-planning';

export function PlanningInterventionFormList() {
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();

  const { priorityAreaId } = useParams<any>();

  const { dashboard } = useDashboardContext();

  const bottomRef = React.useRef<HTMLDivElement>(null);

  const [openCollapse, setOpenCollapse] = useLocalStorage(
    'planning-intervention-form-list-collapse',
    []
  );

  const [priorityArea, setPriorityArea] = React.useState<any>(null);
  const [interventions, setInterventions] = React.useState<any>([]);

  const [searchTerm, setSearchTerm] = React.useState('');
  const [page, setPage] = React.useState(1);

  const [openAddDialog, setOpenAddDialog] = React.useState(false);

  // active and archived tabs
  const [isArchivedTab, setIsArchivedTab] = React.useState(0);

  // --------------------------------------------------------------------------------------------------------

  // filter id
  const filterId = React.useMemo(() => {
    const params = new URLSearchParams(location.search);
    return params.get('id');
  }, [location]);

  // --------------------------------------------------------------------------------------------------------

  const variables = React.useMemo(
    () => ({
      priorityAreaId,
      filterIsArchived: isArchivedTab === 1,
      filterInterventionIds: filterId ? [filterId] : null,
      filterSearchQuery: searchTerm,
      paginationInterventionsOffset: (page - 1) * 10,
    }),
    [priorityAreaId, isArchivedTab, filterId, searchTerm, page]
  );

  const { loading, error, data } = useAreasPlanning('intervention', variables);

  // --------------------------------------------------------------------------------------------------------

  const [updateInterventionsOrderMutation] = useMutation(
    INTERVENTIONS_UPDATE_ORDER,
    {
      update: (cache, { data: { interventionsUpdateOrder } }) => {
        // update priority area
        cache.writeQuery({
          query: PRIORITY_AREA_PLANNING_QUERY,
          variables: {
            priorityAreaId: interventionsUpdateOrder.priorityArea.id,
          },
          data: {
            priorityArea: interventionsUpdateOrder.priorityArea,
          },
        });

        // update interventions
        cache.writeQuery({
          query: INTERVENTIONS_PLANNING_QUERY,
          variables,
          data: {
            interventions: interventionsUpdateOrder.interventions,
          },
        });
      },
    }
  );

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setPage(1);
    setIsArchivedTab(newValue);
  };

  // --------------------------------------------------------------------------------------------------------
  // --------------------------------------------------------------------------------------------------------
  // effects
  // --------------------------------------------------------------------------------------------------------

  // max five
  React.useEffect(() => {
    if (openCollapse.length > 5) {
      setOpenCollapse((prev: any) => prev.slice(1));
    }
  }, [openCollapse, setOpenCollapse]);

  // data
  React.useEffect(() => {
    if (data) {
      setInterventions(data.interventions.items);
    }
  }, [data]);

  // --------------------------------------------------------------------------------------------------------
  if (error) return <ReactErrorComponent error={error} />;

  if (loading || !data) {
    return (
      <Stack spacing={3} sx={{ my: 3 }}>
        {[0, 1, 2].map((index) => (
          <Skeleton key={index} variant="rectangular" height={100} />
        ))}
      </Stack>
    );
  }

  // --------------------------------------------------------------------------------------------------------

  return (
    <PlanningLayoutTabPanel
      index={1}
      title={`${t('Planning')}`}
      subheader={`${t("Set up your main area's sub-areas")}`}
      breadcrumbs={priorityArea?.breadcrumbs}
      actions={
        !filterId ? (
          <Button
            startIcon={<AddCircleOutlineIcon />}
            variant="contained"
            disableElevation
            onClick={() => setOpenAddDialog(true)}
          >
            {t('Add')}
          </Button>
        ) : (
          <Button
            variant="contained"
            disableElevation
            onClick={() => history.push(location.pathname)}
          >
            {t('View All')}
          </Button>
        )
      }
    >
      {/* -------------------------------------------------------------------------------------------------------- */}
      {/* add dialog */}
      {/* -------------------------------------------------------------------------------------------------------- */}
      <PlanningInterventionFormAddDialog
        open={openAddDialog}
        setOpen={setOpenAddDialog}
        listVariables={variables}
      />

      {/* header */}
      <PlanningInterventionFormListHeader
        filterId={filterId}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        priorityArea={priorityArea?.priorityArea}
        setOpenCollapse={setOpenCollapse}
      />

      {/* -------------------------------------------------------------------------------------------------------- */}
      {/* interventions */}
      {/* -------------------------------------------------------------------------------------------------------- */}

      {/* put this is tab for active and archived */}
      {dashboard.enableAreaArchive && (
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={isArchivedTab}
            onChange={handleTabChange}
            aria-label="basic tabs example"
          >
            <Tab label={t('Active')} id="area-tabs-0" />
            <Tab label={`${t('Archived')}`} id="area-tabs-1" />
          </Tabs>
        </Box>
      )}

      <DraggableAreaContainer
        details={interventions}
        setDetails={setInterventions}
        filtered={!!filterId || !!searchTerm}
        updateOrderMutation={updateInterventionsOrderMutation}
      >
        {interventions?.map((intv: any, index: any) => (
          <ErrorBoundary
            key={intv.id}
            FallbackComponent={ReactErrorComponentSmall}
          >
            <PlanningInterventionFormDetail
              interventionInitial={intv}
              filtered={!!filterId || !!searchTerm}
              index={index}
              openCollapse={openCollapse}
              setOpenCollapse={setOpenCollapse}
              listVariables={variables}
            />
          </ErrorBoundary>
        ))}
      </DraggableAreaContainer>

      {/* no interventions */}
      {!data?.interventions?.meta.count && (
        <Box sx={{ p: 4, my: 3, textAlign: 'center', color: 'grey.700' }}>
          <Typography variant="h5">{t('Nothing to show')}</Typography>
          <Typography variant="body1" sx={{ mb: 1 }}>
            {t('No results found')}
          </Typography>
        </Box>
      )}

      {/* add button */}
      {!filterId &&
        !searchTerm &&
        data?.interventions?.meta.count < 11 &&
        isArchivedTab !== 1 && (
          <Box sx={{ textAlign: 'center' }}>
            <Button
              variant="contained"
              disableElevation
              onClick={() => setOpenAddDialog(true)}
              startIcon={<AddCircleOutlineIcon />}
            >
              {t('Add')} {dashboard.interventionName}
            </Button>
          </Box>
        )}

      {/* pagination */}
      {!filterId && data?.interventions?.meta.count > 10 && (
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 5, mb: 3 }}>
          <Pagination
            count={Math.ceil(data.interventions.meta.count / 10)}
            page={page}
            onChange={(e, pg) => setPage(pg)}
          />
        </Box>
      )}

      {/* ---------------------------------------------------------------------------------------------------------------------- */}
      {/* other updates */}
      {/* ---------------------------------------------------------------------------------------------------------------------- */}
      {!!priorityAreaId && (
        <PlanningLayoutLoadBreadcrumbs
          setPriorityArea={setPriorityArea}
          searchTerm={searchTerm}
          isIntervention
        />
      )}

      <div ref={bottomRef} />
    </PlanningLayoutTabPanel>
  );
}
