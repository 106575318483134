import React from 'react';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';

import { ResponsiveChoropleth } from '@nivo/geo';

import { useDashboardContext } from '../../_lib/context/dashboard-context';

import { getScaleAndProjection, hexToRGBA } from '../../_utils/map-utils';
import { useJSONMap } from '../../_utils/hooks/use-json-map';
import { ReactErrorComponent } from '../../_lib/react-error';

import { MapTooltip } from './tooltip';

export function HomepageMapViewMap({
  parentWidth,
  priorityAreas,
}: {
  parentWidth: number;
  priorityAreas: any[];
}) {
  const { t } = useTranslation();

  const { dashboard, navbarFilters, setNavbarFilters } = useDashboardContext();

  const { data, loading, error } = useJSONMap(dashboard.customMapviewMap);

  const UNKNOWN_COLOR = '#ffffff';
  const BG_COLOR = React.useMemo(
    () =>
      dashboard.customMapPalette?.bgColor ||
      dashboard.colorPalette?.primary?.bgcolor,
    [dashboard.customMapPalette, dashboard.colorPalette]
  );

  // ------------------------------------------------------------------------------------------------------------------------
  // ------------------------------------------------------------------------------------------------------------------------
  // memoized functions + values
  // ------------------------------------------------------------------------------------------------------------------------

  const getInterventionCount = React.useCallback(
    (feature: any) => {
      if (
        dashboard.mode === 'SUPRANATIONAL' &&
        dashboard.mapDodUseChildInterventionCount === false
      ) {
        return (
          priorityAreas
            ?.filter(
              (pa: any) =>
                !!pa.regions?.find(
                  (r: any) =>
                    r.name.toLowerCase() ===
                    feature.properties.name.toLowerCase()
                )
            )
            ?.flatMap((pa: any) => pa.interventions)?.length || 0
        );
      }
      return (
        priorityAreas
          ?.flatMap((pa: any) => pa.interventions)
          ?.reduce(
            (acc: number, intv: any) =>
              acc +
              (intv
                ? Array.from(
                    new Set(intv.regions.map((r: any) => r.name.toLowerCase()))
                  ).filter(
                    (r: any) => r === feature.properties.name.toLowerCase()
                  ).length
                : 0),
            0
          ) || 0
      );
    },
    [dashboard.mode, dashboard.mapDodUseChildInterventionCount, priorityAreas]
  );

  // regions available to priority areas and if not supranational, interventions
  const regionsInAreas = React.useMemo(
    () =>
      Array.from(
        new Set([
          ...(priorityAreas
            ?.flatMap((pa: any) => pa.regions)
            ?.map((r: any) => r.name.toLowerCase()) || []),

          ...(priorityAreas
            ?.flatMap((p: any) =>
              p.interventions?.flatMap((i: any) => i.regions)
            )
            ?.map((r: any) => r.name.toLowerCase()) || []),
        ])
      ),
    [priorityAreas]
  );

  // map fit
  const mapFit = React.useMemo(() => {
    let newMapFit: any = {
      scale: 1,
      translate: [0, 0],
    };
    if (data) {
      const { scale, translate } = getScaleAndProjection(
        data,
        parentWidth,
        500
      );
      newMapFit = {
        scale,
        translate,
      };
    }
    return newMapFit;
  }, [data, parentWidth]);

  // pull navbar filter changes
  const mapState = React.useMemo(() => {
    let newMapState: any = {
      data: [],
      features: [],
      error: false,
    };

    if (data) {
      // populate data and features map state
      data.features?.forEach((feature: any, idx: number) => {
        // push data only for existing region names
        if (
          regionsInAreas?.find(
            (r: any) => r === feature.properties.name.toLowerCase()
          )
        ) {
          newMapState.data.push({
            id: feature.properties.name,
            value: getInterventionCount(feature),
            selected: false,
            countIntvs: getInterventionCount(feature),
            countPas: priorityAreas?.reduce(
              (acc: number, pa: any) =>
                acc +
                Array.from(
                  new Set(pa.regions.map((r: any) => r.name.toLowerCase()))
                ).filter(
                  (r: any) => r === feature.properties.name.toLowerCase()
                ).length,
              0
            ),
          });
        }

        // push all features
        newMapState.features.push({
          ...feature,
          id: feature.properties.name,
        });
      });

      // set selected
      if (navbarFilters.regionsIds?.length) {
        newMapState = {
          ...newMapState,
          data: newMapState.data?.map((d: any) => {
            const region = dashboard.regions?.find(
              (r: any) => r.name.toLowerCase() === d.id.toLowerCase()
            );
            if (region && navbarFilters.regionsIds?.includes(region.id)) {
              return {
                ...d,
                selected: true,
              };
            }
            return d;
          }),
        };
      }
    }
    return newMapState;
  }, [
    data,
    navbarFilters.regionsIds,
    regionsInAreas,
    getInterventionCount,
    priorityAreas,
    dashboard.regions,
  ]);

  const domain = React.useMemo(() => {
    const values = mapState?.data?.map((d: any) => d.value) || [];
    const minVal = Math.min(...values);
    return [minVal > 0 ? 0 : minVal, Math.max(...values)];
  }, [mapState?.data]);

  // ------------------------------------------------------------------------------------------------------------------------
  // ------------------------------------------------------------------------------------------------------------------------
  // handlers
  // ------------------------------------------------------------------------------------------------------------------------

  const handleClick = (mapData: any) => {
    if (!navigator.onLine) return;
    const region = dashboard.regions?.find(
      (r: any) => r.name.toLowerCase() === mapData.properties.name.toLowerCase()
    );

    // set navbar filter with region ids
    if (region && regionsInAreas.includes(region.name.toLowerCase())) {
      if (navbarFilters.regionsIds?.includes(region.id)) {
        setNavbarFilters((old: any) => ({
          ...old,
          regionsIds: Array.from(
            new Set([...old.regionsIds].filter((r: any) => r !== region.id))
          ),
        }));
      } else {
        setNavbarFilters((old: any) => ({
          ...old,
          regionsIds: Array.from(
            new Set([...(old.regionsIds || []), region.id])
          ),
        }));
      }
    }
  };

  // ------------------------------------------------------------------------------------------------------------------------

  if (error) return <ReactErrorComponent error={error} />;

  if (loading) return <Skeleton height={500} variant="rectangular" />;

  if (data && !data.features?.length)
    return (
      <Box
        sx={{
          display: 'flex',
          height: '100%',
          width: '100%',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Typography
          fontStyle="italic"
          color="textSecondary"
          sx={{ display: 'flex', alignItems: 'center' }}
        >
          <WarningAmberRoundedIcon sx={{ mr: 1 }} />
          {t('Invalid Map File')}
        </Typography>
      </Box>
    );

  // ------------------------------------------------------------------------------------------------------------------------

  return (
    <ResponsiveChoropleth
      data={mapState.data}
      features={mapState.features}
      margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
      colors={
        mapState.data?.length
          ? Array.from({ length: 5 }).map((_, i) => {
              return hexToRGBA(BG_COLOR, 0.5 + i * 0.1);
            })
          : [UNKNOWN_COLOR]
      }
      domain={domain}
      unknownColor={UNKNOWN_COLOR}
      label="properties.name"
      projectionScale={mapFit.scale} // {310}
      projectionTranslation={mapFit.translate as [number, number]} // {[0.375, 0.5]}
      enableGraticule
      graticuleLineColor="#dedede"
      borderWidth={(feature: any) => {
        if (feature?.data?.selected) return 2;
        return 0.5;
      }}
      borderColor={(feature: any) => {
        if (feature?.data?.selected) return '#000000';
        return (
          dashboard.customMapPalette?.borderColor ||
          dashboard.colorPalette?.secondary?.bgcolor
        );
      }}
      onClick={handleClick}
      tooltip={MapTooltip}
      legends={
        mapState.data?.length
          ? [
              {
                anchor: 'top-left',
                direction: 'column',
                justify: false,
                translateX: 20,
                translateY: 20,
                itemsSpacing: 5,
                itemWidth: 70,
                itemHeight: 18,
                itemDirection: 'left-to-right',
                itemOpacity: 0.85,
                symbolSize: 18,
              },
            ]
          : []
      }
    />
  );
}
