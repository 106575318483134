import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, gql } from '@apollo/client';

import LoadingButton from '@mui/lab/LoadingButton';

import { useDashboardContext } from '../../../_lib/context/dashboard-context';
import { logError } from '../../../_lib/error';

const SEND_REPORT = gql`
  mutation SendReport($id: ID!, $emails: [String!]!, $message: String) {
    reportShare(id: $id, emails: $emails, message: $message) {
      success
      message
    }
  }
`;

export function ReportActionShareSend({
  report,
  emails,
  setEmails,
  message,
  setMessage,
  setOpen,
  loading,
  setLoading,
}: any) {
  const { t } = useTranslation();

  const { setSnackbarOpen, setSnackbarIsError } = useDashboardContext();

  const [sendReport] = useMutation(SEND_REPORT);

  const handleSend = React.useCallback(() => {
    sendReport({
      variables: {
        id: report?.id,
        emails: emails.map((email: any) =>
          typeof email === 'object' ? email.email : email
        ),
        message,
      },
    })
      .then((res) => {
        const { success } = res.data.reportShare;
        if (success) {
          setSnackbarOpen(true);
          setEmails([]);
          setMessage('');
          setOpen(false);
        } else {
          setSnackbarIsError(true);
          setSnackbarOpen(true);
        }
        setLoading(false);
      })
      .catch((err) => {
        logError(err);
        setSnackbarIsError(true);
        setSnackbarOpen(true);
        setLoading(false);
      });
  }, [
    sendReport,
    report,
    emails,
    message,
    setSnackbarOpen,
    setSnackbarIsError,
    setEmails,
    setMessage,
    setOpen,
    setLoading,
  ]);

  return (
    <LoadingButton
      onClick={handleSend}
      variant="contained"
      sx={{ ml: 1, textTransform: 'none' }}
      disableElevation
      loading={loading}
    >
      {t('Send')}
    </LoadingButton>
  );
}
