import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';

import DateRangeIcon from '@mui/icons-material/DateRange';
import Button from '@mui/material/Button';

import { ContentDialogContext } from '../../../_lib/context/content-dialog-context';
import { DashboardContext } from '../../../_lib/context/dashboard-context';

import { CONTENT_QUERY } from '../../../_lib/graphql/queries';
import { CONTENT_UPDATE_CREATE } from '../../../_lib/graphql/mutations';
import { logError } from '../../../_lib/error';

import { DialogModal } from '../../../components/dialog-modal';

export function ContentDialogActionRequestExtension() {
  const { t } = useTranslation();

  const { content, contentId } = React.useContext(ContentDialogContext);
  const { setSnackbarOpen, setSnackbarIsError } =
    React.useContext(DashboardContext);

  const [confirmDialogOpen, setConfirmDialogOpen] = React.useState(false);

  const [addUpdateMutation, { loading }] = useMutation(CONTENT_UPDATE_CREATE, {
    update: (cache, { data }) => {
      cache.writeQuery({
        query: CONTENT_QUERY,
        data: { content: data.contentUpdateCreate.content },
        variables: { id: contentId },
      });
    },
  });

  const requestDeadlineExtension = (update: any) => {
    const variables = {
      contentId: content.id,
      text: update.text,
      status: 'PENDING',
      extensionRequest: update.date,
      attachment: update.attachment,
    };
    addUpdateMutation({ variables })
      .then(() => {
        setConfirmDialogOpen(false);
        setSnackbarIsError(false);
      })
      .catch((err) => logError(err))
      .finally(() => {
        setSnackbarOpen(true);
      });
  };

  return (
    <>
      <DialogModal
        loading={loading}
        onDismiss={() => setConfirmDialogOpen(false)}
        onSubmit={requestDeadlineExtension}
        show={confirmDialogOpen}
        title={t('Suggest a new deadline and give reasons for the change')}
        type="select-date-text-input-attachment"
        initialDate={content.deadline}
      />

      <Button
        id="request-content-extension"
        onClick={() => setConfirmDialogOpen(true)}
        variant="outlined"
        startIcon={<DateRangeIcon />}
      >
        {t('Request extension')}
      </Button>
    </>
  );
}
