import React from 'react';
import { useHistory } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';

import Dialog from '@mui/material/Dialog';
import MenuItem from '@mui/material/MenuItem';
import LoadingButton from '@mui/lab/LoadingButton';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';

import { REPORT_DELETE } from '../../../_lib/graphql/mutations';

import { DashboardContext } from '../../../_lib/context/dashboard-context';
import { logError } from '../../../_lib/error';
import { DialogTitle } from '../../../DialogTitle/dialog-title';

export function ReportActionDeleteDialog({
  report,
  open,
  setOpen,
  loading,
  setLoading,
}: any) {
  const { t } = useTranslation();
  const history = useHistory();

  const { setSnackbarIsDelete, setSnackbarOpen } =
    React.useContext(DashboardContext);

  const [deleteReportMutation] = useMutation(REPORT_DELETE);

  // --------------------------------------------------------------------------------------------------------
  // --------------------------------------------------------------------------------------------------------
  // handlers
  // --------------------------------------------------------------------------------------------------------

  const handleClose = React.useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const handleDelete = React.useCallback(() => {
    setLoading(true);

    deleteReportMutation({
      variables: {
        id: report.id,
      },
    })
      .then((r: any) => {
        if (r.data.reportDelete.success) {
          setSnackbarIsDelete(true);
          setSnackbarOpen(true);
          history.push(`${t('/reports')}`);
        }
      })
      .catch((e: any) => {
        logError(e);
        setLoading(false);
      });
  }, [
    deleteReportMutation,
    history,
    report.id,
    setLoading,
    setSnackbarIsDelete,
    setSnackbarOpen,
    t,
  ]);

  // --------------------------------------------------------------------------------------------------------

  return (
    <Dialog open={open}>
      <DialogTitle onClose={handleClose} disabled={loading}>
        {t('Delete report')}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t('Are you sure you want to delete this report?')}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClose}
          color="primary"
          variant="outlined"
          disabled={loading}
        >
          {t('Cancel')}
        </Button>
        <LoadingButton
          onClick={handleDelete}
          color="error"
          variant="contained"
          autoFocus
          disableElevation
          loading={loading}
        >
          {t('Delete')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
