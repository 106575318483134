import React from 'react';
import { useTranslation } from 'react-i18next';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';

import { AreaSummaryBarChart } from '../../components/area-summary/bar-chart';
import { useSummary } from '../../_utils/hooks/queries/use-summary';

export function DashboardSummaryMilestones({
  filterPriorityAreaIds,
  filterInterventionIds,
}: any) {
  const { t } = useTranslation();

  const variables = React.useMemo(
    () => ({
      filterPriorityAreaIds,
      filterInterventionIds,
    }),
    [filterPriorityAreaIds, filterInterventionIds]
  );

  const { loading, data, error } = useSummary('milestonesSummary', variables);

  // ----------------------------------------------------------------------------------------------------------

  if (error) return null;

  if (loading || !data) {
    return <Skeleton variant="rectangular" height={50} />;
  }

  if (!data?.dashboard?.summary?.milestonesSummary?.total) return null;

  // ----------------------------------------------------------------------------------------------------------

  return (
    <Card variant="outlined">
      <CardContent>
        <Typography variant="body1" sx={{ mb: 0.5, fontWeight: 500 }}>
          {t('Milestones')}{' '}
          <Typography variant="body2" component="span">
            : {data.dashboard.summary.milestonesSummary.total}
          </Typography>
        </Typography>
        <AreaSummaryBarChart
          height="18px"
          borderRadius="3px"
          contentSummary={data.dashboard.summary.milestonesSummary}
        />
      </CardContent>
    </Card>
  );
}
