import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Container from '@mui/material/Container';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';

import { AuthDemoAlert } from './demo-alert';

import AuthBg from '../../assets/img/bg/bg-auth-min.jpg';
import { AuthSafariAlert } from './safari-alert';

export function ImpressumReleaseVersion() {
  return (
    <Typography
      component="p"
      variant="caption"
      color="text.secondary"
      textAlign="center"
    >
      v{process.env.REACT_APP_VERSION}
    </Typography>
  );
}

export function Copyright(props: any) {
  const { t } = useTranslation();
  return (
    <>
      <Typography
        variant="body2"
        color="text.secondary"
        textAlign="center"
        gutterBottom
      >
        {t('Built by')}{' '}
        <Link
          color="inherit"
          href="https://institute.global/"
          target="_blank"
          rel="noreferre noopener"
        >
          Tony Blair Institute
        </Link>
      </Typography>
      <ImpressumReleaseVersion />
    </>
  );
}

export function AuthScreenLayout({
  icon,
  title,
  children,
  demoEmail,
  demoPassword,
}: any) {
  const { t } = useTranslation();
  const location = useLocation();
  return (
    <>
      {/* background */}
      <style>
        {`
          body {
            background: url("${AuthBg}");
            background-size: 100% auto;
            background-repeat: no-repeat;
            background-position: center bottom;
            background-attachment: fixed;
          }
        `}
      </style>

      <Box
        sx={{
          pt: 10,
        }}
      >
        <Container component="main" maxWidth="xs" sx={{ pb: 4 }}>
          <Box sx={{ textAlign: 'center' }}>
            <Typography variant="h4" color="grey.800" sx={{ mb: 8 }}>
              {t('Digital Delivery Dashboard')}
            </Typography>
          </Box>
          <Box
            sx={{
              mt: 4,
              mb: 8,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              p: 4,
              bgcolor: 'white',
              borderRadius: 1,
              border: 1,
              borderColor: 'grey.400',
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
              {icon || <LockOutlinedIcon />}
            </Avatar>
            <Typography component="h1" variant="h5">
              {title || t('Sign In')}
            </Typography>

            {/* safari alert */}
            {location.pathname === '/' && <AuthSafariAlert />}

            {/* demo */}
            {location.pathname === '/' &&
              process.env.REACT_APP_IS_DEMO_SITE && (
                <AuthDemoAlert email={demoEmail} password={demoPassword} />
              )}

            {/* content */}
            {children}
          </Box>

          {/* copyright */}
          <Copyright />
        </Container>
      </Box>
    </>
  );
}
