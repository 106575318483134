import React from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorBoundary } from 'react-error-boundary';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

import { DialogTitle } from '../../../DialogTitle/dialog-title';

import { ReactErrorComponent } from '../../../_lib/react-error';

import { ReportActionDownload } from '../download';
import { ReportActionsShareAutocomplete } from './autocomplete';
import { ReportActionShareSend } from './send';

export function ReportActionsShareDialog({ report, open, setOpen }: any) {
  const { t } = useTranslation();

  const [emails, setEmails] = React.useState<any>([]);
  const [message, setMessage] = React.useState('');

  const [loading, setLoading] = React.useState(false);

  return (
    <Dialog open={open} maxWidth="xs" fullWidth>
      <DialogTitle onClose={() => setOpen(false)} disabled={loading}>
        {t('Share')} &apos;{report?.title}&apos;
      </DialogTitle>

      <DialogContent>
        <ErrorBoundary FallbackComponent={ReactErrorComponent}>
          <Box>
            <Stack spacing={2.5}>
              <ReportActionsShareAutocomplete
                emails={emails}
                setEmails={setEmails}
                disabled={loading}
              />
              {!!emails?.length && (
                <TextField
                  label={t('Message')}
                  multiline
                  minRows={3}
                  size="small"
                  fullWidth
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  disabled={loading}
                />
              )}
            </Stack>
          </Box>
        </ErrorBoundary>
      </DialogContent>

      <DialogActions
        sx={{
          p: 2,
          pt: 0,
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <ReportActionDownload isOutlined textTransformNone disabled={loading} />

        {!emails?.length ? (
          <Button
            onClick={() => setOpen(false)}
            variant="contained"
            sx={{ textTransform: 'none' }}
            disableElevation
          >
            {t('Done')}
          </Button>
        ) : (
          <Box>
            <Button
              onClick={() => setOpen(false)}
              sx={{
                textTransform: 'none',
              }}
              disabled={loading}
            >
              {t('Cancel')}
            </Button>
            <ReportActionShareSend
              setOpen={setOpen}
              emails={emails}
              setEmails={setEmails}
              message={message}
              setMessage={setMessage}
              report={report}
              loading={loading}
              setLoading={setLoading}
            />
          </Box>
        )}
      </DialogActions>
    </Dialog>
  );
}
