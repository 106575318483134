import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';

import { Collapse, TablePaginationProps, Typography } from '@mui/material';
import { Fragment, MouseEventHandler } from 'react';
import {
  SectionTableHeaderContainer,
  SectionTableHeaderTab,
  SectionTitleContainer,
  SectionTableHeaderTabProps,
  TabType,
} from './executive-summary-content-table.styles';
import {
  ExecutiveSummaryTableRow,
  ExecutiveSummaryTableRowProps,
} from './table-row';
import { Table } from './table';
import { useLocalStorage } from '../../../../_utils/hooks/use-local-storage';

type ExecutiveSummaryContentTableProps = {
  sectionTitle?: string | null;
  tabTitle?: string | null;
  onClickPanelHeader?:
    | MouseEventHandler<HTMLButtonElement | HTMLElement>
    | undefined;
  children?: React.ReactNode;
  paginationProps?: Partial<TablePaginationProps>;
  tabProps?: SectionTableHeaderTabProps;
};

export default function ExecutiveSummaryContentTable(
  props: ExecutiveSummaryContentTableProps
) {
  const [panelHeaderState, setpanelHeaderState] = useLocalStorage<
    string,
    boolean
  >(`${props?.sectionTitle?.replace(/ /g, '')}-panel`, false);

  const handlePanelHeaderClick:
    | MouseEventHandler<HTMLButtonElement | HTMLElement>
    | undefined = (event) => {
    setpanelHeaderState(!panelHeaderState);
    props.onClickPanelHeader?.(event);
  };

  return (
    <>
      <SectionTitleContainer>
        <Typography
          variant="h5"
          component="h2"
          fontSize={{
            xs: '1rem',
            sm: '1.5rem',
          }}
        >
          {props?.sectionTitle}
        </Typography>
      </SectionTitleContainer>
      <Stack>
        <SectionTableHeaderContainer
          onClick={handlePanelHeaderClick}
          sx={{ cursor: 'pointer' }}
        >
          <SectionTableHeaderTab
            tabType={TabType.OVERDUE}
            {...props.tabProps}
            variant="body1"
            component="p"
          >
            {props?.tabTitle}
          </SectionTableHeaderTab>
          <IconButton
            aria-label="open overview"
            onClick={handlePanelHeaderClick}
            sx={{ displayPrint: 'none' }}
          >
            <KeyboardArrowRightIcon
              sx={{
                transform: `rotate(${panelHeaderState ? '90deg' : '0deg'})`,
                transition: 'transform 0.3s',
              }}
            />
          </IconButton>
        </SectionTableHeaderContainer>

        <Collapse in={panelHeaderState} unmountOnExit>
          <Box pt={1} />
          <Box>
            <Table paginationProps={props?.paginationProps}>
              {props?.children}
            </Table>
          </Box>
        </Collapse>
      </Stack>
    </>
  );
}

export type ExecutiveSummaryContentTableRowsProps<T extends { id?: string }> = {
  tableData?: T[];
  formatExecutiveSummaryContentTableRowData?: ({
    item,
  }: {
    item: T;
  }) => ExecutiveSummaryTableRowProps['data'];
};

export function ExecutiveSummaryContentTableRows<T extends { id?: string }>(
  props: ExecutiveSummaryContentTableRowsProps<T>
) {
  return (
    <>
      {props.tableData?.map((item: T, index: number) => {
        const data =
          props.formatExecutiveSummaryContentTableRowData?.({
            item,
          }) || {};
        const rowKey = `${item?.id}${index}`;
        return (
          <ExecutiveSummaryTableRow key={rowKey} id={item?.id} data={data} />
        );
      }) || null}
    </>
  );
}
