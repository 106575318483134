import React from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorBoundary } from 'react-error-boundary';

import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

import { ReactErrorComponent } from '../../../_lib/react-error';

import { AreaSummaryCardMilestones } from './milestones';
import { AreaSummaryCardActivities } from './activities';
import { AreaSummaryCardCounts } from './counts';

export function AreaSummaryChartContent({ area, filterCharts }: any) {
  const { t } = useTranslation();

  // --------------------------------------------------------------------------------------------------------------------
  // --------------------------------------------------------------------------------------------------------------------
  // callbacks
  // --------------------------------------------------------------------------------------------------------------------

  const filterUnstarted = React.useCallback(
    (contentSummary: any) => {
      const newContentSummary = contentSummary
        ? structuredClone(contentSummary)
        : {};
      if (filterCharts.indexOf('unstarted') === -1) {
        delete newContentSummary.unstarted;
      }
      return newContentSummary;
    },
    [filterCharts]
  );

  // --------------------------------------------------------------------------------------------------------------------

  return (
    <ErrorBoundary FallbackComponent={ReactErrorComponent}>
      <Typography sx={{ mb: 1, fontWeight: 500 }}>{t('Summary')} </Typography>

      {/* number of children interventions */}
      {area.__typename !== 'InterventionType' && (
        <AreaSummaryCardCounts area={area} />
      )}

      <Stack spacing={2} divider={<Divider />}>
        {/* milestones */}
        <AreaSummaryCardMilestones
          area={area}
          filterUnstarted={filterUnstarted}
        />

        {/* activities */}
        <AreaSummaryCardActivities
          area={area}
          filterUnstarted={filterUnstarted}
        />
      </Stack>
    </ErrorBoundary>
  );
}
