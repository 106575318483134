import React from 'react';
import { useTranslation } from 'react-i18next';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';

import Checkbox from '@mui/material/Checkbox';

import { ReportsContext } from '../../../_lib/context/reports-context';

export function ReportsEditFiltersMembers() {
  const { t } = useTranslation();

  const { filterMembers, setFilterMembers, loading } =
    React.useContext(ReportsContext);

  // ------------------------------------------------------------------------------------------------------------------------
  // ------------------------------------------------------------------------------------------------------------------------
  // handlers
  // ------------------------------------------------------------------------------------------------------------------------

  const handleToggle = React.useCallback(
    () => setFilterMembers((prev: any) => !prev),
    [setFilterMembers]
  );

  // ------------------------------------------------------------------------------------------------------------------------
  // ------------------------------------------------------------------------------------------------------------------------
  // effects
  // ------------------------------------------------------------------------------------------------------------------------

  // ------------------------------------------------------------------------------------------------------------------------

  const labelId = `checkbox-list-label-members`;
  return (
    <List
      sx={{ width: '100%' }}
      aria-labelledby="nested-list-subheader"
      subheader={
        <ListSubheader component="div" id="nested-list-subheader">
          {t('Members')}
        </ListSubheader>
      }
    >
      <ListItem disablePadding>
        <ListItemButton
          role={undefined}
          onClick={handleToggle}
          dense
          disabled={loading}
        >
          <ListItemIcon>
            <Checkbox
              edge="start"
              checked={!filterMembers}
              tabIndex={-1}
              disableRipple
              inputProps={{ 'aria-labelledby': labelId }}
              name="filter-members"
            />
          </ListItemIcon>
          <ListItemText
            id={labelId}
            primary={t('Include members information')}
          />
        </ListItemButton>
      </ListItem>
    </List>
  );
}
