import React from 'react';
import { useTranslation } from 'react-i18next';

import Alert from '@mui/material/Alert';
import Container from '@mui/material/Container';
import Collapse from '@mui/material/Collapse';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';

import CloseIcon from '@mui/icons-material/Close';

import { useDashboardContext } from '../../_lib/context/dashboard-context';
import { getStringDate } from '../../_utils/general-utils';
import { useLocalStorage } from '../../_utils/hooks/use-local-storage';

function ParentDashboardAlertDetail({ pD, open, setOpen }: any) {
  const { t } = useTranslation();

  const { dashboard } = useDashboardContext();

  return (
    <Collapse in={open[pD.id]}>
      <Alert
        severity="warning"
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={() => {
              setOpen((prev: any) => ({
                ...prev,
                [pD.id]: false,
              }));
            }}
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        }
      >
        {t("This dashboard's data has been shared with another dashboard by")}{' '}
        <Link href={`mailto:${pD.createdBy.email}`} target="_blank">
          {pD.createdBy.name}
        </Link>{' '}
        {t('since')} {getStringDate(pD.createdAt, dashboard.language)}
      </Alert>
    </Collapse>
  );
}

function ParentDashboardAlertList({ open, setOpen }: any) {
  const { dashboard } = useDashboardContext();

  const anyOpen = Object.values(open).some((v: any) => v);

  if (!anyOpen) return null;

  return (
    <Stack spacing={2} sx={{ my: 3 }}>
      {dashboard.parentDashboards.map((pD: any) => (
        <ParentDashboardAlertDetail
          key={pD.id}
          pD={pD}
          open={open}
          setOpen={setOpen}
        />
      ))}
    </Stack>
  );
}

export function ParentDashboardAlert({ includeContainer }: any) {
  const { dashboard, user } = useDashboardContext();
  const [open, setOpen] = useLocalStorage(
    `parent-dashboard-alert`,
    dashboard.parentDashboards?.reduce(
      (acc: any, pD: any) => ({ ...acc, [pD.id]: true }),
      {}
    ) || {}
  );

  if (!dashboard.parentDashboards?.length || !user.isAdmin) return null;

  return includeContainer ? (
    <Container>
      <ParentDashboardAlertList open={open} setOpen={setOpen} />
    </Container>
  ) : (
    <ParentDashboardAlertList open={open} setOopen={setOpen} />
  );
}
