import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CardHeader from '@mui/material/CardHeader';
import Chip from '@mui/material/Chip';

import { getStringDateTime } from '../../_utils/general-utils';
import { DashboardContext } from '../../_lib/context/dashboard-context';

import { ReportsEditActionsMenu } from '../actions/menu';

import BgImage from '../../../assets/img/bg/Modern-Icon-Technology-Pattern.jpg';

export function ReportsHomeDetail({ report }: any) {
  const { t } = useTranslation();
  const history = useHistory();

  const { dashboard } = React.useContext(DashboardContext);

  return (
    <Grid item xs={12} sm={4} lg={3}>
      <Card>
        <CardHeader
          action={<ReportsEditActionsMenu report={report} />}
          title={report.title}
          subheader={`${report.author.name} . 
              ${getStringDateTime(report.updatedAt, dashboard.language)}`}
          subheaderTypographyProps={{ variant: 'caption' }}
        />
        <CardActionArea
          onClick={() =>
            history.push(
              `${t('/reports')}/${report.id}${!report?.pdf ? t('/edit') : ''}`
            )
          }
        >
          <CardMedia
            component="img"
            height="100"
            image={report.image_url || BgImage}
            alt="Report Image"
          />
          <CardContent>
            <Typography
              variant="body2"
              color="textSecondary"
              paragraph={!!report.description}
              component="div"
            >
              <Chip
                label={report.isPublished ? t('Published') : t('Draft')}
                size="small"
                color={report.isPublished ? 'success' : 'default'}
              />
            </Typography>
            {!!report.description && (
              <Typography variant="body1" color="textSecondary">
                {report.description}
              </Typography>
            )}
          </CardContent>
        </CardActionArea>
      </Card>
    </Grid>
  );
}
