import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';

import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';

import AddIcon from '@mui/icons-material/Add';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { useDashboardContext } from '../../_lib/context/dashboard-context';

import { logError } from '../../_lib/error';

import { DialogModal } from '../../components/dialog-modal';

import { TARGET_DELETE } from '../../_lib/graphql/mutations';

import { PlanningTargetsInputList } from './list';

export function TargetsInput({
  addTarget,
  newTargets,
  setNewTargets,

  area,
  setArea,

  errors,
  disabled,

  openCollapse,
  setOpenCollapse,
}: any) {
  const { t } = useTranslation();
  const {
    setSnackbarIsDelete,
    setSnackbarOpen,
    setSnackbarIsError,
    setRecentlyDeletedProps,
  } = useDashboardContext();

  const [confirmDeleteDialogOpen, setConfirmDeleteDialogOpen] =
    React.useState(false);
  const [deleteTargetId, setDeleteTargetId] = React.useState('');

  const [deleteTargetMutation] = useMutation(TARGET_DELETE);

  // ---------------------------------------------------------------------------------------------------------
  // ---------------------------------------------------------------------------------------------------------
  //  handlers
  // ---------------------------------------------------------------------------------------------------------

  // collapse
  const toggleCollapse = React.useCallback(() => {
    setOpenCollapse((prev: any) => !prev);
  }, [setOpenCollapse]);

  const handleAddTarget = React.useCallback(() => {
    setOpenCollapse(true);
    addTarget();
  }, [addTarget, setOpenCollapse]);

  const handleDelete = React.useCallback(
    (target: any) => {
      if (target.fauxId) {
        setNewTargets((prev: any) =>
          prev.filter((tgt: any) => tgt.fauxId !== target.fauxId)
        );
      } else {
        setDeleteTargetId(target.id);
        setConfirmDeleteDialogOpen(true);
      }
    },
    [setNewTargets]
  );

  const deleteTarget = React.useCallback(() => {
    const target = area.targetsDb.find((tgt: any) => tgt.id === deleteTargetId);
    const index = area.targetsDb.findIndex(
      (tgt: any) => tgt.id === deleteTargetId
    );
    deleteTargetMutation({ variables: { id: deleteTargetId } })
      .then(() => {
        setArea((prev: any) => ({
          ...prev,
          targetsDb: prev.targetsDb.filter(
            (tgt: any) => tgt.id !== deleteTargetId
          ),
        }));

        setConfirmDeleteDialogOpen(false);
        setRecentlyDeletedProps({
          objectId: deleteTargetId,
          objectName: 'target',
          object: target,
          successFunc: () => {
            setArea((prev: any) => {
              const tgts = [...prev.targetsDb];
              tgts.splice(index, 0, target);
              return {
                ...prev,
                targetsDb: tgts,
              };
            });
          },
        });
        setSnackbarIsDelete(true);
        setSnackbarOpen(true);
      })
      .catch((e: any) => {
        setSnackbarIsError(true);
        logError(e);
      });
  }, [
    deleteTargetId,
    deleteTargetMutation,
    setArea,
    area.targetsDb,
    setSnackbarIsDelete,
    setSnackbarIsError,
    setSnackbarOpen,
    setRecentlyDeletedProps,
  ]);

  // ---------------------------------------------------------------------------------------------------------

  return (
    <Box sx={{ mt: 3, mb: 0.5, display: 'flex' }}>
      {/* Collapse */}
      <Box sx={{ flexGrow: 0, mr: 1 }}>
        <Tooltip title={`${t('Expand')}`}>
          <IconButton onClick={toggleCollapse}>
            <ExpandMoreIcon
              sx={{
                transform: openCollapse ? 'rotate(0deg)' : 'rotate(-90deg)',
                transition: 'transform 0.2s ease-in-out',
              }}
            />
          </IconButton>
        </Tooltip>
      </Box>

      {/* Content */}
      <Box
        sx={{
          flexGrow: 1,
        }}
      >
        {/* Title and Add Target Button */}
        <Box
          sx={{
            display: { xs: 'block', sm: 'flex' },
            alignItems: 'center',
            justifyContent: 'space-between',
            mb: 1.5,
            mt: 0.5,
          }}
        >
          <Box sx={{ flexShrink: 1 }}>
            <Typography
              variant="h6"
              color={disabled ? 'textSecondary' : 'textPrimary'}
              onClick={toggleCollapse}
            >
              {t('Targets')}{' '}
              <Typography variant="body2" component="span">
                ( {area.targetsDb?.length || 0} )
              </Typography>
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {t(
                'Targets include key performance indicators and qualitative targets you would like to track'
              )}
              <Typography variant="caption" component="span" sx={{ ml: 1 }}>
                <Link
                  href="https://docs.digitaldashboard.cc/docs/#track-quantitative-data-and-targets"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t('Learn More')} &rarr;
                </Link>
              </Typography>
            </Typography>
          </Box>

          {!disabled && (
            <Box sx={{ ml: { xs: 0, sm: 1 }, mt: { xs: 0.5, sm: 0 } }}>
              <Button
                startIcon={<AddIcon />}
                variant="outlined"
                onClick={handleAddTarget}
                size="small"
                sx={{ whiteSpace: 'nowrap' }}
              >
                {t('Add target')}
              </Button>
            </Box>
          )}
        </Box>

        <Collapse in={openCollapse}>
          {/* targets */}
          <PlanningTargetsInputList
            disabled={disabled}
            area={area}
            setArea={setArea}
            newTargets={newTargets}
            setNewTargets={setNewTargets}
            handleDelete={handleDelete}
          />

          {/* confirm delete dialog */}
          <DialogModal
            show={confirmDeleteDialogOpen}
            onSubmit={deleteTarget}
            onDismiss={() => setConfirmDeleteDialogOpen(false)}
            message={t(
              'Deleting this target will also delete all associated updates?'
            )}
            type="confirm-only"
          />

          {/* Errors */}
          {!!errors.targets && (
            <Alert severity="error" sx={{ my: 1 }}>
              {errors.targets}
            </Alert>
          )}
        </Collapse>
      </Box>
    </Box>
  );
}
