import React from 'react';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import MenuIcon from '@mui/icons-material/Menu';

import TbiDdLogo from '../../../assets/img/tbilogo/dashboard.jpg';

export function DashboardAppBarDrawer({
  logoSrc,
  title,
  areasFilter,
  showAreasFilter,
  launchDashboard,
  showLaunchDashboard,
}: any) {
  const [drawerOpen, setDrawerOpen] = React.useState(false);

  const toggleDrawer = (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event?.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }
    setDrawerOpen(!drawerOpen);
  };

  return (
    <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
      <IconButton
        size="large"
        edge="start"
        color="inherit"
        aria-label="open drawer"
        sx={{ mr: 1, displayPrint: 'none' }}
        onClick={toggleDrawer}
      >
        <MenuIcon />
      </IconButton>

      <SwipeableDrawer
        anchor="left"
        open={drawerOpen}
        onClose={toggleDrawer}
        onOpen={toggleDrawer}
      >
        <Box sx={{ width: '250px' }}>
          <Toolbar sx={{ flexDirection: 'column', py: 2 }}>
            <img
              src={
                logoSrc?.includes('/static/images/tbilogo.jpg') &&
                process.env.REACT_APP_IS_V3
                  ? TbiDdLogo
                  : logoSrc
              }
              alt="dashboard logo"
              height="35"
              style={{
                marginBottom: '10px',
              }}
            />
            <Typography variant="h6" component="div" align="center">
              {title}
            </Typography>
          </Toolbar>

          <Divider />

          {/* launch dashboard */}
          {showLaunchDashboard && (
            <Box sx={{ py: 3, px: 2 }}>
              {React.cloneElement(launchDashboard, { fullWidth: true })}
            </Box>
          )}

          {/* areas filter */}
          {showAreasFilter && <Box sx={{ py: 3, px: 2 }}>{areasFilter}</Box>}
        </Box>
      </SwipeableDrawer>
    </Box>
  );
}
