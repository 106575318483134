import React from 'react';

import DoneIcon from '@mui/icons-material/Done';
import FlagIcon from '@mui/icons-material/Flag';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

import { ContentDialogContext } from '../_lib/context/content-dialog-context';

export function CreateContent({ type }: any) {
  const { setContentForm } = React.useContext(ContentDialogContext);

  // ----------------------------------------------------------------------------------------------------
  // ----------------------------------------------------------------------------------------------------
  // handlers
  // ----------------------------------------------------------------------------------------------------

  const handleClick = () => {
    setContentForm({
      open: true,
      defaultContentType: type,
    });
  };

  // ----------------------------------------------------------------------------------------------------
  // ----------------------------------------------------------------------------------------------------
  // effects
  // ----------------------------------------------------------------------------------------------------

  // ----------------------------------------------------------------------------------------------------

  return (
    <>
      {type === 'activity' && (
        <DoneIcon
          id="create-activity"
          sx={{ color: '#66bb6a' }}
          onClick={handleClick}
        />
      )}
      {type === 'milestone' && (
        <FlagIcon
          id="create-milestone"
          sx={{ color: '#2196f3' }}
          onClick={handleClick}
        />
      )}
      {type === 'issue' && (
        <ErrorOutlineIcon
          id="raise-issue"
          sx={{ color: '#ffb300' }}
          onClick={handleClick}
        />
      )}
    </>
  );
}
