import React from 'react';

import { useDashboardContext } from '../../../context/dashboard-context';
import { usePrefetchQuery } from '../use-prefetch-query';

import { PA_QUERY } from '../../../../priority-area/summary';

export function GraphqlPrefetchQueriesArea({ area }: { area: any }) {
  const { navbarFilterVariables } = useDashboardContext();

  const variables = React.useMemo(
    () => ({
      priorityAreaId: area.id,
      ...navbarFilterVariables,
    }),
    [area, navbarFilterVariables]
  );

  const { data } = usePrefetchQuery({
    query: PA_QUERY,
    variables,
  });

  // --------------------------------------------------------------------------------------------------------
  return null;
}
