import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';

import { TabPanel } from '../tab-panel';
import { DashboardFormImportExportList } from './list';
import { DashboardFormImportExportActionsExport } from './actions/export';
import { DashboardFormImportExportActionsImport } from './actions/import';

export function SettingsImportExport({ tabVal, tabInd }: any) {
  const { t } = useTranslation();

  return (
    <TabPanel value={tabVal} index={tabInd}>
      <Box>
        <Typography variant="h5" sx={{ mb: 1 }}>
          {t('Excel Import and Export')}
        </Typography>
        <Typography variant="body1" paragraph color="textSecondary">
          {t('Import and export your planning data in Excel format')}
        </Typography>

        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
          <DashboardFormImportExportActionsExport />
          <DashboardFormImportExportActionsImport />
        </Stack>

        <Divider sx={{ my: 4 }} />

        <DashboardFormImportExportList />
      </Box>
    </TabPanel>
  );
}
