import React from 'react';

import { autoId } from '../../_utils/auto-id';

import * as serviceWorkerRegistration from '../offline/serviceWorkerRegistration';

const AppContext = React.createContext<any>({});

export function AppContextProvider({ children }: any) {
  const [waitingWorker, setWaitingWorker] =
    React.useState<ServiceWorker | null>(null);

  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.getRegistrations().then((registrations) => {
      registrations.forEach((registration) => {
        registration.waiting?.postMessage({ type: 'SKIP_WAITING' });
      });
    });
  }

  serviceWorkerRegistration.register({
    onUpdate: (registration) => {
      if (registration.waiting) {
        setWaitingWorker(registration.waiting);
      }
    },
  });

  const appContextValue = React.useMemo(
    () => ({
      waitingWorker,
    }),
    [waitingWorker]
  );

  // --------------------------------------------------------------------------------------------------------------------
  // --------------------------------------------------------------------------------------------------------------------
  // effects
  // --------------------------------------------------------------------------------------------------------------------

  // track tabs with local storage
  React.useEffect(() => {
    const tabLoadEventHandler = () => {
      const hash = `tab_${autoId()}`;
      sessionStorage.setItem('TabHash', hash);
      const tabs = JSON.parse(localStorage.getItem('TabsOpen') || '{}');
      tabs[hash] = true;
      localStorage.setItem('TabsOpen', JSON.stringify(tabs));
    };

    tabLoadEventHandler();

    const tabUnloadEventHandler = () => {
      const hash = sessionStorage.getItem('TabHash');
      const tabs = JSON.parse(localStorage.getItem('TabsOpen') || '{}');
      if (hash && tabs[hash]) {
        delete tabs[hash];
        localStorage.setItem('TabsOpen', JSON.stringify(tabs));
      }
    };

    window.addEventListener('beforeunload', tabUnloadEventHandler);

    return () =>
      window.removeEventListener('beforeunload', tabUnloadEventHandler);
  }, []);

  // --------------------------------------------------------------------------------------------------------------------

  return (
    <AppContext.Provider value={appContextValue}>
      {children}
    </AppContext.Provider>
  );
}

export function useAppContext() {
  return React.useContext(AppContext);
}
