import { useTranslation } from 'react-i18next';

import Alert from '@mui/material/Alert';

import { useDashboardContext } from '../../../../_lib/context/dashboard-context';

export function AppbarAvatarAlertsEmailsDisabled() {
  const { t } = useTranslation();
  const { dashboard } = useDashboardContext();

  if (dashboard.enableEmailNotifications) return null;

  return (
    <Alert severity="info" sx={{ width: '210px', minWidth: '100%' }}>
      {t(
        'Email notifications for this dashboard are disabled. Your administrator can enable them in the dashboard settings.'
      )}
    </Alert>
  );
}
