import React from 'react';
import { gql, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import { styled } from '@mui/material/';
import Tab from '@mui/material/Tab';
import { TabsProps as StyledTabsProps } from '@mui/material/Tabs';
import TableChartIcon from '@mui/icons-material/TableChart';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Container from '@mui/material/Container';

import {
  FILTER_VARIABLES,
  FILTER_VARIABLES_DEF,
} from '../../../_lib/graphql/fragments';
import { useOnlineStatus } from '../../../_lib/offline/use-online-status';
import { useDashboardContext } from '../../../_lib/context/dashboard-context';

import { CardContainer } from './card/card-container';
import { ExecutivePriorityAreaBreadcrumbs } from './breadcrumbs';
import { InterventionsSummaryStatusBar } from '../../../components/area-summary/interventions-summary/status-bar';
import { InterventionsSummaryTable } from '../../../components/area-summary/interventions-summary/table';
import { PriorityAreaMilestonesSlider } from '../../../priority-area/milestones/slider';
import { TrackerOverview } from '../../../tracker/tracker-overview';
import { SectionTitle } from '../../../components/section-title';
import { ReactErrorComponent } from '../../../_lib/react-error';
import { PriorityAreaTargets } from '../../../priority-area/targets';

const ContentSummaryFragment = gql`
  fragment ExecSummaryContentSummaryFragment on SummaryContentStatusType {
    id
    total
    percDone
    content {
      id
    }
  }
`;

const ExecutiveSummaryContentStatusFragment = gql`
  ${ContentSummaryFragment}
  fragment ExecSummaryContentStatusFragment on SummaryContentType {
    id
    total
    percDone
    completed {
      ...ExecSummaryContentSummaryFragment
    }
    ongoing {
      ...ExecSummaryContentSummaryFragment
    }
    issue {
      ...ExecSummaryContentSummaryFragment
    }
    pending {
      ...ExecSummaryContentSummaryFragment
    }
    overdue {
      ...ExecSummaryContentSummaryFragment
    }
    longoverdue {
      ...ExecSummaryContentSummaryFragment
    }
    unstarted {
      ...ExecSummaryContentSummaryFragment
    }
  }
`;

const PA_FRAGMENT = gql`
  ${ExecutiveSummaryContentStatusFragment}
  fragment ExecutivePriorityAreaFragment on PriorityAreaType {
    id
    reference
    name
    urlRelative
    urlRelativeLevel

    dashboard {
      id
    }

    level {
      id
      name
    }
    levelNext {
      id
      name
      slug
    }
    parent {
      id
      name
      urlRelative
    }
    leads {
      id
      name
      email
    }
    coLeads {
      id
      name
      email
    }
    leadsFreeText
    coLeadsFreeText

    children {
      id
      reference
      name
      urlRelative
      urlRelativeLevel
    }
    childrenCount

    totalActivitiesCount

    interventions {
      id
      name
    }
    interventionsCount

    milestones {
      id
      title

      status

      deadline
      createdAt
      completionDate

      percDone

      intervention {
        id
        reference
        name
      }
    }

    summary {
      contentSummary {
        ...ExecSummaryContentStatusFragment
      }
    }
  }
`;

export const PA_QUERY = gql`
  ${PA_FRAGMENT}
  query PriorityAreaExecutiveSection(
    $priorityAreaId: ID!
    ${FILTER_VARIABLES_DEF}
  ) {
    priorityArea(
      priorityAreaId: $priorityAreaId
      ${FILTER_VARIABLES}
    ) {
      ...ExecutivePriorityAreaFragment
    }
  }
`;

const StyledTabs = styled((props: StyledTabsProps) => (
  <TabList
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))(({ theme }) => ({
  '& .MuiTabs-indicator': {
    display: 'none',
    justifyContent: 'center',
    backgroundColor: '#ffffff',
  },
  '& .MuiTabs-indicatorSpan': {
    maxWidth: 40,
    width: '100%',
    backgroundColor: '#635ee7',
  },
}));

const StyledTabPanel = styled(TabPanel)(({ theme }) => ({
  boxShadow: theme.shadows[0],
}));

export function ExecutivePriorityAreaScreen() {
  const { t } = useTranslation();
  const history = useHistory();
  const { priorityAreaId } = useParams<any>();

  const isOnline = useOnlineStatus();
  const {
    dashboard: { id: contextDashboardId, interventionName },
    navbarFilterVariables,
  } = useDashboardContext();

  const [interventionsPage, setInterventionsPage] = React.useState(0);
  const [interventionsRowsPerPage, setInterventionsRowsPerPage] =
    React.useState(5);

  const { data, loading, error, refetch } = useQuery(PA_QUERY, {
    variables: {
      priorityAreaId,
      ...navbarFilterVariables,
      paginationInterventionsOffset:
        interventionsPage * interventionsRowsPerPage,
      paginationInterventionsLimit: interventionsRowsPerPage,
      paginationChildrenLimit: -1,
    },
    fetchPolicy: isOnline ? 'network-only' : 'cache-only',
  });

  const [value, setValue] = React.useState('1');

  const [summaryCardItems, setSummaryCardItems] = React.useState({} as any);

  const tabStyles = {
    py: 3,
    px: 6,
    background: '#ffffff',
    boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2)',
  };

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  // effects
  React.useEffect(() => {
    if (!data && !loading && !error) refetch();
  }, [data, loading, error, refetch]);

  const tabHeadings = [
    `${interventionName} (${data?.priorityArea?.interventionsCount})`,
    `${t('Milestones')} (${data?.priorityArea?.milestones.length})`,
    t('Activities'),
    t('Targets'),
  ];

  React.useEffect(() => {
    if (data && data.priorityArea) {
      // left card data
      const areaSummary = {
        headerItems: {
          title: data?.priorityArea?.level?.name,
          subtitle: data?.priorityArea?.name,
          areaUrl: data?.priorityArea?.urlRelative,
        },
        contentItems: {
          ...data.priorityArea,
        },
      };
      // left card data
      const childrenSummary = {
        headerItems: {
          title: data?.priorityArea?.levelNext?.name,
          subtitle: '',
          areaUrl: data?.priorityArea?.urlRelative,
        },
        contentItems: {
          ...data.priorityArea,
        },
      };

      setSummaryCardItems({ areaSummary, childrenSummary });
    }
  }, [data]);

  if (error) {
    return <ReactErrorComponent error={error} />;
  }

  if (loading || !Object.keys(summaryCardItems).length) {
    return (
      <Container
        maxWidth={false}
        sx={{
          py: 5,
        }}
      >
        <Skeleton height="700px" variant="rectangular" />
      </Container>
    );
  }

  if (
    data.priorityArea &&
    data.priorityArea.dashboard.id !== contextDashboardId
  ) {
    // not allowed
    return null;
  }

  return (
    <Container
      maxWidth={false}
      sx={{
        pb: 5,
      }}
    >
      <ExecutivePriorityAreaBreadcrumbs priorityArea={data?.priorityArea} />
      <Grid container columnSpacing={2} rowSpacing={2}>
        <Grid container item xs={12} md={12} columnSpacing={2} rowSpacing={2}>
          {/* excutive priority area summary card left */}
          <Grid item xs={12} md={6}>
            <CardContainer
              sx={{
                p: 3,
                borderRadius: '4px',
                height: '100%',
              }}
              headerItems={summaryCardItems?.areaSummary?.headerItems}
              contentItems={summaryCardItems?.areaSummary?.contentItems}
            />
          </Grid>

          {/* excutive priority area summary card right */}
          {data?.priorityArea?.levelNext && (
            <Grid item xs={12} md={6}>
              <CardContainer
                sx={{
                  p: 3,
                  borderRadius: '4px',
                  height: '100%',
                }}
                headerItems={summaryCardItems?.childrenSummary?.headerItems}
                contentItems={summaryCardItems?.childrenSummary?.contentItems}
                isChildInfo
              />
            </Grid>
          )}
        </Grid>
        <Grid item xs={12} md={12}>
          <TabContext value={value}>
            <StyledTabs
              value={value}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="scrollable auto tabs example"
              sx={{
                '&.MuiTabs-root .MuiTabs-scroller .MuiTabs-flexContainer .MuiTab-root:not(:first-of-type)':
                  {
                    ml: '5px',
                  },
              }}
              defaultValue="1"
            >
              {tabHeadings?.map((item, index) => {
                return (
                  <Tab
                    disableRipple
                    key={item}
                    label={item}
                    value={`${index + 1}`}
                    sx={{
                      '&.MuiTab-root.Mui-selected': {
                        background: '#ffffff',
                        color: '#000000',
                      },
                      background: '#CCCCCC',
                      px: 6,
                    }}
                  />
                );
              })}
            </StyledTabs>

            {/* interventions */}
            <StyledTabPanel value="1" sx={tabStyles}>
              {/* status bar graph */}
              <InterventionsSummaryStatusBar
                height="17px"
                borderRadius="3px 3px 0 0"
                variables={{ priorityAreaId }}
              />

              {/* interventions table */}
              <InterventionsSummaryTable
                showPriorityArea
                data={{
                  interventions: {
                    items: data?.priorityArea?.interventions || [],
                    meta: {
                      count: data?.priorityArea?.interventionsCount || 0,
                    },
                  },
                }}
                page={interventionsPage}
                setPage={setInterventionsPage}
                rowsPerPage={interventionsRowsPerPage}
                setRowsPerPage={setInterventionsRowsPerPage}
                openInNewTab
              />
            </StyledTabPanel>

            {/* milestones tab */}
            <StyledTabPanel value="2" sx={tabStyles}>
              <PriorityAreaMilestonesSlider
                milestones={data?.priorityArea?.milestones || []}
              />
            </StyledTabPanel>

            {/* activities tab */}
            <StyledTabPanel value="3" sx={tabStyles}>
              <SectionTitle
                title={t('Activities')}
                subheader={t(
                  'Summary of upcoming, overdue, and pending activities and issues'
                )}
                actions={
                  <Button
                    variant="contained"
                    startIcon={<TableChartIcon />}
                    onClick={() =>
                      history.push(
                        `${data?.priorityArea?.urlRelative}${t('/tracker')}`
                      )
                    }
                    disableElevation
                    sx={{ displayPrint: 'none' }}
                  >
                    {t('Open Activity Tracker')}
                  </Button>
                }
                sx={{ mb: 2 }}
              />
              <TrackerOverview priorityAreas={[data?.priorityArea]} />
            </StyledTabPanel>

            {/* Targets tab */}
            <StyledTabPanel value="4" sx={{ pb: 4, ...tabStyles }}>
              <PriorityAreaTargets tableOnly />
            </StyledTabPanel>
          </TabContext>
        </Grid>
      </Grid>
    </Container>
  );
}
