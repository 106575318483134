import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';

import LockResetOutlinedIcon from '@mui/icons-material/LockResetOutlined';

import { useDashboardContext } from '../../../_lib/context/dashboard-context';

import { ChangePasswordDialog } from '../../../navbar/navbar-menu/change-password/dialog';

import { CalendarURL } from './calendar-url';
import { TwoFactorSettings } from './two-factor';

export function MyAccountDetails() {
  const { t } = useTranslation();
  const { dashboard, user } = useDashboardContext();

  const [openChangePasswordDialog, setOpenChangePasswordDialog] =
    React.useState(false);

  return (
    <>
      {/* change password dialog */}
      <ChangePasswordDialog
        open={openChangePasswordDialog}
        setOpen={setOpenChangePasswordDialog}
      />

      <Card sx={{ mb: 6, px: 3, py: 4 }}>
        <Stack spacing={3}>
          <TextField
            type="email"
            label={t('Email address')}
            value={user.email}
            disabled
            fullWidth
          />

          <Button
            variant="contained"
            onClick={() => setOpenChangePasswordDialog(true)}
            sx={{ textTransform: 'none' }}
            startIcon={<LockResetOutlinedIcon />}
          >
            {t('Change Password')}
          </Button>

          {/* 2 factor authentication */}
          <Divider />
          <TwoFactorSettings />

          {/* calendar url */}
          {dashboard.enableCalendarLink && [
            <Divider key="my-account-calendar-divider" />,
            <CalendarURL key="my-account-calendar" />,
          ]}
        </Stack>
      </Card>
    </>
  );
}
