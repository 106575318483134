import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Skeleton from '@mui/material/Skeleton';

import { getMediaUrl } from '../../_utils/general-utils';

import { useDashboardContext } from '../../_lib/context/dashboard-context';
import { useReportsContext } from '../../_lib/context/reports-context';

import { ReportsTitle } from '../title';
import { ReportActionDownload } from '../actions/download';
import { ReportActionShare } from '../actions/share';
import { ReportsEditActionsMenu } from '../actions/menu';
import { ReportsEditReportDataReport } from '../edit/report-data/report';

export function ReportsView() {
  const { t } = useTranslation();
  const history = useHistory();
  const { reportId } = useParams() as any;

  const { dashboard } = useDashboardContext();
  const { report } = useReportsContext();

  const formEl = React.useRef(null);

  // ------------------------------------------------------------------------------------------------------------------------

  return (
    <>
      <form ref={formEl} noValidate autoComplete="off">
        <ReportsTitle
          primary={report?.title}
          secondary={
            report?.deletedAt ? (
              <Chip label={t('Deleted')} size="small" color="error" />
            ) : !report?.publishedAt ? (
              <Chip label={t('Draft')} size="small" color="default" />
            ) : (
              <Chip label={t('Published')} size="small" color="success" />
            )
          }
          actions={
            <Stack
              spacing={1}
              direction="row"
              display={report?.deletedAt ? 'none' : 'flex'}
            >
              <Button
                onClick={() =>
                  history.push(`${t('/reports')}/${reportId}${t('/edit')}`)
                }
                disabled={!report}
              >
                {t('Edit')}
              </Button>

              <ReportActionShare report={report} disabled={!report} />
              <ReportsEditActionsMenu
                report={report}
                hideEdit
                hideShare
                disabled={!report}
              />
            </Stack>
          }
        />

        <Container sx={{ py: 2 }}>
          <Paper sx={{ my: 3, px: 3, py: 4 }}>
            <Grid container spacing={3}>
              {/* pdf */}
              <Grid item sm={8} xs={12}>
                {!report ? (
                  <Skeleton variant="rectangular" height={800} />
                ) : (
                  <Box>
                    {report?.pdf?.url && (
                      <iframe
                        src={getMediaUrl(report.pdf.url)}
                        title={`${dashboard.name}.pdf`}
                        height="800px"
                        width="100%"
                      />
                    )}
                  </Box>
                )}
              </Grid>

              {/* side bar / actions */}
              <Grid item sm={4} xs={12}>
                <Stack>
                  <ReportActionDownload report={report} disabled={!report} />
                </Stack>
                <Divider sx={{ mt: 3, mb: 2 }} />

                {/* todo: report details: description, author, date created */}

                {/* todo: updates/comments */}
              </Grid>
            </Grid>
          </Paper>
        </Container>
      </form>

      {/* ------------------------------------------------------------------------------------------------------------------------ */}
      {/* data fetch */}
      {/* ------------------------------------------------------------------------------------------------------------------------ */}

      <ReportsEditReportDataReport fetchAssets />
    </>
  );
}
