import { Column } from '@material-table/core';
import Typography from '@mui/material/Typography';

// Assignee column
export const assigneeColumns = ({ t, status, dashboard }: any) => [
  {
    id: 'id',
    title: t('Assigned to'),
    field: !dashboard.enableActivityMultipleAssignees
      ? 'assignee.name'
      : 'assignees',
    hidden: status === 'milestones',
    emptyValue: () => (
      <Typography component="span" variant="body2" color="text.secondary">
        {t('Unassigned')}
      </Typography>
    ),
    render: (content: any) => {
      if (typeof content === 'string') {
        return (
          <Typography component="span" variant="body1">
            {content}
          </Typography>
        );
      }

      return (
        <>
          <Typography
            variant="body2"
            sx={{
              whiteSpace: 'nowrap',
              maxWidth: '180px',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {content.type !== 'issue'
              ? dashboard.enableActivityMultipleAssignees
                ? content.assignees?.map((a: any) => a.name).join(', ')
                : content.assignee?.name
              : '--'}
          </Typography>
          <Typography
            component="div"
            variant="caption"
            color="text.secondary"
            sx={{
              whiteSpace: 'nowrap',
              maxWidth: '180px',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {content.type !== 'issue'
              ? dashboard.enableActivityMultipleAssignees
                ? content.assignees?.map((a: any) => a.email).join(', ')
                : content.assignee?.email
              : '--'}
          </Typography>
        </>
      );
    },
    customExport: (content: any) =>
      content.type !== 'issue'
        ? dashboard.enableActivityMultipleAssignees
          ? content.assignees?.map((a: any) => a.name).join(', ')
          : content.assignee?.name
        : '',
  } as Column<any>,
];
