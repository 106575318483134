/* eslint-disable react/no-array-index-key */
import React from 'react';
import Box, { BoxProps } from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';

export function LinearProgressWithParts(
  props: BoxProps & {
    values: {
      value: number;
      label: string;
      color: string;
      onMouseEnter?: (e: any) => void;
      onMouseLeave?: () => void;
    }[];
    disableTooltip?: boolean;
  }
) {
  const { values, sx, disableTooltip } = props;

  const valuesTotal = React.useMemo(
    () => values.reduce((acc, cur) => acc + cur.value, 0),
    [values]
  );
  const parts = React.useMemo(
    () =>
      values.map((value: any, index: number) => ({
        ...value,
        id: index,
        perc: (value.value / valuesTotal) * 100,
      })),
    [values, valuesTotal]
  );

  const boxParts = React.useMemo(
    () =>
      parts.map((part: any) => (
        <Box
          key={part.id}
          sx={{
            width: `${part.perc}%`,
            bgcolor: part.color,
            display: 'inline-block',
            cursor: 'pointer',
          }}
          onMouseEnter={part.onMouseEnter}
          onMouseLeave={part.onMouseLeave}
        />
      )),
    [parts]
  );

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Box
        sx={{
          borderRadius: 1,
          bgcolor: 'text.secondary',
          display: 'flex',
          overflow: 'hidden',
          ...sx,
        }}
      >
        {!disableTooltip
          ? boxParts.map((part: any, index: number) => (
              <Tooltip
                key={index}
                placement="bottom"
                title={`${parts[index].label}: ${
                  parts[index].value
                } (${Math.round(parts[index].perc)}%)`}
                arrow
              >
                {part}
              </Tooltip>
            ))
          : boxParts.map((part: any) => part)}
      </Box>
    </Box>
  );
}

LinearProgressWithParts.defaultProps = {
  disableTooltip: false,
};
