import React from 'react';

import { getReportTemplateMetas } from '../../../../_utils/reports/get-report-template-meta';

import { Page } from '../page';

import { ReportsPdfUiHeaderArea } from '../ui/header/area';
import { ReportsPdfUiSectionTitle } from '../ui/section-title';

import { PdftTemplateSectionsAreaHeader } from '../sections/area-header';

import { ReportPdfSectionAreaContent } from '../sections/area-content';

export function PdftTemplatePageArea({
  filterArea,
  user,
  isNew,
  report,
  reportData,
  filterAreas,
  t,
}: any) {
  const reportsTemplateMetas = React.useMemo(
    () => getReportTemplateMetas(reportData.dashboard.reportsTemplateMeta),
    [reportData.dashboard.reportsTemplateMeta]
  );

  // determine the area
  const { priorityAreas } = reportData;
  let area = null as any;
  let areaName = '';

  priorityAreas.forEach((pa: any) => {
    if (filterArea === `pa-${pa.id}`) {
      area = pa;
      areaName = !reportData.dashboard.enableAreaLevels
        ? reportData.dashboard.priorityAreaName
        : pa.level.name;
    }
    pa.interventionsDb?.forEach((intv: any) => {
      if (filterArea === `intv-${intv.id}`) {
        area = intv;
        areaName = reportData.dashboard.interventionName;
      }
    });
  });

  if (!area) return null;

  // -------------------------------------------------------------------------------------------------

  return (
    <Page
      pageOrientation={
        reportsTemplateMetas?.pageOrientation === 'LANDSCAPE'
          ? 'landscape'
          : 'portrait'
      }
    >
      <ReportsPdfUiHeaderArea area={area} areaName={areaName} />

      {/* area header */}
      <ReportsPdfUiSectionTitle
        primary={t('Principal Characteristics')}
        reportData={reportData}
      />
      <PdftTemplateSectionsAreaHeader
        t={t}
        reportData={reportData}
        area={area}
        areaName={areaName}
      />

      {/* are content */}
      <ReportPdfSectionAreaContent
        reportData={reportData}
        t={t}
        areas={[area]}
        filterArea={filterArea}
      />
    </Page>
  );
}
