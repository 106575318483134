import { useTranslation } from 'react-i18next';
import { gql } from '@apollo/client';

import { PlanningFieldsInputAutocomplete } from './_autocomplete';

const QUERY_ITEMS = gql`
  query Financiers {
    financiers {
      id
      name
    }
  }
`;

const SUBSCRIBE_ITEMS = gql`
  subscription Financiers {
    financiers {
      id
      name
    }
  }
`;

const CREATE_ITEM = gql`
  mutation FinancierCreate($name: String!) {
    financierCreate(name: $name) {
      financiers {
        id
        name
      }
    }
  }
`;

const UPDATE_ITEM = gql`
  mutation FinancierUpdate($id: ID!, $name: String!) {
    financierUpdate(id: $id, name: $name) {
      success
      message
      financiers {
        id
        name
      }
    }
  }
`;

const DELETE_ITEM = gql`
  mutation FinancierDelete($id: ID!) {
    financierDelete(id: $id) {
      success
      message
      financiers {
        id
        name
      }
    }
  }
`;

const UPDATE_ITEM_ORDER = gql`
  mutation FinancierUpdateOrder($ids: [ID!]!) {
    financierUpdateOrder(ids: $ids) {
      success
      message
      financiers {
        id
        name
      }
    }
  }
`;

export function PlanningFieldsInputFinancier({
  disabled,
  area,
  setArea,
  error,
  label,
}: any) {
  const { t } = useTranslation();

  // ----------------------------------------------------------------------------------------

  return (
    <PlanningFieldsInputAutocomplete
      disabled={disabled}
      area={area}
      setArea={setArea}
      GET_ITEMS={QUERY_ITEMS}
      SUBSCRIBE_ITEMS={SUBSCRIBE_ITEMS}
      CREATE_ITEM={CREATE_ITEM}
      createItemOpName="financierCreate"
      UPDATE_ITEM={UPDATE_ITEM}
      updateItemOpName="financierUpdate"
      DELETE_ITEM={DELETE_ITEM}
      deleteItemOpName="financierDelete"
      UPDATE_ITEM_ORDER={UPDATE_ITEM_ORDER}
      updateItemOrderOpName="financierUpdateOrder"
      dataKey="financiers"
      manageDialogTitle={t('Manage Financiers')}
      manageDialogInputLabel={t('Name')}
      manageDialogAddLabel={t('Add Financier')}
      label={label}
      sx={{
        flexGrow: 1,
      }}
      disableClearable
      error={error}
    />
  );
}
