import React from 'react';
import { gql, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import Button from '@mui/material/Button';

import { getMediaUrl } from '../../../../_utils/general-utils';
import { useOnlineStatus } from '../../../../_lib/offline/use-online-status';

const ITEM_QUERY = gql`
  query ImportExportItem($id: ID!) {
    importExport(id: $id) {
      id
      file {
        url
      }
    }
  }
`;

export function ImportExportActionsDownload({ item }: any) {
  const { t } = useTranslation();

  const isOnline = useOnlineStatus();

  const { data, refetch } = useQuery(ITEM_QUERY, {
    variables: { id: item.id },
  });

  // check if the file is available
  React.useEffect(() => {
    if (isOnline && !!data?.importExport?.file?.url) {
      fetch(getMediaUrl(data.importExport.file.url)).catch(() => {
        refetch();
      });
    }
  }, [data?.importExport?.file?.url, isOnline, refetch, item]);

  if (!data?.importExport?.file?.url) return null;

  return (
    <Button
      size="small"
      sx={{ ml: 1 }}
      href={getMediaUrl(data.importExport.file.url)}
      target="_blank"
      download
    >
      {t('Download')}
    </Button>
  );
}
