import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import PaletteIcon from '@mui/icons-material/Palette';
import { LoadingButton } from '@mui/lab';

import { SettingsColorOptionRow } from '../../../../components/settings-color-option-row';

import { DashboardContext } from '../../../../_lib/context/dashboard-context';
import { DASHBOARD_UPDATE } from '../../../../_lib/graphql/mutations';
import { DialogTitle } from '../../../../DialogTitle/dialog-title';
import { logError } from '../../../../_lib/error';

export function ContentStatusColorsDialog({ open, setOpen }: any) {
  const { t } = useTranslation();
  const { dashboard, setSnackbarOpen, setSnackbarIsError } =
    React.useContext(DashboardContext);

  const [dashboardUpdateMutation] = useMutation(DASHBOARD_UPDATE);

  const [options, setOptions] = React.useState<any>(dashboard.metaStatusColors);
  const [loading, setLoading] = React.useState(false);

  // -----------------------------------------------------------------------------------------
  // -----------------------------------------------------------------------------------------
  // handlers
  // -----------------------------------------------------------------------------------------

  const handleCancel = () => {
    setOptions(dashboard.metaStatusColors);
    setLoading(false);
    setOpen(false);
  };

  const handleSave = () => {
    setLoading(true);
    dashboardUpdateMutation({
      variables: { metaStatusColors: JSON.stringify(options) },
    })
      .catch((err) => {
        logError(err);
        setSnackbarIsError(true);
      })
      .finally(() => {
        setLoading(false);
        setSnackbarOpen(true);
      });
  };

  // -----------------------------------------------------------------------------------------
  // -----------------------------------------------------------------------------------------
  // effects
  // -----------------------------------------------------------------------------------------

  React.useEffect(() => {
    setOptions(dashboard.metaStatusColors);
  }, [dashboard]);

  // -----------------------------------------------------------------------------------------

  return (
    <Dialog open={open} maxWidth="sm" fullWidth>
      <DialogTitle onClose={() => setOpen(false)}>
        <PaletteIcon sx={{ mr: 2 }} />
        {t('Activity Status Colours')}
      </DialogTitle>
      <DialogContent dividers>
        <TableContainer component={Paper} variant="outlined">
          <Table
            sx={{
              minWidth: '100%',
              '& .MuiTableCell-root': {
                whiteSpace: 'nowrap',
                textAlign: 'center',
              },
            }}
            aria-label="status colors table"
          >
            <TableHead>
              <TableRow>
                <TableCell>{t('Preview')}</TableCell>
                <TableCell>{t('Text Colour')}</TableCell>
                <TableCell>{t('Background Colour')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.keys(options)
                .filter(
                  (statusKey: any) =>
                    !(
                      !dashboard.enableActivityStatusLongOverdue &&
                      statusKey === 'longoverdue'
                    )
                )
                .map((statusKey) => (
                  <SettingsColorOptionRow
                    key={statusKey}
                    option={options[statusKey]}
                    optionKey={statusKey}
                    options={options}
                    setOptions={setOptions}
                  />
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions sx={{ p: 2 }}>
        <Button onClick={() => handleCancel()} disabled={loading}>
          {t('Cancel')}
        </Button>
        <LoadingButton
          variant="contained"
          onClick={() => handleSave()}
          loading={loading}
        >
          {t('Save')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
