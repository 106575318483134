import { ErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';

import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import ButtonGroup from '@mui/material/ButtonGroup';
import Typography from '@mui/material/Typography';

import { useLocalStorage } from '../../_utils/hooks/use-local-storage';

import { ReactErrorComponent } from '../../_lib/react-error';

import { MapTargets } from './map-targets';
import { TargetsSummaryTable } from '../targets-summary/table';
import { LinearProgressWithLabel } from '../linear-progress/with-label';

export function AreaTargets({
  area,
  rowsPerPage,
  setRowsPerPage,
  page,
  setPage,
  tableOnly,
}: any) {
  const { t } = useTranslation();

  const [tabValue, setTabValue] = useLocalStorage(
    `${area.__typename}-${area.id}-area-summary-targets-tab-value`,
    0
  );

  return (
    <ErrorBoundary FallbackComponent={ReactErrorComponent}>
      <Typography variant="h4">{t('Targets')}</Typography>

      <Box mb={tableOnly ? 2 : undefined}>
        {/* total */}
        <LinearProgressWithLabel
          sx={{ height: '15px', borderRadius: '3px' }}
          value={area.summary.targets?.progressPerc}
        />
      </Box>

      {!tableOnly && (
        <Box sx={{ my: 2 }}>
          <ButtonGroup disableElevation>
            <Button
              variant={tabValue === 0 ? 'contained' : 'outlined'}
              onClick={() => setTabValue(0)}
            >
              {t('Charts')}
            </Button>
            <Button
              variant={tabValue === 1 ? 'contained' : 'outlined'}
              onClick={() => setTabValue(1)}
            >
              {t('Table')}
            </Button>
          </ButtonGroup>
        </Box>
      )}

      {tabValue === 0 && !tableOnly ? (
        <MapTargets
          targetsSummary={area.summary.targets}
          container={area}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          page={page}
          setPage={setPage}
        />
      ) : (
        <TargetsSummaryTable
          targetsSummary={area.summary.targets}
          area={area}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          page={page}
          setPage={setPage}
        />
      )}
    </ErrorBoundary>
  );
}
