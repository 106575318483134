import React from 'react';

import { useDashboardContext } from '../_lib/context/dashboard-context';

import { TrackerProvider } from '../_lib/context/tracker-context';

import { TrackerTabs } from './tabs';
import { TrackerTable } from './tracker-table';
import { TrackerSpeedDial } from './tracker-speed-dial';

export function Tracker({ content, areaTitle }: any) {
  const { dashboard } = useDashboardContext();

  return (
    <TrackerProvider content={content}>
      <TrackerTabs />
      <TrackerTable areaTitle={areaTitle} />
      {dashboard.mode !== 'SUPRANATIONAL' && <TrackerSpeedDial />}
    </TrackerProvider>
  );
}
