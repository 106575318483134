import React from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import { useDashboardContext } from '../../../../_lib/context/dashboard-context';

import { CONTENT_GROUPS_QUERY } from '../../../../_lib/graphql/queries';
import { CONTENT_GROUPS_SUBSCRIPTION } from '../../../../_lib/graphql/subscriptions';
import {
  CONTENT_GROUP_CREATE,
  CONTENT_GROUP_DELETE,
  CONTENT_GROUP_UPDATE,
  CONTENT_GROUP_UPDATE_ORDER,
} from '../../../../_lib/graphql/mutations';

import { DragggableDialogDialog } from '../../../../components/draggable-dialog/dialog';

import { logError } from '../../../../_lib/error';
import { SubscribeToMoreComponent } from '../../../../components/subscribe-to-more';

export function ContentGroupsManageDialog({
  open,
  setOpen,
  disableEnforceFocus,
}: any) {
  const { t } = useTranslation();

  const { setSnackbarIsError, setSnackbarOpen, dashboard } =
    useDashboardContext();

  const { loading, data, error, subscribeToMore } =
    useQuery(CONTENT_GROUPS_QUERY);

  const [details, setDetails] = React.useState<any>([]);

  // ----------------------------------------------------------------------------------------------------------

  const [createContentGroupMutation] = useMutation(CONTENT_GROUP_CREATE);
  const [updateContentGroupMutation] = useMutation(CONTENT_GROUP_UPDATE);
  const [deleteContentGroupMutation] = useMutation(CONTENT_GROUP_DELETE);
  const [contentGroupsUpdateOrderMutation] = useMutation(
    CONTENT_GROUP_UPDATE_ORDER
  );

  const createMutation = (value: any, weight?: any) =>
    createContentGroupMutation({
      variables: {
        title: value,
        weight: Number.parseFloat(weight),
      },
    });

  const updateMutation = ({ id, value, weight }: any) =>
    updateContentGroupMutation({
      variables: {
        id,
        title: value,
        weight: Number.parseFloat(weight),
      },
    });

  const deleteMutation = (id: number) =>
    deleteContentGroupMutation({ variables: { id } });

  const updateOrderMutation = (contentGroupIds: number[]) =>
    contentGroupsUpdateOrderMutation({
      variables: {
        contentGroupIds,
      },
    });

  // ----------------------------------------------------------------------------------------------------------
  // ----------------------------------------------------------------------------------------------------------
  // effects
  // ----------------------------------------------------------------------------------------------------------

  React.useEffect(() => {
    if (error) {
      logError(error);
      setSnackbarIsError(true);
      setSnackbarOpen(true);
      setOpen(false);
    }
  }, [error, setSnackbarIsError, setSnackbarOpen, setOpen]);

  React.useEffect(() => {
    if (data) {
      const weightSum = data.contentGroups?.reduce(
        (acc: number, cur: any) => acc + cur.weight,
        0
      );
      setDetails(
        data.contentGroups?.map((group: any) => ({
          id: group.id,
          value: group.title,
          weight: group.weight,
          weightRatio: group.weight / weightSum,
        }))
      );
    }
  }, [data]);

  // ----------------------------------------------------------------------------------------------------------

  return (
    <>
      <DragggableDialogDialog
        open={open}
        setOpen={setOpen}
        details={details}
        setDetails={setDetails}
        title={t('Manage Activity Phases')}
        inputLabel={t('Title')}
        addLabel={t('Add Activity Phase')}
        createMutation={createMutation}
        updateMutation={updateMutation}
        deleteMutation={deleteMutation}
        updateOrderMutation={updateOrderMutation}
        disableEnforceFocus={disableEnforceFocus}
        loadingData={loading}
        includeWeight={dashboard.enableActivityGroupWeights}
      />

      {/*  subscriptions */}
      {!!data && (
        <SubscribeToMoreComponent
          subscribeToMore={subscribeToMore}
          document={CONTENT_GROUPS_SUBSCRIPTION}
        />
      )}
    </>
  );
}
