import { useTranslation } from 'react-i18next';

import { PlanningFieldsInputManageDialog } from '../../../fields-input/_manage-dialog';

import { AREA_LEVELS_QUERY } from '../../../../_lib/graphql/queries';
import { AREA_LEVELS_SUBSCRIPTION } from '../../../../_lib/graphql/subscriptions';
import {
  AREA_LEVEL_CREATE,
  AREA_LEVEL_DELETE,
  AREA_LEVEL_UPDATE,
  AREA_LEVEL_UPDATE_ORDER,
} from '../../../../_lib/graphql/mutations';

export function AdvancedAreaLevelsDialog({ open, setOpen }: any) {
  const { t } = useTranslation();

  return (
    <PlanningFieldsInputManageDialog
      open={open}
      setOpen={setOpen}
      GET_ITEMS={AREA_LEVELS_QUERY}
      SUBSCRIBE_ITEMS={AREA_LEVELS_SUBSCRIPTION}
      CREATE_ITEM={AREA_LEVEL_CREATE}
      createItemOpName="areaLevelCreate"
      UPDATE_ITEM={AREA_LEVEL_UPDATE}
      updateItemOpName="areaLevelUpdate"
      DELETE_ITEM={AREA_LEVEL_DELETE}
      deleteItemOpName="areaLevelDelete"
      UPDATE_ITEM_ORDER={AREA_LEVEL_UPDATE_ORDER}
      updateItemOrderOpName="areaLevelUpdateOrder"
      dataKey="areaLevels"
      dialogTitle={t('Manage Priority Area Levels')}
      dialogInputLabel={t('Name')}
      dialogAddLabel={t('Add Priority Area Level')}
    />
  );
}
