import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';

import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

import SwapHorizRoundedIcon from '@mui/icons-material/SwapHorizRounded';

import { SWITCH_DASHBOARD } from '../../../_lib/graphql/mutations';

import { SwitchDashboardDialogReload } from './reload';
import { logError } from '../../../_lib/error';
import { useDashboardContext } from '../../../_lib/context/dashboard-context';

export function SwitchDashboardDialog({ open, setOpen, dashboardId }: any) {
  const { t } = useTranslation();

  const { setSnackbarOpen, setSnackbarIsError } = useDashboardContext();

  const [switchDashboardMutation] = useMutation(SWITCH_DASHBOARD);

  // --------------------------------------------------------------------------------------------------------------------
  // --------------------------------------------------------------------------------------------------------------------
  // handlers
  // --------------------------------------------------------------------------------------------------------------------

  // switch to dashboard
  const handleSwitchDashboard = React.useCallback(
    () =>
      switchDashboardMutation({
        variables: {
          dashboardId,
        },
      })
        .then(() => {
          window.localStorage.clear();
          window.location.pathname = '/';
          window.location.reload();
        })
        .catch((err) => {
          logError(err);
          setSnackbarIsError(true);
          setSnackbarOpen(true);
          setOpen(false);
        }),
    [
      dashboardId,
      setOpen,
      setSnackbarIsError,
      setSnackbarOpen,
      switchDashboardMutation,
    ]
  );

  // --------------------------------------------------------------------------------------------------------------------
  // --------------------------------------------------------------------------------------------------------------------
  // effects
  // --------------------------------------------------------------------------------------------------------------------

  React.useEffect(() => {
    if (open && dashboardId) {
      handleSwitchDashboard();
    }
  }, [dashboardId, handleSwitchDashboard, open]);

  // --------------------------------------------------------------------------------------------------------------------

  return (
    <Dialog open={open && !!dashboardId} maxWidth="xs" fullWidth>
      <DialogTitle color="primary">
        <SwapHorizRoundedIcon sx={{ mr: 2 }} />
        {t('Switching Dashboard')}
      </DialogTitle>
      <DialogContent sx={{ p: 3, textAlign: 'center' }}>
        <Stack sx={{ my: 7, alignItems: 'center' }} spacing={2}>
          <CircularProgress />
          <Typography variant="body1" color="primary">
            {t('Loading')}...
          </Typography>

          {/* reload button */}
          {open && <SwitchDashboardDialogReload />}
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
