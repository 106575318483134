import React from 'react';
import { useMutation } from '@apollo/client';
import { useHistory, useLocation } from 'react-router-dom';

import { logError } from '../../_lib/error';
import { useContentDialogContext } from '../../_lib/context/content-dialog-context';
import { useDashboardContext } from '../../_lib/context/dashboard-context';
import { CONTENT_QUERY } from '../../_lib/graphql/queries';
import { CONTENT_CREATE, CONTENT_UPDATE } from '../../_lib/graphql/mutations';

import { ContentFormForm } from './form';

export function ContentForm() {
  const location = useLocation();
  const history = useHistory();

  const { setSnackbarIsError, setSnackbarOpen } = useDashboardContext();
  const { contentForm, setContentForm, contentId } = useContentDialogContext();

  const [loading, setLoading] = React.useState(false);

  // ----------------------------------------------------------------------------------------------------------

  const [contentCreateMutation] = useMutation(CONTENT_CREATE);

  const [contentUpdateMutation] = useMutation(CONTENT_UPDATE, {
    update: (cache, { data }) => {
      cache.writeQuery({
        query: CONTENT_QUERY,
        data: { content: data.contentUpdate.content },
        variables: { id: contentId },
      });
    },
  });

  // ----------------------------------------------------------------------------------------------------------
  // ----------------------------------------------------------------------------------------------------------
  // handlers
  // ----------------------------------------------------------------------------------------------------------

  const handleSubmit = (content: any) => {
    setLoading(true);

    const mutation = contentForm?.isEdit
      ? contentUpdateMutation
      : contentCreateMutation;

    return mutation({
      variables: {
        ...content,
      },
    })
      .then((result) => {
        setContentForm(null);

        if (!contentForm?.isEdit) {
          // open the created item
          const newContent = result.data.contentCreate.content;
          const params = new URLSearchParams(location.search);
          params.set('content', newContent.id);
          history.push(`${location.pathname}?${params.toString()}`);
        }
      })
      .catch((err) => {
        logError(err);
        setSnackbarIsError(true);
      })
      .finally(() => {
        setSnackbarOpen(true);
        setLoading(false);
      });
  };

  // ----------------------------------------------------------------------------------------------------------
  if (!contentForm?.open) return null;

  return (
    <ContentFormForm
      loading={loading}
      setLoading={setLoading}
      handleSubmit={handleSubmit}
    />
  );
}
