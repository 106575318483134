import React from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Container from '@mui/material/Container';
import Skeleton from '@mui/material/Skeleton';

import { PRIORITY_AREA_TRACKER_QUERY } from '../../_lib/graphql/queries';
import { PRIORITY_AREA_TRACKER_SUBSCRIPTION } from '../../_lib/graphql/subscriptions';

import { ReactError } from '../../_lib/react-error';
import { useDashboardContext } from '../../_lib/context/dashboard-context';

import { Tracker } from '../../tracker';
import { LayoutDashboard } from '../../_layout/dashboard';
import { SubscribeToMore } from '../../components/subscribe-to-more';

export function PriorityAreaLoaderTracker() {
  const { priorityAreaId } = useParams<any>();

  const { navbarFilterVariables } = useDashboardContext();

  const variables = {
    priorityAreaId,
    ...navbarFilterVariables,
  };

  const { loading, error, data, refetch, subscribeToMore } = useQuery(
    PRIORITY_AREA_TRACKER_QUERY,
    {
      variables,
    }
  );

  // ------------------------------------------------------------------------------------------------------------------
  // ------------------------------------------------------------------------------------------------------------------
  // effects
  // ------------------------------------------------------------------------------------------------------------------

  // ------------------------------------------------------------------------------------------------------------------

  if (error) return <ReactError error={error} />;

  if (loading || !data) {
    if (!data && !loading) refetch();
    return (
      <Container maxWidth={false} sx={{ my: 4 }}>
        <Skeleton variant="rectangular" width="100%" height={500} />
      </Container>
    );
  }
  // ------------------------------------------------------------------------------------------------------------------

  return (
    <LayoutDashboard>
      <Container maxWidth={false} sx={{ my: 4 }}>
        <Card id="tracker">
          <CardContent>
            <Tracker
              content={data.priorityArea.content}
              areaTitle={`${data.priorityArea.reference}.${data.priorityArea.name}`}
            />
          </CardContent>
        </Card>
      </Container>

      {/* subscription */}
      {!!data && (
        <SubscribeToMore
          subscribeToMore={() =>
            subscribeToMore({
              document: PRIORITY_AREA_TRACKER_SUBSCRIPTION,
              variables,
              updateQuery: (prev, { subscriptionData }) => {
                if (!subscriptionData.data) return prev;
                return subscriptionData.data;
              },
            })
          }
          variables={variables}
        />
      )}
    </LayoutDashboard>
  );
}
