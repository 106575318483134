import React from 'react';
import { View, Text } from '@react-pdf/renderer';

import grey from '@mui/material/colors/grey';
import green from '@mui/material/colors/green';

import Boxed from '../ui/boxed';

import {
  replaceSpacesOfIntlNumberFormat,
  withCommas,
} from '../../../../_utils/general-utils';
import { getTargets } from '../../../../_utils/targets-utils';
import { ReportsPdfUiProgressBar } from '../ui/progress-bar';

export function ReportsPdfSectionsTarget({ t, reportData, areas }: any) {
  const { dashboard } = reportData;
  const targets = getTargets(areas);

  if (targets.length === 0) return null;

  const maxPerRow = 4;
  const boxWidth = `${93 / maxPerRow}%`;

  return (
    <View
      style={{
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
      }}
    >
      {targets.map((tgt: any, i: number) => {
        const tgtPerc = (tgt.latestAmount / tgt.targetAmount) * 100;
        const progresssParts = [
          {
            label: t('Achieved'),
            width: tgtPerc,
            fill:
              tgtPerc >= 100
                ? green[800]
                : dashboard.colorPalette.primary.bgcolor,
          },
          {
            label: t('Remaining'),
            width:
              (1 - tgt.latestAmount / tgt.targetAmount) * 100 > 0
                ? (1 - tgt.latestAmount / tgt.targetAmount) * 100
                : 0,
            fill: grey[500],
          },
        ];

        return (
          <React.Fragment
            // eslint-disable-next-line react/no-array-index-key
            key={`${tgt.targetUnit?.name}-${i}-${tgt.targetAmount}`}
          >
            <Boxed
              flexDirection="column"
              width={boxWidth}
              spacing={2}
              wrap={false}
              title={tgt.targetUnit?.name || '-'}
              titleFlexGrow={0}
              contentFlexGrow={1}
              content={
                <View style={{ textAlign: 'left', width: '100%' }}>
                  <Text>
                    <Text style={{ fontSize: '8px' }}>
                      {`${t('Target')}: `}
                    </Text>
                    {replaceSpacesOfIntlNumberFormat(
                      withCommas(tgt.targetAmount, dashboard.language)
                    )}
                  </Text>
                  {!!tgt.startAmount && (
                    <Text>
                      <Text style={{ fontSize: '8px' }}>
                        {`${t('Start')}: `}
                      </Text>
                      {replaceSpacesOfIntlNumberFormat(
                        withCommas(tgt.startAmount, dashboard.language)
                      )}
                    </Text>
                  )}
                  <Text>
                    <Text style={{ fontSize: '8px' }}>{t('Deadline')}:</Text>{' '}
                    {tgt.deadline}
                  </Text>
                  <Text>
                    <Text style={{ fontSize: '8px' }}>
                      {`${t('Start Date')}: `}
                    </Text>
                    {tgt.startedOn}
                  </Text>

                  {/* progress Bar */}
                  <View style={{ margin: '5px 0' }}>
                    <ReportsPdfUiProgressBar parts={progresssParts} />
                  </View>

                  {/* latest update */}
                  {tgt.latestDate !== tgt.startDate &&
                    tgt.latestAmount !== tgt.startAmount && (
                      <>
                        <View
                          style={{
                            borderBottom: `1px solid ${grey[500]}`,
                            margin: '5px 0',
                          }}
                        />
                        <Text
                          style={{
                            fontSize: '11px',
                            color: grey[700],
                            marginBottom: '3px',
                          }}
                        >
                          {t('Latest Update')}
                        </Text>
                        <Text>
                          <Text style={{ fontSize: '8px' }}>
                            {`${t('Date')}: `}
                          </Text>
                          {tgt.latestDate}
                        </Text>
                        <Text>
                          <Text style={{ fontSize: '8px' }}>
                            {`${t('Amt')}: `}
                          </Text>
                          {replaceSpacesOfIntlNumberFormat(
                            withCommas(tgt.latestAmount, dashboard.language)
                          )}{' '}
                          <Text style={{ fontSize: '8px' }}>
                            {tgt.targetUnit?.name}
                          </Text>
                        </Text>
                      </>
                    )}
                </View>
              }
            />
            {i % maxPerRow === maxPerRow - 1 && (
              <View style={{ width: '100%', height: '15px' }} />
            )}
          </React.Fragment>
        );
      })}
    </View>
  );
}
