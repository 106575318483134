import React from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorBoundary } from 'react-error-boundary';
import { useLocation } from 'react-router-dom';

import { ContentDialog } from '../../content/content-dialog';
import { ContentDialogContext } from '../context/content-dialog-context';
import { ReactError } from '../react-error';

export function ContentDialogRouter() {
  const { t } = useTranslation();
  const location = useLocation();

  const { contentId, setContentId } = React.useContext(ContentDialogContext);

  const [showContentDialog, setShowContentDialog] = React.useState(false);

  // --------------------------------------------------------------------------------

  React.useEffect(() => {
    const params = new URLSearchParams(location.search);
    const urlContentId = params.get('content');

    const isReportScreen = location.pathname.startsWith(t('/report'));

    // hide content dialog if no content id in url or if on report screen
    if (!urlContentId && showContentDialog && isReportScreen) {
      setShowContentDialog(false);
    }

    // show content dialog
    if (urlContentId && !showContentDialog && !isReportScreen) {
      setShowContentDialog(true);
    }

    // set the id for content dialog if different
    if (urlContentId !== contentId) {
      setContentId(urlContentId);
    }
  }, [
    setContentId,
    contentId,
    location.search,
    showContentDialog,
    t,
    location.pathname,
  ]);

  // --------------------------------------------------------------------------------

  return (
    contentId && (
      <ErrorBoundary FallbackComponent={ReactError}>
        <ContentDialog
          showContentDialog={showContentDialog}
          setShowContentDialog={setShowContentDialog}
        />
      </ErrorBoundary>
    )
  );
}
