import React from 'react';
import { useTranslation } from 'react-i18next';

import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { DashboardContext } from '../../../../_lib/context/dashboard-context';
import { timeStringToAmPm } from '../../../../_utils/general-utils';

import { AdvancedCustomWeeklyDigestDialog } from './dialog';

export function AdvancedCustomWeeklyDigest({ enabled }: any) {
  const { t } = useTranslation();

  const [openDialog, setOpenDialog] = React.useState(false);

  const { dashboard } = React.useContext(DashboardContext);

  const daysOfWeek: Array<string> = [
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday',
  ];

  return (
    <>
      <AdvancedCustomWeeklyDigestDialog
        open={openDialog}
        setOpen={setOpenDialog}
      />

      <Box sx={{ display: { xs: 'block', sm: 'flex' }, alignItems: 'center' }}>
        <Typography sx={{ mr: { xs: 0, sm: 2 }, mt: { xs: 1.5, sm: 0 } }}>
          <Typography variant="body2" component="span" color="textSecondary">
            {t('Current Digest Day and Time')}
            {': '}
          </Typography>
          {dashboard.enableCustomWeeklyDigest
            ? `${t('Every')} ${daysOfWeek[dashboard.weeklyDigestDay]} ${t(
                'at'
              )} ${timeStringToAmPm(dashboard.weeklyDigestTime)}`
            : `${t('Every')} ${daysOfWeek[6]} ${t('at')} ${timeStringToAmPm(
                '00:00:00'
              )}`}
        </Typography>

        {enabled && (
          <Button
            variant="outlined"
            size="small"
            onClick={() => setOpenDialog(true)}
          >
            {t('Customize digest day and time')}
          </Button>
        )}
      </Box>
    </>
  );
}
