import React from 'react';
import { useTranslation } from 'react-i18next';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Slider from '@mui/material/Slider';

import { getGraphQLDate } from '../_utils/general-utils';
import { useDashboardContext } from '../_lib/context/dashboard-context';

import { FileUpload } from './file-upload';
import { ConfirmCancelButtons } from './confirm-cancel-buttons';
import { DialogTitle } from '../DialogTitle/dialog-title';
import { DateInput } from './date-input';
import { ContentAssigneeSelect } from '../content/assignee-select';

export function DialogModal({
  onSubmit,
  loading,
  onDismiss,
  message,
  title,
  type,
  initialValue,
  initialDate,
  show,
  open,
}: any) {
  const { t } = useTranslation();

  const { dashboard } = useDashboardContext();

  const size = React.useMemo(
    () => (type && type.includes('text-input') ? 'sm' : 'xs'),
    [type]
  );
  const dateInput = React.useMemo(
    () =>
      type
        ? type.includes('date') ||
          type.includes('issue') ||
          type.includes('convert')
        : false,
    [type]
  );
  const placeholderObject = React.useMemo(
    () => ({
      text: initialValue || '',
      date: initialDate || getGraphQLDate(new Date()),
      value: !initialValue
        ? 0
        : Number.isNaN(parseFloat(initialValue))
        ? 0
        : parseFloat(initialValue),
    }),
    [initialDate, initialValue]
  );

  const [update, setUpdate] = React.useState<any>(placeholderObject);

  const [notANumber, setNotaNumber] = React.useState<any>(false);
  const [noTextError, setNoTextError] = React.useState<any>(false);
  const [errors, setErrors] = React.useState<any>({});

  // -----------------------------------------------------------------------------------------------------------------
  // -----------------------------------------------------------------------------------------------------------------
  // handlers
  // -----------------------------------------------------------------------------------------------------------------

  const changeValue = React.useCallback((value: any, valueType: any) => {
    setUpdate((prev: any) => ({ ...prev, [valueType]: value }));

    // Reset errors
    const errorType = valueType;
    if (value)
      setErrors((prev: any) => ({
        ...prev,
        [errorType]: false,
      }));
  }, []);

  const handleConfirm = React.useCallback(() => {
    setNotaNumber(false);
    setNoTextError(false);
    setErrors({});
    if (Number.isNaN(parseInt(update.value, 10))) {
      setNotaNumber(t('Please use a valid number'));
      return;
    }
    if (!!type?.includes('text-input') && !update.text) {
      setNoTextError(t('You must include this field'));
      return;
    }

    if (
      !!type?.includes('convert') &&
      (dashboard.enableActivityMultipleAssignees
        ? !update?.assignees?.length
        : !update?.assignee?.id)
    ) {
      setErrors({
        ...errors,
        assignee: t('You must include an assignee.'),
      });
      return;
    }

    onSubmit(update);
  }, [
    update,
    type,
    dashboard.enableActivityMultipleAssignees,
    onSubmit,
    t,
    errors,
  ]);

  // -----------------------------------------------------------------------------------------------------------------
  // -----------------------------------------------------------------------------------------------------------------
  // effects
  // -----------------------------------------------------------------------------------------------------------------

  // check for initial value change and update if not open
  React.useEffect(() => {
    if (!open && !show) setUpdate(placeholderObject);
  }, [open, show, placeholderObject]);

  // -----------------------------------------------------------------------------------------------------------------

  return (
    <Dialog
      id="confirmation-dialog"
      className={type}
      fullWidth
      maxWidth={size}
      open={show || open}
    >
      <DialogTitle onClose={onDismiss}>{title}</DialogTitle>
      <DialogContent>
        <Stack spacing={3}>
          {typeof message === 'string' ? (
            <Typography>{message}</Typography>
          ) : (
            message
          )}

          {!!dateInput && (
            <Box sx={{ mt: 1 }}>
              <DateInput
                id="dialog-date-input"
                date={update.date}
                setDate={(date: any) => setUpdate({ ...update, date })}
                disabled={loading}
                label={t('Date')}
                size="small"
                fullWidth
              />
            </Box>
          )}

          {!!type?.includes('text-input') && (
            <TextField
              label={t('Text')}
              multiline
              minRows={2}
              fullWidth
              onChange={(event) => changeValue(event.target.value, 'text')}
              value={update.text}
              disabled={loading}
              helperText={noTextError}
              error={!!noTextError}
              sx={{ my: 2 }}
            />
          )}

          {/* number input */}
          {!!type?.includes('integer-input') && (
            <TextField
              label={t('Number')}
              value={update.value}
              onChange={(event) =>
                setUpdate({ ...update, value: event.target.value })
              }
              InputProps={{
                inputProps: {
                  inputMode: 'numeric',
                  pattern: '[0-9]*',
                  style: { textAlign: 'right' },
                },
              }}
              size="small"
              fullWidth
              error={!!notANumber}
              helperText={notANumber}
              disabled={loading}
            />
          )}

          {/* slider */}
          {!!type?.includes('slider') && (
            <Box>
              <Typography
                sx={{ textAlign: 'center', mb: 1 }}
                variant="h6"
              >{`${update.value}%`}</Typography>
              <Slider
                value={update.value}
                onChange={(event, value) => setUpdate({ ...update, value })}
                min={0}
                max={100}
                disabled={loading}
              />
            </Box>
          )}

          {/* attachment */}
          {!!type?.includes('attachment') && (
            <Box id="update-attachment" sx={{ textAlign: 'right' }}>
              <Typography sx={{ mb: 1 }}>
                {!!update.attachment && `${update.attachment.name} `}
              </Typography>
              <FileUpload
                isIcon={false}
                file={update.attachment}
                setFile={(file: any) =>
                  setUpdate({ ...update, attachment: file })
                }
                disabled={loading}
              />
            </Box>
          )}

          {/* tracker : issue : convert to activity */}
          {!!type && !!type.includes('convert') && (
            <ContentAssigneeSelect
              content={{ ...update, type: 'activity' }}
              setContent={setUpdate}
              loading={loading}
              errors={errors}
            />
          )}
        </Stack>
      </DialogContent>

      {/* actions */}
      <DialogActions sx={{ p: 2, pt: 0 }}>
        <ConfirmCancelButtons
          confirm={handleConfirm}
          cancel={onDismiss}
          loading={loading}
        />
      </DialogActions>
    </Dialog>
  );
}

DialogModal.defaultProps = {
  open: false,
  show: false,
};

export default DialogModal;
