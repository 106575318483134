/* eslint-disable react/function-component-definition */
import ReactPDF from '@react-pdf/renderer';
import { FC, PropsWithChildren } from 'react';

// react pdf
export const Svg: FC<PropsWithChildren<ReactPDF.SVGProps>> = ({ ...props }) => (
  <ReactPDF.Svg {...props} />
);
export const G: FC<PropsWithChildren<ReactPDF.GProps>> = ({ ...props }) => (
  <ReactPDF.G {...props} />
);
export const ClipPath: FC<PropsWithChildren<ReactPDF.ClipPathProps>> = ({
  ...props
}) => <ReactPDF.ClipPath {...props} />;
export const Page: FC<PropsWithChildren<ReactPDF.PageProps>> = ({
  ...props
}) => <ReactPDF.Page {...props} />;
export const Text: FC<PropsWithChildren<ReactPDF.TextProps>> = ({
  ...props
}) => <ReactPDF.Text {...props} />;
export const View: FC<PropsWithChildren<ReactPDF.ViewProps>> = ({
  ...props
}) => <ReactPDF.View {...props} />;
export const Document: FC<PropsWithChildren<ReactPDF.DocumentProps>> = ({
  ...props
}) => <ReactPDF.Document {...props} />;
export const Image: FC<PropsWithChildren<ReactPDF.ImageProps>> = ({
  ...props
}) => <ReactPDF.Image {...props} />;
