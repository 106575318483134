import React from 'react';
import { useTranslation } from 'react-i18next';
import { gql, useMutation } from '@apollo/client';

import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';

import { useDashboardContext } from '../../_lib/context/dashboard-context';
import { useContentDialogContext } from '../../_lib/context/content-dialog-context';

import { logError } from '../../_lib/error';

import { CONTENT_QUERY } from '../../_lib/graphql/queries';
import { CONTENT_FRAGMENT } from '../../_lib/graphql/fragments';

import { DialogModal } from '../../components/dialog-modal';

import { ContentDialogSectionTitle } from './section-title';

const UPDATE_PERC = gql`
  mutation contentUpdatePercDone($contentId: ID!, $percDone: Int!) {
    contentUpdatePercDone(contentId: $contentId, percDone: $percDone) {
      content {
        ...ContentFragment
      }
    }
  }
  ${CONTENT_FRAGMENT}
`;

export function ContentDialogPercentageDone() {
  const { t } = useTranslation();

  const { dashboard, setSnackbarOpen, setSnackbarIsError } =
    useDashboardContext();
  const { content, contentId } = useContentDialogContext();

  const [loading, setLoading] = React.useState(false);

  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [dialogConfirmOpen, setDialogConfirmOpen] = React.useState(false);

  const [percDone, setPercDone] = React.useState<any>(0);

  const [updateContentPercDoneMutation] = useMutation(UPDATE_PERC, {
    update: (cache, { data }) => {
      cache.writeQuery({
        query: CONTENT_QUERY,
        data: { content: data.contentUpdatePercDone.content },
        variables: { id: contentId },
      });
    },
  });

  // ----------------------------------------------------------------------------------------------------------------------------------
  // ----------------------------------------------------------------------------------------------------------------------------------
  // handlers
  // ----------------------------------------------------------------------------------------------------------------------------------

  const updatePercentage = ({ value }: any) => {
    setLoading(true);
    return updateContentPercDoneMutation({
      variables: {
        contentId: content.id,
        percDone: Number.parseInt(value, 10),
      },
    })
      .then(() => {
        setSnackbarOpen(true);
        setDialogOpen(false);
        setLoading(false);
      })
      .catch((e: any) => {
        logError(e);
        setSnackbarIsError(true);
        setSnackbarOpen(true);
        setLoading(false);
      });
  };

  // ----------------------------------------------------------------------------------------------------------------------------------
  // ----------------------------------------------------------------------------------------------------------------------------------
  // effects
  // ----------------------------------------------------------------------------------------------------------------------------------

  React.useEffect(() => {
    if (!dialogOpen) {
      setPercDone(content?.percDone);
    }
  }, [content?.percDone, dialogOpen]);

  // ----------------------------------------------------------------------------------------------------------------------------------

  return (
    <>
      {/* confirm save dialog */}
      <DialogModal
        open={dialogConfirmOpen}
        onDismiss={() => setDialogConfirmOpen(false)}
        onSubmit={() => {
          updatePercentage({ value: percDone }).then(() => {
            setDialogConfirmOpen(false);
          });
        }}
        loading={loading}
        title={t('Confirm Save')}
        message={<Typography>{t('Are you sure you want to save?')}</Typography>}
      />

      {/* perc done */}
      <DialogModal
        open={dialogOpen}
        loading={loading}
        onSubmit={({ value }: any) => {
          setPercDone(value);
          if (!dashboard.enableActivityConfirmSave) {
            updatePercentage({ value });
          } else {
            setDialogConfirmOpen(true);
          }
        }}
        onDismiss={() => setDialogOpen(false)}
        title={t('Percentage Done')}
        message={t('Update the percentage of completion')}
        type="slider"
        initialValue={percDone}
      />

      {/* components */}
      <ContentDialogSectionTitle>
        {t('Percentage Completed')}
      </ContentDialogSectionTitle>
      <Typography variant="body1" paragraph gutterBottom>
        {content.percDone}
        {' % '}
        {t('Completed')}
        <Button
          variant="outlined"
          onClick={() => setDialogOpen(true)}
          startIcon={<AddIcon />}
          disabled={loading || content.access !== 'EDIT'}
          sx={{ ml: 2 }}
        >
          {t('Update')}
        </Button>
      </Typography>
    </>
  );
}
