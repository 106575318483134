import React from 'react';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';

import { DateInput } from '../../components/date-input';
import { DashboardContext } from '../../_lib/context/dashboard-context';

export function ContentFormDates({
  content,
  setContent,
  loading,
  errors,
}: any) {
  const { t } = useTranslation();

  const {
    dashboard: { enableActivityStartDate },
  } = React.useContext(DashboardContext);

  // -------------------------------------------------------------------------------------------------------------
  // -------------------------------------------------------------------------------------------------------------
  // handlers
  // -------------------------------------------------------------------------------------------------------------

  const setStartDate = (date: any) => {
    setContent({
      ...content,
      startDate: date,
    });
  };
  const setDeadline = (date: any) => {
    setContent({
      ...content,
      deadline: date,
    });
  };

  // -------------------------------------------------------------------------------------------------------------

  if (!content) return null;

  return (
    <Box sx={{ mt: !content.id ? 2 : 4 }}>
      {/* already completed? */}
      {!content.id && (
        <Box>
          <FormControlLabel
            id="content-already-completed"
            control={
              <Checkbox
                checked={!!content.completionDate}
                onChange={() =>
                  setContent({
                    ...content,
                    completionDate: !content.completionDate,
                  })
                }
                disabled={loading}
              />
            }
            label={`${t('Already completed?')}`}
            disabled={loading}
          />
        </Box>
      )}

      <Box sx={{ mt: 1 }}>
        <Grid container spacing={2}>
          {/* Start date */}
          {enableActivityStartDate && (
            <Grid item xs={12} sm={6}>
              <DateInput
                date={content.startDate}
                setDate={setStartDate}
                label={t('Start Date')}
                id="content-dialog-start-date-input"
                fullWidth
                max={content.deadline}
                errorText={errors.startDate}
                clearable
                loading={loading}
              />
            </Grid>
          )}

          {/* Deadline */}
          <Grid item xs={12} sm={6}>
            <DateInput
              date={content.deadline}
              setDate={setDeadline}
              id="content-form-deadline-input"
              label={`${t('Deadline')} *`}
              fullWidth
              min={content.startDate}
              max={
                !content.id && content.completionDate ? new Date() : undefined
              }
              errorText={errors.deadline}
              loading={loading}
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
