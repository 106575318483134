import React from 'react';
import { useTranslation } from 'react-i18next';
import { getQuarter, addQuarters, subQuarters, endOfQuarter } from 'date-fns';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Slider from '@mui/material/Slider';

import TablePagination from '@mui/material/TablePagination';
import { PriorityAreaMilestonesSliderCard } from './card';

export function PriorityAreaMilestonesSlider({ milestones }: any) {
  const { t } = useTranslation();

  const theme = useTheme();
  const smDown = useMediaQuery(theme.breakpoints.down('sm'));

  const [sliderValue, setSliderValue] = React.useState(0);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  // -------------------------------------------------------------------------------------------------------------

  const quarters = React.useMemo(() => {
    const result = [
      {
        value: 0,
        label: t('Prev Quarters'),
        endDate: endOfQuarter(subQuarters(new Date(), 1)),
      },
      {
        value: 1,
        label: t('This Quarter'),
        endDate: endOfQuarter(new Date()),
      },
    ];

    // add next four quarters
    Array.from({ length: 4 }).forEach((_, i) => {
      const endQuarter = addQuarters(new Date(), i + 1);
      const quarter = getQuarter(endQuarter);
      result.push({
        value: i + 2,
        label: `${endQuarter.getFullYear()} Q${quarter}`,
        endDate: endOfQuarter(endQuarter),
      });
    });

    return result;
  }, [t]);

  // array of milestones for the current slider value
  const milestonesPerQuarter = React.useMemo(() => {
    const result = {} as any;
    milestones
      ?.filter((m: any) => m.status !== 'archived')
      ?.forEach((milestone: any) => {
        quarters.every((quarter: any) => {
          if (new Date(milestone.deadline) < quarter.endDate) {
            if (!result[quarter.value]) {
              result[quarter.value] = [];
            }
            result[quarter.value].push(milestone);
            return false;
          }
          return true;
        });
      });
    return result;
  }, [milestones, quarters]);

  const milestonesQuarter = React.useMemo(
    () => milestonesPerQuarter[sliderValue] || [],
    [milestonesPerQuarter, sliderValue]
  );

  // -------------------------------------------------------------------------------------------------------------
  // -------------------------------------------------------------------------------------------------------------
  // handlers
  // -------------------------------------------------------------------------------------------------------------

  const handleSlide = (value: any) => {
    setSliderValue(value);
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // -------------------------------------------------------------------------------------------------------------
  // -------------------------------------------------------------------------------------------------------------
  // effects
  // -------------------------------------------------------------------------------------------------------------

  // -------------------------------------------------------------------------------------------------------------

  return (
    <Box sx={{ mt: 1.4, width: '95%', mx: 'auto', minHeight: '60vh' }}>
      <Slider
        aria-label="Quarters"
        step={null}
        marks={quarters}
        min={0}
        max={5}
        getAriaValueText={(value) => quarters[value].label}
        valueLabelDisplay={smDown ? 'on' : 'off'}
        valueLabelFormat={(value) => quarters[value].label}
        onChange={(event, value) => handleSlide(value)}
        sx={{
          height: '10px',
          mb: {
            xs: 1,
            sm: '30px',
          },
          '& .MuiSlider-thumb': {
            height: '24px',
            width: '24px',
          },
          '& .MuiSlider-mark': {
            height: '5px',
            width: '5px',
            borderRadius: '5px',
          },
          '& .MuiSlider-markLabel': {
            ...theme.typography.body1,
            fontWeight: 500,
            top: '35px',
            display: { xs: 'none', sm: 'block' },
          },
        }}
      />

      <Box
        sx={{
          mt: {
            xs: 0,
            sm: 2,
            md: 3,
            lg: 4,
          },
        }}
      >
        {milestonesQuarter.length > 0 ? (
          milestonesQuarter
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((m: any) => (
              <PriorityAreaMilestonesSliderCard key={m.id} milestone={m} />
            ))
        ) : (
          <Box sx={{ py: 5 }}>
            <Typography
              variant="body1"
              color="text.secondary"
              sx={{ textAlign: 'center', fontStyle: 'italic' }}
            >
              {t('No milestones to show...')}
            </Typography>
          </Box>
        )}

        {/* pagination */}
        {milestonesQuarter.length > 5 && (
          <TablePagination
            component="div"
            count={milestonesQuarter.length}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={[5, 10, 25]}
            labelRowsPerPage={t('Rows per page')}
          />
        )}
      </Box>
    </Box>
  );
}
