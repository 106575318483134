import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import { useHistory, useParams } from 'react-router-dom';

import LoadingButton from '@mui/lab/LoadingButton';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import UTurnLeftIcon from '@mui/icons-material/UTurnLeft';
import PublishOutlinedIcon from '@mui/icons-material/PublishOutlined';

import { REPORT_UPDATE } from '../../_lib/graphql/mutations';
import { CONTEXT_USER_QUERY } from '../../_lib/graphql/queries';

import { useReportsContext } from '../../_lib/context/reports-context';
import { useDashboardContext } from '../../_lib/context/dashboard-context';
import { logError } from '../../_lib/error';
import CsvBuilder from './download/CsvBuilder';

export function ReportsEditActionSave({
  isDraft,
  isUnpublish,
  disabled,
  isMoreMenuItem,
}: any) {
  const { t } = useTranslation();

  const history = useHistory();
  const { reportId } = useParams<any>();

  const { setSnackbarOpen, setSnackbarIsError } = useDashboardContext();
  const {
    report,
    formEl,
    setFormErrors,

    pdfBlob,
    csvBuilders,

    filterAreas,
    filterDates,

    setLoading,
  } = useReportsContext();

  const [localLoading, setLocalLoading] = React.useState(false);

  // ------------------------------------------------------------------------------------------------------------------------------

  const [updateReportMutation] = useMutation(REPORT_UPDATE, {
    update(cache, result) {
      cache.writeQuery({
        query: CONTEXT_USER_QUERY,
        data: { user: result.data.reportUpdate.user },
      });
    },
  });

  // ------------------------------------------------------------------------------------------------------------------------------
  // ------------------------------------------------------------------------------------------------------------------------------
  // handlers
  // ------------------------------------------------------------------------------------------------------------------------------

  const fetchFormData = React.useCallback(() => {
    const form = formEl.current as any;
    const formDatas = new FormData(form);

    return {
      title: formDatas.get('title') as string,
      description: formDatas.get('description') as string,
      filterProgramme: formDatas.get('filter-programme') as string,
      filterMembers: !!formDatas.get('filter-members'),
    };
  }, [formEl]);

  const handleSubmit = React.useCallback(() => {
    setLocalLoading(true);
    setLoading(true);
    setFormErrors({});

    const updatedFormData = fetchFormData();
    if (!updatedFormData?.title?.trim()) {
      setFormErrors({
        title: t('Title cannot be empty'),
      });
      setLocalLoading(false);
      setLoading(false);
      return;
    }

    updateReportMutation({
      variables: {
        id: reportId || 'new',
        title: updatedFormData.title,
        description: updatedFormData.description,
        filterProgramme: updatedFormData.filterProgramme,
        filterAreas: filterAreas.length > 0 ? filterAreas : ['delete_all'],
        filterMembers: updatedFormData.filterMembers,
        filterStartDate: filterDates?.startDate,
        filterEndDate: filterDates?.endDate,
        status: isDraft ? 'draft' : isUnpublish ? 'unpublish' : 'publish',
        pdf: new File(
          [pdfBlob],
          `${reportId || 'new'}-${
            updatedFormData.title
          }-${new Date().toISOString()}.pdf`
        ),
        csvs: csvBuilders.map((csvBuilder: CsvBuilder) => {
          return new File(
            [csvBuilder.getBlob()],
            `${reportId || 'new'}-${
              updatedFormData.title
            }-${csvBuilder.getFileName()}-${new Date().toISOString()}.csv`
          );
        }),
      },
    })
      .then((r: any) => {
        setSnackbarOpen(true);
        history.push(`${t('/reports')}/${r.data.reportUpdate.report.id}`);
      })
      .catch((err: any) => {
        logError(err);
        setSnackbarIsError(true);
        setSnackbarOpen(true);

        setLocalLoading(false);
        setLoading(false);
      });
  }, [
    setLoading,
    setFormErrors,
    fetchFormData,
    updateReportMutation,
    reportId,
    filterAreas,
    filterDates?.startDate,
    filterDates?.endDate,
    isDraft,
    isUnpublish,
    pdfBlob,
    csvBuilders,
    t,
    setSnackbarOpen,
    history,
    setSnackbarIsError,
  ]);

  // ------------------------------------------------------------------------------------------------------------------------------
  // ------------------------------------------------------------------------------------------------------------------------------
  // effects
  // ------------------------------------------------------------------------------------------------------------------------------

  // ------------------------------------------------------------------------------------------------------------------------------

  return (
    <>
      {/* todo: confirm dialog for unpublish / publish */}

      {/* button */}
      {!isMoreMenuItem ? (
        <LoadingButton
          onClick={handleSubmit}
          variant={isDraft ? 'outlined' : 'contained'}
          loading={localLoading}
          disableElevation
          disabled={localLoading ? false : disabled}
        >
          {isDraft
            ? t('Save draft')
            : isUnpublish
            ? t('Unpublish')
            : report?.publishedAt
            ? t('Update')
            : t('Publish')}
        </LoadingButton>
      ) : (
        <MenuItem onClick={handleSubmit}>
          <ListItemIcon>
            {isDraft ? (
              <SaveOutlinedIcon fontSize="small" />
            ) : isUnpublish ? (
              <UTurnLeftIcon
                fontSize="small"
                sx={{
                  transform: 'rotate(90deg)',
                }}
              />
            ) : report?.publishedAt ? (
              <PublishOutlinedIcon fontSize="small" />
            ) : (
              t('Publish')
            )}
          </ListItemIcon>
          {isDraft
            ? t('Save draft')
            : isUnpublish
            ? t('Unpublish')
            : report?.publishedAt
            ? t('Update')
            : t('Publish')}
        </MenuItem>
      )}
    </>
  );
}
