import React from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorBoundary } from 'react-error-boundary';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import AddIcon from '@mui/icons-material/Add';

import { randomId } from '@mui/x-data-grid-generator';

import { ReactErrorComponent } from '../../_lib/react-error';

import { getGraphQLDate } from '../../_utils/general-utils';

import { PlanningLayoutTabPanel } from '../layout/tab-panel';

import { NoMilestones } from './no-milestones';
import { SetupMilestoneDetail } from './detail';
import { PlanningSetupMilestonesFormListHeader } from './list-header';

export function SetupMilestonesList({ intervention }: any) {
  const { t } = useTranslation();

  const [newMilestones, setNewMilestones] = React.useState([] as any);
  const [showNoMilestones, setShowNoMilestones] = React.useState(
    false as boolean
  );

  const bottomRef = React.useRef(null);

  // ------------------------------------------------------------------------------------------------
  // ------------------------------------------------------------------------------------------------
  // handlers
  // ------------------------------------------------------------------------------------------------

  const addMilestone = () => {
    setNewMilestones((prev: any) => [
      ...prev,
      {
        title: '',
        description: '',
        assignee: null,
        deadline: getGraphQLDate(new Date()),
        isMilestone: true,
        fauxId: randomId(),
        scroll: true,
      },
    ]);
  };

  // ------------------------------------------------------------------------------------------------
  // ------------------------------------------------------------------------------------------------
  // effects
  // ------------------------------------------------------------------------------------------------

  // If no milestones, show no milestones card
  React.useEffect(() => {
    setShowNoMilestones(
      (intervention.content.length === 0 ||
        !intervention.content.find((c: any) => c.isMilestone)) &&
        !newMilestones.length
    );
  }, [intervention, newMilestones]);

  // new milestone scroll to bottom
  React.useEffect(() => {
    if (newMilestones.length > 0) {
      const bottom = bottomRef.current as any;
      bottom.scrollIntoView({ behavior: 'smooth' });
    }
  }, [newMilestones]);

  // ------------------------------------------------------------------------------------------------

  return (
    <PlanningLayoutTabPanel
      index={1}
      title={`${t('Planning')}: ${t('Milestones')}`}
      subheader={`${t('Set up your milestones')}`}
    >
      <PlanningSetupMilestonesFormListHeader
        addMilestone={addMilestone}
        intervention={intervention}
      />

      {/* No Milestones notification */}
      {showNoMilestones && <NoMilestones />}

      {/* Otherwise list them */}
      {intervention.content.map(
        (content: any) =>
          content.isMilestone && (
            <SetupMilestoneDetail
              key={content.id}
              content={content}
              intervention={intervention}
            />
          )
      )}

      {/* New milestones list */}
      {newMilestones.map((milestone: any) => (
        <ErrorBoundary
          key={milestone.fauxId}
          FallbackComponent={ReactErrorComponent}
        >
          <SetupMilestoneDetail
            content={milestone}
            intervention={intervention}
            newMilestones={newMilestones}
            setNewMilestones={setNewMilestones}
          />
        </ErrorBoundary>
      ))}

      <Box sx={{ my: 3, textAlign: 'center' }}>
        <Button
          variant="contained"
          color="primary"
          size="large"
          startIcon={<AddIcon />}
          onClick={addMilestone}
        >
          {t('Add Milestone')}
        </Button>
      </Box>

      <div ref={bottomRef} />
    </PlanningLayoutTabPanel>
  );
}
