import React from 'react';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { useDashboardContext } from '../../_lib/context/dashboard-context';
import { slugify } from '../../_utils/general-utils';

import { AnalyticsInterventions } from './charts/interventions';
import { AnalyticsClassifications } from './charts/classifications';
import { AnalyticsFinancials } from './charts/financials';
import { AnalyticsFinanciers } from './charts/financiers';
import { AnalyticsStatus } from './charts/status';
import { AnalyticsActivityGroups } from './charts/groups';

export type ChartType = {
  title: string;
  description: string;
  component: JSX.Element;
};

class Chart {
  title: string;

  description: string;

  component: JSX.Element;

  constructor({ title, description, component }: ChartType) {
    this.title = title;
    this.description = description;
    this.component = (
      <Box sx={{ py: 2, px: 3 }}>
        <Box
          sx={{
            position: 'relative',
            mb: 1.5,
          }}
        >
          <Box id={slugify(title)} sx={{ position: 'absolute', top: -80 }} />
          <Typography variant="h6">{title}</Typography>
          <Typography variant="body2" color="text.secondary">
            {description}
          </Typography>

          {/* todo: add menu button with download / export options */}
        </Box>

        {React.cloneElement(component, {
          title,
          description,
        })}
      </Box>
    );
  }
}

export function useAnalytics() {
  const { t } = useTranslation();

  const { dashboard } = useDashboardContext();

  // ---------------------------------------------------------------------------------------------------------------------

  const classificationName = React.useMemo(
    () =>
      dashboard.enableCustomClassificationLabel
        ? dashboard.customClassificationLabel
        : t('Classification'),
    [
      dashboard.enableCustomClassificationLabel,
      dashboard.customClassificationLabel,
      t,
    ]
  );

  // ---------------------------------------------------------------------------------------------------------------------

  const financeBudgetLabel = React.useMemo(
    () =>
      dashboard.enableCustomFinanceLabels
        ? dashboard.customFinanceBudgetLabel
        : t('Budget'),
    [dashboard.customFinanceBudgetLabel, dashboard.enableCustomFinanceLabels, t]
  );

  const financeSpentLabel = React.useMemo(
    () =>
      dashboard.enableCustomFinanceLabels
        ? dashboard.customFinanceSpentLabel
        : t('Spent'),
    [dashboard.customFinanceSpentLabel, dashboard.enableCustomFinanceLabels, t]
  );

  const financeFinancedLabel = React.useMemo(
    () =>
      dashboard.enableCustomFinanceLabels
        ? dashboard.customFinanceFinancedLabel
        : t('Financed'),
    [
      dashboard.customFinanceFinancedLabel,
      dashboard.enableCustomFinanceLabels,
      t,
    ]
  );

  // ---------------------------------------------------------------------------------------------------------------------

  const charts: ChartType[] = React.useMemo(
    () => [
      new Chart({
        title: dashboard.interventionName,
        description: t(
          'Breakdown per intervention / project and priority area / main area.'
        ),
        component: <AnalyticsInterventions />,
      }),
      new Chart({
        title: t('Status'),
        description: t(
          'Count of status per intervention / project and priority area / main area.'
        ),
        component: <AnalyticsStatus />,
      }),
      ...(dashboard.enableActivityGroup
        ? [
            new Chart({
              title: t('Phase'),
              description: t(
                'Count of activities per phase / group per priority area / main area.'
              ),
              component: <AnalyticsActivityGroups />,
            }),
          ]
        : []),
      new Chart({
        title: classificationName,
        description: t(
          'Breakdown per intervention / project and priority area / main area.'
        ),
        component: <AnalyticsClassifications />,
      }),
      new Chart({
        title: financeBudgetLabel,
        description: t(
          'Breakdown per intervention / project and priority area / main area.'
        ),
        component: <AnalyticsFinancials financial="budget" />,
      }),
      new Chart({
        title: financeSpentLabel,
        description: t(
          'Breakdown per intervention / project and priority area / main area.'
        ),
        component: <AnalyticsFinancials financial="currentlySpent" />,
      }),
      new Chart({
        title: financeFinancedLabel,
        description: t(
          'Breakdown per intervention / project and priority area / main area.'
        ),
        component: <AnalyticsFinancials financial="totalFunding" />,
      }),
      new Chart({
        title: t('Financiers'),
        description: t(
          'Breakdown per intervention / project and priority area / main area.'
        ),
        component: <AnalyticsFinanciers />,
      }),
    ],
    [
      classificationName,
      dashboard.interventionName,
      dashboard.enableActivityGroup,
      financeBudgetLabel,
      financeFinancedLabel,
      financeSpentLabel,
      t,
    ]
  );

  return { charts };
}
