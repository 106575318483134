import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import LoadingButton from '@mui/lab/LoadingButton';

import { useDashboardContext } from '../../../../_lib/context/dashboard-context';
import { logError } from '../../../../_lib/error';

import { MEMBER_UPDATE } from '../../../../_lib/graphql/mutations';
import { CONTEXT_USER_QUERY } from '../../../../_lib/graphql/queries';

export function MyAccountPreferencesDetailNameAndPosition({ setting }: any) {
  const { t } = useTranslation();

  const { setSnackbarIsError, setSnackbarOpen } = useDashboardContext();

  const [loading, setLoading] = React.useState(false);
  const [nameAndPosition, updateNameAndPosition] = React.useReducer(
    (state: any, newState: any) => ({ ...state, ...newState }),
    {
      name: setting.name,
      position: setting.position,
    }
  );

  const settingName = React.useRef(setting.name);
  const settingPosition = React.useRef(setting.position);

  const [updateUserSettings] = useMutation(MEMBER_UPDATE, {
    update(cache, { data: { memberUpdate } }) {
      cache.writeQuery({
        query: CONTEXT_USER_QUERY,
        data: { user: memberUpdate.user },
      });
    },
  });

  // --------------------------------------------------------------------------------------------------------------------------
  // --------------------------------------------------------------------------------------------------------------------------
  // handlers
  // --------------------------------------------------------------------------------------------------------------------------

  const handleSave = () => {
    setLoading(true);
    updateUserSettings({
      variables: {
        settingId: setting.id,
        name: nameAndPosition.name,
        position: nameAndPosition.position,
      },
    })
      .then(() => {
        setLoading(false);
        settingName.current = nameAndPosition.name;
        settingPosition.current = nameAndPosition.position;
        setSnackbarOpen(true);
      })
      .catch((err) => {
        logError(err);
        setLoading(false);
        setSnackbarIsError(true);
        setSnackbarOpen(true);
      });
  };

  // --------------------------------------------------------------------------------------------------------------------------
  // --------------------------------------------------------------------------------------------------------------------------
  // effects
  // --------------------------------------------------------------------------------------------------------------------------

  // stay updated with the setting
  React.useEffect(() => {
    updateNameAndPosition({ name: setting.name, position: setting.position });
  }, [setting.name, setting.position]);

  // --------------------------------------------------------------------------------------------------------------------------

  return (
    <Box>
      <Typography
        variant="body2"
        component="div"
        sx={{
          color: loading ? 'text.disabled' : 'text.primary',
        }}
      >
        <span
          contentEditable={!loading}
          suppressContentEditableWarning
          onInput={(e) =>
            updateNameAndPosition({ name: e.currentTarget.innerText })
          }
        >
          {settingName.current}
        </span>
        {' . '}
        <span
          contentEditable={!loading}
          suppressContentEditableWarning
          onInput={(e) => {
            updateNameAndPosition({ position: e.currentTarget.innerText });
          }}
        >
          {settingPosition.current}
        </span>

        {nameAndPosition.name !== setting.name ||
        nameAndPosition.position !== setting.position ? (
          <LoadingButton
            variant="text"
            size="small"
            onClick={handleSave}
            sx={{ ml: 1, color: 'primary.main' }}
            loading={loading}
          >
            {t('Save')}
          </LoadingButton>
        ) : null}
      </Typography>
    </Box>
  );
}
