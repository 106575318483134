import React from 'react';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Chip from '@mui/material/Chip';
import ListItemIcon from '@mui/material/ListItemIcon';

import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import PlaylistRemoveIcon from '@mui/icons-material/PlaylistRemove';
import TableChartOutlinedIcon from '@mui/icons-material/TableChartOutlined';
import BackupTableOutlinedIcon from '@mui/icons-material/BackupTableOutlined';

export function MyAccountPreferencesDetailTitle({ setting }: any) {
  const { t } = useTranslation();

  return (
    <Box>
      <Typography
        variant="body1"
        component="div"
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {setting.dashboard.name}
      </Typography>
      <Typography variant="body2" color="textSecondary" component="div">
        {setting.dashboard.country} . {setting.dashboard.language}
        {(setting.dashboard.mode === 'TRACKER' ||
          setting.dashboard.mode === 'SUPRANATIONAL') && (
          <Chip
            label={
              setting.dashboard.mode === 'TRACKER'
                ? t('Tracker')
                : t('Dashboard of Dashboards')
            }
            size="small"
            sx={{
              ml: 1,
              cursor: 'pointer',
            }}
            variant="outlined"
            color="primary"
            icon={
              setting.dashboard.mode === 'TRACKER' ? (
                <TableChartOutlinedIcon />
              ) : (
                <BackupTableOutlinedIcon />
              )
            }
          />
        )}
      </Typography>
    </Box>
  );
}
