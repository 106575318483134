import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

export const ReportsContext = React.createContext<any>('');

export function ReportsContextProvider({ children }: any) {
  const { t } = useTranslation();
  const location = useLocation();

  const [loading, setLoading] = React.useState(true);

  const formEl = React.useRef(null);
  const [formErrors, setFormErrors] = React.useState<any>({});

  // data
  const [report, setReport] = React.useState<any>(null);
  const [reportData, setReportData] = React.useState<any>(null);
  const [reportDataReset, setReportDataReset] = React.useState<any[]>([]);

  // filters
  const [filterAreas, setFilterAreas] = React.useState(false);
  const [filterDates, updateFilterDates] = React.useReducer(
    (state: any, newState: any) => ({ ...state, ...newState }),
    {
      startDate: null,
      endDate: null,
    }
  );

  // members
  const [filterMembers, setFilterMembers] = React.useState(true);

  const isNew = React.useMemo(
    () => location.pathname.endsWith(`${t('/new')}`),
    [location.pathname, t]
  );

  // report data variables
  const reportDataVariables = React.useMemo(
    () => ({
      filterStartDate: filterDates.startDate,
      filterEndDate: filterDates.endDate,
    }),
    [filterDates]
  );

  const [pdfBlob, setPdfBlob] = React.useState<Blob | null>(null);
  const [csvBuilders, setCsvuilders] = React.useState<any>([]);

  // ------------------------------------------------------------------------------------------------------------------------

  // context
  const contextValue = React.useMemo(
    () => ({
      isNew,

      loading,
      setLoading,

      formEl,
      formErrors,
      setFormErrors,

      report,
      setReport,
      reportData,
      setReportData,
      reportDataReset,
      setReportDataReset,

      filterAreas,
      setFilterAreas,
      filterDates,
      updateFilterDates,
      filterMembers,
      setFilterMembers,

      reportDataVariables,

      pdfBlob,
      setPdfBlob,
      csvBuilders,
      setCsvuilders,
    }),
    [
      isNew,
      loading,
      formErrors,
      report,
      reportData,
      reportDataReset,
      filterAreas,
      filterDates,
      filterMembers,
      reportDataVariables,
      pdfBlob,
      csvBuilders,
    ]
  );

  return (
    <ReportsContext.Provider value={contextValue}>
      {children}
    </ReportsContext.Provider>
  );
}

export function useReportsContext() {
  const context = React.useContext<any>(ReportsContext);
  if (context === undefined) {
    throw new Error(
      'useReportsContext must be used within a ReportsContextProvider'
    );
  }
  return context;
}
