import { View, Text } from '@react-pdf/renderer';
import { compareAsc } from 'date-fns';

import grey from '@mui/material/colors/grey';

import { getStringDate } from '../../../../../../_utils/general-utils';

import Boxed from '../../../ui/boxed';
import { Item } from '../../../ui/list';

export function ReportPdfSectionActivitiesListsList({
  t,
  title,
  dashboard,
  activities,
}: any) {
  const { metaStatusColors } = dashboard;

  const sortedActivities = structuredClone(activities);
  Object.keys(activities).forEach((key: any) => {
    if (!activities[key]?.content) return;
    let sortedActivitesContent: any = structuredClone(activities[key].content);
    if (
      sortedActivitesContent &&
      sortedActivitesContent.length > 0 &&
      sortedActivitesContent[0].createdAt
    ) {
      sortedActivitesContent = sortedActivitesContent?.sort((a: any, b: any) =>
        compareAsc(new Date(a.createdAt), new Date(b.createdAt))
      );
    }
    if (
      sortedActivitesContent &&
      sortedActivitesContent.length > 0 &&
      sortedActivitesContent[0].deadline
    ) {
      sortedActivitesContent = sortedActivitesContent?.sort((a: any, b: any) =>
        compareAsc(new Date(a.deadline), new Date(b.deadline))
      );
    }
    sortedActivities[key].content = sortedActivitesContent;
  });

  return (
    <Boxed
      flexDirection="column"
      spacing={2}
      title={title}
      titleFlexGrow={0}
      titleAlignItems="flex-start"
      titleTextStyle={{ fontSize: '12px' }}
      contentFlexGrow={1}
      content={
        <View
          style={{
            width: '100%',
            textAlign: 'left',
          }}
        >
          {Object.keys(sortedActivities).map((key: any) => {
            if (!sortedActivities[key]) return null;

            return (
              <View key={key} style={{ marginBottom: '10px' }}>
                <Text
                  style={{
                    marginBottom: '5px',
                    fontWeight: 'bold',
                    color: metaStatusColors[key].bgcolor,
                  }}
                >
                  {t(metaStatusColors[key].text)}
                </Text>

                {/* no activities */}
                {(!sortedActivities[key] ||
                  (!!sortedActivities[key].content &&
                    sortedActivities[key].content.length === 0)) && (
                  <Text>{t('-')}</Text>
                )}

                {/* activities */}
                {!!sortedActivities[key] &&
                  !!sortedActivities[key].content &&
                  sortedActivities[key].content
                    .slice(0, 3)
                    .map((activity: any) => {
                      return (
                        <Item key={activity.id} view>
                          <Text>
                            {activity.title?.trim() || '-'}
                            {dashboard.enableActivityPercentage &&
                              key !== 'issue' &&
                              ` (${activity.percDone}%)`}
                          </Text>
                          <Text>
                            {/* deadline */}
                            {!!activity.deadline && (
                              <Text style={{ fontSize: '9px' }}>
                                {t('Deadline')}:{' '}
                                {getStringDate(
                                  activity.deadline,
                                  dashboard.language
                                )}
                              </Text>
                            )}

                            {!!activity.deadline &&
                              (!!activity.priorityArea ||
                                !!activity.intervention) && <Text> . </Text>}

                            {/* priority area */}
                            {!!activity.priorityArea && (
                              <Text
                                style={{
                                  color: grey[500],
                                  fontSize: '9px',
                                }}
                              >
                                {activity.priorityArea.reference &&
                                  `${activity.priorityArea.reference} - `}
                                {activity.priorityArea.name?.trim() || '--'}
                              </Text>
                            )}

                            {!!activity.priorityArea &&
                              !!activity.intervention && <Text> . </Text>}

                            {/* intervention */}
                            {!!activity.intervention && (
                              <Text
                                style={{
                                  color: grey[500],
                                  fontSize: '8px',
                                }}
                              >
                                {activity.intervention.reference &&
                                  `${activity.intervention.reference} - `}
                                {activity.intervention.name?.trim() || '--'}
                              </Text>
                            )}
                          </Text>
                        </Item>
                      );
                    })}
              </View>
            );
          })}
        </View>
      }
    />
  );
}
