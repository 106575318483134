import { useTranslation } from 'react-i18next';

import Container from '@mui/material/Container';
import SignalWifiOffIcon from '@mui/icons-material/SignalWifiOff';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

export function ScreenInfoOffline() {
  const { t } = useTranslation();
  return (
    <Container maxWidth="xs" sx={{ py: 5 }}>
      <Alert severity="error" variant="outlined" icon={<SignalWifiOffIcon />}>
        <AlertTitle>{t('You are offline')}</AlertTitle>
        {t(
          'Oops! It seems you are offline. When you are back online, you will be able to use the application.'
        )}
      </Alert>
    </Container>
  );
}
