import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';

export function AuthDemoAlert({ email, password }: any) {
  const { t } = useTranslation();

  return (
    <Box sx={{ maxWidth: '100%', my: 2 }}>
      <Alert
        severity="info"
        sx={{
          '& .MuiAlert-message': {
            overflow: 'auto',
          },
        }}
      >
        <Typography variant="body2" gutterBottom>
          {t(
            'This is a demo site. Data is deleted every hour. You can log in using:'
          )}
        </Typography>
        <Typography variant="body2">
          <strong>{t('Email')}</strong>
        </Typography>
        <Typography
          variant="body2"
          gutterBottom
          sx={{
            border: 1,
            borderRadius: 1,
            px: 1,
            py: 0.5,
            bgcolor: 'grey.200',
            color: 'text.secondary',
            borderColor: 'text.secondary',
            overflow: 'hidden',
          }}
        >
          {email}
        </Typography>
        <Typography variant="body2">
          <strong>{t('Password')}</strong>
        </Typography>
        <Typography
          variant="body2"
          gutterBottom
          sx={{
            border: 1,
            borderRadius: 1,
            px: 1,
            py: 0.5,
            bgcolor: 'grey.200',
            color: 'text.secondary',
            borderColor: 'text.secondary',
            overflow: 'hidden',
          }}
        >
          {password}
        </Typography>
      </Alert>
    </Box>
  );
}
