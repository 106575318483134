import React from 'react';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import LoadingButton from '@mui/lab/LoadingButton';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';

import { useDashboardContext } from '../../../../_lib/context/dashboard-context';
import { Snackbar } from '../../../snackbar';

import { MEMBER_REFRESH_CALENDAR_TOKEN } from '../../../../_lib/graphql/mutations';
import { AlertDialog } from './alert-dialog';
import { logError } from '../../../../_lib/error';
import { getApiURL, getStringDateTime } from '../../../../_utils/general-utils';
import { MyAccountSection } from '../../section';

export function CalendarURL() {
  const { t } = useTranslation();
  const {
    user,
    dashboard: { language },
    setSnackbarOpen,
    setSnackbarIsError,
  } = useDashboardContext();

  const [showResetAlert, setShowResetAlert] = React.useState(false);
  const [openCopySnackbar, setOpenCopySnackbar] = React.useState(false);

  const [refreshCalendarTokenMutation, { loading }] = useMutation(
    MEMBER_REFRESH_CALENDAR_TOKEN
  );

  const calendarURL = React.useMemo(
    () => `${getApiURL()}/calendar/ical/${user.settings?.calendarToken}/`,
    [user.settings?.calendarToken]
  );

  // ---------------------------------------------------------------------------------------------------------
  // ---------------------------------------------------------------------------------------------------------
  // handlers
  // ---------------------------------------------------------------------------------------------------------

  const resetLink = React.useCallback(() => {
    refreshCalendarTokenMutation()
      .then(() => {
        setShowResetAlert(false);
      })
      .catch((error) => {
        setSnackbarIsError(true);
        logError(error);
      })
      .finally(() => {
        setSnackbarOpen(true);
      });
  }, [refreshCalendarTokenMutation, setSnackbarIsError, setSnackbarOpen]);

  const handleCopy = React.useCallback(() => {
    navigator.clipboard.writeText(calendarURL);
    setOpenCopySnackbar(true);
  }, [calendarURL]);

  const handleReset = React.useCallback(() => {
    setShowResetAlert(true);
  }, []);

  const handleConfirmReset = React.useCallback(() => {
    resetLink();
  }, [resetLink]);

  // ---------------------------------------------------------------------------------------------------------
  // ---------------------------------------------------------------------------------------------------------
  // effects
  // ---------------------------------------------------------------------------------------------------------

  // ---------------------------------------------------------------------------------------------------------

  return (
    <>
      <MyAccountSection
        icon={<CalendarMonthOutlinedIcon />}
        title={t('iCal / .ics Calendar URL')}
        description={t(
          'Use this URL to add your calendar to any calendar app that supports the iCal format.'
        )}
        action={
          <Box>
            <Typography sx={{ mb: 1, fontWeight: 700 }} />
            <TextField
              label={t('URL')}
              variant="filled"
              size="small"
              aria-readonly
              value={calendarURL}
              fullWidth
              sx={{ mb: 1 }}
              InputProps={{
                readOnly: true,
                endAdornment: (
                  <InputAdornment position="end">
                    <Tooltip title={t('Copy')} arrow>
                      <IconButton
                        aria-label="copy calendar url"
                        onClick={handleCopy}
                        edge="end"
                        size="small"
                      >
                        <ContentCopyIcon fontSize="inherit" />
                      </IconButton>
                    </Tooltip>
                  </InputAdornment>
                ),
              }}
              onFocus={(event) => event.target.select()}
              helperText={`${t('Last Updated')}: ${getStringDateTime(
                user.settings?.calendarTokenUpdatedAt,
                language
              )}`}
            />
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <LoadingButton
                loading={loading}
                variant="outlined"
                size="small"
                sx={{
                  textTransform: 'none',
                }}
                onClick={handleReset}
              >
                {loading ? t('Resetting...') : t('Reset')}
              </LoadingButton>
            </Box>
          </Box>
        }
      />

      {/* --------------------------------------------------------------------------------------------------------- */}

      {/* copy snackbar */}
      <Snackbar
        open={openCopySnackbar}
        setOpen={setOpenCopySnackbar}
        message={t('Calendar URL copied to clipboard')}
      />

      {/* reset alert */}
      <AlertDialog
        open={showResetAlert}
        title={t('Reset calendar URL?')}
        subTitle={`${t('This will invalidate the current URL. Are you sure?')}`}
        cancel={() => setShowResetAlert(false)}
        confirm={handleConfirmReset}
        buttonLabels={[t('Reset'), t('Cancel')]}
        loading={loading}
      />
    </>
  );
}
