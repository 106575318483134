import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';

import LoadingButton from '@mui/lab/LoadingButton';
import SwapHorizRoundedIcon from '@mui/icons-material/SwapHorizRounded';

import { SWITCH_DASHBOARD } from '../../_lib/graphql/mutations';

import { logError } from '../../_lib/error';
import { useDashboardContext } from '../../_lib/context/dashboard-context';

export function AreaSummaryGoToDashboard({ area }: any) {
  const { t } = useTranslation();

  const { setSnackbarIsError, setSnackbarOpen } = useDashboardContext();

  const [switchDashboardMutation, { loading }] = useMutation(SWITCH_DASHBOARD);

  const dashboardId =
    area.__typename === 'PriorityAreaType'
      ? area.dashboardOfDashboards?.dashboard?.id
      : area.dashboard?.id;

  const switchDashboards = () =>
    switchDashboardMutation({
      variables: { dashboardId },
    })
      .then(() => {
        window.localStorage.clear();
        window.location.pathname = '/';
        window.location.reload();
      })
      .catch((err) => {
        logError(err);
        setSnackbarIsError(true);
        setSnackbarOpen(true);
      });

  return (
    <LoadingButton
      variant="contained"
      size="large"
      loading={loading}
      disableElevation
      startIcon={<SwapHorizRoundedIcon />}
      onClick={switchDashboards}
      disabled={!dashboardId}
    >
      {t('Go To Dashboard')}
    </LoadingButton>
  );
}
