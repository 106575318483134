import React from 'react';
import { gql, useQuery } from '@apollo/client';
import { useLocation } from 'react-router-dom';
import { logError } from '../../../_lib/error';
import { useOnlineStatus } from '../../../_lib/offline/use-online-status';

const ROUTE_CONTENT_PERMISSION_QUERY = gql`
  query RouteContentPermission($contentId: ID) {
    routeContentPermission(contentId: $contentId) {
      reload
    }
  }
`;

const ROUTE_CONTENT_PERMISSION_SUBSCRIPTION = gql`
  subscription RouteContentPermission($contentId: ID) {
    routeContentPermission(contentId: $contentId) {
      reload
    }
  }
`;

const ROUTE_PERMISSION_QUERY = gql`
  query RoutePermission($route: String!) {
    routePermission(route: $route) {
      access
    }
  }
`;

const ROUTE_PERMISSION_SUBSCRIPTION = gql`
  subscription RoutePermission($route: String!) {
    routePermission(route: $route) {
      access
    }
  }
`;

export function useRouteContentPermission() {
  const location = useLocation();
  const isOnline = useOnlineStatus();

  // check if content ID passed
  const params = React.useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );
  const contentId = React.useMemo(() => params.get('content'), [params]);

  const { data, loading, error, refetch, subscribeToMore } = useQuery(
    ROUTE_CONTENT_PERMISSION_QUERY,
    {
      variables: { contentId },
      skip: !contentId,
      fetchPolicy: isOnline ? 'network-only' : 'cache-only',
    }
  );

  // refetch
  React.useEffect(() => {
    if (contentId && !data && !loading && !error) refetch();
  }, [data, loading, error, refetch, contentId]);

  // error
  React.useEffect(() => {
    if (error) logError(error);
  }, [error]);

  // subscription
  const readyToSubscribe = React.useMemo(() => !!data, [data]);

  React.useEffect(() => {
    let unsubscribe: any;
    if (readyToSubscribe) {
      unsubscribe = subscribeToMore({
        document: ROUTE_CONTENT_PERMISSION_SUBSCRIPTION,
        variables: { contentId },
        updateQuery: (prev, { subscriptionData }) => {
          if (!subscriptionData.data) return prev;
          return subscriptionData.data;
        },
      });
    }
    return () => {
      if (unsubscribe) unsubscribe();
    };
  }, [readyToSubscribe, subscribeToMore, contentId]);

  return {
    data: contentId
      ? data
      : {
          routeContentPermission: {
            reload: false,
          },
        },
    loading,
    error,
  };
}

export function useRoutePermission() {
  const location = useLocation();
  const isOnline = useOnlineStatus();

  const { data, loading, error, refetch, subscribeToMore } = useQuery(
    ROUTE_PERMISSION_QUERY,
    {
      variables: { route: location.pathname },
      fetchPolicy: isOnline ? 'network-only' : 'cache-only',
    }
  );

  // refetch
  React.useEffect(() => {
    if (!data && !loading && !error) refetch();
  }, [data, loading, error, refetch]);

  // error
  React.useEffect(() => {
    if (error) logError(error);
  }, [error]);

  // subscription
  const readyToSubscribe = React.useMemo(() => !!data, [data]);

  React.useEffect(() => {
    let unsubscribe: any;
    if (readyToSubscribe) {
      unsubscribe = subscribeToMore({
        document: ROUTE_PERMISSION_SUBSCRIPTION,
        variables: { route: location.pathname },
        updateQuery: (prev, { subscriptionData }) => {
          if (!subscriptionData.data) return prev;
          return subscriptionData.data;
        },
      });
    }
    return () => {
      if (unsubscribe) unsubscribe();
    };
  }, [readyToSubscribe, subscribeToMore, location.pathname]);

  return { data, loading, error };
}
