import React from 'react';
import { useTranslation } from 'react-i18next';

import Button from '@mui/material/Button';

import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import RestoreFromTrashOutlinedIcon from '@mui/icons-material/RestoreFromTrashOutlined';

import { useContentDialogContext } from '../../../../_lib/context/content-dialog-context';

import { ContentDialogActionsDeleteDialog } from './dialog';

export function ContentDialogActionsDelete() {
  const { t } = useTranslation();

  const { content } = useContentDialogContext();

  const [open, setOpen] = React.useState(false);

  // -------------------------------------------------------------------------------------------------------------------

  return (
    <>
      <Button
        startIcon={
          content.status !== 'deleted' ? (
            <DeleteForeverRoundedIcon />
          ) : (
            <RestoreFromTrashOutlinedIcon />
          )
        }
        variant="outlined"
        disableElevation
        color={content.status !== 'deleted' ? 'error' : 'success'}
        onClick={() => setOpen(true)}
      >
        {content.status !== 'deleted' ? t('Delete') : t('Restore')}
      </Button>

      <ContentDialogActionsDeleteDialog
        open={open}
        setOpen={setOpen}
        content={content}
      />
    </>
  );
}
