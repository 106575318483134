import React from 'react';
import { useLocation } from 'react-router-dom';
import { TargetDialog } from '../../components/area-targets/target-dialog';
import { getTargetRouteId } from '../../_utils/targets-utils';

export function TargetDialogRouter({
  target,
  container,
  includePriorityAreas,
}: any) {
  const location = useLocation();

  const [showTargetDialog, setShowTargetDialog] = React.useState(false);

  React.useEffect(() => {
    const params = new URLSearchParams(location.search);
    const targetId = params.get('target');
    if (getTargetRouteId(target, container) === targetId && !showTargetDialog) {
      setShowTargetDialog(true);
    }
  }, [showTargetDialog, target, container, location.search]);

  return (
    <TargetDialog
      target={target}
      container={container}
      showTargetDialog={showTargetDialog}
      setShowTargetDialog={setShowTargetDialog}
      includePriorityAreas={includePriorityAreas}
    />
  );
}
