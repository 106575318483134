import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';

import Alert from '@mui/material/Alert';
import LoadingButton from '@mui/lab/LoadingButton';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Box from '@mui/material/Box';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import ArchiveIcon from '@mui/icons-material/Archive';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

import { useDashboardContext } from '../../../_lib/context/dashboard-context';
import { logError } from '../../../_lib/error';

import { DialogTitle } from '../../../DialogTitle/dialog-title';

import {
  CONTEXT_USER_QUERY,
  INTERVENTIONS_PLANNING_QUERY,
  PRIORITY_AREA_PLANNING_QUERY,
} from '../../../_lib/graphql/queries';
import { INTERVENTION_DELETE } from '../../../_lib/graphql/mutations';
import { useArchiveArea } from '../../../_utils/hooks/mutations/use-delete-archive-area';

export function PlanningAreaActionsDeleteInterventionDialog({
  open,
  setOpen,
  intervention,
  isArchive,
  listVariables,
}: any) {
  const { t } = useTranslation();

  const {
    dashboard,
    setSnackbarOpen,
    setSnackbarIsError,
    setRecentlyDeletedProps,
    setSnackbarIsDelete,
  } = useDashboardContext();

  const [loading, setLoading] = React.useState(false);

  const [deleteMutation] = useMutation(INTERVENTION_DELETE, {
    update(cache, result) {
      // update list of interventions
      cache.writeQuery({
        query: INTERVENTIONS_PLANNING_QUERY,
        data: { interventions: result.data.interventionDelete.interventions },
        variables: {
          ...listVariables,
        },
      });

      // update interventions list in priority area
      cache.writeQuery({
        query: PRIORITY_AREA_PLANNING_QUERY,
        data: {
          priorityArea: result.data.interventionDelete.priorityArea,
        },
        variables: {
          priorityAreaId: result.data.interventionDelete.priorityArea.id,
        },
      });

      // update user context
      cache.writeQuery({
        query: CONTEXT_USER_QUERY,
        data: {
          user: result.data.interventionDelete.user,
        },
      });
    },
  });
  const { archiveMutation, archiving } = useArchiveArea(
    'Intervention',
    listVariables
  );

  const getUIState = () => {
    if (isArchive) {
      return {
        label: t('Archive'),
        alertMsg: t(
          'This item can be restored later from the list of archived items.'
        ),
        dialogTitle: t('Are you sure you want to archive this?'),
        startIcon: <ArchiveIcon />,
        color: 'warning',
      };
    }

    return {
      label: t('Delete'),
      alertMsg: t(
        'This action can only be undone immediately you delete it. To undo this action, click undo on the success message that appears.'
      ),
      dialogTitle: t('Are you sure you want to delete this?'),
      startIcon: <DeleteForeverIcon />,
      color: 'error',
    };
  };

  // ----------------------------------------------------------------------------------------------------
  // ----------------------------------------------------------------------------------------------------
  // handlers
  // ----------------------------------------------------------------------------------------------------

  const handleDeleteConfirm = () => {
    setLoading(true);
    deleteMutation({
      variables: {
        ...listVariables,
        id: intervention.id,
      },
    })
      .then(() => {
        setRecentlyDeletedProps({
          objectId: intervention.id,
          objectName: 'intervention',
          object: intervention,
          successFunc: () => {
            return null;
          },
        });
        setSnackbarIsDelete(true);
      })
      .catch((err: any) => {
        logError(err);
        setSnackbarIsError(true);
        setLoading(false);
      })
      .finally(() => {
        setSnackbarOpen(true);
      });
  };

  const handleArchiveConfirm = () => {
    archiveMutation({
      variables: {
        ...listVariables,
        id: intervention.id,
      },
    })
      .catch((err: any) => {
        logError(err);
        setSnackbarIsError(true);
      })
      .finally(() => {
        setSnackbarOpen(true);
      });
  };

  // ----------------------------------------------------------------------------------------------------

  return (
    <Dialog open={open} maxWidth="xs" fullWidth>
      <DialogTitle onClose={() => setOpen(false)}>
        {getUIState().label}: {dashboard.interventionName}
      </DialogTitle>
      <DialogContent>
        <Box>
          <Typography variant="body1" sx={{ mb: 1 }}>
            {getUIState().dialogTitle}
          </Typography>
          <Typography sx={{ pl: 2 }}>
            {intervention.reference} - {intervention.name}
          </Typography>
          <Alert severity="warning" sx={{ mt: 2 }}>
            {getUIState().alertMsg}
          </Alert>

          {/* TODO: show target updates and activities that will be deleted */}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => setOpen(false)}
          variant="text"
          disabled={loading}
        >
          {t('Cancel')}
        </Button>
        <LoadingButton
          loading={loading || archiving}
          onClick={isArchive ? handleArchiveConfirm : handleDeleteConfirm}
          variant="contained"
          color={
            getUIState().color as
              | 'inherit'
              | 'error'
              | 'primary'
              | 'secondary'
              | 'success'
              | 'info'
              | 'warning'
              | undefined
          }
          disableElevation
          startIcon={getUIState().startIcon}
        >
          {t('Confirm')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
