import React from 'react';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

export function ReportTemplateDialogMetaInputsMilestones({
  disabled,
  templateMeta,
  updateTemplateMeta,
}: any) {
  const { t } = useTranslation();

  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth disabled={disabled} size="small">
        <InputLabel id="milestones-display-select-label">
          {t('Milestones Display')}
        </InputLabel>
        <Select
          labelId="milestones-display-select-label"
          id="milestones-display-select"
          value={templateMeta.milestonesDisplay || 'TIMELINE'}
          label={t('Milestones Display')}
          onChange={(e: any) =>
            updateTemplateMeta({ milestonesDisplay: e.target.value })
          }
        >
          <MenuItem value="TIMELINE">{`${t('Timeline')} (${t(
            'Default'
          )})`}</MenuItem>
          <MenuItem value="LIST">{t('List')}</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
}
