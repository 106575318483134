import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';

import Box from '@mui/material/Box';
import Popper from '@mui/material/Popper';
import Paper from '@mui/material/Paper';
import Portal from '@mui/material/Portal';

import { ReactErrorComponent } from '../../_lib/react-error';

import { ContentSummary } from '../../tracker/content-summary';

export function DoughnutChartTooltip({ tooltipContext }: any) {
  const anchorRef = React.useRef(null);

  const [mouseOver, setMouseOver] = React.useState(false);

  // ---------------------------------------------------------------------------------------------------------
  // ---------------------------------------------------------------------------------------------------------
  // effects
  // ---------------------------------------------------------------------------------------------------------

  // ---------------------------------------------------------------------------------------------------------

  return (
    <Portal container={document.body}>
      <Box
        ref={anchorRef}
        sx={{
          height: '1px',
          width: '1px',
          opacity: 0,
          position: 'absolute',
          ...tooltipContext?.anchorPosition,
        }}
      />

      <Popper
        open={tooltipContext?.opacity === 1 || mouseOver}
        anchorEl={anchorRef?.current}
        onClick={(event) => {
          event.stopPropagation();
        }}
      >
        <Paper
          onMouseEnter={() => {
            setMouseOver(true);
          }}
          onMouseLeave={() => setMouseOver(false)}
          sx={{
            backgroundColor: 'white',
            width: '350px',
            maxWidth: '90vw',
            padding: 0.8,
            boxShadow: 4,
            zIndex: 8,
          }}
        >
          <ErrorBoundary FallbackComponent={ReactErrorComponent}>
            <ContentSummary
              content={tooltipContext?.content}
              status={tooltipContext?.status}
              totalActivities={tooltipContext?.totalActivities}
              count={tooltipContext?.count}
              onClose={(e: any) => {
                e.stopPropagation();
                setMouseOver(false);
              }}
            />
          </ErrorBoundary>
        </Paper>
      </Popper>
    </Portal>
  );
}
