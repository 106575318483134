import authIsLoggedIn from './queries/auth/is-logged-in.graphql';
import isDemoSite from './queries/auth/is-demo-site.graphql';

import context from './queries/context.graphql';
import contextDashboard from './queries/dashboard/dashboard.graphql';

import user from './queries/accounts/user.graphql';
import users from './queries/accounts/users.graphql';
import userSettings from './queries/accounts/user-settings.graphql';

import dashboardReportsTemplateMeta from './queries/dashboard/dashboard-reports-template-meta.graphql';

import importExports from './queries/import-exports.graphql';

import reports from './queries/report/reports.graphql';
import report from './queries/report/report.graphql';
import reportTemplateMeta from './queries/report/report-template-meta.graphql';
import reportData from './queries/report/report-data.graphql';
import reportDataPriorityArea from './queries/report/report-data-priority-area.graphql';
import reportDataIntervention from './queries/report/report-data-intervention.graphql';

import priorityAreasPlanning from './queries/area/priority-area/priority-areas-planning.graphql';
import priorityAreaPlanning from './queries/area/priority-area/priority-area-planning.graphql';
import priorityAreaPlanningDelete from './queries/area/priority-area/priority-area-planning-delete.graphql';
import priorityAreaPlanningBreadcrumbs from './queries/area/priority-area/priority-area-planning-breadcrumbs.graphql';
import priorityAreaTracker from './queries/area/priority-area/priority-area-tracker.graphql';
import priorityAreasHome from './queries/area/priority-area/priority-areas-home.graphql';

import interventionsPlanning from './queries/area/intervention/interventions-planning.graphql';
import interventionPlanning from './queries/area/intervention/intervention-planning.graphql';
import interventions from './queries/area/intervention/interventions.graphql';
import intervention from './queries/area/intervention/intervention.graphql';
import interventionTracker from './queries/area/intervention/intervention-tracker.graphql';

import milestonesPlanning from './queries/milestones-planning.graphql';
import trackerLanding from './queries/tracker-landing.graphql';
import activityTracker from './queries/activity-tracker.graphql';

import areaPermissions from './queries/area/area-permissions.graphql';

import targetUnits from './queries/targets/target-units.graphql';

import content from './queries/content/content.graphql';
import myContent from './queries/content/my-content.graphql';

import contentGroups from './queries/content-groups.graphql';
import contentGroup from './queries/content-group.graphql';
import contentBlockers from './queries/content/content-blockers.graphql';
import contentExecutiveSummary from './queries/content/content-executive-summary.graphql';

import classifications from './queries/classifications.graphql';
import regions from './queries/regions.graphql';

import areaLevels from './queries/area/area-levels.graphql';

export const AUTH_IS_LOGGED_IN_QUERY = authIsLoggedIn;
export const AUTH_IS_DEMO_SITE_QUERY = isDemoSite;

export const CONTEXT_QUERY = context;
export const CONTEXT_DASHBOARD_QUERY = contextDashboard;

export const CONTEXT_USER_QUERY = user;
export const CONTEXT_USERS_QUERY = users;
export const USER_SETTINGS_QUERY = userSettings;

export const DASHBOARD_REPORTS_TEMPLATE_META_QUERY =
  dashboardReportsTemplateMeta;

export const REPORTS_QUERY = reports;
export const REPORT_QUERY = report;
export const REPORT_TEMPLATE_META_QUERY = reportTemplateMeta;
export const REPORT_DATA_QUERY = reportData;
export const REPORT_DATA_PRIORITY_AREA_QUERY = reportDataPriorityArea;
export const REPORT_DATA_INTERVENTION_QUERY = reportDataIntervention;

export const IMPORT_EXPORTS_QUERY = importExports;

export const PRIORITY_AREAS_PLANNING_QUERY = priorityAreasPlanning;
export const PRIORITY_AREA_PLANNING_QUERY = priorityAreaPlanning;
export const PRIORITY_AREA_PLANNING_DELETE_QUERY = priorityAreaPlanningDelete;
export const PRIORITY_AREA_PLANNING_BREADCRUMBS_QUERY =
  priorityAreaPlanningBreadcrumbs;
export const PRIORITY_AREA_TRACKER_QUERY = priorityAreaTracker;
export const PRIORITY_AREAS_HOME_QUERY = priorityAreasHome;

export const INTERVENTIONS_PLANNING_QUERY = interventionsPlanning;
export const INTERVENTION_PLANNING_QUERY = interventionPlanning;
export const INTERVENTIONS_QUERY = interventions;
export const INTERVENTION_QUERY = intervention;
export const INTERVENTION_TRACKER_QUERY = interventionTracker;

export const MILESTONES_PLANNING_QUERY = milestonesPlanning;
export const TRACKER_LANDING_QUERY = trackerLanding;
export const ACTIVITY_TRACKER_QUERY = activityTracker;

export const AREA_PERMISSIONS_QUERY = areaPermissions;

export const CONTENT_QUERY = content;
export const MY_CONTENT_QUERY = myContent;

export const CONTENT_GROUPS_QUERY = contentGroups;
export const CONTENT_GROUP_QUERY = contentGroup;
export const CONTENT_EXECUTIVE_SUMMARY_QUERY = contentExecutiveSummary;

export const CONTENT_BLOCKERS_QUERY = contentBlockers;

export const CLASSIFICATIONS_QUERY = classifications;
export const REGIONS_QUERY = regions;

export const AREA_LEVELS_QUERY = areaLevels;

export const TARGET_UNITS_QUERY = targetUnits;
