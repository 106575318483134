import { useQuery } from '@apollo/client';
import { useDashboardContext } from '../../../../_lib/context/dashboard-context';
import { CONTENT_EXECUTIVE_SUMMARY_QUERY } from '../../../../_lib/graphql/queries';
import { ExecutiveSummaryFiltersValues } from '../executive-summary-filter/executive-summary-filter';
import { getPriorityAreasGroupedByLevels } from '../executive-summary-filter/executive-summary-filter.helpers';
import { PriorityArea } from '../executive-summary/executive-summary.types';
import {
  filterContentsByPriorityAreas,
  filterPriorityAreasByLevel,
  getFilterOptions,
} from './use-executive-summary.helpers';

const getContentByStatus = ({
  contents,
  priorityArea,
  interventions,
  contentByStatus,
  activityStatusLongOverdueDays,
}: any) => {
  const today = new Date();
  const longOverdueDays = Number(activityStatusLongOverdueDays) || 0;
  const result = contents?.reduce(
    (acc: any, item: any) => {
      const deadlineDate = new Date(item?.deadline);
      const deletedAt = item?.deletedAt;
      const longOverDueDate = new Date(
        new Date(deadlineDate).setDate(deadlineDate.getDate() + longOverdueDays)
      );
      const archivedAt = item?.archivedAt;
      const completionDate = item?.completionDate;
      // const updateStatus = item?.updates || [];

      // TODO: should check if in pending state?
      if (!deletedAt && item.isIssue) {
        // issues
        // TODO: does the deadline matter with issues?
        acc.issues.add(item);

        if (priorityArea?.level?.order === 0) {
          contentByStatus.issues.add({
            ...item,
            priorityArea,
            interventions,
          });
        }
      } else if (
        archivedAt ||
        deletedAt ||
        completionDate
        //  || updateStatus?.indexOf('PENDING') > -1
      ) {
        // pass
      }
      // long overdue
      else if (!deletedAt && deadlineDate < today && longOverDueDate < today) {
        acc.longoverdue.add(item);
        if (priorityArea?.level?.order === 0) {
          contentByStatus.longoverdue.add({
            ...item,
            priorityArea,
            interventions,
          });
        }
      }
      // overdue
      else if (!deletedAt && deadlineDate < today) {
        acc.overdue.add(item);
        if (priorityArea?.level?.order === 0) {
          contentByStatus.overdue.add({
            ...item,
            priorityArea,
            interventions,
          });
        }
      }
      return acc;
    },
    {
      overdue: new Set(),
      longoverdue: new Set(),
      issues: new Set(),
      priorityArea,
      interventions,
    }
  );
  return {
    ...result,
    overdue: Array.from(result?.overdue),
    longoverdue: Array.from(result?.longoverdue),
    issues: Array.from(result?.issues),
  };
};

type UseExecutiveSummaryProps = {
  filters?: ExecutiveSummaryFiltersValues;
};

export const useExecutiveSummary = (props: UseExecutiveSummaryProps) => {
  const contentByStatus = {
    overdue: new Set(),
    longoverdue: new Set(),
    issues: new Set(),
    priorityArea: null,
    interventions: null,
  };

  const dashboardContext = useDashboardContext();

  const {
    data: contentExecutiveData,
    loading: contentExecutiveLoading,
    error: contentExecutiveError,
  } = useQuery(CONTENT_EXECUTIVE_SUMMARY_QUERY, {
    variables: {
      dashboardId: dashboardContext?.dashboard?.id,
    },
    fetchPolicy: 'cache-and-network',
  });

  const parentAreaLevel = dashboardContext?.dashboard?.areaLevels?.[0];

  const transformedFilters = Object.keys(props?.filters || {}).reduce(
    (acc, key) => {
      return {
        onId: {
          ...acc?.onId,
          ...props?.filters?.[key as keyof ExecutiveSummaryFiltersValues],
        },
        priorityAreas: acc?.priorityAreas?.concat(
          Object.values(
            props?.filters?.[key as keyof ExecutiveSummaryFiltersValues] || {}
          )
        ),
      };
    },
    {
      onId: {} as { [key in string]: PriorityArea },
      priorityAreas: [] as PriorityArea[],
    }
  );

  const priorityAreas = filterPriorityAreasByLevel({
    priorityAreas: contentExecutiveData?.dashboard?.priorityAreas || [],
    filters: transformedFilters,
  });

  const filterOptions = getFilterOptions({
    allPriorityAreas: contentExecutiveData?.dashboard?.priorityAreas || [],
    currentPriorityAreas: priorityAreas || [],
    filters: transformedFilters,
  });

  const priorityAreasFlattened =
    priorityAreas?.reduce((acc: any, item: any) => {
      return acc.concat([
        {
          contentByStatus: getContentByStatus({
            contents: item.content,
            priorityArea: item,
            interventions: [],
            contentByStatus,
            activityStatusLongOverdueDays:
              dashboardContext?.dashboard?.activityStatusLongOverdueDays,
          }),
          contents: item.content,
          interventions: [],
          interventionsCount: item?.interventionsCount,
          ...item,
        },
      ]);
    }, []) || [];

  const groupedPriorityAreasByLevel = Object.values(
    priorityAreasFlattened?.reduce((acc: any, item: any) => {
      const level = item?.level?.order;

      if (level === 0) {
        if (!Object.prototype.hasOwnProperty.call(acc, item.id)) {
          acc[item.id] = {
            ...item,
          };
        } else {
          acc[item.id] = {
            ...acc[item.id],
            ...item,
          };
        }
      } else if (level > 0) {
        if (!Object.prototype.hasOwnProperty.call(acc, item.parent.id)) {
          acc[item.parent.id] = {
            children: [item],
          };
        } else {
          acc[item?.parent?.id].children = (
            acc?.[item?.parent?.id]?.children || []
          )?.concat([item]);
        }
      }
      return acc;
    }, {}) || {}
  );

  const priorityAreasLevelsFilterOptions: { [key: string]: PriorityArea[] } =
    getPriorityAreasGroupedByLevels({
      priorityAreas: filterOptions,
    });

  return {
    contentByStatus: {
      ...contentByStatus,
      overdue: filterContentsByPriorityAreas({
        contents: Array.from(contentByStatus?.overdue),
        filteredPriorityAreas: transformedFilters,
      }),
      longoverdue: filterContentsByPriorityAreas({
        contents: Array.from(contentByStatus?.longoverdue),
        filteredPriorityAreas: transformedFilters,
      }),
      issues: filterContentsByPriorityAreas({
        contents: Array.from(contentByStatus?.issues),
        filteredPriorityAreas: transformedFilters,
      }),
    },
    groupedPriorityAreasByLevel,
    parentAreaLevel,
    contentExecutiveLoading,
    contentExecutiveError,
    priorityAreasLevelsFilterOptions,
  };
};
