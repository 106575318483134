import React from 'react';
import { useTranslation } from 'react-i18next';

import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

import { DashboardContext } from '../../../../../_lib/context/dashboard-context';

export function AdvancedAreaFieldsDetailInAreaCheckbox({
  configEdit,
  setConfigEdit,
  disabled,
}: any) {
  const { t } = useTranslation();

  const { dashboard } = React.useContext(DashboardContext);

  return (
    <FormControl
      sx={{ m: 3 }}
      component="fieldset"
      variant="standard"
      disabled={disabled}
    >
      <FormLabel component="legend" disabled={disabled}>
        {t('Available in:')}
      </FormLabel>
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              size="small"
              checked={configEdit.inPriorityArea}
              onChange={(event) =>
                setConfigEdit({
                  ...configEdit,
                  inPriorityArea: event.target.checked,
                })
              }
            />
          }
          label={dashboard.priorityAreaName}
          disabled={disabled}
        />
        <FormControlLabel
          control={
            <Checkbox
              size="small"
              checked={configEdit.inIntervention}
              onChange={(event) =>
                setConfigEdit({
                  ...configEdit,
                  inIntervention: event.target.checked,
                })
              }
            />
          }
          label={dashboard.interventionName}
          disabled={disabled}
        />
      </FormGroup>
    </FormControl>
  );
}
