import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

import { useDashboardContext } from '../../_lib/context/dashboard-context';

import { InterventionsSummary } from '../../components/area-summary/interventions-summary';

export function DashboardSummaryInterventions({
  filterPriorityAreaIds,
  filterInterventionIds,
}: any) {
  const { dashboard } = useDashboardContext();

  return (
    <Card variant="outlined">
      <CardContent>
        <InterventionsSummary
          id="dashboard-summary-interventions"
          showDetails={dashboard.homeOverviewShowInterventionsDetails}
          showPriorityArea
          variables={{
            filterPriorityAreaIds,
            filterInterventionIds,
          }}
        />
      </CardContent>
    </Card>
  );
}
