import { gql } from '@apollo/client';

export const EXECUTIVE_SUMMARY_PRIORITY_AREA_QUERY = gql`
  query ExecutiveSummaryPriorityAreas($priorityAreaId: ID!) {
    priorityArea(priorityAreaId: $priorityAreaId) {
      id
      urlRelative
    }
  }
`;
