import React from 'react';
import { useQuery } from '@apollo/client';

export function usePrefetchQuery({ query, variables }: any) {
  const { data, loading, error, refetch } = useQuery(query, {
    variables,
    fetchPolicy: 'cache-and-network',
  });

  const [readyToRefetch, setReadyToRefetch] = React.useState(false);

  // set timeout to load data after 40 seconds
  React.useEffect(() => {
    let timeout: any = null;
    if (readyToRefetch) {
      timeout = setTimeout(() => {
        refetch(variables);
        setReadyToRefetch(false);
      }, 40000);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [readyToRefetch, refetch, variables]);

  // if data is loaded, set ready to refetch
  React.useEffect(() => {
    if (!loading && !error) {
      setReadyToRefetch(true);
    }
  }, [loading, error]);

  return { data, loading, error };
}
