import React from 'react';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import Paper from '@mui/material/Paper';

import { TARGET_UPDATE_DELETE } from '../../../_lib/graphql/mutations';

import { useDashboardContext } from '../../../_lib/context/dashboard-context';
import { logError } from '../../../_lib/error';

import { DialogModal } from '../../dialog-modal';

import { TargetUpdateUpdate } from './update';

export function TargetUpdate({ update, target, container }: any) {
  const { t } = useTranslation();

  const { interventionId, priorityAreaId } = useParams<any>();

  const {
    setSnackbarOpen,
    setSnackbarIsDelete,
    setSnackbarIsError,
    setRecentlyDeletedProps,
    navbarFilterVariables,
  } = useDashboardContext();

  const [loading, setLoading] = React.useState(false);
  const [showConfirmDelete, setShowConfirmDelete] = React.useState(false);

  const [targetUpdateDeleteMutation] = useMutation(TARGET_UPDATE_DELETE);

  const handleDelete = () => {
    setLoading(true);
    targetUpdateDeleteMutation({
      variables: {
        id: update.id,
        ...(interventionId
          ? { interventionId }
          : { priorityAreaId, ...navbarFilterVariables }),
      },
    })
      .then(() => {
        setSnackbarIsDelete(true);
        setRecentlyDeletedProps({
          objectId: update?.id,
          objectName: 'target_update',
          object: null,
          successFunc: null,
        });
      })
      .catch((e) => {
        logError(e);
        setLoading(false);
        setSnackbarIsError(true);
      })
      .finally(() => setSnackbarOpen(true));
  };

  return (
    <>
      {/* delete confirmation dialog */}
      <DialogModal
        show={showConfirmDelete}
        title={`${t('Delete update?')}`}
        message={
          <Paper
            variant="outlined"
            sx={{
              py: 1,
              px: 2,
              bgcolor: 'grey.100',
            }}
          >
            <TargetUpdateUpdate
              update={update}
              target={target}
              container={container}
            />
          </Paper>
        }
        onDismiss={() => setShowConfirmDelete(false)}
        onSubmit={handleDelete}
        loading={loading}
      />

      {/* target update */}
      <TargetUpdateUpdate
        update={update}
        target={target}
        container={container}
        setShowConfirmDelete={setShowConfirmDelete}
      />
    </>
  );
}
