import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';

import { useDashboardContext } from '../../../_lib/context/dashboard-context';
import { isValidEmail } from '../../../_utils/general-utils';

export function ReportActionsShareAutocomplete({ emails, setEmails }: any) {
  const { users } = useDashboardContext();

  const filterOptions = createFilterOptions({
    matchFrom: 'any',
    stringify: (option: any) => `${option.name} ${option.email}`,
  });

  return (
    <Autocomplete
      multiple
      freeSolo
      value={emails}
      onChange={(event, newValue) => {
        setEmails(newValue);
      }}
      options={users}
      filterOptions={filterOptions}
      filterSelectedOptions
      isOptionEqualToValue={(option, newValue) =>
        typeof option === 'object'
          ? option.id === newValue.id
          : option === newValue
      }
      renderOption={(props, option: any) => {
        return (
          <MenuItem {...props} key={option.email}>
            <ListItemText
              primary={typeof option === 'object' ? option.name : option}
              secondary={option.email}
              sx={{
                '& .MuiListItemText-primary, & .MuiListItemText-secondary': {
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                },
              }}
            />
          </MenuItem>
        );
      }}
      getOptionLabel={(option: any) =>
        typeof option === 'object'
          ? `${option.name} 🤟 ${option.email}`
          : option
      }
      renderTags={(value: readonly string[], getTagProps) =>
        value.map((option: any, index: number) => {
          const email = typeof option === 'object' ? option.email : option;
          return (
            <Chip
              variant="outlined"
              label={email}
              color={isValidEmail(email) ? 'default' : 'error'}
              {...getTagProps({ index })}
            />
          );
        })
      }
      renderInput={(params) => {
        const inputValue = params.inputProps.value as string;
        return (
          <TextField
            {...params}
            variant="outlined"
            placeholder="E-mail addresses"
            inputProps={{
              ...params.inputProps,
              value: inputValue ? inputValue.split(' 🤟 ')[0] : '',
            }}
            type="email"
          />
        );
      }}
      size="small"
    />
  );
}
