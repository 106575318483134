import { View, StyleSheet } from '@react-pdf/renderer';

import { ReportPdfSectionActivitiesListsList } from '../lists/list';

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
  box: {
    width: '49%',
  },
});

export function ReportsPdfSectionsActivitiesMilestonesList({
  t,
  dashboard,
  area,
}: any) {
  return (
    <View style={styles.container}>
      <View style={styles.box}>
        <ReportPdfSectionActivitiesListsList
          t={t}
          dashboard={dashboard}
          title={t('Previous Completed')}
          activities={{
            completed: area.summary.milestonesSummary?.completed,
          }}
        />
      </View>
      <View style={styles.box}>
        <ReportPdfSectionActivitiesListsList
          t={t}
          dashboard={dashboard}
          title={t('Current and Updates')}
          activities={{
            ongoing: area.summary.milestonesSummary?.ongoing,
            pending: area.summary.milestonesSummary?.pending,
            overdue: area.summary.milestonesSummary?.overdue,
            issue: area.summary.milestonesSummary?.issues,
          }}
        />
      </View>
    </View>
  );
}
