import SvgIcon from '@mui/material/SvgIcon';

export function ReportsIcon(props: any) {
  return (
    <SvgIcon viewBox="0 0 512 512" {...props}>
      <g>
        <g xmlns="http://www.w3.org/2000/svg">
          <path
            d="m121.524 138.59h147.161c9.697 0
9.697-15 0-15h-147.161c-9.697 0-9.697 15 0 15z"
            data-original="#000000"
            className=""
          />
          <path
            d="m121.524 193.615h113.161c9.697
0 9.697-15 0-15h-113.161c-9.697 0-9.697 15 0 15z"
            data-original="#000000"
            className=""
          />
          <path
            d="m121.524 248.64h97.161c9.697 0
9.697-15 0-15h-97.161c-9.697 0-9.697 15 0 15z"
            data-original="#000000"
            className=""
          />
          <path
            d="m259.708
432.383h-4.969v-73.299c0-4.142-3.357-7.5-7.5-7.5h-30.098v-26.438c0-4.142-3.357-7.5-7.5-7.5h-37.507c-4.143
0-7.5 3.358-7.5 7.5v45.637h-30.039c-4.143 0-7.5 3.358-7.5 7.5v54.1h-4.969c-4.143
0-7.5 3.358-7.5 7.5s3.357 7.5 7.5 7.5h137.581c4.143 0 7.5-3.358
7.5-7.5s-3.357-7.5-7.499-7.5zm-117.612 0v-46.6h22.508v46.6zm37.539
0v-99.737h22.507v99.737zm37.596 0v-65.799h22.508v65.799z"
            data-original="#000000"
            className=""
          />
          <path
            d="m511.938
233.603c-.977-31.572-13.836-61.282-36.211-83.657-10.736-10.736-22.972-19.077-36.037-25.035-.219-.126-.446-.243-.681-.349-.24-.108-.483-.202-.727-.282-16.897-7.451-35.13-10.947-53.281-10.479v-41.322c0-9.606-7.813-17.421-17.418-17.421h-10.817v-9.364c0-9.606-7.813-17.421-17.418-17.421h-10.847v-10.852c.001-9.606-7.813-17.421-17.417-17.421h-293.664c-9.605
0-17.418 7.815-17.418 17.421v422.101c0 9.606 7.813 17.421 17.418
17.421h10.847v10.852c0 9.606 7.813 17.421 17.387 17.421l10.849.044v9.32c0 9.606
7.813 17.421 17.418 17.421h234.279c1.961 0 3.901-.8
5.291-2.184l69.303-68.975c1.414-1.408 2.209-3.321 2.209-5.316v-74.354c1.011.025
2.022.049 3.033.049 21.14 0 42.19-5.403 60.601-15.719 8.461-4.74
1.128-17.823-7.332-13.086-40.043 22.439-90.158
16.91-124.484-12.955l73.651-73.651 100.69 26.682c-4.438 13.121-11.452
25.572-21.081 36.556-6.392 7.292 4.886 17.18 11.279 9.889 20.69-23.601
31.549-53.906 30.578-85.334zm-86.049-98.158-43.947 97.678-75.742
75.743c-37.205-42.673-35.504-107.693 5.117-148.314 30.924-30.924 75.99-39.289
114.572-25.107zm-380.206 334.77c-1.333 0-2.417-1.086-2.417-2.421v-422.1c0-1.335
1.084-2.421 2.417-2.421h52.318c9.697 0 9.697-15 0-15h-52.318c-9.604 0-17.417
7.815-17.417 17.421v396.248h-10.846c-1.333
0-2.418-1.086-2.418-2.421v-422.1c0-1.335 1.085-2.421 2.418-2.421h293.664c1.333 0
2.418 1.086 2.418 2.421v10.853h-173.5c-9.697 0-9.697 15 0 15h199.347c1.333 0
2.418 1.086 2.418 2.421v9.364h-267.847c-9.604 0-17.418 7.815-17.418
17.421v397.78zm25.819 24.364v-422.1c0-1.335 1.085-2.421
2.418-2.421h293.664c1.333 0 2.418 1.086 2.418 2.421v42.619c-25.375 3.746-49.802
15.359-69.292 34.849-48.252 48.252-48.252 126.764 0 175.017 18.979 18.978 42.904
31.004 69.292 34.906v67.512h-51.885c-9.604 0-17.418 7.815-17.418
17.421v52.197h-226.779c-1.333
0-2.418-1.086-2.418-2.421zm244.197-8.244v-41.532c0-1.335 1.085-2.421
2.418-2.421h41.336zm82.986-253.866 40.89-90.884c9.184 4.913 17.808 11.232 25.544
18.968 26.287 26.288 36.379 63.104 29.852 97.432z"
            data-original="#000000"
            className=""
          />
        </g>
      </g>
    </SvgIcon>
  );
}
