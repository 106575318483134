import React from 'react';
import { useTranslation } from 'react-i18next';

import Typography from '@mui/material/Typography';

import { useDashboardContext } from '../../../_lib/context/dashboard-context';

import { LeadTextProfile } from './profile';

export function AreaSummaryLeadText({ area, isLead, variant }: any) {
  const { t } = useTranslation();
  const { dashboard } = useDashboardContext();

  // ------------------------------------------------------------------------------------------------------------

  // leads and coleads
  const leads = React.useMemo(() => {
    const leadsFreeTextArray =
      dashboard.enableLeadsFreeText && area.leadsFreeText
        ? JSON.parse(area.leadsFreeText)
        : [];
    const coLeadsFreeTextArray =
      dashboard.enableLeadsFreeText && area.coLeadsFreeText
        ? JSON.parse(area.coLeadsFreeText)
        : [];

    return {
      leads: [
        ...((!dashboard.enableLeadsMulti ? [area.leads[0]] : area.leads) || []),
        ...leadsFreeTextArray.map((name: any, index: number) => ({
          id: `freeText-${index}`,
          name,
        })),
      ],
      coLeads: [
        ...((!dashboard.enableLeadsMulti ? [area.coLeads[0]] : area.coLeads) ||
          []),
        ...coLeadsFreeTextArray.map((name: any, index: number) => ({
          id: `freeText-${index}`,
          name,
        })),
      ],
    };
  }, [dashboard.enableLeadsFreeText, dashboard.enableLeadsMulti, area]);

  const filterdUsers = React.useMemo(() => {
    return leads[isLead ? 'leads' : 'coLeads'].filter((user: any) => !!user);
  }, [leads, isLead]);

  // ------------------------------------------------------------------------------------------------------------
  // ------------------------------------------------------------------------------------------------------------
  // handlers
  // ------------------------------------------------------------------------------------------------------------

  // ------------------------------------------------------------------------------------------------------------

  return (
    <Typography variant={variant || 'body1'}>
      <Typography variant="caption" color="textSecondary">{`${
        isLead ? t('Lead') : 'Co-Lead'
      }:`}</Typography>{' '}
      {filterdUsers.map((user: any, index: number) => (
        <React.Fragment key={user.id}>
          {user.email ? <LeadTextProfile user={user} /> : user.name}
          {index < filterdUsers.length - 1 && ', '}
        </React.Fragment>
      ))}
      {filterdUsers.length === 0 && '-'}
    </Typography>
  );
}
