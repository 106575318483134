import { weightedMean } from '../general-utils';

export function combineSummary({ accContenSummary, currContentSummary }: any) {
  const acc = structuredClone(
    accContenSummary || {
      total: 0,
      percDone: 0,
    }
  );

  if (!currContentSummary) return acc;

  const curr = structuredClone(currContentSummary);

  Object.keys(curr)
    .filter(
      (key) =>
        key !== '__typename' &&
        key !== 'percDone' &&
        key !== 'id' &&
        key !== 'total'
    )
    .forEach((key: any) => {
      if (!acc[key]) {
        acc[key] = {
          total: 0,
          percDone: 0,
          content: [] as any,
        } as any;
      }

      acc[key].total += curr[key]?.total || 0;
      acc[key].content = [...acc[key].content, ...(curr[key]?.content || [])];

      // sort content by date
      acc[key].content.sort((a: any, b: any) => {
        if (key === 'completed') {
          return (
            new Date(b.completionDate).valueOf() -
            new Date(a.completionDate).valueOf()
          );
        }
        if (key === 'issue') {
          return (
            new Date(b.createdAt).valueOf() - new Date(a.createdAt).valueOf()
          );
        }
        return new Date(a.deadline).valueOf() - new Date(b.deadline).valueOf();
      });
    });

  acc.total += curr.total;

  // percentage done weighted by total
  acc.percDone = weightedMean(
    [acc.percDone || 0, curr.percDone || 0],
    [acc.total, curr.total]
  );

  return acc;
}

export function reduceSummaries({
  id,
  name,
  priorityAreas,
  interventionsCountDistinct,
  selectedPriorityAreaId,
}: any) {
  if (!priorityAreas?.length) return null;
  if (priorityAreas?.length === 1) {
    return {
      ...priorityAreas[0],
      interventionsCount: 1,
      priorityAreasCount: 1,
    };
  }

  // --------------------------------------------------------------------------------------------------------------------

  const combined = structuredClone(priorityAreas)?.reduce(
    (acc: any, curr: any) => {
      if (acc.priorityAreas) {
        acc.priorityAreas = [...acc.priorityAreas, structuredClone(curr)];
      } else {
        acc.priorityAreas = [structuredClone(acc), structuredClone(curr)];
      }

      // interventions
      acc.interventions = [
        ...(acc.interventions || []),
        ...(curr.interventions || []),
      ];

      // delete unnecessary attributes
      delete acc.__typename;

      // financials
      acc.summary.budget += curr.summary?.budget || 0;
      acc.summary.currentlySpent += curr.summary?.currentlySpent || 0;
      acc.summary.totalFunding += curr.summary?.totalFunding || 0;

      acc.summary.budgetPercentage = [
        acc.summary.totalFunding,
        acc.summary.budget,
      ].every((val: any) => !!val)
        ? Math.round((acc.summary.totalFunding / acc.summary.budget) * 100)
        : acc.summary.budget
        ? 100
        : 0;
      acc.summary.spentPercentage = [
        acc.summary.currentlySpent,
        acc.summary.budget,
      ].every((val: any) => !!val)
        ? Math.round((acc.summary.currentlySpent / acc.summary.budget) * 100)
        : acc.summary.budget
        ? 100
        : 0;

      // content and milestone summary
      acc.summary.contentSummary = combineSummary({
        accContenSummary: acc.summary?.contentSummary,
        currContentSummary: curr.summary?.contentSummary,
      });
      acc.summary.milestonesSummary = combineSummary({
        accContenSummary: acc.summary?.milestonesSummary,
        currContentSummary: curr.summary?.milestonesSummary,
      });

      return acc;
    },
    {
      summary: {
        budget: 0,
        currentlySpent: 0,
        totalFunding: 0,
        budgetPercentage: 0,
        spentPercentage: 0,

        contentSummary: {
          total: 0,
          percDone: 0,
        },
        milestonesSummary: {
          total: 0,
          percDone: 0,
        },
      },
    }
  );

  // --------------------------------------------------------------------------------------------------------------------

  // interventions count

  let interventionsCount = combined.interventions?.length || 0;
  if (interventionsCountDistinct) {
    interventionsCount = Object.keys(
      combined.interventions?.reduce((acc: any, curr: any) => {
        acc[curr.name.toLowerCase()] = curr;
        return acc;
      }, {}) || {}
    ).length;
  }

  // priority areas count
  const priorityAreasCount = priorityAreas.filter((pa: any) => {
    if (selectedPriorityAreaId === pa.id || !pa.summary?.contentSummary)
      return true;
    return Object.keys(pa.summary.contentSummary)
      .filter(
        (key) => key !== '__typename' && key !== 'percDone' && key !== 'id'
      )
      .some((k: any) => pa.summary.contentSummary[k]?.total > 0);
  })?.length;

  // --------------------------------------------------------------------------------------------------------------------
  // finally return the combined summary

  return {
    id: id || 'all',
    name: name || 'All Teams',
    summary: combined.summary,
    interventions: combined.interventions,
    interventionsCount,
    priorityAreasCount,
  } as any;
}
