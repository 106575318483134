import { isNumber } from 'lodash';
import { PriorityArea } from '../executive-summary/executive-summary.types';

export const getPriorityAreasGroupedByLevels = ({
  priorityAreas,
}: {
  priorityAreas?: PriorityArea[];
}) => {
  return priorityAreas?.reduce((acc: any, item: any) => {
    if (!isNumber(item?.level?.order)) {
      console.error('No level order found for priority area', item);
    }
    const key = `${item?.level?.order}`;
    if (!Object.prototype.hasOwnProperty.call(acc, key)) {
      acc[key] = [];
    }
    acc[key].push(item);
    return acc;
  }, {});
};
