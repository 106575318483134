import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import {
  useRouteContentPermission,
  useRoutePermission,
} from '../../_utils/hooks/queries/use-route-permission';
import { useDashboardContext } from '../context/dashboard-context';

import { ScreenInfoLoading } from '../../_screens/_info/loading';

function RoutePermission({ children }: any) {
  const history = useHistory();
  const location = useLocation();

  const { user } = useDashboardContext();

  const { data, loading } = useRoutePermission();

  // track route permission and redirect if needed
  React.useEffect(() => {
    if (!data || loading) return;

    if (
      !data.routePermission?.access &&
      user.defaultRoute !== location.pathname
    ) {
      history.push(user.defaultRoute);
    }
  }, [data, history, loading, location.pathname, user.defaultRoute]);

  // ----------------------------------------------------------------------------------------------------------------------

  if (
    !data ||
    (!data.routePermission?.access && user.defaultRoute !== location.pathname)
  )
    return <ScreenInfoLoading />;

  // ----------------------------------------------------------------------------------------------------------------------

  return children;
}

export function RoutePermissions({ children }: any) {
  const { data: dataContentPermission } = useRouteContentPermission();

  // ----------------------------------------------------------------------------------------------------------------------

  // check content permission and if needed reload page
  React.useEffect(() => {
    if (!dataContentPermission) return;

    // reload page if the content permissions says to
    if (dataContentPermission.contentPermission?.reload) {
      window.localStorage.clear();
      window.location.reload();
    }
  }, [dataContentPermission]);

  // ----------------------------------------------------------------------------------------------------------------------

  if (dataContentPermission?.contentPermission?.reload)
    return <ScreenInfoLoading />;

  // ----------------------------------------------------------------------------------------------------------------------

  return <RoutePermission>{children}</RoutePermission>;
}
