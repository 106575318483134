import React from 'react';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import Button from '@mui/material/Button';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';

import { DialogModal } from '../components/dialog-modal';

import { logError } from '../_lib/error';
import { useDashboardContext } from '../_lib/context/dashboard-context';

import { DASHBOARD_LAUNCH } from '../_lib/graphql/mutations';
import {
  CONTEXT_DASHBOARD_QUERY,
  CONTEXT_USER_QUERY,
} from '../_lib/graphql/queries';

export function LaunchDashboard({ isDark, fullWidth, inStep, disabled }: any) {
  const { t } = useTranslation();
  const history = useHistory();

  const { setSnackbarIsError, setSnackbarOpen } = useDashboardContext();

  const [loading, setLoading] = React.useState(false);
  const [confirmDialogOpen, setConfirmDialogOpen] = React.useState(false);

  const [launchDashboardMutation] = useMutation(DASHBOARD_LAUNCH, {
    update: (cache, { data }) => {
      cache.writeQuery({
        query: CONTEXT_DASHBOARD_QUERY,
        data: { dashboard: data.dashboardLaunch.dashboard },
      });

      cache.writeQuery({
        query: CONTEXT_USER_QUERY,
        data: { user: data.dashboardLaunch.user },
      });
    },
  });

  const launchDashboard = () => {
    setLoading(true);
    launchDashboardMutation()
      .then(() => {
        history.push('/');
      })
      .catch((e) => {
        logError(e);
        setSnackbarIsError(true);
        setSnackbarOpen(true);
        setLoading(false);
      });
  };

  // -------------------------------------------------------------------------------------------------

  return (
    <>
      <DialogModal
        loading={loading}
        onSubmit={launchDashboard}
        onDismiss={() => setConfirmDialogOpen(false)}
        title={t('Ready to launch?')}
        type=""
        show={confirmDialogOpen}
      />

      <Button
        id="launch-dashboard"
        onClick={() => setConfirmDialogOpen(true)}
        variant={inStep ? 'text' : 'contained'}
        color={!inStep ? (isDark ? 'secondary' : 'primary') : undefined}
        startIcon={!inStep && <PlayCircleOutlineIcon />}
        fullWidth={fullWidth}
        disableElevation
        size={inStep ? 'small' : 'medium'}
        disabled={disabled}
      >
        {inStep ? t('Launch') : t('Launch Dashboard')}
      </Button>
    </>
  );
}
