import React from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';

import Skeleton from '@mui/material/Skeleton';

import { CONTENT_GROUP_QUERY } from '../../../_lib/graphql/queries';
import { CONTENT_GROUP_SUBSCRIPTION } from '../../../_lib/graphql/subscriptions';

function ContentDialogTitleGroupSubscriptions({
  subscribeToMore,
}: {
  subscribeToMore: () => () => void;
}) {
  React.useEffect(() => {
    return subscribeToMore();
  }, [subscribeToMore]);
  return null;
}

export function ContentDialogTitleGroup({ id }: any) {
  const { t } = useTranslation();

  const { loading, data, error, subscribeToMore } = useQuery(
    CONTENT_GROUP_QUERY,
    {
      variables: { contentGroupId: id },
    }
  );

  if (loading) return <Skeleton width={200} sx={{ display: 'inline-block' }} />;

  if (error) return <>{t('Error loading')}</>;

  return (
    <>
      {data.contentGroup.title}

      {/* subscriptions */}
      {!!data && (
        <ContentDialogTitleGroupSubscriptions
          subscribeToMore={() =>
            subscribeToMore({
              document: CONTENT_GROUP_SUBSCRIPTION,
              variables: { contentGroupId: id },
              updateQuery: (prev, { subscriptionData }) => {
                if (!subscriptionData.data) return prev;
                return subscriptionData.data;
              },
            })
          }
        />
      )}
    </>
  );
}
