import Chip, { ChipProps } from '@mui/material/Chip';

export function InterventionStatusChip({
  status,
  size,
}: {
  status: any;
  size?: ChipProps['size'];
}) {
  return (
    <Chip
      label={status?.name || '-'}
      color="default"
      sx={
        status?.color
          ? (theme) => ({
              bgcolor: status?.color,
              color: theme.palette.getContrastText(status?.color),
            })
          : undefined
      }
      size={size}
    />
  );
}

InterventionStatusChip.defaultProps = {
  size: 'medium',
};
