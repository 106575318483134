import React from 'react';

import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

export function LeadTextProfile({ user }: any) {
  // ------------------------------------------------------------------------------------------------------------
  // ------------------------------------------------------------------------------------------------------------
  // handlers
  // ------------------------------------------------------------------------------------------------------------

  const handleClick = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.preventDefault();
      event.stopPropagation();
      window.open(`mailto:${user.email}`, '_blank');
    },
    [user.email]
  );

  // ------------------------------------------------------------------------------------------------------------

  return (
    <>
      {/* button */}
      <Tooltip title={user.email} arrow placement="top">
        <Typography
          variant="inherit"
          component="span"
          sx={{
            color: 'primary.main',
            cursor: 'pointer',
            '&:hover': {
              textDecoration: 'underline',
            },
          }}
          onClick={handleClick}
        >
          {user.name}
        </Typography>
      </Tooltip>
    </>
  );
}
