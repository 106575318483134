import React from 'react';

import Button from '@mui/material/Button';

import { useTranslation } from 'react-i18next';
import { TargetUnitCategoriesDialog } from './dialog';

export function AdvancedManageTargetCategories({ enabled }: any) {
  const { t } = useTranslation();

  const [openDialog, setOpenDialog] = React.useState(false);

  return (
    <>
      <TargetUnitCategoriesDialog open={openDialog} setOpen={setOpenDialog} />

      {enabled && (
        <Button
          variant="outlined"
          size="small"
          onClick={() => setOpenDialog(true)}
        >
          {t('Manage Target Units')}
        </Button>
      )}
    </>
  );
}
