import contentFragment from './fragments/content/content.graphql';

import dashboardFragment from './fragments/dashboard/dashboard.graphql';
import dashboardSummaryContentStatusFragment from './fragments/area/dashboard-summary/content-status.graphql';

import userFragment from './fragments/accounts/user.graphql';
import userSetting from './fragments/accounts/user-setting.graphql';

import contentStatus from './fragments/content-status.graphql';

import target from './fragments/target.graphql';
import summaryTarget from './fragments/summary/summary-target.graphql';

export const DASHBOARD_FRAGMENT = dashboardFragment;
export const DASHBOARD_SUMMARY_CONTENT_STATUS_FRAGMENT =
  dashboardSummaryContentStatusFragment;

export const CONTENT_FRAGMENT = contentFragment;

export const USER_FRAGMENT = userFragment;
export const USER_SETTING_FRAGMENT = userSetting;

export const CONTENT_STATUS_FRAGMENT = contentStatus;

export const TARGET_FRAGMENT = target;

export const SUMMARY_TARGET_FRAGMENT = summaryTarget;

export const FILTER_VARIABLES_DEF = `
  $filterAll: Boolean
  $filterAndOr: String
  $filterPriorityAreaIds: [ID!]
  $filterInterventionIds: [ID!]
  $filterProgrammeIds: [ID!]
  $filterStatusIds: [ID!]
  $filterFinanciersIds: [ID!]
  $filterStakeholdersIds: [ID!]
  $filterRegionsIds: [ID!]
  $filterClassificationsIds: [ID!]
  $filterAreaFieldOptionsIds: [ID!]
  $filterAccess: String
  $filterSearchQuery: String
  $paginationPriorityAreasLimit: Int
  $paginationPriorityAreasOffset: Int
  $paginationInterventionsLimit: Int
  $paginationInterventionsOffset: Int
  $paginationChildrenLimit: Int
  $paginationChildrenOffset: Int
  $paginationTargetsLimit: Int
  $paginationTargetsOffset: Int
`;

export const FILTER_VARIABLES = `
  filters: {
    all: $filterAll
    andOr: $filterAndOr
    priorityAreaIds: $filterPriorityAreaIds
    interventionIds: $filterInterventionIds
    programmeIds: $filterProgrammeIds
    statusIds: $filterStatusIds
    financiersIds: $filterFinanciersIds
    stakeholdersIds: $filterStakeholdersIds
    regionsIds: $filterRegionsIds
    classificationsIds: $filterClassificationsIds
    areaFieldOptionsIds: $filterAreaFieldOptionsIds
    access: $filterAccess
    searchQuery: $filterSearchQuery
  }
  pagination: {
    priorityAreasLimit: $paginationPriorityAreasLimit
    priorityAreasOffset: $paginationPriorityAreasOffset
    interventionsLimit: $paginationInterventionsLimit
    interventionsOffset: $paginationInterventionsOffset
    childrenLimit: $paginationChildrenLimit
    childrenOffset: $paginationChildrenOffset
    targetsLimit: $paginationTargetsLimit
    targetsOffset: $paginationTargetsOffset
  }
`;
