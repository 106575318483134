import { useTranslation } from 'react-i18next';
import { ErrorBoundary } from 'react-error-boundary';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';

import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';

import { useDashboardContext } from '../../../../_lib/context/dashboard-context';

import { ReactErrorComponentSmall } from '../../../../_lib/react-error';

import { MyAccountPreferencesDetailTitle } from './title';
import { MyAccountPreferencesDetailMenu } from './menu';
import { MyAccountPreferencesDetailNameAndPosition } from './name-and-position';
import { MyAccountPreferencesDetailNotificationsList } from './notifications/list';

export function MyAccountPreferencesDetail({ setting }: any) {
  const { t } = useTranslation();

  const { user, dashboard } = useDashboardContext();

  return (
    <ErrorBoundary FallbackComponent={ReactErrorComponentSmall}>
      <Box
        sx={{
          display: 'flex',
        }}
      >
        <Tooltip title={t('Current Dashboard')} arrow>
          <RadioButtonCheckedIcon
            sx={{
              mr: 1.5,
              color: 'primary.main',
              visibility:
                setting.dashboard.id === dashboard.id ? 'visible' : 'hidden',
            }}
          />
        </Tooltip>

        {/* main */}
        <Box sx={{ flexGrow: 1 }}>
          <Stack spacing={1.7}>
            <MyAccountPreferencesDetailTitle setting={setting} />

            {/* name + postition */}
            <MyAccountPreferencesDetailNameAndPosition setting={setting} />

            {/* notifications preferences */}

            {/* all */}
            <MyAccountPreferencesDetailNotificationsList
              setting={setting}
              title={t('All Notifications')}
              options={['notificationsEnabled', 'notificationsEmailEnabled']}
            />

            {/* admin preferences */}
            {user.isAdmin && (
              <MyAccountPreferencesDetailNotificationsList
                setting={setting}
                title={t('Administrator Notifications')}
                options={[
                  'adminMemberAdded',
                  'adminMemberRemoved',
                  'adminMemberMadeAdmin',
                  'adminDashboardDeleted',
                ]}
              />
            )}

            {/* regular preferences */}
            <MyAccountPreferencesDetailNotificationsList
              setting={setting}
              title={t('Regular Notifications')}
              options={[
                'regularHourlyUpdates',
                'regularWeeklyUpdates',
                'regularContentAssigned',
                'regularContentMentioned',
              ]}
            />

            {/* other */}
            <MyAccountPreferencesDetailNotificationsList
              setting={setting}
              title={t('Other Notifications')}
              options={[
                'priorityAreaDeleted',
                'interventionDeleted',
                'regularContentDeleted',
                // 'targetDeleted',
                // 'financierDeleted',
              ]}
            />
          </Stack>
        </Box>

        {/* menu */}
        <Box>
          <MyAccountPreferencesDetailMenu setting={setting} />
        </Box>
      </Box>
    </ErrorBoundary>
  );
}
