import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import FilterListIcon from '@mui/icons-material/FilterList';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import UnfoldLessIcon from '@mui/icons-material/UnfoldLess';

import { useDashboardContext } from '../../_lib/context/dashboard-context';

export function PlanningPriorityAreaFormListHeader({
  priorityArea,
  loading,
  searchTerm,
  setSearchTerm,
  ogPas,
  setFilteredPAs,
  setOpenCollapse,
}: any) {
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();

  const { dashboard } = useDashboardContext();

  const [filterId, setFilterId] = React.useState<any>(null);

  // ----------------------------------------------------------------------------------------------------
  // ----------------------------------------------------------------------------------------------------
  // effects
  // ----------------------------------------------------------------------------------------------------

  // filter
  React.useEffect(() => {
    if (searchTerm) {
      const filtered = ogPas?.filter((pa: any) => {
        return (
          pa.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          pa.reference?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          pa.description?.toLowerCase().includes(searchTerm.toLowerCase())
        );
      });
      setFilteredPAs(filtered || []);
    } else {
      setFilteredPAs(ogPas || []);
    }
  }, [searchTerm, ogPas, setFilteredPAs]);

  // filter by id
  React.useEffect(() => {
    const params = new URLSearchParams(location.search);
    const id = params.get('id');
    setFilterId(id);

    if (id) {
      setFilteredPAs(ogPas?.filter((pa: any) => pa.id === id));
    } else {
      setFilteredPAs(ogPas || []);
    }
  }, [location, ogPas, setFilteredPAs]);

  // ----------------------------------------------------------------------------------------------------
  // ----------------------------------------------------------------------------------------------------
  // menu
  // ----------------------------------------------------------------------------------------------------

  // menu for collapse and expand
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleOpenMenu = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCollapseAll = () => {
    setOpenCollapse([]);
    setAnchorEl(null);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  // ----------------------------------------------------------------------------------------------------

  return (
    <Box
      sx={{
        my: 2.5,
        display: 'flex',
        alignItems: { xs: 'flex-start', sm: 'center' },
        flexDirection: { xs: 'column', sm: 'row' },
      }}
    >
      <Box sx={{ flexGrow: 1, pr: { xs: 0, sm: 2 }, mb: { xs: 2, sm: 0 } }}>
        {!!priorityArea && (
          <>
            {/* back */}
            <Button
              variant="outlined"
              startIcon={<ArrowBackIcon />}
              size="small"
              sx={{ mb: 1.5, textTransform: 'none' }}
              onClick={() =>
                history.push(
                  `${t('/planning')}${
                    priorityArea.urlRelativeLevel ||
                    `/${dashboard.priorityAreasSlug}`
                  }${!priorityArea?.archivedAt ? `?id=${priorityArea.id}` : ''}`
                )
              }
            >
              {priorityArea.reference}. {priorityArea.name}
            </Button>

            {/* title */}
            <Typography variant="h5" sx={{ fontSize: '1.3rem' }}>
              {loading ? (
                <Skeleton />
              ) : (
                <>
                  {priorityArea.reference} - {priorityArea.name}
                </>
              )}
            </Typography>
            <Typography variant="body1" color="textSecondary">
              {loading ? (
                <Skeleton />
              ) : (
                <>
                  {priorityArea?.levelNext?.name}{' '}
                  {!filterId && `(${ogPas?.length})`}
                </>
              )}
            </Typography>

            {/* view all */}
            {!!filterId && (
              <Button
                variant="outlined"
                disableElevation
                onClick={() => history.push(location.pathname)}
                sx={{ mt: 1, textTransform: 'none', whiteSpace: 'nowrap' }}
                size="small"
              >
                {t('View All')}:{' '}
                {dashboard.enableAreaLevels
                  ? priorityArea.levelNext?.name
                  : dashboard.priorityAreaName}
              </Button>
            )}
          </>
        )}
      </Box>

      {/* filter + menu */}

      {!filterId && !loading && (
        <>
          {/* filter */}
          <TextField
            label={t('Filter')}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            variant="outlined"
            size="small"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <FilterListIcon />
                </InputAdornment>
              ),
            }}
            sx={{
              '& .MuiInputBase-input': {
                bgcolor: 'white',
                pl: 1,
              },
            }}
            placeholder={`${t('Name')}${
              dashboard.mode === 'FULL' ? `, ${t('Reference')}` : ''
            }`}
          />

          {/* collapse / expand all */}
          <IconButton
            sx={{ ml: 1 }}
            aria-controls={open ? 'collapse-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleOpenMenu}
          >
            <MoreVertIcon />
          </IconButton>
          <Menu
            id="collapse-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleCloseMenu}
            MenuListProps={{
              'aria-labelledby': 'c-button',
            }}
          >
            <MenuItem onClick={handleCollapseAll}>
              <ListItemIcon>
                <UnfoldLessIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>{t('Collapse All')}</ListItemText>
            </MenuItem>
          </Menu>
        </>
      )}
    </Box>
  );
}
