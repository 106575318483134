import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from '@apollo/client';

import Box from '@mui/material/Box';
import DialogContent from '@mui/material/DialogContent';
import Dialog from '@mui/material/Dialog';
import LinearProgress from '@mui/material/LinearProgress';

import { DialogTitle } from '../../../DialogTitle/dialog-title';
import { useDashboardContext } from '../../../_lib/context/dashboard-context';

import { DASHBOARD_UPDATE } from '../../../_lib/graphql/mutations';
import {
  CONTEXT_DASHBOARD_QUERY,
  DASHBOARD_REPORTS_TEMPLATE_META_QUERY,
} from '../../../_lib/graphql/queries';
import { DASHBOARD_REPORTS_TEMPLATE_META_SUBSCRIPTION } from '../../../_lib/graphql/subscriptions';

import { logError } from '../../../_lib/error';
import { SubscribeToMore } from '../../../components/subscribe-to-more';

import { getReportTemplateMetas } from '../../../_utils/reports/get-report-template-meta';

import { ReportTemplateDialogMetaInputs } from './meta-inputs';
import { ReportTemplateDialogActions } from './actions';

export function ReportsTemplateDialog({
  isReport,
  open,
  setOpen,
  disableEdit,
  setDisableEdit,
}: any) {
  const { t } = useTranslation();

  const { setSnackbarOpen, setSnackbarIsError } = useDashboardContext();

  const [loading, setLoading] = React.useState(false);

  const [templateMeta, updateTemplateMeta] = React.useReducer(
    (prev: any, next: any) => {
      return { ...prev, ...next };
    },
    {
      countryFocalPoint: '',
      countryLead: '',
      countryFlag: null,
      countryFlagId: null,
      countryFlagName: null,
      countryFlagRemove: false,
      milestonesDisplay: 'TIMELINE',
      pageOrientation: 'LANDSCAPE',
    }
  );

  // -------------------------------------------------------------------------------------------------------

  // update dashboard mutation
  const [updateDashboard] = useMutation(DASHBOARD_UPDATE, {
    update(cache, { data: { dashboardUpdate } }) {
      cache.writeQuery({
        query: CONTEXT_DASHBOARD_QUERY,
        data: { dashboard: dashboardUpdate.dashboard },
      });
    },
  });

  const {
    data,
    loading: dataLoading,
    error,
    refetch,
    subscribeToMore,
  } = useQuery(DASHBOARD_REPORTS_TEMPLATE_META_QUERY);

  // -------------------------------------------------------------------------------------------------------
  // -------------------------------------------------------------------------------------------------------
  // handlers
  // -------------------------------------------------------------------------------------------------------

  const handleSave = React.useCallback(() => {
    setLoading(true);

    const {
      countryFocalPoint,
      countryLead,
      countryFlag,
      countryFlagRemove,
      milestonesDisplay,
      pageOrientation,
    } = templateMeta;

    updateDashboard({
      variables: {
        reportsDefaultTemplateMeta: {
          countryFocalPoint,
          countryLead,
          countryFlag:
            countryFlag instanceof File || countryFlag instanceof Blob
              ? countryFlag
              : undefined,
          countryFlagRemove,
          milestonesDisplay,
          pageOrientation,
        },
      },
    })
      .then(async (r: any) => {
        if (!r?.data?.dashboardUpdate?.success) {
          setSnackbarIsError(true);
          logError(
            new Error('Error updating dashboard reportsDefaultTemplate')
          );
        } else {
          await refetch();
          setDisableEdit(true);
        }
      })
      .catch((err) => {
        logError(err);
        setSnackbarIsError(true);
      })
      .finally(() => {
        setSnackbarOpen(true);
        setLoading(false);
      });
  }, [
    refetch,
    setDisableEdit,
    setSnackbarIsError,
    setSnackbarOpen,
    templateMeta,
    updateDashboard,
  ]);

  // handle submit
  const handleSubmit = React.useCallback(
    (e: any) => {
      e.preventDefault();
      handleSave();
    },
    [handleSave]
  );

  // handle close
  const handleClose = React.useCallback(() => {
    if (data?.dashboard?.reportsTemplateMeta) {
      updateTemplateMeta(
        getReportTemplateMetas(data.dashboard.reportsTemplateMeta)
      );
    }
    setOpen(false);
  }, [data, setOpen]);

  // -------------------------------------------------------------------------------------------------------
  // -------------------------------------------------------------------------------------------------------
  // effects
  // -------------------------------------------------------------------------------------------------------

  // reports template meta
  React.useEffect(() => {
    if (data?.dashboard?.reportsTemplateMeta) {
      updateTemplateMeta(
        getReportTemplateMetas(data.dashboard.reportsTemplateMeta)
      );
    }
  }, [data]);

  // error
  React.useEffect(() => {
    if (error) logError(error);
  }, [error]);

  // refetch if no data and not loading
  React.useEffect(() => {
    if (!data && !dataLoading && !error) {
      refetch();
    }
  }, [data, dataLoading, refetch, error]);

  // -------------------------------------------------------------------------------------------------------

  return (
    <>
      <Dialog open={open} maxWidth="xs" fullWidth>
        <DialogTitle onClose={handleClose}>
          {!isReport
            ? t('Default Reports Template Details')
            : t('Report Template Details')}
        </DialogTitle>
        {dataLoading ? (
          <LinearProgress />
        ) : (
          <DialogContent dividers>
            <Box
              sx={{
                py: 1,
              }}
              component="form"
              onSubmit={handleSubmit}
              id="report-template-dialog-form"
            >
              <ReportTemplateDialogMetaInputs
                disabled={loading || dataLoading || disableEdit}
                templateMeta={templateMeta}
                updateTemplateMeta={updateTemplateMeta}
              />
            </Box>

            {/* TODO: Add preview illustration of the template */}
          </DialogContent>
        )}

        {/* actions */}
        <ReportTemplateDialogActions
          handleClose={handleClose}
          disableEdit={disableEdit}
          setDisableEdit={setDisableEdit}
          loading={loading || dataLoading}
        />
      </Dialog>

      {/* subscribe to more */}
      {!!data && (
        <SubscribeToMore
          subscribeToMore={() =>
            subscribeToMore({
              document: DASHBOARD_REPORTS_TEMPLATE_META_SUBSCRIPTION,
              updateQuery: (prev, { subscriptionData }) => {
                if (!subscriptionData.data) return prev;
                return subscriptionData.data;
              },
            })
          }
        />
      )}
    </>
  );
}
