import { View, StyleSheet } from '@react-pdf/renderer';

import { reduceSummaries } from '../../../../../../_utils/content-utils/reduce-summaries';

import { ReportPdfSectionActivitiesListsList } from './list';

const styles = StyleSheet.create({
  box: {
    width: '100%',
    marginBottom: 20,
  },
});

export function ReportsPdfSectionsActivitiesLists({
  t,
  reportData,
  areas,
}: any) {
  const { dashboard } = reportData;

  const combined = reduceSummaries({
    priorityAreas: areas,
  });

  if (!combined.summary.contentSummary) return null;

  if (
    [
      !combined.summary.contentSummary.ongoing,
      !combined.summary.contentSummary.completed,
      !combined.summary.contentSummary.overdue,
      !combined.summary.contentSummary.issue,
      !combined.summary.contentSummary.pending,
      !combined.summary.contentSummary.unstarted,
    ].every(Boolean)
  )
    return null;

  return (
    <View>
      <View style={styles.box}>
        <ReportPdfSectionActivitiesListsList
          t={t}
          dashboard={dashboard}
          title={t('Most Recent Activities')}
          activities={{
            ongoing: combined.summary.contentSummary?.ongoing,
            completed: combined.summary.contentSummary?.completed,
          }}
        />
      </View>
      <View style={styles.box}>
        <ReportPdfSectionActivitiesListsList
          t={t}
          dashboard={dashboard}
          title={t('Main Risks')}
          activities={{
            overdue: combined.summary.contentSummary?.overdue,
            issue: combined.summary.contentSummary?.issue,
          }}
        />
      </View>
      <View style={styles.box}>
        <ReportPdfSectionActivitiesListsList
          t={t}
          dashboard={dashboard}
          title={t('Planned Activities')}
          activities={{
            pending: combined.summary.contentSummary?.pending,
            unstarted: combined.summary.contentSummary?.unstarted,
          }}
        />
      </View>
    </View>
  );
}
