import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';

import LoadingButton from '@mui/lab/LoadingButton';

interface AlertDialogProps {
  open: boolean;
  title: string;
  subTitle?: string;
  buttonLabels?: string[];
  cancel: () => void;
  confirm: () => void;
  loading?: boolean;
}

export function AlertDialog({
  open,
  title,
  subTitle,
  cancel,
  confirm,
  buttonLabels,
  loading,
}: AlertDialogProps) {
  if (!open) return null;
  return (
    <Alert
      severity="warning"
      sx={{
        '& .MuiAlert-message': {
          flexGrow: 1,
        },
      }}
    >
      <AlertTitle>{title}</AlertTitle>
      {subTitle || null}
      <Divider sx={{ my: 1.5 }} />
      <Stack spacing={2} direction="row" justifyContent="flex-end">
        <Button
          onClick={cancel}
          variant="text"
          size="small"
          disabled={loading}
          color="warning"
        >
          {buttonLabels ? buttonLabels[1] : 'No'}
        </Button>
        <LoadingButton
          onClick={confirm}
          variant="contained"
          size="small"
          color="warning"
          disableElevation
          loading={loading}
        >
          {buttonLabels ? buttonLabels[0] : 'Yes'}
        </LoadingButton>
      </Stack>
    </Alert>
  );
}

AlertDialog.defaultProps = {
  buttonLabels: ['Yes', 'No'],
  subTitle: null,
  loading: false,
};
