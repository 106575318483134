import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import { useParams } from 'react-router-dom';

import Dialog from '@mui/material/Dialog';
import TextField from '@mui/material/TextField';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import DialogActions from '@mui/material/DialogActions';
import Stack from '@mui/material/Stack';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';

import { DialogTitle } from '../../DialogTitle/dialog-title';
import { useDashboardContext } from '../../_lib/context/dashboard-context';

import { INTERVENTIONS_CREATE } from '../../_lib/graphql/mutations';
import { logError } from '../../_lib/error';
import {
  CONTEXT_USER_QUERY,
  INTERVENTIONS_PLANNING_QUERY,
  PRIORITY_AREA_PLANNING_QUERY,
} from '../../_lib/graphql/queries';

export function PlanningInterventionFormAddDialog({
  open,
  setOpen,
  listVariables,
}: any) {
  const { t } = useTranslation();

  const { priorityAreaId } = useParams<any>();
  const { dashboard, setSnackbarOpen, setSnackbarIsError } =
    useDashboardContext();

  const [loading, setLoading] = React.useState(false);

  const [interventions, setInterventions] = React.useState([{ name: '' }]);
  const [errors, setErrors] = React.useState([]);

  const [createInterventions] = useMutation(INTERVENTIONS_CREATE, {
    update(cache, { data }) {
      // update interventions
      cache.writeQuery({
        query: INTERVENTIONS_PLANNING_QUERY,
        data: { interventions: data.interventionsCreate.interventions },
        variables: {
          ...listVariables,
          priorityAreaId: data.interventionsCreate.priorityArea.id,
          filterIsArchived: false,
        },
      });

      // update priority area
      cache.writeQuery({
        query: PRIORITY_AREA_PLANNING_QUERY,
        data: { priorityArea: data.interventionsCreate.priorityArea },
        variables: {
          priorityAreaId: data.interventionsCreate.priorityArea.id,
        },
      });

      // update user
      cache.writeQuery({
        query: CONTEXT_USER_QUERY,
        data: { user: data.interventionsCreate.user },
      });
    },
  });

  const handleCancel = () => {
    setOpen(false);
    setErrors([]);
  };

  const handleRemove = (index: number) => {
    const newIntvs = structuredClone(interventions);
    newIntvs.splice(index, 1);
    setInterventions(newIntvs);
  };

  const handleSave = () => {
    setLoading(true);

    // check for errors
    setErrors([]);
    const newErrors = structuredClone(errors) as any;
    interventions.forEach((intv: any, index: number) => {
      if (!intv.name) {
        newErrors[index] = true;
      } else {
        newErrors[index] = false;
      }
    });
    setErrors(newErrors);

    // save if no errors
    if (interventions.every((intv: any) => intv.name)) {
      createInterventions({
        variables: {
          ...listVariables,
          priorityAreaId,
          names: interventions.map((intv: any) => intv.name),
        },
      })
        .then(() => {
          setInterventions([{ name: '' }]);
          setLoading(false);
          setSnackbarOpen(true);

          setOpen(false);
        })
        .catch((error: any) => {
          logError(error);
          setSnackbarIsError(true);
          setSnackbarOpen(true);

          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  };

  return (
    <Dialog open={open} fullWidth maxWidth="xs">
      <DialogTitle onClose={handleCancel}>
        {t('Add')}: {dashboard.interventionName}
      </DialogTitle>
      <DialogContent>
        <Stack spacing={2} sx={{ mt: 1 }}>
          {interventions.map((intv, index) => (
            <TextField
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              label={t('Name')}
              value={intv.name}
              onChange={(e) => {
                const newIntvs = structuredClone(interventions);
                newIntvs[index].name = e.target.value;
                setInterventions(newIntvs);
              }}
              fullWidth
              required
              InputProps={{
                endAdornment: index > 0 && (
                  <InputAdornment position="end">
                    <IconButton onClick={() => handleRemove(index)}>
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              size="small"
              sx={{ '& .MuiOutlinedInput-root': { pr: 0 } }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  const newIntvs = structuredClone(interventions);
                  newIntvs.push({ name: '' });
                  setInterventions(newIntvs);
                }
                if (e.key === 'Delete' && index > 0) {
                  e.preventDefault();
                  handleRemove(index);
                }
              }}
              autoFocus={index === interventions.length - 1}
              error={errors[index]}
              helperText={errors[index] ? t('Required') : ''}
              disabled={loading}
            />
          ))}
          <Button
            variant="text"
            color="primary"
            onClick={() => {
              const newIntvs = structuredClone(interventions);
              newIntvs.push({ name: '' });
              setInterventions(newIntvs);
            }}
            startIcon={<AddIcon />}
            sx={{ justifyContent: 'flex-start' }}
            disabled={loading}
          >
            {t('Add')}
          </Button>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button disabled={loading} variant="outlined" onClick={handleCancel}>
          {t('Cancel')}
        </Button>
        <LoadingButton
          loading={loading}
          variant="contained"
          disableElevation
          onClick={handleSave}
        >
          {t('Save')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

export default PlanningInterventionFormAddDialog;
