import { Action } from '@material-table/core';

import ArchiveIcon from '@mui/icons-material/Archive';
import UnarchiveIcon from '@mui/icons-material/Unarchive';
import DeleteIcon from '@mui/icons-material/Delete';

import { useTranslation } from 'react-i18next';
import { useContentAccess } from '../../_utils/hooks/use-content-access';

export const getTableActions = ({
  tab,
  handleRowDeleteClick,
  handleRowArchiveClick,
}: any) => {
  const tableActions: (
    | Action<object>
    | ((rowData: any) => Action<object>)
    | { action: (rowData: any) => Action<object>; position: string }
  )[] = [];

  // actions for activity views
  if (tab !== 'logins') {
    // archive activity
    tableActions.push((rowData) => {
      const { t } = useTranslation();
      const { content } = useContentAccess(rowData.id);
      return {
        icon: () =>
          rowData.status === 'archived' ? <UnarchiveIcon /> : <ArchiveIcon />,
        tooltip: `${
          rowData.status === 'archived' ? t('Unarchive') : t('Archive')
        }`,
        onClick: handleRowArchiveClick,
        hidden: !content || content.access !== 'EDIT',
      };
    });

    // delete activity
    tableActions.push((rowData) => {
      const { t } = useTranslation();
      const { content } = useContentAccess(rowData.id);

      return {
        icon: () => <DeleteIcon />,
        tooltip: `${t('Delete')}`,
        onClick: handleRowDeleteClick,
        hidden: !content || content.access !== 'EDIT',
      };
    });
  }

  return tableActions;
};
