import React from 'react';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import PlaylistRemoveIcon from '@mui/icons-material/PlaylistRemove';

import { useDashboardContext } from '../../../../../_lib/context/dashboard-context';

import { MyAccountPreferencesDetailTitleSwitchDashboardDialog } from './switch-dashboard-dialog';
import { MyAccountPreferencesDetailTitleLeaveDashboardDialog } from './leave-dashboard-dialog';

export function MyAccountPreferencesDetailMenu({ setting }: any) {
  const { t } = useTranslation();

  const { dashboard } = useDashboardContext();

  const [openSwitchDashboardDialog, setOpenSwitchDashboardDialog] =
    React.useState(false);
  const [openLeaveDashboardDialog, setOpenLeaveDashboardDialog] =
    React.useState(false);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box>
      <IconButton
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {dashboard.id !== setting.dashboard.id && (
          <MenuItem
            onClick={() => {
              setOpenSwitchDashboardDialog(true);
              handleClose();
            }}
          >
            <ListItemIcon>
              <SwapHorizIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>{t('Switch to Dashboard')}</ListItemText>
          </MenuItem>
        )}
        <MenuItem
          onClick={() => {
            setOpenLeaveDashboardDialog(true);
            handleClose();
          }}
        >
          <ListItemIcon>
            <PlaylistRemoveIcon fontSize="small" color="error" />
          </ListItemIcon>
          <ListItemText
            sx={{
              color: 'error.main',
            }}
          >
            {t('Leave Dashboard')}
          </ListItemText>
        </MenuItem>
      </Menu>

      {/* switch dashboard dialog */}
      <MyAccountPreferencesDetailTitleSwitchDashboardDialog
        open={openSwitchDashboardDialog}
        setOpen={setOpenSwitchDashboardDialog}
        setting={setting}
      />

      {/* leave dashboard dialog */}
      <MyAccountPreferencesDetailTitleLeaveDashboardDialog
        open={openLeaveDashboardDialog}
        setOpen={setOpenLeaveDashboardDialog}
        setting={setting}
      />
    </Box>
  );
}
