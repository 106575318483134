import React from 'react';

import { useTranslation } from 'react-i18next';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Skeleton from '@mui/material/Skeleton';

import { DialogTitle } from '../../DialogTitle/dialog-title';

import { ReactErrorComponent } from '../../_lib/react-error';

import { DragggableDialogList } from './list';
import { DraggableDialogAdd } from './add';
import { DraggableDialogUnassignedWeight } from './unassigned-weight';

export function DragggableDialogDialog({
  open,
  setOpen,
  details,
  setDetails,
  ogDetails,
  title,
  inputLabel,
  addLabel,
  createMutation,
  updateMutation,
  deleteMutation,
  updateOrderMutation,
  disableEnforceFocus,
  loadingData,
  errorData,
  includeColorPicker,
  includeWeight,
  includeUnitCategory,
}: any) {
  const { t } = useTranslation();

  // ----------------------------------------------------------------------------------------------------
  // ----------------------------------------------------------------------------------------------------
  // handlers
  // ----------------------------------------------------------------------------------------------------

  const handleClose = () => {
    setOpen(false);
  };

  // ----------------------------------------------------------------------------------------------------
  // ----------------------------------------------------------------------------------------------------
  // effects
  // ----------------------------------------------------------------------------------------------------

  // ----------------------------------------------------------------------------------------------------

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="xs"
      disableEnforceFocus={disableEnforceFocus}
    >
      <DialogTitle onClose={handleClose}>{title}</DialogTitle>
      <DialogContent dividers>
        {loadingData &&
          [1, 2, 3].map((i) => (
            <Skeleton
              key={i}
              sx={{ height: '50px', my: 2 }}
              variant="rectangular"
            />
          ))}

        {!loadingData && errorData && <ReactErrorComponent error={errorData} />}

        {includeWeight && <DraggableDialogUnassignedWeight details={details} />}

        {!loadingData && !errorData && details && (
          <DragggableDialogList
            details={details}
            setDetails={setDetails}
            ogDetails={ogDetails}
            inputLabel={inputLabel}
            updateMutation={updateMutation}
            deleteMutation={deleteMutation}
            updateOrderMutation={updateOrderMutation}
            loadingData={loadingData}
            includeColorPicker={includeColorPicker}
            includeWeight={includeWeight}
            includeCategory={includeUnitCategory}
          />
        )}

        {/* dialog add */}
        <DraggableDialogAdd
          loadingData={loadingData}
          addLabel={addLabel}
          createMutation={createMutation}
          includeColorPicker={includeColorPicker}
          includeWeight={includeWeight}
          includeCategory={includeUnitCategory}
        />
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          variant="contained"
          onClick={handleClose}
          disabled={loadingData}
          disableElevation
        >
          {t('Close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
