import { reverse } from 'lodash';
import { autoId } from './auto-id';

// -------------------------------------------------------------------------------------------------------
// -------------------------------------------------------------------------------------------------------
// aggregate priority area targets
// -------------------------------------------------------------------------------------------------------
// -------------------------------------------------------------------------------------------------------

export const aggregateTargets = (ogTargets: any) => {
  // Clone targets to avoid mutating the original
  const targets = structuredClone(ogTargets || []);

  // Aggregate targets according to the unit
  // e.g targetsByUnit['kgs'] = [{target}, {target}]
  const targetsByUnit: any = targets.reduce((acc: any, t: any) => {
    const unitId = t.targetUnit.id;
    const target: any = {
      ...t,
      targets: [t],
      targetIntervals: [
        {
          id: t.id,
          amount: t.startAmount,
          date: t.startedOn,
        },
        {
          id: t.id,
          amount: t.targetAmount,
          date: t.deadline,
        },
      ],
    };

    if (!acc[unitId]) {
      acc[unitId] = [target];
    } else {
      // check if target from same area is already in the list
      const targetFromSameArea = acc[unitId].find((t2: any) =>
        t.priorityArea
          ? t2.priorityArea?.id === t.priorityArea.id
          : t2.intervention?.id === t.intervention.id
      );
      if (targetFromSameArea) {
        if (new Date(t.startedOn) < new Date(targetFromSameArea.startedOn)) {
          targetFromSameArea.startedOn = t.startedOn;
          targetFromSameArea.startAmount = t.startAmount;
        }
        if (new Date(t.deadline) > new Date(targetFromSameArea.deadline)) {
          targetFromSameArea.deadline = t.deadline;
          targetFromSameArea.targetAmount = t.targetAmount;
        }
        if (new Date(t.latestDate) > new Date(targetFromSameArea.latestDate)) {
          targetFromSameArea.latestDate = t.latestDate;
          targetFromSameArea.latestAmount = t.latestAmount;
        }

        targetFromSameArea.targetFinancedAmount += t.targetFinancedAmount;

        targetFromSameArea.targets.push(t);
        targetFromSameArea.updates.push(...t.updates);

        targetFromSameArea.targetIntervals = [
          ...targetFromSameArea.targetIntervals,
          ...target.targetIntervals,
        ].sort(
          (a: any, b: any) =>
            new Date(b.date).valueOf() - new Date(a.date).valueOf()
        );

        acc[unitId] = acc[unitId].map((t2: any) =>
          t2.id === targetFromSameArea.id ? targetFromSameArea : t2
        );
      } else {
        // if target from same area is not in the list, add it
        acc[unitId].push(target);
      }
    }
    return acc;
  }, {});

  // -------------------------------------------------------------------------------------------------------

  // Loop through each unit and aggregate targets
  return Object.keys(targetsByUnit).map((unitId) => {
    // Create the default aggregated target
    const aggregatedTarget = {
      isAggregated: true,
      id: unitId,
      targetUnit: targetsByUnit[unitId][0].targetUnit,
      dashboard: targetsByUnit[unitId][0].dashboard,

      targets: targetsByUnit[unitId].flatMap((t: any) => t.targets),
      targetIntervals: targetsByUnit[unitId].flatMap(
        (t: any) => t.targetIntervals
      ),

      interventions: [] as any,
      priorityAreas: [] as any,

      startAmount: 0,
      startedOn: targetsByUnit[unitId][0].startedOn,

      targetAmount: 0,
      deadline: targetsByUnit[unitId][0].deadline,

      targetFinancedAmount: 0,

      updates: [] as any,
      updatesAll: [] as any,

      latestAmount: targetsByUnit[unitId][0].latestAmount,
      latestDate: targetsByUnit[unitId][0].latestDate,

      progressPerc: 0,

      partner: '',
    };

    // -------------------------------------------------------------------------------------------------------
    // Loop through each target and aggregate
    targetsByUnit[unitId].forEach((t: any) => {
      // check date extremes
      if (new Date(t.startedOn) < new Date(aggregatedTarget.startedOn)) {
        aggregatedTarget.startedOn = t.startedOn;
      }
      if (new Date(t.deadline) > new Date(aggregatedTarget.deadline)) {
        aggregatedTarget.deadline = t.deadline;
      }
      if (new Date(t.latestDate) > new Date(aggregatedTarget.latestDate)) {
        aggregatedTarget.latestDate = t.latestDate;
      }

      // Add intervention and priority area to list of interventions
      if (t.intervention) {
        aggregatedTarget.interventions.push(t.intervention);
      }
      if (t.priorityArea) {
        aggregatedTarget.priorityAreas.push(t.priorityArea);
      }

      // Add partners
      aggregatedTarget.partner = t.partner
        ? `${aggregatedTarget.partner}, ${t.partner}`
        : aggregatedTarget.partner;

      // -------------------------------------------------------------------------------------------------------

      // Sum of all targets and start amounts
      aggregatedTarget.targetAmount += t.targetAmount;
      aggregatedTarget.startAmount += t.startAmount;
      aggregatedTarget.latestAmount += t.latestAmount;

      aggregatedTarget.targetFinancedAmount += t.targetFinancedAmount;

      const target = {
        updates: [] as any,
      } as any;
      // add start amount as update
      target.updates.push({
        id: `start-${t.id}`,
        date: t.startedOn,
        value: t.startAmount,
        intervention: t.intervention,
        priorityArea: t.priorityArea,
        isTargetStart: true,
        target: t,
      });
      // add all updates and add intervention info
      target.updates = target.updates.concat(
        t.updates.map((update: any) => ({
          ...update,
          intervention: t.intervention,
          priorityArea: t.priorityArea,
        }))
      );

      // Sort updates by date : 2021 to 2020
      target.updates = target.updates.sort(
        (a: any, b: any) =>
          new Date(b.date).valueOf() - new Date(a.date).valueOf()
      );
      target.updatesAll = target.updates;

      // push updates to aggregated target
      aggregatedTarget.updates.push(...target.updates);
    });

    // Sort updates by date : 2021 to 2020
    aggregatedTarget.updates = aggregatedTarget.updates.sort(
      (a: any, b: any) =>
        new Date(b.date).valueOf() - new Date(a.date).valueOf()
    );

    // Aggregate update values to get latest date and amount
    aggregatedTarget.latestDate = aggregatedTarget.updates[0].date;
    let cummulativeValue = 0;
    const targetUpdates = reverse(structuredClone(aggregatedTarget.updates));
    targetUpdates.forEach((update: any, i: number) => {
      if (i === 0) {
        cummulativeValue += update.value;
      } else {
        const prevTgtUpdates = targetUpdates
          .slice(0, i)
          .findLast((upd: any) => upd.target.id === update.target.id);
        const prevTgtUpdateValue = prevTgtUpdates ? prevTgtUpdates.value : 0;
        cummulativeValue += update.value - prevTgtUpdateValue;
      }
    });
    aggregatedTarget.latestAmount = cummulativeValue;

    // calculate progress percentage

    aggregatedTarget.progressPerc = 0;
    if (aggregatedTarget.targetAmount - aggregatedTarget.startAmount === 0) {
      // target amount and start amount are the same
      if (aggregatedTarget.latestAmount - aggregatedTarget.targetAmount === 0) {
        // latest amount is the same as target amount
        aggregatedTarget.progressPerc = 100;
      } else {
        // latest amount is not the same as target amount
        aggregatedTarget.progressPerc =
          ((aggregatedTarget.latestAmount - aggregatedTarget.targetAmount) /
            aggregatedTarget.targetAmount) *
          100;
      }
    } else {
      aggregatedTarget.progressPerc =
        ((aggregatedTarget.latestAmount - aggregatedTarget.startAmount) /
          (aggregatedTarget.targetAmount - aggregatedTarget.startAmount)) *
        100;
    }

    if (Number.isNaN(aggregatedTarget.progressPerc)) {
      aggregatedTarget.progressPerc = 0;
    }

    return aggregatedTarget;
  });
};

// -------------------------------------------------------------------------------------------------------
// -------------------------------------------------------------------------------------------------------
// get targets
// -------------------------------------------------------------------------------------------------------
// -------------------------------------------------------------------------------------------------------

export const getTargets = (areas: any) =>
  areas?.flatMap((area: any) => aggregateTargets(area?.targets || []) || []);

export const getTargetRouteId = (target: any, area: any) => {
  if (!area) return target.id;
  if (area.id === 'overview') return `overview-${target.id}`;
  if (area.__typename === 'PriorityAreaType')
    return `pa-${area.id}-${target.id}`;
  if (area.__typename === 'InterventionType')
    return `int-${area.id}-${target.id}`;
  return autoId();
};
