import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { gql, useQuery } from '@apollo/client';

import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Skeleton from '@mui/material/Skeleton';
import TableChartIcon from '@mui/icons-material/TableChart';

import { DashboardContext } from '../_lib/context/dashboard-context';
import { ReactErrorComponent } from '../_lib/react-error';
import { SubscribeToMore } from '../components/subscribe-to-more';

import { SectionTitle } from '../components/section-title';

import { TrackerOverview } from '../tracker/tracker-overview';

import { CONTENT_STATUS_FRAGMENT } from '../_lib/graphql/fragments';

const PA_FRAGMENT = gql`
  ${CONTENT_STATUS_FRAGMENT}
  fragment PriorityAreaTrackerOverviewFragment on PriorityAreaType {
    id
    urlRelative
    summary {
      id
      contentSummary {
        ...ContentStatusFragment
      }
    }
  }
`;

const PA_QUERY = gql`
  ${PA_FRAGMENT}
  query PriorityAreaTrackerOverview(
    $priorityAreaId: ID!
    $filterInterventionIds: [ID!]
    $filterProgrammeIds: [ID!]
    $filterStatusIds: [ID!]
    $filterFinanciersIds: [ID!]
    $filterStakeholdersIds: [ID!]
    $filterRegionsIds: [ID!]
    $filterClassificationsIds: [ID!]
    $filterAreaFieldOptionsIds: [ID!]
    $filterAndOr: String = "AND"
  ) {
    priorityArea(
      priorityAreaId: $priorityAreaId
      filters: {
        interventionIds: $filterInterventionIds
        programmeIds: $filterProgrammeIds
        statusIds: $filterStatusIds
        financiersIds: $filterFinanciersIds
        stakeholdersIds: $filterStakeholdersIds
        regionsIds: $filterRegionsIds
        classificationsIds: $filterClassificationsIds
        areaFieldOptionsIds: $filterAreaFieldOptionsIds
        andOr: $filterAndOr
      }
    ) {
      ...PriorityAreaTrackerOverviewFragment
    }
  }
`;

const PA_SUBSCRIPTION = gql`
  ${PA_FRAGMENT}
  subscription PriorityAreaTrackerOverview(
    $priorityAreaId: ID!
    $filterInterventionIds: [ID!]
    $filterProgrammeIds: [ID!]
    $filterStatusIds: [ID!]
    $filterFinanciersIds: [ID!]
    $filterStakeholdersIds: [ID!]
    $filterRegionsIds: [ID!]
    $filterClassificationsIds: [ID!]
    $filterAreaFieldOptionsIds: [ID!]
    $filterAndOr: String = "AND"
  ) {
    priorityArea(
      priorityAreaId: $priorityAreaId
      filters: {
        interventionIds: $filterInterventionIds
        programmeIds: $filterProgrammeIds
        statusIds: $filterStatusIds
        financiersIds: $filterFinanciersIds
        stakeholdersIds: $filterStakeholdersIds
        regionsIds: $filterRegionsIds
        classificationsIds: $filterClassificationsIds
        areaFieldOptionsIds: $filterAreaFieldOptionsIds
        andOr: $filterAndOr
      }
    ) {
      ...PriorityAreaTrackerOverviewFragment
    }
  }
`;

export function PriorityAreaTrackerOverview() {
  const { t } = useTranslation();
  const history = useHistory();
  const { priorityAreaId } = useParams<any>();

  const { navbarFilterVariables } = React.useContext(DashboardContext);

  const { data, loading, error, refetch, subscribeToMore } = useQuery(
    PA_QUERY,
    {
      variables: {
        priorityAreaId,
        ...navbarFilterVariables,
      },
    }
  );

  // --------------------------------------------------------------------------------------------------------------

  if (error) return <ReactErrorComponent error={error} />;

  if (loading || !data) {
    if (!data && !loading) refetch();
    return <Skeleton variant="rectangular" height={400} sx={{ my: 3 }} />;
  }

  // --------------------------------------------------------------------------------------------------------------

  return (
    <>
      <Card sx={{ my: 3 }}>
        <CardContent sx={{ p: 4 }}>
          <SectionTitle
            title={t('Activities')}
            subheader={t(
              'Summary of upcoming, overdue, and pending activities and issues'
            )}
            actions={
              <Button
                variant="contained"
                startIcon={<TableChartIcon />}
                onClick={() =>
                  history.push(
                    `${data.priorityArea.urlRelative}${t('/tracker')}`
                  )
                }
                disableElevation
                sx={{ displayPrint: 'none' }}
              >
                {t('Open Activity Tracker')}
              </Button>
            }
            sx={{ mb: 2 }}
          />
          <TrackerOverview priorityAreas={[data.priorityArea]} />
        </CardContent>
      </Card>

      {/* subscribe to more */}
      <SubscribeToMore
        subscribeToMore={() =>
          subscribeToMore({
            document: PA_SUBSCRIPTION,
            variables: {
              priorityAreaId,
              ...navbarFilterVariables,
            },
            updateQuery: (prev, { subscriptionData }) => {
              if (!subscriptionData.data) return prev;
              return subscriptionData.data;
            },
          })
        }
        variables={{
          priorityAreaId,
          ...navbarFilterVariables,
        }}
      />
    </>
  );
}
