import React from 'react';

export const PlanningContext = React.createContext<any>({});

export function PlanningContextProvider({ children }: any) {
  const [tab, setTab] = React.useState(0);
  const [inPlanningEdit, setInPlanningEdit] = React.useState(false);

  const contextValue = React.useMemo(
    () => ({
      tab,
      setTab,
      inPlanningEdit,
      setInPlanningEdit,
    }),
    [tab, setTab, inPlanningEdit, setInPlanningEdit]
  );

  return (
    <PlanningContext.Provider value={contextValue}>
      {children}
    </PlanningContext.Provider>
  );
}

export function usePlanningContext() {
  return React.useContext(PlanningContext);
}
