import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';

import TableCell from '@mui/material/TableCell';
import MuiTableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

import Box from '@mui/material/Box';

import { useHistory } from 'react-router-dom';
import { ReactErrorComponent } from '../../../../_lib/react-error';
import { PriorityArea } from '../executive-summary/executive-summary.types';

export type ExecutiveSummaryTableRowProps = {
  id?: string;
  data?: Partial<{
    title: string;
    urlRelative: string;
    lead: Exclude<PriorityArea['leads'], undefined>[number];
    priorityArea: Partial<PriorityArea>;
  }>;
};

export function ExecutiveSummaryTableRow({
  id,
  data = {},
}: ExecutiveSummaryTableRowProps) {
  const { t } = useTranslation();
  const history = useHistory();
  const { lead, priorityArea, title, urlRelative } = data;

  // ------------------------------------------------------------------------------------------------------
  // ------------------------------------------------------------------------------------------------------
  // handlers
  // ------------------------------------------------------------------------------------------------------

  const handleRowClick:
    | React.MouseEventHandler<HTMLTableRowElement>
    | undefined = (event) => {
    event.preventDefault();
    event.stopPropagation();
    history.push(`?content=${id}`);
  };

  // ------------------------------------------------------------------------------------------------------

  const rowClickProps = {
    ...(urlRelative
      ? {
          onClick: handleRowClick,
          hover: true,
        }
      : {}),
  };

  return (
    <ErrorBoundary FallbackComponent={ReactErrorComponent}>
      <MuiTableRow
        id={id}
        sx={{
          '&:last-child td, &:last-child th': { border: 0 },
          '&.MuiTableRow-hover:hover': {
            cursor: 'pointer',
          },
        }}
        {...rowClickProps}
      >
        <TableCell component="th" scope="row">
          <Box mb={0.5}>
            <Typography
              variant="body2"
              sx={{
                textDecoration: 'underline',
              }}
            >
              {title}
            </Typography>
          </Box>
          <Box
            display="flex"
            flexDirection="row"
            sx={{
              '& > :not(:first-of-type)': {
                ml: 3,
              },
            }}
          >
            <Box
              display="flex"
              alignItems="center"
              sx={{
                '& > :not(:first-of-type)': {
                  ml: 1,
                },
              }}
            >
              <Typography fontWeight="600">{t('Lead')}:</Typography>
              <Typography variant="body2">{lead?.name}</Typography>
            </Box>
            <Box
              display="flex"
              alignItems="center"
              sx={{
                '& > :not(:first-of-type)': {
                  ml: 1,
                },
              }}
            >
              <Typography fontWeight="600">Cabinet:</Typography>
              <Typography variant="body2">{priorityArea?.name}</Typography>
            </Box>
            <Box
              display="flex"
              alignItems="center"
              sx={{
                '& > :not(:first-of-type)': {
                  ml: 1,
                },
              }}
            >
              <Typography fontWeight="600">Entites:</Typography>
              <Typography variant="body2">
                {priorityArea?.level?.name}
              </Typography>
            </Box>
          </Box>
        </TableCell>
      </MuiTableRow>
    </ErrorBoundary>
  );
}
