import React from 'react';

import Skeleton from '@mui/material/Skeleton';

import { useSummary } from '../../_utils/hooks/queries/use-summary';
import { AreaSummaryChartFinance } from '../../components/area-summary/chart/finance';

export function DashboardSummaryFinance({
  filterPriorityAreaIds,
  filterInterventionIds,
}: any) {
  const variables = React.useMemo(
    () => ({
      filterPriorityAreaIds,
      filterInterventionIds,
    }),
    [filterInterventionIds, filterPriorityAreaIds]
  );

  const { data, loading, error } = useSummary('finance', variables);

  if (error) return null;

  if (loading)
    return <Skeleton variant="rectangular" height={120} sx={{ mb: 2 }} />;

  return (
    <AreaSummaryChartFinance
      area={data?.dashboard}
      titleVariant="h6"
      bodyVariant="body1"
      barHeight={15}
    />
  );
}
