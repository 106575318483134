import React from 'react';
import { useQuery } from '@apollo/client';

import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import Skeleton from '@mui/material/Skeleton';

import { ReactErrorComponent } from '../../_lib/react-error';

import { ACTIVITY_TRACKER_QUERY } from '../../_lib/graphql/queries';
import { ACTIVITY_TRACKER_SUBSCRIPTION } from '../../_lib/graphql/subscriptions';

import { Tracker } from '..';
import { SubscribeToMoreComponent } from '../../components/subscribe-to-more';
import { ActivityTrackerBreadcrumb } from '../../breadcrumbs/activity-tracker-breadcrumb';

export function ActivityTrackerLoader() {
  const { loading, error, data, refetch, subscribeToMore } = useQuery(
    ACTIVITY_TRACKER_QUERY
  );

  const content = React.useMemo(
    () =>
      data?.priorityAreas.items
        .flatMap((p: any) => p.interventions)
        .flatMap((i: any) => i.content) || [],
    [data]
  );

  if (error) return <ReactErrorComponent error={error} />;

  if (loading || !data) {
    if (!data && !loading) refetch();
    return (
      <Container maxWidth={false} sx={{ py: 4 }}>
        <Skeleton variant="rectangular" width="100%" height={500} />
      </Container>
    );
  }

  return (
    <Container maxWidth={false}>
      <ActivityTrackerBreadcrumb />

      <Card id="tracker" sx={{ p: 3, my: 4 }}>
        <Tracker content={content} />
      </Card>

      {/* subscription */}
      <SubscribeToMoreComponent
        document={ACTIVITY_TRACKER_SUBSCRIPTION}
        subscribeToMore={subscribeToMore}
      />
    </Container>
  );
}
