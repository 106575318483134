import React from 'react';

import { ChartOptions, ChartData, ChartDataset } from 'chart.js';

import { useTranslation } from 'react-i18next';

import { useDashboardContext } from '../../../../_lib/context/dashboard-context';
import { truncateChartLabel } from '../../../../planning/analytics/charts-config';
import { PriorityArea } from '../executive-summary/executive-summary.types';

type UseExecutiveSummaryStatusChartProps = {
  data: any;
  title?: string;
  currentPriorityAreas?: any[];
  filters?: any;
};

export const useExecutiveSummaryStatusChart = ({
  data,
  title,
  filters,
}: UseExecutiveSummaryStatusChartProps) => {
  const {
    dashboard: { priorityAreaName, colorPalette },
  } = useDashboardContext();
  const { t } = useTranslation();

  const chartData = React.useMemo(() => {
    if (data?.priorityAreas) {
      const consolidatedPAStatuses = { byArea: {} } as any;
      const priorityAreas = data.priorityAreas.items?.filter((pa: any) => {
        // no filters
        if (!Object.keys(filters || {}).length) {
          return !pa.parent;
        }
        // filter parent
        if (!!filters?.['0'] && !pa.parent) {
          return false;
        }
        // filter children
        if (
          !!filters?.['1'] &&
          Object.keys(filters?.['0'] || {}).includes(`${pa.id}`)
        ) {
          return false;
        }
        return true;
      });

      // byArea
      priorityAreas?.forEach((pa: PriorityArea) => {
        const byAreaKey = `${pa.name}${pa.reference}`;
        if (!consolidatedPAStatuses.byArea[byAreaKey]) {
          consolidatedPAStatuses.byArea[byAreaKey] = {} as any;
        }
        pa.interventions?.forEach((intervention: any) => {
          if (!intervention.status) {
            if (!consolidatedPAStatuses.byArea[byAreaKey].Unknown) {
              consolidatedPAStatuses.byArea[byAreaKey].Unknown = 0;
            }
            consolidatedPAStatuses.byArea[byAreaKey].Unknown += 1;
          } else {
            if (
              !consolidatedPAStatuses.byArea[byAreaKey][
                intervention.status.name
              ]
            ) {
              consolidatedPAStatuses.byArea[byAreaKey][
                intervention.status.name
              ] = 0;
            }
            consolidatedPAStatuses.byArea[byAreaKey][
              intervention.status.name
            ] += 1;
          }
        });
        consolidatedPAStatuses.byArea[byAreaKey].stackTotal =
          pa.interventions?.length || 0;
      });

      let unknownExists = false;
      const statuses: any[] = priorityAreas?.reduce((acc: any, pa: any) => {
        pa.interventions?.forEach((intervention: any) => {
          if (intervention.status) {
            if (!acc.find((s: any) => s.name === intervention.status?.name)) {
              acc.push({
                color: intervention.status?.color,
                name: intervention.status?.name,
              });
            }
          } else {
            unknownExists = true;
          }
        });
        return acc;
      }, []);

      // add unknown status where interventions have no status
      if (unknownExists) {
        statuses.push({ name: 'Unknown', color: 'grey' });
      }

      const stackTotal = Object.keys(consolidatedPAStatuses.byArea).map(
        (areaNameKey) => consolidatedPAStatuses.byArea[areaNameKey].stackTotal
      );

      const byAreaDatasets = Array.from(
        new Set(statuses.map((s: any) => s.name))
      ).map((status: any, index) => {
        const statusName =
          statuses.find((s: any) => s.name === status).name || 'Unknown';
        const label = statusName === 'Unknown' ? t('Unknown') : statusName;
        return {
          label,
          data: priorityAreas.map((pa: any) => {
            const byAreaKey = `${pa.name}${pa.reference}`;
            return (
              consolidatedPAStatuses.byArea[byAreaKey][status || 'Unknown'] || 0
            );
          }),
          backgroundColor: statuses[index].color,
          categoryPercentage: 0.75,
          barPercentage: 0.75,
          stackTotal,
        } as ChartDataset & {
          stackTotal: number[];
        };
      });

      const getOptions = (axisLabel: string) =>
        ({
          plugins: {
            title: {
              display: true,
              text: title,
            },
            legend: {
              position: 'bottom' as const,
            },
            datalabels: {
              color: colorPalette.secondary.textColor,
              formatter(value, context) {
                if (value === 0) return '';
                const theDataset = context.dataset as ChartDataset & {
                  stackTotal: number[];
                };
                const total = theDataset.stackTotal?.at(context.dataIndex) || 0;
                if (total === 0) return '';
                return `${Math.floor((value / total) * 100)}%`;
              },
              labels: {
                title: {
                  font: {
                    weight: 'bold',
                  },
                },
                value: {
                  color: colorPalette.secondary.textColor,
                },
              },
            },
          },
          responsive: true,
          scales: {
            x: {
              stacked: true,
              title: {
                display: true,
                text: axisLabel,
              },
              ticks: {
                font: {
                  size: 10,
                },
              },
            },
            y: {
              stacked: true,
            },
          },
        } as ChartOptions);

      const newChartData = {
        labels: priorityAreas.map((paItem: any) =>
          truncateChartLabel(`${paItem?.reference}. ${paItem?.name}`)
        ),
        datasets: byAreaDatasets,
      } as ChartData;

      return {
        groupedByArea: {
          data: newChartData,
          options: getOptions(priorityAreaName),
        },
      };
    }

    return {
      groupedByArea: null,
    };
  }, [
    data?.priorityAreas,
    priorityAreaName,
    filters,
    t,
    title,
    colorPalette.secondary.textColor,
  ]);

  return {
    chartData,
  };
};
