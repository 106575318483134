import React from 'react';
import { useTranslation } from 'react-i18next';

import Skeleton from '@mui/material/Skeleton';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import { logError } from '../../_lib/error';
import { useDashboardContext } from '../../_lib/context/dashboard-context';

import { DraggableDialogDetailInput } from './detail-input';

export function DraggableDialogDetail({
  detail,
  inputLabel,
  deleteMutation,
  updateMutation,
  loadingData,
  includeColorPicker,
  includeWeight,
  includeCategory,
  disableNameInput,
  disableDelete,
  italicTitle,
}: any) {
  const { t } = useTranslation();

  const {
    setSnackbarOpen,
    setSnackbarIsDelete,
    setSnackbarIsError,
    setSnackbarMessage,
  } = useDashboardContext();

  const [isEditing, setIsEditing] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  // Menu
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleMenuBtnClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  // --------------------------------------------------------------------------------------------------------------------
  // --------------------------------------------------------------------------------------------------------------------
  // handlers
  // --------------------------------------------------------------------------------------------------------------------

  // Edit
  const handleEdit = () => {
    setIsEditing(true);
    setAnchorEl(null);
  };

  // Delete
  const handleDelete = () => {
    setAnchorEl(null);
    setLoading(true);
    deleteMutation(detail.id)
      .then((r: any) => {
        const data = r?.data?.[Object.keys(r.data)[0]];
        if (data?.success) {
          setSnackbarIsDelete(true);
        } else {
          setSnackbarIsError(true);
          setSnackbarMessage(data?.message);
          setLoading(false);
        }
      })
      .catch((err: any) => {
        if (err !== 'Cannot delete. It is in use.') logError(err);
        setSnackbarIsError(true);
        setLoading(false);
      })
      .finally(() => {
        setSnackbarOpen(true);
      });
  };

  // --------------------------------------------------------------------------------------------------------------------

  return (
    <Card
      variant="outlined"
      sx={{
        mb: 2,
        py: 1,
        px: 1.5,
        bgColor: loadingData ? 'grey.200' : 'white',
      }}
    >
      {isEditing ? (
        <DraggableDialogDetailInput
          label={inputLabel}
          detail={detail}
          setIsEditing={setIsEditing}
          updateMutation={updateMutation}
          includeColorPicker={includeColorPicker}
          includeWeight={includeWeight}
          includeCategory={includeCategory}
          disableNameInput={disableNameInput}
          italicTitle={italicTitle}
        />
      ) : (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {/* colour */}
          {!!includeColorPicker && (
            <Box sx={{ mr: 1.7 }}>
              <Box sx={{ height: 20, width: 20, bgcolor: detail.color }} />
            </Box>
          )}

          {/* title */}
          <Box
            sx={{
              flexGrow: 1,
            }}
          >
            {typeof detail.value !== 'string' ? (
              detail.value
            ) : (
              <Typography
                sx={{
                  color: loading ? 'text.secondary' : 'text.primary',
                  fontStyle: italicTitle ? 'italic' : undefined,
                }}
                component="div"
              >
                {detail.value}

                {detail.chip}

                {/* category */}
                {includeCategory && (
                  <Typography
                    variant="caption"
                    sx={{
                      color: 'text.secondary',
                      fontStyle: detail.category ? undefined : 'italic',
                    }}
                  >
                    {' '}
                    {!!detail.category && <b>{t('Category')}: </b>}
                    {detail.category || <i>{t('No Category Set')}</i>}
                  </Typography>
                )}
              </Typography>
            )}
          </Box>

          {/* weight */}
          {includeWeight && (
            <Box
              sx={{
                justifySelf: 'flex-end',
              }}
            >
              <Tooltip title={t('Weight')} arrow>
                <Chip
                  sx={{
                    whiteSpace: 'nowrap',
                    mx: 1,
                    opacity: 0.5 + 0.5 * detail.weightRatio,
                  }}
                  color="primary"
                  label={detail.weight}
                />
              </Tooltip>
            </Box>
          )}
          <div>
            {loadingData ? (
              <Skeleton sx={{ height: '50px' }} variant="rectangular" />
            ) : !detail.isReadonly ? (
              <>
                <IconButton
                  size="small"
                  aria-controls="cg-menu"
                  aria-haspopup="true"
                  onClick={handleMenuBtnClick}
                  disabled={loading}
                >
                  <MoreVertIcon fontSize="inherit" />
                </IconButton>
                <Menu
                  id="cg-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={() => setAnchorEl(null)}
                >
                  <MenuItem onClick={handleEdit}>
                    <ListItemIcon>
                      <EditIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>{t('Edit')}</ListItemText>
                  </MenuItem>
                  {!disableDelete && (
                    <MenuItem onClick={handleDelete}>
                      <ListItemIcon>
                        <DeleteIcon fontSize="small" />
                      </ListItemIcon>
                      <ListItemText>{t('Delete')}</ListItemText>
                    </MenuItem>
                  )}
                </Menu>
              </>
            ) : (
              <Tooltip title={t('Read Only')} arrow>
                <Box sx={{ display: 'flex' }}>
                  <InfoOutlinedIcon fontSize="small" color="disabled" />
                </Box>
              </Tooltip>
            )}
          </div>
        </Box>
      )}
    </Card>
  );
}
