import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, Link as RouterLink } from 'react-router-dom';

import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';

import { ReportsContext } from '../_lib/context/reports-context';

import { BreadcrumbLayout } from './_layout';

export function ReportsBreadcrumb() {
  const { t } = useTranslation();
  const location = useLocation();

  const { report, setReport } = React.useContext(ReportsContext);

  const [reportId, setReportId] = React.useState('');
  const [reportTitle, setReportTitle] = React.useState('');

  // ----------------------------------------------------------------------------------------------------------
  // ----------------------------------------------------------------------------------------------------------
  // effects
  // ----------------------------------------------------------------------------------------------------------

  React.useEffect(() => {
    const updatedReportId = location.pathname.split('/')[2];
    setReportId(updatedReportId);

    if (location.pathname === t('/reports')) {
      setReportTitle('');
      setReport({});
    }
  }, [location, t, setReport]);

  React.useEffect(() => {
    if (reportId !== t('new')) {
      setReportTitle(report ? report.title : '');
    } else {
      setReportTitle(`${t('New Report')}`);
    }
  }, [reportId, setReportTitle, report, t]);

  // ----------------------------------------------------------------------------------------------------------

  return (
    <BreadcrumbLayout>
      <Link
        component={RouterLink}
        to="/"
        color="inherit"
        underline="hover"
        sx={{ display: 'flex', alignItems: 'center', columnGap: 1 }}
      >
        <HomeRoundedIcon fontSize="inherit" />
        <Typography sx={{ display: { xs: 'none', sm: 'initial' } }}>
          {t('Home')}
        </Typography>
      </Link>
      <Link
        component={RouterLink}
        to={t('/reports')}
        color={location.pathname === t('/reports') ? 'text.primary' : 'inherit'}
        underline="hover"
      >
        {t('Reports')}
      </Link>
      {reportTitle && (
        <Typography color="text.primary">{reportTitle}</Typography>
      )}
    </BreadcrumbLayout>
  );
}
