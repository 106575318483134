import React from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorBoundary } from 'react-error-boundary';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

import { ReactErrorComponent } from '../_lib/react-error';
import { useDashboardContext } from '../_lib/context/dashboard-context';
import { useLocalStorage } from '../_utils/hooks/use-local-storage';

import { AreasFilter } from '../components/areas-filter';
import { AreaSummaryCard } from '../components/area-summary/card';

import { DashboardSummary } from './dashboard-summary';
import { HomepageMapView } from './map-view';

export function Homepage({ priorityAreas }: any) {
  const { t } = useTranslation();
  const { dashboard, navbarFilters } = useDashboardContext();

  const areasFilterLoc = 'homepage';

  const [searchValue, setSearchValue] = useLocalStorage(
    `areas-search-filter-${areasFilterLoc}`,
    ''
  );
  const [filterCharts, setFilterCharts] = useLocalStorage(
    `filter-charts-${areasFilterLoc}`,
    ['unstarted']
  );

  // ------------------------------------------------------------------------------------------------
  // ------------------------------------------------------------------------------------------------
  // handlers
  // ------------------------------------------------------------------------------------------------

  // ------------------------------------------------------------------------------------------------
  // ------------------------------------------------------------------------------------------------
  // effects
  // ------------------------------------------------------------------------------------------------

  const isFiltered = React.useMemo(
    () =>
      !!navbarFilters.programmeIds?.length ||
      !!navbarFilters.statusIds?.length ||
      !!navbarFilters.financiersIds?.length ||
      !!navbarFilters.stakeholdersIds?.length ||
      !!navbarFilters.regionsIds?.length ||
      !!navbarFilters.classificationsIds?.length ||
      !!navbarFilters.areaFieldOptionsIds?.length,
    [navbarFilters]
  );

  const filteredPriorityAreas = React.useMemo(
    () =>
      priorityAreas?.filter((p: any) => {
        // filter by search
        if (searchValue) {
          const search = searchValue.toLowerCase();
          const searchString = `${p.reference} - ${p.name}`.toLowerCase();
          if (!searchString.includes(search)) return false;
        }

        return true;
      }),
    [priorityAreas, searchValue]
  );

  // ------------------------------------------------------------------------------------------------

  return (
    <div id="priority-areas-overview">
      {/* if filtered, don't show goal */}
      {!isFiltered && (
        <Box sx={{ my: 5, mx: 'auto', maxWidth: '900px' }}>
          <Typography
            variant="h5"
            align="center"
            sx={{
              color: 'grey.800',
            }}
          >
            {dashboard.goal}
          </Typography>
        </Box>
      )}

      {/* summary */}
      {dashboard.enableHomeOverview && (
        <ErrorBoundary FallbackComponent={ReactErrorComponent}>
          <DashboardSummary />
        </ErrorBoundary>
      )}

      {/* map view */}
      {dashboard.enableMapView && dashboard.customMapviewMap && (
        <ErrorBoundary FallbackComponent={ReactErrorComponent}>
          <HomepageMapView />
        </ErrorBoundary>
      )}

      {/* priority areas quick filter */}
      <AreasFilter
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        filterCharts={filterCharts}
        setFilterCharts={setFilterCharts}
      />

      {/* priority area cards */}
      <Grid
        container
        spacing={2.5}
        justifyContent="center"
        sx={{ mt: 3, mb: 7 }}
        id="pa-cards"
      >
        {/* all priority areas */}
        {filteredPriorityAreas.map((p: any) => (
          <Grid key={p.id} item xs={12} sm={6} md={5} lg={3} xl={2}>
            <ErrorBoundary FallbackComponent={ReactErrorComponent}>
              <AreaSummaryCard area={p} filterCharts={filterCharts} />
            </ErrorBoundary>
          </Grid>
        ))}

        {/* nothing to show */}
        {filteredPriorityAreas?.length === 0 && (
          <Grid
            item
            xs={4}
            sx={{ textAlign: 'center', color: 'text.secondary' }}
          >
            <Typography
              variant="h5"
              sx={{ fontStyle: 'italic', fontWeight: 400 }}
            >
              {t('Nothing found')}
            </Typography>
          </Grid>
        )}
      </Grid>
    </div>
  );
}
