import React from 'react';

import CircularProgress from '@mui/material/CircularProgress';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import ErrorIcon from '@mui/icons-material/Error';
import BlockIcon from '@mui/icons-material/Block';
import HourglassBottom from '@mui/icons-material/HourglassBottom';

export const DEFAULT_SUMMARY_ITEMS_ORDER = [
  'Financials',
  'Targets',
  'Activities Chart',
];

export const EMOJI_DELIMITER = ' ✨🐯🍒 ';

export const IMPORT_EXPORT_STATUS: any = {
  DRAFT: {
    label: 'Draft',
    icon: undefined,
  },
  PENDING: {
    label: 'Pending',
    icon: React.createElement(CircularProgress, { size: 15 }),
  },
  PROCESSING: {
    label: 'Processing',
    icon: React.createElement(CircularProgress, { size: 15 }),
  },
  AWAITING_CONFIRMATION: {
    label: 'Awaiting Confirmation',
    icon: React.createElement(HourglassBottom, { fontSize: 'small' }),
  },
  SUCCESS: {
    label: 'Success',
    icon: React.createElement(CheckCircleIcon, { fontSize: 'small' }),
  },
  FAILED: {
    label: 'Failed',
    icon: React.createElement(ErrorIcon, { fontSize: 'small' }),
  },
  CANCELLED: {
    label: 'Cancelled',
    icon: React.createElement(CancelIcon, { fontSize: 'small' }),
  },
  DELETED: {
    label: 'Deleted',
    icon: React.createElement(BlockIcon, { fontSize: 'small' }),
  },
  UNKNOWN: {
    label: 'Unknown',
    icon: undefined,
  },
};
