import React from 'react';
import { useTranslation } from 'react-i18next';

import { useDashboardContext } from '../../_lib/context/dashboard-context';

import { AppBarFilter } from '../appbar/filter';
import { AreasFilterLoader } from './_loader';

export function NavbarFilter({ isDark }: any) {
  const { t } = useTranslation();
  const { navbarFilters, setNavbarFilters, user } = useDashboardContext();

  const [loading, setLoading] = React.useState(false);
  const [filterOptions, setFilterOptions] = React.useState<any>(null);
  const [selectedOptions, setSelectedOptions] = React.useState<any>([]);

  // --------------------------------------------------------------------------------------------------
  // --------------------------------------------------------------------------------------------------
  // handlers
  // --------------------------------------------------------------------------------------------------

  const handleFilter = (event: any, filters: any) => {
    const newNavbarFilters = {
      programmeIds: undefined,
      statusIds: undefined,
      financiersIds: undefined,
      stakeholdersIds: undefined,
      regionsIds: undefined,
      classificationsIds: undefined,
      areaFieldOptionsIds: undefined,
    } as any;

    filters.forEach((filter: any) => {
      if (!newNavbarFilters[filter.type]) {
        newNavbarFilters[filter.type] = [];
      }
      newNavbarFilters[filter.type].push(filter.id);
    });

    setNavbarFilters((prev: any) => ({
      ...prev,
      ...newNavbarFilters,
    }));
  };

  // --------------------------------------------------------------------------------------------------
  // --------------------------------------------------------------------------------------------------
  // effects
  // --------------------------------------------------------------------------------------------------

  // set selected options
  React.useEffect(() => {
    if (filterOptions) {
      const newSelectedOptions = filterOptions.filter((option: any) => {
        if (navbarFilters[option.type]) {
          return navbarFilters[option.type].includes(option.id);
        }
        return false;
      });

      setSelectedOptions(newSelectedOptions);
    }
  }, [filterOptions, navbarFilters]);

  // check if navbar filters exist in filter options
  React.useEffect(() => {
    if (filterOptions) {
      setNavbarFilters((prev: any) => {
        const newNavbarFilters = {
          programmeIds: undefined,
          statusIds: undefined,
          financiersIds: undefined,
          stakeholdersIds: undefined,
          regionsIds: undefined,
          classificationsIds: undefined,
          areaFieldOptionsIds: undefined,
        } as any;

        Object.keys(prev).forEach((key) => {
          if (prev[key]) {
            prev[key].forEach((id: any) => {
              if (
                filterOptions.find(
                  (option: any) => option.id === id && option.type === key
                )
              ) {
                if (!prev[key]) {
                  prev[key] = [];
                }
                prev[key].push(id);
              }
            });
          }
        });

        return { ...prev, ...newNavbarFilters };
      });
    }
  }, [filterOptions, setNavbarFilters]);

  // --------------------------------------------------------------------------------------------------

  if (user.isRegular) return null;

  return (
    <>
      <AppBarFilter
        label={`${t('Filter')}`}
        options={filterOptions}
        selectedOptions={selectedOptions}
        onChange={handleFilter}
        isDark={isDark}
        loading={loading}
      />
      {/* data load */}
      <AreasFilterLoader
        setFilterOptions={setFilterOptions}
        setLoading={setLoading}
      />
    </>
  );
}
