import React from 'react';

import { DashboardContext } from '../_lib/context/dashboard-context';
import { replaceSpacesOfIntlNumberFormat } from '../_utils/general-utils';

import { useCurrency } from '../_utils/hooks/use-currency';

import currencyListJson from '../_lib/data/currency-list.json';

export function CurrencyText({
  amount,
  decimals,
  dashboard: pDashboard,
  currency: pCurrency,
}: any) {
  const { dashboard: cDashboard } = React.useContext(DashboardContext);

  const dashboard = pDashboard || cDashboard;
  const currency = pCurrency || dashboard.currency;

  const currencyList = React.useMemo(() => currencyListJson, []);

  const currencyFull = currencyList.find(
    (c: any) => `${c.entity} :: ${c.alpha_code}` === currency
  );

  const amountParsed = new Intl.NumberFormat(dashboard.language, {
    style: 'currency',
    currency: currencyFull?.alpha_code || 'USD',
    minimumFractionDigits: decimals || 0,
  }).format(amount);

  const amountComponents = replaceSpacesOfIntlNumberFormat(amountParsed);

  return (
    <>
      {amountComponents.map((c: any, i: number) => (
        // eslint-disable-next-line react/no-array-index-key
        <React.Fragment key={i}>{c}</React.Fragment>
      ))}
    </>
  );
}

export function CurrencySymbol({ currency }: any = null) {
  const { symbol } = useCurrency(currency);

  return <span>{symbol}</span>;
}
