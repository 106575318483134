import React from 'react';
import { useTranslation } from 'react-i18next';

import { ChromePicker } from 'react-color';

import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Popover from '@mui/material/Popover';

export function SettingsColorOptionRow({
  option,
  optionKey,
  options,
  setOptions,
}: any) {
  const { t } = useTranslation();

  const [status, setStatus] = React.useState(option);
  const [colorPickerType, setColorPickerType] = React.useState('');

  // ----------------------------------------------------------------------------------
  // popover
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const handleColorClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    type: string
  ) => {
    setColorPickerType(type);
    setAnchorEl(event.currentTarget);
  };
  const handleColorClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? 'color-picker-popover' : undefined;

  // ----------------------------------------------------------------------------------
  // ----------------------------------------------------------------------------------
  // handlers
  // ----------------------------------------------------------------------------------

  // color picker
  const handleColorPicker = (c: any) => {
    if (colorPickerType === 'textColor') {
      setStatus({ ...status, textColor: c.hex });
      setOptions({
        ...options,
        ...{ [optionKey]: { ...status, textColor: c.hex } },
      });
    }
    if (colorPickerType === 'bgcolor') {
      setStatus({ ...status, bgcolor: c.hex });
      setOptions({
        ...options,
        ...{ [optionKey]: { ...status, bgcolor: c.hex } },
      });
    }
  };

  // ----------------------------------------------------------------------------------

  return (
    <>
      <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
        <TableCell sx={{ bgcolor: option.bgcolor, color: option.textColor }}>
          {t(status.text)}
        </TableCell>
        <TableCell>
          <Button
            aria-describedby={id}
            onClick={(e) => handleColorClick(e, 'textColor')}
            fullWidth
            sx={{ minWidth: '100px' }}
            variant="outlined"
            startIcon={
              <Box sx={{ p: 0.5, border: 1, borderColor: 'grey.500' }}>
                <Box
                  sx={{
                    bgcolor: status.textColor,
                    width: '10px',
                    height: '10px',
                  }}
                />
              </Box>
            }
          >
            {status.textColor}
          </Button>
        </TableCell>
        <TableCell>
          <Button
            aria-describedby={id}
            onClick={(e) => handleColorClick(e, 'bgcolor')}
            fullWidth
            sx={{ minWidth: '100px' }}
            variant="outlined"
            startIcon={
              <Box sx={{ p: 0.5, border: 1, borderColor: 'grey.500' }}>
                <Box
                  sx={{
                    bgcolor: status.bgcolor,
                    width: '10px',
                    height: '10px',
                  }}
                />
              </Box>
            }
          >
            {status.bgcolor}
          </Button>
        </TableCell>
      </TableRow>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleColorClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <ChromePicker
          color={
            colorPickerType === 'textColor' ? status.textColor : status.bgcolor
          }
          onChangeComplete={handleColorPicker}
        />
      </Popover>
    </>
  );
}
