import React from 'react';
import { isEqual } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';

import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

import { DashboardContext } from '../../_lib/context/dashboard-context';
import { logError } from '../../_lib/error';

import { CONTENT_GROUPS_QUERY } from '../../_lib/graphql/queries';
import { CONTENT_GROUPS_SUBSCRIPTION } from '../../_lib/graphql/subscriptions';

import { ContentGroupsManageSmall } from '../../planning/settings/advanced/content-groups-manage';
import { SubscribeToMore } from '../../components/subscribe-to-more';

export function ContentGroupSelect({ content, setContent, disabled }: any) {
  const { t } = useTranslation();

  const { user } = React.useContext(DashboardContext);

  const [groupSelected, setGroupSelected] = React.useState<any>(null);

  const { loading, data, error, subscribeToMore } =
    useQuery(CONTENT_GROUPS_QUERY);

  // ----------------------------------------------------------------------------------------------------
  // ----------------------------------------------------------------------------------------------------
  // effects
  // ----------------------------------------------------------------------------------------------------

  React.useEffect(() => {
    if (data && content.group) {
      const selected = data.contentGroups.find(
        (g: any) => g.id === content.group.id
      );
      if (!isEqual(selected, groupSelected)) {
        setGroupSelected(selected);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, content]);

  React.useEffect(() => {
    if (error) {
      logError(error);
    }
  }, [error]);

  // ----------------------------------------------------------------------------------------------------

  return (
    <>
      {user.isAdmin && (
        <div style={{ marginBottom: '8px', textAlign: 'right' }}>
          <ContentGroupsManageSmall disabled={disabled} />
        </div>
      )}
      <Autocomplete
        id="select-activity-group"
        value={groupSelected || null}
        options={data?.contentGroups || []}
        getOptionLabel={(option: any) => option.title}
        onChange={(event: any, group: any) => {
          setContent(
            structuredClone({
              ...content,
              group,
              unGroup: !group?.id,
            })
          );
        }}
        disabled={disabled || loading}
        renderInput={(params) => (
          <TextField
            {...params}
            label={t('Phase')}
            placeholder={`${t('Select a phase')}...`}
          />
        )}
        fullWidth
        size="small"
      />

      {/* subscription */}
      {!!data && (
        <SubscribeToMore
          subscribeToMore={() =>
            subscribeToMore({
              document: CONTENT_GROUPS_SUBSCRIPTION,
              updateQuery: (prev, { subscriptionData }) => {
                if (!subscriptionData.data) return prev;
                return subscriptionData.data;
              },
            })
          }
        />
      )}
    </>
  );
}
