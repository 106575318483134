import React from 'react';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';

import * as serviceWorkerRegistration from '../../_lib/offline/serviceWorkerRegistration';
import { useOnlineStatus } from '../../_lib/offline/use-online-status';

import { DialogTitle } from '../../DialogTitle/dialog-title';

function ScreenInfoUpdateLayout({ title, description, actions }: any) {
  const { t } = useTranslation();
  const [openDialog, setOpenDialog] = React.useState(true);

  const isOnline = useOnlineStatus();

  return (
    <Dialog open={openDialog} maxWidth="xs" fullWidth>
      <DialogTitle onClose={() => setOpenDialog(false)} variant="h6">
        <Box component="span" sx={{ mr: 1.5 }}>
          🚀
        </Box>{' '}
        {title || t('Update Available')}
      </DialogTitle>

      <DialogContent dividers>
        {description ||
          t(
            'There is an update available. Please reload the page to get the latest update. If this does not work, please clear your cache and try again.'
          )}
      </DialogContent>

      <DialogActions disableSpacing sx={{ p: 3, display: 'block' }}>
        {actions || (
          <Stack spacing={1.5}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={() => {
                if (isOnline) {
                  window.localStorage.clear();
                  serviceWorkerRegistration.unregister().then(() => {
                    window.location.reload();
                  });
                } else {
                  window.location.reload();
                }
              }}
              disableElevation
            >
              {t('Reload Page')}
            </Button>
            <Button
              variant="contained"
              color="info"
              fullWidth
              onClick={() => {
                window.location.href = '/';
                if (isOnline) {
                  window.localStorage.clear();
                  serviceWorkerRegistration.unregister().then(() => {
                    window.location.reload();
                  });
                }
              }}
              disableElevation
            >
              {t('Go to Homepage')}
            </Button>
            <Typography variant="body2" align="center">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://tawk.to/chat/614ee0ad25797d7a8900c198/1fge1rail"
              >
                {t('Send us a message')}
              </a>
            </Typography>
          </Stack>
        )}
      </DialogActions>
    </Dialog>
  );
}

export function ScreenInfoUpdateAvailable() {
  return <ScreenInfoUpdateLayout />;
}

export function ScreenInfoUpdateInProgress() {
  const { t } = useTranslation();

  return (
    <ScreenInfoUpdateLayout
      title={t('Update in Progress')}
      description={
        <Box sx={{ p: 2, textAlign: 'center' }}>
          <CircularProgress />
          <Typography sx={{ mt: 2 }}>
            {t('Please wait while we update your app.')}
          </Typography>
        </Box>
      }
    />
  );
}
