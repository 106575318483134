import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';

import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LaunchOutlinedIcon from '@mui/icons-material/LaunchOutlined';
import EditIcon from '@mui/icons-material/Edit';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';

import { useDashboardContext } from '../../_lib/context/dashboard-context';

import { PlanningAreaActionsMove } from './move';
import { PlanningAreaActionsDelete } from './delete';

export function PlanningAreaActionsHeader({
  area,
  openLink,
  toggleCollapse,
  disabled,
  setDisabled,
  openCollapse,
  setOpenCollapse,
  setOpenPermissionsDialog,
  listVariables,
}: any) {
  const { t } = useTranslation();

  const { dashboard } = useDashboardContext();

  return (
    <Stack spacing={1} direction="row">
      {!openCollapse.includes(area.id) && disabled && (
        <Stack spacing={0.5} direction={{ xs: 'column', md: 'row' }}>
          {/* open */}
          {!dashboard.inPlanning && (
            <Tooltip title={`${t('Open')}`}>
              <IconButton component={RouterLink} to={openLink} target="_blank">
                <LaunchOutlinedIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          )}
          {/* move */}
          {((dashboard.enableAreaLevels &&
            area.__typename !== 'InterventionType') ||
            area.__typename === 'InterventionType') &&
            !area.archivedAt && (
              <PlanningAreaActionsMove
                child={area}
                parent={
                  area.__typename === 'InterventionType'
                    ? area.priorityArea
                    : area.parent
                }
                listVariables={listVariables}
              />
            )}
          {/* edit */}
          {!area.archivedAt && (
            <Tooltip title={`${t('Edit')}`}>
              <IconButton
                onClick={() => {
                  setDisabled(false);
                  setOpenCollapse((prev: any) => [...prev, area.id]);
                }}
              >
                <EditIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          )}
          {/* add person */}
          {dashboard.enableAreaPermissions && !area.archivedAt && (
            <Tooltip title={`${t('Share')}`}>
              <IconButton onClick={() => setOpenPermissionsDialog(true)}>
                <PersonAddAlt1Icon fontSize="small" />
              </IconButton>
            </Tooltip>
          )}

          {/* archive */}
          {dashboard.enableAreaArchive && (
            <PlanningAreaActionsDelete
              area={area}
              isArchive
              listVariables={listVariables}
            />
          )}

          {/* delete */}
          <PlanningAreaActionsDelete
            area={area}
            listVariables={listVariables}
          />
        </Stack>
      )}

      {/* expand */}
      {disabled && (
        <Tooltip title={`${t('Expand')}`}>
          <IconButton onClick={() => toggleCollapse(area.id)}>
            <ExpandMoreIcon
              sx={{
                transform: openCollapse.includes(area.id)
                  ? 'rotate(0deg)'
                  : 'rotate(-90deg)',
                transition: 'transform 0.2s ease-in-out',
              }}
            />
          </IconButton>
        </Tooltip>
      )}
    </Stack>
  );
}
