import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Paper from '@mui/material/Paper';
import PaletteIcon from '@mui/icons-material/Palette';
import LoadingButton from '@mui/lab/LoadingButton';

import { DialogTitle } from '../../../../DialogTitle/dialog-title';
import { useDashboardContext } from '../../../../_lib/context/dashboard-context';
import { MapColorsRow } from './map-colors-row';
import { logError } from '../../../../_lib/error';
import { useDashboardUpdate } from '../../../../_utils/hooks/use-dashboard-update';

export function MapColorsDialog({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: (t: boolean) => void;
}) {
  const { dashboard, setSnackbarOpen, setSnackbarIsError } =
    useDashboardContext();
  const { t } = useTranslation();
  const { dashboardUpdateMutation, loading } = useDashboardUpdate();
  const { customMapPalette } = dashboard;
  const [options, setOptions] = React.useState<any>(
    customMapPalette?.bgColor
      ? customMapPalette
      : {
          bgColor: dashboard.colorPalette?.primary?.bgcolor,
          borderColor: dashboard.colorPalette?.secondary?.bgcolor,
        }
  );

  const handleSave = () => {
    dashboardUpdateMutation({
      variables: {
        customMapPalette: JSON.stringify(options),
      },
    })
      .catch((err) => {
        logError(err);
        setSnackbarIsError(true);
      })
      .finally(() => {
        setSnackbarOpen(true);
      });
  };

  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <Dialog open={open} maxWidth="sm" fullWidth>
      <DialogTitle onClose={() => setOpen(false)}>
        <PaletteIcon sx={{ mr: 2 }} />
        {t('Custom Map Colours')}
      </DialogTitle>
      <DialogContent dividers>
        <TableContainer component={Paper} variant="outlined">
          <Table
            sx={{
              minWidth: '100%',
              '& .MuiTableCell-root': {
                whiteSpace: 'nowrap',
                textAlign: 'center',
              },
            }}
            aria-label="map colors table"
            size="small"
          >
            <TableHead>
              <TableRow>
                <TableCell>{t('Map Backgound Colour')}</TableCell>
                <TableCell>{t('Map Border Colour')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                {Object.keys(options).map((statusKey: string) => (
                  <MapColorsRow
                    key={statusKey}
                    setOptions={setOptions}
                    color={options[statusKey]}
                    colorPickerType={statusKey}
                  />
                ))}
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions sx={{ p: 2 }}>
        <Button onClick={() => handleCancel()} disabled={loading}>
          {t('Cancel')}
        </Button>
        <LoadingButton
          variant="contained"
          onClick={() => handleSave()}
          loading={loading}
        >
          {t('Save')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
