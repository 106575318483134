import React from 'react';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';

import { useDashboardContext } from '../../_lib/context/dashboard-context';
import { useOnlineStatus } from '../../_lib/offline/use-online-status';
import { useAreas } from '../../_utils/hooks/queries/use-areas';

export function DashboardSummaryFilter({
  selectedPriorityAreaIds,
  setSelectedPriorityAreaIds,
  selectedInterventionIds,
  setSelectedInterventionIds,
  selectedInterventionNames,
  setSelectedInterventionNames,
}: any) {
  const { t } = useTranslation();

  const isOnline = useOnlineStatus();

  const { dashboard, navbarFilterVariables } = useDashboardContext();

  const areaLevelNames: string = React.useMemo(
    () =>
      dashboard.areaLevels
        ? dashboard.areaLevels
            .map((areaLevel: any) => areaLevel.name)
            .join(', ')
        : '',
    [dashboard.areaLevels]
  );

  const {
    data: dataPriorityAreas,
    loading: loadingPriorityAreas,
    error: errorPriorityAreas,
  } = useAreas('priorityArea', {
    ...navbarFilterVariables,
    filterAll: true,
    paginationPriorityAreasLimit: -1,
  });
  const {
    data: dataInterventions,
    loading: loadingInterventions,
    error: errorInterventions,
  } = useAreas('intervention', {
    ...navbarFilterVariables,
    paginationInterventionsLimit: -1,
  });

  const loading = React.useMemo(
    () => loadingPriorityAreas || loadingInterventions,
    [loadingPriorityAreas, loadingInterventions]
  );
  const error = React.useMemo(
    () => errorPriorityAreas || errorInterventions,
    [errorPriorityAreas, errorInterventions]
  );

  // ---------------------------------------------------------------------------------------------------------
  // ---------------------------------------------------------------------------------------------------------
  // efffects
  // ---------------------------------------------------------------------------------------------------------

  const interventionOptions = React.useMemo(() => {
    if (selectedPriorityAreaIds?.length) {
      return (
        dataInterventions?.interventions?.items?.filter((intervention: any) =>
          selectedPriorityAreaIds.includes(intervention.priorityArea.id)
        ) || []
      );
    }
    let options: any = dataInterventions?.interventions?.items || [];
    if (dashboard.homeOverviewInterventionsCountDistinct) {
      options = options.reduce((acc: any, cur: any) => {
        if (acc.find((o: any) => o.name === cur.name)) return acc;
        return [...acc, cur];
      }, []);
    }
    return options;
  }, [
    selectedPriorityAreaIds,
    dataInterventions,
    dashboard.homeOverviewInterventionsCountDistinct,
  ]);

  if (error) return null;

  // ---------------------------------------------------------------------------------------------------------

  return (
    <Box sx={{ mt: -2, mb: 2 }}>
      <Stack
        spacing={2}
        direction={{ xs: 'column', sm: 'row' }}
        alignItems="center"
      >
        {/* priority areas */}
        <Box>
          <Tooltip
            placement="top-end"
            title={
              !selectedInterventionIds?.length
                ? ''
                : (t('Clear sub-area filter to enable') as string)
            }
          >
            <Autocomplete
              id="dashboard-overview-filter-pa"
              options={dataPriorityAreas?.priorityAreas?.items || []}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={
                    !dashboard.enableAreaLevels
                      ? dashboard.priorityAreaName
                      : areaLevelNames
                  }
                />
              )}
              getOptionLabel={(option) =>
                `${option.reference} - ${option.name}`
              }
              fullWidth
              sx={{ width: 300, maxWidth: '100%' }}
              size="small"
              multiple
              value={
                dataPriorityAreas?.priorityAreas?.items.filter((pa: any) =>
                  selectedPriorityAreaIds?.includes(pa.id)
                ) ?? []
              }
              disabled={loading || !isOnline}
              onChange={(event, newValue) => {
                setSelectedPriorityAreaIds(newValue?.map((pa: any) => pa.id));
                setSelectedInterventionIds(null);
                setSelectedInterventionNames(null);
              }}
              clearText={`${t('Clear')}`}
              noOptionsText={t('No options')}
            />
          </Tooltip>
        </Box>

        {/* interventions */}
        <Box>
          <Autocomplete
            disablePortal
            id="dashboard-overview-filter-intv"
            options={interventionOptions ?? []}
            multiple
            renderInput={(params) => (
              <TextField {...params} label={dashboard.interventionName} />
            )}
            isOptionEqualToValue={(option, value) => {
              if (
                !dashboard.homeOverviewInterventionsCountDistinct &&
                option === value
              )
                return true;
              if (
                dashboard.homeOverviewInterventionsCountDistinct &&
                option.name === value.name
              )
                return true;
              return false;
            }}
            getOptionLabel={(option) =>
              `${
                !dashboard.homeOverviewInterventionsCountDistinct
                  ? `${option.reference} - `
                  : ''
              }${option.name}`
            }
            fullWidth
            sx={{ width: 300, maxWidth: '100%' }}
            size="small"
            value={
              !dashboard.homeOverviewInterventionsCountDistinct
                ? interventionOptions?.filter((intv: any) =>
                    selectedInterventionIds?.includes(intv.id)
                  ) || []
                : selectedInterventionNames?.length
                ? interventionOptions?.filter((intv: any) =>
                    selectedInterventionNames?.includes(intv.name)
                  ) || []
                : []
            }
            onChange={(event, newValue) => {
              if (!dashboard.homeOverviewInterventionsCountDistinct) {
                setSelectedInterventionIds(
                  newValue?.map((intv: any) => intv.id)
                );
                setSelectedInterventionNames(null);
              } else {
                setSelectedInterventionIds(null);
                setSelectedInterventionNames(
                  newValue?.map((intv: any) => intv.name)
                );
                const ids =
                  dataInterventions?.interventions?.items
                    ?.filter(
                      (intv: any) =>
                        (selectedPriorityAreaIds?.length
                          ? selectedPriorityAreaIds?.includes(
                              intv.priorityArea.id
                            )
                          : true) &&
                        newValue
                          ?.map((intv: any) => intv.name)
                          .includes(intv.name)
                    )
                    .map((intv: any) => intv.id) || null;
                setSelectedInterventionIds(ids?.length ? ids : null);
              }
            }}
            clearText={`${t('Clear')}`}
            disabled={loading || !isOnline}
            noOptionsText={t('No options')}
          />
        </Box>
      </Stack>
    </Box>
  );
}
