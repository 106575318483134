import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import Container from '@mui/material/Container';
import Skeleton from '@mui/material/Skeleton';

import { ReactError } from '../_lib/react-error';

import { INTERVENTION_QUERY } from '../_lib/graphql/queries';
import { INTERVENTION_SUBSCRIPTION } from '../_lib/graphql/subscriptions';

import { Intervention } from '.';
import { LayoutDashboard } from '../_layout/dashboard';
import { SubscribeToMoreComponent } from '../components/subscribe-to-more';

export function InterventionLoader() {
  const { interventionId } = useParams<any>();

  const { loading, error, data, refetch, subscribeToMore } = useQuery(
    INTERVENTION_QUERY,
    {
      variables: { interventionId },
    }
  );

  // ----------------------------------------------------------------------------------------------------------------

  // error
  if (error) return <ReactError error={error} />;

  // loading
  if (loading || !data) {
    if (!data && !loading) refetch();

    return (
      <Container sx={{ my: 3 }}>
        <Skeleton variant="rectangular" height={500} />
      </Container>
    );
  }

  // ----------------------------------------------------------------------------------------------------------------

  // content
  return (
    <LayoutDashboard>
      <Intervention intervention={data?.intervention} />

      {/* subscription */}
      <SubscribeToMoreComponent
        subscribeToMore={subscribeToMore}
        document={INTERVENTION_SUBSCRIPTION}
        variables={{ interventionId }}
      />
    </LayoutDashboard>
  );
}
