import { MY_CONTENT_QUERY } from '../../queries';
import { usePrefetchQuery } from '../use-prefetch-query';

export function GraphqlPrefetchQueriesMyContent() {
  const { data } = usePrefetchQuery({
    query: MY_CONTENT_QUERY,
  });

  return (
    <>
      {/* todo: flagged + viewed + access */}
      {/* todo: ? */}
    </>
  );
}
