import React from 'react';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { DashboardContext } from '../../../_lib/context/dashboard-context';
import { PlanningFieldsInputCustomFieldsSelect } from './select';

export function PlanningFieldsInputCustomFields({
  area,
  setArea,
  disabled,
}: any) {
  const { t } = useTranslation();
  const { dashboard } = React.useContext(DashboardContext);

  const areaFieldConfigKeys = React.useMemo(
    () => Array.from({ length: 10 }, (_, i) => `areaField${i + 1}Config`),
    []
  );

  // -------------------------------------------------------------------------------------------------------

  if (
    !areaFieldConfigKeys.some(
      (key: any) =>
        dashboard[key] &&
        ((area?.__typename === 'PriorityAreaType' &&
          dashboard[key].inPriorityArea) ||
          (area?.__typename === 'InterventionType' &&
            dashboard[key].inIntervention))
    )
  )
    return null;

  // -------------------------------------------------------------------------------------------------------

  return (
    <Box>
      <Typography
        variant="body1"
        sx={{ fontWeight: 500, mb: 1 }}
        color={disabled ? 'textSecondary' : 'textPrimary'}
      >
        {t('Additional Fields')}
      </Typography>

      {/* area fields */}
      <Grid container spacing={2}>
        {areaFieldConfigKeys
          .filter(
            (key: any) =>
              dashboard[key] &&
              ((area?.__typename === 'PriorityAreaType' &&
                dashboard[key].inPriorityArea) ||
                (area?.__typename === 'InterventionType' &&
                  dashboard[key].inIntervention))
          )
          ?.map((key: any) => {
            const config = dashboard[key];

            const value = area?.areaFieldValues?.find(
              (field: any) => field.config.id === config.id
            ) || {
              config: { ...config },
            };

            return (
              <Grid key={key} item xs={12} sm={6} md={4}>
                {/* free text */}
                {config.fieldType === 'TEXT' && (
                  <TextField
                    label={config.label}
                    size="small"
                    fullWidth
                    disabled={disabled}
                    value={value?.value || ''}
                    onChange={(e) => {
                      const areaFieldValues =
                        area?.areaFieldValues?.filter(
                          (field: any) => field.config.id !== config.id
                        ) || [];
                      areaFieldValues.push({
                        ...value,
                        value: e.target.value,
                      });
                      setArea({
                        ...area,
                        areaFieldValues: [...areaFieldValues],
                      });
                    }}
                  />
                )}

                {/* number */}
                {config.fieldType === 'NUMBER' && (
                  <TextField
                    label={config.label}
                    size="small"
                    fullWidth
                    disabled={disabled}
                    InputProps={{
                      inputProps: {
                        inputMode: 'numeric',
                        pattern: '[0-9]*',
                        style: { textAlign: 'right' },
                      },
                    }}
                    value={value?.number || ''}
                    onChange={(e) => {
                      const areaFieldValues =
                        area?.areaFieldValues?.filter(
                          (field: any) => field.config.id !== config.id
                        ) || [];
                      areaFieldValues.push({
                        ...value,
                        number: e.target.value,
                      });
                      setArea({
                        ...area,
                        areaFieldValues: [...areaFieldValues],
                      });
                    }}
                  />
                )}

                {/* date */}
                {config.fieldType === 'DATE' && (
                  <DatePicker
                    label={config.label}
                    value={value?.date ? new Date(value.date) : null}
                    onChange={(newValue) => {
                      const areaFieldValues =
                        area?.areaFieldValues?.filter(
                          (field: any) => field.config.id !== config.id
                        ) || [];
                      areaFieldValues.push({
                        ...value,
                        date: newValue,
                      });
                      setArea({
                        ...area,
                        areaFieldValues: [...areaFieldValues],
                      });
                    }}
                    disabled={disabled}
                    slotProps={{
                      textField: {
                        size: 'small',
                      },
                    }}
                  />
                )}

                {/* select */}
                {config.fieldType === 'SELECT' && (
                  <PlanningFieldsInputCustomFieldsSelect
                    config={config}
                    disabled={disabled}
                    area={area}
                    setArea={setArea}
                    value={value}
                  />
                )}

                {/* multi select */}
                {config.fieldType === 'MULTI_SELECT' && (
                  <PlanningFieldsInputCustomFieldsSelect
                    config={config}
                    disabled={disabled}
                    area={area}
                    setArea={setArea}
                    value={value}
                    multiple
                  />
                )}
              </Grid>
            );
          })}
      </Grid>
    </Box>
  );
}
