import React from 'react';

import { useTranslation } from 'react-i18next';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import { DoughnutChart } from '../../components/doughnut-chart';
import { useSummary } from '../../_utils/hooks/queries/use-summary';

export function DashboardSummaryActivities({
  filterPriorityAreaIds,
  filterInterventionIds,
}: any) {
  const { t } = useTranslation();

  const variables = React.useMemo(
    () => ({
      filterPriorityAreaIds,
      filterInterventionIds,
    }),
    [filterPriorityAreaIds, filterInterventionIds]
  );

  const { loading, data, error } = useSummary('contentSummary', variables);

  // ----------------------------------------------------------------------------------------

  if (error) return null;

  if (loading || !data) return <Skeleton variant="rectangular" height={300} />;

  if (!data?.dashboard?.summary?.contentSummary?.total) return null;

  return (
    <Card variant="outlined">
      <CardContent>
        <Box sx={{}}>
          <Typography variant="h6" sx={{ mb: 2 }}>
            {t('Activities')}{' '}
            <Typography variant="body2" component="span">
              : {data.dashboard.summary.contentSummary.total}
            </Typography>
          </Typography>

          <Box sx={{ maxHeight: '300px' }}>
            <DoughnutChart
              id="dashboard-summary-home"
              contentSummary={data.dashboard.summary.contentSummary}
            />
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
}
