import { Box, Typography } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { TypographyTypeMap } from '@mui/material/Typography';
import { Theme, styled } from '@mui/material/styles';

export enum TabType {
  ISSUE = '#FFB020',
  OVERDUE = '#D50000',
}

export type SectionTableHeaderTabComponent<T> = OverridableComponent<
  TypographyTypeMap<T>
>;
export type SectionTableHeaderTabProps = Partial<{ tabType: TabType }>;

export const SectionTitleContainer = styled(Box)(({ theme }) => ({
  backgroundColor: '#EEEEEE',
  padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
}));

export const SectionTableHeaderContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
}));

export const SectionTableHeaderTab = styled<
  SectionTableHeaderTabComponent<SectionTableHeaderTabProps>
>(Typography, {
  shouldForwardProp: (prop) => prop !== 'tabType',
})(({ theme, tabType }: { theme: Theme } & SectionTableHeaderTabProps) => ({
  fontSize: '16px',
  backgroundColor: tabType,
  color: '#FFFFFF',
  padding: `0 ${theme.spacing(1)}`,
  borderRadius: '4px',
})) as SectionTableHeaderTabComponent<SectionTableHeaderTabProps>;

export const ExecutiveSummaryContentTableContainer = styled(Box)(() => ({
  border: '1px solid #EEEEEE',
  borderRadius: '0 0 4px 4px',
}));
