import React from 'react';
import { useTranslation } from 'react-i18next';

import TextField from '@mui/material/TextField';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import LoadingButton from '@mui/lab/LoadingButton';
import Button from '@mui/material/Button';
import DoneIcon from '@mui/icons-material/Done';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import FormHelperText from '@mui/material/FormHelperText';

import { DialogTitle } from '../DialogTitle/dialog-title';
import { useDashboardContext } from '../_lib/context/dashboard-context';
import { isValidEmail } from '../_utils/general-utils';

export function UserCreateDialog({
  newUser,
  setNewUser,
  onSubmit,
  onClose,
  loading,
  open,
}: any) {
  const { t } = useTranslation();
  const { user } = useDashboardContext();

  const [error, setError] = React.useState<any>({});

  // -----------------------------------------------------------------------------------------------------------------------
  // -----------------------------------------------------------------------------------------------------------------------
  // handlers
  // -----------------------------------------------------------------------------------------------------------------------

  const handleConfirm = () => {
    if (!newUser.name || !newUser.position || !isValidEmail(newUser.email)) {
      return setError({
        name: !newUser.name ? t('Name is required') : '',
        position: !newUser.position ? t('Position is required') : '',
        email: !isValidEmail(newUser.email)
          ? t('Please enter a valid email')
          : '',
      });
    }

    setError({});

    return onSubmit(newUser);
  };

  // -----------------------------------------------------------------------------------------------------------------------
  // -----------------------------------------------------------------------------------------------------------------------
  // effects
  // -----------------------------------------------------------------------------------------------------------------------

  // -----------------------------------------------------------------------------------------------------------------------

  return (
    <Dialog fullWidth maxWidth="xs" open={open}>
      <DialogTitle onClose={onClose}>{t('New Member')}</DialogTitle>

      <DialogContent dividers>
        <Box>
          <Stack spacing={2}>
            {/* name */}
            <TextField
              label={t('Name')}
              variant="outlined"
              size="small"
              value={newUser.name || ''}
              onChange={(event) =>
                setNewUser({
                  ...newUser,
                  name: event.target.value,
                })
              }
              fullWidth
              required
              error={!!error.name}
              helperText={error.name}
              disabled={loading}
            />

            {/* title / position */}
            <TextField
              label={t('Position')}
              variant="outlined"
              size="small"
              value={newUser.position || ''}
              onChange={(event) =>
                setNewUser({
                  ...newUser,
                  position: event.target.value,
                })
              }
              fullWidth
              required
              error={!!error.position}
              helperText={error.position}
              disabled={loading}
            />

            {/* email */}
            <TextField
              label={t('Email')}
              variant="outlined"
              size="small"
              value={newUser.email || ''}
              onChange={(event) =>
                setNewUser({
                  ...newUser,
                  email: event.target.value,
                })
              }
              inputProps={{
                type: 'email',
              }}
              fullWidth
              required
              disabled={loading}
              error={!!error.email}
              helperText={error.email}
            />

            {/* is admin ? */}
            {!!user.isAdmin && (
              <>
                {/* set admin */}
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={newUser.isAdmin || false}
                        onChange={() =>
                          setNewUser({
                            ...newUser,
                            isAdmin: !newUser.isAdmin,
                          })
                        }
                        disabled={loading}
                      />
                    }
                    label={t('Member is dashboard admin?') as string}
                    disabled={loading}
                  />
                </FormGroup>

                {/* dashboard view permissions */}
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          !!newUser.isAdmin ||
                          !!newUser?.hasDashboardViewPermission
                        }
                        onChange={() =>
                          setNewUser({
                            ...newUser,
                            hasDashboardViewPermission:
                              !!newUser.isAdmin ||
                              !newUser?.hasDashboardViewPermission,
                          })
                        }
                        disabled={loading || !!newUser.isAdmin}
                      />
                    }
                    label={`${t('Has Dashboard View Permission?')}`}
                    disabled={loading || !!newUser.isAdmin}
                  />

                  <FormHelperText>
                    {t(
                      "Dashboard view permission allows the member to view the dashboard's data but not edit it."
                    )}
                  </FormHelperText>
                </FormGroup>
              </>
            )}

            {/* notify member */}
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={newUser.notify || false}
                    onChange={() =>
                      setNewUser({
                        ...newUser,
                        notify: !newUser.notify,
                      })
                    }
                    disabled={loading}
                  />
                }
                label={t('Notify member?') as string}
                disabled={loading}
              />

              {newUser.notify ? (
                <FormHelperText>
                  {t(
                    'If new, the member will receive an email to set their password.'
                  )}
                </FormHelperText>
              ) : (
                <FormHelperText>
                  {t(
                    'If new, the member will not receive an email to set their password but will be able reset it at a later date.'
                  )}
                </FormHelperText>
              )}
            </FormGroup>
          </Stack>
        </Box>
      </DialogContent>

      <DialogActions>
        <Button disabled={loading} onClick={onClose} variant="outlined">
          {t('Cancel')}
        </Button>
        <LoadingButton
          loading={loading}
          onClick={handleConfirm}
          variant="contained"
          startIcon={<DoneIcon />}
          disableElevation
        >
          {t('Save')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
