import React from 'react';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import LinkOutlinedIcon from '@mui/icons-material/LinkOutlined';

import { DASHBOARD_UPDATE } from '../../../../_lib/graphql/mutations';
import {
  CONTEXT_DASHBOARD_QUERY,
  CONTEXT_USER_QUERY,
} from '../../../../_lib/graphql/queries';

import { useDashboardContext } from '../../../../_lib/context/dashboard-context';
import { logError } from '../../../../_lib/error';

import { AdvancedSettingsOptionSwitch } from './switch';
import { slugify } from '../../../../_utils/general-utils';

export function AdvancedSettingsOption({ option }: any) {
  const { t } = useTranslation();
  const { user, dashboard } = useDashboardContext();

  const [enabled, setEnabled] = React.useState(dashboard[option.key]);
  const [loading, setLoading] = React.useState(false);

  // -------------------------------------------------------------------------------------------------------

  const [dashboardUpdateMutation] = useMutation(DASHBOARD_UPDATE, {
    update(cache, { data: { dashboardUpdate } }) {
      cache.writeQuery({
        query: CONTEXT_DASHBOARD_QUERY,
        data: { dashboard: dashboardUpdate.dashboard },
      });

      cache.writeQuery({
        query: CONTEXT_USER_QUERY,
        data: { user: dashboardUpdate.user },
      });
    },
  });

  // -------------------------------------------------------------------------------------------------------
  // -------------------------------------------------------------------------------------------------------
  // handlers
  // -------------------------------------------------------------------------------------------------------

  const handleCheck = (checked: boolean) => {
    setEnabled(checked);
    setLoading(true);

    dashboardUpdateMutation({
      variables: {
        [option.key]: checked,
      },
    })
      .catch((err) => {
        setEnabled(!checked);
        logError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // -------------------------------------------------------------------------------------------------------
  // -------------------------------------------------------------------------------------------------------
  // effects
  // -------------------------------------------------------------------------------------------------------

  React.useEffect(() => {
    setEnabled(dashboard[option.key]);
  }, [dashboard, option.key]);

  // -------------------------------------------------------------------------------------------------------

  return (
    <Box
      sx={{
        position: 'relative',
      }}
    >
      <Box id={slugify(option.title)} sx={{ position: 'absolute', top: -80 }} />
      <Typography variant="h6" sx={{ mb: 2 }}>
        {option.title}{' '}
        {option.beta && (
          <Tooltip placement="top" title={`${t('In public testing')}.`} arrow>
            <Chip
              color="info"
              label={t('Beta')}
              size="small"
              sx={{
                fontSize: '70%',
                height: '18px',
                borderRadius: 1,
              }}
            />
          </Tooltip>
        )}
        {option.experimental && (
          <Tooltip placement="top" title={`${t('Highly unstable.')}`} arrow>
            <Chip
              color="warning"
              label={t('Experimental')}
              size="small"
              sx={{
                fontSize: '70%',
                height: '18px',
                borderRadius: 1,
              }}
              variant="outlined"
            />
          </Tooltip>
        )}
        {/* link button */}
        {user.isSuperuser && (
          <IconButton
            size="small"
            sx={{ ml: 1, transform: 'rotate(135deg)' }}
            href={`#${slugify(option.title)}`}
          >
            <LinkOutlinedIcon fontSize="small" />
          </IconButton>
        )}
      </Typography>

      <AdvancedSettingsOptionSwitch
        option={option}
        enabled={enabled}
        loading={loading}
        handleCheck={handleCheck}
      />
    </Box>
  );
}
