import { View, Text } from '@react-pdf/renderer';
import { Style } from '@react-pdf/types';

interface BoxedProps {
  width?: string;
  widthRatio?: [number, number];
  title?: string;
  content?: string | JSX.Element;
  flexDirection?: 'row' | 'column';
  titleTextStyle?: Style;
  titleBgColor?: string;
  titleColor?: string;
  titleBorder?: string;
  titleJustifyContent?: Style['justifyContent'];
  titleAlignItems?: Style['alignItems'];
  titleTextAlign?: Style['textAlign'];
  titleFlexGrow?: number;
  titleFlex?: number;
  contentBorder?: string;
  contentTextAlign?: Style['textAlign'];
  contentJustifyContent?: Style['justifyContent'];
  contentAlignItems?: Style['alignItems'];
  contentFlexGrow?: number;
  contentFlex?: number;
  spacing?: number;
  wrap?: boolean;
}

export function ReportsPdfUiBoxed({
  width,
  widthRatio,
  title,
  content,
  flexDirection,
  titleTextStyle,
  titleBgColor,
  titleColor,
  titleBorder,
  titleJustifyContent,
  titleAlignItems,
  titleTextAlign,
  titleFlexGrow,
  titleFlex,
  contentBorder,
  contentTextAlign,
  contentJustifyContent,
  contentAlignItems,
  contentFlexGrow,
  contentFlex,
  spacing,
  wrap,
}: BoxedProps) {
  const spacingPx = spacing ? `${spacing * 4}px` : 0;
  const spacingPxhalf = spacing ? `${spacing * 2}px` : 0;

  const [widthTitle, widthContent] = [
    ((widthRatio?.[0] || 1) / (widthRatio?.reduce((a, b) => a + b) || 1)) * 100,
    ((widthRatio?.[1] || 1) / (widthRatio?.reduce((a, b) => a + b) || 1)) * 100,
  ];

  return (
    <View style={{ width, display: 'flex', flexDirection }} wrap={wrap}>
      <View
        style={{
          backgroundColor: titleBgColor,
          color: titleColor,
          width:
            flexDirection === 'row'
              ? `calc(${widthTitle}% - ${spacingPxhalf})`
              : '100%',
          padding: '5px',
          display: 'flex',
          justifyContent: titleJustifyContent,
          alignItems: titleAlignItems,
          textAlign: titleTextAlign,
          flexGrow: titleFlexGrow,
          flex: titleFlex,
        }}
        wrap={false}
      >
        <Text style={{ fontWeight: 'bold', ...titleTextStyle }}>{title}</Text>
      </View>
      {spacing && (
        <View
          style={{
            width: flexDirection === 'row' ? spacingPx : '100%',
            height: flexDirection === 'row' ? '100%' : spacingPx,
          }}
        />
      )}
      <View
        style={{
          border: contentBorder,
          padding: '5px',
          width:
            flexDirection === 'row'
              ? `calc(${widthContent}% - ${spacingPxhalf})`
              : '100%',
          display: 'flex',
          justifyContent: contentJustifyContent,
          alignItems: contentAlignItems,
          textAlign: contentTextAlign,
          flexGrow: contentFlexGrow,
          flex: contentFlex,
        }}
      >
        {!content ? (
          <Text>Content goes here</Text>
        ) : typeof content === 'string' ? (
          <Text>{content}</Text>
        ) : (
          content
        )}
      </View>
    </View>
  );
}

ReportsPdfUiBoxed.defaultProps = {
  width: '100%',
  widthRatio: [1, 1],
  title: 'Title Goes Here',
  content: 'Content goes here',
  flexDirection: 'row',
  titleTextStyle: {},
  titleBgColor: '#eeeeee',
  titleColor: '#424242',
  titleBorder: '1px solid #333333',
  titleJustifyContent: 'center',
  titleAlignItems: 'center',
  titleTextAlign: 'center',
  titleFlexGrow: 1,
  titleFlex: undefined,
  contentBorder: '1px solid #333333',
  contentTextAlign: 'center',
  contentJustifyContent: 'center',
  contentAlignItems: 'center',
  contentFlexGrow: 0,
  contentFlex: undefined,
  spacing: 1,
  wrap: undefined,
};

export default ReportsPdfUiBoxed;
