import React from 'react';
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { REPORTS_QUERY } from '../../_lib/graphql/queries';
import { REPORTS_SUBSCRIPTION } from '../../_lib/graphql/subscriptions';

import { logError } from '../../_lib/error';
import { SubscribeToMore } from '../../components/subscribe-to-more';
import { ReportsIcon } from '../../components/icons/reports-icon';

import { ReportsHomeDetail } from './detail';

export function ReportsHomeList() {
  const { t } = useTranslation();

  const { data, loading, error, refetch, subscribeToMore } =
    useQuery(REPORTS_QUERY);

  // ---------------------------------------------------------------------------------------------------
  // ---------------------------------------------------------------------------------------------------
  // effects
  // ---------------------------------------------------------------------------------------------------

  React.useEffect(() => {
    if (error) logError(error);
  }, [error]);

  React.useEffect(() => {
    if (!data && !loading && !error) refetch();
  }, [data, error, loading, refetch]);

  // ---------------------------------------------------------------------------------------------------

  if (loading || !data) return <Skeleton variant="rectangular" height={300} />;

  return (
    <>
      {!data?.reports?.length && (
        <Box sx={{ textAlign: 'center', color: 'text.secondary', my: 3 }}>
          <ReportsIcon sx={{ fontSize: 50, mb: 2, color: 'action' }} />
          <Typography variant="body1">
            {t('Reports will be shown here.')}
          </Typography>
        </Box>
      )}

      <Grid container spacing={2}>
        {data.reports?.map((report: any) => (
          <ReportsHomeDetail key={report.id} report={report} />
        ))}
      </Grid>

      {/* subscription */}
      <SubscribeToMore
        subscribeToMore={() =>
          subscribeToMore({
            document: REPORTS_SUBSCRIPTION,
            updateQuery: (prev, { subscriptionData }) => {
              if (!subscriptionData.data) return prev;
              return subscriptionData.data;
            },
          })
        }
      />
    </>
  );
}
