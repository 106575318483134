import React from 'react';
import { useTranslation } from 'react-i18next';

import Button from '@mui/material/Button';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { CurrencyText } from '../../currency-text';
import { useLocalStorage } from '../../../_utils/hooks/use-local-storage';
import { consolidateFinanciersByFinanceMethod } from '../../../_utils/finance-utils';

export function AreaSummaryFinancialsFinanceMethods({
  id,
  financiers,
  onlyTable,
}: any) {
  const { t } = useTranslation();

  const [openMore, setOpenMore] = useLocalStorage(`${id}-methods`, false);

  const financeMethods = React.useMemo(
    () => consolidateFinanciersByFinanceMethod(financiers),
    [financiers]
  );

  if (Object.keys(financeMethods).length === 0) return null;

  // ------------------------------------------------------------------------------------------------------------------------

  return (
    <Box>
      {!onlyTable && (
        <Button
          fullWidth
          onClick={() => setOpenMore(!openMore)}
          endIcon={
            <ExpandMoreIcon
              sx={{
                transform: openMore ? 'rotate(0deg)' : 'rotate(-90deg)',
                transition: 'transform 0.2s ease-in-out',
                displayPrint: 'none',
              }}
            />
          }
          variant="outlined"
          sx={{
            justifyContent: 'space-between',
            textTransform: 'none',
            borderRadius: openMore ? '4px 4px 0 0' : '4px',
            transition: 'border-radius 0.2s ease-in-out',
            displayPrint: openMore ? 'flex' : 'none',
          }}
        >
          {t('Finance Methods Summary')}
        </Button>
      )}

      {/* table */}
      <Collapse in={openMore || onlyTable}>
        <TableContainer
          component={Paper}
          variant="outlined"
          sx={{ maxHeight: 440, borderRadius: '0px 0px 4px 4px', borderTop: 0 }}
        >
          <Table stickyHeader size="small">
            <TableHead>
              <TableRow>
                <TableCell sx={{ bgcolor: 'grey.200' }}>
                  {t('Finance Method')}
                </TableCell>
                <TableCell align="right" sx={{ bgcolor: 'grey.200' }}>
                  {t('Amount')}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.keys(financeMethods).map((financeMethod: any) => (
                <TableRow
                  key={financeMethod}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {financeMethod === 'N/A' ? (
                      <i>{t('Unassigned')}</i>
                    ) : (
                      financeMethod
                    )}
                  </TableCell>
                  <TableCell align="right" sx={{ whiteSpace: 'nowrap' }}>
                    <CurrencyText
                      amount={financeMethods[financeMethod].amount}
                      currency={financeMethods[financeMethod].currency}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Collapse>
    </Box>
  );
}
