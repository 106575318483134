import React from 'react';
import { useTranslation } from 'react-i18next';
import { gql, useMutation } from '@apollo/client';

import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Alert from '@mui/material/Alert';
import LoadingButton from '@mui/lab/LoadingButton';

import { logError } from '../../../../_lib/error';

const VERIFY_TOKEN = gql`
  mutation TwoFactorVerifyToken($token: String!) {
    twoFactorVerifyToken(token: $token) {
      message
      success
    }
  }
`;

const VERIFY_BACKUP_TOKEN = gql`
  mutation TwoFactorVerifyBackupToken($token: String!) {
    twoFactorVerifyBackupToken(token: $token) {
      success
      message
    }
  }
`;

export function TwoFactorVerifyCode({
  onSuccess,
  disabled,
  loading,
  setLoading,
  spacing,
  includeBackupCodesOption,
}: any) {
  const { t } = useTranslation();

  const [authCode, setAuthCode] = React.useState('');
  const [useBackupCodes, setUseBackCodes] = React.useState(false);
  const [error, setError] = React.useState('');

  const [verifyMutation] = useMutation(VERIFY_TOKEN);
  const [verifyBackupMutation] = useMutation(VERIFY_BACKUP_TOKEN);

  // ---------------------------------------------------------------------------------------------------------------------
  // ---------------------------------------------------------------------------------------------------------------------
  // handlers
  // ---------------------------------------------------------------------------------------------------------------------

  const handleVerify = (code: string) => {
    setError('');

    if (authCode?.length < 6) {
      setError(`${t('Code must be 6 characters long.')}`);
      return;
    }

    setLoading(true);

    const mutation = useBackupCodes ? verifyBackupMutation : verifyMutation;

    mutation({
      variables: {
        token: code,
      },
    })
      .then((res) => {
        const { success, message } =
          res.data[
            useBackupCodes
              ? 'twoFactorVerifyBackupToken'
              : 'twoFactorVerifyToken'
          ];
        setLoading(false);
        if (success) {
          if (onSuccess) {
            onSuccess();
          }
        } else {
          setError(message);
        }
      })
      .catch((err) => {
        setLoading(false);
        logError(err);
      });
  };

  // ---------------------------------------------------------------------------------------------------------------------

  return (
    <Stack spacing={spacing || 1}>
      <TextField
        value={authCode}
        onChange={(e) => setAuthCode(e.target.value.trim())}
        type="text"
        size="small"
        label={useBackupCodes ? t('Backup Code') : t('OTP Code')}
        autoFocus
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            handleVerify(authCode);
          }
        }}
        disabled={loading || disabled}
      />

      {!!error && <Alert severity="error">{t(error)}</Alert>}

      <LoadingButton
        variant="contained"
        id="verify-qr"
        onClick={() => handleVerify(authCode)}
        loading={loading}
        disabled={disabled}
        sx={{ textTransform: 'none' }}
      >
        {useBackupCodes ? t('Verify Backup Code') : t('Verify')}
      </LoadingButton>

      {includeBackupCodesOption && (
        <LoadingButton
          variant="outlined"
          sx={{ textTransform: 'none' }}
          onClick={() => setUseBackCodes(!useBackupCodes)}
          disabled={disabled}
        >
          {!useBackupCodes ? t('Use Backup Codes') : t('Use OTP Code')}
        </LoadingButton>
      )}
    </Stack>
  );
}
