import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';

import PlaylistRemoveIcon from '@mui/icons-material/PlaylistRemove';

import { MEMBER_REMOVE } from '../../../../../_lib/graphql/mutations';
import { CONTEXT_USERS_QUERY } from '../../../../../_lib/graphql/queries';

import { MyAccountPreferencesDetailTitleConfirmDialog } from './confirm-dialog';
import { useDashboardContext } from '../../../../../_lib/context/dashboard-context';
import { logError } from '../../../../../_lib/error';

export function MyAccountPreferencesDetailTitleLeaveDashboardDialog({
  open,
  setOpen,
  setting,
}: any) {
  const { t } = useTranslation();

  const { setSnackbarOpen, setSnackbarIsError } = useDashboardContext();

  const [loading, setLoading] = React.useState(false);

  const [memberRemoveMutation] = useMutation(MEMBER_REMOVE, {
    update(cache, { data: { memberRemove } }) {
      cache.writeQuery({
        query: CONTEXT_USERS_QUERY,
        data: { users: memberRemove.users },
      });
    },
  });

  const handleSubmit = () => {
    setLoading(true);
    memberRemoveMutation({
      variables: {
        settingId: setting.id,
      },
    })
      .then(() => {
        setSnackbarOpen(true);
      })
      .catch((error) => {
        logError(error);
        setLoading(false);
        setSnackbarIsError(true);
        setSnackbarOpen(true);
      });
  };

  return (
    <MyAccountPreferencesDetailTitleConfirmDialog
      title={t('Leave Dashboard?')}
      description={t('Are you sure you want to leave this dashboard?')}
      confirmButtonLabel={t('Leave')}
      confirmButtonIcon={<PlaylistRemoveIcon />}
      confirmButtonColor="error"
      confirmButtonOnClick={handleSubmit}
      loading={loading}
      open={open}
      setOpen={setOpen}
      setting={setting}
    />
  );
}
