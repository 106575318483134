import { useTranslation } from 'react-i18next';
import { gql } from '@apollo/client';

import Chip from '@mui/material/Chip';

import { PlanningFieldsInputAutocomplete } from './_autocomplete';
import { useDashboardContext } from '../../_lib/context/dashboard-context';

import { REGIONS_QUERY } from '../../_lib/graphql/queries';

const SUBSCRIBE_ITEMS = gql`
  subscription Regions {
    regions {
      id
      name
      isMapFeature
      isReadonly
    }
  }
`;

const CREATE_ITEM = gql`
  mutation RegionCreate($name: String!) {
    regionCreate(name: $name) {
      region {
        id
        name
        isMapFeature
        isReadonly
      }
      regions {
        id
        name
        isMapFeature
        isReadonly
      }
    }
  }
`;

const UPDATE_ITEM = gql`
  mutation RegionUpdate($id: ID!, $name: String!) {
    regionUpdate(id: $id, name: $name) {
      success
      message
      regions {
        id
        name
        isMapFeature
        isReadonly
      }
    }
  }
`;

const DELETE_ITEM = gql`
  mutation RegionDelete($id: ID!) {
    regionDelete(id: $id) {
      success
      message
      regions {
        id
        name
        isMapFeature
        isReadonly
      }
    }
  }
`;

const UPDATE_ITEM_ORDER = gql`
  mutation RegionUpdateOrder($ids: [ID!]!) {
    regionUpdateOrder(ids: $ids) {
      success
      message
      regions {
        id
        name
        isMapFeature
        isReadonly
      }
    }
  }
`;

export function PlanningFieldsInputRegions({ disabled, area, setArea }: any) {
  const { t } = useTranslation();

  const { dashboard } = useDashboardContext();

  // ----------------------------------------------------------------------------------------

  return (
    <PlanningFieldsInputAutocomplete
      disabled={disabled}
      area={area}
      setArea={setArea}
      GET_ITEMS={REGIONS_QUERY}
      SUBSCRIBE_ITEMS={SUBSCRIBE_ITEMS}
      CREATE_ITEM={CREATE_ITEM}
      createItemOpName="regionCreate"
      UPDATE_ITEM={UPDATE_ITEM}
      updateItemOpName="regionUpdate"
      DELETE_ITEM={DELETE_ITEM}
      deleteItemOpName="regionDelete"
      UPDATE_ITEM_ORDER={UPDATE_ITEM_ORDER}
      updateItemOrderOpName="regionUpdateOrder"
      dataKey="regions"
      manageDialogTitle={
        dashboard.enableCustomRegionLabel
          ? `${t('Manage')} ${dashboard.customRegionLabel}`
          : t('Manage Regions')
      }
      manageDialogInputLabel={t('Name')}
      manageDialogAddLabel={
        dashboard.enableCustomRegionLabel
          ? `${t('Add')} ${dashboard.customRegionLabel}`
          : t('Add Region')
      }
      label={
        dashboard.enableCustomRegionLabel
          ? ` ${dashboard.customRegionLabel}`
          : t('Regions')
      }
      multiple
      addOption
      forceManageOption
      chip={(item: any) =>
        item.isMapFeature && (
          <Chip
            size="small"
            label={t('Map')}
            color="primary"
            sx={{ ml: 1, bgcolor: dashboard.customMapPalette.bgColor }}
          />
        )
      }
    />
  );
}
