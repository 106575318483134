import React from 'react';
import { View, Text } from '@react-pdf/renderer';

import { Page } from '../page';
import { List, Item } from '../ui/list';

import { getReportTemplateMetas } from '../../../../_utils/reports/get-report-template-meta';

export function ReportsPdfPagesMembers({ t, reportData }: any) {
  const reportsTemplateMetas = React.useMemo(
    () => getReportTemplateMetas(reportData.dashboard.reportsTemplateMeta),
    [reportData.dashboard.reportsTemplateMeta]
  );

  return (
    <Page
      pageOrientation={
        reportsTemplateMetas?.pageOrientation === 'LANDSCAPE'
          ? 'landscape'
          : 'portrait'
      }
    >
      <View>
        <Text style={{ fontSize: 16, marginBottom: 10 }}>
          {t('Members Details')}
        </Text>
        <View>
          <List>
            {reportData?.dashboard?.users?.map((user: any, i: number) => (
              <Item key={user.id} number={i + 1}>
                {user.name}
                {user.isAdmin && ` - ${t('Admin')}`}
                {' - '}
                {user.email}
              </Item>
            ))}
          </List>
        </View>
      </View>
    </Page>
  );
}
