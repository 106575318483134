import React from 'react';
import { useTranslation } from 'react-i18next';

import Button, { ButtonProps } from '@mui/material/Button';

import { AdvancedAreaLevelsDialog } from './dialog';

export function AdvancedAreaLevels({
  disabled,
  variant,
  size,
  fullWidth,
}: {
  disabled?: ButtonProps['disabled'];
  variant?: ButtonProps['variant'];
  size?: ButtonProps['size'];
  fullWidth?: ButtonProps['fullWidth'];
}) {
  const { t } = useTranslation();

  const [openDialog, setOpenDialog] = React.useState(false);

  return (
    <>
      {/* dialog */}
      <AdvancedAreaLevelsDialog open={openDialog} setOpen={setOpenDialog} />

      {/* button */}
      <Button
        disabled={disabled}
        variant={variant}
        size={size}
        fullWidth={fullWidth}
        onClick={() => setOpenDialog(true)}
      >
        {t('Manage Priority Area Levels')}
      </Button>
    </>
  );
}

AdvancedAreaLevels.defaultProps = {
  variant: 'outlined',
  size: 'small',
  fullWidth: false,
  disabled: false,
};
