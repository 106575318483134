import React from 'react';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import DoneIcon from '@mui/icons-material/Done';

import { ContentDialogContext } from '../../../_lib/context/content-dialog-context';

import { CONTENT_UPDATE_CREATE } from '../../../_lib/graphql/mutations';
import { logError } from '../../../_lib/error';

import { DialogModal } from '../../../components/dialog-modal';
import { DashboardContext } from '../../../_lib/context/dashboard-context';

export function SuggestContentCompleted({ checkbox }: any) {
  const { t } = useTranslation();

  const { setSnackbarOpen, setSnackbarIsError } =
    React.useContext(DashboardContext);
  const { content } = React.useContext(ContentDialogContext);

  const [confirmDialogOpen, setConfirmDialogOpen] = React.useState(false);

  // content checked or indeterminate
  const [checked, setChecked] = React.useState(!!content.completionDate);
  const [indeterminate, setIndeterminate] = React.useState(
    content.status === 'pending'
  );
  React.useEffect(() => {
    if (content) {
      setIndeterminate(content.status === 'pending');
      setChecked(!!content.completionDate);
    }
  }, [content]);

  const [addUpdateMutation, { loading }] = useMutation(CONTENT_UPDATE_CREATE);

  const suggestCompletedActivity = (update: any) => {
    setChecked(!checked);

    const variables = {
      contentId: content.id,
      status: 'PENDING',
      text: update.text,
      attachment: update.attachment,
      suggestCompleted: true,
    };

    addUpdateMutation({ variables })
      .then(() => {
        setConfirmDialogOpen(false);
      })
      .catch((err) => {
        logError(err);
        setSnackbarIsError(true);
      })
      .finally(() => {
        setSnackbarOpen(true);
      });
  };

  return (
    <>
      <DialogModal
        loading={loading}
        onDismiss={() => setConfirmDialogOpen(false)}
        onSubmit={suggestCompletedActivity}
        show={confirmDialogOpen}
        title={t(
          'Provide reasons / evidence for marking this content as completed'
        )}
        type="text-input-attachment"
      />

      {!checkbox ? (
        <Button
          id="suggest-content-completed"
          onClick={() => setConfirmDialogOpen(true)}
          variant="outlined"
          startIcon={<DoneIcon />}
          disabled={indeterminate || loading || content.status === 'deleted'}
        >
          {t('Suggest completed')}
        </Button>
      ) : (
        <Checkbox
          checked={checked}
          indeterminate={indeterminate}
          color="default"
          inputProps={{ 'aria-label': `${t('Suggest completed')}` }}
          onChange={() => setConfirmDialogOpen(true)}
          disabled={
            checked || indeterminate || loading || content.status === 'deleted'
          }
        />
      )}
    </>
  );
}
