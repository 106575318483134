import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActionArea from '@mui/material/CardActionArea';
import Paper from '@mui/material/Paper';

import { ReactErrorComponent } from '../../../_lib/react-error';
import { useDashboardContext } from '../../../_lib/context/dashboard-context';

import { SwitchDashboardDialog } from '../../switch-dashboard/dialog';

import { DEFAULT_SUMMARY_ITEMS_ORDER } from '../../../_lib/data/constants';

import { AreaSummaryCardHeader } from './header';
import { AreaSummaryCardFinance } from './finance';
import { AreaSummaryChartContent } from './content';
import { AreaSummaryCardLeads } from './leads';
import { AreaSummaryCardTargets } from './targets';

export function AreaSummaryCard({
  area: areaOg,
  filterCharts,
  simple,
  disableClick,
}: any) {
  const {
    dashboard: {
      mode: dashboardMode,
      enableDodPaSwitch,
      enableSummaryItemsOrder,
      summaryItemsOrder,
    },
  } = useDashboardContext();

  const [switchDashboardOpen, setSwitchDashboardOpen] = React.useState(false);
  const sortOrder = React.useMemo(
    () =>
      enableSummaryItemsOrder && summaryItemsOrder.length
        ? summaryItemsOrder
        : DEFAULT_SUMMARY_ITEMS_ORDER,
    [enableSummaryItemsOrder, summaryItemsOrder]
  );

  // --------------------------------------------------------------------------------------------------------------------
  // --------------------------------------------------------------------------------------------------------------------
  // handlers
  // --------------------------------------------------------------------------------------------------------------------

  const handleSwitchDashboard = React.useCallback(() => {
    if (!areaOg.dashboardOfDashboards?.dashboard?.id) return;
    setSwitchDashboardOpen(true);
  }, [areaOg.dashboardOfDashboards?.dashboard?.id]);

  // --------------------------------------------------------------------------------------------------------------------

  // map items before render
  const componentsMap: any = React.useMemo(
    () => ({
      Financials: !simple ? (
        // financials
        <AreaSummaryCardFinance key="financials" area={areaOg} />
      ) : null,
      Targets: !simple ? (
        <AreaSummaryCardTargets key="targets" area={areaOg} />
      ) : null,
      'Activities Chart': (
        <Paper key="activities-chart" variant="outlined" sx={{ p: 2, mb: 2 }}>
          {/* content */}
          <AreaSummaryChartContent area={areaOg} filterCharts={filterCharts} />
        </Paper>
      ),
    }),
    [areaOg, filterCharts, simple]
  );

  // --------------------------------------------------------------------------------------------------------------------

  return (
    <>
      {/* switch dashboard dialog */}
      <SwitchDashboardDialog
        open={switchDashboardOpen}
        setOpen={setSwitchDashboardOpen}
        dashboardId={areaOg.dashboardOfDashboards?.dashboard?.id}
      />

      {/* component */}
      <ErrorBoundary FallbackComponent={ReactErrorComponent}>
        {/* card */}
        <Card
          sx={{
            width: '100%',
          }}
          variant={simple ? 'outlined' : 'elevation'}
        >
          {/* header */}
          <AreaSummaryCardHeader
            area={areaOg}
            handleSwitchDashboard={handleSwitchDashboard}
          />

          {/* body */}
          <CardActionArea
            onClick={
              !disableClick &&
              enableDodPaSwitch &&
              dashboardMode === 'SUPRANATIONAL'
                ? handleSwitchDashboard
                : undefined
            }
            disabled={switchDashboardOpen}
            disableRipple={
              dashboardMode === 'SUPRANATIONAL' &&
              window.location.pathname !== '/'
            }
            component={
              disableClick ||
              (enableDodPaSwitch && dashboardMode === 'SUPRANATIONAL')
                ? 'div'
                : RouterLink
            }
            to={areaOg.urlRelative}
          >
            <CardContent
              sx={{
                bgcolor: 'grey.100',
              }}
            >
              {sortOrder.map((key: string) => componentsMap[key])}

              {/* leads */}
              {dashboardMode !== 'SUPRANATIONAL' && (
                <AreaSummaryCardLeads area={areaOg} />
              )}
            </CardContent>
          </CardActionArea>
        </Card>
      </ErrorBoundary>
    </>
  );
}
