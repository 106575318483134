import React from 'react';
import { useTranslation } from 'react-i18next';
import { gql, useQuery } from '@apollo/client';
import { ErrorBoundary } from 'react-error-boundary';

import Paper from '@mui/material/Paper';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import Skeleton from '@mui/material/Skeleton';

import { useDashboardContext } from '../../../_lib/context/dashboard-context';

import { logError } from '../../../_lib/error';
import { SubscribeToMoreComponent } from '../../subscribe-to-more';
import { ReactErrorComponent } from '../../../_lib/react-error';

import { InterventionsSummaryTableRow } from './table-row';

const D_QUERY = gql`
  query DashboardInterventionsSummaryTable {
    dashboard {
      id
      interventionsHaveBudget
      interventionsHaveStatus
    }
  }
`;

const D_SUBSCRIPTION = gql`
  subscription DashboardInterventionsSummaryTable {
    dashboard {
      id
      interventionsHaveBudget
      interventionsHaveStatus
    }
  }
`;

export function InterventionsSummaryTable({
  data: dataInterventions,
  showPriorityArea,
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
  openInNewTab,
}: any) {
  const { t } = useTranslation();

  const {
    dashboard: { enableCustomFinanceLabels, customFinanceBudgetLabel },
  } = useDashboardContext();

  const { data, loading, error, subscribeToMore } = useQuery(D_QUERY);

  // --------------------------------------------------------------------------------------------------------
  // --------------------------------------------------------------------------------------------------------
  // handlers
  // --------------------------------------------------------------------------------------------------------

  // --------------------------------------------------------------------------------------------------------
  // --------------------------------------------------------------------------------------------------------
  // effects
  // --------------------------------------------------------------------------------------------------------

  // error
  React.useEffect(() => {
    if (error) logError(error);
  }, [error]);

  // --------------------------------------------------------------------------------------------------------

  if (error) return <ReactErrorComponent error={error} />;

  if (loading || !data) return <Skeleton variant="rectangular" height={200} />;

  // --------------------------------------------------------------------------------------------------------

  return (
    <ErrorBoundary FallbackComponent={ReactErrorComponent}>
      <TableContainer
        component={Paper}
        variant="outlined"
        sx={{ borderRadius: '0 0 4px 4px' }}
      >
        <Table stickyHeader size="small">
          <TableHead>
            <TableRow>
              <TableCell
                sx={{
                  bgcolor: 'grey.200',
                  whiteSpace: 'nowrap',
                  minWidth: '300px',
                }}
              >
                {t('Name')}
              </TableCell>
              <TableCell
                sx={{
                  bgcolor: 'grey.200',
                  whiteSpace: 'nowrap',
                  minWidth: '200px',
                }}
              >
                {t('Leads')}
              </TableCell>
              {data.dashboard.interventionsHaveStatus && (
                <TableCell sx={{ bgcolor: 'grey.200', whiteSpace: 'nowrap' }}>
                  {t('Status')}
                </TableCell>
              )}
              {data.dashboard.interventionsHaveBudget && (
                <TableCell
                  align="left"
                  sx={{
                    bgcolor: 'grey.200',
                    whiteSpace: 'nowrap',
                    minWidth: '200px',
                  }}
                >
                  {enableCustomFinanceLabels
                    ? customFinanceBudgetLabel
                    : t('Budget')}
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {dataInterventions?.interventions?.items?.map(
              (intervention: any) => (
                <InterventionsSummaryTableRow
                  key={intervention.id}
                  intervention={intervention}
                  hasStatus={data.dashboard.interventionsHaveStatus}
                  hasBudget={data.dashboard.interventionsHaveBudget}
                  showPriorityArea={showPriorityArea}
                  openInNewTab={openInNewTab}
                />
              )
            )}
          </TableBody>
          {dataInterventions?.interventions?.meta.count > rowsPerPage && (
            <TableFooter>
              <TableRow>
                <TablePagination
                  count={dataInterventions?.interventions?.meta.count}
                  onPageChange={(event, pg) => setPage(pg)}
                  page={page}
                  rowsPerPage={rowsPerPage}
                  rowsPerPageOptions={[5, 10, 25]}
                  onRowsPerPageChange={(event) => {
                    setRowsPerPage(parseInt(event.target.value, 10));
                    setPage(0);
                  }}
                  labelRowsPerPage={t('Rows per page')}
                />
              </TableRow>
            </TableFooter>
          )}
        </Table>
      </TableContainer>

      {/* subscribe to more */}
      {!!data && (
        <SubscribeToMoreComponent
          subscribeToMore={subscribeToMore}
          document={D_SUBSCRIPTION}
        />
      )}
    </ErrorBoundary>
  );
}
