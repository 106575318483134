import React from 'react';

import { useTranslation } from 'react-i18next';

import Button from '@mui/material/Button';

import { AdvancedDashboardSummaryDialog } from './dialog';

export function AdvancedDashboardSummary({ enabled }: any) {
  const { t } = useTranslation();

  const [open, setOpen] = React.useState(false);

  if (!enabled) return null;
  return (
    <>
      <AdvancedDashboardSummaryDialog open={open} setOpen={setOpen} />
      <Button variant="outlined" size="small" onClick={() => setOpen(true)}>
        {t('Dashboard Overview Options')}
      </Button>
    </>
  );
}
