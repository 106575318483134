import React from 'react';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import { useDashboardContext } from '../../_lib/context/dashboard-context';

import { AreasFilterMenu } from './menu';

export function AreasFilter({
  searchValue,
  setSearchValue,

  filterCharts,
  setFilterCharts,
  placeholder,
}: any) {
  const { t } = useTranslation();

  const { dashboard } = useDashboardContext();

  const handleChange = (event: any) => {
    setSearchValue(event.target.value);
  };

  const handleClear = () => {
    setSearchValue('');
  };

  // --------------------------------------------------------------------------------

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        mt: 5,
        displayPrint: 'none',
      }}
    >
      <TextField
        id="areas-search-input"
        value={searchValue}
        label={t('Search')}
        placeholder={placeholder || dashboard.priorityAreaName}
        variant="outlined"
        onChange={handleChange}
        autoComplete="off"
        size="small"
        sx={{ maxWidth: '450px', width: '100%', bgcolor: 'white' }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="clear area search filter"
                onClick={handleClear}
                onMouseDown={handleClear}
                edge="end"
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />

      <AreasFilterMenu
        filterCharts={filterCharts}
        setFilterCharts={setFilterCharts}
      />
    </Box>
  );
}
