import React from 'react';
import { useTranslation } from 'react-i18next';
import countryList from 'country-list';

import Box from '@mui/material/Box';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

import { useDashboardContext } from '../../../../_lib/context/dashboard-context';

import { DashboardFormDetailsCurrency } from './currency';

export function DashboardFormDetailsCountry({
  disabled,
  newDashboard,
  setNewDashboard,
  errors,
}: any) {
  const { t } = useTranslation();

  const { dashboard } = useDashboardContext();

  const countryOptions = React.useMemo(() => {
    if (process.env.REACT_APP_IS_DEMO_SITE) {
      return [...countryList.getData(), { name: t('Demo') }];
    }
    return countryList.getData();
  }, [t]);

  // Handle change
  const handleChange = (event: any, newValue: any | null) => {
    setNewDashboard({
      ...newDashboard,
      country: newValue,
    });
  };

  return (
    <Box>
      <Autocomplete
        fullWidth
        size="small"
        disabled={disabled}
        id="dashboard-country-select"
        value={newDashboard.country}
        onChange={handleChange}
        options={countryOptions.map((c: any) => c.name)}
        renderInput={(params) => (
          <TextField
            {...params}
            label={t('Country')}
            placeholder={`${t('Select country')}...`}
            inputProps={{
              ...params.inputProps,
              autoComplete: 'new-password', // disable autocomplete and autofill
            }}
            error={!!errors.country}
            helperText={errors.country}
          />
        )}
        disableClearable
      />

      {/* dashboard currency */}
      {dashboard.mode !== 'TRACKER' && (
        <DashboardFormDetailsCurrency
          newDashboard={newDashboard}
          setNewDashboard={setNewDashboard}
          disabled={disabled}
        />
      )}
    </Box>
  );
}
