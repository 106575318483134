import React from 'react';

import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';

import LaunchIcon from '@mui/icons-material/Launch';
import { useTranslation } from 'react-i18next';

export function AppbarAvatarDocumentation() {
  const { t, i18n } = useTranslation();

  const handleOpen = React.useCallback(() => {
    let url = 'https://docs.digitaldashboard.cc/';
    if (i18n.language !== 'en')
      url = `https://docs.digitaldashboard.cc/${i18n.language}/`;
    const w = window.open(url, '_blank');
    if (w) w.focus();
  }, [i18n.language]);

  return (
    <MenuItem onClick={handleOpen}>
      <ListItemIcon>
        <LaunchIcon fontSize="small" />
      </ListItemIcon>

      <ListItemText
        primary={t('Documentation')}
        primaryTypographyProps={{
          noWrap: true,
          display: 'flex',
          alignItems: 'center',
        }}
      />
    </MenuItem>
  );
}
