import React from 'react';
import { gql, useQuery } from '@apollo/client';

import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Skeleton from '@mui/material/Skeleton';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { useDashboardContext } from '../../../_lib/context/dashboard-context';
import { useLocalStorage } from '../../../_utils/hooks/use-local-storage';

import { InterventionsSummaryStatusBar } from './status-bar';
import { InterventionsSummaryTable } from './table';
import { SubscribeToMoreComponent } from '../../subscribe-to-more';
import {
  FILTER_VARIABLES,
  FILTER_VARIABLES_DEF,
} from '../../../_lib/graphql/fragments';
import { ReactErrorComponentSmall } from '../../../_lib/react-error';

const INTVS_QUERY = gql`
  query InterventionsSummaryQuery(
    $priorityAreaId: ID,
    ${FILTER_VARIABLES_DEF}
  ) {
    interventions(
      priorityAreaId: $priorityAreaId,
      ${FILTER_VARIABLES}
    ) {
      items{
        id
      }
      meta {
        count
      }
    }
  }
`;
const INTVS_SUBSCRIPTION = gql`
  subscription InterventionsSummarySubscription(
    $priorityAreaId: ID,
    ${FILTER_VARIABLES_DEF}
  ) {
    interventions(
      priorityAreaId: $priorityAreaId,
      ${FILTER_VARIABLES}
    ) {
      items {
       id
      }
      meta {
        count
      }
    }
  }
`;

export function InterventionsSummary({
  id,
  showDetails,
  showPriorityArea,
  detailsDefaultOpen,
  variables: variablesProp,
}: any) {
  const {
    dashboard: { interventionName },
    navbarFilterVariables,
  } = useDashboardContext();

  const [open, setOpen] = useLocalStorage(
    id || 'interventions-summary-more',
    !!detailsDefaultOpen
  );

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const variables = React.useMemo(
    () => ({
      ...variablesProp,
      ...navbarFilterVariables,
      paginationInterventionsLimit: rowsPerPage,
      paginationInterventionsOffset: page * rowsPerPage,
    }),
    [navbarFilterVariables, page, rowsPerPage, variablesProp]
  );

  const { loading, data, error, subscribeToMore, refetch } = useQuery(
    INTVS_QUERY,
    {
      variables,
    }
  );

  // --------------------------------------------------------------------------------------------------------
  // --------------------------------------------------------------------------------------------------------
  // effects
  // --------------------------------------------------------------------------------------------------------

  // refetch
  React.useEffect(() => {
    if (!loading && !data && !error) refetch();
  }, [data, error, loading, refetch]);

  // --------------------------------------------------------------------------------------------------------

  if (error) return <ReactErrorComponentSmall error={error} />;

  if (loading || !data) return <Skeleton variant="rectangular" height={50} />;

  // --------------------------------------------------------------------------------------------------------

  return (
    <>
      {/* title */}
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 0.5 }}>
        <Typography variant="h6" sx={{ flexGrow: 1 }}>
          {interventionName}{' '}
          <Typography variant="body1" component="span">
            ({data?.interventions.meta?.count})
          </Typography>
        </Typography>

        {showDetails && (
          <IconButton
            onClick={(event: any) => {
              event.stopPropagation();
              setOpen(!open);
            }}
            {...{
              component: 'span',
              sx: {
                ml: '0.5',
                displayPrint: 'none',
              },
            }}
          >
            <ExpandMoreIcon
              sx={{
                transform: open ? 'rotate(0deg)' : 'rotate(-90deg)',
                transition: 'transform 0.2s ease-in-out',
              }}
              fontSize="inherit"
            />
          </IconButton>
        )}
      </Box>

      {/* status bar graph */}
      <InterventionsSummaryStatusBar
        variables={{
          ...variablesProp,
          ...navbarFilterVariables,
        }}
        height="17px"
        borderRadius={showDetails && open ? '3px 3px 0 0' : undefined}
      />

      {/* interventions table */}
      {showDetails && (
        <Collapse in={open}>
          <InterventionsSummaryTable
            data={data}
            showPriorityArea={showPriorityArea}
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
          />
        </Collapse>
      )}

      {/* subscribe to more */}
      <SubscribeToMoreComponent
        document={INTVS_SUBSCRIPTION}
        variables={variables}
        subscribeToMore={subscribeToMore}
      />
    </>
  );
}
