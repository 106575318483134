import { View } from '@react-pdf/renderer';

import { hasSomeFinance } from '../../../../_utils/finance-utils';
import { getTargets } from '../../../../_utils/targets-utils';
import { reduceSummaries } from '../../../../_utils/content-utils/reduce-summaries';

import {
  ReportsPdfUiSectionSubTitle,
  ReportsPdfUiSectionTitle,
} from '../ui/section-title';
import { ReportsPdfSectionsFinance } from './finance';
import { ReportsPdfSectionsTarget } from './targets';
import { ReportsPdfSectionsActivitiesAreasCharts } from './activities/areas-charts';
import { ReportsPdfSectionsActivitiesLists } from './activities/lists';
import { ReportsPdfSectionsActivitiesMilestones } from './activities/milestones';
import { ReportsPdfSectionsActivitiesPercentage } from './activities/percentage';

import { ReportsPdfSectionsActivitiesChart } from './activities/chart';

export function ReportPdfSectionAreaContent({
  reportData,
  t,
  areas,
  filterArea,
}: any) {
  let showFinance = true;
  if (!hasSomeFinance(areas)) {
    showFinance = false;
  }

  let showTargets = true;
  const targets = getTargets(areas);
  if (targets.length === 0) showTargets = false;

  return (
    <View>
      {/* finance + targets */}
      {showFinance && (
        <>
          <ReportsPdfUiSectionTitle
            primary={t('Finance')}
            reportData={reportData}
          />
          <ReportsPdfSectionsFinance
            t={t}
            reportData={reportData}
            areas={areas}
          />
        </>
      )}
      {showFinance && showTargets && <View style={{ height: '10px' }} />}
      {showTargets && (
        <>
          <ReportsPdfUiSectionTitle
            primary={t('Targets')}
            reportData={reportData}
          />
          <ReportsPdfSectionsTarget
            t={t}
            dashboard={reportData.dashboard}
            reportData={reportData}
            areas={areas}
          />
        </>
      )}

      {/* activities */}

      <ReportsPdfUiSectionTitle
        primary={t('Progress')}
        reportData={reportData}
      />

      {/* milestones */}
      <ReportsPdfSectionsActivitiesMilestones
        t={t}
        dashboard={reportData.dashboard}
        areas={areas}
      />

      <ReportsPdfUiSectionSubTitle>
        {t('Activities')} &amp; {t('Issues')}
      </ReportsPdfUiSectionSubTitle>

      {/* percentage complete */}
      {reportData.dashboard.enableActivityPercentage && (
        <ReportsPdfSectionsActivitiesPercentage
          t={t}
          dashboard={reportData.dashboard}
          area={reduceSummaries({
            priorityAreas: areas,
          })}
        />
      )}

      {/* chart */}
      {!filterArea ? (
        <ReportsPdfSectionsActivitiesAreasCharts
          t={t}
          dashboard={reportData.dashboard}
          areas={areas}
        />
      ) : filterArea.startsWith('pa-') ? (
        <ReportsPdfSectionsActivitiesAreasCharts
          t={t}
          dashboard={reportData.dashboard}
          areas={areas[0]?.interventions}
        />
      ) : (
        <View>
          <ReportsPdfSectionsActivitiesChart
            t={t}
            dashboard={reportData.dashboard}
            area={areas[0]}
            boxWidth="100%"
            chartIndex={0}
          />
        </View>
      )}
      <View style={{ height: '20px' }} />

      {/* list */}
      <ReportsPdfSectionsActivitiesLists
        t={t}
        reportData={reportData}
        areas={areas}
      />
    </View>
  );
}
