import JSpdf from 'jspdf';
import autoTable, { CellDef, RowInput } from 'jspdf-autotable';

import { ExportCsv } from '@material-table/exporters';
import { Column } from '@material-table/core';

// @ts-ignore
import DataManager from '@material-table/core/dist/utils/data-manager';

export function ExportPdf({
  columns,
  data = [],
  filename = 'data',
  dashboard,
  t,
}: {
  columns: any;
  data: any[];
  filename: string;
  dashboard?: any;
  t: any;
}) {
  const headFillColor = dashboard?.colorPalette?.primary?.bgcolor || '#007681';
  const headTextColor = dashboard?.colorPalette?.primary?.textColor || '#fff';

  try {
    const cellWidth: any = {
      id: 20,
      title: 200,
      status: 60,
      'group.title': 60,
      'intervention.priorityArea.name': 80,
      'intervention.name': 80,
      'assignee.name': 80,
    };
    const finalData: RowInput[] = data.map((row) =>
      columns.map(
        (col: any, i: number) =>
          ({
            content:
              col.field === 'status'
                ? t(dashboard?.metaStatusColors[row[i]]?.text)
                : row[i] || '',
            styles: {
              minCellWidth: cellWidth[col.field],
              fillColor:
                col.field === 'status'
                  ? dashboard?.metaStatusColors[row[i]]?.bgcolor
                  : undefined,
              textColor:
                col.field === 'status'
                  ? dashboard?.metaStatusColors[row[i]]?.textColor
                  : '#000',
              fontSize: ['status', 'group.title', 'type'].includes(col.field)
                ? 8
                : 10,
              halign: col.field === 'status' ? 'center' : 'left',
              valign: col.field === 'status' ? 'middle' : 'top',
            },
          } as CellDef)
      )
    );

    const unit = 'pt';
    const size = 'A4';
    const orientation = 'landscape';
    const doc = new JSpdf(orientation, unit, size);

    doc.setFontSize(15);
    const { pageSize } = doc.internal;
    const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
    const lines = doc.splitTextToSize(filename, pageWidth - 80, {});
    doc.text(lines, 40, 40);

    autoTable(doc, {
      startY: lines.length * (15 + 1.15) + 40 + 5,
      head: [columns.map((col: any) => col.title)],
      body: finalData,
      rowPageBreak: 'auto',
      theme: 'grid',
      headStyles: {
        fillColor: headFillColor,
        textColor: headTextColor,
      },
    });

    doc.save(`${filename}.pdf`);
  } catch (err) {
    console.error(
      `exporting pdf : unable to import 'jspdf-autotable' : ${err}`
    );
  }
}

export function trackerTableUtilsExport({
  exportFileName,
  isCsv = false,
  dashboard,
  t,
  columns,
  renderData,
  tableData,
}: {
  exportFileName: string;
  isCsv?: boolean;
  dashboard?: any;
  t: any;
  columns: Column<any>[];
  renderData?: any[];
  tableData: {
    searchedData: any[];
    filteredData: any[];
    groupedData: any[];
    selectedData: any[];
  };
}) {
  const dataManager = new DataManager();

  const exportData = tableData.searchedData.map((rowData: any) => {
    const updatedRowData = columns.map((columnDef: any) => {
      if (typeof columnDef.customExport === 'function') {
        return columnDef.customExport(rowData);
      }
      return dataManager.getFieldValue(rowData, columnDef);
    });
    return [
      ...updatedRowData,
      rowData.isMilestone
        ? t('Milestone')
        : rowData.isIssue
        ? t('Open Issue')
        : t('Activity'),
    ];
  });

  const exportColumns = [...columns, { title: t('Type'), field: 'type' }];

  const exportContent = {
    cols: exportColumns,
    data: exportData,
  };

  let flaggedColIndex = -1;
  const exportCols = exportContent.cols.filter((c: any, i: number) => {
    if (c.field === 'flagged') {
      flaggedColIndex = i;
      return false;
    }
    return true;
  });

  let exportDatas = structuredClone(exportContent.data);
  if (flaggedColIndex !== -1) {
    exportDatas = [
      ...exportDatas.map((d: any) => {
        d.splice(flaggedColIndex, 1);
        return d;
      }),
    ];
  }

  if (isCsv) {
    ExportCsv(exportCols, exportDatas, exportFileName);
  } else {
    ExportPdf({
      columns: exportCols,
      data: exportDatas,
      filename: exportFileName,
      dashboard,
      t,
    });
  }
}
