import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';

import Button from '@mui/material/Button';
import DoneIcon from '@mui/icons-material/Done';

import { DialogModal } from '../../../../components/dialog-modal';
import { DashboardContext } from '../../../../_lib/context/dashboard-context';
import { CONTENT_UPDATE_ACCEPT } from '../../../../_lib/graphql/mutations';
import { logError } from '../../../../_lib/error';
import { CONTENT_QUERY } from '../../../../_lib/graphql/queries';
import { ContentDialogContext } from '../../../../_lib/context/content-dialog-context';

export function AcceptContentUpdate({ update }: any) {
  const { t } = useTranslation();

  const { setSnackbarOpen, setSnackbarIsError } =
    React.useContext(DashboardContext);
  const { contentId } = React.useContext(ContentDialogContext);

  const [confirmDialogOpen, setConfirmDialogOpen] = React.useState(false);

  const [acceptUpdateMutation, { loading }] = useMutation(
    CONTENT_UPDATE_ACCEPT,
    {
      update: (cache, { data }) => {
        cache.writeQuery({
          query: CONTENT_QUERY,
          data: { content: data.contentUpdateAccept.content },
          variables: { id: contentId },
        });
      },
    }
  );

  const acceptActivityUpdate = () => {
    acceptUpdateMutation({ variables: { id: update.id } }).catch((e) => {
      setSnackbarIsError(true);
      setSnackbarOpen(true);
      logError(e);
    });
  };

  return (
    <>
      <DialogModal
        loading={loading}
        show={confirmDialogOpen}
        onSubmit={acceptActivityUpdate}
        onDismiss={() => setConfirmDialogOpen(false)}
        type=""
        title={t('Accept this update?')}
      />

      <Button
        variant="outlined"
        size="small"
        startIcon={<DoneIcon />}
        onClick={() => setConfirmDialogOpen(true)}
        disabled={update.status === 'OBSOLETE'}
        color="primary"
      >
        {t('Accept')}
      </Button>
    </>
  );
}
