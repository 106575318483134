import React from 'react';

import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import Stack from '@mui/material/Stack';
import Skeleton from '@mui/material/Skeleton';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

import { ReactErrorComponent } from '../../_lib/react-error';
import { useDashboardContext } from '../../_lib/context/dashboard-context';
import { useLocalStorage } from '../../_utils/hooks/use-local-storage';

import { PlanningPriorityAreaFormListHeader } from './list-header';
import { PlanningLayoutTabPanel } from '../layout/tab-panel';
import { PlanningPriorityAreaFormDetail } from './detail';
import { PlanningPriorityAreaFormAddDialog } from './add-dialog';
import { PlanningPriorityAreaFormDraggable } from './draggable/container';

import { PlanningLayoutLoadBreadcrumbs } from '../layout/load-breadcrumbs';
import { useAreasPlanning } from '../../_utils/hooks/queries/use-areas-planning';

export function PlanningPriorityAreaFormList() {
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();

  const { priorityAreaId } = useParams<any>();

  const { dashboard } = useDashboardContext();

  const [isArchivedTab, setIsArchivedTab] = React.useState(0);
  const [searchTerm, setSearchTerm] = React.useState('');

  const [ogPas, setOgPas] = React.useState<any[]>([]);
  const [filteredPAs, setFilteredPAs] = React.useState<any>([]);
  const [paBreadcrumbs, setPaBreadcrumbs] = React.useState<any>(null);

  const [openAddDialog, setOpenAddDialog] = React.useState(false);

  const [openCollapse, setOpenCollapse] = useLocalStorage(
    'planning-priority-area-form-list-collapse',
    []
  );

  // ----------------------------------------------------------------------------------------------------------------------

  const variables = React.useMemo(
    () => ({
      parentId: dashboard.enableAreaLevels ? priorityAreaId : undefined,
      filterIsArchived: isArchivedTab === 1,
    }),
    [priorityAreaId, dashboard.enableAreaLevels, isArchivedTab]
  );

  const { data, loading, error } = useAreasPlanning('priorityArea', variables);

  // ----------------------------------------------------------------------------------------------------------------------

  // active and archived tabs

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setIsArchivedTab(newValue);
  };

  // ----------------------------------------------------------------------------------------------------------------------
  // ----------------------------------------------------------------------------------------------------------------------
  // memo
  // ----------------------------------------------------------------------------------------------------------------------

  // filter by id
  const filterId = React.useMemo(() => {
    const params = new URLSearchParams(location.search);
    const id = params.get('id');
    return id;
  }, [location]);

  // ----------------------------------------------------------------------------------------------------------------------
  // ----------------------------------------------------------------------------------------------------------------------
  // effects
  // ----------------------------------------------------------------------------------------------------------------------

  // data
  React.useEffect(() => {
    if (data) {
      setOgPas(data.priorityAreas.items);
    }
  }, [data]);

  // set the collapse
  // uncollapse if navigated to
  React.useEffect(() => {
    const params = new URLSearchParams(location.search);
    const id = params.get('id');

    setOpenCollapse((prev: any) => {
      if (id && !prev.includes(id)) {
        return [...prev, id];
      }
      return prev;
    });
  }, [location.search, setOpenCollapse]);

  // max five
  React.useEffect(() => {
    if (openCollapse.length > 5) {
      setOpenCollapse((prev: any) => prev.slice(1));
    }
  }, [openCollapse, setOpenCollapse]);

  // ----------------------------------------------------------------------------------------------------------------------

  // ----------------------------------------------------------------------------------------------------------------------

  if (error) return <ReactErrorComponent error={error} />;

  if (loading || !data) {
    return (
      <Stack spacing={3} sx={{ my: 3 }}>
        {[0, 1, 2].map((index) => (
          <Skeleton key={index} variant="rectangular" height={100} />
        ))}
      </Stack>
    );
  }

  // ----------------------------------------------------------------------------------------------------------------------

  return (
    <PlanningLayoutTabPanel
      index={1}
      title={`${t('Planning')}`}
      subheader={`${t('Set up your main areas')}`}
      breadcrumbs={
        !priorityAreaId
          ? [
              {
                label: dashboard.priorityAreaName,
                to: `${t('/planning')}/${dashboard.priorityAreasSlug}`,
              },
            ]
          : paBreadcrumbs?.breadcrumbs
      }
      actions={
        !filterId ? (
          <Button
            startIcon={<AddCircleOutlineIcon />}
            variant="contained"
            disableElevation
            onClick={() => setOpenAddDialog(true)}
          >
            {t('Add')}
          </Button>
        ) : (
          <Button
            variant="contained"
            disableElevation
            onClick={() => history.push(location.pathname)}
          >
            {t('View All')}
          </Button>
        )
      }
    >
      {/* ---------------------------------------------------------------------------------------------------------------------- */}

      {/* add dialog */}
      <PlanningPriorityAreaFormAddDialog
        open={openAddDialog}
        setOpen={setOpenAddDialog}
        areaName={
          paBreadcrumbs?.priorityArea?.levelNext?.name ||
          dashboard.priorityAreaName
        }
      />

      {/* ---------------------------------------------------------------------------------------------------------------------- */}

      {/* priority areas */}

      {/* header */}
      <PlanningPriorityAreaFormListHeader
        priorityArea={paBreadcrumbs?.priorityArea}
        loading={loading}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        ogPas={ogPas}
        setFilteredPAs={setFilteredPAs}
        setOpenCollapse={setOpenCollapse}
      />

      {/* put this is tab for active and archived */}
      {dashboard.enableAreaArchive && (
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            value={isArchivedTab}
            onChange={handleTabChange}
            aria-label="basic tabs example"
          >
            <Tab label={t('Active')} id="area-tabs-0" />
            <Tab label={`${t('Archived')}`} id="area-tabs-1" />
          </Tabs>
        </Box>
      )}

      <PlanningPriorityAreaFormDraggable
        details={filteredPAs}
        setDetails={setFilteredPAs}
        filtered={!!filterId || !!searchTerm}
      >
        {filteredPAs?.map((pa: any, index: any) => (
          <PlanningPriorityAreaFormDetail
            key={pa.id}
            priorityAreaInitial={pa}
            filtered={!!filterId || !!searchTerm}
            index={index}
            openCollapse={openCollapse}
            setOpenCollapse={setOpenCollapse}
            listVariables={variables}
          />
        ))}
      </PlanningPriorityAreaFormDraggable>

      {/* no filtered priotity ares */}
      {!loading && !filteredPAs?.length && (
        <Box sx={{ mt: 3, mb: 5, textAlign: 'center', color: 'grey.700' }}>
          <Typography variant="h6">{t('No results')}</Typography>
          <Typography variant="body2">{t('No results found')}</Typography>
        </Box>
      )}

      {/* no priority areas */}
      {data?.priorityAreas?.items.length === 0 && isArchivedTab !== 1 && (
        <Box sx={{ p: 4, my: 3, textAlign: 'center', color: 'grey.700' }}>
          <Typography variant="h5">{t('Nothing to show')}</Typography>
          <Typography variant="body1" sx={{ mb: 1 }}>
            {t('Add to get started')}
          </Typography>
        </Box>
      )}

      {/* add button */}
      {!!data && !filterId && isArchivedTab !== 1 && (
        <Box sx={{ textAlign: 'center' }}>
          <Button
            variant="contained"
            disableElevation
            onClick={() => setOpenAddDialog(true)}
            startIcon={<AddCircleOutlineIcon />}
          >
            {t('Add')}:{' '}
            {paBreadcrumbs?.priorityArea
              ? paBreadcrumbs?.priorityArea.levelNext?.name
              : dashboard.priorityAreaName}
          </Button>
        </Box>
      )}

      {/* ---------------------------------------------------------------------------------------------------------------------- */}
      {/* other updates */}
      {/* ---------------------------------------------------------------------------------------------------------------------- */}
      {!!priorityAreaId && (
        <PlanningLayoutLoadBreadcrumbs setPriorityArea={setPaBreadcrumbs} />
      )}
    </PlanningLayoutTabPanel>
  );
}
