import { useTranslation } from 'react-i18next';
import { Card, CardContent, Typography } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

export function NoMilestones() {
  const { t } = useTranslation();

  return (
    <Card variant="outlined" sx={{ my: 3 }}>
      <CardContent
        sx={{
          px: 3,
          py: 4,
          fontStyle: 'italic',
          textAlign: 'center',
          color: 'grey.500',
        }}
      >
        <InfoOutlinedIcon fontSize="large" />
        <Typography variant="h5" sx={{ fontWeight: 400 }}>
          {t('No milestones')}...
        </Typography>
        <Typography variant="body1">
          {t('Add some now to get started')}
        </Typography>
      </CardContent>
    </Card>
  );
}
