import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';

import MuiCardHeader, {
  CardHeaderProps as MuiCardHeaderProps,
} from '@mui/material/CardHeader';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import TableChartIcon from '@mui/icons-material/TableChart';
import Typography from '@mui/material/Typography';
import { useDashboardContext } from '../../../../_lib/context/dashboard-context';

type CardHeaderProps = MuiCardHeaderProps & {
  headerItems: {
    title: string;
    subtitle: string;
    areaUrl: string;
  };
};

function CardHeader({ headerItems }: CardHeaderProps) {
  const { t } = useTranslation();

  const { dashboard } = useDashboardContext();

  // --------------------------------------------------------------------------------------------------------------------

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorEl);

  // --------------------------------------------------------------------------------------------------------------------
  // --------------------------------------------------------------------------------------------------------------------
  // handlers
  // --------------------------------------------------------------------------------------------------------------------

  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  // --------------------------------------------------------------------------------------------------------------------

  return (
    <MuiCardHeader
      sx={{ m: 0, pb: 0 }}
      title={
        <Typography
          sx={{
            color: 'grey.600',
            fontWeight: '500',
            fontSize: '1.5rem',
            py: 1,
          }}
        >
          {headerItems?.title || ''}
        </Typography>
      }
      subheader={
        <Typography
          sx={{ fontSize: '2rem', letterSpacing: '0.5px', mb: 0, p: 0 }}
        >
          {headerItems?.subtitle || ''}
        </Typography>
      }
      action={
        <>
          {(dashboard.mode === 'SUPRANATIONAL'
            ? window.location.pathname === '/'
            : true) && (
            <IconButton
              aria-label="actions"
              aria-controls="priority-area-card-menu"
              aria-haspopup="true"
              aria-expanded={openMenu ? 'true' : undefined}
              onClick={handleMenuClick}
              sx={{ displayPrint: 'none' }}
            >
              <MoreVertIcon />
            </IconButton>
          )}

          <Menu
            id="priority-area-card-menu"
            anchorEl={anchorEl}
            open={openMenu}
            onClose={handleMenuClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            {/* activity tracker */}
            <MenuItem
              component={RouterLink}
              to={`../..${headerItems?.areaUrl}/${t('tracker')}`}
              target="_blank"
            >
              <ListItemIcon>
                <TableChartIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>{t('Open Activity Tracker')}</ListItemText>
            </MenuItem>
          </Menu>
        </>
      }
    />
  );
}

export default CardHeader;
