import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';

import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import AssignmentIcon from '@mui/icons-material/Assignment';
import SettingsIcon from '@mui/icons-material/Settings';
import AnalyticsIcon from '@mui/icons-material/Analytics';

import { DashboardContext } from '../../_lib/context/dashboard-context';
import { PlanningContext } from '../../_lib/context/planning-context';

export function PlanningLayoutTabs() {
  const { t } = useTranslation();

  const location = useLocation();
  const history = useHistory();

  const { dashboard } = React.useContext(DashboardContext);
  const { tab, setTab } = React.useContext(PlanningContext);

  const handleTabChange = React.useCallback(
    (event: React.SyntheticEvent, newTab: number) => {
      const tabRoutes = [
        t(`/settings`),
        `${t('/planning')}/${
          !dashboard.enableAreaLevels
            ? dashboard.priorityAreasSlug
            : dashboard.areaLevels[0].slug
        }`,
        t(`/analytics`),
      ];
      history.push(tabRoutes[newTab]);
    },
    [
      dashboard.areaLevels,
      dashboard.enableAreaLevels,
      dashboard.priorityAreasSlug,
      history,
      t,
    ]
  );

  const a11yProps = React.useCallback((index: number) => {
    return {
      id: `planning-tab-${index}`,
      'aria-controls': `planning-tabpanel-${index}`,
    };
  }, []);

  // -----------------------------------------------------------------------------------------------------------------
  // -----------------------------------------------------------------------------------------------------------------
  // effects
  // -----------------------------------------------------------------------------------------------------------------

  React.useEffect(() => {
    if (
      location.pathname.startsWith(
        `${t('/planning')}/${
          !dashboard.enableAreaLevels
            ? dashboard.priorityAreasSlug
            : dashboard.areaLevels[0].slug
        }`
      )
    ) {
      setTab(1);
    } else if (location.pathname.startsWith(t('/settings'))) {
      setTab(0);
    } else if (location.pathname.startsWith(t('/analytics'))) {
      setTab(2);
    }
  }, [
    location,
    setTab,
    dashboard.priorityAreasSlug,
    dashboard.enableAreaLevels,
    t,
    dashboard.areaLevels,
  ]);

  // -----------------------------------------------------------------------------------------------------------------

  return (
    <Box
      sx={{
        borderBottom: 1,
        borderColor: 'divider',
        bgcolor: 'grey.100',
        position: 'sticky',
        top: 0,
        zIndex: 999,
      }}
    >
      <Tabs
        value={tab || 0}
        onChange={handleTabChange}
        aria-label="planning tabs"
        variant="scrollable"
        scrollButtons="auto"
        sx={{ minHeight: '0' }}
      >
        <Tab
          icon={<SettingsIcon />}
          iconPosition="start"
          label={t('Dashboard Settings')}
          {...a11yProps(0)}
          sx={{ minHeight: '48px' }}
        />
        <Tab
          icon={<AssignmentIcon />}
          iconPosition="start"
          label={t('Planning')}
          {...a11yProps(1)}
          sx={{ minHeight: '48px' }}
        />
        {dashboard.enableAnalytics && !dashboard.inPlanning ? (
          <Tab
            icon={<AnalyticsIcon />}
            iconPosition="start"
            label={t('Analytics')}
            {...a11yProps(2)}
            sx={{ minHeight: '48px' }}
          />
        ) : null}
        {/* <Tab label={t('Resources')} {...a11yProps(2)} /> */}
      </Tabs>
    </Box>
  );
}
