import React from 'react';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';

import { useSummary } from '../../../_utils/hooks/queries/use-summary';

import { AreaSummaryBarChart } from '../bar-chart';

export function AreaSummaryCardMilestones({ area, filterUnstarted }: any) {
  const areaType =
    area?.__typename === 'PriorityAreaType'
      ? 'priorityArea'
      : area?.__typename === 'InterventionType'
      ? 'intervention'
      : undefined;

  const { t } = useTranslation();

  const { data, loading, error } = useSummary(
    'milestonesSummary',
    {
      priorityAreaId: areaType === 'priorityArea' ? area.id : undefined,
      interventionId: areaType === 'intervention' ? area.id : undefined,
    },
    areaType
  );

  const milestonesSummary = React.useMemo(
    () =>
      areaType
        ? filterUnstarted(data?.[areaType]?.summary?.milestonesSummary)
        : undefined,
    [areaType, data, filterUnstarted]
  );

  if (error) return null;

  if (loading || !data)
    return <Skeleton variant="rectangular" height={120} sx={{ mb: 2 }} />;

  if (!areaType) return null;
  if (!milestonesSummary?.total) return null;

  return (
    <Box sx={{ my: 1 }}>
      <Typography variant="body2" sx={{ mb: 0.5 }}>
        {t('Milestones')}: {milestonesSummary?.total}
      </Typography>

      <AreaSummaryBarChart
        height="24px"
        borderRadius="3px"
        contentSummary={milestonesSummary}
      />
    </Box>
  );
}
