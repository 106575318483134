import React from 'react';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';

import { ContentDialogSectionTitle } from '../section-title';
import { logError } from '../../../_lib/error';

import { DashboardContext } from '../../../_lib/context/dashboard-context';
import { ContentDialogContext } from '../../../_lib/context/content-dialog-context';

import { CONTENT_UPDATE } from '../../../_lib/graphql/mutations';
import { getGraphQLDate } from '../../../_utils/general-utils';

import { DialogModal } from '../../../components/dialog-modal';
import { CONTENT_QUERY } from '../../../_lib/graphql/queries';

export function ContentDialogActionsState() {
  const { t } = useTranslation();

  const { dashboard, setSnackbarOpen, setSnackbarIsError } =
    React.useContext(DashboardContext);
  const { content, contentId } = React.useContext(ContentDialogContext);

  const [realState, setRealState] = React.useState('ongoing');
  const [loading, setLoading] = React.useState(false);

  const [dialogConfirmOpen, setDialogConfirmOpen] = React.useState(false);
  const [changeValue, setChangeValue] = React.useState('');

  const [updateContenMutation] = useMutation(CONTENT_UPDATE, {
    update: (cache, { data }) => {
      cache.writeQuery({
        query: CONTENT_QUERY,
        data: { content: data.contentUpdate.content },
        variables: { id: contentId },
      });
    },
  });

  // -------------------------------------------------------------------------------------------------
  // -------------------------------------------------------------------------------------------------
  // handlers
  // -------------------------------------------------------------------------------------------------

  const handleChange = (newState: any) => {
    if (content.status === newState) {
      return Promise.resolve();
    }

    setLoading(true);
    const initialState = content.status;

    return updateContenMutation({
      variables: {
        id: content.id,
        archive:
          newState === 'archived'
            ? true
            : initialState === 'archived'
            ? false
            : undefined,
        completionDate:
          newState === 'completed' ? getGraphQLDate(new Date()) : undefined,
        unComplete:
          initialState === 'completed' &&
          ['ongoing', 'unstarted'].includes(newState)
            ? true
            : undefined,
        start:
          newState === 'ongoing'
            ? true
            : newState === 'unstarted'
            ? false
            : undefined,
      },
    })
      .catch((err) => {
        logError(err);
        setSnackbarIsError(true);
      })
      .finally(() => {
        setSnackbarOpen(true);
        setLoading(false);
      });
  };

  const buttons = {
    unstarted: {
      label: t('Start'),
      changeValue: 'ongoing',
    },
    ongoing: {
      label: t('Finish'),
      changeValue: 'completed',
    },
    overdue: {
      label: t('Finish'),
      changeValue: 'completed',
    },
    completed: {
      label: t('Archive'),
      changeValue: 'archived',
    },
    archived: {
      label: t('Unarchive'),
      changeValue: 'unarchive',
    },
  } as any;

  // -------------------------------------------------------------------------------------------------
  // -------------------------------------------------------------------------------------------------
  // effects
  // -------------------------------------------------------------------------------------------------

  // real state
  React.useEffect(() => {
    setRealState(
      content.status === 'overdue'
        ? !content.startedAt
          ? 'unstarted'
          : 'ongoing'
        : content.status === 'unarchive'
        ? 'archived'
        : content.status
    );
  }, [content.status, content.startedAt, content.startDate]);

  // -------------------------------------------------------------------------------------------------

  return [
    'unstarted',
    'ongoing',
    'archived',
    'completed',
    'overdue',
    'unarchive',
  ].includes(realState) ? (
    <Box
      sx={{
        display: 'flex',
        mb: 2,
        alignItems: 'center',
        justifyContent: { xs: 'space-between', sm: 'flex-start' },
      }}
    >
      {/* confirm save dialog */}
      <DialogModal
        open={dialogConfirmOpen}
        onDismiss={() => setDialogConfirmOpen(false)}
        onSubmit={() => {
          handleChange(changeValue).then(() => {
            setDialogConfirmOpen(false);
          });
        }}
        loading={loading}
        title={t('Confirm Save')}
        message={<Typography>{t('Are you sure you want to save?')}</Typography>}
      />

      <ContentDialogSectionTitle sx={{ mb: 0 }}>
        {t('State')}
      </ContentDialogSectionTitle>
      <Box sx={{ ml: { xs: 2, sm: 3 } }}>
        <Button
          size="small"
          variant="contained"
          disableElevation
          onClick={() => {
            setChangeValue(buttons[realState]?.changeValue);
            if (!dashboard.enableActivityConfirmSave) {
              handleChange(buttons[realState]?.changeValue);
            } else {
              setDialogConfirmOpen(true);
            }
          }}
          disabled={loading || content.access !== 'EDIT'}
        >
          {buttons[realState]?.label ?? t('Archive')}
        </Button>
        <Select
          value={realState}
          onChange={(e) => {
            setChangeValue(e.target.value);
            if (!dashboard.enableActivityConfirmSave) {
              handleChange(e.target.value);
            } else {
              setDialogConfirmOpen(true);
            }
          }}
          size="small"
          sx={{ minWidth: '150px', ml: 2 }}
          variant="standard"
          disabled={loading || content.access !== 'EDIT'}
        >
          <MenuItem value="unstarted">{t('Unstarted')}</MenuItem>
          <MenuItem value="ongoing">{t('Ongoing')}</MenuItem>
          <MenuItem value="completed">{t('Completed')}</MenuItem>
          <MenuItem value="archived">{t('Archived')}</MenuItem>
        </Select>
      </Box>
    </Box>
  ) : null;
}
