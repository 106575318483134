import React from 'react';
import { useTranslation } from 'react-i18next';

import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import HourglassBottomTwoToneIcon from '@mui/icons-material/HourglassBottomTwoTone';

import { ContentDialogContext } from '../../../_lib/context/content-dialog-context';

import { ContentDialogBlockersDetailText } from './detail/text';

function BlocksDetail({ block }: any) {
  // --------------------------------------------------------------------------------------------------------------------
  // --------------------------------------------------------------------------------------------------------------------
  // effects
  // --------------------------------------------------------------------------------------------------------------------

  // --------------------------------------------------------------------------------------------------------------------

  return (
    <Box key={block.id} sx={{ display: 'inline-flex' }}>
      <HourglassBottomTwoToneIcon
        fontSize="small"
        color="info"
        sx={{ m: 0.5, mr: 1 }}
      />
      <Box>
        <Box
          component="div"
          sx={{
            display: 'inline-flex',
            border: 1,
            borderColor: 'grey.400',
            px: 0.5,
            borderRadius: 0.2,
            mb: 1,
          }}
        >
          <ContentDialogBlockersDetailText
            blocker={{ text: `#${block?.content.id}` }}
          />
        </Box>
      </Box>
    </Box>
  );
}

export function ContentDialogBlockersBlocks() {
  const { t } = useTranslation();

  const { content } = React.useContext(ContentDialogContext);

  return (
    (content.blocks?.length > 0 && (
      <>
        <Typography variant="body1">{t('Blocks these')}:</Typography>
        <Stack spacing={1} sx={{ py: 1 }}>
          {content.blocks.map((block: any) => (
            <BlocksDetail key={block.id} block={block} />
          ))}
        </Stack>
      </>
    )) ||
    null
  );
}
