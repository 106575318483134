import { View, Text } from '@react-pdf/renderer';

export function ReportsPdfUiSectionTitle({
  primary,
  reportData,
}: {
  primary: string;
  reportData: any;
}) {
  const { colorPalette } = reportData.dashboard;

  return (
    <View
      style={{
        width: '100%',
        backgroundColor: colorPalette.primary.bgcolor,
        textAlign: 'center',
        padding: '5px',
        margin: '15px 0',
      }}
      wrap={false}
    >
      <Text
        style={{
          color: colorPalette.primary.textColor,
          fontWeight: 'bold',
        }}
      >
        {primary}
      </Text>
    </View>
  );
}

export function ReportsPdfUiSectionSubTitle({ children }: any) {
  return (
    <View
      style={{
        width: '100%',
        textAlign: 'center',
        padding: '5px',
        margin: '0 0 10px',
      }}
      wrap={false}
    >
      <Text
        style={{
          fontWeight: 'bold',
        }}
      >
        {children}
      </Text>
    </View>
  );
}
