import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';

import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import Container from '@mui/material/Container';

import { DashboardBreadcrumb } from '../breadcrumbs/dashboard-breadcrumb';
import { ReportsBreadcrumb } from '../breadcrumbs/reports-breadcrumb';
import { ReactErrorComponent } from '../_lib/react-error';

export function LayoutDashboard({ children }: { children: React.ReactNode }) {
  const { t } = useTranslation();
  const location = useLocation();

  const isReportScreens = React.useMemo(
    () => location.pathname.startsWith(t('/reports')),
    [location.pathname, t]
  );
  const isPlanningScreens = React.useMemo(
    () =>
      location.pathname.startsWith(t('/settings')) ||
      location.pathname.startsWith(t('/planning')),
    [location.pathname, t]
  );

  return (
    <div>
      {/* breadcrumb */}
      <Container>
        {!isReportScreens && !isPlanningScreens && <DashboardBreadcrumb />}
        {isReportScreens && <ReportsBreadcrumb />}
      </Container>
      <ErrorBoundary FallbackComponent={ReactErrorComponent}>
        {children}
      </ErrorBoundary>
    </div>
  );
}
