import React from 'react';

const OnlineStatusContext = React.createContext(true);

export function ProvideOnlineStatus({ children }: any) {
  const [onlineStatus, setOnlineStatus] = React.useState<boolean>(true);

  React.useEffect(() => {
    window.addEventListener('offline', () => {
      setOnlineStatus(false);
    });
    window.addEventListener('online', () => {
      setOnlineStatus(true);
    });

    return () => {
      window.removeEventListener('offline', () => {
        setOnlineStatus(false);
      });
      window.removeEventListener('online', () => {
        setOnlineStatus(true);
      });
    };
  }, []);

  return (
    <OnlineStatusContext.Provider value={onlineStatus}>
      {children}
    </OnlineStatusContext.Provider>
  );
}

export const useOnlineStatus = () => {
  return React.useContext(OnlineStatusContext);
};
