import React from 'react';
import { useTranslation } from 'react-i18next';
import { gql, useQuery } from '@apollo/client';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import Link from '@mui/material/Link';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import { getApiURL } from '../../../../_utils/general-utils';

import { TwoFactorVerifyCode } from './verify-code';
import { SubscribeToMore } from '../../../subscribe-to-more';
import { logError } from '../../../../_lib/error';

const OTP_DEVICE_FRAGMENT = gql`
  fragment OtpDeviceFragment on TOTPDeviceType {
    id
    name
    confirmed
    qrCode
    key
  }
`;

const OTP_DEVICE_QUERY = gql`
  query OtpDeviceQuery {
    otpDevice {
      ...OtpDeviceFragment
    }
  }
  ${OTP_DEVICE_FRAGMENT}
`;

const OTP_DEVICE_SUBSCRIPTION = gql`
  subscription OtpDeviceSubscription {
    otpDevice {
      ...OtpDeviceFragment
    }
  }
  ${OTP_DEVICE_FRAGMENT}
`;

export function QRCodeView({ disabled, loading, setLoading, onSuccess }: any) {
  const { t } = useTranslation();

  const {
    data,
    loading: loadingDevice,
    error,
    subscribeToMore,
  } = useQuery(OTP_DEVICE_QUERY);

  // ---------------------------------------------------------------------------------------------------------------------
  // ---------------------------------------------------------------------------------------------------------------------
  // handlers
  // ---------------------------------------------------------------------------------------------------------------------

  // ---------------------------------------------------------------------------------------------------------------------
  // effects
  // ---------------------------------------------------------------------------------------------------------------------

  // error
  React.useEffect(() => {
    if (error) logError(error);
  }, [error]);

  React.useLayoutEffect(() => {
    document.getElementById('verify-qr')?.scrollIntoView({ block: 'center' });
  }, [data?.otpDevice]);

  // ---------------------------------------------------------------------------------------------------------------------

  return (
    <>
      <Stack
        sx={{
          alignSelf: 'center',
          justifyContent: 'center',
          mt: 1,
        }}
        spacing={2}
      >
        <Typography variant="body2">
          {t(
            'Scan the QR Code below with your authenticator app and enter the code displayed to verify.'
          )}
          <br />
          <Typography variant="caption">
            <Link
              href="https://www.consumerreports.org/digital-security/use-authentication-apps-for-multifactor-security-a1020135759/"
              target="_blank"
            >
              <InfoOutlinedIcon fontSize="inherit" /> {t('Authenticator Apps')}
            </Link>
          </Typography>
        </Typography>

        {/* qr code */}
        {loadingDevice ? (
          <Box>
            <CircularProgress />
          </Box>
        ) : (
          <>
            <Box
              component="img"
              border={1}
              sx={{
                width: '200px',
                height: 'auto',
                objectFit: 'cover',
                marginBlock: 1,
              }}
              alt="2FA QR Code"
              src={
                data?.otpDevice
                  ? `${getApiURL()}/${data?.otpDevice.qrCode}`
                  : ''
              }
            />
            <Typography variant="caption">
              {t(
                'If you are unable to scan the QR code, enter the following key'
              )}
              :
              <br />
              {data?.otpDevice ? data.otpDevice.key : ''}
            </Typography>
          </>
        )}

        {/* verify code */}
        <TwoFactorVerifyCode
          onSuccess={onSuccess}
          disabled={disabled}
          loading={loading}
          setLoading={setLoading}
        />
      </Stack>

      {/* subscribe to more */}
      {!!data && (
        <SubscribeToMore
          subscribeToMore={() =>
            subscribeToMore({
              document: OTP_DEVICE_SUBSCRIPTION,
              updateQuery: (prev, { subscriptionData }) => {
                if (!subscriptionData.data) return prev;
                return subscriptionData.data;
              },
            })
          }
        />
      )}
    </>
  );
}
