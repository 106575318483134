import React from 'react';
import { isEqual } from 'lodash';
import Typography from '@mui/material/Typography';

import { useDashboardContext } from '../../../_lib/context/dashboard-context';
import { useContentDialogContext } from '../../../_lib/context/content-dialog-context';

import { getStringDateTime } from '../../../_utils/general-utils';

export function ContentDialogContentUpdateUpdate({ updateId, children }: any) {
  const {
    dashboard: { language },
  } = useDashboardContext();
  const { content } = useContentDialogContext();

  const [update, setUpdate] = React.useState(
    content.updates.find((u: any) => u.id === updateId)
  );

  React.useEffect(() => {
    const contentUpdate = content.updates.find((u: any) => u.id === updateId);
    if (!isEqual(contentUpdate, update)) {
      setUpdate(contentUpdate);
    }
  }, [content, update, updateId]);

  return update.automatic ? (
    <>
      <Typography variant="body1">
        <strong>{`${update.author.name}:`}</strong> {update.text}
      </Typography>
      <Typography variant="caption" color="textSecondary">
        {getStringDateTime(update.postDatetime, language)}
      </Typography>
    </>
  ) : (
    children
  );
}
