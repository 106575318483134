import React from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorBoundary } from 'react-error-boundary';

import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';

import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded';

import { useDashboardContext } from '../../../../_lib/context/dashboard-context';
import { ReactErrorComponent } from '../../../../_lib/react-error';
import { useDashboardUpdate } from '../../../../_utils/hooks/use-dashboard-update';
import { logError } from '../../../../_lib/error';
import { SwitchIcon } from '../../../../components/icons/switch-icon';

import { CustomMapUploadDialog } from './dialog';
import { MapColorsDialog } from './colors-dialog';

export function AdvancedMapView({ enabled }: any) {
  const { t } = useTranslation();

  const {
    dashboard: { mode, mapDodUseChildInterventionCount, customMapviewMap },
    setSnackbarIsError,
    setSnackbarOpen,
  } = useDashboardContext();
  const { dashboardUpdateMutation, loading } = useDashboardUpdate();

  const [openMapUploadDialog, setOpenMapUploadDialog] = React.useState(false);
  const [openColorsDialog, setOpenColorsDialog] = React.useState(false);
  const [interventionCountInDod, setInterventionCountInDod] = React.useState(
    mapDodUseChildInterventionCount
  );

  if (!enabled) return null;

  // handlers
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInterventionCountInDod(event.target.checked);

    dashboardUpdateMutation({
      variables: {
        mapDodUseChildInterventionCount: event.target.checked,
      },
    })
      .catch((err) => {
        logError(err);
        setSnackbarIsError(true);
      })
      .finally(() => {
        setSnackbarOpen(true);
      });
  };

  return (
    <ErrorBoundary FallbackComponent={ReactErrorComponent}>
      {/* dialogs */}
      <CustomMapUploadDialog
        open={openMapUploadDialog}
        setOpen={setOpenMapUploadDialog}
      />
      <MapColorsDialog open={openColorsDialog} setOpen={setOpenColorsDialog} />

      {/* actions */}
      <Box sx={{ display: { xs: 'block', sm: 'flex' }, alignItems: 'center' }}>
        {enabled && (
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: 2,
            }}
          >
            {/* customize map */}
            <Button
              variant={customMapviewMap ? 'outlined' : 'contained'}
              size="small"
              color={customMapviewMap ? 'primary' : 'error'}
              onClick={() => setOpenMapUploadDialog(true)}
              startIcon={!customMapviewMap ? <ErrorOutlineRoundedIcon /> : null}
              disableElevation
            >
              {t('Set Custom Map')}
            </Button>

            {/* colours */}
            <Button
              variant="outlined"
              size="small"
              onClick={() => setOpenColorsDialog(true)}
            >
              {t('Manage Map Colours')}
            </Button>

            {/* use child dashboards data */}
            {mode === 'SUPRANATIONAL' && (
              <Box>
                <FormControlLabel
                  control={
                    <Switch
                      checked={interventionCountInDod}
                      onChange={handleChange}
                      inputProps={{ 'aria-label': 'controlled' }}
                      icon={
                        <SwitchIcon
                          enabled={interventionCountInDod}
                          loading={loading}
                          size={15}
                        />
                      }
                      size="small"
                    />
                  }
                  label={
                    <Typography variant="caption">
                      {t("Use child dashboards' data")}
                    </Typography>
                  }
                  labelPlacement="end"
                />
              </Box>
            )}
          </Box>
        )}
      </Box>
    </ErrorBoundary>
  );
}
