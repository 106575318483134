import React from 'react';

export function SubscribeToMore({
  subscribeToMore,
  variables,
}: {
  subscribeToMore: () => () => void;
  variables?: any;
}) {
  React.useEffect(() => {
    return subscribeToMore();
  }, [subscribeToMore, variables]);
  return null;
}

SubscribeToMore.defaultProps = {
  variables: {},
};

export function SubscribeToMoreComponent({
  subscribeToMore,
  document,
  variables,
}: {
  subscribeToMore: any;
  document: any;
  variables?: any;
}) {
  return (
    <SubscribeToMore
      subscribeToMore={() =>
        subscribeToMore({
          document,
          variables,
          updateQuery: (prev: any, { subscriptionData }: any) => {
            if (!subscriptionData.data) return prev;
            return subscriptionData.data;
          },
        })
      }
      variables={variables}
    />
  );
}

SubscribeToMoreComponent.defaultProps = {
  variables: {},
};
