import React from 'react';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Link from '@mui/material/Link';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import { SwitchIcon } from '../../../../components/icons/switch-icon';

export function AdvancedSettingsOptionSwitch({
  option,
  enabled,
  loading,
  handleCheck,
}: {
  option: any;
  enabled: boolean;
  loading: boolean;
  handleCheck: (checked: boolean) => void;
}) {
  const { t } = useTranslation();

  // -------------------------------------------------------------------------------------------------------

  return (
    <Box>
      <FormControl component="fieldset" variant="standard">
        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                checked={enabled || false}
                onChange={(event: any) => handleCheck(event.target.checked)}
                name={option.key}
                checkedIcon={<SwitchIcon enabled={enabled} loading={loading} />}
                icon={<SwitchIcon enabled={enabled} loading={loading} />}
              />
            }
            label={
              <>
                <Typography variant="body1">{option.label}</Typography>
                <Typography variant="caption">{option.desc}</Typography>
              </>
            }
            disabled={loading}
            sx={{
              alignItems: 'flex-start',
              m: 0,
            }}
          />
        </FormGroup>
      </FormControl>

      {/* learn more */}
      {!!option.learnmore && (
        <Typography variant="body2" sx={{ pl: 7, mt: 1 }}>
          <Link
            href={option.learnmore}
            target="_blank"
            rel="noopener noreferrer"
            sx={{ display: 'flex', alignItems: 'center' }}
          >
            <InfoOutlinedIcon fontSize="inherit" sx={{ mr: 0.5 }} />
            {t('Learn More')}
          </Link>
        </Typography>
      )}

      {/* actions */}
      {!!option.actions && (
        <Box sx={{ mt: 2 }}>
          {React.cloneElement(option.actions, { enabled })}
        </Box>
      )}
    </Box>
  );
}
