import React from 'react';
import { gql, useMutation } from '@apollo/client';

import { logError } from '../../_lib/error';

const authContext = React.createContext(null as any);

const AUTH_LOGIN = gql`
  mutation AuthLogin($email: String!, $password: String!) {
    authLogin(email: $email, password: $password) {
      success
      message
      user {
        id
        isTwoFactorEnabled
      }
    }
  }
`;

const AUTH_LOGOUT = gql`
  mutation AuthLogout {
    authLogout {
      success
      message
    }
  }
`;

const AUTH_FORGOT_PASSWORD = gql`
  mutation AuthForgotPassword(
    $email: String!
    $googleRecaptchaToken: String!
    $reactAppUrl: String
  ) {
    authForgotPassword(
      email: $email
      googleRecaptchaToken: $googleRecaptchaToken
      reactAppUrl: $reactAppUrl
    ) {
      success
      message
    }
  }
`;

const AUTH_RESET_PASSWORD = gql`
  mutation AuthResetPassword(
    $password: String!
    $uidb64: String!
    $token: String!
  ) {
    authResetPassword(password: $password, uidb64: $uidb64, token: $token) {
      success
      message
    }
  }
`;

export const useProvideAuth = () => {
  // sign in
  const [signInMutation] = useMutation(AUTH_LOGIN);
  const signIn = (email: string, password: string) =>
    signInMutation({
      variables: { email, password },
    });

  // sign out
  const [signOutMutatation] = useMutation(AUTH_LOGOUT);
  const signOut = () =>
    signOutMutatation()
      .then(() => {
        if (process.env.NODE_ENV !== 'development') {
          window.localStorage.clear();
        }
        window.location.reload();
      })
      .catch((error) => {
        logError(error);
      });

  // forgot password
  const [forgotPasswordMutation] = useMutation(AUTH_FORGOT_PASSWORD);
  const forgotPassword = (email: string, googleRecaptchaToken: string) =>
    forgotPasswordMutation({
      variables: {
        email,
        googleRecaptchaToken,
        reactAppUrl: window.location.origin,
      },
    }).catch((error) => {
      logError(error);
    });

  // reset password
  const [resetPasswordMutation] = useMutation(AUTH_RESET_PASSWORD);
  const resetPassword = (password: string, uidb64: string, token: string) =>
    resetPasswordMutation({
      variables: {
        password,
        uidb64,
        token,
      },
    }).catch((error) => {
      logError(error);
    });

  return {
    signIn,
    signOut,
    forgotPassword,
    resetPassword,
  };
};

export const useAuth = () => {
  return React.useContext(authContext);
};

export function ProvideAuth({ children }: any) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}
