import React from 'react';

import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Chip from '@mui/material/Chip';

import { DashboardContext } from '../../../../_lib/context/dashboard-context';

export function DashboardFormDetailsLanguage({
  disabled,
  newDashboard,
  setNewDashboard,
}: any) {
  const {
    dashboard: { supportedLanguages },
  } = React.useContext(DashboardContext);
  const { t } = useTranslation();
  const selectedLanguage = newDashboard.language;

  // Handle change
  const handleChange = (event: SelectChangeEvent) => {
    const language = event.target.value;
    setNewDashboard((old: any) => ({
      ...old,
      language,
    }));
  };

  return (
    <Box>
      <FormControl fullWidth size="small" disabled={disabled}>
        <InputLabel id="select-dashboard-language">{t('Language')}</InputLabel>
        <Select
          labelId="select-dashboard-language"
          id="select-language"
          value={selectedLanguage}
          label={t('Language')}
          onChange={handleChange}
          disabled={disabled}
        >
          {Object.keys(supportedLanguages).map((language: any) => {
            return (
              <MenuItem
                key={`${supportedLanguages[language]}-select-item`}
                value={supportedLanguages[language]}
              >
                {language}
                {(supportedLanguages[language] === 'sr' ||
                  supportedLanguages[language] === 'sw') && (
                  <Chip
                    label={t('BETA')}
                    size="small"
                    sx={{ ml: 1.5 }}
                    color="info"
                    variant="outlined"
                  />
                )}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </Box>
  );
}
