import React from 'react';

import { useTranslation } from 'react-i18next';
import { gql, useQuery } from '@apollo/client';

import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import { useDashboardContext } from '../../../_lib/context/dashboard-context';
import { SubscribeToMoreComponent } from '../../../components/subscribe-to-more';
import { logError } from '../../../_lib/error';

const INTVS_FRAGMENT = gql`
  fragment ContentFormInterventionSelectInterventionFragment on InterventionType {
    id
    reference
    name
    priorityArea {
      id
      reference
      name
      level {
        id
        name
      }
    }
  }
`;

const INTV_QUERY = gql`
  query ContentFormInterventionSelectIntervention($interventionId: ID!) {
    intervention(interventionId: $interventionId) {
      ...ContentFormInterventionSelectInterventionFragment
    }
  }
  ${INTVS_FRAGMENT}
`;

const INTV_SUBSCRIPTION = gql`
  subscription ContentFormInterventionSelectIntervention($interventionId: ID!) {
    intervention(interventionId: $interventionId) {
      ...ContentFormInterventionSelectInterventionFragment
    }
  }
  ${INTVS_FRAGMENT}
`;

export function ContentFormInterventionSelectButton({
  handleClick,
  anchorEl,
  content,
  errors,
}: any) {
  const { t } = useTranslation();
  const { dashboard } = useDashboardContext();

  const { data, loading, error, refetch, subscribeToMore } = useQuery(
    INTV_QUERY,
    {
      variables: {
        interventionId: content?.intervention?.id || 0,
      },
    }
  );

  // --------------------------------------------------------------------------------------------------------------
  // --------------------------------------------------------------------------------------------------------------
  // effects
  // --------------------------------------------------------------------------------------------------------------

  // error
  React.useEffect(() => {
    if (error) logError(error);
  }, [error]);

  // refetch
  React.useEffect(() => {
    if (!loading && !data && !error) refetch();
  }, [loading, data, error, refetch]);

  // --------------------------------------------------------------------------------------------------------------

  const open = Boolean(anchorEl);

  return (
    <>
      <Typography variant="caption" component="div" sx={{ mb: 0.2 }}>
        {dashboard.interventionName}
      </Typography>

      <LoadingButton
        id="intervention-select-button"
        aria-controls={open ? 'intervention-select-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        endIcon={<ArrowDropDownIcon />}
        variant="outlined"
        sx={{
          textTransform: 'none',
          width: '100%',
          justifyContent: 'flex-start',
          '& .MuiButton-endIcon': {
            marginLeft: '1',
          },
        }}
        size="large"
        color="inherit"
        loadingPosition="end"
        loading={loading}
      >
        <Typography
          sx={{
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            marginRight: 'auto',
            fontStyle: content?.intervention?.id ? 'normal' : 'italic',
          }}
          component="div"
        >
          {content?.intervention?.id
            ? `${
                dashboard.mode !== 'TRACKER'
                  ? data?.intervention.reference
                    ? `${data?.intervention.reference} -`
                    : ''
                  : ''
              } ${data?.intervention.name}`
            : `${t('Select')}: ${dashboard.interventionName}`}
        </Typography>
      </LoadingButton>

      {/* error text */}
      {!!errors?.intervention && (
        <Typography variant="caption" component="div" color="error">
          * {errors.intervention}
        </Typography>
      )}
      {/* subscribe to more */}
      <SubscribeToMoreComponent
        subscribeToMore={subscribeToMore}
        document={INTV_SUBSCRIPTION}
        variables={{
          interventionId: content?.intervention?.id || 0,
        }}
      />
    </>
  );
}
