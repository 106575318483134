import React from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Switch } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';

import { Helmet } from 'react-helmet-async';

import LinearProgress from '@mui/material/LinearProgress';

import { useDashboardContext } from '../context/dashboard-context';
import { AppProviders } from '../providers/app';

import { BaseLayout } from '../../_layout/base';

import { ContentForm } from '../../content/content-form';
import { ContentDialogRouter } from './content-dialog-router';
import { ContentAddMenu } from '../../components/content-add-menu';
import { ReactErrorComponent } from '../react-error';
import { TrackerLoaderMyContent } from '../../tracker/loader/my-content';
import { ScreenMyAccount } from '../../_screens/my-account';

import { ActivityTrackerRouter } from './activity-tracker-router';
import { FullDashboardRouter } from './full-dashboard-router';
import { ActivityTrackerLoader } from '../../tracker/loader/activity-tracker';

import { ExcecutiveSummaryScreen } from '../../_screens/executive-view/executive-summary';
import { ExecutivePriorityAreaScreen } from '../../_screens/executive-view/executive-priority-area';
import { RoutePermissions } from '../providers/permissions';
import { ParentDashboardAlert } from '../../components/alerts/parent-dashboard-alert';
import { DashboardDeletedAlert } from '../../components/alerts/dashboard-deleted-alert';

export function HomePage() {
  const { t } = useTranslation();
  const { dashboard } = useDashboardContext();

  if (!dashboard) return <LinearProgress />;

  return (
    <>
      {/* head */}
      <Helmet>
        <title>{dashboard?.name}</title>
        <meta name="description" content={dashboard.goal} />
        <link rel="icon" sizes="32x32" href={dashboard.logo} />
        <link rel="icon" sizes="16x16" href={dashboard.logo} />
        <link rel="icon" href={dashboard.logo} />
      </Helmet>

      <BaseLayout>
        {/* dashboard content */}
        <ErrorBoundary FallbackComponent={ReactErrorComponent}>
          <RoutePermissions>
            {/* alerts */}
            <DashboardDeletedAlert />
            <ParentDashboardAlert includeContainer />

            <Switch>
              {/* tracker */}
              <Route
                exact
                path={t('/tracker')}
                component={ActivityTrackerLoader}
              />

              {/* my content and my account */}
              <Route
                exact
                path={t('/my-content')}
                component={TrackerLoaderMyContent}
              />
              <Route
                exact
                path={t('/my-account')}
                component={ScreenMyAccount}
              />

              {/* executive route */}
              {/* todo: move to only full dashboard router */}
              <Route
                exact
                path="/executive"
                component={ExcecutiveSummaryScreen}
              />
              <Route
                exact
                path={`/executive/${dashboard.priorityAreasSlug}/:priorityAreaId`}
                component={ExecutivePriorityAreaScreen}
              />
              <Route
                path={`/executive/${dashboard.priorityAreasSlug}/:id/*/:priorityAreaId`}
                component={ExecutivePriorityAreaScreen}
              />

              {/* rest of routes */}
              {dashboard.mode === 'TRACKER' ? (
                <ActivityTrackerRouter />
              ) : (
                <FullDashboardRouter />
              )}
            </Switch>
          </RoutePermissions>
        </ErrorBoundary>

        {/* ---------------------------------------------------------------------------------------------------------- */}

        {/* content dialog router and form */}
        <ContentDialogRouter />
        <ContentForm />
        <ContentAddMenu />
      </BaseLayout>
    </>
  );
}

export function AppLoader() {
  return (
    <React.Suspense fallback={<LinearProgress />}>
      <AppProviders>
        <HomePage />
      </AppProviders>
    </React.Suspense>
  );
}
