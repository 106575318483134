import React from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorBoundary } from 'react-error-boundary';

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { ReactErrorComponent } from '../../../_lib/react-error';

import { TabPanel } from '../tab-panel';
import { AdvancedSettingsOption } from './option';

import { AdvancedSettingsFilter } from './filter';

import { useSettingsAdvancedContext } from './dashboard-form-advanced-context';

export function SettingsAdvanced({ tabVal, tabInd }: any) {
  const { t } = useTranslation();
  const { settings } = useSettingsAdvancedContext();

  // ----------------------------------------------------------------------------------------------------------------
  // ----------------------------------------------------------------------------------------------------------------

  const [filteredSettings, setFilteredSettings] = React.useState<any>(settings);

  // ----------------------------------------------------------------------------------------------------------------

  return (
    <TabPanel value={tabVal} index={tabInd}>
      <Box>
        {/* title + filter */}
        <Box
          display="flex"
          sx={{
            flexWrap: 'wrap',
            alignItems: 'center',
            justifyContent: 'space-between',
            rowGap: 3,
            columnGap: 2,
            mb: 3,
            width: '100%',
          }}
        >
          <Typography variant="h5">{t('Advanced Settings')}</Typography>
          <AdvancedSettingsFilter setFilteredSettings={setFilteredSettings} />
        </Box>

        {/* options */}
        <Stack spacing={3} divider={<Divider />}>
          {/* settings */}
          {Object.keys(filteredSettings || {})
            .sort((a: any, b: any) => settings[a].order - settings[b].order)
            .map((key) => (
              <React.Fragment key={key}>
                {/* settings title */}
                <Typography sx={{ color: 'grey.700', fontWeight: 700 }}>
                  {filteredSettings[key].title}
                </Typography>

                {/* settings options */}
                {filteredSettings[key].options.map((option: any) => {
                  return (
                    <ErrorBoundary
                      key={option.key}
                      FallbackComponent={ReactErrorComponent}
                    >
                      <AdvancedSettingsOption option={option} />
                    </ErrorBoundary>
                  );
                })}

                {/* no options */}
                {filteredSettings[key].options.length === 0 && (
                  <Typography sx={{ color: 'grey.700', fontWeight: '300' }}>
                    {t('No options available')}
                  </Typography>
                )}
              </React.Fragment>
            ))}

          {/* no settings */}
          {Object.keys(filteredSettings || {}).length === 0 && (
            <Typography sx={{ color: 'grey.500', textAlign: 'center', my: 5 }}>
              {t('No settings found')}
            </Typography>
          )}
        </Stack>
      </Box>
    </TabPanel>
  );
}
