import React from 'react';
import { useTranslation } from 'react-i18next';

import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';

import { ContentDialogContext } from '../_lib/context/content-dialog-context';

export function ChangeContent() {
  const { t } = useTranslation();

  const { setContentForm } = React.useContext(ContentDialogContext);

  // -----------------------------------------------------------------------------------------------------------------------------
  // -----------------------------------------------------------------------------------------------------------------------------
  // handlers
  // -----------------------------------------------------------------------------------------------------------------------------

  // -----------------------------------------------------------------------------------------------------------------------------
  // -----------------------------------------------------------------------------------------------------------------------------
  // effects
  // -----------------------------------------------------------------------------------------------------------------------------

  // -----------------------------------------------------------------------------------------------------------------------------

  return (
    <Button
      id="edit-content-btn"
      variant="outlined"
      fullWidth
      startIcon={<EditIcon />}
      onClick={() =>
        setContentForm({
          open: true,
          isEdit: true,
        })
      }
    >
      {t('Edit')}
    </Button>
  );
}
