import { useTheme } from '@mui/material/styles';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export function SectionTitle({ title, subheader, actions, sx }: any) {
  const theme = useTheme();

  return (
    <Box
      sx={{
        pb: 3,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderBottom: 1,
        borderColor: 'divider',
        [theme.breakpoints.down('sm')]: {
          display: 'block',
        },
        ...sx,
      }}
    >
      <Box>
        {!!title && <Typography variant="h4">{title}</Typography>}
        {!!subheader && (
          <Typography variant="body1" color="text.secondary">
            {subheader}
          </Typography>
        )}
      </Box>
      {!!actions && (
        <Box
          sx={{
            ml: 1,
            [theme.breakpoints.down('sm')]: {
              ml: 0,
              mt: 2,
            },
          }}
        >
          {actions}
        </Box>
      )}
    </Box>
  );
}
