import React from 'react';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

import { DashboardContext } from '../../_lib/context/dashboard-context';
import { ContentDialogContext } from '../../_lib/context/content-dialog-context';

export function ContentDialogAlert() {
  const { t } = useTranslation();

  const { dashboard } = React.useContext(DashboardContext);
  const { content } = React.useContext(ContentDialogContext);

  return (
    (!content.intervention && (
      <Box sx={{ mt: 3, mb: 2 }}>
        <Alert severity="error">
          <AlertTitle>{`${t('Missing')} ${
            dashboard.interventionName
          }`}</AlertTitle>
          {t(
            'Please tag a Lead or Co-Lead to assign this issue to an intervention/project.'
          )}
        </Alert>
      </Box>
    )) ||
    null
  );
}
