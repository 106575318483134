import React from 'react';
import { useQuery } from '@apollo/client';

import Container from '@mui/material/Container';
import Card from '@mui/material/Card';
import Skeleton from '@mui/material/Skeleton';

import { MY_CONTENT_QUERY } from '../../_lib/graphql/queries';
import { MY_CONTENT_SUBSCRIPTION } from '../../_lib/graphql/subscriptions';

import { SubscribeToMore } from '../../components/subscribe-to-more';

import { ReactErrorComponent } from '../../_lib/react-error';

import { Tracker } from '..';
import { MyContentBreadcrumb } from '../../breadcrumbs/my-content-breadcrumb';

// --------------------------------------------------------------------------------------------------------------------

export function TrackerLoaderMyContent() {
  const { loading, error, data, refetch, subscribeToMore } =
    useQuery(MY_CONTENT_QUERY);

  // --------------------------------------------------------------------------------------------------------------------

  if (error) return <ReactErrorComponent error={error} />;

  if (loading || !data) {
    if (!data && !loading) refetch();
    return (
      <Container sx={{ my: 3 }}>
        <Skeleton variant="rectangular" height={300} />
      </Container>
    );
  }
  // --------------------------------------------------------------------------------------------------------------------

  return (
    <Container maxWidth={false} sx={{ my: 3 }}>
      {/* breadcrumb */}
      <MyContentBreadcrumb />

      {/* tracker */}
      <Card id="my-content" sx={{ p: 3, mt: 3.5 }}>
        <Tracker content={data.myContent} />
      </Card>

      {/* subscription */}
      <SubscribeToMore
        subscribeToMore={() =>
          subscribeToMore({
            document: MY_CONTENT_SUBSCRIPTION,
            updateQuery: (prev, { subscriptionData }) => {
              if (!subscriptionData.data) return prev;
              return subscriptionData.data;
            },
          })
        }
      />
    </Container>
  );
}
