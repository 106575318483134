import React from 'react';
import { useLocation } from 'react-router-dom';

import Card from '@mui/material/Card';
import Collapse from '@mui/material/Collapse';

import { AreaPermissionsManage } from '../../../components/area-permissions/manage';
import { DraggableAreaDetail } from '../../../components/draggable/area-detail';

import { PlanningPriorityAreaFormDetailHeader } from './header';
import { PlanningPriorityAreaFormDetailContent } from './content';

export function PlanningPriorityAreaFormDetail({
  priorityAreaInitial,
  filtered,
  index,
  openCollapse,
  setOpenCollapse,
  listVariables,
}: any) {
  const location = useLocation();
  const [openPermissionsDialog, setOpenPermissionsDialog] =
    React.useState(false);

  const [disabled, setDisabled] = React.useState(true);

  // -------------------------------------------------------------------------------------------------------
  // -------------------------------------------------------------------------------------------------------
  // handlers
  // -------------------------------------------------------------------------------------------------------

  // collapse
  const toggleCollapse = (id: string) => {
    setOpenCollapse((prev: any) =>
      prev.includes(id) ? prev.filter((i: string) => i !== id) : [...prev, id]
    );
  };

  // -------------------------------------------------------------------------------------------------------
  // -------------------------------------------------------------------------------------------------------
  // effects
  // -------------------------------------------------------------------------------------------------------

  // enable edit
  React.useEffect(() => {
    const params = new URLSearchParams(location.search);
    const id = params.get('id');
    const edit = params.get('edit');

    if (id) {
      setDisabled(!edit);
    }
  }, [location.search, priorityAreaInitial.archivedAt, setDisabled]);

  const isOpen = React.useMemo(
    () =>
      openCollapse.includes(priorityAreaInitial.id) ||
      (!disabled && !priorityAreaInitial.archivedAt),
    [
      openCollapse,
      priorityAreaInitial.id,
      priorityAreaInitial.archivedAt,
      disabled,
    ]
  );

  // -------------------------------------------------------------------------------------------------------

  return (
    <>
      {/* ------------------------------------------------------------------------------------------------------- */}
      {/* dialog */}
      {/* ------------------------------------------------------------------------------------------------------- */}

      {/* permissions */}
      {openPermissionsDialog && (
        <AreaPermissionsManage
          open={openPermissionsDialog}
          setOpen={setOpenPermissionsDialog}
          area={priorityAreaInitial}
        />
      )}

      {/* ------------------------------------------------------------------------------------------------------- */}
      {/* detail */}
      {/* ------------------------------------------------------------------------------------------------------- */}

      <DraggableAreaDetail
        detail={priorityAreaInitial}
        index={index}
        isDragDisabled={filtered || isOpen || !!priorityAreaInitial.archivedAt}
      >
        <Card
          sx={{
            my: 3,
            ...(priorityAreaInitial.archivedAt ? { background: '#eee' } : {}),
          }}
          className="priority-area-form"
          variant="outlined"
        >
          {/* header */}
          <PlanningPriorityAreaFormDetailHeader
            priorityAreaInitial={priorityAreaInitial}
            filtered={filtered}
            toggleCollapse={toggleCollapse}
            setOpenPermissionsDialog={setOpenPermissionsDialog}
            disabled={disabled || priorityAreaInitial.archivedAt}
            setDisabled={setDisabled}
            openCollapse={openCollapse}
            setOpenCollapse={setOpenCollapse}
            listVariables={listVariables}
          />

          {/* details */}

          <Collapse in={isOpen}>
            {isOpen && (
              <PlanningPriorityAreaFormDetailContent
                priorityAreaInitial={priorityAreaInitial}
                disabled={disabled || priorityAreaInitial.archivedAt}
                setDisabled={setDisabled}
                setOpenPermissionsDialog={setOpenPermissionsDialog}
                listVariables={listVariables}
              />
            )}
          </Collapse>
        </Card>
      </DraggableAreaDetail>
    </>
  );
}
