import React from 'react';
import { useTranslation } from 'react-i18next';

import Grid from '@mui/material/Grid';

import { useDashboardContext } from '../../_lib/context/dashboard-context';

import { MemberAutocomplete } from '../member-autocomplete';

export function AreaPermissionsLeads({
  disabled,
  area,
  setArea,
  errors,
  sx,
}: any) {
  const { t } = useTranslation();

  const { dashboard } = useDashboardContext();

  return (
    <Grid container sx={sx} spacing={2}>
      <Grid item xs={12} sm={6}>
        <MemberAutocomplete
          label={t('Lead')}
          error={errors.lead}
          disabled={disabled}
          required
          disableClearable
          value={dashboard.enableLeadsMulti ? area.leads : area.lead}
          valueFreeText={area.leadsFreeText}
          onChange={(event: any, value: any) => {
            // free text
            if (typeof value === 'string') {
              setArea({
                ...area,
                leadsFreeText: JSON.stringify([value]),
                lead: null,
              });
              return;
            }
            if (value.id === 'freeText' || value.id === 'addUserFreeText') {
              setArea({
                ...area,
                leadsFreeText: JSON.stringify([value.inputValue]),
                lead: null,
              });
              return;
            }

            // normal
            if (!dashboard.enableLeadsMulti) {
              setArea({ ...area, lead: value, leadsFreeText: null });
              return;
            }

            // multiple
            const leads = value.filter(
              (v: any) =>
                typeof v !== 'string' &&
                v?.id !== 'freeText' &&
                v?.id !== 'addUserFreeText'
            );
            const leadsFreeText = JSON.stringify(
              value
                .filter(
                  (v: any) =>
                    typeof v === 'string' ||
                    v?.id === 'freeText' ||
                    v?.id === 'addUserFreeText'
                )
                .map((v: any) => v.name || v)
            );

            setArea({
              ...area,
              leads,
              leadsFreeText,
            });
          }}
          multiple={dashboard.enableLeadsMulti}
          freeSolo={dashboard.enableLeadsFreeText}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <MemberAutocomplete
          label={t('Co-Lead')}
          error={errors.coLead}
          disabled={disabled}
          value={dashboard.enableLeadsMulti ? area.coLeads : area.coLead}
          valueFreeText={area.coLeadsFreeText}
          onChange={(event: any, value: any) => {
            // free text
            if (typeof value === 'string') {
              setArea({
                ...area,
                coLeadsFreeText: value,
                coLead: null,
              });
              return;
            }
            if (value.id === 'freeText' || value.id === 'addUserFreeText') {
              setArea({
                ...area,
                coLeadsFreeText: value.inputValue,
                coLead: null,
              });
              return;
            }

            // normal
            if (!dashboard.enableLeadsMulti) {
              setArea({ ...area, coLead: value, coLeadsFreeText: null });
              return;
            }

            // multiple
            const coLeads = value.filter(
              (v: any) =>
                typeof v !== 'string' &&
                v?.id !== 'freeText' &&
                v?.id !== 'addUserFreeText'
            );
            const coLeadsFreeText = JSON.stringify(
              value
                .filter(
                  (v: any) =>
                    typeof v === 'string' ||
                    v?.id === 'freeText' ||
                    v?.id === 'addUserFreeText'
                )
                .map((v: any) => v.name || v)
            );

            setArea({
              ...area,
              coLeads,
              coLeadsFreeText,
            });
          }}
          disableClearable
          required
          multiple={dashboard.enableLeadsMulti}
          freeSolo={dashboard.enableLeadsFreeText}
        />
      </Grid>
    </Grid>
  );
}
