import React from 'react';
import { useTranslation } from 'react-i18next';
import { gql, useQuery } from '@apollo/client';

import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import ListItemText from '@mui/material/ListItemText';
import Autocomplete from '@mui/material/Autocomplete';
import Skeleton from '@mui/material/Skeleton';

import { DashboardContext } from '../../../../_lib/context/dashboard-context';
import { SubscribeToMore } from '../../../../components/subscribe-to-more';
import { logError } from '../../../../_lib/error';
import { PlanningPriorityAreaFormActionsCancel } from '../../actions/cancel';
import { PlanningPriorityAreaFormActionsSave } from '../../actions/save';

const USER_FRAGMENT = gql`
  fragment PriorityAreaPlanningSupranationalUserFragment on UserType {
    id
    userSettings {
      id
      isAdmin
      dashboard {
        id
        name
        mode
      }
    }
  }
`;

const USER_QUERY = gql`
  query PriorityAreaPlanningSupranationalUser {
    user {
      ...PriorityAreaPlanningSupranationalUserFragment
    }
  }
  ${USER_FRAGMENT}
`;

const USER_SUBSCRIPTION = gql`
  subscription PriorityAreaPlanningSupranationalUserFragment {
    user {
      ...PriorityAreaPlanningSupranationalUserFragment
    }
  }
  ${USER_FRAGMENT}
`;

export function PlanningPriorityAreaDetailSupranational({
  disabled,
  priorityArea,
  setPriorityArea,
  errors,
  setDisabled,
  valid,
  loading,
  setLoading,
  paChildren,
  newFinanciers,
  setNewFinanciers,
  newTargets,
  setNewTargets,
}: any) {
  const { t } = useTranslation();

  const { dashboard } = React.useContext(DashboardContext);

  const [user, setUser] = React.useState<any>(null);

  const {
    data,
    loading: userLoading,
    error,
    subscribeToMore,
  } = useQuery(USER_QUERY);

  // ------------------------------------------------------------------------------------------------------------
  // ------------------------------------------------------------------------------------------------------------
  // effects
  // ------------------------------------------------------------------------------------------------------------

  // error
  React.useEffect(() => {
    if (error) logError(error);
  }, [error]);

  // data
  React.useEffect(() => {
    if (data) {
      setUser(data.user);
    }
  }, [data]);

  // ------------------------------------------------------------------------------------------------------------

  if (userLoading)
    return <Skeleton variant="rectangular" height={100} sx={{ mt: 1 }} />;

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Stack spacing={2}>
            {/* ref + name */}
            <Grid container spacing={1.5} sx={{ mb: 2 }}>
              <Grid item xs={3} sm={2}>
                <TextField
                  label={t('Ref')}
                  disabled={disabled}
                  value={priorityArea.reference || ''}
                  onChange={(e) =>
                    setPriorityArea({
                      ...priorityArea,
                      reference: e.target.value,
                    })
                  }
                  size="small"
                  fullWidth
                  required
                />
              </Grid>
              <Grid item sx={{ flexGrow: 1 }}>
                <TextField
                  label={t('Name')}
                  disabled={disabled}
                  value={priorityArea.name || ''}
                  onChange={(e) =>
                    setPriorityArea({
                      ...priorityArea,
                      name: e.target.value,
                    })
                  }
                  size="small"
                  fullWidth
                  helperText={errors.name}
                  error={!!errors.name}
                  required
                />
              </Grid>
            </Grid>

            {/* dashboard select */}
            <FormControl fullWidth disabled={disabled} required>
              <InputLabel id="demo-simple-select-label">
                {t('Dashboard')}
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={
                  user
                    ? priorityArea.dashboardOfDashboards?.dashboard?.id || ''
                    : ''
                }
                label="Dashboard"
                onChange={(event) => {
                  setPriorityArea({
                    ...priorityArea,
                    dashboardOfDashboards: {
                      dashboard: {
                        id: event.target.value,
                      },
                    },
                  });
                }}
              >
                {/* full dashboards with admin access */}
                {/* todo: filter for those selected in other priority areas */}
                {user?.userSettings
                  ?.filter(
                    (setting: any) =>
                      setting.isAdmin && setting.dashboard.mode === 'FULL'
                  )
                  ?.map((setting: any) => (
                    <MenuItem
                      value={setting.dashboard.id}
                      key={setting.dashboard.id}
                      disabled={
                        !!dashboard.childDashboards.find(
                          (childDashboard: any) =>
                            childDashboard.dashboard.id === setting.dashboard.id
                        )
                      }
                    >
                      {setting.dashboard.name}
                    </MenuItem>
                  ))}

                {/* no longer or don't have admin access to selected dashboard */}
                {priorityArea.dashboardOfDashboards?.dashboard?.id &&
                  user?.userSettings?.filter(
                    (setting: any) =>
                      setting.isAdmin &&
                      setting.dashboard.mode === 'FULL' &&
                      setting.dashboard.id ===
                        priorityArea.dashboardOfDashboards?.dashboard?.id
                  )?.length === 0 && (
                    <MenuItem
                      value={priorityArea.dashboardOfDashboards?.dashboard?.id}
                      key={priorityArea.dashboardOfDashboards?.dashboard?.id}
                    >
                      <ListItemText
                        primary={
                          priorityArea.dashboardOfDashboards?.dashboard?.name
                        }
                        secondary={t('No longer available')}
                      />
                    </MenuItem>
                  )}
              </Select>
            </FormControl>

            {/* Regions autocomplete */}
            {dashboard.enableMapView && (
              <Autocomplete
                options={dashboard.regions}
                multiple
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="area-regions"
                    label={t('Regions')}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'nope',
                    }}
                  />
                )}
                disabled={disabled}
                getOptionLabel={(option: any) => {
                  return `${option.name}`;
                }}
                value={priorityArea.regions}
                isOptionEqualToValue={(o: any, p: any) => o.id === p.id}
                renderOption={(props: any, option: any) => (
                  <MenuItem {...props} key={`${option.name}`}>
                    <ListItemText primary={`${option.name}`} />
                  </MenuItem>
                )}
                onChange={(event: any, newValue: any | null) => {
                  setPriorityArea({
                    ...priorityArea,
                    regions: newValue,
                  });
                }}
              />
            )}
          </Stack>
        </Grid>
      </Grid>

      {/* save and cancel buttons */}

      {!disabled && (
        <Stack
          spacing={2}
          direction={{ xs: 'column', sm: 'row' }}
          justifyContent="flex-end"
          sx={{
            mt: 2,
          }}
        >
          <PlanningPriorityAreaFormActionsCancel
            priorityAreaInitial={priorityArea}
            setPriorityArea={setPriorityArea}
            setDisabled={setDisabled}
            setNewTargets={setNewTargets}
            disabled={loading}
          />
          <PlanningPriorityAreaFormActionsSave
            valid={valid}
            priorityArea={priorityArea}
            paChildren={paChildren}
            newFinanciers={newFinanciers}
            setNewFinanciers={setNewFinanciers}
            newTargets={newTargets}
            setNewTargets={setNewTargets}
            setDisabled={setDisabled}
            loading={loading}
            setLoading={setLoading}
          />
        </Stack>
      )}

      {/* subscribe to more */}
      {!!data && (
        <SubscribeToMore
          subscribeToMore={() =>
            subscribeToMore({
              document: USER_SUBSCRIPTION,
              updateQuery: (prev: any, { subscriptionData }: any) => {
                if (!subscriptionData.data) return prev;
                return subscriptionData.data;
              },
            })
          }
        />
      )}
    </>
  );
}
