import React from 'react';
import { useTranslation } from 'react-i18next';

import DialogContent from '@mui/material/DialogContent';
import Dialog from '@mui/material/Dialog';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

import CurrencyList from '../../_lib/data/currency-list.json';

import { DialogTitle } from '../../DialogTitle/dialog-title';

export function CurrencyChangeDialog({
  open,
  setOpen,
  setValue,
  currency,
  disableClearable,
}: any) {
  const { t } = useTranslation();

  const [inputValue, setInputValue] = React.useState('');

  // -------------------------------------------------------------------------------------------------------------------------
  // -------------------------------------------------------------------------------------------------------------------------
  // handlers
  // -------------------------------------------------------------------------------------------------------------------------

  const handleListItemClick = (value: string) => {
    setValue(value);
  };

  // -------------------------------------------------------------------------------------------------------------------------

  return (
    <Dialog maxWidth="xs" fullWidth open={open}>
      <DialogTitle onClose={() => setOpen(false)}>
        {t('Select Currency')}
      </DialogTitle>
      <DialogContent dividers>
        <Autocomplete
          value={currency}
          onChange={(event: any, newValue: string) => {
            handleListItemClick(newValue);
          }}
          inputValue={inputValue}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          id="controllable-states-demo"
          options={CurrencyList.map(
            (currencyItem: any) =>
              `${currencyItem.entity} :: ${currencyItem.alpha_code}`
          )}
          renderInput={(params) => (
            <TextField {...params} label={t('Currency')} />
          )}
          disableClearable={disableClearable}
          fullWidth
        />
      </DialogContent>
    </Dialog>
  );
}
