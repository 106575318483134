import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

function stringToColor(string: string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.substr(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function stringAvatar(name: string) {
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: `${name?.split(' ')[0][0]}${
      name?.split(' ')[1] ? name?.split(' ')[1][0] : ''
    }`,
  };
}

export default function BackgroundLetterAvatar({
  name,
  email,
  includeName,
}: any) {
  return (
    <Box sx={{ display: 'inline-flex', alignItems: 'center' }}>
      <Tooltip title={name} arrow disableHoverListener={includeName}>
        <Avatar {...stringAvatar(name || email)} />
      </Tooltip>
      {!!includeName && (
        <Box sx={{ pl: 1 }}>
          <Typography
            component="div"
            variant="body1"
            sx={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {name}
          </Typography>
          <Typography
            component="div"
            variant="caption"
            color="text.secondary"
            sx={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            <Link href={`mailto:${email}`} target="_blank">
              {email}
            </Link>
          </Typography>
        </Box>
      )}
    </Box>
  );
}
