import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { LayoutDashboard } from '../_layout/dashboard';

import { ReportsHome } from '../reports/home';
import { ReportsEdit } from '../reports/edit';
import { ReportsView } from '../reports/view';

export function ReportsScreen() {
  const { t } = useTranslation();
  const { path } = useRouteMatch();

  return (
    <LayoutDashboard>
      <Switch>
        <Route exact path={path}>
          <ReportsHome />
        </Route>
        <Route exact path={`${path}${t('/new')}`}>
          <ReportsEdit />
        </Route>
        <Route path={`${path}/:reportId${t('/edit')}`}>
          <ReportsEdit />
        </Route>
        <Route path={`${path}/:reportId`}>
          <ReportsView />
        </Route>
      </Switch>
    </LayoutDashboard>
  );
}
