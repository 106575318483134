import React from 'react';
import { useTranslation } from 'react-i18next';

import Grid from '@mui/material/Grid';

import {
  Autocomplete,
  AutocompleteValue,
  Chip,
  TextField,
  Typography,
} from '@mui/material';
import { ErrorBoundary } from 'react-error-boundary';
import { useDashboardContext } from '../../../../_lib/context/dashboard-context';
import { ReactErrorComponent } from '../../../../_lib/react-error';
import { isPriorityArea } from '../executive-summary/executive-summary.helpers';
import { PriorityArea } from '../executive-summary/executive-summary.types';

export type ExecutiveSummaryFiltersValues = {
  [key in '0' | '1']: { [key in string]: PriorityArea };
};

function PlaceholderAutoComplete({
  label,
  isDisabled,
}: {
  label: string;
  isDisabled: boolean;
}) {
  return (
    <Autocomplete<PriorityArea, true, false, true>
      sx={{
        '& .Mui-disabled': {
          background: '#EEEEEE',
        },
      }}
      disabled={isDisabled}
      freeSolo
      multiple
      renderInput={(params) => <TextField {...params} label={label} />}
      options={[]}
    />
  );
}

export function ExecutiveSummaryFilters({
  onFilter,
  filterOptions,
  filters,
}: {
  onFilter: (props: {
    values: ExecutiveSummaryFiltersValues;
    filterElementId: keyof ExecutiveSummaryFiltersValues;
  }) => void;
  filterOptions: { [key: string]: PriorityArea[] };
  filters: ExecutiveSummaryFiltersValues;
}) {
  const priorityAreaLevel1ChipName = 'priority-area-level-1-chip';
  const level0Options = filterOptions?.['0'] || [];
  const level1Options = filterOptions?.['1'] || [];

  const hasSelectedParentPriorityArea = !!Object.prototype.hasOwnProperty.call(
    filters,
    '0'
  );

  const { t } = useTranslation();
  const dashboardContext = useDashboardContext();

  const parentAreaLevel = dashboardContext?.dashboard?.areaLevels?.[0];
  const childrenAreaLevel = dashboardContext?.dashboard?.areaLevels?.[1];

  const handleAutocompleteChange =
    ({ elementId }: { elementId: keyof ExecutiveSummaryFiltersValues }) =>
    (
      event: React.SyntheticEvent,
      values: AutocompleteValue<PriorityArea, true, any, any>
    ) => {
      if (Array.isArray(values)) {
        const filterValues = (values as PriorityArea[])
          ?.filter((v) => isPriorityArea(v))
          .reduce((acc, value: PriorityArea) => {
            const key =
              `${value?.level?.order}` as keyof ExecutiveSummaryFiltersValues;
            if (!Object.prototype.hasOwnProperty.call(acc, key)) {
              acc[key] =
                {} as ExecutiveSummaryFiltersValues[keyof ExecutiveSummaryFiltersValues];
            }
            acc[key][value?.id] = value;
            return acc;
          }, {} as ExecutiveSummaryFiltersValues);

        onFilter?.({
          values: filterValues,
          filterElementId: elementId,
        });
      }
    };

  const getOptionsLabel = (option: PriorityArea | string) => {
    if (typeof option === 'string') return '';
    return `${option.reference} - ${option.name}`;
  };

  const handleAutocompleteChangeLevel0 = (
    event: React.SyntheticEvent,
    values: AutocompleteValue<PriorityArea, false, any, any>
  ) => {
    return handleAutocompleteChange({
      elementId: '0',
    })(event, [values as string | PriorityArea]);
  };

  const handleAutocompleteChangeLevel1 = handleAutocompleteChange({
    elementId: '1',
  });

  return (
    <Grid container columnSpacing={2} rowSpacing={2}>
      <Grid item xs={12} md={1}>
        <Typography
          variant="h5"
          component="h2"
          fontWeight="600"
          fontSize={{
            xs: '1rem',
            sm: '1.5rem',
          }}
        >
          {t('Filter')}
        </Typography>
      </Grid>
      <Grid container item xs={12} md={11} columnSpacing={2} rowSpacing={2}>
        <ErrorBoundary FallbackComponent={ReactErrorComponent}>
          <Grid item xs={12} sm={6} md={4}>
            <Autocomplete<PriorityArea, false, false, true>
              id="priority-area-level-0"
              freeSolo
              renderInput={(params) => (
                <TextField {...params} label={parentAreaLevel?.name} />
              )}
              options={level0Options}
              isOptionEqualToValue={(option, value) => {
                return option.id === value.id;
              }}
              getOptionLabel={getOptionsLabel}
              onChange={handleAutocompleteChangeLevel0}
              clearText={t('Clear') || undefined}
              noOptionsText={t('No options')}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            {hasSelectedParentPriorityArea ? (
              <Autocomplete<PriorityArea, true, false, true>
                sx={{
                  '& .Mui-disabled': {
                    background: '#EEEEEE',
                  },
                }}
                disabled={!hasSelectedParentPriorityArea}
                freeSolo
                multiple
                id={priorityAreaLevel1ChipName}
                renderInput={(params) => (
                  <TextField {...params} label={childrenAreaLevel?.name} />
                )}
                renderTags={(value: PriorityArea[], getTagProps) =>
                  value.map((option: PriorityArea, index: number) => {
                    return (
                      <Chip
                        id="priority-area-level-1-chip"
                        variant="outlined"
                        label={option?.name}
                        {...getTagProps({ index })}
                      />
                    );
                  })
                }
                options={level1Options}
                getOptionLabel={getOptionsLabel}
                onChange={handleAutocompleteChangeLevel1}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                clearText={t('Clear') || undefined}
                noOptionsText={t('No options')}
              />
            ) : (
              // replace with placeholder to reset state of uncontrolled component
              <PlaceholderAutoComplete
                label={childrenAreaLevel?.name}
                isDisabled
              />
            )}
          </Grid>
        </ErrorBoundary>
      </Grid>
    </Grid>
  );
}
