import React from 'react';
import { useMutation } from '@apollo/client';

import { useDashboardContext } from '../_lib/context/dashboard-context';
import { logError } from '../_lib/error';

import { USER_CREATE } from '../_lib/graphql/mutations';
import { CONTEXT_USERS_QUERY } from '../_lib/graphql/queries';

import { UserCreateDialog } from '../navbar/user-create-dialog';

export function CreateMember({
  setSelect,
  showAddUserDialog,
  setShowAddUserDialog,
}: any) {
  const { dashboard, setSnackbarOpen, setSnackbarIsError } =
    useDashboardContext();

  const [loading, setLoading] = React.useState(false);
  const [newUser, setNewUser] = React.useState<any>({
    notify: true,
  });

  const [createUserMutation] = useMutation(USER_CREATE, {
    update: (cache, { data }) => {
      cache.writeQuery({
        query: CONTEXT_USERS_QUERY,
        data: { users: data.userCreate.users },
      });
    },
  });

  // -----------------------------------------------------------------------------------------------------------------------
  // -----------------------------------------------------------------------------------------------------------------------
  // handlers
  // -----------------------------------------------------------------------------------------------------------------------

  const createUser = (user: any) => {
    setLoading(true);
    return createUserMutation({
      variables: {
        ...user,
        dashboardId: dashboard.id,
        isAdmin: user.isAdmin || false,
        nootify: user.notify || false,
      },
    })
      .then((result) => {
        if (setSelect)
          setSelect({
            ...result.data.userCreate.user,
          });
        setNewUser({
          notify: true,
        });
        setShowAddUserDialog(false);
      })
      .catch((e) => {
        setSnackbarIsError(true);
        logError(e);
      })
      .finally(() => {
        setLoading(false);
        setSnackbarOpen(true);
      });
  };

  // -----------------------------------------------------------------------------------------------------------------------

  return (
    <UserCreateDialog
      loading={loading}
      onSubmit={createUser}
      onClose={() => setShowAddUserDialog(false)}
      open={showAddUserDialog}
      newUser={newUser}
      setNewUser={setNewUser}
    />
  );
}

export default CreateMember;
