import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TableContainer from '@mui/material/TableContainer';
import Stack from '@mui/material/Stack';

import { useTranslation } from 'react-i18next';

export function EntriesTable({
  dataItem,
  mode,
}: {
  dataItem: any;
  mode: string;
}) {
  const { t } = useTranslation();
  const filteredModified = dataItem.modified.filter((item: any) =>
    mode === 'modified' ? !item.markToDelete : item.markToDelete
  );

  const entriesState = {
    new: {
      headerText: t('The following items will be created'),
      noItemsText: t('There are no items to be created here.'),
      affectedCount: dataItem.new.length,
      items: dataItem.new,
    },
    modified: {
      headerText: t('The following items will be modified'),
      noItemsText: t('There are no modified items here.'),
      affectedCount: filteredModified.length,
      items: filteredModified,
    },
    deleted: {
      headerText: t('The following items will be deleted'),
      noItemsText: t('There are no deleted items.'),
      affectedCount: filteredModified.length,
      items: filteredModified,
    },
  } as any;

  const tableHeadBgColor = 'grey.200';

  return (
    <>
      {entriesState[mode].affectedCount ? (
        <Typography variant="subtitle2" mb={1} sx={{ color: 'grey.800' }}>
          {entriesState[mode].headerText} (
          <b>{entriesState[mode].affectedCount}</b>):
        </Typography>
      ) : (
        <Typography
          fontStyle="italic"
          variant="subtitle2"
          sx={{ color: 'grey.900' }}
          my={1.5}
        >
          &bull; {entriesState[mode].noItemsText}
        </Typography>
      )}
      {!!entriesState[mode].affectedCount && (
        <TableContainer
          component={Paper}
          variant="outlined"
          sx={{ maxHeight: 450 }}
        >
          <Table size="small" stickyHeader>
            <TableHead>
              <TableRow>
                {Object.keys(entriesState[mode].items[0])
                  .filter((key: any) => key !== 'markToDelete')
                  .map((itemHeader: any) => {
                    return (
                      <TableCell
                        sx={{
                          bgcolor: tableHeadBgColor,
                          fontWeight: 800,
                          fontSize: '.85rem',
                          color: 'grey.900',
                        }}
                        key={`item-header-${itemHeader}`}
                      >
                        {t(itemHeader)}
                      </TableCell>
                    );
                  })}
              </TableRow>
            </TableHead>
            <TableBody>
              {entriesState[mode].items.map(
                (entryItem: any, itemIndex: number) => {
                  return (
                    <TableRow hover key={`${mode}-item-${String(itemIndex)}`}>
                      {Object.keys(entryItem)
                        .filter((key: any) => key !== 'markToDelete')
                        .map((itemKey: any, keyIndex: number) => {
                          return (
                            <TableCell
                              component="th"
                              scope="row"
                              key={`item-${itemKey}`}
                              sx={{ color: 'grey.900' }}
                            >
                              <Stack flexDirection="row">
                                <Typography
                                  fontSize=".85rem"
                                  align="left"
                                  pr={1}
                                >
                                  {keyIndex === 0 && `${itemIndex + 1}.`}
                                </Typography>
                                {mode === 'new' || mode === 'deleted' ? (
                                  <Typography
                                    fontSize=".85rem"
                                    align="left"
                                    sx={{
                                      textDecoration:
                                        mode === 'deleted'
                                          ? 'line-through'
                                          : 'initial',
                                    }}
                                  >
                                    {entryItem[itemKey]}
                                  </Typography>
                                ) : entryItem[itemKey].changed ? (
                                  <Stack flexDirection="column" spacing={0.5}>
                                    <Typography
                                      sx={{
                                        color: 'orange',
                                      }}
                                      fontSize=".85rem"
                                      align="left"
                                    >
                                      {t('New')}:{' '}
                                      {entryItem[itemKey].value || ''}
                                    </Typography>
                                    <Typography
                                      fontSize=".85rem"
                                      align="left"
                                      sx={{
                                        color: 'grey.800',
                                      }}
                                    >
                                      {t('Current')}:{' '}
                                      {entryItem[itemKey].prev || ''}
                                    </Typography>
                                  </Stack>
                                ) : (
                                  <Typography fontSize=".85rem" align="left">
                                    {entryItem[itemKey].value}
                                  </Typography>
                                )}
                              </Stack>
                            </TableCell>
                          );
                        })}
                    </TableRow>
                  );
                }
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
}
