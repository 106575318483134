import React from 'react';
import { useTranslation } from 'react-i18next';
import { gql, useMutation } from '@apollo/client';

import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';

import { useDashboardContext } from '../../../_lib/context/dashboard-context';
import { CONTENT_FRAGMENT } from '../../../_lib/graphql/fragments';
import { logError } from '../../../_lib/error';

import { DialogModal } from '../../../components/dialog-modal';

const UPDATE_ACTIVITY_DATES = gql`
  ${CONTENT_FRAGMENT}
  mutation ContentUpdateDates(
    $contentId: ID!
    $deadline: Date
    $startDate: Date
    $completionDate: Date
  ) {
    contentUpdateDates(
      contentId: $contentId
      deadline: $deadline
      startDate: $startDate
      completionDate: $completionDate
    ) {
      content {
        ...ContentFragment
      }
      success
      message
      updatedContent {
        ...ContentFragment
      }
    }
  }
`;

export function ContentDialogDatesInputSaveBtn({
  content,
  startDate,
  deadline,
  completionDate,
  setAnchorEl,
  setLoading,
  loading,
  setShowError,
}: any) {
  const { t } = useTranslation();

  const { dashboard, setSnackbarOpen, setSnackbarIsError } =
    useDashboardContext();

  const [dialogConfirmOpen, setDialogConfirmOpen] = React.useState(false);

  const [contentUpdateDatesMutation] = useMutation(UPDATE_ACTIVITY_DATES);

  // -------------------------------------------------------------------------------------------------
  // -------------------------------------------------------------------------------------------------
  // handlers
  // -------------------------------------------------------------------------------------------------

  const handleSave = () => {
    setLoading(true);
    return contentUpdateDatesMutation({
      variables: { contentId: content.id, startDate, deadline, completionDate },
    })
      .then(() => {
        setLoading(false);
        setAnchorEl(null);
      })
      .catch((error: any) => {
        logError(error);
        setShowError(true);
        setLoading(false);
        setSnackbarIsError(true);
      })
      .finally(() => {
        setSnackbarOpen(true);
      });
  };

  // -------------------------------------------------------------------------------------------------

  return (
    <>
      {/* confirm save dialog */}
      <DialogModal
        open={dialogConfirmOpen}
        onDismiss={() => setDialogConfirmOpen(false)}
        onSubmit={() => {
          handleSave().then(() => {
            setDialogConfirmOpen(false);
          });
        }}
        loading={loading}
        title={t('Confirm Save')}
        message={<Typography>{t('Are you sure you want to save?')}</Typography>}
      />

      {/* save button */}
      <LoadingButton
        variant="contained"
        color="primary"
        fullWidth
        onClick={() => {
          if (!dashboard.enableActivityConfirmSave) {
            handleSave();
          } else {
            setDialogConfirmOpen(true);
          }
        }}
        loading={loading}
        disableElevation
      >
        {t('Save')}
      </LoadingButton>
    </>
  );
}
