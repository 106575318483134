import React from 'react';
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import { Container, Card, CardContent, Skeleton } from '@mui/material';

import { useDashboardContext } from '../context/dashboard-context';
import { TRACKER_LANDING_QUERY } from '../graphql/queries';
import { TRACKER_LANDING_SUBSCRIPTION } from '../graphql/subscriptions';

import { ReactErrorComponent } from '../react-error';
import { TrackerOverview } from '../../tracker/tracker-overview';
import { reduceSummaries } from '../../_utils/content-utils/reduce-summaries';
import { SubscribeToMore } from '../../components/subscribe-to-more';

export function TrackerLandingLoader() {
  const { t } = useTranslation();
  const { dashboard } = useDashboardContext();

  const { loading, error, data, subscribeToMore } = useQuery(
    TRACKER_LANDING_QUERY
  );

  const trackerData = React.useMemo(() => {
    if (data) {
      const combinedData = reduceSummaries({
        priorityAreas: data.priorityAreas.items,
        name: `${t('All')}: ${dashboard.priorityAreaName}`,
      });
      return [combinedData, ...data.priorityAreas.items];
    }
    return null;
  }, [data, dashboard.priorityAreaName, t]);

  // ---------------------------------------------------------------------------------------------------

  if (error) return <ReactErrorComponent error={error} />;

  if (loading || !trackerData)
    return (
      <Container sx={{ py: 4 }}>
        <Skeleton variant="rectangular" height={400} />
      </Container>
    );

  return (
    <>
      <Container>
        <Card sx={{ my: 4 }}>
          <CardContent>
            <TrackerOverview priorityAreas={trackerData} />
          </CardContent>
        </Card>
      </Container>

      <SubscribeToMore
        subscribeToMore={() =>
          subscribeToMore({
            document: TRACKER_LANDING_SUBSCRIPTION,
            updateQuery: (prev, { subscriptionData }) => {
              if (!subscriptionData.data) return prev;
              return subscriptionData.data;
            },
          })
        }
      />
    </>
  );
}
