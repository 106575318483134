import React from 'react';
import { useTranslation } from 'react-i18next';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';

import { useDashboardContext } from '../../../../_lib/context/dashboard-context';
import { ReactErrorComponent } from '../../../../_lib/react-error';
import { logError } from '../../../../_lib/error';

import { useJSONMap } from '../../../../_utils/hooks/use-json-map';

export function FeaturesTable() {
  const { t } = useTranslation();

  const {
    dashboard: { customMapviewMap },
  } = useDashboardContext();

  const { data, loading, error } = useJSONMap(customMapviewMap);

  const tableHeadBgColor = 'grey.200';

  const featureNames = React.useMemo(() => {
    const names = new Set<string>();
    data?.features?.forEach((feature: any) => {
      names.add(feature.properties?.name);
    });
    return Array.from(names).sort();
  }, [data?.features]);

  if (error) {
    logError(error);
    return <ReactErrorComponent error={error} />;
  }

  if (loading) {
    return <Skeleton width={400} height={400} variant="rectangular" />;
  }

  if (data && !data.features) {
    return <Typography>{t('Invalid Map File')}</Typography>;
  }

  return (
    <Box>
      <Typography color="grey.800" sx={{ mb: 1 }}>
        {t('The following are the features in the current map')}:
      </Typography>
      <Paper sx={{ width: 400, overflow: 'hidden' }} variant="outlined">
        <TableContainer sx={{ maxHeight: 400 }}>
          <Table sx={{ minWidth: 300 }} stickyHeader size="small">
            <TableHead>
              <TableRow>
                <TableCell sx={{ bgcolor: tableHeadBgColor }}>#</TableCell>
                <TableCell sx={{ bgcolor: tableHeadBgColor }}>
                  {t('Feature Name')}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {featureNames.map((name: any, index: number) => (
                <TableRow
                  key={name}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{ color: 'grey.800' }}
                  >
                    {index + 1}
                  </TableCell>
                  <TableCell sx={{ color: 'grey.800' }}>{name}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
}
