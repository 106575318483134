import React from 'react';
import { useTranslation } from 'react-i18next';

import Button from '@mui/material/Button';
import ArchiveIcon from '@mui/icons-material/Archive';
import UnarchiveIcon from '@mui/icons-material/Unarchive';

import { useContentDialogContext } from '../../../../_lib/context/content-dialog-context';

import { ContentDialogActionsArchiveDialog } from './dialog';

export function ContentDialogActionsArchive() {
  const { t } = useTranslation();

  const { content } = useContentDialogContext();

  const [dialogConfirmOpen, setDialogConfirmOpen] = React.useState(false);

  return (
    <>
      {/* confirm save dialog */}
      <ContentDialogActionsArchiveDialog
        open={dialogConfirmOpen}
        setOpen={setDialogConfirmOpen}
        content={content}
      />

      <Button
        variant="outlined"
        fullWidth
        startIcon={
          content.status === 'archived' ? <UnarchiveIcon /> : <ArchiveIcon />
        }
        onClick={() => setDialogConfirmOpen(true)}
      >
        {content.status === 'archived' ? t('Unarchive') : t('Archive')}
      </Button>
    </>
  );
}
