import React from 'react';
import Box, { BoxProps } from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';

export function LinearProgressDeterminateLoading({
  sx,
}: {
  sx?: BoxProps['sx'];
}) {
  const [progress, setProgress] = React.useState(0);

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 85) {
          return 85;
        }
        const diff = Math.random() * 10;
        return Math.min(oldProgress + diff, 85);
      });
    }, 500);

    return () => {
      if (setProgress) setProgress(100);
      clearInterval(timer);
    };
  }, []);

  return (
    <Box sx={{ width: '100%', ...sx }}>
      <LinearProgress variant="determinate" value={progress} />
    </Box>
  );
}

LinearProgressDeterminateLoading.defaultProps = {
  sx: {},
};
