import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { useDashboardContext } from '../../_lib/context/dashboard-context';

export function MapTooltip({ feature }: any) {
  const { dashboard } = useDashboardContext();

  return (
    <Box
      sx={{
        background: 'white',
        px: 1,
        py: 0.5,
        border: 1,
        borderColor: 'grey.800',
        display: 'flex',
        alignItems: 'flex-start',
      }}
    >
      <Box
        sx={{
          width: '1rem',
          height: '1rem',
          background: feature?.color,
          borderSize: '1px',
          borderStyle: 'solid',
          borderColor:
            dashboard.customMapPalette?.borderColor ||
            dashboard.colorPalette?.secondary?.bgcolor,
          mr: 1,
          mt: 0.5,
          p: 0,
        }}
      />
      <Box>
        <Typography sx={{ display: 'flex' }}>
          {feature?.properties?.name}
        </Typography>
        <Typography variant="body2">
          {dashboard.interventionName}: {feature.data?.countIntvs || 0}{' '}
        </Typography>
      </Box>
    </Box>
  );
}
