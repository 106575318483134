import React from 'react';

import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';

import MaterialTable, { Options } from '@material-table/core';

import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import ArrowUpward from '@mui/icons-material/ArrowUpward';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import GetAppIcon from '@mui/icons-material/GetApp';
import ViewColumnIcon from '@mui/icons-material/ViewColumn';

import DDDGroupRow from './custom/group-row';

import { TrackerTableToolbar } from './toolbar';
import { useTrackerTableColumns } from './columns';

import { getTableActions } from './actions';

import { ReactErrorComponent } from '../../_lib/react-error';
import { hexToRGB } from '../../_utils/general-utils';

import { useDashboardContext } from '../../_lib/context/dashboard-context';
import { useTrackerContext } from '../../_lib/context/tracker-context';
import { trackerTableUtilsExport } from '../../_utils/tracker-table-utils';
import { ContentDialogActionsDeleteDialog } from '../../content/content-dialog/actions/delete/dialog';
import { ContentDialogActionsArchiveDialog } from '../../content/content-dialog/actions/archive/dialog';

const tableIcons = {
  SortArrow: React.forwardRef((props: any, ref) => (
    <ArrowUpward {...props} ref={ref} />
  )),
  DetailPanel: React.forwardRef((props: any, ref) => (
    <ChevronRightIcon {...props} ref={ref} />
  )),
  Search: React.forwardRef((props: any, ref) => (
    <SearchIcon {...props} ref={ref} />
  )),
  ResetSearch: React.forwardRef((props: any, ref) => (
    <CloseIcon {...props} ref={ref} />
  )),
  Export: React.forwardRef((props: any, ref) => (
    <GetAppIcon {...props} ref={ref} />
  )),
  ViewColumn: React.forwardRef((props: any, ref) => (
    <ViewColumnIcon {...props} ref={ref} />
  )),
};

export function TrackerTable({ areaTitle }: any) {
  const { t } = useTranslation();

  const history = useHistory();
  const location = useLocation();

  const { priorityAreaId, interventionId } = useParams<any>();

  const { dashboard } = useDashboardContext();

  const { contentCount, filteredContent, filteredUsers, filterObject } =
    useTrackerContext();

  // ------------------------------------------------------------------------------------------------------------------

  // content to delete / archive
  const [contentToDeleteOrArchive, setContentToDeleteOrArchive] =
    React.useState<any>(null);
  const [showConfirmDeleteDialogOpen, setShowConfirmDeleteDialogOpen] =
    React.useState(false);
  const [showConfirmArchiveDialogOpen, setShowConfirmArchiveDialogOpen] =
    React.useState(false);

  // current tab key
  const { status } = React.useMemo(() => filterObject, [filterObject]);
  const { columns: tableColumns } = useTrackerTableColumns({
    status,
  });

  // ------------------------------------------------------------------------------------------------------------------
  // ------------------------------------------------------------------------------------------------------------------
  // handlers
  // ------------------------------------------------------------------------------------------------------------------

  // handle row click (show content)
  const handleRowClick = (event: any, content: any) => {
    if (status === 'logins') return;
    const params = new URLSearchParams(location.search);
    params.set('content', content.id);
    history.push(`${location.pathname}?${params.toString()}`);
  };

  // handle row delete
  const handleRowDeleteClick = (event: any, content: any) => {
    setContentToDeleteOrArchive(content);
    setShowConfirmDeleteDialogOpen(true);
  };

  // hanlde row archive
  const handleRowArchiveClick = (event: any, content: any) => {
    setContentToDeleteOrArchive(content);
    setShowConfirmArchiveDialogOpen(true);
  };

  // row highlight

  const rowHighlight = React.useCallback(
    (content: any) => {
      if (content.type === 'milestone') return 'rgba(170, 170, 255, 0.2)';
      if (content.type === 'issue')
        return hexToRGB(dashboard.metaStatusColors.issue.bgcolor, 0.1);
      return 'white';
    },
    [dashboard.metaStatusColors.issue.bgcolor]
  );

  // ------------------------------------------------------------------------------------------------------------------
  // ------------------------------------------------------------------------------------------------------------------
  // memoized variables
  // ------------------------------------------------------------------------------------------------------------------

  // table title
  const tableTitle = React.useMemo(() => {
    if (contentCount) {
      const tableTitles = {
        all: `${t('Current')} : ${contentCount.all.count} ${
          dashboard.enableActivityPercentage
            ? `[${t('Perc Done')}: ${contentCount.all.percDone}%]`
            : ''
        }`,
        milestones: `${t('Milestones')} : ${contentCount.milestones.count} ${
          dashboard.enableActivityPercentage &&
          dashboard.enableMilestonesCalculation
            ? `[${t('Perc Done')}: ${contentCount.all.percDone}%]`
            : ''
        }`,
        activities: `${t('Activities')} : ${contentCount.activities.count} ${
          dashboard.enableActivityPercentage
            ? `[${t('Perc Done')}: ${contentCount.all.percDone}%]`
            : ''
        }`,
        issue: `${t('Open Issues')} : ${contentCount.issue.count}`,
        archived: `${t('Archived')} : ${contentCount.archived.count} ${
          dashboard.enableActivityPercentage
            ? `[${t('Perc Done')}: ${contentCount.all.percDone}%]`
            : ''
        }`,
        my: `${t('Assigned to me')} : ${contentCount.my.count} ${
          dashboard.enableActivityPercentage
            ? `[${t('Perc Done')}: ${contentCount.all.percDone}%]`
            : ''
        }`,
        logins: t('Recent Logins'),
      } as any;
      return tableTitles[status];
    }
    return '';
  }, [
    status,
    contentCount,
    dashboard.enableActivityPercentage,
    dashboard.enableMilestonesCalculation,
    t,
  ]);

  // material table data
  const materialTableData = React.useMemo(
    () =>
      structuredClone(
        status === 'logins'
          ? filteredUsers
          : filteredContent.map((c: any) => ({
              ...c,
              assigneesText: c.assignees?.map((a: any) => a.name).join(', '),
            }))
      ),
    [filteredContent, filteredUsers, status]
  );

  // update column actions
  const tableActions = React.useMemo(
    () =>
      getTableActions({
        tab: status,
        t,
        handleRowDeleteClick,
        handleRowArchiveClick,
      }),
    [status, t]
  );

  // export file name
  const exportFileName = React.useMemo(() => {
    if (
      dashboard.mode === 'TRACKER' ||
      location.pathname === t('/my-content')
    ) {
      return `${dashboard.name} - ${tableTitle}`;
    }

    if (priorityAreaId || interventionId) {
      return `${dashboard.name} - ${areaTitle} - ${tableTitle}`;
    }

    return dashboard.name;
  }, [
    dashboard.mode,
    dashboard.name,
    location.pathname,
    t,
    priorityAreaId,
    interventionId,
    tableTitle,
    areaTitle,
  ]);

  // options object
  const tableOptions = React.useMemo<Options<any>>(
    () => ({
      // search
      search: true,
      searchAutoFocus: location.pathname.endsWith(`/${t('tracker')}`),

      paging: true,

      // row highlights
      rowStyle: (content: any) => ({ backgroundColor: rowHighlight(content) }),
      headerStyle: {
        position: 'sticky' as 'sticky',
        backgroundColor: 'white',
        whiteSpace: 'nowrap' as 'nowrap',
        zIndex: 10,
      },

      // max body height
      maxBodyHeight: 800,

      // groups
      grouping: false, // TODO: enable grouping (disabled for now for performance reasons)
      persistentGroupingsId: location.pathname,
      showGroupingCount: true,
      defaultExpanded: true,
      draggable: true,

      // actions
      actionsColumnIndex: -1,

      // columns
      columnsButton: true,

      // export
      exportMenu: [
        {
          label: `${t('Export')} PDF`,
          exportFunc: (columns, renderData, tableData) =>
            trackerTableUtilsExport({
              exportFileName,
              dashboard,
              t,
              columns,
              renderData,
              tableData,
            }),
        },
        {
          label: `${t('Export')} CSV`,
          exportFunc: (columns, renderData, tableData) =>
            trackerTableUtilsExport({
              exportFileName,
              isCsv: true,
              dashboard,
              t,
              columns,
              renderData,
              tableData,
            }),
        },
      ],
    }),
    [location.pathname, t, rowHighlight, exportFileName, dashboard]
  );

  // ------------------------------------------------------------------------------------------------------------------
  // ------------------------------------------------------------------------------------------------------------------
  // effects
  // ------------------------------------------------------------------------------------------------------------------

  // ------------------------------------------------------------------------------------------------------------------

  return (
    <ErrorBoundary FallbackComponent={ReactErrorComponent}>
      {/* ------------------------------------------------------------------------------------------------------------------ */}
      {/* dialogs */}

      {/* archive content */}
      <ContentDialogActionsArchiveDialog
        content={contentToDeleteOrArchive}
        open={showConfirmArchiveDialogOpen}
        setOpen={setShowConfirmArchiveDialogOpen}
      />

      {/* delete content */}
      <ContentDialogActionsDeleteDialog
        content={contentToDeleteOrArchive}
        open={showConfirmDeleteDialogOpen}
        setOpen={setShowConfirmDeleteDialogOpen}
      />

      {/* ------------------------------------------------------------------------------------------------------------------ */}

      {/* tracker table */}
      <Box id="tracker-table">
        <MaterialTable
          title={
            tableTitle.split('[').length > 1 ? (
              <>
                <Box component="span" sx={{ mx: 0.5 }}>
                  {tableTitle.split('[')[0]}
                </Box>
                <small>
                  [{tableTitle.split('[')[1]}{' '}
                  {dashboard.enableActivityPercentage &&
                    dashboard.enableActivityGroupWeights &&
                    dashboard.contentGroups?.length > 0 && (
                      <Tooltip
                        title={
                          <>
                            {dashboard.contentGroups.map((cg: any) => (
                              <Typography
                                key={cg.id}
                                variant="caption"
                                component="p"
                              >{`${cg.title}: ${cg.weight}`}</Typography>
                            ))}

                            <Typography
                              variant="caption"
                              component="p"
                              sx={{
                                fontStyle: 'italic',
                              }}
                            >
                              {`${t('Unassigned')}: `}
                              {dashboard.activityGroupUnassignedWeight}
                            </Typography>
                          </>
                        }
                        arrow
                      >
                        <Typography variant="caption" color="text.secondary">
                          ({t('Weighted')})
                        </Typography>
                      </Tooltip>
                    )}
                </small>
              </>
            ) : (
              tableTitle
            )
          }
          icons={tableIcons}
          localization={{
            header: {
              actions: '',
            },
            body: { emptyDataSourceMessage: `${t('Nothing to show')}` },
            grouping: {
              placeholder: `${t('Drag column headers here to group by')}`,
              groupedBy: `${t('Grouped By')}:`,
            },
            toolbar: {
              exportTitle: `${t('Export')}`,
              exportAriaLabel: `${t('Export')}`,
              exportCSVName: `${t('Export as')} CSV`,
              exportPDFName: `${t('Export as')} PDF`,
              searchTooltip: `${t('Search')}`,
              searchPlaceholder: `${t('Search')}`,
              searchAriaLabel: `${t('Search')}`,
              clearSearchAriaLabel: `${t('Clear Search')}`,
              addRemoveColumns: `${t('Add or remove columns')}`,
            },
          }}
          // options
          options={tableOptions}
          // row data
          columns={tableColumns}
          actions={tableActions}
          // data
          data={materialTableData}
          // on row click
          onRowClick={handleRowClick}
          // custom components
          components={{
            // toolbar
            Toolbar: (props: any) => (
              <TrackerTableToolbar status={status} {...props} />
            ),

            // grouping
            GroupRow: (props: any) => <DDDGroupRow {...props} />,
          }}
        />
      </Box>

      {/* ------------------------------------------------------------------------------------------------------------------ */}
    </ErrorBoundary>
  );
}
