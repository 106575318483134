import React from 'react';

import Box from '@mui/material/Box';

import { ContentFormInterventionSelectButton } from './button';
import { ContentFormInterventionSelectMenu } from './menu';

export function ContentFormInterventionSelect({
  content,
  setContent,
  errors,
}: any) {
  const [menuAnchorEl, setMenuAnchorEl] = React.useState<null | HTMLElement>(
    null
  );

  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setMenuAnchorEl(null);
  };

  // --------------------------------------------------------------------------------------------------------------

  // --------------------------------------------------------------------------------------------------------------

  // --------------------------------------------------------------------------------------------------------------

  return (
    <>
      {/* todo: add a priority area filter */}

      {/* interventions */}
      <Box>
        <ContentFormInterventionSelectButton
          handleClick={handleOpenMenu}
          anchorEl={menuAnchorEl}
          content={content}
          errors={errors}
        />

        <ContentFormInterventionSelectMenu
          anchorEl={menuAnchorEl}
          handleClose={handleCloseMenu}
          content={content}
          setContent={setContent}
        />
      </Box>
    </>
  );
}
