/* eslint-disable react/no-array-index-key */

import Typography from '@mui/material/Typography';

import { ContentDialogBlockersDetailTextContent } from './content';

export function ContentDialogBlockersDetailText({
  blocker,
  setContentStatus,
}: any) {
  const reactComponents = [] as any;

  // match http://localhost:3000/?content=123 or #123
  const matchUrl = `${window.location.protocol}//${window.location.host}[\\S]+content=[\\d]+`;
  const matchUrlRegex = new RegExp(matchUrl, 'gm');
  const matchRegex = new RegExp(
    `(?:${matchUrl})|(?:(?:^#|\\s#)[\\d]+(?:$|\\s))`,
    'gm'
  );

  // extract matches
  const matches = [...blocker.text.matchAll(matchRegex)];

  // split text by matches
  const splitText = blocker.text.split(matchRegex);

  // for each split text, add a span and if there is a match, add a ContentDialogBlockersDetailTextContent
  splitText.forEach((st: string, i: number) => {
    reactComponents.push(<span key={i}> {st} </span>);

    // check match index exists first because there might be more splits than
    // matches and vice versa
    if (matches[i]) {
      let contentId = matches[i][0];

      // if match is a url, extract content id
      if ([...contentId.matchAll(matchUrlRegex)].length > 0) {
        contentId = `${new URL(contentId).searchParams.get('content')}`;
      } else {
        contentId = contentId.replace(/#/g, '');
      }

      // push react component
      reactComponents.push(
        <ContentDialogBlockersDetailTextContent
          key={`rc-${i}`}
          contentId={contentId}
          setContentStatus={setContentStatus}
        />
      );
    }
  });

  return (
    <Typography
      component="span"
      variant="body2"
      sx={{
        minWidth: 0,
        textDecoration: blocker.isCompleted ? 'line-through' : 'none',
      }}
    >
      {matches.length === 0 ? blocker.text : reactComponents}
    </Typography>
  );
}
