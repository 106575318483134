import React from 'react';
import { useTranslation } from 'react-i18next';

import Box, { BoxProps } from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { DashboardContext } from '../_lib/context/dashboard-context';

export function ContentStatusChip({
  status,
  ...other
}: { status: string } & BoxProps) {
  const { t } = useTranslation();
  const {
    dashboard: { metaStatusColors },
  } = React.useContext(DashboardContext);

  const label = metaStatusColors[status];

  const { sx, ...otherProps } = other;

  if (!status) return null;

  return (
    <Box
      display="inline-block"
      sx={{
        display: 'flex',
        bgcolor: label ? label.bgcolor : 'grey.200',
        color: label ? label.textColor : 'grey.700',
        textTransform: 'uppercase',
        borderRadius: '2px',
        px: 0.7,
        pb: 0.45,
        pt: 0.5,
        ...sx,
      }}
      {...otherProps}
    >
      <Typography variant="caption" sx={{ lineHeight: 1 }}>
        {label ? t(label.text) : ''}
      </Typography>
    </Box>
  );
}
