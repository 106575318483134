import { gql } from '@apollo/client';

export const EXECUTIVE_SUMMARY_CONTENT_SUMMARY_QUERY = gql`
  query ExecutiveSummaryContentSummary($contentIds: [ID!]!) {
    contents(contentIds: $contentIds) {
      content {
        id
        title
        urlRelative
      }
    }
  }
`;
