import React from 'react';
import { useTranslation } from 'react-i18next';

import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import MuiSnackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import { useUndoDelete } from '../_utils/hooks/use-undo-delete';

export function Snackbar({
  open,
  setOpen,
  message,
  setMessage,
  isDelete,
  setIsDelete,
  isError,
  setIsError,
  recentlyDeletedProps,
  setRecentlyDeletedProps,
}: any) {
  const { t } = useTranslation();
  const { undo, loading } = useUndoDelete();

  const [localOpen, setLocalOpen] = React.useState(open);

  const handleClose = () => {
    if (setOpen) setOpen(false);
    setLocalOpen(false);
  };

  const handleExited = React.useCallback(() => {
    if (setIsDelete) setIsDelete(false);
    if (setIsError) setIsError(false);
    if (setMessage) setMessage('');
    if (recentlyDeletedProps?.objectId) setRecentlyDeletedProps(null);
  }, [
    setIsDelete,
    setIsError,
    setMessage,
    recentlyDeletedProps,
    setRecentlyDeletedProps,
  ]);

  React.useEffect(() => {
    setLocalOpen(open);
  }, [open]);

  return (
    <MuiSnackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      open={localOpen}
      autoHideDuration={isDelete ? 10000 : 3000}
      TransitionProps={{ onExited: handleExited }}
      onClose={() => handleClose()}
    >
      <Alert
        severity={isError ? 'error' : 'success'}
        sx={{
          bgcolor: 'background.paper',
        }}
        onClose={() => handleClose()}
        variant="outlined"
        action={
          <>
            {isDelete && recentlyDeletedProps && (
              <Button
                disabled={loading}
                size="small"
                onClick={undo}
                color="inherit"
              >
                {t('Undo')}
              </Button>
            )}
            <IconButton
              aria-label="close"
              color="inherit"
              sx={{ p: 0.5 }}
              onClick={() => handleClose()}
            >
              <CloseIcon />
            </IconButton>
          </>
        }
      >
        {message ||
          (isDelete && t('Successfully deleted.')) ||
          (isError && t('Something went wrong. Please try again.')) ||
          t('Successfully saved changes.')}
      </Alert>
    </MuiSnackbar>
  );
}
