import React from 'react';
import { useTranslation } from 'react-i18next';

import Button from '@mui/material/Button';

import { ContentStatusColorsDialog } from './dialog';

export function ContentStatusColors({ enabled }: any) {
  const { t } = useTranslation();

  const [openDialog, setOpenDialog] = React.useState(false);

  if (!enabled) return null;

  return (
    <>
      <ContentStatusColorsDialog open={openDialog} setOpen={setOpenDialog} />

      <Button
        variant="outlined"
        size="small"
        onClick={() => setOpenDialog(true)}
      >
        {t('Manage Activity Status Colours')}
      </Button>
    </>
  );
}
